import * as React from 'react';
import {
    Dialog,
    DialogType,
    DialogFooter,
    PrimaryButton,
    hiddenContentStyle,
    mergeStyles,
} from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import { ILineItemExcel } from '../../../Models/IExportToFinance';
import { exportToCSV } from '../../../Components/Shared/exportToCSV';
import { ExportToFinanceColumnsExcel } from '../ExportToFinanceColumns';

const screenReaderOnly = mergeStyles(hiddenContentStyle);

// ---------------------------------------------------------------------------
export const DownloadAuditConfirmationPopUp: React.FC<{ dismiss: any; items: ILineItemExcel[]; LoggedInAlias: string }> = ({
    dismiss,
    items,
    LoggedInAlias,
}) => {
    const subTextId: string = useId('ConfirmTextLabel');
    
    const WebJEFileName = () => {
        const TodayD = new Date();
        const sDay = TodayD.getDate().toString().length == 2 ? TodayD.getDate().toString() : '0' + TodayD.getDate().toString() ;
        const sMonth = TodayD.toDateString().substring(4,7);
        const sYear = TodayD.getFullYear();
        const sTimearray = TodayD.toLocaleTimeString().split(':');
        const sTimestring = (sTimearray[0].length == 2 ? sTimearray[0] : '0' + sTimearray[0]) + sTimearray[1] + sTimearray[2].split(' ')[1];

        return "WebJEReport_" + sMonth + "_" + sDay + "_" + sYear + "_" + sTimestring;
    }

    const dialogContentProps = {
        type: DialogType.normal,
        // title: 'Warning! Deleting this e-form cannot be undone.',
        closeButtonAriaLabel: 'Close',
        subText: 'Are you sure you wish to Audit this data?',
    };

    return (
        <>
            <label id={subTextId} className={screenReaderOnly}>
                Are you sure you wish to Audit this data?
            </label>

            <Dialog hidden={false}  dialogContentProps={dialogContentProps}>
                <DialogFooter>
                    <PrimaryButton onClick={dismiss} text="Cancel" />
                    <PrimaryButton
                        onClick={() => {
                            dismiss();
                           ExportToWebJEReport(items, LoggedInAlias);
                        }  }
                        text="OK"
                    />
                </DialogFooter>
            </Dialog>
        </>
    );

    async function ExportToWebJEReport(items: ILineItemExcel[], LoggedInAlias: string) {
                    //Need to add export to csv / excel functionality here
                    exportToCSV(items, WebJEFileName(), ExportToFinanceColumnsExcel);
    }
};