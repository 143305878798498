import { IColumn } from '@fluentui/react';

export const SendBackColumns: IColumn[] = [
    {
        key: 'ReloID',
        name: 'Record ID',
        fieldName: 'ReloID',
        minWidth: 60,
        maxWidth: 60,
    },
    {
        key: 'FileID',
        name: 'File ID',
        fieldName: 'FileID',
        minWidth: 60,
        maxWidth: 60,
    },
    {
        key: 'SendBackDate',
        name: 'Send Back Date',
        fieldName: 'SendBackDate',
        minWidth: 100,
        maxWidth: 100,
    },
    {
        key: 'SubmittedDate',
        name: 'Submitted Date',
        fieldName: 'SubmittedDate',
        minWidth: 100,
        maxWidth: 100,
    },
    {
        key: 'SubmittedBy',
        name: 'Submitted By',
        fieldName: 'SubmittedBy',
        minWidth: 100,
        maxWidth: 100,
    },
    {
        key: 'FullName',
        name: 'Full Name',
        fieldName: 'FullName',
        minWidth: 100,
        maxWidth: 100,
    },
    {
        key: 'EmployeeID',
        name: 'Employee ID',
        fieldName: 'EmployeeID',
        minWidth: 100,
        maxWidth: 100,
    },
    {
        key: 'HireTypeName',
        name: 'Hire Type',
        fieldName: 'HireTypeName',
        minWidth: 100,
        maxWidth: 100,
    },
    {
        key: 'RelocationPolicyTypeName',
        name: 'Relocation Policy',
        fieldName: 'RelocationPolicyTypeName',
        minWidth: 120,
        maxWidth: 120,
    },
    {
        key: 'Comments',
        name: 'Comments',
        fieldName: 'Comments',
        minWidth: 60,
        maxWidth: 60,
    }
];
