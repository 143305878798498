import * as React from 'react';

const messageStyle = {
    padding: '100px',
    fontFamily: 'Segoe UI',
    fontSize: '14px',
    color: '#78367d',
};

export const UnAuthorized: React.FC<{}> = () => {
    return (
        <div style={messageStyle}>
            You do not have permission to access the Global Mobility Authorization Tool (GMAT).
        </div>
    );
};
