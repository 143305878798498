import * as React from 'react';
import { Spinner, SpinnerSize, IStackTokens, Stack } from '@fluentui/react';

export const LoadingSpinner: React.FC<{}> = () => {
    const stackTokens: IStackTokens = {
        childrenGap: 20,
        maxWidth: 250,
    };

    return (
        <Stack tokens={stackTokens} style={{ width: '100%', margin: '200px auto 0px auto', display: 'table' }}>
            <div>                
                <p role='alert'> 
                    <Spinner label="Loading, please wait..." size={SpinnerSize.large} style={{ border: '30px', color: 'rgb(192, 192, 192)' }} /> 
                </p>
            </div>
        </Stack>
    );
};
