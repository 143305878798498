import { ReduxContext } from '@employee-experience/common';
import {
    DefaultPalette,
    IconButton,
    IStackItemStyles,
    IStackStyles,
    IStackTokens,
    PrimaryButton,
    Stack,
    MessageBar,
    MessageBarType,
    SpinnerSize,
    Spinner,
    DetailsList,
    Selection,
    SelectionMode,
    IColumn,
    MarqueeSelection,
} from '@fluentui/react';
import * as React from 'react';
import Dropzone from 'react-dropzone';
import { IAppState } from '../../Common/Redux/AppState';
import { ILoggedInUserRoles } from '../../Models/IUserRoles';
import { useServiceStore } from '../../RootStateContext';
import { UnAuthorized } from '../../Components/QueueTable/Unauthorized';
import { StackDisclaimer } from '../../Components/Shared/DisclaimerText';
import { IReassignValues } from '../../Models/IReAssignRecords';
import { ReassignReportColumns } from './ReassignReportColumns';
import * as XLSX from 'xlsx';

import './UploadReassignReport.css';

interface UploadReassignReportProps {}
export const UploadReassignReport: React.FC<UploadReassignReportProps> = (props) => {
    const [selectedFiles, setSelectedFiles] = React.useState<File[]>([]);
    const [reassignedResult, setReassignedResults] = React.useState<IReassignValues[]>([] as IReassignValues[]);
    const { useSelector } = React.useContext(ReduxContext);
    const userRole: ILoggedInUserRoles = useSelector((state: IAppState) => state.root.userReducer.loggedInUserRoles);
    const { uploadReassignReportService } = useServiceStore();
    const [ShowMessageBar, setShowMessageBar] = React.useState<boolean>(false);
    const [setUploading, setsetUploading] = React.useState<boolean>(true);
    const [StatusUpload, setSatusUpload] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [ShowUploadedMessageBar, setShowUploadedMessageBar] = React.useState<boolean>(false);
    const [ShowReassignedMessageBar, setShowReassignedMessageBar] = React.useState<boolean>(false);
    const [StatusUploaded, setSatusUploaded] = React.useState<string>('');
    const [UploadedJsonData, setUploadedJsonData] = React.useState<boolean>(false);
    const [ReassignedRecords, setReassignedRecords] = React.useState<boolean>(false);
    const [selectedItems, setSelectedItems] = React.useState<IReassignValues[]>([] as IReassignValues[]);

    const _closePanel = () => {
        setSelectedFiles([]);
        setShowUploadedMessageBar(true);
        setSatusUploaded('removed');
    };
    const onDropFile = (file: File[]) => {
        setShowMessageBar(false);
        if (file) {
            setSelectedFiles(file);
            setShowUploadedMessageBar(true);
            setSatusUploaded('uploaded');
            setsetUploading(true);
        }
    };

    const _selection = React.useMemo(
        () =>
            new Selection({
                onSelectionChanged: () => {
                    _getSelectionRecords();
                },
                selectionMode: SelectionMode.multiple,
            }),
        [ReassignReportColumns]
    );

    const _getSelectionRecords = (): any => {
        const selectedRecords = _selection.getSelection();
        setSelectedItems(selectedRecords);
    };

    const acceptedFileItems = selectedFiles.map((file) => (
        <li key={file.name}>
            {file.name} - {file.size} bytes{' '}
            <IconButton
                iconProps={{ iconName: 'ChromeClose' }}
                className="BulkUploadDeleteIcon"
                onClick={_closePanel}
                title="Clear"
                tabIndex={0}
                role="button"
                ariaLabel="Clear selected file"
            />
        </li>
    ));

    const sentReassignRecords = () => {
        uploadReassignReportService
            .UploadReassignReport(selectedItems)
            .then((message) => {
                setLoading(true);
                setSatusUpload(true);
            })
            .catch((error) => {
                setLoading(false);
                setReassignedRecords(false);
                setShowReassignedMessageBar(true);
            })
            .finally(() => {
                setLoading(false);
                setShowReassignedMessageBar(true);
                setReassignedRecords(true);
            });
    };

    const onUploadReport = () => {
        setsetUploading(false);
        setLoading(true);
        var arraylist;
        const file = selectedFiles[0];
        let fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);
        fileReader.onload = (e) => {
            let arrayBuffer = fileReader.result;
            var data = new Uint8Array(arrayBuffer);
            var arr = new Array();
            for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
            var bstr = arr.join('');
            var workbook = XLSX.read(bstr, { type: 'binary' });
            var first_sheet_name = workbook.SheetNames[0];
            var worksheet = workbook.Sheets[first_sheet_name];
            arraylist = XLSX.utils.sheet_to_json(worksheet, { raw: true });
            setReassignedResults(arraylist);
        };

        setLoading(false);
        setShowMessageBar(false);
        setSatusUpload(true);
        setShowUploadedMessageBar(false);
        setSatusUploaded('');
        setsetUploading(false);
        setUploadedJsonData(true);
    };

    // Styles definition
    const stackStyles: IStackStyles = {
        root: {
            height: 100,
        },
    };
    const stackItemStyles: IStackItemStyles = {
        root: {
            alignItems: 'center',
            background: '#faf9f8',
            color: DefaultPalette.black,
            display: 'flex',
            padding: '5px',
            justifyContent: 'center',
        },
    };
    const stackHeaderItemStyles: IStackItemStyles = {
        root: {
            color: DefaultPalette.black,
            display: 'flex',
            padding: '5px',
            justifyContent: 'Left',
            alignItems: 'flex-start',
        },
    };
    // Tokens definition
    const outerStackTokens: IStackTokens = { childrenGap: 5, padding: 5 };
    const innerStackTokens: IStackTokens = {
        childrenGap: 5,
        padding: 10,
    };
    const horizontalGapStackTokens: IStackTokens = { childrenGap: 30 };

    function _renderItemColumn(
        item: IReassignValues | undefined,
        index: number | undefined,
        column: IColumn | undefined
    ) {
        const fieldContent = item?.[column?.fieldName as keyof IReassignValues] as string;
        switch (column?.key) {
            case 'ReloIds':
                if (item?.ReloIds === null) {
                    return <span>{fieldContent}</span>;
                } else {
                    return item?.ReloIds;
                }
            case 'AliasReassigning':
                if (item?.AliasReassigning === null) {
                    return <span>{fieldContent}</span>;
                } else {
                    return item?.AliasReassigning;
                }
            default:
                return <span>{fieldContent}</span>;
        }
    }

    return (
        <Stack>
            {loading && (
                <Stack>
                    <Spinner
                        label="Loading, please wait..."
                        size={SpinnerSize.large}
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            height: '100%',
                            width: '100%',
                            zIndex: 9999999,
                            backgroundColor: 'rgb(255, 255, 255, 255)',
                        }}
                    />
                </Stack>
            )}
            <Stack tokens={outerStackTokens}>
                {userRole.ReloAdmin || userRole.GloMoCo || (userRole.GloMoCo && userRole.ReloSpecialist) ? (
                    <Stack>
                        <Stack
                            horizontal
                            disableShrink
                            horizontalAlign="space-between"
                            tokens={horizontalGapStackTokens}
                        >
                            <span style={{ marginLeft: '20px' }}>
                                <h1>Upload Cartus Reassign Report</h1>
                            </span>
                        </Stack>
                        {ShowMessageBar && StatusUpload ? (
                            <MessageBar delayedRender={false} messageBarType={MessageBarType.success} role={'alert'}>
                                Success! Your file uploads correctly.
                            </MessageBar>
                        ) : (
                            ShowMessageBar &&
                            !StatusUpload && (
                                <MessageBar delayedRender={false} messageBarType={MessageBarType.error} role={'alert'}>
                                    SORRY! The file could not be uploaded. Please check the format and try again.
                                </MessageBar>
                            )
                        )}
                        {ShowUploadedMessageBar && StatusUploaded === 'uploaded' ? (
                            <MessageBar delayedRender={false} messageBarType={MessageBarType.success} role={'alert'}>
                                Success! Your file is ready to Upload. Please click on Upload File button to validate
                                your file.
                            </MessageBar>
                        ) : (
                            ShowUploadedMessageBar &&
                            StatusUploaded === 'removed' && (
                                <MessageBar
                                    delayedRender={false}
                                    messageBarType={MessageBarType.success}
                                    role={'alert'}
                                >
                                    Success! The file has been removed.
                                </MessageBar>
                            )
                        )}
                        <Stack styles={stackStyles} tokens={innerStackTokens}>
                            <Stack.Item grow={3} styles={stackItemStyles}>
                                <Dropzone onDrop={onDropFile}>
                                    {({ getRootProps, getInputProps }) => (
                                        <section className="container dragContainer">
                                            <div {...getRootProps({ className: 'dropzone' })}>
                                                <input {...getInputProps()} />
                                                <p style={{ color: 'black' }}>Drag n Drop or Click to select file</p>
                                            </div>
                                        </section>
                                    )}
                                </Dropzone>
                            </Stack.Item>
                        </Stack>
                        {selectedFiles && selectedFiles.length > 0 && (
                            <Stack grow={4} styles={stackHeaderItemStyles}>
                                <h2> Selected File</h2>
                                <ul>{acceptedFileItems}</ul>
                            </Stack>
                        )}
                        <Stack grow styles={stackHeaderItemStyles}>
                            <PrimaryButton
                                tabIndex={0}
                                role="button"
                                onClick={onUploadReport}
                                disabled={selectedFiles.length > 0 && setUploading ? false : true}
                            >
                                Upload File
                            </PrimaryButton>
                        </Stack>

                        {UploadedJsonData && (
                            <Stack>
                                {ShowReassignedMessageBar && ReassignedRecords ? (
                                    <MessageBar
                                        delayedRender={false}
                                        messageBarType={MessageBarType.success}
                                        role={'alert'}
                                    >
                                        Success! The records have been reassigned.
                                    </MessageBar>
                                ) : (
                                    ShowReassignedMessageBar &&
                                    !ReassignedRecords && (
                                        <MessageBar
                                            delayedRender={false}
                                            messageBarType={MessageBarType.error}
                                            role={'alert'}
                                        >
                                            Error! There was an error while trying to Reassign the records.
                                        </MessageBar>
                                    )
                                )}
                                <MarqueeSelection selection={_selection}>
                                    <DetailsList
                                        selectionMode={SelectionMode.multiple}
                                        items={reassignedResult}
                                        selection={_selection}
                                        selectionPreservedOnEmptyClick={true}
                                        columns={ReassignReportColumns}
                                        onRenderItemColumn={_renderItemColumn}
                                        ariaLabelForSelectionColumn="Toggle selection"
                                        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                                        checkButtonAriaLabel="select row"
                                        ariaLabelForGrid="Reassign Records"
                                        styles={{
                                            root: {
                                                width: '100%',
                                            },
                                        }}
                                    />
                                </MarqueeSelection>
                                <Stack grow styles={stackHeaderItemStyles}>
                                    <PrimaryButton
                                        tabIndex={0}
                                        role="button"
                                        onClick={sentReassignRecords}
                                        disabled={selectedItems.length > 0 ? false : true}
                                    >
                                        Reassign Records
                                    </PrimaryButton>
                                </Stack>
                            </Stack>
                        )}

                        {StackDisclaimer()}
                    </Stack>
                ) : (
                    <UnAuthorized />
                )}
            </Stack>
        </Stack>
    );
};
