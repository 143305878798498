import * as React from 'react';
import {
    Stack,
    IconButton,
    Label,
    IStackStyles,
    IStackProps,
    IStackItemStyles,
    TextField,
    IDropdownOption,
    Dropdown,
    FontIcon,
    mergeStyles,
    PrimaryButton,
    Text,
} from '@fluentui/react';
import { CoreBenefitsList } from './CoreBenefitsList';
import { OptionalBenefitsList } from '../NewAuthForm/OptionalBenefitsList';
import { ExceptionsList } from '../NewAuthForm/ExceptionsList';
import { LumpSumPanel } from './LumpSumPanel';
import { PreHireTypePanel } from './PreHireTypePanel';
import { LoadingSpinner } from '../../Components/Shared/LoadingSpinner';

// --------- Styles ----------



const oneColumnProps: Partial<IStackProps> = {
    tokens: { childrenGap: 15 },
    styles: { root: { width: '100%' } },
};

const stackStyles: Partial<IStackStyles> = { root: { width: '100%', marginTop: '7px', marginBottom: '0px', padding: '14px' } };
const accordionHeader: IStackItemStyles = {
    root: {
        padding: 5,
        cursor: 'pointer',
        fontSize: '20px',
    },
};
const accordionContent: IStackItemStyles = {
    root: {
        margin: '0px 0px 7px 11px',
        width: '99%',
        boxShadow: 'rgb(0 0 0 / 16%) 0px 1px 4px'
    },
};

const iconinfoClass = mergeStyles({
    fontSize: 22,
    height: 22,
    width: 22,
    paddingRight: 20,
    color: '#0078D7'
});
// --------- Constants ----------
const stackTokens = { childrenGap: 20 };

const threeColumnProps: Partial<IStackProps> = {
    tokens: { childrenGap: 15 },
    styles: { root: { width: '32.33%' } },
};
// --------------------------------------------------
export const RelocationInformation: React.FC<any> = React.forwardRef((props, ref) => {
    const [isLoaded, setIsLoaded] = React.useState<boolean>(true);
    const [isOpen, setIsOpen] = React.useState<boolean>(true);
    const [relocationPolicyError, setRelocationPolicyError] = React.useState<string | undefined>(undefined);
    const [relocationTypeError, setRelocationTypeError] = React.useState<string | undefined>(undefined);

    React.useImperativeHandle(
        ref,
        () => ({
            validateForm() {
                let invalidFields: string[] = [];
                if (isNotNullOrUndefined(props.relocationPolicyID)) {
                    invalidFields = ['relocationPolicyID', 'relocationPolicyType'];
                }
                return { errors: Array.from(new Set(invalidFields)) }
            }
        }),
    );

    const toggleRelocationInformation = () => {
        setIsOpen(!isOpen);
    };

    React.useEffect(() => {
        if (props.validateOnSubmit || props.validateOnReSubmit) {
            if (isNotNullOrUndefined(props.relocationPolicyID)) {
                setRelocationPolicyError('Relocation Policy Business Group.');
                setRelocationTypeError('Please select Relocation Type.');
                props.erroredFields(['relocationPolicyID', 'relocationPolicyType']);
            }
            if(isNotNullOrUndefined(props.relocationpolicyTypeID) || props.relocationpolicyTypeID === 0){
                setRelocationTypeError('Please select Relocation Type.');
                props.erroredFields(['relocationPolicyType']);
            }
        }
    }, [props.validateOnSubmit, props.validateOnReSubmit]);

    const isNotNullOrUndefined = (value: any) => {
        if (value === null || value === undefined || isNaN(value)) return true;
        if (typeof value === 'string' && value === '') return true;
        return false;
    };

    return (
        <Stack className="RelocationInformation" style={{ padding: '0px 2px 0px 2px' }} id='corebenefits'>
            <Stack horizontal={true} style={{ backgroundColor: 'rgb(235, 235, 235)', maxWidth: '100%', margin: '5px 0px 0px 5px' }}>
                <Stack.Item style={{ marginTop: '3px' }} className="ac-icon">
                    <IconButton
                        title="Relocation Information"
                        iconProps={{
                            iconName: isOpen ? 'ChevronUp' : 'ChevronDown',
                        }}
                        onClick={toggleRelocationInformation}
                    />
                </Stack.Item>
                <Stack.Item align="center" styles={accordionHeader} className="ac-title">
                    <h2 style={{ fontSize: '20px' }}> Relocation Information </h2>
                </Stack.Item>
            </Stack>

            {isOpen && (
                <Stack styles={accordionContent}>
                    <Stack horizontal tokens={stackTokens} styles={stackStyles}>
                        <Stack {...threeColumnProps}>
                            <Dropdown
                                options={props.relocationPolicyOptions}
                                label="Relocation Policy"
                                selectedKey={props.relocationPolicyID}
                                required
                                onChange={onRelocationPolicyChange}
                                errorMessage={relocationPolicyError}
                            ></Dropdown>
                        </Stack>
                        <Stack {...threeColumnProps}>
                            <Dropdown
                                options={props.relocationPolicyTypeOptions}
                                disabled={props.hireTypeOption != 0 && props.relocationPolicyID != 0 ? false : true}
                                label="Relocation Type"
                                selectedKey={props.relocationpolicyTypeID?.toString()}
                                onChange={onReloTypeChange}
                                required
                                errorMessage={relocationTypeError}
                            ></Dropdown>
                            <Stack horizontal={true}>
                                <FontIcon
                                    iconName="Info"
                                    aria-label="Estimated Start Date Info"
                                    className={iconinfoClass}
                                />
                                <Text variant="xSmall">
                                    To make a selection here, first select Job Level and a Relocation Policy.
                                </Text>
                            </Stack>
                        </Stack>
                    </Stack>
                    {isLoaded && (
                        <>
                            {props.showBenefitsPanel && (
                                <>
                                    <Stack style={{ paddingLeft: '10px' }}>
                                        {/* Core Benefits */}
                                        <h3> <Label>Core Benefits</Label> </h3>
                                        {props.relocationpolicyTypeID !== 0 &&
                                            props.relocationpolicyTypeID !== null &&
                                            props.relocationpolicyTypeID !== undefined && (
                                                <CoreBenefitsList
                                                    reloTypeId={props.relocationpolicyTypeID}
                                                    reloPolicyId={props.relocationPolicyID}
                                                    hireTypeId={props.hireTypeOption}
                                                    coreBenefits={props.coreBenefits}
                                                    coreAllowance={props.coreAllowance}
                                                    relocationCashAmount={props.relocationCashAmount}
                                                    updateRelocationCashAmt={props.updateRelocationCashAmt}
                                                    validateOnSubmit={props.validateOnSubmit}
                                                    validateOnReSubmit={props.validateOnReSubmit}
                                                    erroredFields={props.erroredFields}
                                                    clearedFields={props.clearedFields}
                                                    taxpactforlumpsum={props.taxpactforlumpsum}
                                                />
                                            )}
                                    </Stack>
                                    {props.showOptionalException && 
                                    (<Stack>
                                        <Stack styles={stackStyles}>
                                            <h3> <Label>Optional Benefits</Label> </h3>
                                            {props.relocationpolicyTypeID != 51
                                                && props.relocationpolicyTypeID != 50
                                                && (
                                                    <Text>Select all Optional Benefits that apply. Use the Exceptions drop down below to provide Optional Benefits beyond policy caps.</Text>)
                                            }
    
                                            {props.relocationpolicyTypeID == 50
                                                && (
                                                    <Text style={{ color: 'red' }}>Optional Benefits are limited to one only. Household Goods and Storage equals one benefit.</Text>)
                                            }
    
                                            {props.relocationpolicyTypeID == 51
                                                && (
                                                    <Text style={{ color: 'red' }}>Optional Benefits are limited to two only. Household Goods and Storage equals one benefit.</Text>)
                                            }
                                            {props.relocationpolicyTypeID !== 0 &&
                                                props.relocationpolicyTypeID !== undefined && (
                                                    <OptionalBenefitsList
                                                        relocationpolicyTypeID={props.relocationpolicyTypeID}
                                                        reloPolicyId={props.relocationPolicyID}
                                                        allOptionalBenefits={props.allOptionalBenefits}
                                                        handleOptionalBenefitUnitUpdate={
                                                            props.handleOptionalBenefitUnitUpdate
                                                        }
                                                    />
                                                )}
                                        </Stack>
                                                    
                                        {props.relocationpolicyTypeID !== null &&
                                            props.relocationpolicyTypeID !== 0 &&
                                            props.relocationpolicyTypeID !== undefined && (
                                                <Stack>
                                                    <Stack horizontal tokens={stackTokens} styles={stackStyles}>
                                                        <Stack>
                                                            <h3><Label style={{ fontSize: '17px' }}>Exceptions</Label></h3>
                                                            <Label style={{ fontSize: '12px' }}>
                                                                Use the Exceptions drop down below to add approved
                                                                exceptions to Core and/ or Optional Benefits.
                                                            </Label>
                                                        </Stack>
                                                    </Stack>
                                                    <Stack horizontal tokens={stackTokens} styles={stackStyles}>
                                                        <Stack horizontal tokens={stackTokens} styles={stackStyles}>
                                                            <Stack {...threeColumnProps}>
                                                                <Dropdown
                                                                    label="Exceptions (optional)"
                                                                    options={props.exceptionOptions}
                                                                    disabled={false}
                                                                    onChange={(
                                                                        event: React.FormEvent<HTMLDivElement>,
                                                                        option?: IDropdownOption
                                                                    ) => {
                                                                        props.updateSelectedException(option!.text);
                                                                    }}
                                                                ></Dropdown>
                                                            </Stack>
                                                            <Stack style={{ marginTop: '28px' }}>
                                                                <PrimaryButton
                                                                    text="Add Exception"
                                                                    onClick={props.handleAddException}
                                                                    allowDisabledFocus
                                                                    disabled={false}
                                                                />
                                                            </Stack>
                                                            <Stack style={{ marginTop: '28px' }}></Stack>
                                                        </Stack>
                                                    </Stack>
                                                </Stack>
                                            )}
                                    </Stack>)}
                                </>
                            )}

                            {props.relocationpolicyTypeID !== null &&
                                props.relocationpolicyTypeID == 36 &&
                                props.relocationpolicyTypeID !== undefined && (
                                    <Stack>
                                        <Stack horizontal tokens={stackTokens} styles={stackStyles}>
                                            <Stack>
                                                <h3><Label style={{ fontSize: '17px' }}>Exceptions</Label></h3>
                                                <Label style={{ fontSize: '12px' }}>
                                                    Add Exceptions if you need to make adjustments to the package.
                                                </Label>
                                            </Stack>
                                        </Stack>
                                        <Stack horizontal tokens={stackTokens} styles={stackStyles}>
                                            <Stack horizontal tokens={stackTokens} styles={stackStyles}>
                                                <Stack {...threeColumnProps}>
                                                    <Dropdown
                                                        label="Exceptions (optional)"
                                                        options={props.exceptionOptions}
                                                        disabled={false}
                                                        onChange={(
                                                            event: React.FormEvent<HTMLDivElement>,
                                                            option?: IDropdownOption
                                                        ) => {
                                                            props.updateSelectedException(option!.text);
                                                        }}
                                                    ></Dropdown>
                                                </Stack>
                                                <Stack style={{ marginTop: '28px' }}>
                                                    <PrimaryButton
                                                        text="Add Exception"
                                                        onClick={props.handleAddException}
                                                        allowDisabledFocus
                                                        disabled={false}
                                                    />
                                                </Stack>
                                                <Stack style={{ marginTop: '28px' }}></Stack>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                )}

                            <Stack styles={stackStyles}>
                                {props.passExceptionList.length > 0 && (
                                    <ExceptionsList
                                        sortedItems={props.passExceptionList}
                                        relocationPolicyID={Number(props.relocationPolicyID)}
                                        removeExceptionHandler={props.handleRemoveException}
                                        handleUpdateUnit={props.handleExceptionUnitValueUpdate}
                                        exceptionComponentUpdated={props.exceptionListUpdatedFlag}
                                        exceptionsResponse={props.exceptionsResponse}
                                    />
                                )}
                            </Stack>

                            {props.showLumpSumPanel && (
                                <>
                                    {' '}
                                    <Stack>
                                        <h3> <Label>Core Benefits</Label> </h3>
                                        <LumpSumPanel
                                            relocationPolicyID={Number(props.relocationPolicyID)}
                                            relocationType={props.relocationType}
                                            updateLumSumNetCash={props.updateLumSumNetCash}
                                            updateLumSumAccuralCost={props.updateLumSumAccuralCost}
                                            LumpSumRelocation={props.lumpSumRelocation}
                                            taxPctForLumpSum={props.taxPctForLumpSum}
                                        />
                                    </Stack>
                                </>
                            )}

                            {props.showPreHireTypePanel && (
                                <>
                                    <PreHireTypePanel
                                        preHireTypeBenefits={props.preHireTypeBenefits}
                                        updatePreHireSelectedBenefits={props.updatePreHireSelectedBenefits}
                                    />
                                </>
                            )}

                            <Stack horizontal tokens={stackTokens} styles={stackStyles}>
                                <Stack {...oneColumnProps}>
                                    <TextField
                                        label="Comments"
                                        role='textbox'
                                        multiline
                                        rows={3}
                                        maxLength={400}
                                        value={props.comments}
                                        onChange={(_, newValue?: string) => {
                                            props.updateRelocationComments(newValue);
                                        }}
                                        description={`${400 - (props.comments != null ? props.comments.length : 0)
                                            } Characters Left`}
                                    ></TextField>
                                </Stack>
                            </Stack>
                        </>
                    )}
                    {!isLoaded && <LoadingSpinner />}
                </Stack>
            )}
        </Stack>
    );

    async function onRelocationPolicyChange(
        event: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption
    ): Promise<void> {
        setIsLoaded(false);
        await props.onRelocationPolicyChange(option);
        setIsLoaded(true);
        if (isNotNullOrUndefined(option!.key)) {
            setRelocationPolicyError('Relocation Policy Business Group.');
            props.erroredFields(['relocationPolicyID']);
        } else {
            setRelocationPolicyError(undefined);
            props.clearedFields('relocationPolicyID');
        }
    }

    async function onReloTypeChange(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): Promise<void> {
        setIsLoaded(false);
        await props.changeReloType(option?.key as number);
        setIsLoaded(true);
        if (isNotNullOrUndefined(option!.key)) {
            setRelocationTypeError('Please select Relocation Type.');
            props.erroredFields(['relocationPolicyType']);
        } else {
            setRelocationTypeError(undefined);
            props.clearedFields('relocationPolicyType');
        }
    }
});
