import { combineReducers } from 'redux';
import { ActionType } from './actionTypes';
import { IRelocationFormQueues } from '../../Models/IRelocationFormQueues';
import { ILoggedInUserRoles, IUserRoles, UserRoles } from '../../Models/IUserRoles';
import {
    IGlomocoeReducerState,
    IMyReloReducerState,
    IRelocampReducerState,
    ISendBackReducerState,
    IUserReducerState,
    IEstimatesQueueFilterPanelData,
} from './AppState';
import { INewRelocationRequest } from '../../Models/INewRelocationRequest';
import { IGlomocoeQueueForm } from '../../Models/IGlomocoeQueue';

const initialUserReducerState: IUserReducerState = {
    userRoles: {} as IUserRoles,
    loggedInUserRoles: {} as ILoggedInUserRoles,
    homePage: "",
};

const userReducer = (state: IUserReducerState = initialUserReducerState, action: any) => {
    switch (action.type) {
        case ActionType.SET_USERS:
            let defaultHomePage = ""
            let userRoles: ILoggedInUserRoles = {
                GloMoCo: false,
                ReloAdmin: false,
                ReloAnalyst: false,
                ReloSpecialist: false,
                Staffing: false,
                Guest: false,
                NOROLE: false,
            };

            action.data.Roles.forEach((val: UserRoles) => {
                switch (val) {
                    case UserRoles.ReloAdmin:
                        defaultHomePage = "/MyReloQueue"
                        return (userRoles.ReloAdmin = true);
                    case UserRoles.ReloAnalyst:
                        defaultHomePage = "/EstimatesQueue"
                        return (userRoles.ReloAnalyst = true);
                    case UserRoles.ReloSpecialist:
                        defaultHomePage = "/ReloSpecialistQueue"
                        return (userRoles.ReloSpecialist = true);
                    case UserRoles.GloMoCo:
                        defaultHomePage = "/GlomocoeQueue"
                        return (userRoles.GloMoCo = true);
                    case UserRoles.Staffing:
                        defaultHomePage = "/MyReloQueue"
                        return (userRoles.Staffing = true);
                    case UserRoles.Guest:
                        defaultHomePage = "/PendingExceptionsQueue"
                        return (userRoles.Guest = true);
                    default:
                        defaultHomePage = "/NoRole"
                        return (userRoles.NOROLE = true);
                }
            });

            return {
                ...state,
                userRoles: action.data,
                loggedInUserRoles: userRoles,
                homePage: defaultHomePage
            };
        default:
            return state;
    }
};

const initalMyReloState: IMyReloReducerState = {
    reloQueues: {} as IRelocationFormQueues,
};

const myReloReducer = (state: IMyReloReducerState = initalMyReloState, action: any) => {
    switch (action.type) {
        case ActionType.SET_MYRELOQUEUE:
            return {
                ...state,
                reloQueues: action.data,
            };
        default:
            return state;
    }
};

const initalGlomocoeState: IGlomocoeReducerState = {
    glomocoeQueues: {} as IGlomocoeQueueForm,
};

const glomocoeReducer = (state: IGlomocoeReducerState = initalGlomocoeState, action: any) => {
    switch (action.type) {
        case ActionType.SET_GLOMOCOEQUEUE:
            return {
                ...state,
                glomocoeQueues: action.data,
            };
        default:
            return state;
    }
};

const initalrelocampState: IRelocampReducerState = {
    relocampQueues: {} as IRelocationFormQueues,
};

const relocampReducer = (state: IRelocampReducerState = initalrelocampState, action: any) => {
    switch (action.type) {
        case ActionType.SET_RELOCAMPQUEUE:
            return {
                ...state,
                relocampQueues: action.data,
            };
        default:
            return state;
    }
};

const initalSendbackState: ISendBackReducerState = {
    sendBackQueues: {} as IRelocationFormQueues,
};

const sendBackReducer = (state: ISendBackReducerState = initalSendbackState, action: any) => {
    switch (action.type) {
        case ActionType.SET_SENDBACKQUEUE:
            return {
                ...state,
                sendBackQueues: action.data,
            };
        default:
            return state;
    }
};

const initialNewRelocationRequestState: INewRelocationRequest = {
    isNewRelocation: false,
};

const newRelocationReducer = (state: INewRelocationRequest = initialNewRelocationRequestState, action: any) => {
    switch (action.type) {
        case ActionType.SET_STARTNEWRELOCATION:
            return {
                ...state,
                isNewRelocation: action.data,
            };

        case ActionType.SET_TAXRATESBYJOBLEVEL:
            return {
                ...state,
                taxByJobLevel: action.data,
            };
        default:
            return state;
    }
};

const estimatesQueueFilterPanelData: IEstimatesQueueFilterPanelData = {
    filters: {
        selectedByDefectFilters: [],
        selectedAllDefectFilters: [],
        selectedreloTypeFitlers: [],
        selectedstatusFilters: [],
        filterFromDate: [],
        filterToDate: [],
        searchDropDown: '',
        searchKeyword: '',
    },
    activeEstimatesTab: ''
};

const estimatesQueueFilterPanelReducer = (state: IEstimatesQueueFilterPanelData = estimatesQueueFilterPanelData, action: any) => {
    switch (action.type) {
        case ActionType.SET_FILTERS:
            return {
                ...state,
                filters: action.data,
            };
        case ActionType.RESET_FILTERS_QUEUE:
            return {
                ...state,
                filters: {
                    selectedByDefectFilters: [],
                    selectedAllDefectFilters: [],
                    selectedreloTypeFitlers: [],
                    selectedstatusFilters: [],
                    filterFromDate: [],
                    filterToDate: [],
                },
            };
        case ActionType.RESET_FILTERS_SEARCH:
            return {
                ...state,
                filters: {
                    searchDropDown: '',
                    searchKeyword: '',
                },
            };
        case ActionType.SET_ACTIVETAB:
            return {
                ...state,
                activeEstimatesTab: action.data,
            };
        default:
            return state;
    }
};

export const appReducer = combineReducers({
    userReducer,
    myReloReducer,
    glomocoeReducer,
    relocampReducer,
    sendBackReducer,
    newRelocationReducer,
    estimatesQueueFilterPanelReducer
    //here we can add other reducers
});
