import {
    CheckboxVisibility,
    PrimaryButton,
    MessageBar,
    MessageBarType,
    ShimmeredDetailsList,
    Spinner,
    SpinnerSize,
    Stack,
    StackItem,
    IColumn,
    IStackItemStyles,
} from '@fluentui/react';
import * as React from 'react';
import { useServiceStore } from '../../RootStateContext';
import { Pagination } from '../../Components/Shared/Pagination';
import { ExportToFinanceColumns } from './ExportToFinanceColumns';
import { ILineItem, ILineItemExcel } from '../../Models/IExportToFinance';
import { DownLoadConfirmationPopUp } from './PopUps/DownloadConfirmationPopUp';
import { DownloadAuditConfirmationPopUp } from './PopUps/DownloadAuditConfirmationPopUp';
import { IUserRoles, ILoggedInUserRoles } from '../../Models/IUserRoles';
import { IAppState } from '../../Common/Redux/AppState';
import { ReduxContext } from '@employee-experience/common';
import { StackDisclaimer } from '../../Components/Shared/DisclaimerText';
import { UnAuthorized } from '../../Components/QueueTable/Unauthorized';

export const ExportToWebJEReport: React.FC<{}> = (props) => {
    const [queueItems, setQueueItems] = React.useState<ILineItem[]>([]);
    const [queueItemsShow, setQueueItemsShow] = React.useState<any[]>([]);
    const [queueItemsExcel] = React.useState<ILineItemExcel[]>([]);
    const [showExportConfirmationPopup, setshowExportConfirmationPopup] = React.useState<boolean>(false);
    const [showExportAuditConfirmationPopup, setshowExportAuditConfirmationPopup] = React.useState<boolean>(false);
    const { exportToWebJEService } = useServiceStore();
    const { useSelector } = React.useContext(ReduxContext);
    const userdata: IUserRoles = useSelector((state: IAppState) => state.root.userReducer.userRoles);
    const userRole: ILoggedInUserRoles = useSelector((state: IAppState) => state.root.userReducer.loggedInUserRoles);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [apiError, setApiError] = React.useState<boolean>(false);
    const [messageText, setMessageText] = React.useState<string>('');
    const [columns, setColumns] = React.useState<IColumn[]>(ExportToFinanceColumns);

    function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
        const key = columnKey as keyof T;
        return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    }

    React.useEffect(() => {
        getExportWebJEItems();
    }, []);

    const roundHighestNumber = (Amount: number) => {
        //As we also round negative number we need this custom round so negative numbers round to the highest insted of lowest number
        return Amount >= 0 ? Math.round(Amount) : Amount % 1 <= -0.5 ? Math.floor(Amount) : Math.round(Amount);
    };

    async function getExportWebJEItems() {
        setLoading(true);
        await exportToWebJEService
            .GetLineItems()
            .then((result) => {
                let tempAmount = result.map((item) => ({
                    ...item,
                    Amount: roundHighestNumber(item.Amount),
                }));
                setQueueItems(tempAmount);
                queueItemsExcel.length = 0;
                tempAmount.forEach((item) => {
                    let newitem = {
                        ACCOUNT: item.Account,
                        COST_CENTER: item.CostCenter,
                        INTERNAL_ORDER: item.InternalOrder,
                        SO_ORDER: item.SOOrder,
                        SO_LINEITEM: item.SOLineItem,
                        TRADING_PARTNER: item.TradingPartner,
                        PROFIT_CENTER: item.ProfitCenter,
                        AMOUNT: item.Amount,
                        LINE_DESCRIPTION: item.LineDescription,
                        PROCESSING_STATUS: item.ProcessingStatus,
                        DESTINATION_COMPANY_CODE: item.DestinationCompanyCode,
                    };
                    queueItemsExcel.push(newitem);
                });
            })
            .catch((error) => {
                setMessageText('Unable to Fetch WebJE Records');
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const dismissPopUp = () => {
        setshowExportConfirmationPopup(false);
        setshowExportAuditConfirmationPopup(false);
        getExportWebJEItems();
    };

    const handleErrorMessageBar = () => {
        setApiError(false);
        setMessageText('');
    };

    const buttonStyles = {
        marginBottom: '10px',
    };

    const stackItemStyles: IStackItemStyles = {
        root: {
            padding: 5,
        },
    };

    return (
        <Stack>
            {loading && (
                <Stack>
                    <div>
                        <Spinner
                            label="Loading, please wait..."
                            size={SpinnerSize.large}
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                height: '100%',
                                width: '100%',
                                zIndex: 9999999,
                                backgroundColor: 'rgb(255, 255, 255, 255)',
                            }}
                        />
                    </div>
                </Stack>
            )}

            <Stack.Item align="auto" styles={{ root: { padding: 5 } }}>
                <h1>Export to Finance</h1>
            </Stack.Item>
            {apiError && (
                <MessageBar
                    messageBarType={MessageBarType.error}
                    isMultiline={false}
                    onDismiss={handleErrorMessageBar}
                    dismissButtonAriaLabel="Close"
                >
                    {messageText}
                </MessageBar>
            )}
            {showExportConfirmationPopup && (
                <DownLoadConfirmationPopUp
                    dismiss={dismissPopUp}
                    items={queueItemsExcel}
                    LoggedInAlias={userdata.Alias}
                />
            )}
            {showExportAuditConfirmationPopup && (
                <DownloadAuditConfirmationPopUp
                    dismiss={dismissPopUp}
                    items={queueItemsExcel}
                    LoggedInAlias={userdata.Alias}
                />
            )}

            {userRole.ReloAnalyst || userRole.ReloAdmin ? (
                <Stack>
                    <StackItem>
                        <Stack horizontal styles={stackItemStyles}>
                            <Stack.Item align="auto" styles={stackItemStyles}>
                                {queueItems.length > 0 && (
                                    <PrimaryButton
                                        text="Export to Web JE"
                                        onClick={() => {
                                            setshowExportConfirmationPopup(true);
                                        }}
                                        style={buttonStyles}
                                        disabled={queueItems.length == 0}
                                        allowDisabledFocus
                                    ></PrimaryButton>
                                )}
                            </Stack.Item>
                            <Stack.Item align="auto" styles={stackItemStyles}>
                                {queueItems.length > 0 && (
                                    <PrimaryButton
                                        text="Audit Web JE Export"
                                        onClick={() => {
                                            setshowExportAuditConfirmationPopup(true);
                                        }}
                                        style={buttonStyles}
                                        disabled={queueItems.length == 0}
                                        allowDisabledFocus
                                    ></PrimaryButton>
                                )}
                            </Stack.Item>
                        </Stack>
                    </StackItem>

                    {queueItems.length > 0 && (
                        <StackItem>
                            <ShimmeredDetailsList
                                items={queueItemsShow}
                                columns={columns}
                                onColumnHeaderClick={_onColumnClick}
                                checkboxVisibility={CheckboxVisibility.hidden}
                                ariaLabelForGrid="Export to WebJE Items"
                            />
                        </StackItem>
                    )}
                    {queueItems.length > 0 && (
                        <StackItem>
                            <Pagination
                                data={queueItems}
                                columns={ExportToFinanceColumns}
                                setData={setQueueItemsShow}
                            />
                        </StackItem>
                    )}
                    {queueItems.length == 0 && <h2>No Records to display</h2>}
                    {StackDisclaimer()}
                </Stack>
            ) : (
                <UnAuthorized />
            )}
        </Stack>
    );
    function _onColumnClick(event: React.MouseEvent<HTMLElement> | undefined, column: IColumn | undefined): void {
        let isSortedDescending = column?.isSortedDescending;
        if (column?.isSorted) {
            isSortedDescending = !isSortedDescending;
        }
        let sortedItems: ILineItem[] = _copyAndSort(queueItems, column?.fieldName!, isSortedDescending);

        let sortedCol = columns.map((col) => {
            col.isSorted = col.key === column?.key;

            if (col.isSorted) {
                col.isSortedDescending = isSortedDescending;
            }
            return col;
        });
        setQueueItems(sortedItems);
        setColumns(sortedCol);
    }
};
