import {
    CheckboxVisibility,
    MessageBar,
    MessageBarType,
    ShimmeredDetailsList,
    Spinner,
    Dropdown,
    IDropdownOption,
    PrimaryButton,
    SpinnerSize,
    Stack,
    SearchBox,
    IStackTokens,
    StackItem,
    IColumn,
    mergeStyles,
    FontIcon,
    mergeStyleSets
} from '@fluentui/react';
import * as React from 'react';
import { useServiceStore } from '../../RootStateContext';
import { Pagination } from '../../Components/Shared/Pagination';
import { SentToFileSetupColumns } from './SentToFileSetupColumns';
import { ISentToFileSetup } from '../../Models/ISentToFileSetupQueue';
import {  ILoggedInUserRoles } from '../../Models/IUserRoles';
import { IAppState } from '../../Common/Redux/AppState';
import { ReduxContext } from '@employee-experience/common';
import { StackDisclaimer } from '../../Components/Shared/DisclaimerText';
import { UnAuthorized } from '../../Components/QueueTable/Unauthorized';
import { ExportToExcel } from '../../Components/Shared/ExportToExcel';

export const SentToFileSetup: React.FC<{}> = (props) => {
    const [queueItems, setQueueItems] = React.useState<ISentToFileSetup[]>([]);
    const [queueItemsShow, setQueueItemsShow] = React.useState<any[]>([]);
    const { sentToFileSetupQueueService } = useServiceStore();
    const { useSelector } = React.useContext(ReduxContext);
    const userRole: ILoggedInUserRoles = useSelector((state: IAppState) => state.root.userReducer.loggedInUserRoles);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [apiError, setApiError] = React.useState<boolean>(false);
    const [showResetButtom, setShowResetButtom] = React.useState<boolean>(false);
    const [messageText, setMessageText] = React.useState<string>('');
    const [columns, setColumns] = React.useState<IColumn[]>(SentToFileSetupColumns);
    const [searchByValue, setsearchByValue] = React.useState<string>('Search by');
    const [searchByText, setsearchByText] = React.useState<string>('Search by');
    const [searchError, setSearchError] = React.useState<boolean>(false);
    const [searchKeyword, setSearchKeyword] = React.useState<string>('');

    const horizontalGapStackTokens: IStackTokens = {
        childrenGap: 10,
        padding: 10,
    };

    const custom_error_msg = mergeStyles({
        color: 'crimson',
        fontSize: '12.6px',
        marginTop: '5px',
        marginBottom: '0px',
        fontWeight: 'bold',
    });

    const dropdownOptions = [
        { key: 'fileID', text: 'File ID' },
        { key: 'recordID', text: 'Record ID' },
        { key: 'employeeID', text: 'Employee ID' },
    ];

    enum searchByOptions {
        fileID = 1,
        recordID = 2,
        employeeID = 3,
    }

    function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
        const key = columnKey as keyof T;
        return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    }

    React.useEffect(() => {
        getSentToFileSetupitems();
    }, []);


    function _renderItemColumn(item: ISentToFileSetup | undefined, index: number | undefined, column: IColumn | undefined) {
        const fieldContent = item?.[column?.fieldName as keyof ISentToFileSetup] as string;

        const iconClass = mergeStyles({
            fontSize: 16,
            height: 16,
            width: 16,
            paddingLeft: 10,
        });

        const classNames = mergeStyleSets({
            error: [{ color: '#DF0008' }, iconClass],
        });      

        switch (column?.key) {
            case 'FlagSLA3days':
                if (item?.FlagSLA3days == '1')
                {
                    return (
                        <Stack horizontal horizontalAlign="start">
                            <FontIcon
                                aria-label={item?.FlagSLA3days}
                                iconName="Flag"
                                className={classNames.error}
                            />
                        </Stack>
                    );
                }
                default:
                    return <span>{fieldContent}</span>;
        }
    }

    async function getSentToFileSetupitems() {
        setLoading(true);
        await sentToFileSetupQueueService
            .getSentToFileSetup()
            .then((result) => {                
                setQueueItems(result);                              
            })
            .catch((error) => {
                setMessageText('Unable to Fetch Data');
            })
            .finally(() => {
                setLoading(false);
            });
        setShowResetButtom(false);
    }

    async function getSentToFileSetupSearchedItems(){
        const searchOption: searchByOptions = searchByOptions[searchByValue as keyof typeof searchByOptions];
        await sentToFileSetupQueueService.getSentToFileSetupSearch(
            searchOption,
            searchKeyword.trimEnd()
        ).then((result) => {                
            setQueueItems(result);
            setShowResetButtom(true);                              
        })
        .catch((error) => {
            setMessageText('Unable to Fetch Data');
        })       
        
    }

    function onDropdownChange(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void {
        setsearchByValue(option!.key as string);
        setsearchByText(option!.text as string);
        setSearchError(false);
    }

    const handleErrorMessageBar = () => {
        setApiError(false);
        setMessageText('');
    };

    return (
        <Stack>
            {loading && (
                <Stack>
                    <div>
                        <Spinner
                            label="Loading, please wait..."
                            size={SpinnerSize.large}
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                height: '100%',
                                width: '100%',
                                zIndex: 9999999,
                                backgroundColor: 'rgb(255, 255, 255, 255)',
                            }}
                        />
                    </div>
                </Stack>
            )}
            
            <Stack.Item align="auto" styles={{ root: { padding: 5 } }}>
                <h1>Sent To File Setup Queue</h1>
            </Stack.Item>
            {apiError && (
                <MessageBar
                    messageBarType={MessageBarType.error}
                    isMultiline={false}
                    onDismiss={handleErrorMessageBar}
                    dismissButtonAriaLabel="Close"
                >
                    {messageText}
                </MessageBar>
            )}    
            
            
            {(userRole.ReloSpecialist || userRole.GloMoCo || userRole.ReloAdmin) ? (
                <Stack>
                    <Stack horizontal horizontalAlign="space-between" tokens={horizontalGapStackTokens}>
                        <Stack.Item>
                                <Stack.Item align="auto" style={{ display: 'inline-flex' }}>
                                    
                                </Stack.Item>
                                <Stack.Item align="auto" style={{ display: 'inline-flex', paddingLeft: '10px', position: 'relative', top: '30px' }}>
                                    <ExportToExcel
                                        rowsData={queueItems}
                                        colsData={SentToFileSetupColumns}
                                        fileName="SentToFileSetupQueue.xlsx"
                                    />
                                </Stack.Item>
                            </Stack.Item>
                            <Stack.Item>
                                <Stack.Item style={{ display: 'inline-flex' }}>
                                    <Dropdown
                                        label="Search by"
                                        placeholder='Select One'
                                        options={dropdownOptions}
                                        selectedKey={searchByValue}
                                        onChange={onDropdownChange}
                                        ariaLabel="SearchBy Options"
                                        style={{ width: '200px' }}
                                    />
                                </Stack.Item>
                            <Stack.Item align="end" style={{ display: 'inline-flex', paddingLeft: '10px', position: 'relative', top: '33px' }}>
                                <SearchBox
                                    placeholder="Search"
                                    onEscape={(ev) => { }}
                                    onClear={(ev) => { setSearchError(false); }}
                                    onChange={(_, newValue) => setSearchKeyword(newValue || '')}
                                    onSearch={getSentToFileSetupSearchedItems}
                                    value={searchKeyword}
                                />
                                {searchError && (
                                    <p className={custom_error_msg}>
                                        Please enter valid {searchByText}
                                    </p>
                                )}
                            </Stack.Item>
                            <Stack.Item style={{ display: 'inline-flex', paddingLeft: '10px', position: 'relative', top: '30px' }}>
                                <PrimaryButton
                                    text={'Search'}
                                    onClick={() => getSentToFileSetupSearchedItems()}
                                ></PrimaryButton>
                                {showResetButtom && (
                                    <PrimaryButton style={{marginLeft: '10px'}}
                                        text={'Reset Queue'}
                                        onClick={() => getSentToFileSetupitems()}
                                    ></PrimaryButton>
                                )}
                            </Stack.Item>
                        </Stack.Item>
                    </Stack>
                    <Stack>
                    {queueItems?.length > 0 && (
                        <StackItem>
                            <ShimmeredDetailsList
                                items={queueItemsShow}
                                columns={columns}
                                onColumnHeaderClick={_onColumnClick}
                                onRenderItemColumn={_renderItemColumn}
                                checkboxVisibility={CheckboxVisibility.hidden}
                                ariaLabelForGrid=""
                            />
                        </StackItem>
                    )}
                    {queueItems?.length > 0 && (
                        <StackItem>
                            <Pagination data={queueItems} columns={SentToFileSetupColumns} setData={setQueueItemsShow} />
                        </StackItem>
                    )}
                    {queueItems?.length == 0 && <h2>No Records to display</h2>}
                    {StackDisclaimer()}
                    </Stack>
                </Stack>
            ) : (
                    <UnAuthorized />
                
            )}
        </Stack>
    );

    function _onColumnClick(event: React.MouseEvent<HTMLElement> | undefined, column: IColumn | undefined): void {
        let isSortedDescending = column?.isSortedDescending;
        if (column?.isSorted) {
            isSortedDescending = !isSortedDescending;
        }
        let sortedItems: ISentToFileSetup[] = _copyAndSort(queueItems, column?.fieldName!, isSortedDescending);

        let sortedCol = columns.map((col) => {
            col.isSorted = col.key === column?.key;

            if (col.isSorted) {
                col.isSortedDescending = isSortedDescending;
            }
            return col;
        });
        setQueueItems(sortedItems);
        setColumns(sortedCol);
    }
};