import * as React from 'react';
import { ICoherenceHeaderProps } from "@cseo/controls/lib/header";
import { ComponentContext } from "@employee-experience/common/lib/ComponentContext";
import { HelpPopup } from './Components/HelpPopup/HelpPopup';
import { HelpPopupHeader } from './Components/HelpPopup/HelpPopupHeader';
import { IPanelHeaderRenderer } from '@fluentui/react';

export function useHeaderConfig(): ICoherenceHeaderProps {
    const { telemetryClient: telemetryHook } = React.useContext(ComponentContext);
    const panelHeaderRenderer: IPanelHeaderRenderer = () => {
        return <HelpPopupHeader title="Help" />;
      };
    
    const onClick = () => {
        return true;
    }

    return {
        headerLabel: __APP_NAME__,
        appNameSettings: {
            label: __APP_NAME__
        },        
        farRightSettings: {
            helpSettings: {
                panelSettings: {
                  body: <HelpPopup />,
                  style: { height: '224px', width: '304px', marginRight: '96px', marginLeft: 'auto' },
                  onRenderHeader: panelHeaderRenderer,
                  isBlocking: false,
                },
              },
              feedbackSettings: {
                panelSettings: {
                  ocvButtonIsFocused: false,
                  onClick: onClick,
                  launchOptions: {
                    categories: {
                      show: true,
                      customCategories: ['GMAT', 'Feed', 'Catalog', 'Vision', 'Hearing', 'Mobility', 'Cognitive'],
                    },
                  },
                },
              },
        },
        telemetryHook
    }
}