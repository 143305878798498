import * as React from 'react';
import { usePageTitle } from '@employee-experience/common/lib/usePageTitle';
import { useServiceStore } from '../../RootStateContext';
import {
    DetailsListLayoutMode,
    DetailsRow,
    IColumn,
    IComboBoxOption,
    IDetailsRowBaseProps,
    IDetailsRowStyles,
    IStackItemStyles,
    IStackTokens,
    SelectionMode,
    ShimmeredDetailsList,
    Stack,
    FontIcon,
    mergeStyles,
    mergeStyleSets,
    PrimaryButton,
} from '@fluentui/react';
import { LoadingSpinner } from '../../Components/Shared/LoadingSpinner';
import { ActionType } from '../../Common/Redux/actionTypes';
import { RelocampColumns } from './RelocampColumns';
import { ILoggedInUserRoles, IUserRoles } from '../../Models/IUserRoles';
import { IAppState } from '../../Common/Redux/AppState';
import { IReloCampQueue, IReloCampQueueForm } from '../../Models/IReloCampQueue';
import {
    CoherencePageSize,
    CoherencePagination,
    ICoherencePageSizeProps,
    ICoherencePaginationProps,
} from '@cseo/controls/lib/pagination';
import { TileData } from '../../Components/RelocampQueue/RelocampQueueTable.types';
import { getTileData } from '../../Components/RelocampQueue/TileData';
import { FilterPane } from '../../Components/RelocampQueue/RelocampQueueFilterPane';
import { TilesComponent } from '../../Components/RelocampQueue/TilesComponent';
import * as moment from 'moment';
import { UnAuthorized } from '../../Components/QueueTable/Unauthorized';
import { ReloFormStatusTypes } from '../../types/ReloFormTypes';
import { useContext, useEffect, useRef, useState } from 'react';
import { RelocationStatus } from '../../Components/Shared/Relocation.types';
import { ExportToExcel } from '../../Components/Shared/ExportToExcel';
import { RelocampColumnsPage } from './ReloCampColumnsPage';
import { useHistory } from 'react-router-dom';
import { StackDisclaimer } from '../../Components/Shared/DisclaimerText';
import { ReduxContext } from '@employee-experience/common';

export const ReloCampQueue: React.FC<{}> = () => {
    const history = useHistory();
    const { useSelector, dispatch } = useContext(ReduxContext);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [isPanelOpen, setIsPanelOpen] = useState<boolean>(false);
    const [reloCampQueue, setReloCampQueue] = useState<IReloCampQueueForm[]>([]);
    const [columns, setColumns] = useState<IColumn[]>(RelocampColumns);
    const [columnsPage] = useState<IColumn[]>(RelocampColumnsPage);
    const [currentPageData, setCurrentPageData] = useState<IReloCampQueue[]>([]);
    const [tileData, setTileData] = useState<TileData[]>([]);
    const [IsResetHidded, setIsResetHidded] = React.useState<boolean>(true);
    const userdata: IUserRoles = useSelector((state: IAppState) => state.root.userReducer.userRoles);
    const userRole: ILoggedInUserRoles = useSelector((state: IAppState) => state.root.userReducer.loggedInUserRoles);
    const data = useSelector((state: IAppState) => state.root.relocampReducer.relocampQueues);
    const { relocationQueueService } = useServiceStore();

    var alias = '';
    var role = '';
    const DEFAULT_PAGE_SIZE = 10;
    var totalRecords: number = 0;
    const selectedPage = useRef<number>(1);
    const pageCount = useRef<number>(0);
    const currentPageSize = useRef<number>(DEFAULT_PAGE_SIZE);
    let queueItems: IReloCampQueue[] = [];
    const reloFilterRef = React.useRef<any>();
    const [exportQueueItems, setExportQueueItems] = useState<IReloCampQueue[]>([]);
    function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
        const key = columnKey as keyof T;
        return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    }

    const fetchData = (pageSize: number, newIndex: number = 0): void => {
        totalRecords = queueItems.length;
        let rowData: IReloCampQueue[] = [];
        for (let i = newIndex; i < Math.min(pageSize + newIndex, queueItems.length); ++i) {
            let row: IReloCampQueue = queueItems[i];
            rowData.push(row);
        }
        pageCount.current = Math.ceil(totalRecords / currentPageSize.current);

        setCurrentPageData(rowData);
    };

    const onPageChange = (newPageNumber: number): void => {
        if (newPageNumber !== selectedPage.current) {
            selectedPage.current = newPageNumber;
            fetchData(currentPageSize.current, (newPageNumber - 1) * currentPageSize.current);
        }
    };

    const onPageSizeChange = (newPageSize: string | number): void => {
        currentPageSize.current = newPageSize as number;
        selectedPage.current = 1;
        fetchData(currentPageSize.current);
    };
    const paginationProps: ICoherencePaginationProps = {
        pageCount: pageCount.current,
        selectedPage: selectedPage.current,
        previousPageAriaLabel: 'previous page',
        nextPageAriaLabel: 'next page',
        inputFieldAriaLabel: 'page number',
        onPageChange: onPageChange,
    };
    let pageSizeList: IComboBoxOption[] = [];
    for (let index = 10; index <= 50; index += 10) {
        if (queueItems.length < index) {
            pageSizeList.push({ key: queueItems.length, text: 'ALL' });
            break;
        }
        pageSizeList.push({ key: index, text: index.toString() });
    }

    const paginationPageSizeProps: ICoherencePageSizeProps = {
        pageSize: currentPageSize.current,
        pageSizeList: [
            { key: 10, text: '10' },
            { key: 20, text: '20' },
            { key: 50, text: '50' },
            { key: 100, text: '100' },
            { key: 200, text: '200' },
            { key: 300, text: '300' },
            { key: 400, text: '400' },
            { key: 500, text: '500' },
        ] as IComboBoxOption[],
        comboBoxAriaLabel: 'select page size',
        onPageSizeChange: onPageSizeChange,
    };

    useEffect(() => {
        (async function getReloCampQueue() {
            if (userdata.Alias !== '' && userdata.Roles.length > 0) {
                alias = userdata.Alias;
                role = userdata?.Roles[0];
                if (userRole.GloMoCo && userRole.ReloSpecialist) {
                    role = 'glomoco';
                }
                switch (role) {
                    case 'ReloAdmin':
                        role = 'reloadmin';
                        break;
                    default:
                        role = role;
                }
                let reportType = 'relocamp';
                var queuedata = await relocationQueueService.getReloCampQueueRecords(role, reportType, alias);                
                dispatch({
                    type: ActionType.SET_RELOCAMPQUEUE,
                    data: queuedata,
                });
                setReloCampQueue(queuedata);
                setTileData(getTileData(queuedata));
                setLoaded(true);
            }
        })();
    }, []);

    if (reloCampQueue && reloCampQueue.length > 0) {
        queueItems = reloCampQueue.map((row) => {
            return {
                FullName: row.FullName,
                LatestSubmissionDate: row.SubmittedDate,
                SubmissionDate: row.SubmittedDate,
                SubmittedBy: row.SubmittedBy,
                Destination: row.Destination,
                Departure: row.Departure,
                EstimatedStartDate: row.EstimatedStartDate,
                EstimatedEndDate: row.EstimatedEndDate,
                RelocationType: row.RelocationType,
                RecordID: row.RecordID,
                VisaStatus: row.VisaStatus,
                Status: row.Status,
                CandidateId: row.CandidateID,
                OriginalSubmissionDate: row.OriginalSubmissionDate,
                RecruiterAlias: row.RecruiterAlias,
                CandidateID: row.CandidateID,
                EmployeeId: row.EmployeeId,
                FirstName: row.FirstName,
                LastName: row.LastName,
                EverSendBack: row.EverSendBack,
                CurentEmail: row.CurentEmail,
                DepartureCountry: row.DepartureCountry,
                DepartureCity: row.DepartureCity,
                DepartureStateProvince: row.DepartureStateProvince,
                WorkDestinationCountry: row.WorkDestinationCountry,
                WorkDestinationCity: row.WorkDestinationCity,
                WorkDestinationStateProvince: row.WorkDestinationStateProvince,
                BusinessGroup: row.BusinessGroup,
                CostCenter: row.CostCenter,
                JobLevel: row.JobLevel,
                StaffingManagerAlias: row.HRStaffingGroupAlias,
                HiringManagerAlias: row.HRRecruitingAssociate,
                RelocationSpecialist: row.RelocationSpecialist,
                HireTypeName: row.HireTypeName,
                InternshipTypeID: row.InternshipTypeID,
                InternshipTypeName: row.InternshipTypeName,
                Mach: row.Mach,
                Mba: row.Mba,
                RelocationPolicyName: row.RelocationPolicyName,
                Comments: row.Comments,
            };
        });
    }

    const verticalGapStackTokens: IStackTokens = {
        childrenGap: 10,
        padding: 10,
    };

    const _closePanel = () => {
        setIsPanelOpen(false);
    };
    const normalizeDate = (inputDate: Date) => {
        if (inputDate) {
            return new Date(inputDate.toJSON().split('T')[0]);
        }
        else {
        return "";
        }
    }

    useEffect(() => {
        fetchData(currentPageSize.current);
        if (reloCampQueue && reloCampQueue.length > 0) {
            let tempExportQueueItems = queueItems.map((item) => {
                return {
                    ...item,
                    EstimatedStartDate: item.EstimatedStartDate ?normalizeDate(new Date(item.EstimatedStartDate)) : '',
                    EstimatedEndDate: item.EstimatedEndDate ?normalizeDate(new Date(item.EstimatedEndDate)) : '',
                    OriginalSubmissionDate: item.OriginalSubmissionDate
                        ? new Date(item.OriginalSubmissionDate.split('.')[0])
                        : '',
                    Status: item.Status.toUpperCase() === 'SUBMITTED' ? 'Ready To Initiate' : item.Status,
                };
            });
            setExportQueueItems(tempExportQueueItems);
        } else {
            setExportQueueItems([]);
        }
    }, [reloCampQueue]);

    const stackItemStyles: IStackItemStyles = {
        root: {
            padding: 5,
        },
    };

    usePageTitle(`Relocamp Queue - ${__APP_NAME__}`);

    React.useEffect(() => {
        let inp = document.querySelectorAll('input');
        let btn = document.querySelectorAll('button');
        inp.forEach((item) => {
            item.setAttribute('tabindex', '0');
            if (item.getAttribute('aria-label') === 'page number') {
                item.setAttribute('role', 'textbox');
            }
            if (item.getAttribute('type') === 'checkbox') {
                item.setAttribute('role', 'checkbox');
            }
        });
        btn.forEach((item) => {
            if (item.getAttribute('aria-label') === 'previous page') {
                item.setAttribute('role', 'button');
                item?.parentElement?.parentElement?.classList.add('wrap-btn');
                item?.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.classList.add(
                    'wrap-btn'
                );
            }
            if (item.getAttribute('aria-label') === 'next page') {
                item.setAttribute('role', 'button');
            }
            if (item.getAttribute('aria-hidden') == 'true') {
                item.setAttribute('disabled', 'true');
            }
        });
    });

    return (
        <Stack>
            {!loaded ? (
                <LoadingSpinner />
            ) : (
                <>
                    {userRole.GloMoCo || userRole.ReloAdmin ? (
                        <Stack tokens={verticalGapStackTokens}>
                            <h1>Relocamp Queue</h1>
                            <Stack horizontal tokens={verticalGapStackTokens} verticalAlign="end">
                                {
                                    <Stack.Item align="auto" styles={stackItemStyles}>
                                        <PrimaryButton
                                            label="Open Filter"
                                            text="Open Filter"
                                            onClick={() => {
                                                setIsPanelOpen(true);
                                            }}
                                            ariaLabel="Filter Relocamp Queue"
                                            role="button"
                                            style={{ backgroundColor: 'rgb(0, 120, 212) !important', color: 'white' }}
                                        ></PrimaryButton>
                                        <FilterPane
                                            isOpen={isPanelOpen}
                                            _closePanel={_closePanel}
                                            ref={reloFilterRef}
                                            apiData={data}
                                            handleFilterSubmit={handleFilterSubmit}
                                            ShowResetButton={ShowResetButton}
                                            HideResetButton={HideResetButton}
                                        />
                                    </Stack.Item>
                                }
                                <Stack.Item align="auto" styles={stackItemStyles} hidden={IsResetHidded}>
                                    <PrimaryButton
                                        text="Reset Filter"
                                        onClick={() => {
                                            reloFilterRef?.current?.ResetFilterCallFromMyRelo();
                                            setIsResetHidded(true);
                                        }}
                                        ariaLabel="Filter Relo Queue"
                                        role="button"
                                        tabIndex={0}
                                    ></PrimaryButton>
                                </Stack.Item>
                                <Stack.Item align="auto" styles={stackItemStyles}>
                                    <ExportToExcel
                                        rowsData={exportQueueItems}
                                        colsData={columns}
                                        fileName="ReloCampQueue.xlsx"
                                    />
                                </Stack.Item>
                            </Stack>
                            <Stack style={{}}>
                                {currentPageData !== null && currentPageData !== undefined && (
                                    <Stack>
                                        <Stack>
                                            <Stack.Item>
                                                <TilesComponent
                                                    tileData={tileData}
                                                    apiData={data}
                                                    handleTileFilterSubmit={handleFilterSubmit}
                                                />
                                            </Stack.Item>
                                            <Stack.Item>
                                                <ShimmeredDetailsList
                                                    items={currentPageData || []}
                                                    columns={columnsPage}
                                                    onColumnHeaderClick={_onColumnClick}
                                                    onRenderItemColumn={_renderItemColumn}
                                                    onRenderRow={_onRenderRow}
                                                    onItemInvoked={_onItemInvoked}
                                                    onRenderDetailsHeader={_onRenderDetailsHeader}
                                                    setKey="set"
                                                    layoutMode={DetailsListLayoutMode.justified}
                                                    selectionPreservedOnEmptyClick={true}
                                                    selectionMode={SelectionMode.none}
                                                    ariaLabelForSelectionColumn="Toggle selection"
                                                    ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                                                    checkButtonAriaLabel="select row"
                                                    ariaLabelForGrid="Relo Camp Queue"
                                                />
                                            </Stack.Item>
                                        </Stack>
                                        <Stack
                                            className="pagination-controls"
                                            horizontal
                                            horizontalAlign="end"
                                            styles={{ root: { marginTop: '10px' } }}
                                        >
                                            <Stack
                                                horizontal
                                                horizontalAlign="space-between"
                                                className="ml-sm-0 pg-c-sm"
                                            >
                                                <div className="innr">
                                                    <CoherencePagination {...paginationProps} />
                                                </div>
                                                <CoherencePageSize {...paginationPageSizeProps} />
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                )}
                            </Stack>
                            {StackDisclaimer()}
                        </Stack>
                    ) : (
                        <UnAuthorized />
                    )}
                </>
            )}
        </Stack>
    );

    function _onRenderDetailsHeader(headerProps: any, defaultRender: any) {
        return defaultRender({
            ...headerProps,
            styles: {
                root: {
                    selectors: {
                        '.ms-DetailsHeader-cell': {
                            whiteSpace: 'normal',
                            lineHeight: 'normal',
                        },
                        '.ms-DetailsHeader-cellTitle': {
                            height: '100%',
                            alignItems: 'center',
                            wordWrap: 'break-word',
                        },
                    },
                },
            },
        });
    }

    function handleFilterSubmit(filteredData: any[]) {
        setReloCampQueue(filteredData);
    }
    function ShowResetButton() {
        setIsResetHidded(false);
    }
    function HideResetButton() {
        setIsResetHidded(true);
    }
    function _renderItemColumn(
        item: IReloCampQueueForm | undefined,
        index: number | undefined,
        column: IColumn | undefined
    ) {
        const fieldContent = item?.[column?.fieldName as keyof IReloCampQueueForm] as string;

        const statusClass = mergeStyles({ fontSize: '12px', fontFamily: 'Segoe UI' });

        const iconClass = mergeStyles({
            fontSize: 16,
            height: 16,
            width: 16,
            paddingLeft: 5,
        });

        const classNames = mergeStyleSets({
            submitted: [{ color: '#7BA811' }, iconClass],
            error: [{ color: '#DF0008' }, iconClass],
            warning: [{ color: '#EC9F1D' }, iconClass],
        });

        switch (column?.key) {
            case 'Status':
                let statusFilter = reloCampQueue.map((row) => {
                    if (row.ReadyToReview == 1) {
                        return true;
                    } else {
                        return false;
                    }
                });
                if (item?.Status !== RelocationStatus.SUBMITTED) {
                    return (
                        <Stack horizontal horizontalAlign="start">
                            <span className={statusClass}>{item?.Status}</span>
                            <FontIcon
                                aria-label={item?.Status}
                                iconName="SkypeCircleCheck"
                                className={classNames.submitted}
                            />
                        </Stack>
                    );
                }

                if (
                    (statusFilter[index!] == false || statusFilter[index!] == null) &&
                    item?.Status === RelocationStatus.SUBMITTED
                ) {
                    return (
                        <Stack horizontal horizontalAlign="start">
                            <span className={statusClass}>{RelocationStatus.READYTOREVIEW}</span>
                            <FontIcon
                                aria-label={RelocationStatus.READYTOREVIEW}
                                iconName="WarningSolid"
                                className={`${classNames.warning} checked-icon`}
                            />
                        </Stack>
                    );
                }
                if (statusFilter[index!] == true && item?.Status === RelocationStatus.SUBMITTED) {
                    return (
                        <Stack horizontal horizontalAlign="start">
                            <span className={statusClass}>{RelocationStatus.READYTOINITIATE}</span>
                            <FontIcon
                                aria-label={RelocationStatus.READYTOINITIATE}
                                iconName="WarningSolid"
                                className={`${classNames.warning} checked-icon`}
                            />
                        </Stack>
                    );
                }

            case 'EstimatedStartDate':
                if (item?.EstimatedStartDate === null) {
                    return <span>{fieldContent}</span>;
                } else {
                    return moment(item?.EstimatedStartDate).format('MM/DD/YYYY');
                }

            case 'LatestSubmissionDate':
                if (item?.LatestSubmissionDate === null) {
                    return <span>{fieldContent}</span>;
                } else {
                    return moment(item?.LatestSubmissionDate).format('MM/DD/YYYY');
                }

            default:
                return <span>{fieldContent}</span>;
        }
    }

    function _onRenderRow(props: IDetailsRowBaseProps | undefined, defaultRender: any | undefined) {
        const customStyles: Partial<IDetailsRowStyles> = {};
        let divProps;
        if (props) {
            if (
                props.item?.Status == ReloFormStatusTypes.RESUBMITTED ||
                props.item?.Status == ReloFormStatusTypes.SUBMITTED
            ) {
                customStyles.root = {
                    color: 'blue',
                    selectors: {
                        '.ms-DetailsRow-cell': {
                            whiteSpace: 'normal',
                            lineHeight: 'normal',
                        },
                        '.ms-DetailsRow-cellTitle': {
                            height: '100%',
                            alignItems: 'center',
                            wordWrap: 'break-word',
                        },
                    },
                };
                divProps = {
                    onClick: () => {
                        let obj = JSON.stringify({
                            recordID: props.item?.RecordID,
                            recordStatus: props.item?.Status,
                            ActionType: 'RELOCAMP',
                        });
                        history.push('/ReviewForm', obj);
                    },
                    onKeyDown: (e: any) => {
                        if (e.key === 'Enter') {
                            let parentStatus = props.item?.Status;
                            let obj = JSON.stringify({
                                recordID: props.item?.RecordID,
                                recordStatus: parentStatus,
                                ActionType: 'STAFFING',
                            });
                            history.push('/ReviewForm', obj);
                        }
                    },
                };
            }
            return (
                <DetailsRow {...divProps} {...props} styles={customStyles}>
                    {defaultRender && defaultRender(props)}{' '}
                </DetailsRow>
            );
        }
        return null;
    }
    function _onItemInvoked(item: IReloCampQueueForm | undefined, index: number | undefined) {
        //adding cases
        if (item?.Status === ReloFormStatusTypes.RESUBMITTED || item?.Status === ReloFormStatusTypes.SUBMITTED) {
        }
    }
    function _onColumnClick(event: React.MouseEvent<HTMLElement> | undefined, column: IColumn | undefined): void {
        let isSortedDescending = column?.isSortedDescending;
        if (column?.isSorted) {
            isSortedDescending = !isSortedDescending;
        }
        let sortedItems: IReloCampQueueForm[] = _copyAndSort(reloCampQueue, column?.fieldName!, isSortedDescending);

        let sortedCol = columnsPage.map((col) => {
            col.isSorted = col.key === column?.key;

            if (col.isSorted) {
                col.isSortedDescending = isSortedDescending;
            }
            return col;
        });
        setReloCampQueue(sortedItems);
        setColumns(sortedCol);
    }
};

export default ReloCampQueue;
