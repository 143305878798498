import { IGlomocoeQueueForm } from '../../Models/IGlomocoeQueue';
import { TileData } from './GlomocoeQueueTable.types';

// Creates tile data object
export const getTileData = (apiData: IGlomocoeQueueForm[]): TileData[] => {
    // Industry - Us domestic
    let usDomestic = apiData?.filter((obj) => obj.RelocationTypeId === 8);
    let usDomesticCount = usDomestic != null ? usDomestic.length : 0;

    let usDomesticOwner = apiData?.filter((obj) => obj.RelocationTypeId === 9);
    let usDomesticOwnerCount = usDomesticOwner != null ? usDomesticOwner.length : 0;

    let usDomesticRenter = apiData?.filter((obj) => obj.RelocationTypeId === 10);
    let usDomesticRenterCount = usDomesticRenter != null ? usDomesticRenter.length : 0;

    const usDomesticTotal = usDomesticCount + usDomesticOwnerCount + usDomesticRenterCount;

    // Industry - Inbound
    let usInbound = apiData?.filter((obj) => obj.RelocationTypeId === 14);
    let usInboundCount = usInbound != null ? usInbound.length : 0;

    let usInboundCanada = apiData?.filter((obj) => obj.RelocationTypeId === 15);
    let usInboundCanadaCount = usInboundCanada != null ? usInboundCanada.length : 0;

    let usInboundOwner = apiData?.filter((obj) => obj.RelocationTypeId === 16);
    let usInboundOwnerCount = usInboundOwner != null ? usInboundOwner.length : 0;

    let usInboundRenter = apiData?.filter((obj) => obj.RelocationTypeId === 17);
    let usInboundRenterCount = usInboundRenter != null ? usInboundRenter.length : 0;

    let usInboundUni = apiData?.filter((obj) => obj.RelocationTypeId === 18);
    let usInboundUniCount = usInboundUni != null ? usInboundUni.length : 0;

    const usInboundTotal = usInboundCount + usInboundCanadaCount + usInboundOwnerCount + usInboundRenterCount + usInboundUniCount;

    // Total Industry Count
    const industryTotal = usDomesticTotal + usInboundTotal;

    // Global Non US Domestic
    let nonUsOwner = apiData?.filter((obj) => obj.RelocationTypeId === 29);
    let nonUsOwnerCount = nonUsOwner != null ? nonUsOwner.length : 0;

    let nonUsRenter = apiData?.filter((obj) => obj.RelocationTypeId === 30);
    let nonUsRenterCount = nonUsRenter != null ? nonUsRenter.length : 0;

    let nonUsLtNonExec = apiData?.filter((obj) => obj.RelocationTypeId === 41);
    let nonUsLtNonExecCount = nonUsLtNonExec != null ? nonUsLtNonExec.length : 0;

    const nonUsDomesticCount = nonUsOwnerCount + nonUsRenterCount + nonUsLtNonExecCount;

    // Global Non US Global
    let nonUsGlobalOwner = apiData?.filter((obj) => obj.RelocationTypeId === 32);
    let nonUsGlobalOwnerCount = nonUsGlobalOwner != null ? nonUsGlobalOwner.length : 0;

    let nonUsGlobalRenter = apiData?.filter((obj) => obj.RelocationTypeId === 33);
    let nonUsGlobalRenterCount = nonUsGlobalRenter != null ? nonUsGlobalRenter.length : 0;

    let nonUsGlobalEmpDriven = apiData?.filter((obj) => obj.RelocationTypeId === 50);
    let nonUsGlobalEmpDrivenCount = nonUsGlobalEmpDriven != null ? nonUsGlobalEmpDriven.length : 0;

    let nonUsGlobalReloBasic = apiData?.filter((obj) => obj.RelocationTypeId === 51);
    let nonUsGlobalReloBasicCount = nonUsGlobalReloBasic != null ? nonUsGlobalReloBasic.length : 0;

    const nonUsGlobalCount = nonUsGlobalOwnerCount + nonUsGlobalRenterCount + nonUsGlobalEmpDrivenCount + nonUsGlobalReloBasicCount ;

    // Total Global Count
    const globalTotal = nonUsDomesticCount + nonUsGlobalCount;

    // Global Plus
    let globalPlus = apiData?.filter((obj) => obj.RelocationTypeId === 1);
    let globalPlusCount = globalPlus != null ? globalPlus.length : 0;

    let globalPlusExe = apiData?.filter((obj) => obj.RelocationTypeId === 2);
    let globalPlusExeCount = globalPlusExe != null ? globalPlusExe.length : 0;

    let globalPlusRep = apiData?.filter((obj) => obj.RelocationTypeId === 4);
    let globalPlusRepCount = globalPlusRep != null ? globalPlusRep.length : 0;

    let globalPlusExeRep = apiData?.filter((obj) => obj.RelocationTypeId === 3);
    let globalPlusExeRepCount = globalPlusExeRep != null ? globalPlusExeRep.length : 0;

    const globalPlusTotal = globalPlusCount + globalPlusExeCount + globalPlusRepCount + globalPlusExeRepCount;

    // Executive
    let exeSilver = apiData?.filter((obj) => obj.RelocationTypeId === 11);
    let exeSilverCount = exeSilver != null ? exeSilver.length : 0;

    let exePlatinum = apiData?.filter((obj) => obj.RelocationTypeId === 7);
    let exePlatinumCount = exePlatinum != null ? exePlatinum.length : 0;

    let exeInternational = apiData?.filter((obj) => obj.RelocationTypeId === 5);
    let exeInternationalCount = exeInternational != null ? exeInternational.length : 0;

    let ltExec= apiData?.filter((obj) => obj.RelocationTypeId === 42);
    let ltExecCount = ltExec != null ? ltExec.length : 0;

    // Executive Total Count
    const executiveTotal = exeSilverCount + exePlatinumCount + exeInternationalCount + ltExecCount;

    // Visiting
    let visitingArt = apiData?.filter((obj) => obj.RelocationTypeId === 20);
    let visitingArtCount = visitingArt != null ? visitingArt.length : 0;

    let visitingRes = apiData?.filter((obj) => obj.RelocationTypeId === 21);
    let visitingResCount = visitingRes != null ? visitingRes.length : 0;

    // Visiting Total Count
    const visitingTotal = visitingArtCount + visitingResCount;

    // Pre Hire Type Records
    let preHireType = apiData?.filter((obj) => obj.HireTypeID === 7);
    let preHireTypeCount = preHireType != null ? preHireType.length : 0;

    // Lump Sum Alternative    
    //Lump Sum US 
    let lumpSumUSDomInd = apiData?.filter((obj) => obj.RelocationTypeId === 22);
    let lumpSumUSDomIndCount = (lumpSumUSDomInd != null) ? lumpSumUSDomInd.length : 0;
    
    let lumpSumUSDomUniv = apiData?.filter((obj) => obj.RelocationTypeId === 24);
    let lumpSumUSDomUnivCount = (lumpSumUSDomUniv != null) ? lumpSumUSDomUniv.length : 0;
    
    let lumpSumUSDomUnivMBA = apiData?.filter((obj) => obj.RelocationTypeId === 25);
    let lumpSumUSDomUnivMBACount = (lumpSumUSDomUnivMBA != null) ? lumpSumUSDomUnivMBA.length : 0;
    
    let lumpSumUSGlobUniv =apiData?.filter((obj) => obj.RelocationTypeId === 28);
    let lumpSumUSGlobUnivCount = (lumpSumUSGlobUniv != null) ? lumpSumUSGlobUniv.length : 0;
    
    const lumpSumUSTotal = lumpSumUSDomIndCount + lumpSumUSDomUnivCount + lumpSumUSDomUnivMBACount + lumpSumUSGlobUnivCount;
    
    //Lump Sum Non US
    let lumpSumNonUSDomInd =apiData?.filter((obj) => obj.RelocationTypeId === 44);
    let lumpSumNonUSDomIndCount = (lumpSumNonUSDomInd != null) ? lumpSumNonUSDomInd.length : 0;
    
    let lumpSumNonUSDomUniv = apiData?.filter((obj) => obj.RelocationTypeId === 45);
    let lumpSumNonDomUnivCount = (lumpSumNonUSDomUniv != null) ? lumpSumNonUSDomUniv.length : 0;
    
    let lumpSumNonUSGlobUniv = apiData?.filter((obj) => obj.RelocationTypeId === 47);
    let lumpSumNonUSGlobUnivCount = (lumpSumNonUSGlobUniv != null) ? lumpSumNonUSGlobUniv.length : 0;
    
    let lumpSumNonUSDom = apiData?.filter((obj) => obj.RelocationTypeId === 48);
    let lumpSumNonUSDomvCount = (lumpSumNonUSDom != null) ? lumpSumNonUSDom.length : 0;
    
    let lumpSumNonUSGlob = apiData?.filter((obj) => obj.RelocationTypeId === 49);
    let lumpSumNonUSGlobCount = (lumpSumNonUSGlob != null) ? lumpSumNonUSGlob.length : 0;
    
    const lumpSumNonUSTotal = lumpSumNonUSDomIndCount + lumpSumNonDomUnivCount + lumpSumNonUSGlobUnivCount + lumpSumNonUSDomvCount + lumpSumNonUSGlobCount;
    
    const lumpSumTotal = lumpSumUSTotal + lumpSumNonUSTotal;

    let payrollUs = apiData?.filter((obj) => obj.RelocationTypeId === 54);
    let payrollUsCount = (payrollUs != null) ? payrollUs.length : 0;

    let payrollNonUs = apiData?.filter((obj) => obj.RelocationTypeId === 55);
    let payrollNonUsCount = (payrollNonUs != null) ? payrollNonUs.length : 0;

    const payrollSumTotal = payrollUsCount + payrollNonUsCount;
    // ----- create tile data object -----
    const objItems: TileData[] = [];

    // Industry
    let industryObj = {
        header: 'Industry',
        totalCount: industryTotal,
        child1: {
            totalCountData: 9,
            relTypeDataLabelId: 2,
            relTypeData1: usDomesticTotal + ' US Domestic',
        },
        child: [
            {
                relTypeDataLabelId: 4,
                relTypeData: usInboundTotal + ' US Inbound',
            },
        ],
    };
    objItems.push(industryObj);

    // Global
    let globalObj = {
        header: 'Global',
        totalCount: globalTotal,
        child1: {
            totalCountData: 10,
            relTypeDataLabelId: 1,
            relTypeData1: nonUsDomesticCount + ' Non US Domestic',
        },
        child: [
            {
                relTypeDataLabelId: 3,
                relTypeData: nonUsGlobalCount + ' Non US Global',
            },
        ],
    };
    objItems.push(globalObj);

    // Global Plus
    let globalPlusObj = {
        header: 'Global Plus',
        totalCount: globalPlusTotal,
        child1: {
            totalCountData: 6,
        },
    };
    objItems.push(globalPlusObj);

    // Executive
    let executiveObj = {
        header: 'Executive',
        totalCount: executiveTotal,
        child1: {
            totalCountData: 13,
            relTypeDataLabelId: 11,
            relTypeData1: exeSilverCount + ' Silver',
        },
        child: [
            {
                relTypeDataLabelId: 7,
                relTypeData: exePlatinumCount + ' Platinum',
            },
            {
                relTypeDataLabelId: 5,
                relTypeData: exeInternationalCount + ' Silver International',
            },
        ],
    };
    objItems.push(executiveObj);

    // Visiting
    let visitingObj = {
        header: 'Visiting Artist/Researcher',
        totalCount: visitingTotal,
        child1: {
            totalCountData: 12,
            relTypeDataLabelId: 20,
            relTypeData1: visitingArtCount + ' Artist',
        },
        child: [
            {
                relTypeDataLabelId: 21,
                relTypeData: visitingResCount + ' Researcher',
            },
        ],
    };
    objItems.push(visitingObj);

    // Pre Hire Type
    let preHireObj = {
        header: 'Pre Hire',
        totalCount: preHireTypeCount,
        child1: {
            totalCountData: 200,
            relTypeDataLabelId: 200,
            relTypeData1: preHireTypeCount + ' Pre Hire Services',
        },
    };
    objItems.push(preHireObj);

    // Lump Sum Alternative
    let lumpSumObj = {
        header: 'Lump Sum Alternative',
        totalCount: lumpSumTotal,
        child1: {
            totalCountData: 8,
            relTypeDataLabelId: 14,
            relTypeData1: lumpSumUSTotal + ' US Lump Sum',
        },
        child: [
            {
                relTypeDataLabelId: 15,
                relTypeData: lumpSumNonUSTotal + ' Non US Lump Sum',
            },            
        ],
    };
    objItems.push(lumpSumObj);

    // Payroll Compliance
    let payrollComplianceObj = {
        header: 'Payroll Compliance',
        totalCount: payrollSumTotal,
        child1: {
            totalCountData: 16,
            relTypeDataLabelId: 17,
            relTypeData1: payrollUsCount + ' US Payroll Compliance',
        },
        child: [
            {
                relTypeDataLabelId: 18,
                relTypeData: payrollNonUsCount + ' Non-US Payroll Compliance',
            },            
        ],
    };
    objItems.push(payrollComplianceObj);    

    return objItems;
};
