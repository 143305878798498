import { IColumn } from '@fluentui/react';

export const ReloColumns: IColumn[] = [
    {
        key: 'RecordID',
        name: 'GMAT Record ID',
        fieldName: 'RecordID',
        minWidth: 80,
    },
    {
        key: 'CandidateID',
        name: 'CANDIDATE ID',
        fieldName: 'CandidateID',
        minWidth: 80,
    },
    {
        key: 'EmployeeId',
        name: 'EMPLOYEE ID',
        fieldName: 'EmployeeId',
        minWidth: 120,
    },
    {
        key: 'FirstName',
        name: 'FIRST NAME',
        fieldName: 'FirstName',
        minWidth: 120,
    },
    {
        key: 'LastName',
        name: 'LAST NAME',
        fieldName: 'LastName',
        minWidth: 120,
    },
    {
        key: 'Status',
        name: 'Status',
        fieldName: 'Status',
        minWidth: 125,
    },
    {
        key: 'OriginalSubmissionDate',
        name: 'ORIGINAL SUBMISSION DATE',
        fieldName: 'OriginalSubmissionDate',
        minWidth: 125,
    },
    {
        key: 'SubmittedDate',
        name: 'LATEST RESUBMISSION DATE',
        fieldName: 'SubmittedDate',
        minWidth: 125,
    },
    {
        key: 'EstimatedStartDate',
        name: 'ESTIMATED START DATE',
        fieldName: 'EstimatedStartDate',
        minWidth: 125,
    },
    {
        key: 'EverSendBack',
        name: 'EVER SEND BACK',
        fieldName: 'EverSendBack',
        minWidth: 125,
    },
    {
        key: 'CurentEmail',
        name: 'CURRENT EMAIL',
        fieldName: 'CurentEmail',
        minWidth: 125,
    },
    {
        key: 'VisaStatus',
        name: 'VISA PENDING',
        fieldName: 'VisaStatus',
        minWidth: 125,
    },
    {
        key: 'DepartureCountry',
        name: 'DEPARTURE COUNTRY',
        fieldName: 'DepartureCountry',
        minWidth: 125,
    },
    {
        key: 'DepartureCity',
        name: 'DEPARTURE CITY',
        fieldName: 'DepartureCity',
        minWidth: 125,
    },
    {
        key: 'DepartureStateProvince',
        name: 'DEPARTURE STATE/PROVINCE',
        fieldName: 'DepartureStateProvince',
        minWidth: 125,
    },
    {
        key: 'WorkDestinationCountry',
        name: 'DESTINATION COUNTRY',
        fieldName: 'WorkDestinationCountry',
        minWidth: 125,
    },
    {
        key: 'WorkDestinationCity',
        name: 'DESTINATION CITY',
        fieldName: 'WorkDestinationCity',
        minWidth: 125,
    },
    {
        key: 'WorkDestinationStateProvince',
        name: 'DESTINATION STATE/PROVINCE',
        fieldName: 'WorkDestinationStateProvince',
        minWidth: 125,
    },
    {
        key: 'BusinessGroup',
        name: 'BUSINESS GROUP',
        fieldName: 'BusinessGroup',
        minWidth: 125,
    },
    {
        key: 'CostCenter',
        name: 'COST CENTER',
        fieldName: 'CostCenter',
        minWidth: 125,
    },
    {
        key: 'JobLevel',
        name: 'JOB LEVEL',
        fieldName: 'JobLevel',
        minWidth: 125,
    },
    {
        key: 'StaffingManagerAlias',
        name: 'E-FORM SUBMITTER MANAGER ALIAS',
        fieldName: 'StaffingManagerAlias',
        minWidth: 125,
    },
    {
        key: 'RecruitingAssociateUserName',
        name: 'E-FORM SUBMITTER ALIAS',
        fieldName: 'RecruitingAssociateUserName',
        minWidth: 125,
    },
    {
        key: 'HiringManagerAlias',
        name: 'HIRING MANAGER ALIAS',
        fieldName: 'HiringManagerAlias',
        minWidth: 125,
    },
    {
        key: 'RecruiterAlias',
        name: 'RECRUITER ALIAS',
        fieldName: 'RecruiterAlias',
        minWidth: 125,
    },
    {
        key: 'RelocationSpecialist',
        name: 'RELOCATION SPECIALIST',
        fieldName: 'RelocationSpecialist',
        minWidth: 125,
    },
    {
        key: 'HireTypeName',
        name: 'HIRE TYPE',
        fieldName: 'HireTypeName',
        minWidth: 125,
    },
    {
        key: 'InternshipTypeName',
        name: 'INTERNSHIP TYPE',
        fieldName: 'InternshipTypeName',
        minWidth: 125,
    },
    {
        key: 'Mach',
        name: 'MACH',
        fieldName: 'Mach',
        minWidth: 125,
    },
    {
        key: 'Mba',
        name: 'MBA',
        fieldName: 'Mba',
        minWidth: 125,
    },
    {
        key: 'RelocationType',
        name: 'RELOCATION TYPE',
        fieldName: 'RelocationType',
        minWidth: 125,
    },
    {
        key: 'RelocationPolicyName',
        name: 'RELOCATION POLICY',
        fieldName: 'RelocationPolicyName',
        minWidth: 125,
    },
    {
        key: 'Comments',
        name: 'COMMENTS',
        fieldName: 'Comments',
        minWidth: 125,
    },
];
