/* eslint-disable prettier/prettier */
import { IColumn } from '@fluentui/react';
export const ReassignColumnsPage: IColumn[] = [
        {
            key: 'ReloId',
            name: 'Relocation ID',
            fieldName: 'ReloId',
            data: 'string',
            minWidth: 100,
            maxWidth: 200,
        },
        {
            key: 'OldAlias',
            name: 'Old Alias',
            fieldName: 'OldAlias',
            data: 'string',
            minWidth: 100,
            maxWidth: 200,
        },
        {
            key: 'NewAlias',
            name: 'New Alias',
            fieldName: 'NewAlias',
            data: 'string',
            minWidth: 100,
            maxWidth: 200,
        },
        {
            key: 'Result',
            name: 'Result',
            fieldName: 'Result',
            data: 'Result',
            minWidth: 100,
            maxWidth: 200,
        },
];
