import * as React from 'react';
import {
    PrimaryButton,
    Modal,
    mergeStyleSets,
    getTheme,
    FontWeights,
} from '@fluentui/react';
import {
    BulkCommitWarningModalProps,
} from '../../Models/IEstimatesForm';
import { useServiceStore } from '../../RootStateContext';

// --------- Styles ----------
const theme = getTheme();
const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
    },
    header: [
        // eslint-disable-next-line deprecation/deprecation
        theme.fonts.xLargePlus,
        {
            flex: '1 1 auto',
            borderTop: `4px solid orange`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});
const buttonStyles = {
    backgroundColor: 'rgb(0, 120, 212)',
    color: '#FFFFFF',
    marginBottom: '10px',
    marginRight: '10px'
};

export const BulkCommitWarningModal: React.FC<BulkCommitWarningModalProps> = (props) => {


    const { estimatesQueueService } = useServiceStore();

    const onBulkCommit = async () => {
        estimatesQueueService.UpdateBulkCommitStatus(props.selectedBulkCommitId, 'COMMITTED')
            .then((result) => { })
            .finally(() => props.toggleBulkCommitModal('YES'));
    };

    // const onDeleteEForm = async (ReloID: number) => {
    //     let isRowDeleted = false;
    //     isRowDeleted = await deleteEForm(ReloID);

    //     if (isRowDeleted) {
    //         props.toggleDeleteWarningModal(false);
    //         props.toggleDeleteFormFeedbackModal(true);
    //     };
    // };

    return (
        <>
            <Modal
                titleAriaId='title'
                isOpen={props.isModalOpen}
                containerClassName={contentStyles.container}
            >
                <>
                    <div className={contentStyles.header}>
                        <span style={{ color: '#7E511B', fontWeight: 'bold', paddingRight: 10 }}>Warining!</span>

                    </div>

                    <div className={contentStyles.body}>
                        <p>Are you sure you want to bulk commit following files?</p>
                        <span>{props.selectedFileIds}</span>

                        <div style={{ marginTop: 20 }}>
                            <PrimaryButton
                                text="Yes"
                                onClick={onBulkCommit}
                                allowDisabledFocus
                                disabled={false}
                                style={buttonStyles}
                            />
                            <PrimaryButton
                                text="No"
                                onClick={() => props.toggleBulkCommitModal('NO')}
                                allowDisabledFocus
                                disabled={false}
                                style={{ marginRight: 10 }}
                            />

                        </div>
                    </div>
                </>
            </Modal>
        </>)
}