import * as React from 'react';
import { LoadingSpinner } from '../../Components/Shared/LoadingSpinner';
import {
    Stack,
    mergeStyleSets,
    FontWeights,
    getTheme,
    Spinner,
    SpinnerSize,
    MessageBar,
    MessageBarType,
    PrimaryButton,
    SearchBox,
    IStackTokens,
    Text,
    FontIcon,
    Dropdown,
    mergeStyles,
    IStackStyles,
    IDropdownOption,
} from '@fluentui/react';
import { IUserRoles, ILoggedInUserRoles } from '../../Models/IUserRoles';
import { ReduxContext } from '@employee-experience/common';
import { IAppState } from '../../Common/Redux/AppState';
import * as moment from 'moment';
import {
    EstimateProfileData,
    JobLevelTaxRates,
    AllExceptionsEntity,
    TaxAmountEntity,
    RelocationType,
    FileExceptionProps,
    RelocationForm,
    CoreBenefitsEntity,
    EstimatesFormProps,
    EmployeeProfileData,
    JobLevelOptions,
    RelocationPolicyEntity,
    UpdateAuthFEProps,
    EstimateDetailsProps,
    PackageValueProps,
    CustoRefEmployeeProfileProps,
    CustoRefEstimateProfileProps
} from '../../Models/IEstimatesForm';
import { OptionalBenefitMapping } from '../../Models/IReviewForm';
import { useServiceStore } from '../../RootStateContext';
import { EstimateProfile } from './EstimateProfile';
import { EmployeeProfile } from './EmployeeProfile';
import { RelocationPackage } from './RelocationPackage';
import { OfferExceptions } from './OfferExceptions';
import { PostOfferExceptions } from './PostOfferExceptions';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { UnAuthorized } from '../../Components/QueueTable/Unauthorized';

const theme = getTheme();
const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        width: '100em',
    },
    header: [
        theme.fonts.xLargePlus,
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 0 0 0',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
    status: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: '10px',
    },
});
const custom_error_msg = mergeStyles({
    color: 'crimson',
    fontSize: '12.6px',
    marginTop: '5px',
    marginBottom: '0px',
    fontWeight: 'bold',
});
const verticalGapStackTokens: IStackTokens = {
    childrenGap: 10,
    padding: 10,
};
const iconClass = mergeStyles({
    fontSize: 25,
    cursor: 'pointer',
    padding: '4px 4px',
    borderRadius: '2px',
    width: '32px',
    height: '32px',
    backgroundColor: 'transparent',
    color: 'rgb(0, 120, 212)',
});
const stackItemStyles: IStackStyles = {
    root: {
        padding: 5,
    },
};

export const EstimatesForm: React.FC<EstimatesFormProps> = (props) => {
    const currentURL = useLocation();
    const history = useHistory();
    if (currentURL.state == undefined || currentURL.state == null || currentURL.state == '') {
        history.push('/');
    }

    props = JSON.parse(currentURL.state as string);

    const [commitStatus, setCommitStatus] = React.useState<string>(props.committedStatus == 'No Action' ? 'UNCOMMITTED' : props.committedStatus);
    const [pendingAddOfferExceptions, setPendingAddOfferExceptions] = React.useState<FileExceptionProps[]>([]);

    // References to get updated values from different components
    const employeeProfileRef = React.useRef<CustoRefEmployeeProfileProps | null | undefined>();
    const estimateProfileRef = React.useRef<CustoRefEstimateProfileProps | null>();
    const relocationPackageRef = React.useRef<CustoRefEstimateProfileProps | null>();

    // Variables for getting information about the logged in user
    const { useSelector } = React.useContext(ReduxContext);
    const userData: IUserRoles = useSelector((state: IAppState) => state.root.userReducer.userRoles);
    const userRole: ILoggedInUserRoles = useSelector((state: IAppState) => state.root.userReducer.loggedInUserRoles);
    // Flags for keeping track of page handling
    const [isLoaded, setIsLoaded] = React.useState<boolean>(false);
    const [isUpdating, setIsUpdating] = React.useState<boolean>(false);
    const [isRedirect, setIsRedirect] = React.useState<boolean>(false);
    const [isEditMode, setIsEditMode] = React.useState<boolean>(false);

    // Flags for keeping track of when components should be shown or not
    const [isLumpSum, setIsLumpSum] = React.useState<boolean>(false);
    const [isPolicyChanged, setIsPolicyChanged] = React.useState<boolean>(false);
    const [isForceCommitSuccessful, setIsForceCommitSuccessful] = React.useState<boolean>(false);
    const [isUncommitSuccessful, setIsUncommitSuccessful] = React.useState<boolean>(false);
    const [isForceCommitImpossible, setIsForceCommitImpossible] = React.useState(false);
    const [isUpdateSuccessful, setIsUpdateSuccessful] = React.useState<boolean>(false);
    const [arePostOfferExceptionsAllowed, setArePostOfferExceptionsAllowed] = React.useState<boolean>(true);

    // Services and variables for their responses
    const { pendingExceptionQueueService, estimatesFormService, startNewReloRequestService } = useServiceStore();
    const [relocationFormResponse, setRelocationFormResponse] = React.useState<RelocationForm>({} as RelocationForm);
    const [estimateProfileResponse, setEstimateProfileResponse] = React.useState<EstimateProfileData>({} as EstimateProfileData);
    const [estimateRecordsResponse, setEstimateRecordsResponse] = React.useState<EmployeeProfileData>({} as EmployeeProfileData);
    const [relocationTypeResponse, setRelocationTypeResponse] = React.useState<RelocationType>({} as RelocationType);
    const [coreBenefitsResponse, setCoreBenefitsResponse] = React.useState<CoreBenefitsEntity[]>([]);
    const [jobLevelInfoResponse, setJobLevelInfoResponse] = React.useState<JobLevelOptions[]>([]);
    const [taxAmountsResponse, setTaxAmountsResponse] = React.useState<TaxAmountEntity[]>([]);
    const [relocationPoliciesResponse, setRelocationPoliciesResponse] = React.useState<RelocationPolicyEntity[]>([]);
    const [activeOfferExceptionsResponse, setActiveOfferExceptionsResponse] = React.useState<FileExceptionProps[]>([]);
    const [activePostOfferExceptionsResponse, setActivePostOfferExceptionsResponse] = React.useState<FileExceptionProps[]>([]);
    const [packageValueResponse, setPackageValueResponse] = React.useState<PackageValueProps>();

    const [allExceptionsResponse, setAllExceptionsResponse] = React.useState<AllExceptionsEntity[]>([]);
    const [allOptionalBenefitsResponse, setAllOptionalBenefitsResponse] = React.useState<OptionalBenefitMapping[]>([]);
    const [allActiveExceptionsResponse, setAllActiveExceptionsResponse] = React.useState<AllExceptionsEntity[]>([]);
    const [allJobLevelTaxRatesResponse, setAllJobLevelTaxRatesResponse] = React.useState<JobLevelTaxRates[]>([]);

    // Variables for searching for other relocation forms (will only ben shown when redirected from ReloSpecialist Queue)
    const [selectedSearchByValue, setSelectedSearchByValue] = React.useState<string>('Search by');
    const [searchByKeyword, setSearchByKeyword] = React.useState<string>('');
    const [isSearchByErrorCaught, setIsSearchByErrorCaught] = React.useState<boolean>(false);

    // Variables for calculating various costs of relocating
    const [jobLevelTaxRate, setJobLevelTaxRate] = React.useState<number>(0);
    const [totalEstimate, setTotalEstimate] = React.useState<string>('');
    const [corePackageValue, setCorePackageValue] = React.useState<number>(0);
    const [totalPackageValue, setTotalPackageValue] = React.useState<number>(0);
    const [offerExceptionsTotalEstimate, setOfferExceptionsTotalEstimate] = React.useState<number>(0);
    const [postOfferExceptionsTotalEstimate, setPostOfferExceptionsTotalEstimate] = React.useState<number>(0);
    const [relocationTypeOptions, setRelocationTypeOptions] = React.useState<RelocationType[]>([]);

    const dropdownOptions = [
        { key: 'fileID', text: 'File ID' },
        { key: 'recordID', text: 'Record ID' },
        { key: 'employeeNumber', text: 'Employee Number' },
        { key: 'employeeName', text: 'Employee Name' },
    ];

    enum searchByOptions {
        fileID = 1,
        recordID = 2,
        employeeNumber = 3,
        employeeName = 4,
    }

    React.useEffect(() => {
        getEstimateProfileResponse();
    }, []);

    React.useEffect(() => {
        getEstimatesFormData();
    }, [estimateProfileResponse]);

    const [selectedRelocationType, setSelectedRelocationType] = React.useState<string | undefined>(undefined);
    const [selectedRelocationTypeId, setSelectedRelocationTypeId] = React.useState<string | undefined>(undefined);
    const [isLimitedBenefits, setIsLimitedBenefits] = React.useState<boolean>(false);
    const [isChangesCancelled, setIsChangesCancelled] = React.useState<boolean>(false);

    const onRelocationTypeChange = async (newValue: any, changedDetails: any) => {
        setIsUpdating(true);
        setSelectedRelocationType(newValue.text);
        setSelectedRelocationTypeId(newValue.key)
        if (newValue.text === 'Limited Benefits') {
            setIsLimitedBenefits(true)
        } else {
            setIsLimitedBenefits(false)
        }
        const allTaxRates = await getAllJobLevelTaxRatesResponse();

        const taxRate = getJobLevelTaxRate(
            relocationFormResponse?.JobLevelID,
            changedDetails?.HireTypeID,
            allTaxRates
        );
        updateIsLumpSum(changedDetails?.IsLumpSum);
        updateArePostOfferExceptionsAllowed(changedDetails?.ReloPolicyType);

        await getLumpSumRelocationTypes(relocationFormResponse?.FiscalYear);
        await getEstimateRecordsResponse(relocationFormResponse?.EmployeeID);
        await getJobLevelInformation();
        await getCoreBenefitsResponse(
            relocationFormResponse?.FiscalYear,
            changedDetails?.RelocationpolicyTypeID,
            taxRate
        );
        await getAllExceptionsResponse(
            changedDetails?.HireTypeID,
            changedDetails?.RelocationpolicyTypeID);
        await getTaxAmountsResponse(
            changedDetails?.HireTypeID,
            relocationFormResponse?.JobLevelID,
            relocationFormResponse?.ReloID,
            changedDetails?.RelocationpolicyTypeID
        );
        if (newValue.text !== 'US Remote Internship') {
            await getPackageValueResponse(
                relocationFormResponse?.FiscalYear,
                relocationFormResponse?.JobLevelID,
                changedDetails?.RelocationpolicyTypeID,
                relocationFormResponse?.PackageValue,
                relocationFormResponse?.CommitStatus
            );
        }

        await getRelocationPoliciesResponse(changedDetails?.HireTypeID);
        await getAllFileExceptions(
            changedDetails?.HireTypeID,
            relocationFormResponse?.ReloID,
            changedDetails?.RelocationpolicyTypeID,
            props?.fileId
        );
        await getAllActiveExceptionsResponse(changedDetails?.RelocationTypeID);
        await getAllOptionalBenefitsResponse(changedDetails?.RelocationpolicyTypeID, changedDetails?.ReloPolicyType, taxRate);
        setIsPolicyChanged(true);
        setIsUpdating(false);
    }

    React.useEffect(() => {
        let buttons = document.querySelectorAll('button');
        buttons.forEach((button) => {
            button.setAttribute('role', 'button');
            button.setAttribute('tabindex', '0');
            if (button.hasAttribute("aria-posinset")) {
                button.setAttribute("role", "menuitem");
            }
        })
        let gridDivs = document.querySelectorAll("div[role='grid']");
        gridDivs.forEach((gridDiv) => {
            gridDiv.setAttribute('title', 'table data');
        })
    });

    React.useEffect(() => {
        let inputs = document.querySelectorAll('input');
        inputs.forEach((input) => {
            if (input.getAttribute('type') === 'number') {
                input.setAttribute('aria-label', 'textbox');
                input.setAttribute('role', 'textbox');
                input.setAttribute('tabindex', '0');
            }
        });
    });

    React.useEffect(() => {
        const packageValue = getTotalPackageValue();
        updateTotalEstimate(packageValue);
    }, [
        isLumpSum,
        jobLevelTaxRate,
        corePackageValue,
        relocationFormResponse?.LumpSumRelocation,
        relocationFormResponse?.RelocationCashAmount,
        relocationFormResponse?.OptionalBenefitMapping,
    ]);

    React.useEffect(() => {
        let newOfferExceptionsTotalEstimate: number = Number(totalPackageValue);
        activeOfferExceptionsResponse?.forEach((item) => {
            if (item.ExceptionAction != 'R' && item.ExceptionAction != 'Rejected') {
                newOfferExceptionsTotalEstimate += Number(Number(item.TotalAmount).toFixed(2));
            }
        });
        pendingAddOfferExceptions?.forEach((item) => {
            if (item.ExceptionAction != 'R' && item.ExceptionAction != 'Rejected') {
                newOfferExceptionsTotalEstimate += Number(Number(item.TotalAmount).toFixed(2));
            }
        });

        let newPostOfferExceptionsTotalEstimate = newOfferExceptionsTotalEstimate;
        activePostOfferExceptionsResponse?.forEach((item) => {
            if (item.ExceptionAction != 'R' && item.ExceptionAction != 'Rejected') {
                newPostOfferExceptionsTotalEstimate += Number(Number(item.TotalAmount).toFixed(2));
            }
        });
        setOfferExceptionsTotalEstimate(newOfferExceptionsTotalEstimate);
        setPostOfferExceptionsTotalEstimate(newPostOfferExceptionsTotalEstimate);
    }, [totalPackageValue, activeOfferExceptionsResponse, activePostOfferExceptionsResponse, pendingAddOfferExceptions]);

    async function getEstimateProfileResponse() {
        if (userData.Alias !== '' && userData.Roles.length > 0) {
            let estimatesProfileData = await estimatesFormService.getEstimatesFormData(props.fileId, props.reloId)
            setEstimateProfileResponse(estimatesProfileData[0]);
        }
    };

    async function getEstimatesFormData() {
        if (estimateProfileResponse !== null && estimateProfileResponse !== undefined) {
            const relocationForm = await getRelocationFormResponse();
            updateCommitStatus(relocationForm?.CommitStatus);

            const allTaxRates = await getAllJobLevelTaxRatesResponse();
            const taxRate = getJobLevelTaxRate(
                relocationForm?.JobLevelID,
                relocationForm?.HireTypeID,
                allTaxRates
            );

            const allRelocationTypes = await getAllRelocationTypesResponse(relocationForm?.HireTypeID,
                relocationForm?.RelocationPolicyID);
            const relocationType = await getRelocationTypeResponse(
                relocationForm?.HireTypeID,
                relocationForm?.RelocationPolicyID,
                relocationForm?.RelocationpolicyTypeID,
                allRelocationTypes
            );
            updateIsLumpSum(relocationType?.IsLumpSum);
            updateIdLimitedBenefits(relocationType?.RelocationTypeName);
            updateArePostOfferExceptionsAllowed(relocationType?.ReloPolicyType);
            await getEstimateRecordsResponse(relocationForm?.EmployeeID);
            await getJobLevelInformation();
            await getCoreBenefitsResponse(
                relocationForm?.FiscalYear,
                relocationForm?.RelocationpolicyTypeID,
                taxRate
            );
            await getAllExceptionsResponse(
                relocationForm?.HireTypeID,
                relocationForm?.RelocationpolicyTypeID);
            await getTaxAmountsResponse(
                relocationForm?.HireTypeID,
                relocationForm?.JobLevelID,
                relocationForm?.ReloID,
                relocationForm?.RelocationpolicyTypeID
            );
            await getPackageValueResponse(
                relocationForm?.FiscalYear,
                relocationForm?.JobLevelID,
                relocationForm?.RelocationpolicyTypeID,
                relocationForm?.PackageValue,
                relocationForm?.CommitStatus
            );
            await getRelocationPoliciesResponse(relocationForm?.HireTypeID);
            await getAllFileExceptions(
                relocationForm?.HireTypeID,
                relocationForm?.ReloID,
                relocationForm?.RelocationpolicyTypeID,
                props?.fileId
            );
            await getAllActiveExceptionsResponse(relocationType?.RelocationTypeID);
            if (relocationType?.RelocationTypeName !== 'Limited Benefits') {
                await getAllOptionalBenefitsResponse(relocationForm?.RelocationpolicyTypeID, relocationType?.ReloPolicyType, taxRate);
            }

            setIsLoaded(true);
        }
    };

    async function getLumpSumRelocationTypes(fiscalYear: number) {
        //This method will only run when you update relocation type.
        const lumpSumReloTypes = await startNewReloRequestService.GetLumpSumRelocationTypes(fiscalYear); //Update API call to only 
        const { values: estimateProfileValues } = await estimateProfileRef?.current?.validateForm(); //
        const lumpSumReloType = lumpSumReloTypes.filter(lumpSumType => lumpSumType.RelocationTypeID === Number(estimateProfileValues.RelocationpolicyTypeID) && lumpSumType.RelocationPolicyID == estimateProfileValues.RelocationPolicyID)[0];
        if (lumpSumReloType == null || lumpSumReloType == undefined) {
            return
        }
        let netCashValue = Number(lumpSumReloType.NetCash.replace(/\D+/g, ''));
        let estimateDetails: RelocationForm = {
            ...relocationFormResponse,
            LumpSumRelocation: {
                FileFee: lumpSumReloType.FileFee,
                NetCash: netCashValue,
                TaxRate: lumpSumReloType.TaxRateForLumpSum,
                AccrualCostCenter: getTaxGrossUp(netCashValue, lumpSumReloType.TaxRateForLumpSum),
                TotalPackageValue: Number(lumpSumReloType.AccrualCostCenter.replace(/\D+/g, '')) + lumpSumReloType.FileFee,
            }
        }
        setRelocationFormResponse(estimateDetails);
    }

    async function getRelocationFormResponse() {
        const relocationForm = (await estimatesFormService.getRelocationForm(props.reloId))[0];
        setRelocationFormResponse(relocationForm);
        return relocationForm;
    };

    async function getEstimateRecordsResponse(empId: number) {
        const estimateRecords = await estimatesFormService.getValidateEstimateRecords(empId, props.reloId);
        setEstimateRecordsResponse(estimateRecords);
    };

    async function getJobLevelInformation() {
        const jobLevelInfo = await estimatesFormService.getJobLevelInformation();
        setJobLevelInfoResponse(jobLevelInfo);
    };

    async function getCoreBenefitsResponse(fiscalYear: number, reloTypeId: string, jobLevelTaxRate: number) {
        const coreBenefits = await estimatesFormService.getCoreBenefits(fiscalYear, Number(reloTypeId));
        const updatedCoreBenefits = coreBenefits.map((item) => {
            if (item.CoreBenifitID === 12) {
                item.Amount = 'Included @ ' + jobLevelTaxRate + '%';
            }
            if (item.CoreBeniftName.trim() === 'Relocation Cash Allowance') {
                item.taxPct = jobLevelTaxRate;
            }
            return item;
        });
        setCoreBenefitsResponse(updatedCoreBenefits);
    };

    async function getAllJobLevelTaxRatesResponse() {
        const allJobLevelTaxRates = await estimatesFormService.getJobLevelTaxRatesService();
        setAllJobLevelTaxRatesResponse(allJobLevelTaxRates);
        return allJobLevelTaxRates;
    };

    async function getAllExceptionsResponse(HireTypeID: number, RelocationpolicyTypeID: string) {
        const allExceptions = await estimatesFormService.getAllExceptions(
            HireTypeID,
            Number(RelocationpolicyTypeID)
        );
        setAllExceptionsResponse(allExceptions);
    };

    async function getTaxAmountsResponse(
        HireTypeID: number,
        JobLevelID: string,
        ReloID: number,
        RelocationpolicyTypeID: string
    ) {
        const taxAmounts = await estimatesFormService.getTaxAmount(
            HireTypeID,
            Number(JobLevelID),
            ReloID,
            Number(RelocationpolicyTypeID)
        );
        const filteredTaxAmounts = taxAmounts.filter(
            (item) => item.RelocationPolicytypeId === Number(RelocationpolicyTypeID)
        );
        setTaxAmountsResponse(filteredTaxAmounts);
    };

    async function getRelocationPoliciesResponse(HireTypeID: number) {
        const relocationPolicies = await estimatesFormService.getRelocationPolicy(HireTypeID);
        setRelocationPoliciesResponse(relocationPolicies);
        return relocationPolicies;
    };

    async function getAllRelocationTypesResponse(HireTypeID: number, RelocationPolicyID: string,) {
        const allRelocationTypes = await estimatesFormService.getRelocationType();
        setRelocationTypeOptions(getRelocationTypesOptions(HireTypeID, RelocationPolicyID, allRelocationTypes));
        return allRelocationTypes;
    };

    const getRelocationTypesOptions = (HireTypeID: number, RelocationPolicyID: string, result: RelocationType[]) => {
        let editEstimatereloTypes = [];
        editEstimatereloTypes = result;
        let filterrelotypesall: any = [];
        editEstimatereloTypes.map((item: any) => {
            if (item.HireTypeID == HireTypeID && item.RelocationPolicyID == RelocationPolicyID) {
                filterrelotypesall.push(item);
            }
        });

        if (HireTypeID === 4) {
            filterrelotypesall = filterrelotypesall.filter((item: any) => {
                return (item.RelocationTypeID != 37);
            });
        }
        let currentPolicyID = parseInt(RelocationPolicyID);

        let filterrelotypes: any = [];
        let uniqueObject: any = {};
        for (let i in filterrelotypesall) {

            let RelocationTypeID = filterrelotypesall[i]['RelocationTypeID'];

            uniqueObject[RelocationTypeID] = filterrelotypesall[i];
        }

        for (let i in uniqueObject) {
            filterrelotypes.push(uniqueObject[i]);
        }
        if (currentPolicyID == 1 || currentPolicyID == 2 || currentPolicyID == 3 || currentPolicyID == 4 || currentPolicyID == 5) {
            filterrelotypes.push(editEstimatereloTypes.filter((item: any) => item.HireTypeID == 0 && item.RelocationTypeID == "36"
            )[0]);
        }

        if (typeof filterrelotypes !== 'undefined' && filterrelotypes.length > 0) {
            // the array is defined and has at least one element

            if (filterrelotypes.filter((item: any) => item.HireTypeID == 3)[0] &&
                filterrelotypes.filter((item: any) => item.RelocationPolicyID == 2)[0]) {
                filterrelotypes = filterrelotypes.filter((item: any) => item.RelocationTypeID != 12 && item.RelocationTypeID != 13);
                editEstimatereloTypes = filterrelotypes;
            }
            else {
                editEstimatereloTypes = filterrelotypes;

            }
        }
        else {
            editEstimatereloTypes = [];
        }
        return editEstimatereloTypes
    };

    async function getPackageValueResponse(FiscalYear: number, JobLevelID: string, ReloTypeID: string, previousPackageValue: string, reloFormCommitStatus: string | null) {
        let packageValue = '';
        if (reloFormCommitStatus !== 'COMMITTED') {
            await estimatesFormService.getPackageValue(
                FiscalYear.toString(),
                Number(JobLevelID),
                Number(ReloTypeID)
            ).then((response) => {
                if (response !== null && response !== undefined) {
                    setPackageValueResponse(response);
                    packageValue = response?.PackageValue;
                }

            })
        }
        else {
            packageValue = previousPackageValue;
        }
        setCorePackageValue(Number(packageValue ? packageValue : 0));
    };

    async function getAllOptionalBenefitsResponse(RelocationPolicyTypeID: string, ReloPolicyType: string, taxPct: number) {
        const allOptionalBenefits = await estimatesFormService.getAllOptionalBenefits(Number(RelocationPolicyTypeID), ReloPolicyType);

        const allOptionalBenefitsMaxAmounts = (await estimatesFormService
            .getAllOptionalBenefitsDetails(ReloPolicyType))
            .map(item => ({ OptionalBenifitID: item.OptionalBenifitID, MaxAmount: item.MaxAmount }));
        const filteredBenefitsWithMaxAmounts = allOptionalBenefits
            .map((allOptionalBenefitsItem) => {
                if (allOptionalBenefitsItem.Unit?.replace(/ /g, '').toUpperCase().includes("MAX2")) {
                    allOptionalBenefitsItem.UnitMaxValue = '2';
                }
                allOptionalBenefitsItem.Unit =
                    allOptionalBenefitsItem.UnitType === 'Unit' ? '1' : 'Per values provided in Cost Estimate';
                allOptionalBenefitsItem.Amount = isNaN(Number(allOptionalBenefitsItem.Amount))
                    ? '0'
                    : allOptionalBenefitsItem.Amount;
                allOptionalBenefitsItem.MaxAmount = Number(allOptionalBenefitsItem.Amount);
                const findRecord = allOptionalBenefitsMaxAmounts.find((optionalBenefitsItem) => optionalBenefitsItem.OptionalBenifitID === allOptionalBenefitsItem.OptionalBenifitID);
                allOptionalBenefitsItem.MaxAmount = findRecord?.MaxAmount ? findRecord?.MaxAmount : 0;
                allOptionalBenefitsItem.TaxPct = taxPct;
                allOptionalBenefitsItem.taxGrossup = getTaxGrossUp(allOptionalBenefitsItem.Amount, allOptionalBenefitsItem.TaxPct);
                return allOptionalBenefitsItem;
            })
            .filter((item, index, self) => self.findIndex((item2) => item.OptionalBenifitID === item2.OptionalBenifitID) === index);
        setAllOptionalBenefitsResponse((filteredBenefitsWithMaxAmounts as unknown) as OptionalBenefitMapping[]);
    };

    async function getAllFileExceptions(
        HireTypeID: number,
        ReloID: number,
        RelocationpolicyTypeID: string,
        fileID: number
    ) {
        await estimatesFormService
            .getAllFileExceptions(HireTypeID, ReloID, Number(RelocationpolicyTypeID), fileID)
            .then((response) => {
                if (!response.toString().includes('Error')) {
                    let activeOfferExceptions: FileExceptionProps[] = [];
                    let activePostOfferExceptions: FileExceptionProps[] = [];
                    response.map((item) => {
                        item.IsOfferException ? activeOfferExceptions.push(item) : activePostOfferExceptions.push(item);
                    });
                    setActiveOfferExceptionsResponse(activeOfferExceptions);
                    setActivePostOfferExceptionsResponse(activePostOfferExceptions);
                }
            });
    };

    async function getAllActiveExceptionsResponse(RelocationTypeID: string) {
        await estimatesFormService.getExceptions(Number(RelocationTypeID)).then((response) => {
            if (!response.toString().includes('Error')) {
                const filteredExceptions = response.filter((item) => item.OptionalBenifitID === 0);
                setAllActiveExceptionsResponse(filteredExceptions);
            }
        });
    };

    async function updateTaxedOfferExceptions(newJobLevelTaxRate: number) {
        const taxedOfferExceptions: UpdateAuthFEProps[] = activeOfferExceptionsResponse
            .filter((exception: FileExceptionProps) => exception.TaxPct !== 0)
            .map((exception: FileExceptionProps) => ({
                ReloId: relocationFormResponse.ReloID,
                CustFileID: exception.CustFileID,
                ExceptionID: exception.ExceptionId,
                Quantity: exception.ExceptionQuantity,
                ExceptionAmount: exception.ExceptionAmount,
                TotalAmount: getTaxGrossUp(Number(exception.ExceptionAmount), newJobLevelTaxRate),
                IsPostAccrual: false
            }));
        taxedOfferExceptions.forEach(async (exception: UpdateAuthFEProps) => {
            await estimatesFormService.updateAuthFE(exception);
        });
    };

    async function updateTaxedPostOfferExceptions(newJobLevelTaxRate: number) {
        const taxedPostOfferExceptions = activePostOfferExceptionsResponse
            .filter((exception: FileExceptionProps) => exception.TaxPct !== 0 && exception.ExceptionAction !== 'Approved' && exception.ExceptionAction !== 'Rejected')
            .map((exception: FileExceptionProps) => ({
                ...exception,
                TaxPct: newJobLevelTaxRate,
                TotalAmount: getTaxGrossUp(exception.ExceptionAmount, newJobLevelTaxRate),
                Status: exception.ExceptionAction
            }));
        taxedPostOfferExceptions.forEach(async (exception) => {
            await estimatesFormService.saveFileException(exception);
        })
    };

    async function updateTaxedPendingAddOfferExceptions(newJobLevelTaxRate: number) {
        const taxedPendingAddOfferExceptions = pendingAddOfferExceptions
            .filter((exception: FileExceptionProps) => exception.TaxPct !== 0)
            .map((exception: FileExceptionProps) => ({
                ...exception,
                TaxPct: newJobLevelTaxRate,
                TotalAmount: getTaxGrossUp(exception.ExceptionAmount, newJobLevelTaxRate),
            }));
        taxedPendingAddOfferExceptions.forEach(async (exception: FileExceptionProps) => {
            await estimatesFormService.saveFileOfferException(exception);
        })
    };

    async function addUntaxedPendingAddOfferExceptions() {
        const untaxedPendingAddOfferExceptions = pendingAddOfferExceptions
            .filter((exception: FileExceptionProps) => exception.TaxPct === 0);
        untaxedPendingAddOfferExceptions.forEach(async (exception: FileExceptionProps) => {
            await estimatesFormService.saveFileOfferException(exception);
        })
    };

    async function updateExceptions(newJobLevelTaxRate: number) {
        await updateTaxedOfferExceptions(newJobLevelTaxRate);
        await updateTaxedPendingAddOfferExceptions(newJobLevelTaxRate);
        await addUntaxedPendingAddOfferExceptions();
        await updateTaxedPostOfferExceptions(newJobLevelTaxRate);
    };

    async function addAllPendingExceptions() {
        pendingAddOfferExceptions.forEach(async (exception: FileExceptionProps) => {
            await estimatesFormService.saveFileOfferException(exception);
        })
    };

    async function updateEstimateDetails() {
        setIsUpdating(true);

        const { values: estimateProfileValues } = await estimateProfileRef?.current?.validateForm();
        const { values: employeeProfileValues } = await employeeProfileRef?.current?.validateForm();
        const { values: relocationPackageValues } = await relocationPackageRef?.current?.validateForm();

        const oldJobLevelTaxRate = jobLevelTaxRate;
        let newJobLevelTaxRate = jobLevelTaxRate;
        let updatedRelocationPackageValues = relocationPackageValues;
        if (employeeProfileValues?.JobLevelID.toString() !== relocationFormResponse?.JobLevelID) {
            newJobLevelTaxRate = getJobLevelTaxRate(employeeProfileValues?.JobLevelID, relocationFormResponse?.HireTypeID);
            if (newJobLevelTaxRate !== oldJobLevelTaxRate) {
                updatedRelocationPackageValues = getUpdatedRelocationPackageValues(relocationPackageValues, newJobLevelTaxRate);
            }
        }
        if (isLumpSum) {
            updatedRelocationPackageValues['LumpSumRelocation']['AccrualCostCenter'] = getTaxGrossUp(updatedRelocationPackageValues?.LumpSumRelocation?.NetCash, updatedRelocationPackageValues?.LumpSumRelocation?.TaxRate);
            updatedRelocationPackageValues['LumpSumRelocation']['TotalPackageValue'] = updatedRelocationPackageValues?.LumpSumRelocation?.AccrualCostCenter + updatedRelocationPackageValues?.LumpSumRelocation?.FileFee;
        }
        let estimateDetails = ({
            ...relocationFormResponse,
            CoreAllowance: props.isLumpSum
                ? null
                : [
                    {
                        CoreBenifitID: 0,
                        CashAmount: relocationFormResponse?.RelocationCashAmount,
                        IsLessThan: true,
                        CoreBeniftName: 'Relocation Cash Allowance',
                        IsDisabled: false,
                    },
                ],
            DestinationAddress: {
                ...relocationFormResponse.DestinationAddress,
                Country: estimateProfileValues?.country,
            },
            DestinationCompanyCode:Number(employeeProfileValues?.DestinationCompanyCode),
            CostCenter: employeeProfileValues?.CostCenter,
            CurrentDate: new Date().toISOString().split('T')[0] + '00:00:00',
            EmployeeID: employeeProfileValues?.EmployeeID == 0 ? '' : employeeProfileValues?.EmployeeID,
            EstimatedStartDate: isNotNullOrUndefined(employeeProfileValues?.StartDate)
                ? employeeProfileValues?.StartDate
                : relocationFormResponse?.StartDate,
            IsEdit: true,
            IsPolicyChanged: isPolicyChanged,
            JobLevelID: employeeProfileValues?.JobLevelID.toString(),
            LoggedInUser: userData.Alias,
            LumpSumRelocation: updatedRelocationPackageValues?.LumpSumRelocation,
            OptionalBenefitMapping: updatedRelocationPackageValues?.OptionalBenefitMapping,
            PersonalDetails: {
                ...relocationFormResponse.PersonalDetails,
                CurrentEmail: employeeProfileValues?.CurrentEmail,
                FirstName: employeeProfileValues?.FirstName,
                LastName: employeeProfileValues?.LastName,
                MiddleName: isNotNullOrUndefined(employeeProfileValues?.MiddleName)
                    ? employeeProfileValues?.MiddleName
                    : '',
                VisaPending: employeeProfileValues?.VisaPending,
            },
            RelocationCashAmount: updatedRelocationPackageValues?.RelocationCashAmount,
            RelocationPolicyID: estimateProfileValues?.RelocationPolicyID,
            RelocationpolicyTypeID: estimateProfileValues?.RelocationpolicyTypeID,
            Status: 'Authorized',
            StartDate: isNotNullOrUndefined(employeeProfileValues?.StartDate)
                ? moment(employeeProfileValues?.StartDate).format('YYYY-DD-MM') + 'T00:00:00.000Z'
                : new Date(),
            TotalEstimate: postOfferExceptionsTotalEstimate,
            TotalPackageValue: totalPackageValue,
        } as unknown) as EstimateDetailsProps;
        await estimatesFormService
            .updateEstimatesForm(estimateDetails)
            .then(async (response: any) => {
                if (response.toString() === relocationFormResponse?.ReloID.toString()) {
                    const relocationForm = await getRelocationFormResponse();
                    await getEstimateProfileResponse();
                    await getCoreBenefitsResponse(relocationForm?.FiscalYear, relocationForm?.RelocationpolicyTypeID, newJobLevelTaxRate);
                    if (newJobLevelTaxRate !== oldJobLevelTaxRate) {
                        await updateExceptions(newJobLevelTaxRate);
                    } else if (pendingAddOfferExceptions) {
                        await addAllPendingExceptions();
                    }

                    await getAllFileExceptions(
                        relocationForm?.HireTypeID,
                        relocationForm?.ReloID,
                        relocationForm?.RelocationpolicyTypeID,
                        props.fileId
                    );
                    setPendingAddOfferExceptions([]);
                    setIsUpdateSuccessful(true);
                }
            })
            .catch((error: any) => alert(error));

        setIsUpdating(false);
        setIsEditMode(false);
    };

    async function handleSuccessfulCommit() {
        setIsForceCommitSuccessful(true);
        setCommitStatus('COMMITTED');
        const relocationForm = await getRelocationFormResponse();
        await getPackageValueResponse(
            relocationForm?.FiscalYear,
            relocationForm?.JobLevelID,
            relocationForm?.RelocationpolicyTypeID,
            relocationForm?.PackageValue,
            'COMMITTED');
    };

    async function forceCommitFile() {
        setIsUpdating(true);
        await estimatesFormService
            .forceCommit(props.reloId)
            .then((response: any) => {
                handleSuccessfulCommit();
            })
            .catch((error: any) => {
                alert(error);
                return false;
            })
            .finally(() => {
                setIsUpdating(false);
            });
    };

    async function handleForceCommit(flag: string) {
        const isEmployeeDetailsStillValid = await employeeProfileRef?.current?.isStillValid();
        if (flag === "COMMIT") {
            isEmployeeDetailsStillValid ? forceCommitFile() : setIsForceCommitImpossible(true);
        } else if (flag === "FORCE COMMIT") {
            await forceCommitFile();
        }
    };

    async function handleSuccessfulUncommit() {
        setIsUncommitSuccessful(true);
        setCommitStatus('PREVIOUSLY COMMITTED');
        const relocationForm = await getRelocationFormResponse();
        await getPackageValueResponse(
            relocationForm?.FiscalYear,
            relocationForm?.JobLevelID,
            relocationForm?.RelocationpolicyTypeID,
            relocationForm?.PackageValue,
            'PREVIOUSLY COMMITTED');
    };

    async function handleUnCommit() {
        setIsUpdating(true);
        await estimatesFormService
            .unCommit(props.reloId)
            .then((response: any) => {
                handleSuccessfulUncommit();
            })
            .catch((error: any) => {
                alert(error);
                return false;
            })
            .finally(() => {
                setIsUpdating(false);
            });
    };

    async function cancelEditMode() {
        setIsUpdating(true);
        setPendingAddOfferExceptions([]);
        await getEstimateProfileResponse();
        setIsEditMode(false);
        setIsUpdating(false);
        setSelectedRelocationType(undefined);
        setSelectedRelocationTypeId(undefined);
        setIsLimitedBenefits(false);
        setIsChangesCancelled(true);
    };

    function handleMessageBarDismiss() {
        setIsUpdateSuccessful(false);
        setIsForceCommitSuccessful(false);
        setIsUncommitSuccessful(false);
        setIsForceCommitImpossible(false);
    };

    async function getReloSpecialistForm() {
        const searchOption: searchByOptions = searchByOptions[selectedSearchByValue as keyof typeof searchByOptions];
        const tempRecords = await pendingExceptionQueueService.GetExceptionDetailsSearch(searchOption, searchByKeyword.trimEnd());
        const resultTemp = tempRecords[0];
        if (tempRecords.length === 0) {
            setIsSearchByErrorCaught(true);
            return;
        }
        let relospecialistForm = JSON.stringify({
            fileId: resultTemp.fileId,
            reloId: resultTemp.reloId,
            redirectedFrom: 'reloSpecialistQueue',
        });
        history.push('/EstimatesForm', relospecialistForm);
        window.location.reload();
    };

    function getUpdatedRelocationPackageValues(relocationPackageValues: any, newJobLevelTaxRate: number) {
        let updatedRelocationPackageValues = relocationPackageValues;
        if (isLumpSum) {
            updatedRelocationPackageValues.LumpSumRelocation = { ...updatedRelocationPackageValues.LumpSumRelocation, TaxRate: newJobLevelTaxRate };
        } else {
            updatedRelocationPackageValues.OptionalBenefitMapping = updatedRelocationPackageValues.OptionalBenefitMapping.map((item: OptionalBenefitMapping) => {
                item.TaxPct = newJobLevelTaxRate;
                item.taxGrossup = getTaxGrossUp(item.Amount, newJobLevelTaxRate);
                return item;
            });
        }
        return updatedRelocationPackageValues;
    };

    function dismissOnDeleteSuccess() {
        setIsRedirect(true);
    };

    function updateCommitStatus(relocationFormCommitStatus: string) {
        if (relocationFormCommitStatus === 'COMMITTED') {
            setCommitStatus('COMMITTED');
        } else if (relocationFormCommitStatus === null) {
            setCommitStatus('UNCOMMITTED');
        } else {
            setCommitStatus('PREVIOUSLY COMMITTED');
        }
    };

    function updateIsLumpSum(relocationTypeIsLumpSum: boolean) {
        if (relocationTypeIsLumpSum) {
            setIsLumpSum(true);
        } else {
            setIsLumpSum(false);
        }
    };

    function updateIdLimitedBenefits(relocationType: string) {
        if (relocationType === 'Limited Benefits') {
            setIsLimitedBenefits(true);
        } else {
            setIsLimitedBenefits(false);
        }

    }

    function updateArePostOfferExceptionsAllowed(ReloPolicyType: string) {
        if (ReloPolicyType === "LumSum") {
            setArePostOfferExceptionsAllowed(false);
        }
    };

    async function getRelocationTypeResponse(
        HireTypeID: number,
        RelocationPolicyID: string,
        RelocationpolicyTypeID: string,
        allRelocationTypes: RelocationType[],
    ) {
        let relocationType = allRelocationTypes.filter((item) =>
            item.HireTypeID === HireTypeID &&
            item.RelocationPolicyID === Number(RelocationPolicyID) &&
            item.RelocationTypeID === RelocationpolicyTypeID
        )[0];

        if (relocationType === undefined || relocationType === null) {
            relocationType = allRelocationTypes.filter((item) =>
                item.RelocationPolicyID === Number(RelocationPolicyID) &&
                item.RelocationTypeID === RelocationpolicyTypeID
            )[0];
            relocationType.HireTypeID = HireTypeID;
        }
        setRelocationTypeResponse(relocationType);
        return relocationType;
    }

    function onDropdownChange(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void {
        setSelectedSearchByValue(option!.key as string);
    }

    function getSearchResults() {
        getReloSpecialistForm();
    }

    function updatePendingAddOfferExceptions(newException: FileExceptionProps) {
        const newPendingAddOfferExceptionsList = pendingAddOfferExceptions;
        const existingPendingAddOfferExceptionIndex = newPendingAddOfferExceptionsList.findIndex(pendingAddOfferExceptionItem => pendingAddOfferExceptionItem.ExceptionId === newException.ExceptionId);
        let difference = 0;
        if (existingPendingAddOfferExceptionIndex !== -1) {
            // Update the exception amount and total to be accumulative values
            newException.TotalAmount += newPendingAddOfferExceptionsList[existingPendingAddOfferExceptionIndex].TotalAmount;
            newException.ExceptionAmount += newPendingAddOfferExceptionsList[existingPendingAddOfferExceptionIndex].ExceptionAmount;

            // If the maximum amount is smaller, then re-calculate the amount and total values
            if (newException && newException.MaximumAmount && newException.MaximumAmount < newException.ExceptionAmount) {
                newException.ExceptionAmount = newException.MaximumAmount;
                if (newException.TaxGrossUp === 'NT') {
                    newException.TotalAmount = getTaxGrossUp(newException.ExceptionAmount, 0);
                } else {
                    newException.TotalAmount = getTaxGrossUp(newException.ExceptionAmount, newException.TaxRate);
                }
            }
            difference = newPendingAddOfferExceptionsList[existingPendingAddOfferExceptionIndex].TotalAmount;

            // Check if quantity is disabled, otherwise add the quantities together
            if (newException.isQuantityDisabled !== true) {
                newException.ExceptionQuantity += newPendingAddOfferExceptionsList[existingPendingAddOfferExceptionIndex].ExceptionQuantity;
            }

            newPendingAddOfferExceptionsList[existingPendingAddOfferExceptionIndex] = newException;
        } else {
            newPendingAddOfferExceptionsList.push(newException);
        }

        setOfferExceptionsTotalEstimate((prevState: number) => prevState + newException.TotalAmount - difference);
        setPendingAddOfferExceptions(newPendingAddOfferExceptionsList);
    };

    function getJobLevelTaxRate(JobLevelID: string, HireTypeID: number, allJobLevelTaxRates?: JobLevelTaxRates[]) {
        let taxRate = 0;
        if (HireTypeID === 4) {
            taxRate = 24.5;
        } else {
            taxRate = (allJobLevelTaxRates !== undefined)
                ? allJobLevelTaxRates[allJobLevelTaxRates.findIndex((item: JobLevelTaxRates) => item.JobLevelId.toString() === JobLevelID)]?.TaxRate
                : allJobLevelTaxRatesResponse[allJobLevelTaxRatesResponse.findIndex((item: JobLevelTaxRates) => item.JobLevelId.toString() === JobLevelID)]?.TaxRate;
        }
        setJobLevelTaxRate(taxRate);
        return taxRate;
    };

    function getTotalPackageValue() {
        let newPackageValue = 0;
        if (isLumpSum) {
            newPackageValue = getLumpSumPackageTotal(
                relocationFormResponse?.LumpSumRelocation?.NetCash,
                relocationFormResponse?.LumpSumRelocation?.FileFee
            );
        } else {
            newPackageValue = getRelocationPackageTotal(
                corePackageValue,
                relocationFormResponse?.RelocationCashAmount,
                relocationFormResponse?.OptionalBenefitMapping
            );
        }
        setTotalPackageValue(newPackageValue);
        return newPackageValue;
    };

    function updateTotalEstimate(packageValue: number) {
        setTotalEstimate(currencyFormatter(packageValue));
    };

    function getLumpSumPackageTotal(NetCash?: number, FileFee?: number) {
        let newTotal = 0;
        if (NetCash !== undefined && NetCash !== null) {
            newTotal += getTaxGrossUp(NetCash);
        }
        if (FileFee !== undefined && FileFee !== null) {
            newTotal += FileFee;
        }
        return Number(newTotal.toFixed(2));
    };

    function getRelocationPackageTotal(
        RelocationPackageAmount?: number,
        RelocationCashAmount?: number,
        OptionalBenefitsMapping?: OptionalBenefitMapping[]
    ) {
        let newTotal = 0;
        if (RelocationPackageAmount !== undefined && RelocationPackageAmount !== null) {
            newTotal += RelocationPackageAmount;
        }
        if (RelocationCashAmount !== undefined && RelocationCashAmount !== null) {
            newTotal += getTaxGrossUp(RelocationCashAmount);
        }
        if (OptionalBenefitsMapping !== undefined && OptionalBenefitsMapping !== null) {
            OptionalBenefitsMapping.forEach((item) => {
                newTotal += getTaxGrossUp(item.Amount);
            });
        }
        return Number(newTotal.toFixed(2));
    };

    function currencyFormatter(number: number | string | undefined) {
        number = Number(number);
        if (isNullOrUndefined(number) && isNaN(number)) {
            number = 0;
        }

        const amount = Number(number).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
        });
        return amount;
    };

    function getTaxGrossUp(amount: number | string, optionalTaxRate?: number) {
        amount = Number(amount);
        if (isNullOrUndefined(amount) && isNaN(amount)) {
            amount = 0;
        }
        if (optionalTaxRate !== undefined && optionalTaxRate !== 0) {
            return Number(((amount * optionalTaxRate) / 100 + amount).toFixed(2));
        } else {
            if (relocationFormResponse?.RelocationpolicyTypeID === '12'
                || relocationFormResponse?.RelocationpolicyTypeID === '13'
                || relocationFormResponse?.RelocationpolicyTypeID === '39') {
                return Number((Math.round((amount * jobLevelTaxRate) / 100 / 5) * 5 + amount).toFixed(2));
            }
            return Number(((amount * jobLevelTaxRate) / 100 + amount).toFixed(2));
        }
    };

    function getTaxGrossUpFormatted(amount: number | string, optionalTaxRate?: number) {
        return currencyFormatter(getTaxGrossUp(amount, optionalTaxRate ? optionalTaxRate : undefined));
    };

    const isNotNullOrUndefined = (value: any) => {
        return !isNullOrUndefined(value);
    };

    function isNullOrUndefined(value: any) {
        if (value === null || value === undefined || (typeof value === 'string' && value === '')) {
            return true;
        } else {
            return false;
        }
    };

    function isValidPositiveNumber(value: string | undefined | null) {
        return (value !== '' && !isNaN(Number(value)) && 0 < Number(value)) ? true : false;
    }

    return (
        <>
            {!isLoaded ? (
                <LoadingSpinner />
            ) : (
                <>
                    {(userRole.ReloAdmin || userRole.ReloAnalyst || userRole.ReloSpecialist) ? (
                        <Stack>
                            {isUpdating && (
                                <Spinner
                                    label="Loading, please wait..."
                                    size={SpinnerSize.large}
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        height: '100%',
                                        width: '100%',
                                        zIndex: 9999999,
                                        backgroundColor: 'rgb(255, 255, 255, 255)',
                                    }}
                                />
                            )}
                            {props.redirectedFrom && props.redirectedFrom === 'reloSpecialistQueue' && (
                                <Stack horizontal horizontalAlign="end" styles={stackItemStyles}>
                                    <Stack horizontalAlign="space-between" tokens={verticalGapStackTokens}>
                                        <Stack.Item>
                                            <Dropdown
                                                label="Search by"
                                                options={dropdownOptions}
                                                selectedKey={selectedSearchByValue}
                                                onChange={onDropdownChange}
                                                ariaLabel="SearchBy Options"
                                            />
                                        </Stack.Item>
                                        <Stack.Item align="end">
                                            <SearchBox
                                                placeholder="Search"
                                                onEscape={(ev) => { }}
                                                onClear={(ev) => { }}
                                                onChange={(_, newValue) => setSearchByKeyword(newValue || '')}
                                                onSearch={getSearchResults}
                                                value={searchByKeyword}
                                            />
                                        </Stack.Item>
                                        {isSearchByErrorCaught && (
                                            <Stack.Item>
                                                <p className={custom_error_msg}>Please enter valid {selectedSearchByValue}</p>
                                            </Stack.Item>
                                        )}
                                        <Stack.Item align="end">
                                            <PrimaryButton
                                                text={'Search'}
                                                onClick={() => getSearchResults()}
                                            />
                                        </Stack.Item>
                                    </Stack>
                                </Stack>
                            )}

                            <Stack horizontal horizontalAlign="space-between">
                                <Stack.Item align="baseline">
                                    <Text variant="xLarge">
                                        <h1>Relocation Record for {relocationFormResponse?.PersonalDetails?.FirstName}{' '}
                                            {relocationFormResponse?.PersonalDetails?.LastName}{' '}</h1>
                                    </Text>
                                </Stack.Item>
                                <Stack.Item align="baseline">{commitStatus.toUpperCase()}</Stack.Item>
                            </Stack>
                            {props.redirectedFrom === 'estimatesQueue' && <Stack.Item>
                                <FontIcon aria-label="Navigate Back" tabIndex={0} iconName="NavigateBack"
                                    onKeyPress={(event) => {
                                        if (event.key === "Enter") { history.push('/EstimatesQueue', { redirectedFrom: 'estimatesForm' }) }
                                    }}
                                    onClick={() => history.push('/EstimatesQueue', { redirectedFrom: 'estimatesForm' })} className={iconClass} />
                            </Stack.Item>}
                            <Stack className={contentStyles.body}>
                                {isUpdateSuccessful && (
                                    <MessageBar
                                        messageBarType={MessageBarType.success}
                                        isMultiline={false}
                                        onDismiss={handleMessageBarDismiss}
                                        dismissButtonAriaLabel="Clear MessageBar"
                                    >
                                        <strong>Success!</strong> The changes have been successfully updated.
                                    </MessageBar>
                                )}
                                {isForceCommitSuccessful && (
                                    <MessageBar
                                        messageBarType={MessageBarType.success}
                                        isMultiline={false}
                                        onDismiss={handleMessageBarDismiss}
                                        dismissButtonAriaLabel="Clear MessageBar"
                                    >
                                        <strong>Success!</strong> File has been Force committed successfully.
                                    </MessageBar>
                                )}
                                {isUncommitSuccessful && (
                                    <MessageBar
                                        messageBarType={MessageBarType.success}
                                        isMultiline={false}
                                        onDismiss={handleMessageBarDismiss}
                                        dismissButtonAriaLabel="Clear MessageBar"
                                    >
                                        <strong>Success!</strong> File has been uncommitted successfully.
                                    </MessageBar>
                                )}
                                {isForceCommitImpossible && (
                                    <MessageBar
                                        messageBarType={MessageBarType.error}
                                        isMultiline={false}
                                        onDismiss={handleMessageBarDismiss}
                                        dismissButtonAriaLabel="Clear MessageBar"
                                    >
                                        Please correct the employee details and then proceed with committing the file.
                                    </MessageBar>
                                )}
                                <EstimateProfile
                                    ref={estimateProfileRef}
                                    isEditMode={isEditMode}
                                    estimateProfileResponse={estimateProfileResponse}
                                    relocationPoliciesResponse={relocationPoliciesResponse}
                                    relocationTypeOptions={relocationTypeOptions}
                                    HireTypeID={relocationFormResponse?.HireTypeID}
                                    InternshipTypeID={relocationFormResponse?.InternshipTypeID}
                                    RelocationPolicyID={Number(relocationFormResponse?.RelocationPolicyID)}
                                    RelocationPolicyTypeID={relocationFormResponse?.RelocationpolicyTypeID}
                                    isNotNullOrUndefined={isNotNullOrUndefined}
                                    setRelocationTypeResponse={setRelocationTypeResponse}
                                    getAllRelocationTypesResponse={getAllRelocationTypesResponse}
                                    onRelocationTypeChange={onRelocationTypeChange}
                                    selectedRelocationType={selectedRelocationType ? selectedRelocationType : ''}
                                    selectedRelocationTypeId={selectedRelocationTypeId ? selectedRelocationTypeId : ''}
                                    isChangesCancelled={isChangesCancelled}
                                />
                                <EmployeeProfile
                                    ref={employeeProfileRef}
                                    isEditMode={isEditMode}
                                    estimateRecordsResponse={estimateRecordsResponse}
                                    relocationFormResponse={relocationFormResponse}
                                    jobLevelInfoResponse={jobLevelInfoResponse}
                                    isNotNullOrUndefined={isNotNullOrUndefined}
                                />
                                <RelocationPackage
                                    ref={relocationPackageRef}
                                    isEditMode={isEditMode}
                                    commitStatus={commitStatus}
                                    relocationType={relocationTypeResponse?.RelocationTypeName}
                                    totalEstimate={totalEstimate}
                                    isLumpSum={isLumpSum}
                                    isLimitedBenefits={isLimitedBenefits}
                                    LimitedBenifitsTotalPackageValue={packageValueResponse?.FileFee}
                                    relocationCash={relocationFormResponse.RelocationCashAmount}
                                    corePackageValue={corePackageValue}
                                    coreBenefitsResponse={coreBenefitsResponse}
                                    relocationFormResponse={relocationFormResponse}//TODO: Remove sending the whole object to the child component
                                    allOptionalBenefitsResponse={allOptionalBenefitsResponse}
                                    getTaxGrossUpFormatted={getTaxGrossUpFormatted}
                                    currencyFormatter={currencyFormatter}
                                    cancelEditMode={cancelEditMode}
                                    updateEstimateDetails={updateEstimateDetails}
                                    getTaxGrossUp={getTaxGrossUp}
                                    setIsEditMode={setIsEditMode}
                                    isValidPositiveNumber={isValidPositiveNumber}
                                    selectedRelocationType={selectedRelocationType ? selectedRelocationType : ''}
                                />
                                <OfferExceptions
                                    offerExceptionsTotalEstimate={offerExceptionsTotalEstimate}
                                    allExceptionsResponse={allExceptionsResponse}
                                    hiringManagerAlias={relocationFormResponse?.HiringManagerAlias}
                                    taxAmountsResponse={taxAmountsResponse}
                                    isEditMode={isEditMode}
                                    firstAndLastName={relocationFormResponse?.PersonalDetails?.FirstName + " " + relocationFormResponse?.PersonalDetails?.LastName}
                                    activeOfferExceptionsResponse={activeOfferExceptionsResponse}
                                    allActiveExceptionsResponse={allActiveExceptionsResponse}
                                    custFileID={Number(relocationFormResponse?.CustFileID)}
                                    reloId={props.reloId}
                                    commitStatus={commitStatus}
                                    isLumpSum={isLumpSum}
                                    redirectedFrom={props.redirectedFrom}
                                    getAllFileExceptions={() =>
                                        getAllFileExceptions(
                                            relocationFormResponse?.HireTypeID,
                                            relocationFormResponse?.ReloID,
                                            relocationFormResponse?.RelocationpolicyTypeID,
                                            props.fileId
                                        )
                                    }
                                    dismissOnDeleteSuccess={dismissOnDeleteSuccess}
                                    handleForceCommit={handleForceCommit}
                                    handleUnCommit={handleUnCommit}
                                    currencyFormatter={currencyFormatter}
                                    getTaxGrossUp={getTaxGrossUp}
                                    getTaxGrossUpFormatted={getTaxGrossUpFormatted}
                                    storeNewOfferException={updatePendingAddOfferExceptions}
                                    isValidPositiveNumber={isValidPositiveNumber}
                                    isNotNullOrUndefined={isNotNullOrUndefined}
                                    pendingAddOfferExceptions={pendingAddOfferExceptions}
                                    isLimitedBenefits={isLimitedBenefits}
                                    relocationType={relocationTypeResponse?.RelocationTypeName}
                                    LimitedBenifitsTotalPackageValue={packageValueResponse?.FileFee}
                                />
                                {!isLumpSum && arePostOfferExceptionsAllowed && (
                                    <PostOfferExceptions
                                        postOfferExceptionsTotalEstimate={postOfferExceptionsTotalEstimate}
                                        allExceptionsResponse={allExceptionsResponse}
                                        hiringManagerAlias={relocationFormResponse?.HiringManagerAlias}
                                        taxAmountsResponse={taxAmountsResponse}
                                        firstAndLastName={relocationFormResponse?.PersonalDetails?.FirstName + " " + relocationFormResponse?.PersonalDetails?.LastName}
                                        activePostOfferExceptionsResponse={activePostOfferExceptionsResponse}
                                        custFileID={Number(relocationFormResponse.CustFileID)}
                                        reloId={props.reloId}
                                        relocationType={relocationTypeResponse?.RelocationTypeName}
                                        relocationPolictTypeID={relocationFormResponse.RelocationpolicyTypeID}
                                        hireType={estimateProfileResponse?.HireType}
                                        commitStatus={commitStatus}
                                        redirectedFrom={props.redirectedFrom}
                                        getAllFileExceptions={() =>
                                            getAllFileExceptions(
                                                relocationFormResponse?.HireTypeID,
                                                relocationFormResponse?.ReloID,
                                                relocationFormResponse?.RelocationpolicyTypeID,
                                                props.fileId
                                            )
                                        }
                                        getTaxGrossUp={getTaxGrossUp}
                                        getTaxGrossUpFormatted={getTaxGrossUpFormatted}
                                        currencyFormatter={currencyFormatter}
                                        isValidPositiveNumber={isValidPositiveNumber}
                                        isNotNullOrUndefined={isNotNullOrUndefined}
                                        LimitedBenifitsTotalPackageValue={packageValueResponse?.FileFee}
                                    />
                                )}
                            </Stack>
                            <p style={{ margin: 10 }}>
                                HRweb contains only general information and guidelines.It does not create any
                                contractual rights or impose legal obligations on microsoft or its subsidiaries, nor
                                does it guarantee specific treatment in any given situation.HRweb is updated frequently
                                and is subject to change without prior notice.HRweb is intended primarily as a general
                                information resource for employees of Microsoft Information and guidelines may vary by
                                country and by subsidiary. Accordingly employee of Microsoft subsidiaries should check
                                with their HR representative for clarification.
                            </p>
                        </Stack>
                    ) : (
                        <UnAuthorized />
                    )}
                </>
            )}
            {isRedirect && <Redirect to="/EstimatesQueue" />}
        </>
    );
};
