import * as React from 'react';
import {
    PrimaryButton,
    Modal,
    mergeStyleSets,
    getTheme,
    FontWeights,
} from '@fluentui/react';
import {
    WarningModalProps,
} from '../../Models/IEstimatesForm';
import { useServiceStore } from '../../RootStateContext';

const theme = getTheme();
const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
    },
    header: [
        theme.fonts.xLargePlus,
        {
            flex: '1 1 auto',
            borderTop: `4px solid orange`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: {
                margin: '14px 0'
            },
            'p:first-child': {
                marginTop: 0
            },
            'p:last-child': {
                marginBottom: 0
            },
        },
    },
});
const buttonStyles = {
    backgroundColor: 'rgb(0, 120, 212)',
    color: '#FFFFFF',
    marginBottom: '10px',
    marginRight: '10px'
};

export const WarningModal: React.FC<WarningModalProps> = (props) => {
    const { estimatesFormService } = useServiceStore();

    async function deleteFileException(FERecordId: number, ReloID: number) {
        const deleteFileExceptionResponse = await estimatesFormService.deleteFileException(FERecordId, ReloID);
        return deleteFileExceptionResponse
    }

    async function deleteAuthFileException(FERecordId: number, ReloID: number) {
        const deleteAuthFileExceptionResponse = await estimatesFormService.deleteAuthFileException(FERecordId, ReloID);
        return deleteAuthFileExceptionResponse
    }

    const onRemoveItem = async (details: any) => {
        let isRowDeleted = false;
        if (props.exceptionActionForRow === 'PS' || props.exceptionActionForRow === 'PA' || props.exceptionActionForRow === 'Approved') {
            isRowDeleted = await deleteFileException(details.FERecordId, details.ReloID);
        } else if (props.exceptionActionForRow === 'AU') {
            isRowDeleted = await deleteAuthFileException(details.ExceptionId, details.ReloID);
        }

        if (isRowDeleted) {
            await props.getAllFileExceptions();
            props.toggleWarningModal(false);
        };

    }

    return (
        <>
            <Modal
                titleAriaId='title'
                isOpen={props.isModalOpen}
                containerClassName={contentStyles.container}
            >
                <>
                    <div className={contentStyles.header}>
                        <span style={{ color: '#7E511B', fontWeight: 'bold', paddingRight: 10 }}>Warning!</span>
                        <span style={{ color: '#A88A66' }}>Removing this item cannot be undone.</span>

                    </div>

                    <div className={contentStyles.body}>
                        <p>Are you sure you want to remove the item?</p>

                        <div style={{ marginTop: 20 }}>
                            <PrimaryButton
                                text="Keep the item"
                                onClick={() => props.toggleWarningModal(false)}
                                allowDisabledFocus
                                disabled={false}
                                style={{marginRight: 10}}
                            />
                            <PrimaryButton
                                text="Remove Item"
                                onClick={() => onRemoveItem(props.rowDetails)}
                                allowDisabledFocus
                                disabled={false}
                                style={buttonStyles}
                            />
                        </div>
                    </div>
                </>
            </Modal>
        </>)
}