import { MSALV2Client } from '@employee-experience/core/lib/MSALV2Client';
import { TelemetryClient } from '@employee-experience/core/lib/TelemetryClient';
import { HttpClient } from '@employee-experience/core/lib/HttpClient';
import { ComponentLoader } from '@employee-experience/core/lib/ComponentLoader';
import { GraphClient } from '@employee-experience/core/lib/GraphClient';
import { Shell } from '@employee-experience/shell/lib/Shell';
import { withStore } from '@employee-experience/shell/lib/withStore';
import { StoreBuilder } from '@employee-experience/shell/lib/StoreBuilder';
import { ReducerRegistry } from '@employee-experience/shell/lib/ReducerRegistry';
import { appReducer } from './Common/Redux/reducer';
import { UsageHelper } from '@employee-experience/common/lib/Models/UsageTelemetry/Helpers/Usage.helper';
import { UserAttribute } from '@employee-experience/common/lib/Models/UsageTelemetry';
import { ITelemetryContext } from '@employee-experience/common/lib/Models/ITelemetryContext';

const telemetryClient = new TelemetryClient({
    instrumentationKey: __INSTRUMENTATION_KEY__,
    UTPConfig: {
        EnvironmentName: 'Development',
        ServiceOffering: 'Human Resources',
        ServiceLine: 'Benefits',
        Service: 'GMAT',
        ComponentName: '',
        ComponentId: '',
    },
    defaultProperties: {
        appName: __APP_NAME__,
    },
});
const authClient = new MSALV2Client(
    {
        auth: {
            clientId: __CLIENT_ID__,
            redirectUri: window.location.origin,
            authority: 'https://login.microsoftonline.com/72f988bf-86f1-41af-91ab-2d7cd011db47',
        },
    },
    telemetryClient
);

const usageHelper = __APP_NAME__ && __APP_NAME__.trim() != '' ? UsageHelper.Fork(__APP_NAME__) : UsageHelper;

const telemetryContext: ITelemetryContext = {
    sourceComponent: 'Shell',
    sourceScript: 'main',
    setUsageEvent: usageHelper.MassageEvent,
    setUsageUser: (usageUser: UserAttribute) => {
        UsageHelper.SetUser(usageUser);
        usageHelper.SetUser(usageUser);
        return usageUser;
    },
    usageUser: usageHelper.GetUser,
    setUsageConfig: usageHelper.SetUsageConfig,
    getChildContext: usageHelper.ForkTelemetryContext,
};

telemetryClient.setContext(telemetryContext);
const httpClient = __IS_ACCESSIBILITY_TESTING__ === 'true'
    ? new HttpClient(telemetryClient, undefined)
    : new HttpClient(telemetryClient, authClient);
const graphClient = new GraphClient(httpClient);
const componentLoader = new ComponentLoader(telemetryClient, httpClient);

const reducerRegistry = new ReducerRegistry();
reducerRegistry.register('root', appReducer);
const appName = 'GMAT';
const storeResult = new StoreBuilder(reducerRegistry, {})
    .configureLogger(__IS_DEVELOPMENT__)
    .configureSaga({ telemetryClient, telemetryContext, authClient, httpClient, componentLoader, graphClient, appName })
    .build();

export const ShellWithStore = withStore(storeResult)(Shell);
