import * as React from 'react';
import { IColumn, Stack } from '@fluentui/react';
import { CoherencePagination, ICoherencePaginationProps } from '@cseo/controls/lib/pagination';

export const Pagination: React.FC<{ data: any; columns: IColumn[]; setData: any }> = ({ data, columns, setData }) => {
    const DEFAULT_PAGE_SIZE = 15;
    const selectedPage = React.useRef<number>(1);
    const pageCount = React.useRef<number>(0);
    const defaultPageSize = React.useRef<number>(DEFAULT_PAGE_SIZE);
    const DATA_SIZE = data.length;

    const fetchData = (pageSize: number): void => {
        let statIndex: number = (selectedPage.current - 1) * pageSize;
        const dataRender: any[] = [];
        for (let i = 0; i < pageSize; i++) {
            let row: any = data[statIndex];
            statIndex++;
            if (row != undefined) dataRender.push(row);
        }
        pageCount.current = Math.ceil(DATA_SIZE / defaultPageSize.current);
        setData(dataRender);
    };

    const onPageChange = (newPageNumber: number): void => {
        if (newPageNumber !== selectedPage.current) {
            selectedPage.current = newPageNumber;
            fetchData(defaultPageSize.current);
        }
    };

    const paginationProps: ICoherencePaginationProps = {
        pageCount: pageCount.current,
        selectedPage: selectedPage.current,
        previousPageAriaLabel: 'previous page',
        nextPageAriaLabel: 'next page',
        inputFieldAriaLabel: 'page number',
        onPageChange: onPageChange,
    };

    React.useEffect(() => {
        selectedPage.current = 1;
        fetchData(defaultPageSize.current);
    }, [data]);

    React.useEffect(() => {
        let btn = document.querySelectorAll('button');
        let inp = document.querySelectorAll('input');
        inp.forEach((item) => {
            if (item.getAttribute("aria-label") == "page number") {
                item.setAttribute('tabindex', '0');
                item.setAttribute('role', 'textbox');
            }
        })
        btn.forEach((item) => {
            item.setAttribute('tabindex', '0');
            if (item.getAttribute('aria-hidden') == 'true') {
                item.setAttribute('disabled', 'true');
            }
        })
        btn.forEach((item) => {
            if (item.getAttribute("title") == "Prev" || item.getAttribute("title") == "Next") {
                item.setAttribute('role', 'button');
                item.parentElement?.parentElement?.classList.add("wrap-btn");
                item.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.classList.add("wrap-btn");
            }
        })
    });

    return (
        <div>
            <Stack horizontal horizontalAlign="end">
                <Stack horizontal horizontalAlign="space-between" styles={{ root: { width: '50%' } }}>
                    <div style={{ marginLeft: -132 }}>
                        <CoherencePagination {...paginationProps} />
                    </div>
                </Stack>
            </Stack>
        </div>
    );
};
