import { IColumn } from '@fluentui/react';

export const ExceptionQueueColumns: IColumn[] = [
    {
        key: 'Status',
        name: 'STATUS',
        fieldName: 'Status',
        minWidth: 60,
        maxWidth: 60,
    },
    {
        key: 'Approver',
        name: 'APPROVER',
        fieldName: 'Approver',
        minWidth: 70,
        maxWidth: 70,
    },
    {
        key: 'ExceptionAmount',
        name: 'EXCEPTION AMOUNT',
        fieldName: 'ExceptionAmount',
        minWidth: 140,
        maxWidth: 140,
    },
    {
        key: 'GrossUp',
        name: 'GROSS UP',
        fieldName: 'GrossUp',
        minWidth: 75,
        maxWidth: 75,
    },
    {
        key: 'TotalAmount',
        name: 'TOTAL AMOUNT',
        fieldName: 'TotalAmount',
        minWidth: 110,
        maxWidth: 110,
    },
    {
        key: 'Category',
        name: 'CATEGORY',
        fieldName: 'Category',
        minWidth: 70,
    },
    {
        key: 'WentInWebJE',
        name: 'WENT WEBJE',
        fieldName: 'WentInWebJE',
        minWidth: 90,
        maxWidth: 90,
    },
    {
        key: 'CostCenter',
        name: 'COST CENTER',
        fieldName: 'CostCenter',
        minWidth: 95,
        maxWidth: 95,
    },
    {
        key: 'FullName',
        name: 'FULL NAME',
        fieldName: 'FullName',
        minWidth: 75,
    },
    {
        key: 'CandidateID',
        name: 'CANDIDATE ID',
        fieldName: 'CandidateID',
        minWidth: 100,
        maxWidth: 100,
    },
    {
        key: 'EmployeeID',
        name: 'EMPLOYEE ID',
        fieldName: 'EmployeeID',
        minWidth: 95,
        maxWidth: 95,
    },
];

export const ExportExceptionQueueColumns: IColumn[] = [
    {
        key: 'ReloId',
        name: 'RELO ID',
        fieldName: 'ReloId',
        minWidth: 60,
    },
    {
        key: 'FileId',
        name: 'FILE ID',
        fieldName: 'FileId',
        minWidth: 60,
    },
    {
        key: 'Status',
        name: 'STATUS',
        fieldName: 'Status',
        minWidth: 60,
    },
    {
        key: 'DateRequest',
        name: 'DATE REQUEST',
        fieldName: 'DateRequest',
        minWidth: 65,
    },
    {
        key: 'Approver',
        name: 'APPROVER',
        fieldName: 'Approver',
        minWidth: 70,
    },
    {
        key: 'DateApproved',
        name: 'DATE APPROVED',
        fieldName: 'DateApproved',
        minWidth: 75,
    },
    {
        key: 'ExceptionAmount',
        name: 'AMOUNT',
        fieldName: 'ExceptionAmount',
        minWidth: 65,
    },
    {
        key: 'WentInWebJE',
        name: 'SEND TO WEBJE',
        fieldName: 'WentInWebJE',
        minWidth: 60,
    },
    {
        key: 'RemaindersSent',
        name: 'REMAINDERS SENT',
        fieldName: 'RemaindersSent',
        minWidth: 90,
    },
    {
        key: 'StartDate',
        name: 'START DATE',
        fieldName: 'StartDate',
        minWidth: 65,
    },
    {
        key: 'CostCenter',
        name: 'COST CENTER',
        fieldName: 'CostCenter',
        minWidth: 60,
    },
    {
        key: 'RelocationType',
        name: 'RELOCATION TYPE',
        fieldName: 'RelocationType',
        minWidth: 85,
    },
    {
        key: 'CommitStamp',
        name: 'DATE COMMITTED',
        fieldName: 'CommitStamp',
        minWidth: 85,
    },
    {
        key: 'CostOfBenefitPerPolciy',
        name: 'COST OF EXCEPTION',
        fieldName: 'CostOfBenefitPerPolciy',
        minWidth: 75,
    },
    {
        key: 'TotalAmount',
        name: 'TOTAL COST',
        fieldName: 'TotalAmount',
        minWidth: 60,
    },
    {
        key: 'ExceptionJustification',
        name: 'JUSTIFICATION',
        fieldName: 'ExceptionJustification',
        minWidth: 100,
    },
    {
        key: 'Category',
        name: 'CATEGORY',
        fieldName: 'Category',
        minWidth: 70,
    },
    {
        key: 'BusinessGroup',
        name: 'BUSINESS GROUP',
        fieldName: 'BusinessGroup',
        minWidth: 65,
    },
    {
        key: 'FullName',
        name: 'FULL NAME',
        fieldName: 'FullName',
        minWidth: 75,
    },
    {
        key: 'CandidateID',
        name: 'CANDIDATE ID',
        fieldName: 'CandidateID',
        minWidth: 100,
    },
    {
        key: 'EmployeeID',
        name: 'EMPLOYEE ID',
        fieldName: 'EmployeeID',
        minWidth: 95,
    },
];