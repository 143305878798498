import * as React from 'react';
import {
    Label, 
    Stack,
    mergeStyleSets,
} from '@fluentui/react';

export const StackDisclaimer = () => {
    return (
        <Stack> 
            <Label className={`${classNamesDisc.lblDisclaimer} disclaimerStyle`}>HRweb contains only general information and guidelines.It does not create any contractual rights or impose legal obligations on microsoft or its subsidiaries, nor does it guarantee specific treatment in any given situation.HRweb is updated frequently and is subject to change without prior notice.HRweb is intended primarily as a general information resource for employees of Microsoft Information and guidelines may vary by country and by subsidiary. Accordingly employee of Microsoft subsidiaries should check with their HR representative for clarification</Label>
        </Stack>
    );
}

const classNamesDisc = mergeStyleSets({
    lblDisclaimer: {
        'color': 'dark gray',
        'font-weight': '100',
        'font-size': '10px'
    },
});


