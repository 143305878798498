import * as React from 'react';
import {
    Stack,
    IconButton,
    IStackItemStyles,
    IStackProps,
    IStackStyles,
    IStackTokens,
    Label,
    TextField,
    Dropdown,
    IDropdownOption,
} from '@fluentui/react';
import { DatePicker } from 'office-ui-fabric-react';
import { EmployeeProfileData } from '../../Models/IEstimatesForm';
import { FontIcon } from '@fluentui/react/lib/Icon';
import { mergeStyles, mergeStyleSets } from '@fluentui/react/lib/Styling';
import * as moment from 'moment';

const wrapStackTokens: IStackTokens = { childrenGap: 30 };
const iconClass = mergeStyles({
    fontSize: 20,
    height: 20,
    width: 20,
    margin: '0 10px 0 0',
});
const iconColorNames = mergeStyleSets({
    goldenrod: [{
        color: 'goldenrod'
    }, iconClass],
});
const stackStyles: Partial<IStackStyles> = {
    root: {
        width: '100%',
        marginTop: '7px',
        marginBottom: '50px'
    }
};
const formSectionStyle = {
    paddingTop: 15,
    paddingBottom: 15,
    borderTop: '3px solid #dcdcdc',
};
const accordionHeader: IStackItemStyles = {
    root: {
        padding: 5,
        cursor: 'pointer',
        fontSize: '20px',
    },
};
const accordionContent: IStackItemStyles = {
    root: {
        marginLeft: '50px',
        marginTop: 15,
    },
};
const dropdownStyles = {
    dropdown: {
        width: 150
    }
};
const employeeProfileColumnProps: Partial<IStackProps> = {
    tokens: {
        childrenGap: 10
    },
    styles: {
        root: {
            width: '15%'
        }
    },
};

export const EmployeeProfile: React.FC<any> = React.forwardRef((props, ref) => {
    const [isOpen, setIsOpen] = React.useState<boolean>(true);

    const [firstName, setFirstName] = React.useState<string>('');
    const [middleName, setMiddleName] = React.useState<string>('');
    const [lastName, setLastName] = React.useState<string>('');
    const [currentEmail, setCurrentEmail] = React.useState<string>('');
    const [costCenter, setCostCenter] = React.useState<string>('');
    const [startDate, setStartDate] = React.useState<string>('');
    const [employeeNumber, setEmployeeNumber] = React.useState<string>('');
    const [visaPending, setVisaPending] = React.useState<string>('');
    const [jobLevelID, setJobLevelID] = React.useState<string>('');
    const [jobLevelName, setJobLevelName] = React.useState<string>('');

    const [lastNameError, setLastNameError] = React.useState<string | undefined>(undefined);
    const [firstNameError, setFirstNameError] = React.useState<string | undefined>(undefined);
    const [costCenterError, setCostCenterError] = React.useState<string | undefined>(undefined);
    const [currentEmailError, setCurrentEmailError] = React.useState<string | undefined>(undefined);
    const [DepartureCompanyCodeError, setDepartureCompanyCodeError] = React.useState<string | undefined>(undefined);
    const [DestinationCompanyCodeError, setDestinationCompanyCodeError] = React.useState<string | undefined>(undefined);
    const [RequisitionIDError, setRequisitionIDError] = React.useState<string | undefined>(undefined);

    const [isFirstNameValid, setIsFirstNameValid] = React.useState<boolean>(true);
    const [isMiddleNameValid, setIsMiddleNameValid] = React.useState<boolean>(true);
    const [isLastNameValid, setIsLastNameValid] = React.useState<boolean>(true);
    const [isEmployeeIDValid, setIsEmployeeIDValid] = React.useState<boolean>(true);
    const [isCurrentEmailValid, setIsCurrentEmailValid] = React.useState<boolean>(true);
    const [isCostCenterValid, setIsCostCenterValid] = React.useState<boolean>(true);
    const [isDestinationCompanyCodeValid, setIsDestinationCompanyCodeValid] = React.useState<boolean>(true)
    const [isStartDateValid, setIsStartDateValid] = React.useState<boolean>(true);

    const [firstNameOptions, setFirstNameOptions] = React.useState<IDropdownOption[]>([]);
    const [middleNameOptions, setMiddleNameOptions] = React.useState<IDropdownOption[]>([]);
    const [lastNameOptions, setLastNameOptions] = React.useState<IDropdownOption[]>([]);
    const [costCenterOptions, setCostCenterOptions] = React.useState<IDropdownOption[]>([]);
    
    const [destinationCompanyCodeOptions, setDestinationCompanyCodeOptions] = React.useState<IDropdownOption[]>([]);
    const [startDateOptions, setStartDateOptions] = React.useState<IDropdownOption[]>([]);
    const [jobLevelNameOptions, setJobLevelNameOptions] = React.useState<IDropdownOption[]>([]);

    const [DepartureCompanyCode] = React.useState<string | undefined>(props.relocationFormResponse?.DepartureCompanyCode || '');
    const [DestinationCompanyCode,setDestinationCompanyCode] = React.useState<string | undefined>(props.relocationFormResponse?.DestinationCompanyCode || '');
    const [RequisitionID] = React.useState<string | undefined>(props.relocationFormResponse?.RequisitionID || '');

    const [OnlyNumbersPattern] = React.useState(/^[0-9-?_=.*!@#$%^&*]+$/);
    const [emailPattern] = React.useState(/^(?:\w+[\-!\.])*\w+@(?:\w+[\-!\.])*\w+\.\w+$/);

    React.useImperativeHandle(
        ref,
        () => ({
            validateForm() {
                let invalidFields: string[] = [];
                let values: any = {};

                if (props.isNotNullOrUndefined(firstName)) {
                    values['FirstName'] = firstName;
                    setFirstNameError(undefined);
                } else {
                    values['FirstName'] = null;
                    invalidFields.push('FirstName')
                    setFirstNameError('First Name is required');
                }

                if (props.isNotNullOrUndefined(middleName)) {
                    values['MiddleName'] = middleName;
                } else {
                    values['MiddleName'] = null;
                }

                if (props.isNotNullOrUndefined(lastName)) {
                    values['LastName'] = lastName;
                    setLastNameError(undefined);
                } else {
                    values['LastName'] = null;
                    invalidFields.push('LastName')
                    setLastNameError('Last Name is required');
                }
                if(props.isNotNullOrUndefined(DestinationCompanyCode)){
                    values['DestinationCompanyCode']=DestinationCompanyCode;
                    setDestinationCompanyCodeError(undefined);
                }else{
                    values['DestinationCompanyCode']=null;
                    invalidFields.push('DestinationCompanyCode');
                }

                if (props.isNotNullOrUndefined(employeeNumber)) {
                    values['EmployeeID'] = employeeNumber;
                } else {
                    values['EmployeeID'] = null;
                }

                if (props.isNotNullOrUndefined(startDate)) {
                    values['StartDate'] = startDate;
                } else {
                    values['StartDate'] = null;
                }

                if (props.isNotNullOrUndefined(currentEmail)) {
                    if (!emailPattern.test(currentEmail!)) {
                        values['CurrentEmail'] = null;
                        invalidFields.push('CurrentEmail')
                        setCurrentEmailError('Current Email is not valid.');
                    } else {
                        values['CurrentEmail'] = currentEmail;
                        setCurrentEmailError(undefined);
                    }
                } else {
                    values['CurrentEmail'] = null;
                    invalidFields.push('CurrentEmail')
                    setCurrentEmailError('Current Email is required.');
                }

                if (props.isNotNullOrUndefined(costCenter)) {
                    if (costCenter!.length > 0 && costCenter!.length < 2) {
                        invalidFields.push('CostCenter');
                        values['CostCenter'] = null;
                        setCostCenterError('Cost center should be minimum 2 character.');
                    } else if (!OnlyNumbersPattern.test(costCenter!)) {
                        invalidFields.push('CostCenter');
                        values['CostCenter'] = null;
                        setCostCenterError('Cost center accepts only numbers & special characters.');
                    } else if (costCenter!.length > 1 && [5, 7, 8].find((item) => item === costCenter!.length) === undefined) {
                        invalidFields.push('CostCenter');
                        values['CostCenter'] = null;
                        setCostCenterError('Cost center should be 5,7 or 8 digits.');
                    } else {
                        values['CostCenter'] = costCenter;
                        setCostCenterError(undefined);
                    }
                } else {
                    invalidFields.push('CostCenter');
                    values['CostCenter'] = null;
                    setCostCenterError('Cost Center is required.');
                }

                values['VisaPending'] = visaPending;
                values['JobLevelID'] = jobLevelID;

                return { errors: Array.from(new Set(invalidFields)), values }
            },
            isStillValid() {
                return !(!isFirstNameValid || !isLastNameValid || !isEmployeeIDValid || !isCostCenterValid)
            }
        }),
    );

    const toggleEmployeeProfile = () => {
        setIsOpen(!isOpen);
    };

    const validateEmployeeDetails = async (data: EmployeeProfileData) => {
        if (data?.PersonnelNumber !== null && data?.PersonnelNumber.toString() !== props.relocationFormResponse?.EmployeeID.toString()) {
            setIsEmployeeIDValid(false);
            setIsCostCenterValid(true);
            setIsCurrentEmailValid(true);
            setIsLastNameValid(true);
            setIsMiddleNameValid(true);
            setIsFirstNameValid(true);
            setIsStartDateValid(true);
            setIsDestinationCompanyCodeValid(true);
            setCurrentEmail(props.relocationFormResponse?.PersonalDetails?.CurrentEmail);
            setCostCenter(props.relocationFormResponse?.CostCenter);
            setDestinationCompanyCode(props.relocationFormResponse?.DestinationCompanyCode);
        } else if (data?.PersonnelNumber === 0) {
            setIsEmployeeIDValid(false);
            setCurrentEmail(props.relocationFormResponse?.PersonalDetails?.CurrentEmail);
            setCostCenter(props.relocationFormResponse?.CostCenter);            
            setDestinationCompanyCode(props.relocationFormResponse?.DestinationCompanyCode);
        } else {
            setIsEmployeeIDValid(true);
            if (data.FirstName !== null && data.FirstName !== props.relocationFormResponse?.PersonalDetails?.FirstName) {
                setIsFirstNameValid(false);
                setFirstNameOptions([
                    { key: props.relocationFormResponse?.PersonalDetails?.FirstName, text: props.relocationFormResponse?.PersonalDetails?.FirstName },
                    { key: data.FirstName, text: data.FirstName },
                ]);
            } else {
                setIsFirstNameValid(true);
            }
            if(data.PersonnelAreaCode != null && data.PersonnelAreaCode !== props.relocationFormResponse?.DestinationCompanyCode){
                setIsDestinationCompanyCodeValid(false);
                setDestinationCompanyCodeOptions([
                    { key: props.relocationFormResponse?.DestinationCompanyCode, text: props.relocationFormResponse?.DestinationCompanyCode },
                    { key: data.PersonnelAreaCode, text: data.PersonnelAreaCode },
                ])

            }
            else{
                setIsDestinationCompanyCodeValid(true);
            }
            if (data.MiddleName !== null && data.MiddleName !== props.relocationFormResponse?.PersonalDetails?.MiddleName) {
                setIsMiddleNameValid(false);
                setMiddleNameOptions([
                    { key: props.relocationFormResponse?.PersonalDetails?.MiddleName, text: props.relocationFormResponse?.PersonalDetails?.MiddleName },
                    { key: data.MiddleName, text: data.MiddleName },
                ])
            } else {
                setIsMiddleNameValid(true);
            }
            if (data.LastName !== null && data.LastName !== props.relocationFormResponse?.PersonalDetails?.LastName) {
                setIsLastNameValid(false);
                setLastNameOptions([
                    { key: props.relocationFormResponse?.PersonalDetails?.LastName, text: props.relocationFormResponse?.PersonalDetails?.LastName },
                    { key: data.LastName, text: data.LastName },
                ]);
            } else {
                setIsLastNameValid(true);
            }
            let index = true;
            if (props.isNotNullOrUndefined(data.CurrentEmail)) {
                index = data.CurrentEmail.toLowerCase().indexOf("@microsoft.com") == -1;
            }
            if (!index) {
                setCurrentEmail(data.CurrentEmail)
                setIsCurrentEmailValid(true);
            } else {
                if (!props.isNotNullOrUndefined(data.CurrentEmail)
                    || props.relocationFormResponse?.PersonalDetails?.CurrentEmail.toLowerCase() === data.CurrentEmail.toLowerCase()) {
                    setCurrentEmail(props.relocationFormResponse?.PersonalDetails?.CurrentEmail)
                    setIsCurrentEmailValid(true);
                } else {
                    setIsCurrentEmailValid(false);
                    setCurrentEmail(props.relocationFormResponse?.PersonalDetails?.CurrentEmail)
                }
            }

            let internuniv = false;
            if (props.isNotNullOrUndefined(data.CostCenter)) {
                internuniv = props.relocationFormResponse?.HireTypeID == 3 || props.relocationFormResponse?.HireTypeID == 4;
            }
            if (internuniv) {
                setCostCenter(data.CostCenter);
                setIsCostCenterValid(true);
            }
            else {
                if (!props.isNotNullOrUndefined(data.CostCenter)
                    || props.relocationFormResponse?.CostCenter == data.CostCenter) {
                    setIsCostCenterValid(true);
                    setCostCenter(data.CostCenter);
                }
                else {
                    setIsCostCenterValid(false);
                    setCostCenter(props.relocationFormResponse?.CostCenter);
                    setCostCenterOptions([
                        { key: props.relocationFormResponse?.CostCenter, text: props.relocationFormResponse?.CostCenter },
                        { key: data.CostCenter, text: data.CostCenter },
                    ])
                }
            }

            if (data.StartDate !== props.relocationFormResponse?.StartDate) {
                let startDateOption1 = props.relocationFormResponse?.StartDate.split('T')[0];
                let startDateOption2 = data.StartDate ? data.StartDate.split('T')[0] : 'null'
                setIsStartDateValid(false);
                setStartDateOptions([
                    { key: startDateOption1, text: startDateOption1 },
                    { key: startDateOption2, text: startDateOption2 },
                ]);
                setStartDate(startDateOption1);
            } else {
                setStartDate(data.StartDate);
                setIsStartDateValid(true);
            }
        }
    }

    React.useEffect(() => {
        if (props.relocationFormResponse?.EmployeeID !== 0) {
            validateEmployeeDetails(props.estimateRecordsResponse);
        } else {
            setIsEmployeeIDValid(false);
            setIsFirstNameValid(true);
            setIsMiddleNameValid(true);
            setIsLastNameValid(true);
            setIsCurrentEmailValid(true);
            setIsCostCenterValid(true);
            setIsStartDateValid(true);
            setIsDestinationCompanyCodeValid(true);
            setStartDate(moment(props.relocationFormResponse?.StartDate).format('MM/DD/YYYY'))
            setCurrentEmail(props.relocationFormResponse?.PersonalDetails?.CurrentEmail);
            setCostCenter(props.relocationFormResponse?.CostCenter);            
            setDestinationCompanyCode(props.relocationFormResponse?.DestinationCompanyCode);
        }

        setFirstName(props.relocationFormResponse?.PersonalDetails?.FirstName);
        setMiddleName(props.relocationFormResponse?.PersonalDetails?.MiddleName);
        setLastName(props.relocationFormResponse?.PersonalDetails?.LastName);
        setDestinationCompanyCode(props.relocationFormResponse?.DestinationCompanyCode);
        setEmployeeNumber(String(props.relocationFormResponse?.EmployeeID));
        setVisaPending(props.relocationFormResponse?.PersonalDetails?.VisaPending);
        setJobLevelName(props.jobLevelInfoResponse[props.jobLevelInfoResponse?.findIndex((item: any) => item.ID === props.relocationFormResponse?.JobLevelID)].JobLevelName);

        const jobLevelOptions: IDropdownOption[] = props.jobLevelInfoResponse?.map((item: any) => ({ key: item.ID, text: item.JobLevelName }))
        setJobLevelNameOptions(jobLevelOptions);
        setJobLevelID(props.relocationFormResponse?.JobLevelID?.toString());
    }, [props.estimateRecordsResponse, props.relocationFormResponse])

    const onFirstNameChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
        setFirstName(newValue || '');
        if (props.isNotNullOrUndefined(newValue)) {
            setFirstNameError(undefined);
        }
    };

    const onFirstNameDropDownChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
        setFirstName(item.text);
        if (props.isNotNullOrUndefined(item.text)) {
            setFirstNameError(undefined);
        }
    };

    const onMiddleNameChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
        setMiddleName(newValue || '');
    };

    const onMiddleNameDropDownChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
        setMiddleName(item.text);
    };

    const onLastNameChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
        setLastName(newValue || '');
        if (props.isNotNullOrUndefined(newValue)) {
            setLastNameError(undefined);
        }
    };

    const onLastNameDropDownChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
        setLastName(item.text);
        if (props.isNotNullOrUndefined(item.text)) {
            setLastNameError(undefined);
        }
    };

    const onEmployeeNumberChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
        setEmployeeNumber(newValue!)
    };

    const onCurrentEmailChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setCurrentEmail(newValue || '')
        if (props.isNotNullOrUndefined(newValue)) {
            if (!emailPattern.test(newValue!)) {
                setCurrentEmailError('Current Email is not valid.');
            } else {
                setCurrentEmailError(undefined);
            }
        } else {
            setCurrentEmailError('Current Email is required.');
        }
    };

    const onCostCenterChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setCostCenter(newValue || '');
        if (props.isNotNullOrUndefined(newValue)) {
            if (newValue!.length > 0 && newValue!.length < 2) {
                setCostCenterError('Cost center should be minimum 2 character.');
            } else if (!OnlyNumbersPattern.test(newValue!)) {
                setCostCenterError('Cost center accepts only numbers & special characters.');
            } else if (newValue!.length > 1 && [5, 7, 8].find((item) => item === newValue!.length) === undefined) {
                setCostCenterError('Cost center should be 5,7 or 8 digits.');
            } else {
                setCostCenterError(undefined);
            }
        } else {
            setCostCenterError('Cost Center is required.');
        }
    };

    const onDepartureCompanyCodeChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            let newValue = event.currentTarget.value;
            props.updateEmploymentInfo('DepartureCompanyCode', newValue);
            if (props.isNotNullOrUndefined(newValue)) {
                if (newValue!.length < 2) {
                    setDepartureCompanyCodeError('Departure Company Code should be minimum 2 character.');
                    props.erroredFields(['DepartureCompanyCode']);
                } else if (!OnlyNumbersPattern.test(newValue!)) {
                    setDepartureCompanyCodeError('Departure Company Code accepts only numbers');
                    props.erroredFields(['DepartureCompanyCode']);
                } else {
                    setDepartureCompanyCodeError(undefined);
                    props.clearedFields('DepartureCompanyCode');
                }
            }
        }

    const onDestinationCompanyCodeChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            let newValue = event.currentTarget.value;
            setDestinationCompanyCode(newValue);
            if (props.isNotNullOrUndefined(newValue)) {
                if (newValue!.length < 2) {
                    setDestinationCompanyCodeError('Destination Company Code should be minimum 2 character.');
                } else if (!OnlyNumbersPattern.test(newValue!)) {
                    setDestinationCompanyCodeError('Destination Company Code accepts only numbers.');
                } else {
                    setDestinationCompanyCodeError(undefined);
                }
            }
            else{
                setDestinationCompanyCodeError('Destination Company Code is required');
            }
        }

    const onRequisitionIDChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            let newValue = event.currentTarget.value;
            props.updateEmploymentInfo('RequisitionID', newValue);
            if (!props.isNotNullOrUndefined(newValue)) {
                if (newValue!.length < 2) {
                    setRequisitionIDError('Requisition ID should be minimum 2 character.');
                } else if (!OnlyNumbersPattern.test(newValue!)) {
                    setRequisitionIDError('Requisition ID accepts only numbers.');
                } else {
                    setRequisitionIDError(undefined);
                    props.clearedFields('RequisitionID');
                }
            }
        }
        

    const onCostCenterDropDownChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
        setCostCenter(item.text);
        if (props.isNotNullOrUndefined(item.text)) {
            if (item.text!.length > 0 && item.text!.length < 2) {
                setCostCenterError('Cost center should be minimum 2 character.');
            } else if (!OnlyNumbersPattern.test(item.text!)) {
                setCostCenterError('Cost center accepts only numbers & special characters.');
            } else if (item.text!.length > 1 && [5, 7, 8].find((value) => value === item.text!.length) === undefined) {
                setCostCenterError('Cost center should be 5,7 or 8 digits.');
            } else {
                setCostCenterError(undefined);
            }
        } else {
            setCostCenterError('Cost Center is required.');
        }
    }
    const onDestinationCompanyCodeDropDownChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
        setDestinationCompanyCode(item.text);
        if (props.isNotNullOrUndefined(item.text)) {
            if (item.text!.length < 2) {
                setDestinationCompanyCodeError('Destination Company Code should be minimum 2 character.');
            } else if (!OnlyNumbersPattern.test(item.text!)) {
                setDestinationCompanyCodeError('Destination Company Code accepts only numbers.');
            } else {
                setDestinationCompanyCodeError(undefined);
            }        
        } else {
            setDestinationCompanyCodeError('Destination Company Code is required');
        }
    }

    

    const onStartDateDropDownChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
        setStartDate(item.text)
    }

    const onVisaPendingChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
        setVisaPending(item.key.toString());
    }

    const onJobLevelChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
        setJobLevelID(item.key.toString());
        setJobLevelName(item.text.toString());
    };

    const onRenderTitle = (options: IDropdownOption[]): JSX.Element => {
        return (
            <div>
                <FontIcon aria-label="Invalid" iconName="IncidentTriangle" className={iconColorNames.goldenrod} />
                <span>{options[0].text}</span>
            </div>
        );
    };

    return (
        <Stack className="EmployeeProfile" style={formSectionStyle}>
            <Stack horizontal={true}>
                <Stack.Item style={{ marginTop: '3px' }}>
                    <IconButton
                        aria-labelledby="Employee Profile"
                        title="Employee Profile"
                        iconProps={{
                            iconName: isOpen ? 'ChevronUp' : 'ChevronDown',
                        }}
                        onClick={toggleEmployeeProfile}
                        role="button"
                    />
                </Stack.Item>
                <Stack.Item align="center" styles={accordionHeader}>
                    <h2 style={{ fontSize: '20px' }}>Employee Profile</h2>
                </Stack.Item>
            </Stack>

            {isOpen && (
                <Stack styles={accordionContent}>
                    {(!isFirstNameValid || !isMiddleNameValid || !isLastNameValid || !isEmployeeIDValid || !isCurrentEmailValid || !isCostCenterValid || !isStartDateValid || isDestinationCompanyCodeValid) && <Stack horizontal style={{ marginTop: 10, marginBottom: 10 }}>
                        <FontIcon aria-label="Invalid" iconName="IncidentTriangle" className={iconColorNames.goldenrod} />
                        <span>The latest information from microsoft has been updated below</span>
                    </Stack>}

                    <Stack horizontal wrap tokens={wrapStackTokens} styles={stackStyles}>
                        <Stack.Item {...employeeProfileColumnProps}>
                            <Label>First Name</Label>
                            {!props.isEditMode
                                ? <p style={{ display: 'flex' }}>
                                    {!isFirstNameValid && <FontIcon aria-label="Invalid" iconName="IncidentTriangle" className={iconColorNames.goldenrod} />}
                                    {firstName}
                                </p>
                                : !isFirstNameValid
                                    ? <>
                                        <Dropdown
                                            key='first-name'
                                            ariaLabel='First Name'
                                            onRenderTitle={(options) => {
                                                if (options) {
                                                    return onRenderTitle(options)
                                                } else return null
                                            }}
                                            styles={dropdownStyles}
                                            options={firstNameOptions}
                                            onChange={(event, option) => {
                                                if (option) {
                                                    return onFirstNameDropDownChange(event, option)
                                                }
                                            }
                                            }
                                            selectedKey={firstName}
                                        />
                                    </>
                                    :
                                    <TextField
                                        ariaLabel={`First Name`}
                                        defaultValue={firstName}
                                        onChange={onFirstNameChange}
                                        errorMessage={firstNameError}
                                        role="textbox"
                                        required
                                    />
                            }
                        </Stack.Item>

                        <Stack.Item {...employeeProfileColumnProps}>
                            <Label>Middle Name</Label>
                            {!props.isEditMode
                                ? <p style={{ display: 'flex' }}>
                                    {!isMiddleNameValid && <FontIcon aria-label="Invalid" iconName="IncidentTriangle" className={iconColorNames.goldenrod} />}
                                    {middleName}
                                </p>
                                : !isMiddleNameValid
                                    ? <>
                                        <Dropdown
                                            key='middle-name'
                                            ariaLabel='Middle Name'
                                            onRenderTitle={(options) => {
                                                if (options) {
                                                    return onRenderTitle(options)
                                                } else return null
                                            }}
                                            styles={dropdownStyles}
                                            options={middleNameOptions}
                                            onChange={(event, option) => {
                                                if (option) {
                                                    return onMiddleNameDropDownChange(event, option)
                                                }
                                            }
                                            }
                                            selectedKey={middleName}
                                        />
                                    </>
                                    : <TextField
                                        ariaLabel={`Middle Name`}
                                        defaultValue={middleName}
                                        onChange={onMiddleNameChange}
                                        role="textbox"
                                    />
                            }
                        </Stack.Item>

                        <Stack.Item {...employeeProfileColumnProps}>
                            <Label>Last Name</Label>
                            {!props.isEditMode
                                ? <p style={{ display: 'flex' }}>
                                    {!isLastNameValid && <FontIcon aria-label="Invalid" iconName="IncidentTriangle" className={iconColorNames.goldenrod} />}
                                    {lastName}
                                </p>
                                : !isLastNameValid
                                    ? <>
                                        <Dropdown
                                            key='last-name'
                                            ariaLabel='Last Name'
                                            onRenderTitle={(options) => {
                                                if (options) {
                                                    return onRenderTitle(options)
                                                } else return null
                                            }}
                                            styles={dropdownStyles}
                                            options={lastNameOptions}
                                            onChange={(event, option) => {
                                                if (option) {
                                                    return onLastNameDropDownChange(event, option)
                                                }
                                            }
                                            }
                                            selectedKey={lastName}
                                        />
                                    </>
                                    : <TextField 
                                        ariaLabel={`Last Name`}
                                        defaultValue={lastName}
                                        onChange={onLastNameChange}
                                        errorMessage={lastNameError}
                                        role="textbox"
                                        required
                                    />
                            }
                        </Stack.Item>

                        <Stack.Item {...employeeProfileColumnProps}>
                            <Label>Employee Number</Label>
                            {!props.isEditMode
                                ? <p style={{ display: 'flex' }}>
                                    {!isEmployeeIDValid && <FontIcon aria-label="Invalid" iconName="IncidentTriangle" className={iconColorNames.goldenrod} />}
                                    {employeeNumber !== '0' && employeeNumber}
                                </p>
                                : !isEmployeeIDValid
                                    ? <TextField
                                        iconProps={!isEmployeeIDValid && { iconName: 'IncidentTriangle' }}
                                        ariaLabel={`Employee Number`}
                                        defaultValue={employeeNumber}
                                        onChange={onEmployeeNumberChange}
                                        role="textbox"
                                    />
                                    : <TextField
                                        ariaLabel={`Employee Number`}
                                        defaultValue={employeeNumber}
                                        onChange={onEmployeeNumberChange}
                                        role="textbox"
                                    />
                            }
                        </Stack.Item>

                        <Stack.Item {...employeeProfileColumnProps}>
                            <Label>Email Alias</Label>
                            {!props.isEditMode
                                ? <p style={{ display: 'flex' }}>
                                    {!isCurrentEmailValid && <FontIcon aria-label="Invalid" iconName="IncidentTriangle" className={iconColorNames.goldenrod} />}
                                    {currentEmail}
                                </p>
                                : <TextField
                                    ariaLabel={`Email Alias`}
                                    defaultValue={currentEmail}
                                    onChange={onCurrentEmailChange}
                                    errorMessage={currentEmailError}
                                    role="textbox"
                                />
                            }
                        </Stack.Item>

                        <Stack.Item {...employeeProfileColumnProps}>
                            <Label>Cost Center</Label>
                            {!props.isEditMode
                                ? <p style={{ display: 'flex' }}>
                                    {!isCostCenterValid && <FontIcon aria-label="Invalid" iconName="IncidentTriangle" className={iconColorNames.goldenrod} />}
                                    {costCenter}
                                </p>
                                : !isCostCenterValid
                                    ? <>
                                        <Dropdown
                                            key='cost-center'
                                            ariaLabel='Cost Center'
                                            onRenderTitle={(options) => {
                                                if (options) {
                                                    return onRenderTitle(options)
                                                } else return null
                                            }}
                                            styles={dropdownStyles}
                                            options={costCenterOptions}
                                            onChange={(event, option) => {
                                                if (option) {
                                                    return onCostCenterDropDownChange(event, option)
                                                }
                                            }
                                            }
                                            selectedKey={costCenter}
                                        />
                                    </>
                                    : <TextField
                                        ariaLabel={`Cost Center`}
                                        defaultValue={costCenter}
                                        errorMessage={costCenterError}
                                        onChange={onCostCenterChange}
                                        maxLength={8}
                                        minLength={2}
                                        role="textbox"
                                        required
                                    />
                            }
                        </Stack.Item>

                        {(props.relocationFormResponse?.HireTypeID == 2) && (
                        <Stack.Item {...employeeProfileColumnProps}>
                            <Label>Departure Company Code</Label>
                            {!props.isEditMode
                                ? <p style={{ display: 'flex' }}>
                                    {DepartureCompanyCode}
                                </p>
                                : <TextField
                                    ariaLabel={`Departure Company Code`}
                                    defaultValue={DepartureCompanyCode}
                                    errorMessage={DepartureCompanyCodeError}
                                    onChange={onDepartureCompanyCodeChange}
                                    role="textbox"
                                    required
                                />
                            }
                        </Stack.Item>)}
                        <Stack.Item {...employeeProfileColumnProps}>
                        <Label>Destination Company Code</Label>
                            {!props.isEditMode
                                ? <p style={{ display: 'flex' }}>
                                    {!isDestinationCompanyCodeValid && <FontIcon aria-label="Invalid" iconName="IncidentTriangle" className={iconColorNames.goldenrod} />}
                                    {DestinationCompanyCode}
                                </p>
                                : !isDestinationCompanyCodeValid
                                ? <>
                                    <Dropdown
                                        key='destination-companyCode'
                                        ariaLabel='DestinationCompany Code'
                                        onRenderTitle={(options) => {
                                            if (options) {
                                                return onRenderTitle(options)
                                            } else return null
                                        }}
                                        styles={dropdownStyles}
                                        options={destinationCompanyCodeOptions}
                                        onChange={(event, option) => {
                                            if (option) {
                                                return onDestinationCompanyCodeDropDownChange(event, option)
                                            }
                                        }
                                        }
                                        selectedKey={DestinationCompanyCode}
                                    />
                                </>
                                :                                
                                <TextField
                                    ariaLabel={`Destination Company Code`}
                                    defaultValue={DestinationCompanyCode}
                                    errorMessage={DestinationCompanyCodeError}
                                    onChange={onDestinationCompanyCodeChange}
                                    role="textbox"
                                    required
                                />
                            }
                        </Stack.Item>                       

                        <Stack.Item {...employeeProfileColumnProps}>
                            <Label>Requisition ID</Label>
                            {!props.isEditMode
                                ? <p style={{ display: 'flex' }}>
                                    {RequisitionID}
                                </p>
                                : <TextField
                                    ariaLabel={`Requisition ID`}
                                    defaultValue={RequisitionID}
                                    errorMessage={RequisitionIDError}
                                    onChange={onRequisitionIDChange}
                                    role="textbox"
                                    required
                                />
                            }
                        </Stack.Item>

                        <Stack.Item {...employeeProfileColumnProps}>
                            <Label>Start Date</Label>
                            {!props.isEditMode
                                ? <p style={{ display: 'flex' }}>
                                    {!isStartDateValid && <FontIcon aria-label="Invalid" iconName="IncidentTriangle" className={iconColorNames.goldenrod} />}
                                    {moment(startDate).format('MM/DD/YYYY')}
                                </p>
                                : isStartDateValid ?
                                    <DatePicker

                                        placeholder="Select a date..."
                                        ariaLabel="Select a date"
                                        value={moment(startDate).toDate()}
                                        onSelectDate={(newDate) => setStartDate(newDate?.toISOString() || '')}
                                    />
                                    : <>
                                        <Dropdown
                                            key='start-date'
                                            ariaLabel='Start Date'
                                            onRenderTitle={(options) => {
                                                if (options) {
                                                    return onRenderTitle(options)
                                                } else return null
                                            }}
                                            styles={dropdownStyles}
                                            options={startDateOptions}
                                            onChange={(event, option) => {
                                                if (option) {
                                                    return onStartDateDropDownChange(event, option)
                                                }
                                            }
                                            }
                                            selectedKey={startDate}
                                        />
                                    </>}
                        </Stack.Item>

                        <Stack.Item {...employeeProfileColumnProps}>
                            <Label>Visa Pending</Label>
                            {!props.isEditMode
                                ? <p>{visaPending}</p>
                                : <Dropdown
                                    ariaLabel="visa pending"
                                    aria-readonly
                                    options={[
                                        { key: 'Yes', text: 'Yes' },
                                        { key: 'No', text: 'No' },
                                    ]}
                                    styles={dropdownStyles}
                                    defaultSelectedKey={visaPending}
                                    onChange={(event, option) => {
                                        if (option) {
                                            return onVisaPendingChange(event, option)
                                        }
                                    }
                                    }
                                />
                            }
                        </Stack.Item>

                        <Stack.Item {...employeeProfileColumnProps}>
                            <Label>Job Level</Label>
                            {!props.isEditMode
                                ? <p>{jobLevelName}</p>
                                : <Dropdown
                                    ariaLabel="job level dropdown"
                                    aria-readonly
                                    options={jobLevelNameOptions}
                                    styles={dropdownStyles}
                                    defaultSelectedKey={props.relocationFormResponse?.JobLevelID?.toString()}
                                    onChange={(event, option) => {
                                        if (option) {
                                            return onJobLevelChange(event, option)
                                        }
                                    }
                                    }
                                />
                            }
                        </Stack.Item>                        
                    </Stack>
                </Stack>
            )}
        </Stack>

    )
})