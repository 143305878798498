import * as React from 'react';
import {
    Stack,
    IconButton,
    IStackItemStyles,
    IColumn,
    SelectionMode,
    mergeStyles,
    FontIcon,
    PrimaryButton,
    ShimmeredDetailsList,
    DetailsListLayoutMode,
    IDetailsRowBaseProps,
    MessageBar,
    MessageBarType,
    mergeStyleSets,
    Dropdown,
    Text,
    IDropdownStyles,
    IDropdownOption,
} from '@fluentui/react';
import { ILoggedInUserRoles } from '../../Models/IUserRoles';
import { IAppState } from '../../Common/Redux/AppState';
import { ReduxContext } from '@employee-experience/common';
import { PostOfferExceptionsProps, FileExceptionProps, DeleteRowProps } from '../../Models/IEstimatesForm';
import { ExceptionModal } from './ExceptionModal';
import * as moment from 'moment';
import { useServiceStore } from '../../RootStateContext';
import { WarningModal } from './WarningModal';

const formSectionStyle = {
    paddingTop: 15,
    paddingBottom: 15,
    borderTop: '3px solid #dcdcdc',
};
const accordionHeader: IStackItemStyles = {
    root: {
        padding: 5,
        cursor: 'pointer',
        fontSize: '20px',
    },
};
const accordionContent: IStackItemStyles = {
    root: {
        marginLeft: '50px',
        marginTop: 15,
    },
};
const iconClass = mergeStyles({
    fontSize: 20,
    height: 20,
    width: 20,
    margin: '0 10px',
    cursor: 'pointer',
});
const recentIconClass = mergeStyles({
    fontSize: 10,
    height: 10,
    width: 10,
    margin: '0 5px',
});
const classNames = mergeStyleSets({
    deepSkyBlue: [{
        color: 'deepskyblue'
    }, recentIconClass],
});
const buttonStyles = {
    backgroundColor: 'rgb(0, 120, 212)',
    color: '#FFFFFF',
    marginBottom: '10px',
};
const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: 300 },
    root: { paddingRight: 20 },
};

export type ListItem = {
    id: string;
    ExceptionId: number;
    FileExceptionCreatedOn: string;
    ExceptionName: string;
    ExceptionJustification: string;
    ExceptionAmount: number;
    ExceptionQuantity: number;
    TaxPct: number;
    TotalAmount: number;
    ExceptionAmountManager: number;
    ExceptionBalanceAmount: number;
    ExceptionAction: string;
    remove: string;
    Reminders: string;
    FERecordId: number;
};

export const PostOfferExceptions: React.FC<PostOfferExceptionsProps> = React.forwardRef((props, ref) => {
    const [isOpen, setIsOpen] = React.useState<boolean>(true);
    const { useSelector } = React.useContext(ReduxContext);
    const userRole: ILoggedInUserRoles = useSelector((state: IAppState) => state.root.userReducer.loggedInUserRoles);

    const [isAddExceptionModalOpen, setIsExceptionModalOpen] = React.useState<boolean>(false);
    const [isEditExceptionModal, setIsEditExceptionModal] = React.useState<boolean>(false);
    const [isWarningModalOpen, setIsWarningModalOpen] = React.useState<boolean>(false);
    const [isOptionalBenefit, setisOptionalBenefit] = React.useState<boolean>(false);
    const [canBeMPMException, setcanBeMPMException] = React.useState<boolean>(false);

    const { estimatesFormService } = useServiceStore();

    const [postOfferExceptionsList, setPostOfferExceptionsList] = React.useState<FileExceptionProps[]>([]);
    const [postOfferExceptionColumns, setPostOfferExceptionColumns] = React.useState<IColumn[]>([]);

    const [deletedOfferException, setDeletedOfferException] = React.useState<DeleteRowProps>();
    const [postOfferExceptionAction, setPostOfferExceptionAction] = React.useState<string>('');

    const [isApprovalText, setIsApprovalText] = React.useState<boolean>(false);
    const [employeeAlias, setEmployeeAlias] = React.useState<string>('');
    const [mpmAlias, setMPMAlias] = React.useState<string>(__MPM_APPROVER_ALIAS__);
    const [remainingExceptionBalance, setRemainingExceptionBalance] = React.useState(0);

    const [exceptionsDropdown, setExceptionsDropdown] = React.useState<IDropdownOption[]>([]);
    const [selectedException, setSelectedException] = React.useState<IDropdownOption>({} as IDropdownOption);

    const [selectedRow, setSelectedRow] = React.useState<FileExceptionProps>({} as FileExceptionProps);
    const PolicyEligibleExceptionBalance = ["8", "9", "10", "11", "7", "14", "15", "16", "17", "5"];

    const togglePostOfferExceptions = () => {
        setIsOpen(!isOpen);
    };
    const toggleWarningModal = (flag: boolean) => {
        setIsWarningModalOpen(flag);
    };

    React.useEffect(() => {
        const options: IDropdownOption[] = props.allExceptionsResponse.map((item) => ({
            key: item.ExceptionID,
            text: item.ExceptionName,
        }));
        setExceptionsDropdown(options);
    }, []);



    React.useEffect(() => {
        let postOfferExceptionColumns = [
            {
                key: 'column1', name: 'CREATED ON', fieldName: 'FileExceptionCreatedOn', minWidth: 100, maxWidth: 300,
                onRender: (item: ListItem) => <span>{moment(item.FileExceptionCreatedOn).format('MM/DD/YYYY')}</span>,
            },
            {
                key: 'column2', name: 'CATEGORY', fieldName: 'ExceptionName', minWidth: 100, maxWidth: 300,
                onRender: (item: ListItem) => {
                    if (item.ExceptionAction !== "Approved" && item.ExceptionAction !== "Rejected") {
                        return <a role="link" onKeyPress={()=>editException(item)} onClick={() => editException(item)}>{item.ExceptionName}</a>
                    } else {
                        return <span>{item.ExceptionName}</span>
                    }
                },
            },
            {
                key: 'column3', name: 'JUSTIFICATION', fieldName: 'ExceptionJustification', minWidth: 100, maxWidth: 300,
            },
            {
                key: 'column4', name: 'AMOUNT', fieldName: 'ExceptionAmount', minWidth: 100, maxWidth: 300,
                onRender: (item: ListItem) => <span>{props.currencyFormatter(item.ExceptionAmount)}</span>,
            },
            {
                key: 'column5', name: 'APPROVED AMOUNT', fieldName: 'ExceptionAmountManager', minWidth: 100, maxWidth: 300,
                onRender: (item: ListItem) => <span>{props.currencyFormatter(item.ExceptionAmountManager)}</span>,
            },
            {
                key: 'column6', name: 'EXCEPTION BALANCE', fieldName: 'ExceptionBalanceAmount', minWidth: 100, maxWidth: 300,
                onRender: (item: ListItem) => <span>{props.currencyFormatter((item.ExceptionBalanceAmount < 0) ? 0 : item.ExceptionBalanceAmount)}</span>,
            },
            {
                key: 'column7', name: 'TOTAL', fieldName: 'TotalAmount', minWidth: 100, maxWidth: 300,
                onRender: (item: ListItem) => <span>{props.currencyFormatter(item.TotalAmount)}</span>,
            },
            {
                key: 'column8', name: 'ACTION/STATUS', fieldName: 'ExceptionAction', minWidth: 200, maxWidth: 300,
                onRender: (item: ListItem) => {
                    if (item.ExceptionAction === 'PS') {
                        return (
                            <PrimaryButton
                                text="Send For Approval"
                                onClick={() => sendForApproval(item.FERecordId)}
                                allowDisabledFocus
                                disabled={false}
                                style={buttonStyles}
                            />
                        );
                    } else if (item.ExceptionAction === 'AU') {
                        return 'Authorized';
                    } else if (item.ExceptionAction === 'PA') {
                        return (
                            <>
                                <FontIcon aria-label="Recent" iconName="Recent" className={classNames.deepSkyBlue} />
                                <span>Pending Approval</span>
                            </>
                        );
                    } else if (item.ExceptionAction === "Approved" || item.ExceptionAction === "Rejected") {
                        return (
                            <>
                                <span>{item.ExceptionAction}</span>
                            </>
                        );
                    } else {
                        return 'Unknown';
                    }
                },
            },
            {
                key: 'column9', name: 'REMOVE', fieldName: 'remove', minWidth: 100, maxWidth: 300,
                onRender: (item: any) => {
                    let showDeleteButton = false;
                    switch (item.ExceptionAction) {
                        case 'Rejected':
                            return '-';
                        case 'Approved':
                            switch (props.redirectedFrom)
                            {
                                case 'estimatesQueue':
                                    {
                                        if ((props.commitStatus.toUpperCase() === 'UNCOMMITTED' || props.commitStatus.toUpperCase() === 'PREVIOUSLY COMMITTED') && (userRole.ReloAdmin || userRole.ReloAnalyst))
                                            showDeleteButton = true;
                                    }
                                    break;
                                case 'reloSpecialistQueue':
                                    {
                                        if ((props.commitStatus.toUpperCase() === 'UNCOMMITTED' || props.commitStatus.toUpperCase() === 'PREVIOUSLY COMMITTED') && (userRole.ReloAdmin || userRole.ReloAnalyst))
                                            showDeleteButton = true;
                                    }
                                    break;
                            }
                            break;
                        case 'PA':
                            showDeleteButton = true;
                            break;
                        case 'PS':
                            showDeleteButton = true;
                            break;
                    }

                    if (showDeleteButton) {
                        return (
                            <FontIcon
                                role="button"
                                tabIndex={0}
                                id={item.ExceptionId}
                                aria-label="Delete"
                                iconName="Package"
                                className={iconClass}
                                onClick={() => removeRow(item)}
                            />);
                    }},
            },
        ];
        if(canBeMPMException == false){
            postOfferExceptionColumns = postOfferExceptionColumns.filter((item) => item.name !== 'EXCEPTION BALANCE');
        }
        setPostOfferExceptionColumns(postOfferExceptionColumns);
    }, []);

    React.useEffect(() => {
        setPostOfferExceptionsList([...props.activePostOfferExceptionsResponse]);
    }, [props.activePostOfferExceptionsResponse]);

    React.useEffect(() => {
        if (props.relocationPolictTypeID) {
            let inList = PolicyEligibleExceptionBalance.includes(props.relocationPolictTypeID);
            setcanBeMPMException(inList);
        }
    }, [props.relocationPolictTypeID]);

    React.useEffect(() => {
        const approvedNPMExceptions = postOfferExceptionsList.filter((item) => (item.ExceptionAction === "PA" || item.ExceptionAction ==="Approved") && item.ExceptionBalanceAmount !== 0);
        let total = 5500;
        approvedNPMExceptions.forEach(item => {
            total -= item.ExceptionBalanceAmount;
        })
        setRemainingExceptionBalance((total < 0) ? 0 : total);
    }, [postOfferExceptionsList]);
    const updateListData = () => {
        props.getAllFileExceptions();
    };

    const removeRow = async (item: ListItem) => {
        const response = await estimatesFormService.getIndvFileExcRecord(item.FERecordId);
        setDeletedOfferException({ FERecordId: item.FERecordId, ReloID: props.reloId, ExceptionId: item.ExceptionId });
        setPostOfferExceptionAction(item.ExceptionAction);
        setIsWarningModalOpen(response ? true : false);
    };

    function _onRenderRow(props: IDetailsRowBaseProps | undefined, defaultRender: any | undefined) {
        if (!props || !defaultRender) {
            return null;
        }
        return defaultRender({
            ...props,
            styles: {
                root: {
                    selectors: {
                        '.ms-DetailsRow-cell': {
                            whiteSpace: 'normal',
                            lineHeight: 'normal',
                            alignItems: 'center',
                        },
                        '.ms-DetailsRow-cellTitle': {
                            height: '100%',
                            alignItems: 'center',
                            wordWrap: 'break-word',
                        },
                    },
                },
            },
        });
    }

    const onRenderDetailsHeader = (headerProps: any, defaultRender: any) => {
        if (!headerProps || !defaultRender) {
            return null;
        }
        return defaultRender({
            ...headerProps,
            styles: {
                root: {
                    selectors: {
                        '.ms-DetailsHeader-cell': {
                            whiteSpace: 'normal',
                            textOverflow: 'clip',
                            lineHeight: 'normal',
                            height: '100%',
                        },
                        '.ms-DetailsHeader-cellTitle': {
                            height: '100%',
                            alignItems: 'center',
                        },
                    },
                    height: '60px',
                },
            },
        });
    };

    const sendForApproval = async (FERecordId: number) => {
        const approvalResponse = await estimatesFormService.sendExceptionApproval(FERecordId, props.reloId);
        if (approvalResponse === '') {
            await props.getAllFileExceptions();
            setIsApprovalText(true);
        }
        return approvalResponse;
    };

    const triggerModal = (addExceptionFlag: boolean, addOptionalBenefitFlag: boolean = false) => {
        if (selectedException.key !== undefined) {
            setEmployeeAlias(props.hiringManagerAlias);
            setisOptionalBenefit(addOptionalBenefitFlag);
            setIsExceptionModalOpen(addExceptionFlag);
            setIsEditExceptionModal(!addExceptionFlag);
        } else {
            alert('Please select any exception');
        }
    };

    const onDropdownChange = React.useCallback((event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption) => {
        setSelectedException(item || {} as IDropdownOption);
    }, []);

    const editException = async (item: ListItem) => {
        const response = await estimatesFormService.getIndvFileExcRecord(item.FERecordId);
        if (response.Approver !== null) {
            setEmployeeAlias(response.Approver)
        }
        if(response.MPMApprover !== null){
            setMPMAlias(response.MPMApprover )
        }
        if(response.IsOptionalException){
            setisOptionalBenefit(response.IsOptionalException);
        }
        let temp = {
            key: item.ExceptionId,
            text: item.ExceptionName,
        };
        setSelectedException(temp);
        setSelectedRow({ ...response, FERecordId: item.FERecordId });
        setIsExceptionModalOpen(true);
        setIsEditExceptionModal(true);
    };

    return (
        <Stack className="PostOfferExceptions" style={formSectionStyle}>
            <Stack horizontal>
                <Stack.Item style={{ marginTop: '3px' }}>
                    <IconButton
                        aria-labelledby="PostOfferExceptions"
                        title="PostOfferExceptions"
                        iconProps={{
                            iconName: isOpen ? 'ChevronUp' : 'ChevronDown',
                        }}
                        onClick={togglePostOfferExceptions}
                    />
                </Stack.Item>
                <Stack.Item align="center" styles={accordionHeader}>
                <h2 style={{ fontSize: '20px' }}>Post Offer Revisions</h2>
                </Stack.Item>
            </Stack>

            {isOpen && (
                <Stack styles={accordionContent}>
                    <Stack.Item>
                        Select a Category then click Add Exception or Add Optional Benefit. Once the Exception you added appears
                        below you will need to click Send for Approval in order for the approver to receive it.
                        {canBeMPMException && (
                        <Stack.Item>
                            Remaining Exception Balance: {props.currencyFormatter(remainingExceptionBalance)}
                        </Stack.Item>
                        )}
                    </Stack.Item>
                    {isApprovalText && (
                        <MessageBar
                            delayedRender={false}
                            messageBarType={MessageBarType.success}
                        >
                            Success! Your Revision request is sent for approval.
                        </MessageBar>
                    )}

                    <Stack horizontal horizontalAlign="start" verticalAlign="end">
                        <Dropdown
                            placeholder="Select a Category"
                            options={exceptionsDropdown}
                            styles={dropdownStyles}
                            selectedKey={selectedException ? selectedException.key : null}
                            onChange={onDropdownChange}
                            notifyOnReselect={true}
                        />
                        <PrimaryButton
                            text="Add Exception"
                            onClick={() => triggerModal(true)}
                            style={{ marginRight: '20px' }}
                            allowDisabledFocus
                            disabled={false}
                        />
                        { canBeMPMException &&
                            <PrimaryButton
                                text="Add Optional Benefit"
                                onClick={() => triggerModal(true,true)}
                                allowDisabledFocus
                                disabled={false}
                            />
                        }
                        {isAddExceptionModalOpen && selectedException && (
                            <ExceptionModal
                                isModalOpen={isAddExceptionModalOpen}
                                triggerModal={triggerModal}
                                exceptionsDropdown={exceptionsDropdown}
                                mpmAlias = {mpmAlias}
                                hiringManagerAlias={employeeAlias}
                                allExceptionsResponse={props.allExceptionsResponse}
                                selectedException={selectedException}
                                totalEstimate={props.postOfferExceptionsTotalEstimate}
                                taxAmountsResponse={props.taxAmountsResponse}
                                type="postOfferException"
                                firstAndLastName={props.firstAndLastName}
                                updateListData={updateListData}
                                custFileID={props.custFileID}
                                reloId={props.reloId}
                                isEditException={isEditExceptionModal}
                                selectedRow={selectedRow}
                                currencyFormatter={props.currencyFormatter}
                                getTaxGrossUp={props.getTaxGrossUp}
                                getTaxGrossUpFormatted={props.getTaxGrossUpFormatted}
                                relocationType={props.relocationType}
                                hireType={props.hireType}
                                relocationPolictTypeID={props.relocationPolictTypeID}
                                remainingExceptionBalance={remainingExceptionBalance}
                                commitStatus={props.commitStatus}
                                isValidPositiveNumber={props.isValidPositiveNumber}
                                isNotNullOrUndefined={props.isNotNullOrUndefined}
                                isOptionalException = {isOptionalBenefit}
                            />
                        )}
                    </Stack>
                    <ShimmeredDetailsList
                        items={postOfferExceptionsList}
                        columns={postOfferExceptionColumns}
                        selectionMode={SelectionMode.none}
                        layoutMode={DetailsListLayoutMode.justified}
                        onRenderRow={_onRenderRow}
                        onRenderDetailsHeader={onRenderDetailsHeader}
                    />
                    {postOfferExceptionsList.length === 0 &&
                        <Stack horizontalAlign='center'>
                            <Text>No Records to Display </Text>
                        </Stack>
                    }
                    {isWarningModalOpen && (
                        <WarningModal
                            isModalOpen={isWarningModalOpen}
                            toggleWarningModal={toggleWarningModal}
                            rowDetails={deletedOfferException}
                            getAllFileExceptions={props.getAllFileExceptions}
                            exceptionActionForRow={postOfferExceptionAction}
                        />
                    )}
                </Stack>
            )}
            <Stack.Item align="end" styles={accordionHeader}>
                Total Estimate <strong>{props.currencyFormatter(props.postOfferExceptionsTotalEstimate)}</strong>
            </Stack.Item>
            {isWarningModalOpen && (
                <WarningModal
                    isModalOpen={isWarningModalOpen}
                    toggleWarningModal={toggleWarningModal}
                    rowDetails={deletedOfferException}
                    getAllFileExceptions={props.getAllFileExceptions}
                    exceptionActionForRow={postOfferExceptionAction}
                />
            )}
        </Stack>
    );
})
