import { ReduxContext } from '@employee-experience/common';
import {
    DefaultPalette,
    IconButton,
    IStackItemStyles,
    IStackStyles,
    IStackTokens,
    PrimaryButton,
    Stack,
    MessageBar,
    MessageBarType,
    SpinnerSize,
    Spinner
} from '@fluentui/react';
import * as React from 'react';
import Dropzone from 'react-dropzone';
import { IAppState } from '../../Common/Redux/AppState';
import { IUserRoles, ILoggedInUserRoles } from '../../Models/IUserRoles';
import { useServiceStore } from '../../RootStateContext';
import { UnAuthorized } from '../../Components/QueueTable/Unauthorized';
import { StackDisclaimer } from '../../Components/Shared/DisclaimerText';

import './UploadActualsReport.css';

interface UploadActualsReportProps {}
export const UploadActualsReport: React.FC<UploadActualsReportProps> = (props) => {
    const [selectedFiles, setSelectedFiles] = React.useState<File[]>([]);
    const { useSelector } = React.useContext(ReduxContext);
    const userdata: IUserRoles = useSelector((state: IAppState) => state.root.userReducer.userRoles);
    const userRole: ILoggedInUserRoles = useSelector((state: IAppState) => state.root.userReducer.loggedInUserRoles);
    const { uploadActualsReport } = useServiceStore();
    const [ShowMessageBar, setShowMessageBar] = React.useState<boolean>(false);
    const [bsetUploading, setsetUploading] = React.useState<boolean>(true);
    const [bStatusUpload, setSatusUpload] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [ShowUploadedMessageBar, setShowUploadedMessageBar] = React.useState<boolean>(false);
    const [bStatusUploaded, setSatusUploaded] = React.useState<string>('');

    const _closePanel = () => {
        setSelectedFiles([]);
        setShowUploadedMessageBar(true);
        setSatusUploaded('removed');
    };
    const onDropFile = (file: File[]) => {
        setShowMessageBar(false);
        if (file) {
            setSelectedFiles(file);
            setShowUploadedMessageBar(true);
            setSatusUploaded('uploaded');
        }
    };
    const acceptedFileItems = selectedFiles.map((file) => (
        <li key={file.name}>
            {file.name} - {file.size} bytes{' '}
            <IconButton
                iconProps={{ iconName: 'ChromeClose' }}
                className="BulkUploadDeleteIcon"
                onClick={_closePanel}
                title="Clear"
                tabIndex={0}
                role="button"
                ariaLabel="Clear selected file"
            />
        </li>
    ));
    const onUploadReport = () => {    
        setsetUploading(false);     
        setLoading(true);
        uploadActualsReport.UploadActualsReport(selectedFiles[0], userdata.Alias).then((message) => {                
            setSatusUpload(true);             
        })
        .catch((error) => {
            setLoading(false);
            setSatusUpload(false);
            setsetUploading(true); 
        }).finally(() => {
            setLoading(false);
            setShowMessageBar(true);
            setsetUploading(true); 
            setShowUploadedMessageBar(false);
            setSatusUploaded('');
        });
    };
    // Styles definition
    const stackStyles: IStackStyles = {
        root: {
            height: 100,
        },
    };
    const stackItemStyles: IStackItemStyles = {
        root: {
            alignItems: 'center',
            background: '#faf9f8',
            color: DefaultPalette.black,
            display: 'flex',
            padding: '5px',
            justifyContent: 'center',
        },
    };
    const stackHeaderItemStyles: IStackItemStyles = {
        root: {
            // alignItems: 'center',
            color: DefaultPalette.black,
            display: 'flex',
            padding: '5px',
            justifyContent: 'Left',
            alignItems: 'flex-start',
        },
    };
    // Tokens definition
    const outerStackTokens: IStackTokens = { childrenGap: 5, padding: 5 };
    const innerStackTokens: IStackTokens = {
        childrenGap: 5,
        padding: 10,
    };
    const horizontalGapStackTokens: IStackTokens = { childrenGap: 30 };

    return (
        <Stack>
            {loading && (
                <Stack>
                    <div>
                        <Spinner
                            label="Loading, please wait..."
                            size={SpinnerSize.large}
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                height: '100%',
                                width: '100%',
                                zIndex: 9999999,
                                backgroundColor: 'rgb(255, 255, 255, 255)',
                            }}
                        />
                    </div>
                </Stack>
            )}
            <Stack tokens={outerStackTokens}>
                {(userRole.ReloAnalyst || userRole.ReloAdmin) ? (
                <Stack>
                    <Stack horizontal disableShrink horizontalAlign="space-between" tokens={horizontalGapStackTokens}>
                        <span style={{ marginLeft: '20px' }}>
                            <h1>Upload Actuals Report</h1>
                        </span>
                        {/* <span><ExportToExcel /></span> */}
                    </Stack>
                    {ShowMessageBar && bStatusUpload ?(
                            <MessageBar
                                delayedRender={false}
                                messageBarType={MessageBarType.success}
                                role={'alert'}
                            >
                                Success! Your file uploads correctly.
                            </MessageBar>
                        ) : ShowMessageBar && !bStatusUpload && (<MessageBar
                            delayedRender={false}
                            messageBarType={MessageBarType.error}
                            role={'alert'}
                            >
                            SORRY! The file could not be uploaded. Please check the format and try again.
                        </MessageBar>
                        )
                    }
                    {ShowUploadedMessageBar && bStatusUploaded ==='uploaded' ?(
                            <MessageBar
                                delayedRender={false}
                                messageBarType={MessageBarType.success}
                                role={'alert'}
                            >
                                Success! Your file is ready to Upload. Please click on Upload File button to validate your file.
                            </MessageBar>
                        ) : ShowUploadedMessageBar && bStatusUploaded === 'removed' && (<MessageBar
                            delayedRender={false}
                            messageBarType={MessageBarType.success}
                            role={'alert'}
                            >
                            Success! The file has been removed.
                        </MessageBar>
                        )
                    }
                    <Stack styles={stackStyles} tokens={innerStackTokens}>
                        <Stack.Item grow={3} styles={stackItemStyles}>
                            <Dropzone onDrop={onDropFile}>
                                {({ getRootProps, getInputProps }) => (
                                    <section className="container dragContainer">
                                        <div {...getRootProps({ className: 'dropzone' })}>
                                            <input {...getInputProps()} />
                                            <p style={{color:"black"}}>Drag n Drop or Click to select file</p>
                                        </div>
                                    </section>
                                )}
                            </Dropzone>
                        </Stack.Item>
                    </Stack>
                    {selectedFiles && selectedFiles.length > 0 && (
                        <Stack grow={4} styles={stackHeaderItemStyles}>
                            <h2> Selected File</h2>
                            <ul>{acceptedFileItems}</ul>
                        </Stack>
                    )}
                    <Stack grow styles={stackHeaderItemStyles}>
                        <PrimaryButton tabIndex={0} role="button" onClick={onUploadReport} disabled={(selectedFiles.length > 0 && bsetUploading) ? false : true}>
                            Upload File
                        </PrimaryButton>
                    </Stack>
                    {StackDisclaimer()}
                </Stack>
                ) : (
                    <UnAuthorized />
                )}
            </Stack>
        </Stack>
    );
};
