import * as React from 'react';
import {
    buildColumns,
    PrimaryButton,
    DetailsList,
    IColumn,
    DetailsRow,
    IDetailsRowBaseProps,
    IDetailsRowStyles,
    Label,
    TextField,
    DetailsListLayoutMode
} from '@fluentui/react';
import { ReloException } from '../../Models/INewRelocationRequest';
import { ExceptionsEntity } from '../../Models/IReviewForm';

type ExceptionProps = {
    sortedItems: ReloException[];
    removeExceptionHandler: Function;
    handleUpdateUnit: Function;
    exceptionComponentUpdated: number;
    relocationPolicyID: number;
    exceptionsResponse: ExceptionsEntity[];
};

export const ExceptionsList: React.FC<ExceptionProps> = (props) => {
    const [sortedItems, setSortedItems] = React.useState<ReloException[]>([]);
    const [columns, setColumns] = React.useState<IColumn[]>();
    const [dollarFlag, setDollarFlag] = React.useState(false);
    const [localCurrencyFlag, setLocalCurrencyFlag] = React.useState(false);

    const flag = usePrevious(props.exceptionComponentUpdated);
    const dollarSet = [2, 4, 5, 7];
    const localCurrencySet = [1, 3, 6, 8];
    
    React.useEffect(() => {
        setSortedItems(props.sortedItems);
        setColumns(_buildColumns(props.sortedItems));
    }, []);

    React.useEffect(() => {
        if (flag !== props.exceptionComponentUpdated) {
            setSortedItems(props.sortedItems);
            setColumns(_buildColumns(props.sortedItems));
            let curFlag: boolean = false;
            let dolflag: boolean = false;

            if (localCurrencySet.some((item) => item == props.relocationPolicyID)) {
                curFlag = true;
            }
            if (dollarSet.some((item) => item == props.relocationPolicyID)) {
                dolflag = true;
            }

            setLocalCurrencyFlag(curFlag);
            setDollarFlag(dolflag);
        }
    }, [props]);

    function _onRenderRow(props: IDetailsRowBaseProps | undefined, defaultRender: any | undefined) {
        const customStyles: Partial<IDetailsRowStyles> = {};

        customStyles.root = {
            selectors: {
                '.ms-DetailsRow-cell': {
                    whiteSpace: 'normal',
                    lineHeight: 'normal',
                },
                '.ms-DetailsRow-cellTitle': {
                    height: '100%',
                    alignItems: 'center',
                    wordWrap: 'break-word',
                },
            },
        };
        return (
            <DetailsRow {...props} styles={customStyles}>
                {defaultRender && defaultRender(props)}{' '}
            </DetailsRow>
        );
    }

    React.useEffect(() => {
        let btn = document.querySelectorAll('button');
        let inp = document.querySelectorAll('input');
        inp.forEach((item)=>{
            if(item.getAttribute('type')=='text')
            {
                if(!(item.hasAttribute('aria-expanded')||item.hasAttribute('aria-checked')))
                {                    
                  item.setAttribute('role','textbox');
                }
            }
        })
        btn.forEach((item)=>{
            if(item.getAttribute('type')=='button')
            {
                if(!(item.hasAttribute('aria-expanded')||item.hasAttribute('aria-checked')))
                {                    
                    item.setAttribute('role','button');
                }
            }
            if(item.getAttribute('aria-hidden')=='true')
                {
                    item.setAttribute('disabled','true');
                }
        })      
    });

    return (
        <div className="ExceptionSection">
            <DetailsList
                items={sortedItems}
                setKey="set"
                columns={columns}
                onRenderItemColumn={_renderItemColumn}
                onColumnHeaderClick={_onColumnClick}
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                checkButtonAriaLabel="select row"
                checkboxVisibility={2}
                ariaLabelForGrid="Exceptions List"
                onRenderRow={_onRenderRow}
                layoutMode={DetailsListLayoutMode.justified}
            />
        </div>
    );

    function _buildColumns(items: any): IColumn[] {
        const currentColumns = buildColumns(items)
            .filter(col => col.key === 'ExceptionName' ||col.key === 'Unit' || col.key === 'Remove');

            let newColumns: any[] = [];
            currentColumns.forEach((col: any) => {
            if (col.key === 'ExceptionName') {
                newColumns.push({ ...col, name: 'Exceptions' })
            } else if (col.key === 'Unit') {
                newColumns.push({ ...col, name: 'Units/Amount' })
            } else if (col.key === 'Remove') {
                newColumns.push({ ...col, name: 'Remove' })
            }
        });

        return newColumns;
    }

    function _renderItemColumn(
        item: ReloException | undefined,
        index: number | undefined,
        column: IColumn | undefined
    ) {
        let exceptionListLabelID = 'ExceptionName' + index;

        switch (column!.key) {
            case 'ExceptionName':
                return <Label htmlFor={exceptionListLabelID}>{item!.ExceptionName}</Label>;

            case 'Unit':
                if (item!.UnitType == 'N/A') {
                    return <Label>Per values provided in Cost Estimate</Label>;
                } else if (item!.UnitType === 'Free Field' || item!.UnitType === 'Unit/per hour') {
                    return (
                        <span>
                            {dollarFlag && <span>$</span>}
                            {localCurrencyFlag && <span>(In local currency)</span>}
                            <TextField
                                id={exceptionListLabelID}
                                value={item?.Amount}
                                ariaLabel={`${item!.ExceptionName}`}
                                onChange={(e) => {
                                    props.handleUpdateUnit(index, 'Amount', e.currentTarget.value);
                                }}
                                onGetErrorMessage={(e) => {
                                    return totalEstimateCalculationError(e, item!, 'Amount');
                                }}
                            ></TextField>
                        </span>
                    );
                } else if (item!.UnitType == 'Unit') {
                    return (
                        <span style={{whiteSpace: "normal"}}
                        >
                            <TextField
                                id={exceptionListLabelID}
                                ariaLabel={`${item!.ExceptionName}`}
                                value={item?.Unit}
                                onChange={(e) => {
                                    props.handleUpdateUnit(index, 'Unit', e.currentTarget.value);
                                }}
                                onGetErrorMessage={(e) => {
                                    return totalEstimateCalculationError(e, item!, 'Unit');
                                }}
                            ></TextField>
                        </span>
                    );
                }
            case 'Remove':
                return (
                    <span>
                        <PrimaryButton
                            text="Remove"
                            onClick={(event) => {
                                var items = sortedItems;
                                setSortedItems(items.slice(0, index).concat(items.slice(index! + 1)));
                                props.removeExceptionHandler(index);
                            }}
                            allowDisabledFocus
                            disabled={false}
                        ></PrimaryButton>
                    </span>
                );

            default:
                return;
        }
    }

    function _onColumnClick(event: React.MouseEvent<HTMLElement> | undefined, column: IColumn | undefined): void {
        let sortedItem = sortedItems;
        let isSortedDescending = column!.isSortedDescending;
        if (column!.isSorted) {
            isSortedDescending = !isSortedDescending;
        }
        sortedItem = _copyAndSort(sortedItem, column!.fieldName!, isSortedDescending);
        setSortedItems(sortedItem);
        setColumns(
            columns?.map((col) => {
                col.isSorted = col.key === column!.key;

                if (col.isSorted) {
                    col.isSortedDescending = isSortedDescending;
                }
                return col;
            })
        );
    }

    async function totalEstimateCalculationError(
        value: string,
        selectedException: ReloException,
        passedUnitType: string
    ): Promise<string> {
        let selectedExceptionEntity = props.exceptionsResponse.filter((item) => {
            return item.ExceptionID === selectedException.ExceptionID;
        })[0];
        let newAmount = (!isNaN(Number(value)) && 0 < Number(value)) ? Number(value) : 1;
        if (passedUnitType === 'Unit' && !isNaN(selectedExceptionEntity.Actual_Amount)) {
            newAmount = newAmount * selectedExceptionEntity.Actual_Amount;
        }
        if (!isNaN(selectedExceptionEntity.MaximumAmount) &&
            selectedExceptionEntity.MaximumAmount < newAmount) {
                return ('Exception amount of ' +
                numberWithCommas(newAmount.toString()) +
                ' is greater than the maximum available amount of ' +
                numberWithCommas(selectedExceptionEntity.MaximumAmount.toString()) +
                ' available. Please re-enter the ' + passedUnitType);
        }
        
        return '';
    }

    function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
        const key = columnKey as keyof T;
        return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    }

    function numberWithCommas(x: string) {
        if (x !== undefined && x != null && x != '') return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        else return '0';
    }

    function usePrevious(value: any) {
        const ref = React.useRef();
        React.useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }
};
