import { IColumn } from '@fluentui/react';

export const SentToFileSetupColumns: IColumn[] = [
    {
        key: 'ReloID',
        name: 'RECORD ID',
        fieldName: 'ReloID',
        minWidth: 90,
        maxWidth: 90,
    },
    {
        key: 'CustFileID',
        name: 'CUSTOMER FILE ID',
        fieldName: 'CustFileID',
        minWidth: 140,
        maxWidth: 140,
    },    
    {
        key: 'HireType',
        name: 'HIRE TYPE',
        fieldName: 'HireType',
        minWidth: 90,
        maxWidth: 90,
    },
    {
        key: 'DestinationCountry',
        name: 'DESTINATION COUNTRY',
        fieldName: 'DestinationCountry',
        minWidth: 160,
        maxWidth: 160,
    },
    {
        key: 'SendtofileSetupComments',
        name: 'COMMENTS',
        fieldName: 'SendtofileSetupComments',
        minWidth: 140,
        maxWidth: 140,
    },
    {
        key: 'LastUpdatedBySyncJob',
        name: 'LAST UPDATED DATE',
        fieldName: 'LastUpdatedBySyncJob',
        minWidth: 140,
        maxWidth: 140,
    },
    {
        key: 'FlagSLA3days',
        name: 'SLA 3 DAYS FLAG',
        fieldName: 'FlagSLA3days',
        minWidth: 120,
        maxWidth: 120,
    },
];
