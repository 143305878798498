import * as React from 'react';
import {
    Dialog,
    DialogType,
    DialogFooter,
    PrimaryButton,
    hiddenContentStyle,
    mergeStyles,
    PrimaryButton,
} from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import { GenericNotification } from '../GenericNotification';
import { useServiceStore } from '../../../RootStateContext';
import { Redirect } from 'react-router-dom';

const dialogStyles = { main: { maxWidth: 700, borderTop: '5px solid Orange' } };
const screenReaderOnly = mergeStyles(hiddenContentStyle);

// ---------------------------------------------------------------------------
export const DeleteFormPopUp: React.FC<{ dismiss: any; recorID: string }> = ({ dismiss, recorID }) => {
    const { startNewReloRequestService } = useServiceStore();
    const [isRedirect, setIsRedirect] = React.useState<boolean>(false);

    const labelId: string = useId('deleteDialogLabel');
    const subTextId: string = useId('deleteSubTextLabel');
    const modalProps = React.useMemo(
        () => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,
            isBlocking: true,
            styles: dialogStyles,
        }),
        [labelId, subTextId]
    );
    const dialogContentProps = {
        type: DialogType.normal,
        title: 'Warning! Deleting this e-form cannot be undone.',
        closeButtonAriaLabel: 'Close',
        subText: 'Are you sure you want to delete the e-form?',
    };

    // NavigateTO after delete
    const redirectAfterDeletion = () => {
        setIsRedirect(true);
    };

    const deleteRecord = async (reloID: string) => {
        await startNewReloRequestService
            .DeleteReloID(parseInt(reloID))
            .then((result) => {
                if (result.status == 200) {
                    <GenericNotification title="Success" msg="record has been deleted." fx={redirectAfterDeletion} />;
                    setIsRedirect(true);
                }
            })
            .catch((error) => {
                <GenericNotification title="Failure" msg="Error Deleting the Recors, please try again" fx={() => {}} />;
            });
    };

    return (
        <>
            <label id={labelId} className={screenReaderOnly}>
                Delete Form Pop Up
            </label>
            <label id={subTextId} className={screenReaderOnly}>
                Are you sure you want to delete the e-form?
            </label>

            <Dialog hidden={false} onDismiss={dismiss} dialogContentProps={dialogContentProps} modalProps={modalProps}>
                <DialogFooter>
                    <PrimaryButton onClick={dismiss} text="Keep e-form" />
                    <PrimaryButton
                        onClick={async () => {
                            await deleteRecord(recorID);
                        }}
                        text="Delete e-form"
                    />
                </DialogFooter>
            </Dialog>
            {isRedirect && <Redirect to="/MyReloQueue" />}
        </>
    );
};
