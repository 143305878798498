import { IHttpClient, IHttpClientResult } from '@employee-experience/common';
import { IPendingExceptionQueueForm, PendingExceptionQueue, ReloSpecialists } from '../../Models/IPendingExceptionQueue';

export class PendingExceptionQueueQueueService {
    private httpClient: IHttpClient;
    private url: string;

    public constructor(httpClient: IHttpClient) {
        this.httpClient = httpClient;
        this.url = REACT_APP_RELO_API_URL;
    }

    public async GetExceptionDetails(Flag: number, FileID: number): Promise<IPendingExceptionQueueForm[]> {
        const response: IHttpClientResult<IPendingExceptionQueueForm[]> = await this.httpClient.request({
            url: `${this.url}/api/PendingExceptions/GetExceptionDetails/${Flag}/${FileID}?flag=${Flag}&fileID=${FileID}`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    }

    public async GetExceptionDetailsSearch(Flag: number, EmployeeIdentifier: string): Promise<PendingExceptionQueue[]> {
        const response: any = await this.httpClient.request({
            url: `${this.url}/api/PendingExceptions/GetExceptionDetails/${Flag}/${EmployeeIdentifier}?flag=${Flag}&fileID=${EmployeeIdentifier}`,
            resource: __CLIENT_ID__,
        });
        if (typeof response?.data === 'string' && response?.data.includes('An Error has occured contact the admin with this code')) {
            return []
        } else {
            return response?.data;
        }
    }

    public async GetReloSpecialistList(): Promise<ReloSpecialists[]> {
        const response: IHttpClientResult<ReloSpecialists[]> = await this.httpClient.request({
            url: `${this.url}/api//PendingExceptions/GetReloSpecialistList`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    }

    public async GetPendingExceptionsByRSAlias(Alias: string): Promise<IPendingExceptionQueueForm[]> {
        const response: IHttpClientResult<IPendingExceptionQueueForm[]> = await this.httpClient.request({
            url: `${this.url}/api//PendingExceptions/GetPendingExceptionsByRSAlias/${Alias}?alias=${Alias}`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    }
}
