import * as React from 'react';
import {
    buildColumns,
    DetailsList,
    DetailsListLayoutMode,
    CheckboxVisibility,
    IColumn,
    Label,
    SelectionMode,
    Stack,
    Text,
    TextField,
} from '@fluentui/react';
import { OptionalBenefitMapping } from '../../Models/IReviewForm';

type OptionalBenefitsProps = {
    optionalBenefitMapping: OptionalBenefitMapping[];
    reloPolicyId: number;
};

const column: IColumn[] = [
    {
        key: 'optionalBenefitName',
        name: 'Benefits',
        fieldName: 'optionalBenefitName',
        maxWidth: 400,
        minWidth: 250,
        ariaLabel: 'Column for name of optional benefit',
        columnActionsMode: 1,
        isCollapsable: false,
        isCollapsible: false,
        isGrouped: false,
        isMultiline: false,
        isResizable: undefined,
        isRowHeader: false,
        isSorted: false,
        isSortedDescending: false,
        onColumnClick: undefined,
    },
    {
        key: 'precedingString',
        name: '',
        fieldName: 'precedingString',
        maxWidth: 180,
        minWidth: 50,
        ariaLabel: 'Column for unit preceding string',
        columnActionsMode: 1,
        isCollapsable: false,
        isCollapsible: false,
        isGrouped: false,
        isMultiline: false,
        isResizable: undefined,
        isRowHeader: false,
        isSorted: false,
        isSortedDescending: false,
        onColumnClick: undefined,
    },
    {
        key: 'unit',
        name: 'Unit',
        fieldName: 'unit',
        maxWidth: 200,
        minWidth: 50,
        ariaLabel: 'Column for unit of optional benefit',
        columnActionsMode: 1,
        isCollapsable: false,
        isCollapsible: false,
        isGrouped: false,
        isMultiline: false,
        isResizable: undefined,
        isRowHeader: false,
        isSorted: false,
        isSortedDescending: false,
        onColumnClick: undefined,
    },
];

export const OptionalBenefitsList: React.FC<OptionalBenefitsProps> = (props) => {
    const [items, setItems] = React.useState<OptionalBenefitMapping[]>([]);
    const [columns, setColumns] = React.useState<IColumn[]>(column);

    React.useEffect(() => {
        if (props.optionalBenefitMapping.length > 0) {
            mapBenefits();
        }
    }, [props]);

    const dollarSet = [2, 4, 5, 7];
    const localCurrencySet = [1, 3, 6, 8];

    const [dollarFlag, setDollarFlag] = React.useState(false);

    const [localCurrencyFlag, setLocalCurrencyFlag] = React.useState(false);

    const mapBenefits = () => {
        if (props.optionalBenefitMapping.length > 0) {
            let curFlag: boolean = false;
            let dolflag: boolean = false;

            if (localCurrencySet.some((item) => item == props.reloPolicyId)) {
                curFlag = true;
            }
            if (dollarSet.some((item) => item == props.reloPolicyId)) {
                dolflag = true;
            }

            setLocalCurrencyFlag(curFlag);
            setDollarFlag(dolflag);
            setItems(props.optionalBenefitMapping);
            _buildColumns(props.optionalBenefitMapping);
        }
    };

    return (
        <>
            <DetailsList
                items={items}
                columns={columns}
                selectionMode={SelectionMode.none}
                onRenderItemColumn={_renderItemColumn}
                checkboxVisibility={CheckboxVisibility.always}
                layoutMode={DetailsListLayoutMode.justified}
                ariaLabelForGrid="Optional Benefits List"
            />
        </>
    );

    function _renderItemColumn(
        item: OptionalBenefitMapping | undefined,
        index: number | undefined,
        column: IColumn | undefined
    ) {
        const fieldContent = item![column!.fieldName as keyof OptionalBenefitMapping] as string;
        var unitType = item!.UnitType;

        var optionalBenefitId = 'OptionalBenefit' + index;
        switch (column!.key) {
            case 'OptionalBeniftName':
                if (unitType == 'N/A') {
                    return <Label>{fieldContent}</Label>;
                } else {
                    return <Label htmlFor={optionalBenefitId}>{fieldContent}</Label>;
                }
            case 'Unit':
                if (unitType == 'N/A') {
                    return <span>Per values provided in Cost Estimate</span>;
                } else if (unitType == 'Unit') {
                    return (
                        <TextField
                            id={optionalBenefitId + 'minMax'}
                            defaultValue={item!.Unit}
                            typeof={'number'}
                            readOnly={true}
                            ariaLabel={`${item?.OptionalBeniftName} Value`}
                        ></TextField>
                    );
                } else if (unitType == 'FreeField') {
                    return (
                        <Stack>
                            {dollarFlag && <span>$</span>}
                            {localCurrencyFlag && <span>(In local currency)</span>}

                            <TextField
                                id={optionalBenefitId + 'FreeField'}
                                defaultValue={item?.Amount}
                                typeof={'number'}
                                readOnly={true}
                            ></TextField>
                        </Stack>
                    );
                }
            case 'UnitType':
                return;
            default:
                return;
        }
    }

    function _buildColumns(items: any) {
        const newolmn = buildColumns(items);
        let columns: any[] = [];
        newolmn.forEach((column) => {
            //Using unshift to place in the reverse order so it display in the correct order in the UI
            if (column.key === 'OptionalBeniftName') {
                columns.unshift({ ...column, name: 'Optional Benefits Name' });
            }
            if (column.key === 'Unit') {
                columns.unshift({ ...column, name: 'Unit' });
            }
        });
        setColumns(columns);
    }
};
