import { mergeStyleSets, memoizeFunction } from '@fluentui/react';

export const getStyles = memoizeFunction(() => {
    return mergeStyleSets({
        filterContainer: {
            minWidth: 250,
            borderRight: '1px solid #ccc',
            paddingRight: 20,
        },
        sectionTitles: {
            fontWeight: 600,
            fontSize: 14,
            marginBottom: 10,
        },
        checkbox: {
            margin: 5,
        },
        filterTitle: {
            marginBottom: 10,
            marginTop: 10
        },
    });
});
