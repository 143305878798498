import * as React from 'react';
import {
    buildColumns,
    DetailsList,
    DetailsListLayoutMode,
    CheckboxVisibility,
    IColumn,
    Label,
    MarqueeSelection,
    Selection,
    SelectionMode,
    TextField,
    Stack,
    DetailsRow,
    IDetailsRowBaseProps,
    IDetailsRowFieldsProps,
    DetailsRowFields,
} from '@fluentui/react';
import { OptionalBenefitsType } from '../../Models/INewRelocationRequest';

type OptionalBenefitsProps = {
    allOptionalBenefits: any[];
    reloPolicyId: number;
    handleOptionalBenefitUnitUpdate: Function;
    relocationpolicyTypeID: string | number;
};

export const OptionalBenefitsList: React.FC<OptionalBenefitsProps> = (props) => {
    const [items, setItems] = React.useState<OptionalBenefitsType[]>([]);
    const [columns, setColumns] = React.useState<IColumn[]>([]);
    const [dollarFlag, setDollarFlag] = React.useState(false);
    const [localCurrencyFlag, setLocalCurrencyFlag] = React.useState(false);
    const dollarSet = [2, 4, 5, 7];
    const localCurrencySet = [1, 3, 6, 8];

    const [reConfigSelection, setReConfigSelection] = React.useState(0);

    const ref = React.useRef<any>(null);

    React.useLayoutEffect(() => {
        if (props.relocationpolicyTypeID == 50 || props.relocationpolicyTypeID == 51) {
            let checkboxNodes = ref?.current?.querySelectorAll("[role='checkbox']");
            if (checkboxNodes) {
                let node = checkboxNodes["0"]
                node.innerHTML = '';
            }
        }

    }, [items])

    let _selection = React.useMemo(
        () =>
            new Selection({
                onSelectionChanged: () => {
                    _getSelectionDetails();
                },
                selectionMode: SelectionMode.multiple,
                getKey: (item: any) => item.OptionalBenifitID,
                canSelectItem: (item) => {
                    // _selection.setAllSelected(false);
                    // _selection.toggleAllSelected()
                    if (props.relocationpolicyTypeID == 50 && _selection.getSelectedCount() == 1) {
                        if (_selection.isKeySelected(item.OptionalBenifitID)) {
                            return true
                        } else {
                            return false
                        }
                    } else if (props.relocationpolicyTypeID == 51 && _selection.getSelectedCount() == 2) {
                        if (_selection.isKeySelected(item.OptionalBenifitID)) {
                            return true
                        }
                        return false
                    }
                    return true
                }
            }),
        [reConfigSelection]
    );

    function onUnitChange(
        benefit: OptionalBenefitsType,
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
    ) {
        const tempUnit = event.currentTarget.value;
        if (tempUnit !== '' && !isNaN(Number(tempUnit)) && 0 <= Number(tempUnit)) {
            benefit.Unit = Number(tempUnit).toString();
            if (Number(benefit.UnitMaxValue) < Number(tempUnit)) {
                benefit.Unit = benefit.UnitMaxValue;
            }
        } else {
            benefit.Unit = '';
        }
        const selectedItems = _selection.getSelection();
        selectedItems.forEach((item: any) => {
            if (benefit.OptionalBenifitID === item.OptionalBenifitID) {
                item = benefit;
            }
            item.disableStatus = false;
        });
        props.handleOptionalBenefitUnitUpdate(selectedItems);
    }

    function onAmountChange(
        benefit: OptionalBenefitsType,
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
    ) {
        const tempAmount = event.currentTarget.value;
        if (tempAmount !== '' && !isNaN(Number(tempAmount)) && 0 <= Number(tempAmount)) {
            benefit.Amount = tempAmount;
            if (Number(benefit.MaxAmount) < Number(tempAmount)) {
                benefit.Amount = benefit.MaxAmount.toString();
            }
        } else {
            benefit.Amount = '';
        }

        const selectedItems = _selection.getSelection();
        selectedItems.forEach((item: any) => {
            if (benefit.OptionalBenifitID === item.OptionalBenifitID) {
                item = benefit;
            }
            item.disableStatus = false;
        });
        props.handleOptionalBenefitUnitUpdate(selectedItems);
    }

    const _getSelectionDetails = (): any => {
        const selectedItems = _selection.getSelection();

        selectedItems.forEach((item: any) => {
            item.disableStatus = false;
        });
        props.handleOptionalBenefitUnitUpdate(selectedItems);

        if (props.relocationpolicyTypeID == 50) {
            setReConfigSelection(_selection.getSelectedCount());
        } else if (props.relocationpolicyTypeID == 51) {
            setReConfigSelection(_selection.getSelectedCount());
        }
    };

    function mapSelectedRows(): any {
        const newSelection = _selection;
        let preSelectedItems = props.allOptionalBenefits.filter((item) => {
            if (!item.disableStatus) {
                return item;
            }
        });

        newSelection.setItems(props.allOptionalBenefits);
        preSelectedItems.map((selectedItem: any, s) => {
            let index = props.allOptionalBenefits.indexOf(selectedItem);
            newSelection.setIndexSelected(index, true, false);
        });
    }

    React.useEffect(() => {
        mapBenefits();
    }, [props]);

    React.useEffect(() => {
        mapSelectedRows();
    }, []);

    const mapBenefits = () => {
        if (props.allOptionalBenefits.length > 0) {
            let curFlag: boolean = false;
            let dolflag: boolean = false;

            if (localCurrencySet.some((item) => item == props.reloPolicyId)) {
                curFlag = true;
            }
            if (dollarSet.some((item) => item == props.reloPolicyId)) {
                dolflag = true;
            }

            setLocalCurrencyFlag(curFlag);
            setDollarFlag(dolflag);
            setItems(props.allOptionalBenefits);
            _buildColumns(props.allOptionalBenefits);
        }
    };

    const _onRenderRow = (props: IDetailsRowBaseProps | undefined) => {
        if (props) {
            return <DetailsRow rowFieldsAs={onRenderRowFields} {...props} />
        }
        return null
    }

    const onRenderRowFields = (props: IDetailsRowFieldsProps) => {
        return (
            <span data-selection-disabled={true}>
                <DetailsRowFields {...props} />
            </span>
        );
    }

    return (
        <>
            <div className="OptionalBenefitsSection">
                {items.length > 0 && (
                    <div ref={ref}>
                        <MarqueeSelection selection={_selection}>
                            <DetailsList
                                items={items}
                                columns={columns}
                                setKey="set"
                                layoutMode={DetailsListLayoutMode.justified}
                                selection={_selection}
                                selectionPreservedOnEmptyClick={true}
                                onRenderItemColumn={_renderItemColumn}
                                checkboxVisibility={CheckboxVisibility.always}
                                ariaLabelForSelectionColumn="Toggle selection"
                                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                                checkButtonAriaLabel="select row"
                                ariaLabelForGrid="Optional Benefits List"
                                onRenderRow={_onRenderRow}
                            />
                        </MarqueeSelection>
                    </div>
                )}
            </div>
        </>
    );

    function _renderItemColumn(
        item: OptionalBenefitsType | undefined,
        index: number | undefined,
        column: IColumn | undefined
    ) {
        const fieldContent = item![column!.fieldName as keyof OptionalBenefitsType] as string;
        var unitType = item!.UnitType;

        var optionalBenefitId = 'OptionalBenefit' + index;
        switch (column!.key) {
            case 'OptionalBeniftName':
                if (unitType == 'N/A') {
                    return <Label>{fieldContent}</Label>;
                } else {
                    return <Label htmlFor={optionalBenefitId}>{fieldContent}</Label>;
                }
            case 'Unit':
                if (unitType == 'N/A') {
                    return <span>Per values provided in Cost Estimate</span>;
                } else if (unitType == 'Unit') {
                    return (
                        <TextField
                            id={optionalBenefitId + 'Unit'}
                            value={item?.Unit}
                            disabled={item?.disableStatus}
                            onChange={(e) => {
                                return onUnitChange(item!, e);
                            }}
                            ariaLabel={`${item?.OptionalBeniftName} Value`}
                        ></TextField>
                    );
                } else if (unitType == 'FreeField') {
                    return (
                        <Stack>
                            {dollarFlag && <span>$</span>}
                            {localCurrencyFlag && <span>(In local currency)</span>}

                            <TextField
                                id={optionalBenefitId + 'FreeField'}
                                value={item?.Amount}
                                disabled={item?.disableStatus}
                                onChange={(e) => {
                                    onAmountChange(item!, e);
                                }}
                                ariaLabel={`${item?.OptionalBeniftName} Value`}
                            ></TextField>
                        </Stack>
                    );
                }
            case 'UnitType':
                return;
            default:
                return;
        }
    }

    function _buildColumns(items: any) {
        const currentColumns = buildColumns(items);
        let newColumns: any[] = [];
        currentColumns.forEach((column: any) => {
            //Using unshift to place in the reverse order so it display in the correct order in the UI
            if (column.key === 'OptionalBeniftName') {
                newColumns.unshift({ ...column, name: 'Optional Benefits Name' });
            } else if (column.key === 'Unit') {
                newColumns.unshift({ ...column, name: 'Unit' });
            }
        });
        setColumns(newColumns);
    }
};
