import * as React from 'react';
import {
    ExceptionMapping,
    ExceptionsEntity,
    IReviewForm,
    ISendBack,
    jobLevelOptions,
    LumpSumRelocation,
    OptionalBenefitMapping,
    PreHireTypeBenefits,
    RelocationType,
} from '../../Models/IReviewForm';
import { LoadingSpinner } from '../../Components/Shared/LoadingSpinner';
import { useServiceStore } from '../../RootStateContext';
import {
    PrimaryButton,
    IStackStyles,
    Stack,
    mergeStyleSets,
    getTheme,
    FontWeights,
    IIconProps,
    IDropdownOption,
    MessageBar,
    MessageBarType,
    Spinner,
    SpinnerSize,
    TextField,
    ITextFieldStyles,
    IStackProps,
    IStackItemStyles,
    Text,
} from '@fluentui/react';
import { IUserRoles } from '../../Models/IUserRoles';
import { ReduxContext } from '@employee-experience/common';
import { IAppState } from '../../Common/Redux/AppState';
import { HireType } from '../../Models/IReviewForm';
import { PersonalInformation } from './PersonalInformation';
import { EmploymentInformation } from './EmploymentInformation';
import { RelocationInformation } from './RelocationInformation';
import { HireInformation } from './HireInformation';
import { ActionType } from '../../Common/Redux/actionTypes';
import { StackDisclaimer } from '../../Components/Shared/DisclaimerText';
import {
    EmploymentInfo,
    HireInfo,
    ITaxPerJobLevel,
    PersonalInfo,
    ReloException,
    AttachReloSummary,
    IReviewFormRelocationInfo,
} from '../../Models/INewRelocationRequest';
import { RelocationSummaryAttachment } from './RelocationSummaryAttacment';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { SendBackPopUp } from '../NewReviewForm/PopUps/SendBackPopUp';
import { ConfirmationPopUp } from './PopUps/ConfirmationPopUp';
import { DeleteStatusPopUp } from '../ReloForm/PopUps/DeleteStatusPopUp';
import { DeleteWarningPopUp } from '../ReloForm/PopUps/DeleteWarningPopUp';
// ---------- Styles ----------
const stackStyles: Partial<IStackStyles> = { root: { width: '100%', marginTop: '7px' } };
const theme = getTheme();
const buttonStyles = {
    backgroundColor: 'rgb(0, 120, 212)',
    color: '#FFFFFF',
    marginBottom: '10px',
};

export interface ReviewFormProps {
    recordID: number;
    recordStatus: string;
    ActionType: string;
    redirectedFrom: string;
}

const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        width: '100em',
    },
    header: [
        theme.fonts.xLargePlus,
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 0 0 0',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});

// --------------------------------------------------
export const ReviewForm: React.FC<ReviewFormProps> = (props) => {
    const location = useLocation();
    let history = useHistory();
    if (location.state == undefined || location.state == null || location.state == '') {
        history.push('/');
    }
    props = JSON.parse(location.state);

    const { useSelector, dispatch } = React.useContext(ReduxContext);
    const userdata: IUserRoles = useSelector((state: IAppState) => state.root.userReducer.userRoles);
    const homePage: string = useSelector((state: IAppState) => state.root.userReducer.homePage);
    const { reviewFormService, startNewReloRequestService } = useServiceStore();
    const [isRedirect, setIsRedirect] = React.useState<boolean>(false);
    const [loaded, setLoaded] = React.useState<boolean>(false);

    const [reviewFormDataResponse, setReviewFormDataResponse] = React.useState<IReviewForm[]>([]);
    const [reloInfo, setReloInfo] = React.useState<IReviewFormRelocationInfo>({} as IReviewFormRelocationInfo);
    const [jobLevelInformation, setJobLevelInformation] = React.useState<jobLevelOptions[]>([] as jobLevelOptions[]);
    const [taxByJobLevels, settaxByJobLevels] = React.useState<ITaxPerJobLevel[]>([] as ITaxPerJobLevel[]);
    const [lumpSumRelocation, setlumpSumRelocation] = React.useState<LumpSumRelocation>({} as LumpSumRelocation);
    const [savedAlready, setsavedAlready] = React.useState<boolean>(false);
    const [relocationCashAmount, setrelocationCashAmount] = React.useState<number>(0);

    const [_showBenefitsPanel, set_showBenefitsPanel] = React.useState<boolean>(false);
    const [_showOptionalException,set_showOptionalException] = React.useState<boolean>(false);
    const [_relocationPolicyID, set_RelocationPolicyID] = React.useState<number>();
    const [_relocationPolicy] = React.useState<string>('');
    const [_relocationpolicyTypeID, set_relocationpolicyTypeID] = React.useState<number>();
    const [_relocationType, set_relocationType] = React.useState<string>('');
    const [_comments] = React.useState<string>('');
    const [_selectedReloFile] = React.useState<string>('');
    const [_additionalReloFile] = React.useState<string>('');
    const [_fiscalYear, set_fiscalYear] = React.useState<number>();
    const [_optionalBenefitMapping] = React.useState<OptionalBenefitMapping[]>([] as OptionalBenefitMapping[]);
    const [_exceptionMapping] = React.useState<ExceptionMapping[]>([]);
    const [_preHireTypeBenefitsMapping, set_preHireTypeBenefitsMapping] = React.useState<any[]>([]);
    const [_relocationPolicyOptions] = React.useState<IDropdownOption[]>([]);
    const [_relocationPolicyTypeOptions] = React.useState<IDropdownOption[]>([]);
    const [_exceptionOptions, set_exceptionOptions] = React.useState<IDropdownOption[]>([]);
    const [_selectedException] = React.useState<string>('');
    const [_attachReloSummary] = React.useState<AttachReloSummary>({} as AttachReloSummary);
    const [_additionalReloSummary0, set_additionalReloSummary0] = React.useState<AttachReloSummary>(
        {} as AttachReloSummary
    );
    const [_additionalReloSummary1, set_additionalReloSummary1] = React.useState<AttachReloSummary>(
        {} as AttachReloSummary
    );
    const [_additionalReloSummary2, set_additionalReloSummary2] = React.useState<AttachReloSummary>(
        {} as AttachReloSummary
    );
    const [_additionalReloSummary3, set_additionalReloSummary3] = React.useState<AttachReloSummary>(
        {} as AttachReloSummary
    );
    const [_additionalReloSummary4, set_additionalReloSummary4] = React.useState<AttachReloSummary>(
        {} as AttachReloSummary
    );
    const [_passExceptionList] = React.useState<ReloException[]>([]);
    const [_exceptionListUpdatedFlag] = React.useState<number | null>();
    const [_reloTypes, set_reloTypes] = React.useState<any[]>([]);
    const [_relocationPolicyTypeIsFull] = React.useState<string>('');
    const [_showLumpSumPanel, set_showLumpSumPanel] = React.useState<boolean>(false);
    const [_showLimitedBenefits, set_showLimitedBenefits] = React.useState<boolean>(false);
    const [_showPreHireTypePanel, set_showPreHireTypePanel] = React.useState<boolean>(false);
    const [_allOptionalBenefits] = React.useState<any[]>([]);
    const [_exceptions, set_exceptions] = React.useState<any[]>([]);
    const [_gridExceptions, set_gridExceptions] = React.useState<any[]>([]);
    const [_preHireTypeBenefits, set_preHireTypeBenefits] = React.useState<PreHireTypeBenefits[]>(
        [] as PreHireTypeBenefits[]
    );
    const [_selectedPreHireTypeBenefits, set_selectedPreHireTypeBenefits] = React.useState<any[]>([]);
    const [_coreBenefits, set_coreBenefits] = React.useState<any[]>([]);
    const [_coreAllowance, set_coreAllowance] = React.useState<any[]>([]);
    const [_isReloTypeChanged] = React.useState<boolean>(false);

    //Employment Information Fields
    const [_HrStaffinggroupNamelists, set_HrStaffinggroupNamelists] = React.useState<boolean>(false);
    const [_HrStaffingName, set_HrStaffingName] = React.useState<string>(
        'Staffing Manager Alias(GMAT Request Approver)'
    );
    const [SelectedManagerAlias, set_SelectedManagerAlias] = React.useState<string>('');
    const [_HiringManagerLbl, set_HiringManagerLbl] = React.useState<string>('Hiring Manager Alias');
    const [_showHrStaffingManagerAliastxtBox, set_showHrStaffingManagerAliastxtBox] = React.useState<boolean>(false);
    const [_ProgramManagerToolTip] = React.useState<string | null>(null);

    //File Upload Guid
    const [_AttachmentGUID] = React.useState<string>('');
    const [_AttachmentGUID0, set_AttachmentGUID0] = React.useState<string>('');
    const [_AttachmentGUID1, set_AttachmentGUID1] = React.useState<string>('');
    const [_AttachmentGUID2, set_AttachmentGUID2] = React.useState<string>('');
    const [_AttachmentGUID3, set_AttachmentGUID3] = React.useState<string>('');
    const [_AttachmentGUID4, set_AttachmentGUID4] = React.useState<string>('');

    const [_fileUpload] = React.useState<File>({} as File);

    const [_fileUpload0] = React.useState<File>({} as File);
    const [_fileUpload1] = React.useState<File>({} as File);
    const [_fileUpload2] = React.useState<File>({} as File);
    const [_fileUpload3] = React.useState<File>({} as File);
    const [_fileUpload4] = React.useState<File>({} as File);

    const [_AddtionalDescription0] = React.useState<string>('');
    const [_AddtionalDescription1] = React.useState<string>('');
    const [_AddtionalDescription2] = React.useState<string>('');
    const [_AddtionalDescription3] = React.useState<string>('');
    const [_AddtionalDescription4] = React.useState<string>('');

    const [_exceptionsResponse, set_exceptionsResponse] = React.useState<ExceptionsEntity[]>([]);

    const [apiError, setApiError] = React.useState<boolean>(false);
    const [apiSuccess, setApiSuccess] = React.useState<boolean>(false);
    const [messageText, setMessageText] = React.useState<string>('');
    const [loading] = React.useState<boolean>(false);

    //Adding States for ReviewForm
    const [intersnhipTypeName, setIntersnhipTypeName] = React.useState<string | null>(null);
    const [SendBackCmnts, set_SendBackCmnts] = React.useState<boolean>(false);
    const [sendBackComntsSuccess, setSendBackComntsSuccess] = React.useState<boolean>(false);
    const [_rushComments, set_rushComments] = React.useState<string>('');
    const [_departureCompanyCode, set_departureCompanyCode] = React.useState<string>('');
    const [_destinationCompanyCode, set_destinationCompanyCode] = React.useState<string>('');
    const [_requisitionID, set_requisitionID] = React.useState<string>('');
    const [isSubmittedImg, set_isSubmittedImg] = React.useState<boolean>(false);
    const [isFromStaffing, set_IsFromStaffing] = React.useState<boolean>(false);
    const [isSavedImg, set_isSavedImg] = React.useState<boolean>(false);
    const [isReturnForEditsImg, set_isReturnForEditsImg] = React.useState<boolean>(false);
    const [isGlomoco, set_isGlomoco] = React.useState<boolean>(false);
    const [commentsForFileSetup, set_CommentsForFileSetup] = React.useState<boolean>(false);
    const [SendToFileSetupComments, set_SendToFileSetupComments] = React.useState<string | undefined>('');
    const [fileUploadErrors, set_FileUploadErrors] = React.useState<boolean>(false);

    // State values to show buttons
    const [isReadytoinitiate, set_isReadytoinitiate] = React.useState<boolean>(false);
    const [isFormDelete, set_isFormDelete] = React.useState<boolean>(false);
    const [editRecordDisabled, set_editRecordDisabled] = React.useState<boolean>(false);
    const [sendbackdisabled, set_sendbackdisabled] = React.useState<boolean>(true);
    const [SendtoFileSetUpDisabled, set_SendtoFileSetUpDisabled] = React.useState<boolean>(false);
    const [resubmitdisabled, set_Resubmitdisabled] = React.useState<boolean>(false);
    const [formSubmitBtnLbl, set_formSubmitBtnLbl] = React.useState<string>('Submit');
    const [parentStatus, set_ParentStatus] = React.useState<string>(props.recordStatus.toUpperCase());
    const [isDeleteWarningPopUpOpen, setIsDeleteWarningPopUpOpen] = React.useState<boolean>(false);
    const [showSendBackPopUp, setShowSendBackPopUp] = React.useState<boolean>(false);
    const [sendBack, set_sendBack] = React.useState<string>('');
    const [formStatus, setFormStatus] = React.useState<string | null>(null);
    const [displayStatus, set_DisplayStatus] = React.useState<string | null>(null);
    const [openConfirmationPopUp, setOpenConfirmationPopUp] = React.useState<boolean>(false);
    const [isDeleteConfirmationPopUpOpen, setIsDeleteConfirmationPopUpOpen] = React.useState<boolean>(false);
    const [taxpactforlumpsum, setTaxpactforlumpsum] = React.useState<number>(48.5)
    const [isDeleteErrorPopUpOpen, setIsDeleteErrorPopUpOpen] = React.useState<boolean>(false);
    let taxPctForLumpSum = 48.5;
    const [hireInfo, setHireInfo] = React.useState<HireInfo>({
        reloID: 0,
        candidateID: '',
        employeeID: '',
        hireTypeID: 0,
        showInternship: false,
        isMACH: false,
        isMBA: false,
        hireTypesOptions: [] as IDropdownOption[],
    });

    const [personalInfo, setPersonalInfo] = React.useState<PersonalInfo>({
        personalDetails: {
            costCenter: '',
            countryNames: [''],
            currentEmail: '',
            employeeNumber: 0,
            firstName: '',
            jobLevel: 0,
            lastName: '',
            middleName: '',
            personalPhone: '',
            reloID: 0,
            startDate: '',
            visaPending: '',
            workPhone: '',
            stateNames: [''],
        },
        departureAddress: {
            address: '',
            apartment: '',
            city: '',
            country: '',
            postalCode: '',
            ReloID: 0,
            stateProvince: '',
        },
        workDestinationAddress: {
            city: '',
            country: '',
            stateProvince: '',
        },
        homeDestinationAddress: {
            country: '',
            city: '',
            stateProvince: '',
        },
        countryOptions: [],
        workStateOptions: [],
        departureAddressStateOptions: [],
        destinationAddressStateOptions: [],
        visaOptions: [] as IDropdownOption[],
    });

    const [employmentInfo, setEmploymentInfo] = React.useState<EmploymentInfo>({
        businessGroup: '',
        costCenter: '',
        jobLevel: '',
        jobLevelID: '',
        hRStaffingManagerAlias: '',
        hRRecruitingAssociate: '',
        hiringManagerAlias: '',
        estimatedStartDate: '',
        hRRecruiterAlias: '',
        hrStaffingGroup: '',
        estimatedEndDate: '',
        jobLevelOptions: [] as IDropdownOption[],
        businessGroupOptions: [] as IDropdownOption[],
        RushComments: '',
        DestinationCompanyCode: '',
        DepartureCompanyCode: '' ,
        RequisitionID: ''
    });


    React.useEffect(() => {
        (async function getReviewFormData() {
            await reviewFormService.getRelocationFormData(props.recordID).then((result) => {
                if (result.length > 0) {
                    set_isReadytoinitiate(true);
                    setReviewFormDataResponse(result);
                } else alert('No Data Found');
            });
        })();
    }, [parentStatus]);

    React.useEffect(() => {
        if (reviewFormDataResponse && reviewFormDataResponse.length > 0) {
            (async function getReloformData() {
                let displayStatus = parentStatus;
                if (reviewFormDataResponse !== null && reviewFormDataResponse !== undefined) {
                    userdata.Roles.forEach((role, index) => {
                        let tempRecordStatus = parentStatus;
                        let Action = props.ActionType;
                        if (tempRecordStatus !== null) {
                            if (
                                (role.toUpperCase() == 'STAFFING' || role.toUpperCase() == 'RELOADMIN') &&
                                Action.toUpperCase() == 'STAFFING'
                            ) {
                                if (tempRecordStatus.toUpperCase() == 'SENDBACK') {
                                    set_SendBackCmnts(true);
                                    set_formSubmitBtnLbl('RESUBMIT');
                                    displayStatus = 'RETURN FOR EDITS';
                                    set_isReturnForEditsImg(true);
                                }
                                if (
                                    tempRecordStatus.toUpperCase() == 'RESUBMITTED' ||
                                    tempRecordStatus.toUpperCase() == 'SUBMITTED'
                                ) {
                                    set_formSubmitBtnLbl('RESUBMIT');
                                    set_isSubmittedImg(true);
                                    if (tempRecordStatus.toUpperCase() == 'RESUBMITTED') {
                                        if (Action.toUpperCase() == 'STAFFING') {
                                            set_IsFromStaffing(true);
                                            set_isSubmittedImg(false);
                                        } else {
                                            set_IsFromStaffing(false);
                                        }
                                    }
                                }
                            }

                            if (
                                (role.toUpperCase() == 'STAFFING' || role.toUpperCase() == 'GLOMOCO'|| role.toUpperCase() == 'RELOADMIN') &&
                                Action.toUpperCase() == 'SENDBACK'
                            ) {
                                if (tempRecordStatus.toUpperCase() == 'SENDBACK') {
                                    set_SendBackCmnts(true);
                                    set_formSubmitBtnLbl('Recall');
                                    displayStatus = 'RETURN FOR EDITS';
                                    set_isReturnForEditsImg(true);
                                }
                                if (
                                    tempRecordStatus.toUpperCase() == 'RESUBMITTED' ||
                                    tempRecordStatus.toUpperCase() == 'SUBMITTED'
                                ) {
                                    set_formSubmitBtnLbl('RESUBMIT');
                                    set_isSubmittedImg(true);
                                    if (tempRecordStatus.toUpperCase() == 'RESUBMITTED') {
                                        if (Action.toUpperCase() == 'STAFFING') {
                                            set_IsFromStaffing(true);
                                            set_isSubmittedImg(false);
                                        } else {
                                            set_IsFromStaffing(false);
                                        }
                                    }
                                }
                            }

                            if (
                                isNotNullOrUndefined(role) &&
                                (role.toUpperCase() == 'GLOMOCO' || role.toUpperCase() == 'RELOADMIN') &&
                                isNotNullOrUndefined(Action) &&
                                (Action.toUpperCase() == 'GLOMOCO' ||
                                    Action.toUpperCase() == 'RELOCAMP' ||
                                    Action.toUpperCase() == 'SENDBACK')
                            ) {
                                set_isGlomoco(true);
                                if (
                                    tempRecordStatus.toUpperCase() == 'SUBMITTED' ||
                                    tempRecordStatus.toUpperCase() == 'RESUBMITTED'
                                ) {
                                    set_sendbackdisabled(false);
                                    set_editRecordDisabled(true);
                                    set_SendtoFileSetUpDisabled(true);
                                    set_isSubmittedImg(true);

                                    if (tempRecordStatus.toUpperCase() == 'SUBMITTED') displayStatus = 'READY TO INITIATE';
                                    set_CommentsForFileSetup(true);
                                    set_isFormDelete(true);
                                }
                                if (tempRecordStatus.toUpperCase() == 'SENDBACK') {
                                    set_sendbackdisabled(true);
                                    set_editRecordDisabled(false);
                                    set_SendtoFileSetUpDisabled(false);
                                    set_CommentsForFileSetup(false);
                                    set_SendBackCmnts(true);
                                    set_isReturnForEditsImg(true);
                                    set_isSavedImg(false);
                                    set_isSubmittedImg(false);
                                    displayStatus = tempRecordStatus;
                                    set_isFormDelete(true);
                                }
                                if (sendBack == 'isSendBack') {
                                    set_sendbackdisabled(true);
                                    setSendBackComntsSuccess(true);
                                    set_editRecordDisabled(false);
                                    set_SendtoFileSetUpDisabled(false);
                                    set_CommentsForFileSetup(false);
                                    set_isReturnForEditsImg(true);
                                    set_isFormDelete(true);
                                }
                            }
                            if (tempRecordStatus.toUpperCase() == 'AUTHORIZED') {
                                displayStatus = parentStatus;
                                set_isFormDelete(false);
                            }
                            if (
                                tempRecordStatus.toUpperCase() == 'SUBMITTED' ||
                                tempRecordStatus.toUpperCase() == 'RESUBMITTED'
                            ) {
                                set_Resubmitdisabled(true);
                            } else if (
                                tempRecordStatus.toUpperCase() == 'NEW' ||
                                tempRecordStatus.toUpperCase() == 'SENDBACK'
                            ) {
                                set_Resubmitdisabled(false);
                            }
                        }
                    });

                    if (props.ActionType.toUpperCase() == 'RELOCAMP') {
                        if (reviewFormDataResponse[0].ReadyToReview == 1) {
                            if (displayStatus.toUpperCase() != 'RESUBMITTED') {
                                displayStatus = 'READY TO INITIATE';
                            }
                            set_SendtoFileSetUpDisabled(true);
                            set_isReadytoinitiate(true);
                        } else if (reviewFormDataResponse[0].ReadyToReview == 0) {
                            if (displayStatus.toUpperCase() != 'RESUBMITTED') {
                                displayStatus = 'READY TO REVIEW';
                            }
                            set_SendtoFileSetUpDisabled(false);
                            set_isReadytoinitiate(false);
                        }
                    }
                    set_DisplayStatus(displayStatus);
                    setFormStatus(parentStatus);

                    set_preHireTypeBenefitsMapping(reviewFormDataResponse[0]?.PreHireTypeBenefitsMapping)
                    await getHireInfo(reviewFormDataResponse);
                    await getPersonalInfo(reviewFormDataResponse);
                    await getEmploymentInfo(reviewFormDataResponse);
                    await getRelocationInfo(reviewFormDataResponse);
                    await GetTaxRatesByJobLevel();
                    setLoaded(true);
                }
            })();
        }

    }, [reviewFormDataResponse]);

    React.useEffect(() => {
        console.log(hireInfo);
        (async function updateFormData() {
            await onInternshipTypeChange();
            await LoadRelocationType({} as IDropdownOption);
        })();
    }, [hireInfo]);

    async function GetTaxRatesByJobLevel() {
        const taxRatesResponse = await startNewReloRequestService.GetTaxRatesByJobLevel();
        dispatch({
            type: ActionType.SET_TAXRATESBYJOBLEVEL,
            data: taxRatesResponse,
        });
        settaxByJobLevels(taxRatesResponse);
    }

    React.useEffect(() => {
        if (taxByJobLevels.length > 0) {
            let taxDetails = taxByJobLevels.filter((item) => item.ID === Number(employmentInfo.jobLevelID));
            setTaxpactforlumpsum(taxDetails[0]?.TaxRate)
        }
    }, [employmentInfo.jobLevel, taxByJobLevels])

    async function getHireInfo(data: IReviewForm[]) {
        const hireTypeResponse = await reviewFormService.getHireTypeService();
        let hiretypeoptions: IDropdownOption[] = [];
        hireTypeResponse.forEach((item) => {
            if (item.HireTypeID != 5 && item.HireTypeID != 6)
                hiretypeoptions.push({
                    key: item.HireTypeID,
                    text: item.HireTypeName,
                });
            if (item.HireTypeID == 6)
                hiretypeoptions.push({
                    key: item.HireTypeID,
                    text: 'Executive/ M&A',
                });
        });
        let hireType = hireTypeResponse.filter((row: HireType) => {
            return row.HireTypeID === data[0]?.HireTypeID;
        });

        const hireInfo = {
            reloID: data[0].ReloID,
            candidateID: data[0].CandidateID != null && data[0].CandidateID != '' ? data[0].CandidateID : '',
            employeeID: data[0].EmployeeID != null && data[0].EmployeeID != 0 ? data[0].EmployeeID.toString() : '',
            showInternship: hireType[0].HireTypeName === 'Intern' ? true : false,
            showMachAndMba: data[0].HireTypeID === 3 ? true : false,
            isMACH: data[0].IsMach === 1 ? true : false,
            isMBA: data[0].IsMba === 1 ? true : false,
            hireTypesOptions: hiretypeoptions,
            hireTypeID: data[0].HireTypeID,
        };
        setHireInfo(hireInfo);
    }

    async function getPersonalInfo(data: IReviewForm[]) {
        const personalInfo = {
            countryOptions: [],
            workStateOptions: [],
            departureAddressStateOptions: [],
            destinationAddressStateOptions: [],
            personalDetails:
                data[0].PersonalDetails != null
                    ? {
                        costCenter: data[0].PersonalDetails.CostCenter,
                        countryNames: data[0].PersonalDetails.CountryNames,
                        currentEmail: data[0].PersonalDetails.CurrentEmail,
                        employeeNumber: data[0].PersonalDetails.EmployeeNumber,
                        firstName: data[0].PersonalDetails.FirstName,
                        jobLevel: data[0].PersonalDetails.JobLevel,
                        lastName: data[0].PersonalDetails.LastName,
                        middleName: data[0].PersonalDetails.MiddleName,
                        personalPhone: data[0].PersonalDetails.PersonalPhone,
                        reloID: data[0].PersonalDetails.ReloID,
                        startDate: data[0].PersonalDetails.StartDate,
                        visaPending: data[0].PersonalDetails.VisaPending,
                        workPhone: data[0].PersonalDetails.WorkPhone,
                        stateNames: data[0].PersonalDetails.stateNames,
                    }
                    : {
                        costCenter: '',
                        countryNames: [],
                        currentEmail: '',
                        employeeNumber: 0,
                        firstName: '',
                        jobLevel: 0,
                        lastName: '',
                        middleName: '',
                        personalPhone: '',
                        reloID: 0,
                        startDate: '',
                        visaPending: '',
                        workPhone: '',
                        stateNames: [],
                    },
            workDestinationAddress: {
                city: data[0].WorkDestinationCity,
                country: data[0].WorkDestinationCountry,
                stateProvince: data[0].WorkDestinationStateProvince,
            },
            homeDestinationAddress: {
                country: data[0].DestinationAddress.Country,
                city: data[0].DestinationAddress.City,
                stateProvince: data[0].DestinationAddress.StateProvince,
            },
            departureAddress:
                data[0].DepartureAddress != null
                    ? {
                        address: data[0].DepartureAddress.Address,
                        apartment: data[0].DepartureAddress.Apartment,
                        city: data[0].DepartureAddress.City,
                        country: data[0].DepartureAddress.Country,
                        postalCode:
                            data[0].DepartureAddress.PostalCode !== undefined &&
                                data[0].DepartureAddress.PostalCode !== null
                                ? (data[0].DepartureAddress?.PostalCode).toString()
                                : '',
                        ReloID:
                            data[0].DepartureAddress.ReloID !== undefined &&
                                data[0].DepartureAddress.PostalCode !== null
                                ? data[0].DepartureAddress.ReloID
                                : 0,
                        stateProvince: data[0].DepartureAddress.StateProvince,
                    }
                    : {
                        address: '',
                        apartment: '',
                        city: '',
                        country: '',
                        postalCode: '',
                        ReloID: 0,
                        stateProvince: '',
                    },
            visaOptions: [
                { key: 'Yes', text: 'Yes' },
                { key: 'No', text: 'No' },
            ],
        };

        setPersonalInfo(personalInfo);
    }

    async function getEmploymentInfo(data: IReviewForm[]) {
        const jobLevelsOptionResponse = await reviewFormService.getJobLevelInformation();
        setJobLevelInformation(jobLevelsOptionResponse);
        let jobLevel = jobLevelsOptionResponse;
        let jobLevelID = Number(data[0].JobLevelID);

        const employmentInfo = {
            businessGroup: data[0].BusinessGroup,
            costCenter: data[0].CostCenter,
            jobLevel: jobLevel.filter((item)=> Number(item.ID)=== Number(jobLevelID))[0]?.JobLevelName,
            jobLevelID: data[0].JobLevelID,
            hRStaffingManagerAlias: data[0].HRStaffingManagerAlias, 
            hRRecruitingAssociate: data[0].HRRecruitingAssociate, 
            hiringManagerAlias: data[0].HiringManagerAlias,
            estimatedStartDate: data[0].EstimatedStartDate,
            estimatedEndDate: data[0].EstimatedEndDate,
            hrStaffingGroup: data[0].HRStaffinggroup,
            hRRecruiterAlias: data[0].HRRecruiterAlias,
            jobLevelOptions: [],
            businessGroupOptions: [],
            RushComments: data[0].RushComments,
            DestinationCompanyCode: data[0].DestinationCompanyCode,
            DepartureCompanyCode: data[0].DepartureCompanyCode,
            RequisitionID: data[0].RequisitionID,
        };
        setEmploymentInfo(employmentInfo);
    }

    async function getRelocationInfo(data: IReviewForm[]) {
        const relocationPolicyResponse = await reviewFormService.getrelocationPolicy(data[0].HireTypeID);
        set_RelocationPolicyID(parseInt(data[0].RelocationPolicyID));
        let reloPolicy = relocationPolicyResponse.filter((row: any) => {
            return row.RelocationPolicyID === data[0].RelocationPolicyID.toString();
        });

        let relocationTypeResponse = await reviewFormService.getrelocationType();
        let reloType = relocationTypeResponse.filter((row: any) => {
            return row.RelocationTypeID === data[0].RelocationpolicyTypeID.toString();
        });
        const reloInfo = {
            relocationPolicyID: data[0].RelocationPolicyID,
            relocationPolicy: reloPolicy && reloPolicy.length >=1 ? reloPolicy[0].RelocationPolicyName : '',
            relocationpolicyTypeID: data[0].RelocationpolicyTypeID,
            relocationType:reloType && reloType.length >=1 ? reloType[0].RelocationTypeName : '', 
            comments: data[0].Comments,
            selectedReloFile: data[0].AttachReloSummary != null ? data[0].AttachReloSummary.Filename : '',
            additionalReloFile: data[0].AdditonalReloSummary0 != null ? data[0].AdditonalReloSummary0.Filename : '',
            fiscalYear: data[0].FiscalYear,
            optionalBenefitMapping: data[0].OptionalBenefitMapping,
            exceptionMapping: data[0].ExceptionMapping,
            preHireTypeBenefitsMapping: data[0].PreHireTypeBenefitsMapping,
        };
        if (data[0].AttachReloSummary != null) {
        }

        set_rushComments(data[0].RushComments!);
        set_departureCompanyCode(data[0].DepartureCompanyCode!);
        set_destinationCompanyCode(data[0].DestinationCompanyCode!);
        set_requisitionID(data[0].RequisitionID!);
        setReloInfo(reloInfo);

        set_additionalReloSummary0(
            data[0].AdditonalReloSummary0 != null
                ? {
                    AddtionalDescription: data[0].AdditonalReloSummary0!.AddtionalDescription,
                    AttachReloSummaryID: data[0].AdditonalReloSummary0!.AttachReloSummaryID,
                    CreatedBy: data[0].AdditonalReloSummary0!.CreatedBy,
                    CreatedDate: data[0].AdditonalReloSummary0!.CreatedDate,
                    Filename: data[0].AdditonalReloSummary0!.Filename,
                    IsAdditional: data[0].AdditonalReloSummary0!.IsAdditional,
                    IsRemoved: data[0].AdditonalReloSummary0!.IsRemoved,
                    ReloID: data[0].AdditonalReloSummary0!.ReloID,
                }
                : ({} as AttachReloSummary)
        );

        set_additionalReloSummary1(
            data[0].AdditonalReloSummary1 != null
                ? {
                    AddtionalDescription: data[0].AdditonalReloSummary1!.AddtionalDescription,
                    AttachReloSummaryID: data[0].AdditonalReloSummary1!.AttachReloSummaryID,
                    CreatedBy: data[0].AdditonalReloSummary1!.CreatedBy,
                    CreatedDate: data[0].AdditonalReloSummary1!.CreatedDate,
                    Filename: data[0].AdditonalReloSummary1!.Filename,
                    IsAdditional: data[0].AdditonalReloSummary1!.IsAdditional,
                    IsRemoved: data[0].AdditonalReloSummary1!.IsRemoved,
                    ReloID: data[0].AdditonalReloSummary1!.ReloID,
                }
                : ({} as AttachReloSummary)
        );

        set_additionalReloSummary2(
            data[0].AdditonalReloSummary2 != null
                ? {
                    AddtionalDescription: data[0].AdditonalReloSummary2!.AddtionalDescription,
                    AttachReloSummaryID: data[0].AdditonalReloSummary2!.AttachReloSummaryID,
                    CreatedBy: data[0].AdditonalReloSummary2!.CreatedBy,
                    CreatedDate: data[0].AdditonalReloSummary2!.CreatedDate,
                    Filename: data[0].AdditonalReloSummary2!.Filename,
                    IsAdditional: data[0].AdditonalReloSummary2!.IsAdditional,
                    IsRemoved: data[0].AdditonalReloSummary2!.IsRemoved,
                    ReloID: data[0].AdditonalReloSummary2!.ReloID,
                }
                : ({} as AttachReloSummary)
        );

        set_additionalReloSummary3(
            data[0].AdditonalReloSummary3 != null
                ? {
                    AddtionalDescription: data[0].AdditonalReloSummary3!.AddtionalDescription,
                    AttachReloSummaryID: data[0].AdditonalReloSummary3!.AttachReloSummaryID,
                    CreatedBy: data[0].AdditonalReloSummary3!.CreatedBy,
                    CreatedDate: data[0].AdditonalReloSummary3!.CreatedDate,
                    Filename: data[0].AdditonalReloSummary3!.Filename,
                    IsAdditional: data[0].AdditonalReloSummary3!.IsAdditional,
                    IsRemoved: data[0].AdditonalReloSummary3!.IsRemoved,
                    ReloID: data[0].AdditonalReloSummary3!.ReloID,
                }
                : ({} as AttachReloSummary)
        );

        set_additionalReloSummary4(
            data[0].AdditonalReloSummary4 != null
                ? {
                    AddtionalDescription: data[0].AdditonalReloSummary4!.AddtionalDescription,
                    AttachReloSummaryID: data[0].AdditonalReloSummary4!.AttachReloSummaryID,
                    CreatedBy: data[0].AdditonalReloSummary4!.CreatedBy,
                    CreatedDate: data[0].AdditonalReloSummary4!.CreatedDate,
                    Filename: data[0].AdditonalReloSummary4!.Filename,
                    IsAdditional: data[0].AdditonalReloSummary4!.IsAdditional,
                    IsRemoved: data[0].AdditonalReloSummary4!.IsRemoved,
                    ReloID: data[0].AdditonalReloSummary4!.ReloID,
                }
                : ({} as AttachReloSummary)
        );

        set_AttachmentGUID0(data[0].AttachmentGUID0 !== null ? data[0].AttachmentGUID0 : '');
        set_AttachmentGUID1(data[0].AttachmentGUID1 !== null ? data[0].AttachmentGUID1 : '');
        set_AttachmentGUID2(data[0].AttachmentGUID2 !== null ? data[0].AttachmentGUID2 : '');
        set_AttachmentGUID3(data[0].AttachmentGUID3 !== null ? data[0].AttachmentGUID3 : '');
        set_AttachmentGUID4(data[0].AttachmentGUID4 !== null ? data[0].AttachmentGUID4 : '');
        setrelocationCashAmount(isNotNullOrUndefined(data[0].RelocationCashAmount) ? data[0].RelocationCashAmount : 0);
    }

    const dismissSendbackPopUp = () => {
        setShowSendBackPopUp(false);
    };

    const updateisSendBack = () => {
        set_sendBack('isSendBack');
        set_ParentStatus('SENDBACK');
    };

    const onSubmitClick = async () => {
        if (
            reviewFormDataResponse[0].AttachReloSummary == null &&
            reviewFormDataResponse[0].HireTypeID !== 3 &&
            reviewFormDataResponse[0].HireTypeID !== 4 &&
            reviewFormDataResponse[0].HireTypeID !== 7 &&
            reviewFormDataResponse[0].HireTypeID !== 8
        ) {
            set_FileUploadErrors(true);
        } else {
            set_FileUploadErrors(false);
            setLoaded(false);
            let formData = await OnSaveClick();
            let submitObj: ISendBack = formData;
            submitObj.Status = 'Submitted';
            if (parentStatus == 'SENDBACK' || parentStatus == 'RESUBMITTED') {
                submitObj.Status = 'Resubmitted';
            }
            submitObj.ReloID = reviewFormDataResponse[0].ReloID;
            submitObj.alias = userdata.Alias;
            if (reviewFormDataResponse[0].Comments !== null || reviewFormDataResponse[0].Comments !== undefined)
                submitObj.comments = reviewFormDataResponse[0].Comments;
            else submitObj.comments = '';
            
            await startNewReloRequestService
                .Submit(submitObj)
                .then((result) => {
                    if (result.status == 201) {
                        history.push('/SentBackQueue');
                        if (parentStatus.toUpperCase() == 'SUBMITTED' || parentStatus.toUpperCase() == 'SAVED') {
                            set_isSavedImg(false);
                        }
                        if (parentStatus.toUpperCase() == 'RESUBMITTED' || parentStatus.toUpperCase() == 'SENDBACK') {
                            set_isReturnForEditsImg(false);
                        }
                        set_DisplayStatus(submitObj.status);
                    } else {
                        if (parentStatus.toUpperCase() == 'SUBMITTED') {
                            alert('Submit Unsuccessfull');
                        }
                        if (parentStatus.toUpperCase() == 'RESUBMITTED' || parentStatus.toUpperCase() == 'SENDBACK') {
                            alert('ReSubmit Unsuccessfull');
                        }
                    }
                })
                .catch((error) => { })
                .finally(() => {
                    set_SendBackCmnts(false);
                    setLoaded(true);
                });
        }
    };

    const onEditRecordClick = async () => {
        let obj = JSON.stringify({
            recordID: props.recordID,
            recordStatus: parentStatus,
            ActionType: 'GLOMOCO',
            EditRecord: 'editthisrecord',
        });
        history.push('/ReloForm', obj);
    };

    const onSendBackClick = async () => {
        setShowSendBackPopUp(true);
    };

    const handleErrorMessageBar = () => {
        setApiError(false);
        setMessageText('');
    };

    const handleSuccessMessageBar = () => {
        setSendBackComntsSuccess(false);
        setApiSuccess(false);
        setMessageText('');
    };
   

    const iconPropsWarnings: IIconProps = {
        iconName: 'WarningSolid',
        styles: {
            root: { color: '#EC9F1D', fontSize: '22px', paddingLeft: '3px' },
        },
    };

    const iconPropsError: IIconProps = {
        iconName: 'StatusErrorFull',
        styles: {
            root: { color: '#DF0008', fill: 'Black', fontSize: '22px', paddingLeft: '3px' },
        },
    };

    const iconPropsSubmitted: IIconProps = {
        iconName: 'SkypeCircleCheck',
        styles: {
            root: { color: '#7BA811', fill: 'Black', fontSize: '22px', paddingLeft: '0px' },
        },
    };

    const textFieldStyles: Partial<ITextFieldStyles> = {
        field: {
            color: 'Black',
            fontSize: '20px',
            fontFamily: 'Segoe UI',
            paddingRight: '0px',
            flexDirection: 'row-reverse',
        },
    };

    const sendBackCommentsStyles: Partial<ITextFieldStyles> = {
        field: {
            color: '#034778',
            fontSize: '20px',
            fontFamily: 'Segoe UI',
            paddingRight: '0px',
            flexDirection: 'row-reverse',
            backgroundColor: '#D9EDF7',
        },
    };

    const oneColumnProps: Partial<IStackProps> = {
        tokens: { childrenGap: 15 },
        styles: { root: { width: '100%' } },
    };

    const accordionHeader: IStackItemStyles = {
        root: {
            padding: 5,
            cursor: 'pointer',
            fontSize: '20px',
        },
    };

    const formSubmit = async (e) => {
        if (formSubmitBtnLbl === 'Recall') {
            setOpenConfirmationPopUp(true);
        }
    };
    const toggleDeleteWarningPopUp = (flag: boolean) => {
        setIsDeleteWarningPopUpOpen(flag);
    };
    const toggleDeleteStatusPopUp = (status?: string) => {
        if (status === 'DeleteSuccess') {
            setIsDeleteWarningPopUpOpen(false);
            setIsDeleteConfirmationPopUpOpen(true);
        } else if (status === 'DeleteError') {
            setIsDeleteWarningPopUpOpen(false);
            setIsDeleteErrorPopUpOpen(true);
        } else if (isDeleteConfirmationPopUpOpen === true) {
            setIsDeleteConfirmationPopUpOpen(false);
            setIsRedirect(true);
        } else if (isDeleteErrorPopUpOpen === true) {
            setIsDeleteErrorPopUpOpen(false);
        }
    };

    return (
        <>
            {/* Sections */}
            {!loaded ? (
                <LoadingSpinner />
            ) : (
                <>
                    <Stack className={[contentStyles.body, "ml-sm-0"].join(" ")}>
                        {/* Pop Ups */}
                        {isDeleteWarningPopUpOpen && (
                            <DeleteWarningPopUp
                                isModalOpen={isDeleteWarningPopUpOpen}
                                toggleDeleteWarningPopUp={toggleDeleteWarningPopUp}
                                toggleDeleteStatusPopUp={toggleDeleteStatusPopUp}
                                reloId={hireInfo.reloID}
                            />
                        )}
                        {isDeleteConfirmationPopUpOpen && (
                            <DeleteStatusPopUp
                                isModalOpen={isDeleteConfirmationPopUpOpen}
                                toggleDeleteStatusPopUp={toggleDeleteStatusPopUp}
                                message={'This record was successfully deleted.'}
                            />
                        )}
                        {isDeleteErrorPopUpOpen && (
                            <DeleteStatusPopUp
                                isModalOpen={isDeleteErrorPopUpOpen}
                                toggleDeleteStatusPopUp={toggleDeleteStatusPopUp}
                                message={"The record wasn't successfully deleted."}
                            />
                        )}
                        {showSendBackPopUp && (
                            <SendBackPopUp
                                dismiss={dismissSendbackPopUp}
                                formData={reviewFormDataResponse[0]}
                                LoggedInAlias={userdata.Alias}
                                updateisSendBack={updateisSendBack}
                            />
                        )}

                        {loading && (
                            <Stack>
                                <div>
                                    <Spinner
                                        label="Loading, please wait..."
                                        size={SpinnerSize.large}
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            height: '100%',
                                            width: '100%',
                                            zIndex: 9999999,
                                            backgroundColor: 'rgb(255, 255, 255, 255)',
                                        }}
                                    />
                                </div>
                            </Stack>
                        )}

                        {/* Status */}
                        <h1><Stack
                            horizontal={true}
                            className="statusSection ml-sm-0"
                            style={{ borderTop: '3px solid #dcdcdc' }}
                            horizontalAlign="end"
                        >
                            <Stack.Item>
                                {isSubmittedImg &&
                                    (displayStatus?.toLowerCase() == 'resubmitted' ||
                                        displayStatus?.toLowerCase() == 'authorized') && (
                                        <TextField
                                            iconProps={iconPropsWarnings}
                                            ariaLabel="Status"
                                            value={displayStatus}
                                            styles={textFieldStyles}
                                            borderless
                                            readOnly={true}
                                        ></TextField>
                                    )}
                                {isSubmittedImg &&
                                    displayStatus?.toLowerCase() !== 'resubmitted' &&
                                    displayStatus?.toLowerCase() !== 'authorized' && (
                                        <TextField
                                            iconProps={iconPropsSubmitted}
                                            ariaLabel="Status"
                                            value={displayStatus!}
                                            styles={textFieldStyles}
                                            borderless
                                            readOnly={true}
                                        ></TextField>
                                    )}
                                {isFromStaffing && displayStatus?.toLowerCase() == 'resubmitted' && (
                                    <TextField
                                        iconProps={iconPropsSubmitted}
                                        ariaLabel="Status"
                                        value={displayStatus!}
                                        styles={textFieldStyles}
                                        borderless
                                        readOnly={true}
                                    ></TextField>
                                )}
                                {isSavedImg && (
                                    <TextField
                                        iconProps={iconPropsWarnings}
                                        ariaLabel="Status"
                                        value={displayStatus!}
                                        styles={textFieldStyles}
                                        borderless
                                        readOnly={true}
                                    ></TextField>
                                )}
                                {isReturnForEditsImg && (
                                    <TextField
                                        iconProps={iconPropsError}
                                        ariaLabel="Status"
                                        value={displayStatus!}
                                        styles={textFieldStyles}
                                        borderless
                                        readOnly={true}
                                    ></TextField>
                                )}
                            </Stack.Item>
                        </Stack></h1>

                        {apiSuccess && (
                            <MessageBar
                                messageBarType={MessageBarType.success}
                                isMultiline={false}
                                onDismiss={handleSuccessMessageBar}
                                dismissButtonAriaLabel="Close"
                            >
                                {messageText}
                            </MessageBar>
                        )}

                        {SendBackCmnts && (
                            <Stack {...oneColumnProps} styles={accordionHeader}>
                                <TextField
                                    label="Comments"
                                    multiline
                                    rows={3}
                                    readOnly={true}
                                    value={reviewFormDataResponse[0].SendBackComments}
                                    styles={sendBackCommentsStyles}
                                    draggable={false}
                                ></TextField>
                            </Stack>
                        )}

                        {sendBackComntsSuccess && (
                            <MessageBar
                                messageBarType={MessageBarType.success}
                                isMultiline={false}
                                onDismiss={handleSuccessMessageBar}
                                dismissButtonAriaLabel="Close"
                            >
                                Eform has been successfully sent back
                            </MessageBar>
                        )}

                        {apiError && (
                            <MessageBar
                                messageBarType={MessageBarType.error}
                                isMultiline={false}
                                onDismiss={handleErrorMessageBar}
                                dismissButtonAriaLabel="Close"
                            >
                                {messageText}
                            </MessageBar>
                        )}

                        <Stack className={contentStyles.body}>
                            <HireInformation
                                hireInfo={hireInfo}
                                internshipTypeID={reviewFormDataResponse[0].InternshipTypeID}
                                intersnhipTypeName={intersnhipTypeName}
                            />
                            <PersonalInformation personalInfo={personalInfo} />
                            <EmploymentInformation
                                employmentInfo={employmentInfo}
                                jobLevelData={jobLevelInformation}
                                showHrStaffingManagerAliastxtBox={_showHrStaffingManagerAliastxtBox}
                                ShowHrStaffinggroupNamelists={_HrStaffinggroupNamelists}
                                HrStaffingNameLabel={_HrStaffingName}
                                HrHiringManagerLabel={_HiringManagerLbl}
                                InternshipID={reviewFormDataResponse[0].InternshipTypeID}
                                HireTypeID={reviewFormDataResponse[0].HireTypeID}
                                SelectedManagerAlias={SelectedManagerAlias}
                                relocationPolicyID={_relocationPolicyID}
                                relocationpolicyTypeID={_relocationpolicyTypeID}
                                relocationType={_relocationType}
                                RushComments={_rushComments}
                                DepartureCompanyCode={_departureCompanyCode}
                                DestinationCompanyCode={_destinationCompanyCode}
                                RequisitionID={_requisitionID}
                            />
                            <RelocationInformation
                                reloInfo={reloInfo}
                                hireTypeOption={reviewFormDataResponse[0].HireTypeID}
                                showBenefitsPanel={_showBenefitsPanel}
                                showOptionalException = {_showOptionalException}
                                preHireTypeBenefitsMapping={_preHireTypeBenefitsMapping}
                                showLumpSumPanel={_showLumpSumPanel}
                                showLimitedBenefits={_showLimitedBenefits}
                                showPreHireTypePanel={_showPreHireTypePanel}
                                preHireTypeBenefits={_preHireTypeBenefits}
                                selectedPreHireTypeBenefits={_selectedPreHireTypeBenefits}
                                coreBenefits={_coreBenefits}
                                coreAllowance={_coreAllowance}
                                exceptionsResponse={_exceptionsResponse}
                                lumpsumRelocaiton={reviewFormDataResponse[0].LumpSumRelocation}
                                RelocationCashAmount={relocationCashAmount}
                                taxpactforlumpsum={taxpactforlumpsum}
                            />
                            <Stack styles={accordionHeader}>
                                {commentsForFileSetup && (
                                    <Stack {...oneColumnProps} className="ml-sm-0">
                                        <TextField
                                            label="Comments for File Set Up"
                                            multiline
                                            rows={3}
                                            value={SendToFileSetupComments}
                                            description={`${400 -
                                                (SendToFileSetupComments != undefined
                                                    ? SendToFileSetupComments.length
                                                    : 0)
                                                } Characters Left`}
                                            onBlur={(e) => {
                                                if (
                                                    e.currentTarget.value !== '' &&
                                                    e.currentTarget.value !== undefined &&
                                                    e.currentTarget.value !== null
                                                ) {
                                                    set_SendToFileSetupComments(e.currentTarget.value.toString());
                                                } else {
                                                    set_SendToFileSetupComments('');
                                                }
                                            }}
                                            onChange={(_, newValue?: string) => {
                                                set_SendToFileSetupComments(newValue);
                                            }}
                                        ></TextField>
                                    </Stack>
                                )}
                                <RelocationSummaryAttachment
                                    reloFile={reloInfo.selectedReloFile}
                                    addtionalReloFile0={_additionalReloSummary0}
                                    addtionalReloFile1={_additionalReloSummary1}
                                    addtionalReloFile2={_additionalReloSummary2}
                                    addtionalReloFile3={_additionalReloSummary3}
                                    addtionalReloFile4={_additionalReloSummary4}
                                    GUID0={_AttachmentGUID0}
                                    GUID1={_AttachmentGUID1}
                                    GUID2={_AttachmentGUID2}
                                    GUID3={_AttachmentGUID3}
                                    GUID4={_AttachmentGUID4}
                                    reloID={reviewFormDataResponse[0].ReloID}
                                />

                                {fileUploadErrors && (
                                    <span>
                                        <Text>Please upload the file</Text>
                                    </span>
                                )}
                            </Stack>
                            <Stack
                                className="SubmitButtons"
                                horizontal
                                horizontalAlign="end"
                                tokens={{ childrenGap: 20 }}
                                styles={stackStyles}
                            >
                                {!isReadytoinitiate && (
                                    <PrimaryButton
                                        text="Ready to Initiate"
                                        onClick={async () => {
                                            await ReadyToInitiate();
                                        }}
                                        allowDisabledFocus
                                        disabled={false}
                                        style={buttonStyles}
                                    />
                                )}

                                {isFormDelete && (
                                    <PrimaryButton
                                        text="Delete e-form"
                                        onClick={() => toggleDeleteWarningPopUp(true)}
                                        allowDisabledFocus
                                        disabled={false}
                                        style={buttonStyles}
                                    />
                                )}

                                {editRecordDisabled && (
                                    <PrimaryButton
                                        text="Edit this Record"
                                        onClick={() => {
                                            onEditRecordClick();
                                        }}
                                        allowDisabledFocus
                                        disabled={false}
                                        style={buttonStyles}
                                        className="ml-sm-0"
                                    />
                                )}

                                {!sendbackdisabled && (
                                    <PrimaryButton
                                        text="Send Back"
                                        onClick={async () => {
                                            await onSendBackClick();
                                        }}
                                        allowDisabledFocus
                                        disabled={false}
                                        style={buttonStyles}
                                        className="ml-sm-0"
                                    />
                                )}

                                {SendtoFileSetUpDisabled && (
                                    <PrimaryButton
                                        text="Send to File Setup"
                                        onClick={async () => {
                                            await SendtoFileSetUp();
                                        }}
                                        allowDisabledFocus
                                        style={buttonStyles}
                                        className="ml-sm-0"
                                    />
                                )}

                                {(!resubmitdisabled && (props.redirectedFrom !== 'myreloqueue') )&& (
                                    <PrimaryButton
                                        text={formSubmitBtnLbl}
                                        onClick={formSubmit}
                                        allowDisabledFocus
                                        style={buttonStyles}
                                    />
                                )}
                                <ConfirmationPopUp
                                    isModalOpen={openConfirmationPopUp}
                                    toggleConfirmationPopUp={setOpenConfirmationPopUp}
                                    onSubmit={() => onSubmitClick()}
                                />
                            </Stack>
                        </Stack>
                        {StackDisclaimer()}
                    </Stack>
                </>
            )}
            {isRedirect && <Redirect to={homePage} />}
        </>
    );

    function isNotNullOrUndefined(obj: any) {
        if (obj !== undefined && obj !== null) return true;
        else return false;
    }

    async function getPreHireTypeBenefits() {
        let preHireTypeBenefits: any[] = [];
        let selectedPreHireTypeBenefits: any[] = [];
        preHireTypeBenefits = await startNewReloRequestService.GetPreHireTypeBenefits();
        preHireTypeBenefits.forEach((item: { isSelected: boolean; ID: number }) => {
            if (reloInfo?.preHireTypeBenefitsMapping && reloInfo?.preHireTypeBenefitsMapping.length > 0) {
                _preHireTypeBenefitsMapping.forEach((option) => {
                    if (item.ID == option.SelectedPreHireTypeBenefitID) {
                        item.isSelected = true;
                        selectedPreHireTypeBenefits.push(option);
                    }
                });
            } else {
                item.isSelected = false;
            }
            set_preHireTypeBenefits(preHireTypeBenefits);
            set_selectedPreHireTypeBenefits(selectedPreHireTypeBenefits);
        });
    }

    async function loadLumpSumAmount(ReloTypeID: number, HireTypeID: number, RelocationPolicyID: number) {
        let lumpSumPolicyTypes = [];

        lumpSumPolicyTypes = await startNewReloRequestService.GetLumpSumRelocationTypes(_fiscalYear!);
        let selectedJobLevel = employmentInfo.jobLevelOptions.filter(
            (item) => item.key == employmentInfo.jobLevelID
        )[0];
        let lumpSumJobLevel = '';
        if (isNotNullOrUndefined(selectedJobLevel)) {
            lumpSumJobLevel = 'N/A';
            if (selectedJobLevel.key == '1') {
                lumpSumJobLevel = 'Below L60';
            } else if (
                selectedJobLevel.key == '2' ||
                selectedJobLevel.key == '3' ||
                selectedJobLevel.key == '4' ||
                selectedJobLevel.key == '5' ||
                selectedJobLevel.key == '11'
            ) {
                lumpSumJobLevel = 'L60-L64';
            } else if (selectedJobLevel.key == '6' || selectedJobLevel.key == '7' || selectedJobLevel.key == '8') {
                lumpSumJobLevel = 'L65-L67';
            } else if (selectedJobLevel.key == '9' || selectedJobLevel.key == '10') {
                lumpSumJobLevel = 'Above L68';
            }
        }
        if (ReloTypeID == 28 || ReloTypeID == 24 || ReloTypeID == 25 || ReloTypeID == 52 || ReloTypeID == 59) {
            lumpSumPolicyTypes = lumpSumPolicyTypes.filter(
                (item: { RelocationTypeID: number }) => item.RelocationTypeID == ReloTypeID
            );
        } else {
            lumpSumPolicyTypes = lumpSumPolicyTypes.filter(
                (item: { RelocationTypeID: number; JobLevel: string; HireTypeID: number }) => {
                    return (
                        item.RelocationTypeID == ReloTypeID &&
                        item.JobLevel == lumpSumJobLevel &&
                        item.HireTypeID == HireTypeID
                    );
                }
            );
            if (HireTypeID != 4 && selectedJobLevel != undefined) {
                let tax = taxByJobLevels.filter((item) => {
                    return item.JobLevelId == selectedJobLevel.key && item.Fiscal_Year == _fiscalYear;
                })[0];
                taxPctForLumpSum = tax?.TaxRate || 0;
            } else if (lumpSumPolicyTypes && lumpSumPolicyTypes.length > 0) {
                taxPctForLumpSum = lumpSumPolicyTypes[0].TaxRateForLumpSum;
            }
        }
        let savedNetcash = 0;
        let savedAccCostCenter = 0; 
        if (isNotNullOrUndefined(lumpSumPolicyTypes) && lumpSumPolicyTypes.length > 0) {
            if (
                lumpSumRelocation != null &&
                (formStatus?.toLowerCase() == 'saved' ||
                    formStatus?.toLowerCase() == 'ready to initiate' ||
                    formStatus?.toLowerCase() == 'resubmitted')
            ) {
                if (!savedAlready) {
                    setsavedAlready(true);
                    savedNetcash = lumpSumRelocation.NetCash;
                    savedAccCostCenter = parseFloat(
                        ((lumpSumRelocation.NetCash * taxPctForLumpSum) / 100 + lumpSumRelocation.NetCash).toFixed(2)
                    );
                }
            }
            setlumpSumRelocation({} as LumpSumRelocation);
            if (!isNotNullOrUndefined(lumpSumRelocation)) {
                let lumpSumRelo: LumpSumRelocation = {
                    ID: 0,
                    RelocationPolicyTypeID: ReloTypeID!,
                    ReloID: hireInfo.reloID,
                    NetCash:
                        savedNetcash == 0
                            ? parseFloat(lumpSumPolicyTypes[0].NetCash.replace(/$/g, '').replace(/,/g, ''))
                            : savedNetcash,
                    AccrualCostCenter:
                        savedAccCostCenter == 0
                            ? parseFloat(
                                (
                                    (parseFloat(lumpSumPolicyTypes[0].NetCash.replace(/$/g, '').replace(/,/g, '')) *
                                        taxPctForLumpSum) /
                                    100 +
                                    parseFloat(lumpSumPolicyTypes[0].NetCash.replace(/$/g, '').replace(/,/g, ''))
                                ).toFixed(2)
                            )
                            : savedAccCostCenter,
                    CreatedBy: userdata.Alias,
                    CreatedOn: new Date().toString(),
                    IsRemoved: false,
                    TotalPackageValue: 0,
                    FileFee: 0,
                    TaxRate: 0,
                };
                setlumpSumRelocation(lumpSumRelo);
            }
        }
    }

    async function getCoreBenefits(reloTypeId: number) {
        let fiscal_Year = _fiscalYear;
        if (fiscal_Year === undefined || fiscal_Year === 0) {
            fiscal_Year = new Date().getFullYear();
        }

        let coreBenefits: any[] = [];
        let resp = await reviewFormService.getCoreBenefits(reloTypeId, fiscal_Year);
        let coreAllowance: any[] = [];
        let coreTaxRate = '';
        let selectedJobLevel = employmentInfo.jobLevelOptions.filter(
            (item) => item.key == employmentInfo.jobLevelID
        )[0];
        if (hireInfo.hireTypeID != 4 && selectedJobLevel != undefined) {
            let tax = taxByJobLevels.filter((item) => {
                return item.JobLevelId == selectedJobLevel.key && item.Fiscal_Year == fiscal_Year;
            })[0];
            coreTaxRate = tax?.TaxRate?.toString() || '';
        }
        let filterResult = resp.filter((e: any) => {
            if (e.CoreBeniftName.trim() == 'Relocation Cash Allowance') {
                if (
                    e.RelocationPolicyTypeID === 19 ||
                    e.RelocationPolicyTypeID === 20 ||
                    e.RelocationPolicyTypeID === 21 ||
                    e.RelocationPolicyTypeID === 37
                ) {
                    if (e.CoreBenifitID == 3) {
                        coreAllowance.push({
                            CoreBenifitID: 0,
                            CashAmount: e.Amount,
                            IsLessThan: parseFloat(e.Amount) <= 10000 ? true : false,
                            CoreBeniftName: 'Relocation Cash Allowance',
                            IsDisabled: true,
                        });
                        e.Amount = '$' + e.Amount.toString();
                    }
                    return e;
                } else {
                    coreAllowance.push({
                        CoreBenifitID: 0,
                        CashAmount: e.Amount,
                        IsLessThan: parseFloat(e.Amount) <= 10000 ? true : false,
                        CoreBeniftName: 'Relocation Cash Allowance',
                        IsDisabled: false,
                    });
                }
            }
            return e.CoreBeniftName.trim() != 'Relocation Cash Allowance';
        });
        /*
        As lump sum have multiple repeated core benefits for different levels
        for that we only grab the first one.
        */
        if(reloTypeId == 57 || reloTypeId == 59){
            filterResult = filterResult.slice(0,1);
        }
        coreBenefits = filterResult;
        set_coreBenefits(coreBenefits);
        set_coreAllowance(coreAllowance);
        set_fiscalYear(fiscal_Year);
    }

    async function getExceptions(relocationpolicyTypeID: number) {
        let exceptions: any[] = [];
        let resp = await reviewFormService.getExceptions(relocationpolicyTypeID);
        let exceptionsDropDownOptions: IDropdownOption[] = [];
        resp.forEach((element: any) => {
            let option: IDropdownOption = { key: element.ExceptionID, text: element.ExceptionName };
            exceptionsDropDownOptions.push(option);
        });

        let allExceptions = resp;
        exceptions = resp.filter((item: { OptionalBenifitID: number }) => item.OptionalBenifitID == 0);
        if (relocationpolicyTypeID == 36) {
            exceptions = resp;
        } else {
            _allOptionalBenefits.forEach((option) => {
                if (option.isSelected == true) {
                    let filteredException = allExceptions.filter(
                        (item: { OptionalBenifitID: any }) => item.OptionalBenifitID == option.OptionalBenifitID
                    )[0];
                    if (isNotNullOrUndefined(filteredException)) {
                        let selectedException = exceptions.filter(
                            (item) => item.OptionalBenifitID == filteredException.OptionalBenifitID
                        )[0];
                        if (typeof selectedException == 'undefined') {
                            exceptions.push(filteredException);
                        }
                    }
                }
            });
        }

        if (_exceptionMapping !== null && typeof _exceptionMapping !== 'undefined') {
            let gridExceptions: any[] = [];
            _exceptionMapping.forEach((exc) => {
                let selectedExceptionID = exc.ExceptionID;
                let selectedException = exceptions.filter((item) => item.ExceptionID == selectedExceptionID);
                if (isNotNullOrUndefined(selectedException[0])) {
                    selectedException[0].UnitStatus = selectedException[0].Unit;
                    selectedException[0].Unit = exc.Unit;
                    selectedException[0].Amount = exc.Amount;
                    gridExceptions.push(selectedException[0]);
                }
                set_gridExceptions(gridExceptions);
            });
        }
        set_exceptionsResponse(resp);
        set_exceptionOptions(exceptionsDropDownOptions);
        set_exceptions(exceptions);
    }

    async function OnSaveClick() {
        let reviewFormObject = reviewFormDataResponse[0];
        reviewFormObject.LoggedInUser = userdata.Alias;
        reviewFormObject.Status = 'Saved';
        reviewFormObject.IsEdit = true;
        if (reviewFormObject.AttachReloSummary !== null && reviewFormObject.AttachReloSumary !== undefined) {
            reviewFormObject.AttachReloSummary = {
                AddtionalDescription: reviewFormObject.AttachReloSummary.AddtionalDescription,
                AttachReloSummaryID: reviewFormObject.AttachReloSummary.AttachReloSummaryID,
                CreatedBy: userdata.Alias,
                CreatedDate: reviewFormObject.AttachReloSummary.CreatedDate,
                Filename: reviewFormObject.AttachReloSummary.Filename,
                IsAdditional: 0,
                IsRemoved: false,
                ReloID: reviewFormObject.AttachReloSummary.ReloID,
            };
        } else {
            reviewFormObject.AttachReloSummary = {
                AddtionalDescription: '',
                AttachReloSummaryID: 0,
                CreatedBy: userdata.Alias,
                CreatedDate: new Date(),
                Filename: null,
                IsAdditional: 0,
                IsRemoved: true,
                ReloID: props.recordID,
            };
        }
        await startNewReloRequestService
            .SaveRelocationForm(reviewFormObject)
            .then((result) => {
                if (result.status == 201) {
                }
            })
            .catch((error) => {
                alert('Sorry, something went wrong. E-form couldn’t be saved.');
                console.log(error);
            });

        return reviewFormObject;
    }

    async function onInternshipTypeChange() {
        if (
            reviewFormDataResponse[0]?.InternshipTypeID != null &&
            typeof reviewFormDataResponse[0].InternshipTypeID != 'undefined'
        ) {
            let selectedHireTypes = hireInfo.hireTypesOptions.filter((row) => {
                if (row.key === reviewFormDataResponse[0].HireTypeID) {
                    return row.text as string;
                }
            });
            let selectedHireType = selectedHireTypes[0].text;
            if (selectedHireType == 'Intern') {
                if (selectedHireType == 'Intern') {
                    set_HiringManagerLbl('Program Manager (GMAT Request Approver)');
                    if (reviewFormDataResponse[0].InternshipTypeID == 5) {
                        set_HrStaffinggroupNamelists(false);
                        set_showHrStaffingManagerAliastxtBox(true);
                        set_HrStaffingName('Staffing Hire Group (For US)/ Staffing Manager (For Non US)');
                    } else if (reviewFormDataResponse[0].InternshipTypeID == 6) {
                        set_HrStaffinggroupNamelists(true);
                        set_showHrStaffingManagerAliastxtBox(false);
                        employmentInfo.hiringManagerAlias = '';
                    } else {
                        if (selectedHireType != 'Intern') {
                            set_HrStaffingName('Staffing Manager Alias (GMAT Request Approver)');
                        }
                        set_showHrStaffingManagerAliastxtBox(false);
                        set_HrStaffinggroupNamelists(true);
                        employmentInfo.hiringManagerAlias = 'HEIDI';
                    }
                }
                if (reviewFormDataResponse[0].ReloID != 0) {
                    set_relocationpolicyTypeID(parseInt(reviewFormDataResponse[0].RelocationpolicyTypeID));
                }
            }
        }
    }

    async function LoadRelocationType(selectedPolicyType: IDropdownOption) {
        if (
            reviewFormDataResponse[0].HireTypeID != null &&
            typeof reviewFormDataResponse[0].HireTypeID != 'undefined'
        ) {
            let selectedHireTypes = hireInfo.hireTypesOptions.filter((row) => {
                if (row.key === reviewFormDataResponse[0].HireTypeID) {
                    return row.text as string;
                }
            });
            let selectedHireType = selectedHireTypes[0].text;

            if (reviewFormDataResponse[0].ReloID != 0) {
                set_relocationpolicyTypeID(parseInt(reviewFormDataResponse[0].RelocationpolicyTypeID));
            }

            if (selectedHireType != 'University FTE' && selectedHireType != 'Intern') {
                set_HrStaffingName('Staffing Manager Alias (GMAT Request Approver)');
                set_SelectedManagerAlias(reviewFormDataResponse[0].HRStaffingManagerAlias);
            } else {
                set_SelectedManagerAlias('Staffing Hire Group (For US)/ Staffing Manager (For Non US)');
                set_SelectedManagerAlias(reviewFormDataResponse[0].HRStaffinggroup);
                reviewFormDataResponse[0].HRStaffingManagerAlias = reviewFormDataResponse[0].HRStaffinggroup;
            }
            if (selectedHireType == 'Intern') {
                let result = await reviewFormService.GetInternshipTypes();
                result = result.filter((item) => {
                    return item.InternshipTypeID == reviewFormDataResponse[0].InternshipTypeID;
                });

                let selectedInternshipType = result[0].InternshipTypeName;

                if (selectedInternshipType != null && typeof selectedInternshipType != 'undefined')
                    setIntersnhipTypeName(selectedInternshipType);
                hireInfo.showInternship = true;

                if (reviewFormDataResponse[0].InternshipTypeID == 5) {
                    set_HrStaffinggroupNamelists(false);
                    set_showHrStaffingManagerAliastxtBox(true);
                } else {
                    set_HrStaffinggroupNamelists(true);
                    set_showHrStaffingManagerAliastxtBox(false);
                }
            } else {
                hireInfo.showInternship = false;
            }

            if (selectedHireType == 'Intern' || selectedHireType == 'University FTE') {
                set_HrStaffinggroupNamelists(false);
                set_showHrStaffingManagerAliastxtBox(true);

                if (selectedHireType == 'University FTE') {
                    set_HrStaffinggroupNamelists(true);
                    set_showHrStaffingManagerAliastxtBox(false);
                    set_HiringManagerLbl('Hiring Manager Alias (Request Approver)');
                } else {
                    set_HrStaffingName('Program Manager (GMAT Request Approver)');
                    if (reviewFormDataResponse[0].InternshipTypeID == 5) {
                        set_HrStaffinggroupNamelists(false);
                        set_showHrStaffingManagerAliastxtBox(true);
                    } else {
                        set_HrStaffinggroupNamelists(true);
                        set_showHrStaffingManagerAliastxtBox(false);
                    }
                }
            } else {
                set_HrStaffinggroupNamelists(false);
                set_showHrStaffingManagerAliastxtBox(true);
                set_HrStaffingName('E-form Submitters Manager Alias');
                set_HiringManagerLbl('Hiring Manager Alias (Request Approver)');
            }
        }

        if (
            reviewFormDataResponse[0].HireTypeID != null &&
            typeof reviewFormDataResponse[0].HireTypeID != 'undefined' &&
            reviewFormDataResponse[0].RelocationPolicyID != null &&
            typeof reviewFormDataResponse[0].RelocationPolicyID != 'undefined'
        ) {
            await getRelocationTypes(
                reviewFormDataResponse[0].HireTypeID,
                parseInt(reviewFormDataResponse[0].RelocationPolicyID)
            );
        } else set_reloTypes([]);
    }

    async function getRelocationTypes(HireTypeID: number, RelocationPolicyID: number) {
        let reloTypes: RelocationType[];
        let result = await reviewFormService.getrelocationType();

        reloTypes = result;

        var filterrelotypes = reloTypes.filter((type) => {
            return type.HireTypeID == HireTypeID && type.RelocationPolicyID == RelocationPolicyID;
        });

        if (typeof filterrelotypes !== 'undefined' && filterrelotypes.length > 0) {
            reloTypes = filterrelotypes;
            if (isNotNullOrUndefined(reviewFormDataResponse[0].RelocationpolicyTypeID)) {
                await changeReloType1(reloTypes);
                let currentRelocationType = reloTypes.filter((item) => {
                    return item.RelocationTypeID == reviewFormDataResponse[0].RelocationpolicyTypeID;
                });
                if (isNotNullOrUndefined(currentRelocationType) && currentRelocationType.length > 0) {
                    set_relocationType(currentRelocationType[0].RelocationTypeName);

                    if (currentRelocationType[0].ReloPolicyType != null) {
                    }
                }
            }
        } else reloTypes = [];
    }

    async function changeReloType1(_reloTypes: RelocationType[]) {
        var reloTypeID = parseInt(reviewFormDataResponse[0].RelocationpolicyTypeID);

        if (reloTypeID != 0 && reloTypeID !== null && typeof reloTypeID !== 'undefined') {
            let selectedReloType: RelocationType = _reloTypes.filter((item) => {
                return parseInt(item.RelocationTypeID) == reloTypeID;
            })[0];

            if (selectedReloType.HireTypeID == 7) {
                set_showLumpSumPanel(false);
                set_showBenefitsPanel(false);
                set_showLimitedBenefits(false);
                set_showPreHireTypePanel(true);
                await getPreHireTypeBenefits();
            } else {
                if (selectedReloType.ReloPolicyType != null) {
                    if (selectedReloType.ReloPolicyType == 'LumSum') {
                        set_showLumpSumPanel(true);
                        getLumpSumRelocationTypes(
                            reloTypeID,
                            reviewFormDataResponse[0].HireTypeID,
                            reviewFormDataResponse[0].RelocationPolicyID
                        );
                        if(selectedReloType.RelocationTypeName.includes("Plus")){
                            set_showBenefitsPanel(true);
                            set_showOptionalException(false);
                            await getCoreBenefits(reloTypeID);
                        }else{
                            set_showBenefitsPanel(false);
                        }
                    } else {
                        set_showLumpSumPanel(false);
                        set_showBenefitsPanel(true);
                        set_showOptionalException(true);
                        reviewFormDataResponse[0].LumpSumRelocation = {} as LumpSumRelocation;
                        await getCoreBenefits(reloTypeID);
                        await getExceptions(reloTypeID);

                    }
                }
            }

            if (reloTypeID == 36) {
                set_showLumpSumPanel(false);
                set_showBenefitsPanel(false);
                set_showLimitedBenefits(true);
                await getExceptions(reloTypeID);
            }
            else if(reloTypeID == 54 || reloTypeID == 55){
                set_showOptionalException(false);
            }
        } else {
            set_showLumpSumPanel(false);
            set_showBenefitsPanel(false);
        }
    }

    async function ReadyToInitiate() {
        if (props.recordID !== null || props.recordID !== undefined)
            await reviewFormService
                .saveReadyToInitiate(props.recordID)
                .then((result) => {
                    if (result.status == 201) {
                        set_isReadytoinitiate(true);
                        set_SendtoFileSetUpDisabled(true);
                        set_DisplayStatus('Ready to Initiate');
                    } else {
                        set_isReadytoinitiate(false);
                        set_SendtoFileSetUpDisabled(false);
                    }
                })
                .catch(() => {
                    set_isReadytoinitiate(false);
                    set_SendtoFileSetUpDisabled(false);
                })
                .finally(() => { });
    }

    async function SendtoFileSetUp() {
        if (
            employmentInfo.costCenter!.length > 1 &&
            [5, 7, 8].find((item) => item === employmentInfo.costCenter!.length) !== undefined
        ) {
            setLoaded(false);
            await reviewFormService
                .SendToFileSetUp(props.recordID, SendToFileSetupComments!)
                .then((result) => {
                    if (result.status == 201) {
                        set_SendtoFileSetUpDisabled(false);
                        set_editRecordDisabled(false);
                        set_sendbackdisabled(true);
                        set_isFormDelete(false);
                        set_ParentStatus('Authorized');
                    }
                })
                .catch((error) => {
                    alert('Sorry, something went wrong while sending email');
                })
                .finally(() => {
                    setLoaded(true);
                });
        }
    }

    async function getLumpSumRelocationTypes(reloTypeID: number, HireTypeID: number, RelocationPolicyID: string) {
        loadLumpSumAmount(reloTypeID, HireTypeID, parseInt(RelocationPolicyID));
    }
};
