import * as React from 'react';
import {
    buildColumns,
    DetailsList,
    IColumn,
    ITextFieldStyles,
    SelectionMode,
    Stack,
    Text,
    TextField,
} from '@fluentui/react';
import { CoreAllowance, CoreBenefit } from '../../Models/INewRelocationRequest';

type CoreBenefitsProps = {
    reloTypeId: number;
    reloPolicyId: number;
    hireTypeId: number;
    coreBenefits: CoreBenefit[];
    coreAllowance: CoreAllowance[];
    relocationCashAmount: number;
    updateRelocationCashAmt: Function;
    validateOnSubmit: boolean;
    validateOnReSubmit: boolean;
    erroredFields: Function;
    clearedFields: Function;
    taxpactforlumpsum:string;
};

export type ListItem = {
    coreBenefitName: string;
    amount: string;
};

const allowanceStyle = {
    border: '1px solid rgb(237, 235, 233)',
    padding: 10,
    margin :5
};

const textFieldStyles: Partial<ITextFieldStyles> = { fieldGroup: { width: 200 } };

export const CoreBenefitsList: React.FC<CoreBenefitsProps> = (props) => {
    const [items, setItems] = React.useState<ListItem[]>([]);
    const [columns, setColumns] = React.useState<IColumn[]>([]);

    const [relocationCashAmount, setRelocationCashAmount] = React.useState<string>(props.relocationCashAmount.toString() == '0' ? '' : props.relocationCashAmount.toString());

    const [relocationCashAmtError, setRelocationCashAmtError] = React.useState<string | undefined>(undefined);

    const dollarSet = [2, 4, 5, 7];
    const localCurrencySet = [1, 3, 6, 8];

    const [dollarFlag, setDollarFlag] = React.useState(false);

    const [localCurrencyFlag, setLocalCurrencyFlag] = React.useState(false);

    React.useEffect(() => {
        let coreBenefits: ListItem[] = [];
        for (let benefit of props.coreBenefits) {
            let obj = {
                coreBenefitName: benefit.CoreBeniftName,
                amount: benefit.Amount ==="Included @ %" ? 
                "Included @"+props.taxpactforlumpsum+"%"
                :
                benefit.Amount,
            };
            coreBenefits.push(obj);
        }
        let curFlag: boolean = false;
        let dolflag: boolean = false;

        if (localCurrencySet.some((item) => item == props.reloPolicyId)) {
            curFlag = true;
        }
        if (dollarSet.some((item) => item == props.reloPolicyId)) {
            dolflag = true;
        }

        setLocalCurrencyFlag(curFlag);
        setDollarFlag(dolflag);

        setItems(coreBenefits);
        setColumns(_buildColumns(coreBenefits));
    }, [props]);

    React.useEffect(() => {
        if (props.validateOnSubmit || props.validateOnReSubmit) {
            if (
                relocationCashAmount === null ||
                relocationCashAmount === undefined ||
                (relocationCashAmount === '' &&
                    props.reloTypeId != 19 &&
                    props.reloTypeId != 20 &&
                    props.reloTypeId != 21 &&
                    props.reloTypeId != 37 &&
                    props.coreAllowance.length > 0)
            ) {
                setRelocationCashAmtError('Relocation Cash Amount Required');
                props.erroredFields(['relocationCashAmt']);
            }
            else{
                setRelocationCashAmtError(undefined);
                props.clearedFields('relocationCashAmt');
            }
        }
    }, [props.validateOnSubmit, props.validateOnReSubmit]);

    return (
        <>
            {props.reloPolicyId !=14 && (
                    <Text variant="small">
                        Use the Exceptions drop down below for any exceptions approved in addition to the Core Benefits.
                    </Text>
            )}

            <DetailsList items={items} columns={columns} selectionMode={SelectionMode.none} ariaLabelForGrid="Core Benefits List" />

            {props.coreAllowance != undefined &&
                props.coreAllowance.length > 0 &&
                props.reloTypeId != 19 &&
                props.reloTypeId != 20 &&
                props.reloTypeId != 21 &&
                props.reloTypeId != 37 && (
                    <Stack horizontal horizontalAlign="space-between" style={allowanceStyle}>
                        <Stack.Item>
                            {/* 
                     <!--The relocation cash allowance per policy states that staffing/hiring manager can provide 1
                      month’s salary up to a cap of $10,000.To provide more than $10,000, enter $10,000 in the Relocation Cash field then add
                      the remainder as an exception. Executive policies are exempt from the $10,000 relocation cash cap.-->
                  */}
                            <Text variant="large"> Relocation Cash Amount</Text>
                        </Stack.Item>

                        {props.reloTypeId != 19 &&
                            props.reloTypeId != 20 &&
                            props.reloTypeId != 21 &&
                            props.reloTypeId != 37 && (
                                <Stack>
                                    {dollarFlag && <span>$</span>}
                                    {localCurrencyFlag && <span>(In local currency)</span>}
                                    <TextField
                                        value={relocationCashAmount}
                                        onChange={(e, newValue) => setRelocationCashAmount(newValue || '')}
                                        maxLength={props.reloPolicyId == 8 ? 30 : 15}
                                        disabled={
                                            props.coreAllowance[0].IsDisabled ||
                                            (props.hireTypeId == 3 && props.reloPolicyId != 3)
                                        }
                                        styles={textFieldStyles}
                                        onBlur={(e) => { greaterlessThanTenThousand(e.currentTarget.value); updateRelocationCashAmt(e) }
                                        }
                                        errorMessage={relocationCashAmtError}
                                        ariaLabel="Relocation Cash Amount"
                                        role='textbox'
                                    ></TextField>
                                </Stack>
                            )}
                    </Stack>
                )}
        </>
    );

    function greaterlessThanTenThousand(
        CashAmount?: string
    ) {
        if (typeof CashAmount == 'undefined') {
            setRelocationCashAmount('');
        }

        var max = CashAmount;
        if (max == 'Included, varies by country') setRelocationCashAmount(max);

        if (props.reloPolicyId == 1 || props.reloPolicyId == 3 || props.reloPolicyId == 6 || props.hireTypeId == 6) {
            setRelocationCashAmount(max!);
        } else {
            max = removeCommaOrDollar(max!);
            if (parseFloat(max) < 0 || isNaN(parseFloat(max))) setRelocationCashAmount('');
            else if (parseFloat(max) > 100000) setRelocationCashAmount(numberWithCommas('100000'));
            else setRelocationCashAmount(numberWithCommas(`${parseFloat(max)}`))

        }
        if (CashAmount !== null || CashAmount !== undefined) {
            setRelocationCashAmtError(undefined);
            props.clearedFields('relocationCashAmt');
        } else {
            setRelocationCashAmtError('Relocation Cash Amount Required');
            props.erroredFields(['relocationCashAmt']);
        }
    }

    async function updateRelocationCashAmt(
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ) {
        let enteredRelocationCashAmt = await removeCommaOrDollar(event.currentTarget.value);
        props.updateRelocationCashAmt(parseInt(enteredRelocationCashAmt));
    }

    function numberWithCommas(x: string) {
        if (typeof x != 'undefined' && x != '' && x != null) return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        else return '0';
    }

    function removeCommaOrDollar(amount: string) {
        if (amount && amount.length != 0) {
            if (amount.replace) {
                amount = amount.replace(/$/g, '');
                amount = amount.replace(/,/g, '');
            }
        } else amount = '';
        return amount;
    }
};

function _buildColumns(items: ListItem[]): IColumn[] {
    const columns = buildColumns(items);

    const finalColumns = columns.map((col) => {
        switch (col.key) {
            case 'coreBenefitName':
                col.name = 'Benefits';
                col.minWidth = 100;
                col.maxWidth = 200;
                return col;
            case 'amount':
                col.name = 'Amount';
                return col;
            default:
                return col;
        }
    });
    return finalColumns;
}
