import * as React from 'react';
import {
    classNamesFunction,
    DirectionalHint,
    IconButton,
    IIconProps,
    KeyCodes,
    ScreenWidthMinXLarge,
    styled,
} from '@fluentui/react';
import { INavStyleProps, Nav } from '@m365-admin/nav';
import { getStyles } from '@cseo/controls/lib/nav';
import { ICoherenceNavProps, ICoherenceNavStyleProps, ICoherenceNavStyles } from '@cseo/controls/lib/nav/';

const getClassNames = classNamesFunction<INavStyleProps, ICoherenceNavStyles>();
const BUTTON_ID_OPEN_NAV_MOBILE = 'mobileNavOpenButton';
const BUTTON_ID_COLLAPSE_NAV = 'navCollapseButton';

const coherenceNavBase: React.FunctionComponent<ICoherenceNavProps> = (props: ICoherenceNavProps) => {
    const {
        mobileNavOpenButtonId = BUTTON_ID_OPEN_NAV_MOBILE,
        navCollapseButtonId = BUTTON_ID_COLLAPSE_NAV,
        styles,
        theme,
        appName,
        toggleNavAriaLabel,
        toggleNavTooltip,
        ...navProps
    } = props;

    const [navIsHiddenOnMobile, setNavIsHiddenOnMobile] = React.useState<boolean | undefined>(
        !!props.defaultIsNavCollapsed
    );
    // take defaultIsNavCollapsed property into consideration to render Nav on initial launch
    const [isMobile, setIsMobile] = React.useState(window.innerWidth < ScreenWidthMinXLarge);
    const [isCoherenceNavCollapsed, setIsCoherenceNavCollapsed] = React.useState(!!props.defaultIsNavCollapsed);
    const navWrapperRef = React.useRef<HTMLDivElement>(null);
    const mobileNavButtonRef = React.useRef<HTMLDivElement>(null);
    const frameIdRef = React.useRef<number>(0);

    const hamburgerIcon: IIconProps = {
        iconName: 'GlobalNavButton',
    };

    const isNavCollapsed = (isMobile && navIsHiddenOnMobile) || isCoherenceNavCollapsed;

    const classNames = getClassNames(styles, {
        isNavCollapsed: isNavCollapsed,
        theme: theme!,
        shouldScroll: false,
        zoomLevel: window.devicePixelRatio,
    });

    React.useEffect(() => {
        if ((isMobile && navIsHiddenOnMobile) || isCoherenceNavCollapsed) {
            navProps.onNavCollapsed?.(true);
        } else if ((isMobile && !navIsHiddenOnMobile) || !isCoherenceNavCollapsed) {
            navProps.onNavCollapsed?.(false);
        }
    }, [isMobile, navIsHiddenOnMobile, isCoherenceNavCollapsed]);

    React.useEffect(() => {
        window.addEventListener('click', outSideClickDismiss);
        window.addEventListener('keydown', keydownDismiss);

        return (): void => {
            window.removeEventListener('click', outSideClickDismiss);
            window.removeEventListener('keydown', keydownDismiss);
        };
    }, []);

    React.useEffect(() => {
        window.removeEventListener('resize', handleResize);
        window.addEventListener('resize', handleResize);
        return (): void => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isCoherenceNavCollapsed]);

    const handleResize = (): void => {
        if (frameIdRef.current !== 0) {
            cancelAnimationFrame(frameIdRef.current);
        }
        frameIdRef.current = requestAnimationFrame(shouldNavCollapse);
    };

    React.useEffect(() => {
        const windowWidth = window.innerWidth;
        if (navIsHiddenOnMobile !== undefined) {
            if (windowWidth < ScreenWidthMinXLarge && navIsHiddenOnMobile) {
                document.getElementById(mobileNavOpenButtonId)?.focus();
            }
            if (windowWidth < ScreenWidthMinXLarge && !navIsHiddenOnMobile) {
                document.getElementById(navCollapseButtonId)?.focus();
            }
        }
    }, [navIsHiddenOnMobile]);

    const outSideClickDismiss = (event: MouseEvent): void => {
        /* We only want to dismiss if we're:
         1. Clicking on the outside of the entire nav wrapper OR
         2. Clicking on a button in the nav wrapper that's NOT the mobile hamburger
         3. Clicking on a button in the nav wrapper that is NOT a grouping / section (determined by having the aria-expanded attribute) */
        const isMobileHamburger = mobileNavButtonRef?.current?.contains(event.target as Node);
        const isInNavWrapper = navWrapperRef?.current?.contains(event.target as Node);
        const isClickedOnButton = (event.target as Node)?.nodeName.toLowerCase() === 'button';
        const isGroupButton = (event.target as any)?.attributes.getNamedItem('aria-expanded') !== null;

        if (!isMobileHamburger && ((isInNavWrapper && isClickedOnButton && !isGroupButton) || !isInNavWrapper)) {
            setNavIsHiddenOnMobile(true);
        }
    };

    const keydownDismiss = (event: KeyboardEvent): void => {
        // If focus is on hambuger icon, don't dismiss on tab.
        /* istanbul ignore next */
        const activeElementIsHamburgerButton =
            document.activeElement?.id === navCollapseButtonId || document.activeElement?.id === mobileNavOpenButtonId;

        /* istanbul ignore next */
        if (event.keyCode === KeyCodes.escape || (event.keyCode === KeyCodes.tab && !activeElementIsHamburgerButton)) {
            setNavIsHiddenOnMobile(true);
        }
    };

    const showMobileNav = (): void => {
        setNavIsHiddenOnMobile(false);
        setIsCoherenceNavCollapsed(false);
    };

    const navCollapseLinkClick = (event?: React.MouseEvent<HTMLElement, MouseEvent>): void => {
        setNavIsHiddenOnMobile(true);
    };

    const handleNavCollapsed = (): void => {
        if (window.innerWidth > ScreenWidthMinXLarge) {
            setIsCoherenceNavCollapsed(!isCoherenceNavCollapsed);
        }
    };

    const shouldNavCollapse = (): void => {
        if (window.innerWidth < ScreenWidthMinXLarge) {
            setIsMobile(true);
            setNavIsHiddenOnMobile(true);
            setIsCoherenceNavCollapsed(true);
        } else if (window.innerWidth >= ScreenWidthMinXLarge) {
            if (isMobile) {
                setIsMobile(false);
                setNavIsHiddenOnMobile(true);
            }
            setIsCoherenceNavCollapsed(false);
        }
    };

    return (
        <>
            <div className={classNames.mobileNavWrapper} ref={mobileNavButtonRef}>
                <IconButton
                    iconProps={hamburgerIcon}
                    className={classNames.mobileNavTrigger}
                    onClick={showMobileNav}
                    aria-label={toggleNavAriaLabel ?? `${appName} Navigation`}
                    aria-expanded={navIsHiddenOnMobile === false}
                    id={mobileNavOpenButtonId}
                />
            </div>
            <div
                ref={navWrapperRef}
                className={`${classNames.outerNavWrapper} ${navIsHiddenOnMobile !== false ? '' : classNames.showNav}`}
            >
                <Nav
                    aria-busy="true"
                    {...navProps}
                    collapseNavLinkProps={{
                        title: undefined,
                        onClick: navCollapseLinkClick,
                        id: navCollapseButtonId,
                        'aria-label': toggleNavAriaLabel ?? `${appName} Navigation`,
                        tooltipProps: {
                            directionalHint: DirectionalHint.bottomLeftEdge,
                            content: isNavCollapsed
                                ? toggleNavTooltip?.expand ?? 'Expand navigation'
                                : toggleNavTooltip?.collapse ?? 'Collapse navigation',
                        },
                        ...navProps.collapseNavLinkProps,
                    }}
                    isNavCollapsed={isNavCollapsed}
                    onNavCollapsed={handleNavCollapsed}
                    styles={{
                        root: classNames.root,
                        navContainer: classNames.navContainer,
                        navWrapper: classNames.navWrapper,
                        navGroup: classNames.navGroup,
                        navGroupDivider: classNames.navGroupDivider,
                    }}
                />
            </div>
        </>
    );
};

export const Navigation: React.FunctionComponent<ICoherenceNavProps> = styled<
    ICoherenceNavProps,
    ICoherenceNavStyleProps,
    ICoherenceNavStyles
>(coherenceNavBase, getStyles);
