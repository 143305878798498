import { IHttpClient, IHttpClientResult } from '@employee-experience/common';

import {
    EstimateProfileData,
    JobLevelTaxRates,
    RelocationForm,
    JobLevelOptions,
    EmployeeProfileData,
    CoreBenefitsEntity,
    AllExceptionsEntity,
    EmployeeDetails,
    TaxAmountEntity,
    RelocationType,
    PackageValueProps,
    OptionalBenefitProps,
    FileExceptionProps,
    UpdateAuthFEProps,
    EstimateDetailsProps,
    RelocationPolicyEntity,
    SaveFileExceptionProps,
} from '../../Models/IEstimatesForm';

export class EstimatesFormService {
    private httpClient: IHttpClient;
    private url: string;
    private apiURL: string;

    public constructor(httpClient: IHttpClient) {
        this.httpClient = httpClient;
        this.url = REACT_APP_RELO_API_URL;
        this.apiURL = '';
    }

    public async getEstimatesFormData(fileID: number, reloID: number): Promise<EstimateProfileData[]> {
        if (__IS_ACCESSIBILITY_TESTING__ == 'true') {
            this.apiURL = `${this.url}api/reloform/GetEstimatedProfileRecords/?fileID/getReloID?fileID=${fileID}&getReloID=${reloID}`;
        } else {
            this.apiURL = `${this.url}api/reloform/GetEstimatedProfileRecords/fileID/getReloID?fileID=${fileID}&getReloID=${reloID}`;
        }
        const response: IHttpClientResult<EstimateProfileData[]> = await this.httpClient.request({
            url: `${this.apiURL}`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    }

    public async getJobLevelTaxRatesService(): Promise<JobLevelTaxRates[]> {
        const response: IHttpClientResult<JobLevelTaxRates[]> = await this.httpClient.request({
            url: `${this.url}api/policy/JobLevelTaxRates`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    }

    public async getRelocationForm(reloId: number): Promise<RelocationForm[]> {
        if (__IS_ACCESSIBILITY_TESTING__ == 'true') {
            this.apiURL = `${this.url}api/reloform/RelocationForm/?reloId?reloId=${reloId}`;
        } else {
            this.apiURL = `${this.url}api/reloform/RelocationForm/reloId?reloId=${reloId}`;
        }
        const response: IHttpClientResult<RelocationForm[]> = await this.httpClient.request({
            url: `${this.apiURL}`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    }

    public async getJobLevelInformation(): Promise<JobLevelOptions[]> {
        const response: IHttpClientResult<JobLevelOptions[]> = await this.httpClient.request({
            url: `${this.url}api/common/joblevels`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    }

    public async getJobLevelTaxRates(): Promise<JobLevelOptions[]> {
        const response: IHttpClientResult<JobLevelOptions[]> = await this.httpClient.request({
            url: `${this.url}api/policy/JobLevelTaxRates`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    }

    public async getValidateEstimateRecords(empId: number, reloId: number): Promise<EmployeeProfileData> {
        if (__IS_ACCESSIBILITY_TESTING__ == 'true') {
            this.apiURL = `${this.url}api/reloform/GetValidateEstimateRecords/?EmpID/ReloID?EmpID=${empId}&ReloID=${reloId}`;
        } else {
            this.apiURL = `${this.url}api/reloform/GetValidateEstimateRecords/EmpID/ReloID?EmpID=${empId}&ReloID=${reloId}`;
        }
        const response: IHttpClientResult<EmployeeProfileData> = await this.httpClient.request({
            url: `${this.apiURL}`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    }

    public async getExceptions(reloTypeId: number): Promise<AllExceptionsEntity[]> {
        const response: IHttpClientResult<AllExceptionsEntity[]> = await this.httpClient.request({
            url: `${this.url}api/policy/Exceptions/%7BreloTypeId%7D?reloTypeId=${reloTypeId}`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    }

    public async getAllExceptions(HireTypeID: number, RelocationPolicyTypeID: number): Promise<AllExceptionsEntity[]> {
        const response: IHttpClientResult<AllExceptionsEntity[]> = await this.httpClient.request({
            url: `${this.url}api/policy/AllExceptions/HireTypeID/RelocationPolicyTypeID?HireTypeID=${HireTypeID}&RelocationPolicyTypeID=${RelocationPolicyTypeID}`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    }

    public async getOfferAllExceptions(RelocationPolicyTypeID: number): Promise<AllExceptionsEntity[]> {
        const response: IHttpClientResult<AllExceptionsEntity[]> = await this.httpClient.request({
            url: `${this.url}api/policy/Exceptions/reloTypeId?reloTypeId=${RelocationPolicyTypeID}`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    }

    public async getAllFileExceptions(
        HireTypeID: number,
        ReloID: number,
        RelocationPolicyTypeID: number,
        fileID: number
    ): Promise<FileExceptionProps[]> {
        const response: IHttpClientResult<FileExceptionProps[]> = await this.httpClient.request({
            url: `${this.url}api/reloform/GetAllFileExceptions/fileID/HireTypeID/RelocationPolicyTypeID/ReloID?HireTypeID=${HireTypeID}&ReloID=${ReloID}&RelocationPolicyTypeID=${RelocationPolicyTypeID}&fileID=${fileID}`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    }

    public async getRelocationPolicy(HireTypeID: number): Promise<RelocationPolicyEntity[]> {
        const response: IHttpClientResult<RelocationPolicyEntity[]> = await this.httpClient.request({
            url: `${this.url}api/policy/RelocationPolicies/${HireTypeID}`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    }

    public async getRelocationType(): Promise<RelocationType[]> {
        const response: IHttpClientResult<RelocationType[]> = await this.httpClient.request({
            url: `${this.url}api/policy/RelocationTypes`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    } //coreBenefits

    public async getCoreBenefits(FiscalYear: number, ReloTypeID: number): Promise<CoreBenefitsEntity[]> {
        if (__IS_ACCESSIBILITY_TESTING__ == 'true') {
            this.apiURL = `${this.url}api/policy/RelocationTypes/?${ReloTypeID}/FiscalYear/CoreBenefits?FiscalYear=${FiscalYear}&reloTypeId=${ReloTypeID}`;
        } else {
            this.apiURL = `${this.url}api/policy/RelocationTypes/${ReloTypeID}/FiscalYear/CoreBenefits?FiscalYear=${FiscalYear}&reloTypeId=${ReloTypeID}`;
        }
        const response: IHttpClientResult<CoreBenefitsEntity[]> = await this.httpClient.request({
            url: `${this.apiURL}`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    }

    public async getTaxAmount(
        HireTypeID: number,
        JobLevelID: number,
        ReloID: number,
        RelocationPolicyTypeID: number
    ): Promise<TaxAmountEntity[]> {
        const response: IHttpClientResult<TaxAmountEntity[]> = await this.httpClient.request({
            url: `${this.url}api/policy/GetTaxAmount/HireTypeID/RelocationPolicyTypeID/JobLevelID/ReloID?HireTypeID=${HireTypeID}&JobLevelID=${JobLevelID}&ReloID=${ReloID}&RelocationPolicyTypeID=${RelocationPolicyTypeID}`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    }

    public async getEmployeeBasicDetails(Alias: string): Promise<EmployeeDetails> {
        const response: IHttpClientResult<EmployeeDetails> = await this.httpClient.request({
            url: `${this.url}api/Employees/${Alias}`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    }

    public async getPackageValue(
        FiscalYear: string,
        JobLevelID: number,
        ReloTypeID: number
    ): Promise<PackageValueProps> {
        const response: IHttpClientResult<PackageValueProps> = await this.httpClient.request({
            url: `${this.url}api/reloform/GetPackageValue?FiscalYear=${FiscalYear}&JobLevelID=${JobLevelID}&ReloTypeID=${ReloTypeID}`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    }

    public async getAllOptionalBenefits(
        RelocationPolicyTypeID: number,
        reloType: string
    ): Promise<OptionalBenefitProps[]> {
        const response: IHttpClientResult<OptionalBenefitProps[]> = await this.httpClient.request({
            url: `${this.url}api/policy/RelocationTypes/${RelocationPolicyTypeID}/OptionalBenefits`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    }

    public async getAllOptionalBenefitsDetails(reloType: string): Promise<OptionalBenefitProps[]> {
        const response: IHttpClientResult<OptionalBenefitProps[]> = await this.httpClient.request({
            url: `${this.url}api/policy/OptionalBenefits/${reloType}`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    }
    public async saveFileException(fileExceptionDetails: SaveFileExceptionProps): Promise<string> {
        const response: IHttpClientResult<string> = await this.httpClient.request({
            url: `${this.url}api/reloform/SaveFileException`,
            method: 'POST',
            resource: __CLIENT_ID__,
            data: fileExceptionDetails,
        });
        return response?.data;
    }

    public async saveFileOfferException(offerFileExceptionDetails: FileExceptionProps): Promise<string> {
        const response: IHttpClientResult<string> = await this.httpClient.request({
            url: `${this.url}api/reloform/SaveFileOfferException`,
            method: 'POST',
            resource: __CLIENT_ID__,
            data: offerFileExceptionDetails,
        });
        return response?.data;
    }

    public async updateAuthFE(updateAuthFEDetails: UpdateAuthFEProps): Promise<string> {
        const response: IHttpClientResult<string> = await this.httpClient.request({
            url: `${this.url}api/reloform/UpdateAuthFE`,
            method: 'POST',
            resource: __CLIENT_ID__,
            data: updateAuthFEDetails,
        });
        return response?.data;
    }

    public async getIndvFileExcRecord(FERecordId: number): Promise<FileExceptionProps> {
        const response: IHttpClientResult<FileExceptionProps> = await this.httpClient.request({
            url: `${this.url}api/reloform/GetIndvFileExcRecord/FEID?FEID=${FERecordId}`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    }

    public async deleteFileException(FERecordId: number, ReloID: number): Promise<boolean> {
        const response: IHttpClientResult<boolean> = await this.httpClient.request({
            url: `${this.url}api/reloform/DeleteFileException/FEID/ReloID?FEID=${FERecordId}&ReloID=${ReloID}`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    }

    public async deleteAuthFileException(ExpID: number, ReloID: number): Promise<boolean> {
        const response: IHttpClientResult<boolean> = await this.httpClient.request({
            url: `${this.url}api/reloform/DeleteAuthFileException/ExpID/ReloID?ExpID=${ExpID}&ReloID=${ReloID}`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    }

    public async sendExceptionApproval(FERecordId: number, ReloID: number): Promise<string> {
        const response: IHttpClientResult<string> = await this.httpClient.request({
            url: `${this.url}api/reloform/SendExceptionApproval/FEID/ReloID?FEID=${FERecordId}&ReloID=${ReloID}`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    }

    public async getReloOptionalBenefits(ReloID: number): Promise<OptionalBenefitProps[]> {
        const response: IHttpClientResult<OptionalBenefitProps[]> = await this.httpClient.request({
            url: `${this.url}api/reloform/GetRelooptionalbenifts/reloID?reloID=${ReloID}`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    }

    public async deleteReloID(ReloID: number): Promise<boolean> {
        const response: IHttpClientResult<boolean> = await this.httpClient.request({
            url: `${this.url}api/reloform/DeleteReloID/${ReloID}?ReloID=${ReloID}`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    }

    public async updateEstimatesForm(estimateDetails: EstimateDetailsProps): Promise<string> {
        const response: IHttpClientResult<string> = await this.httpClient.request({
            url: `${this.url}api/reloform/EstimateDetails`,
            method: 'POST',
            resource: __CLIENT_ID__,
            data: estimateDetails,
        });
        return response?.data;
    }

    public async forceCommit(ReloID: number): Promise<boolean> {
        const response: IHttpClientResult<boolean> = await this.httpClient.request({
            url: `${this.url}api/reloform/UpdateCommitStatus/RELOID/CommitStatus?CommitStatus=COMMITTED&RELOID=${ReloID}`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    }

    public async unCommit(ReloID: number): Promise<boolean> {
        const response: IHttpClientResult<boolean> = await this.httpClient.request({
            url: `${this.url}api/reloform/UpdateCommitStatus/RELOID/CommitStatus?CommitStatus=UNCOMMITTED&RELOID=${ReloID}`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    }

    public async isCManager(IsCManager: string): Promise<boolean> {
        const response: IHttpClientResult<boolean> = await this.httpClient.request({
            url: `${this.url}api/reloform/GetAliasIsCManager`,
            method: 'POST',
            resource: __CLIENT_ID__,
            data: IsCManager,
        });
        return response?.data;
    }
}
