import { Stack, StackItem } from '@fluentui/react';
import * as React from 'react';
import { HelpPopupLink } from './HelpPopupLink';
import styled from 'styled-components';

export const StyledHelpPopup = styled.div`
  margin-left: 10px;
  margin-top: 14px;
  color: #000000;
`;
export function HelpPopup() {
  return (
    <StyledHelpPopup>
      <Stack verticalFill>
        <StackItem>
          <h4>Contact us</h4>
        </StackItem>
        <br />
        <StackItem>
          <p>{__ASKHR_BENEFITS_PHONE_NUMBER__}</p>
        </StackItem>
        <StackItem>
          <p>{__ASKHR_BENEFITS_HOURS__}</p>
        </StackItem>
        <br />
        <StackItem>
          <HelpPopupLink link={`mailto:${__ASKHR_BENEFITS_EMAIL__}`} text="AskHR - Benefits" />
        </StackItem>
      </Stack>
    </StyledHelpPopup>
  );
}