import { IColumn } from '@fluentui/react';
import { FontIcon } from '@fluentui/react/lib/Icon';
import * as React from 'react';
import { mergeStyles, mergeStyleSets } from '@fluentui/react/lib/Styling';

const iconClass = mergeStyles({
    fontSize: 20,
    height: 20,
    width: 20,
    margin: '0 10px 0 0',
    display: 'flex'
});

const iconColorNames = mergeStyleSets({
    goldenrod: [{ color: 'goldenrod' }, iconClass],
    RfEClass: [{
        fontSize: 23,
        paddingRight: 5,
        marginLeft: 2,
        color: '#323130',
        display: 'grid',
    }]

});
export const RelocampColumnsPage: IColumn[] = [
    {
        key: 'FullName',
        name: 'Employee Name',
        fieldName: 'FullName',
        minWidth: 100,
        maxWidth: 100,
    },
    {
        key: 'LatestSubmissionDate',
        name: 'Latest Submission Date',
        fieldName: 'LatestSubmissionDate',
        minWidth: 120,
        maxWidth: 120,
    },
    {
        key: 'SubmittedBy',
        name: 'Submitted By',
        fieldName: 'SubmittedBy',
        minWidth: 80,
        maxWidth: 80,
    },
    {
        key: 'Destination',
        name: 'Destination',
        fieldName: 'Destination',
        minWidth: 120,
        maxWidth: 120,
    },
    {
        key: 'Departure',
        name: 'Departure',
        fieldName: 'Departure',
        minWidth: 120,
        maxWidth: 120,
    },
    {
        key: 'EstimatedStartDate',
        name: 'Estimated Start Date',
        fieldName: 'EstimatedStartDate',
        minWidth: 120,
        maxWidth: 120,
    },
    {
        key: 'RelocationType',
        name: 'Relocation Type',
        fieldName: 'RelocationType',
        minWidth: 120,
        maxWidth: 120,
    },
    {
        key: 'RecordID',
        name: 'Record ID',
        fieldName: 'RecordID',
        minWidth: 60,
        maxWidth: 60,
        onRender: (item) => {
            const {EverSendBack } = item;
            return (<>
            <span>{item.RecordID}</span>
                <div className={iconClass}>
                    {EverSendBack && <FontIcon
                        iconName="MailForward"
                        className={iconColorNames.RfEClass}
                    />}
                   </div>
            </>)
        }
                       
                   
    
    },
    {
        key: 'Status',
        name: 'Status',
        fieldName: 'Status',
        minWidth: 80,
        maxWidth: 80,
    },
];
