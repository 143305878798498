import * as React from 'react';
import { Stack, IconButton, IStackItemStyles, IStackStyles, TextField, ITextFieldStyles,Text, FontIcon, mergeStyleSets, mergeStyles, } from '@fluentui/react';
import { DatePicker, IStackProps } from 'office-ui-fabric-react';
import { jobLevelOptions } from '../../Models/IReviewForm';
import { EmploymentInfo } from '../../Models/INewRelocationRequest';
import * as moment from 'moment';
import {RushCommentsRequirementText} from '../../Components/Shared/ReusedLabels';

interface EmploymentInformationProps {
    employmentInfo: EmploymentInfo;
    jobLevelData: jobLevelOptions[];
    showHrStaffingManagerAliastxtBox: boolean;
    ShowHrStaffinggroupNamelists: boolean;
    HrStaffingNameLabel: string;
    HrHiringManagerLabel: string;
    InternshipID: number;
    HireTypeID: number;
    SelectedManagerAlias: string;
    RushComments: string;
    relocationPolicyID: number | undefined;
    relocationpolicyTypeID:  number | undefined;
    relocationType: string;
    DepartureCompanyCode: string;
    DestinationCompanyCode: string;
    RequisitionID: string;
}

// --------- Styles ----------

const stackStyles: Partial<IStackStyles> = {
    root: { width: '100%', marginTop: '7px', marginBottom: '50px', padding: '14px' },
};
const accordionHeader: IStackItemStyles = {
    root: {
        padding: 5,
        cursor: 'pointer',
        fontSize: '20px',
    },
};
const accordionContent: IStackItemStyles = {
    root: {
        margin: '0px 0px 7px 11px',
        width: '99%',
        boxShadow: 'rgb(0 0 0 / 16%) 0px 1px 4px',
    },
};

const iconClass = mergeStyles({
    fontSize: 22,
    height: 22,
    width: 22,
    paddingRight: 20,
});

const classNames = mergeStyleSets({
    submitted: [{ color: '#7BA811' }, iconClass],
    error: [{ color: '#DF0008' }, iconClass],
    warning: [{ color: '#EC9F1D' }, iconClass],
    info: [{ color: '#0078D7' }, iconClass],
});

// --------- Constants ----------
const stackTokens = { childrenGap: 20 };
const oneColumnProps: Partial<IStackProps> = {
    tokens: { childrenGap: 15 },
    styles: { root: { width: '100%' } },
};
const threeColumnProps: Partial<IStackProps> = {
    tokens: { childrenGap: 15 },
    styles: { root: { width: '23%' } },
};
// --------------------------------------------------
export const EmploymentInformation: React.FC<EmploymentInformationProps> = (props) => {
    const [isOpen, setIsOpen] = React.useState<boolean>(true);
    const [selectedBusinessGroup, setSelectedBusinessGroup] = React.useState(props.employmentInfo.businessGroup || '');
    const [selectedJobLevel, setSelectedJobLevel] = React.useState(props.employmentInfo.jobLevel || '');
    const [costCenterTextFieldValue, setCostCenterTextFieldValue] = React.useState(
        props.employmentInfo.costCenter || ''
    );

    const [submitterManagerAlias, setSubmitterManagerAlias] = React.useState(
        props.employmentInfo.hRStaffingManagerAlias || ''
    );
    const[showRushCommentsBox, set_showRushCommentsBox]=React.useState<boolean>(false);
    const [_rushComments] = React.useState<string>(props.employmentInfo.RushComments|| '');
    const [DepartureCompanyCode] = React.useState<string | undefined>(props.employmentInfo.DepartureCompanyCode|| '');
    const [DestinationCompanyCode] = React.useState<string | undefined>(props.employmentInfo.DestinationCompanyCode|| '');
    const [RequisitionID] = React.useState<string | undefined>(props.employmentInfo.RequisitionID|| '');
    const [costcenterErrorMessage, set_costcenterErrorMessage] = React.useState<string>('');
    const [submitterAlias, setSubmitterAlias] = React.useState(props.employmentInfo.hRRecruitingAssociate || '');
    const [hiringManagerAlias, setHiringManagerAlias] = React.useState(props.employmentInfo.hiringManagerAlias || '');
    const [recruiterAlias, setRecruiterAlias] = React.useState(props.employmentInfo.hRRecruiterAlias || '');
    const [estimatedStartDate, setEstimatedStartDate] = React.useState<string | null>(
        props.employmentInfo.estimatedStartDate || null
    );
    const [estimatedStartDateError, setEstimatedStartDateError] = React.useState<string | undefined>(undefined);
    const [estimatedEndDate, setEstimatedEndDate] = React.useState<string | null>(
        props.employmentInfo.estimatedEndDate || null
    );

    // Opens and closes hire information component
    const toggleEmploymentInformation = () => {
        setIsOpen(!isOpen);
    };

    // To format Display date
    const formatDate = (date?: Date): string => {
        return !date ? '' : date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();
    };

    const isNotNullOrUndefined = (value: any) => {
        if (value === null || value === undefined) return true;
        if (typeof value === 'string' && value === '') return true;
        return false;
    };

    const textFieldStyles: Partial<ITextFieldStyles> = { fieldGroup: { width: 200 } };
    React.useEffect(() => {
        //Compare Employement Details
        if (props.employmentInfo.businessGroup !== selectedBusinessGroup) {
            setSelectedBusinessGroup(props.employmentInfo.businessGroup);
        }
        if (props.employmentInfo.costCenter !== costCenterTextFieldValue) {
            setCostCenterTextFieldValue(props.employmentInfo.costCenter);
        }
        if (props.employmentInfo.jobLevel !== selectedJobLevel) {
            setSelectedJobLevel(props.employmentInfo.jobLevel);
        }
        if (props.employmentInfo.hRStaffingManagerAlias != submitterManagerAlias) {
            setSubmitterManagerAlias(props.employmentInfo.hRStaffingManagerAlias);
        }
        if (props.employmentInfo.hRRecruitingAssociate != submitterAlias) {
            setSubmitterAlias(props.employmentInfo.hRRecruitingAssociate);
        }
        if (props.employmentInfo.hiringManagerAlias != hiringManagerAlias) {
            setHiringManagerAlias(props.employmentInfo.hiringManagerAlias);
        }
        if (props.employmentInfo.estimatedStartDate != estimatedStartDate) {
            setEstimatedStartDate(props.employmentInfo.estimatedStartDate);
        }
        if (props.employmentInfo.hRRecruiterAlias != recruiterAlias) {
            setRecruiterAlias(props.employmentInfo.hRRecruiterAlias);
        }
        if (props.employmentInfo.estimatedEndDate != estimatedEndDate) {
            setEstimatedEndDate(props.employmentInfo.estimatedEndDate);
        }

        if (
            props.employmentInfo?.costCenter?.length > 1 &&
            [5, 7, 8].find((item) => item === props.employmentInfo.costCenter!.length) === undefined
        ) {
            set_costcenterErrorMessage('Cost center should be 5,7 or 8 digits.');
        }
    }, [props.employmentInfo]);

    const RushChange=() =>{
        var currentDate = new Date().getTime();            
            var estStartDate = moment(props.employmentInfo.estimatedStartDate).toDate().getTime();            
            var startDateDelay = estStartDate - currentDate;
            var delayinDays = Math.floor(startDateDelay / (1000 * 60 * 60 * 24));           
            if (props.HireTypeID != 7 && props.HireTypeID != 4) 
            {
                //Domestic
                if ((props.relocationPolicyID == 1 || props.relocationPolicyID == 2) && delayinDays <= 30) {
                    set_showRushCommentsBox(true);
                }
                //International
                else if ((props.relocationPolicyID == 3 || props.relocationPolicyID == 4 || props.relocationPolicyID == 5 || props.relocationPolicyID == 6 ||props.relocationPolicyID == 10 || props.relocationPolicyID == 11 || props.relocationPolicyID == 12 || props.relocationPolicyID == 13) && delayinDays <= 60) {
                    set_showRushCommentsBox(true);
                }
                else {
                    set_showRushCommentsBox(false);
                }
            }
            else 
            {
                set_showRushCommentsBox(false);
            }
    };

    React.useEffect(()=>
    {
        RushChange();        
    },[props.employmentInfo.estimatedStartDate]);

    return (
        <Stack className="EmploymentInformation" style={{ padding: '0px 2px 0px 2px' }}>
            <Stack
                horizontal={true}
                style={{ backgroundColor: 'rgb(235, 235, 235)', maxWidth: '100%', margin: '5px 0px 0px 5px' }}
            >
                <Stack.Item style={{ marginTop: '3px' }} className="ac-icon">
                    <IconButton
                        aria-labelledby="Employment Information"
                        title="Employment Information"
                        iconProps={{
                            iconName: isOpen ? 'ChevronUp' : 'ChevronDown',
                        }}
                        onClick={toggleEmploymentInformation}
                    />
                </Stack.Item>
                <Stack.Item align="center" styles={accordionHeader} className="ac-title">
                    <h2 style={{ fontSize: '20px' }}> Employment Information </h2>
                </Stack.Item>
            </Stack>

            {isOpen && (
                <Stack styles={accordionContent}>
                    <Stack horizontal tokens={stackTokens} styles={stackStyles}>
                        <Stack {...threeColumnProps}>
                            <TextField
                                label="Business Group"
                                value={selectedBusinessGroup}
                                styles={textFieldStyles}
                                readOnly={true}
                                disabled={true}
                            />
                        </Stack>
                        <Stack {...threeColumnProps}>
                            <TextField
                                label="Cost Center"
                                value={costCenterTextFieldValue}
                                styles={textFieldStyles}
                                readOnly={true}
                                disabled={true}
                                errorMessage={costcenterErrorMessage}
                            />
                        </Stack>
                        <Stack {...threeColumnProps}>
                            <TextField
                                label="Job Level"
                                value={selectedJobLevel}
                                styles={textFieldStyles}
                                readOnly={true}
                                disabled={true}
                            />
                        </Stack>

                        <Stack {...threeColumnProps}>
                            <TextField
                                label={props.HrStaffingNameLabel}
                                defaultValue={props.SelectedManagerAlias}
                                styles={textFieldStyles}
                                readOnly={true}
                                disabled={true}
                            />
                        </Stack>
                    </Stack>

                    <Stack horizontal tokens={stackTokens} styles={stackStyles}>
                        <Stack {...threeColumnProps}>
                            <TextField
                                label="E-Form Submitter Alias"
                                defaultValue={submitterAlias}
                                data-value="2"
                                readOnly={true}
                                disabled={true}
                            />
                        </Stack>
                        <Stack {...threeColumnProps} className="ml-sm-0">
                            <TextField
                                label={props.HrHiringManagerLabel}
                                readOnly={true}
                                disabled={true}
                                defaultValue={hiringManagerAlias}
                                data-value="3"
                            />
                        </Stack>                        

                        <Stack {...threeColumnProps}>
                            <TextField
                                label="Recruiter Alias"
                                defaultValue={recruiterAlias}
                                readOnly={true}
                                disabled={true}
                            />
                        </Stack>
                    </Stack>

                    <Stack horizontal tokens={stackTokens} styles={stackStyles}>
                    {(props.HireTypeID == 2) && (
                        <Stack {...threeColumnProps}>
                            <TextField
                                label="Departure Company Code"
                                role='textbox'
                                defaultValue={DepartureCompanyCode}
                                readOnly={true}
                                maxLength={30}
                                disabled={true}
                                required
                            />
                        </Stack>)}
                        <Stack {...threeColumnProps}>
                            <TextField
                                label="Destination Company Code"
                                role='textbox'
                                defaultValue={DestinationCompanyCode}
                                readOnly={true}
                                maxLength={30}
                                disabled={true}
                                required
                            />
                        </Stack>                       

                        <Stack {...threeColumnProps}>
                            <TextField
                                label="Requisition ID"
                                role='textbox'
                                defaultValue={RequisitionID}
                                readOnly={true}
                                disabled={true}
                                maxLength={30}
                                required
                            />
                        </Stack>

                    </Stack>


                    <Stack horizontal tokens={stackTokens} styles={stackStyles}>
                        <Stack {...threeColumnProps}>
                            <DatePicker
                                label="Estimated Start Date"
                                value={estimatedStartDate ? new Date(estimatedStartDate) : undefined}
                                formatDate={formatDate}
                                onSelectDate={(newDate) => {
                                    onStartDateChange(newDate!);
                                }}
                                textField={{ errorMessage: estimatedStartDateError }}
                                minDate={new Date(Date.now())}
                                readOnly={true}
                                disabled={true}
                            />
                        </Stack>                    
                    
                        {props.HireTypeID == 4 && (
                            <Stack {...threeColumnProps}>
                                <DatePicker
                                    label="Estimated End Date"
                                    value={estimatedEndDate ? new Date(estimatedEndDate) : undefined}
                                    formatDate={formatDate}
                                    onSelectDate={(newDate) => {
                                        onEndDateChange(newDate!);
                                    }}
                                    readOnly={true}
                                    disabled={true}
                                    minDate={new Date(Date.now())}
                                />
                            </Stack>
                        )}
                    </Stack>

                    <Stack horizontal tokens={stackTokens} styles={stackStyles}>
                            <Stack horizontal={true} {...oneColumnProps}> 
                                <FontIcon
                                    iconName="Info"
                                    aria-label="Estimated Start Date Info"
                                    className={classNames.info}
                                />
                                <Text variant="small">
                                    If estimated start date is unknown, select a date 30 days from today. 
                                    If Domestic (intra country) relocation, select a minimum of 30 calendar days from today's date.
                                    If Global (cross border) relocation, select a minimum of 60 calendar days from today's date
                                </Text>
                            </Stack>
                        </Stack>                        
                       
                       { showRushCommentsBox &&
                       
                       <Stack horizontal tokens={stackTokens} styles={stackStyles}>
                            <Stack {...oneColumnProps}>
                            <RushCommentsRequirementText />
                                <TextField
                                    label="Rush Comments"
                                    role='textbox'
                                    multiline
                                    rows={3}
                                    maxLength={400}
                                    defaultValue={''}
                                    value={props.employmentInfo.RushComments}
                                    readOnly={true}                                     
                                    disabled={true}                                   
                                    description={`${400 - (props.RushComments != null ? props.RushComments.length : 0)
                                        } Characters Left`}
                                    required
                                ></TextField>
                            </Stack>
                        </Stack>}

                </Stack>
            )}
        </Stack>
    );

    function onStartDateChange(date: Date | undefined) {
        let newDate = formatDate(date);
        if (isNotNullOrUndefined(newDate)) {
            setEstimatedStartDateError('Estimated Start Date is required.');
        } else {
            setEstimatedStartDateError(undefined);
        }
    }

    function onEndDateChange(date: Date | undefined) {
        let newDate = formatDate(date);
        if (isNotNullOrUndefined(newDate)) {
            setEstimatedStartDateError('Estimated Start Date is required.');
        } else {
            setEstimatedStartDateError(undefined);
        }
    }
};
