import {
    CoherencePageSize,
    CoherencePagination,
    ICoherencePageSizeProps,
    ICoherencePaginationProps,
} from '@cseo/controls/lib/pagination';
import { ReduxContext } from '@employee-experience/common';
import { usePageTitle } from '@employee-experience/common/lib/usePageTitle';
import { ExportToExcel } from '../../Components/Shared/ExportToExcel';
import {
    Checkbox,
    PrimaryButton,
    DetailsListLayoutMode,
    DetailsRow,
    Dropdown,
    getTheme,
    IColumn,
    IComboBoxOption,
    IDetailsRowBaseProps,
    IDetailsRowStyles,
    IDropdownOption,
    IScrollablePaneStyles,
    IStackItemStyles,
    IButtonStyles,
    IStackTokens,
    Selection,
    mergeStyleSets,
    ScrollablePane,
    SearchBox,
    SelectionMode,
    ShimmeredDetailsList,
    Stack,
    Text,
    MarqueeSelection,
    IDetailsRowFieldsProps,
    DetailsRowFields,
    Pivot,
    PivotItem,
    IStyleSet,
    IPivotStyles,
} from '@fluentui/react';
import * as React from 'react';
import { IAppState } from '../../Common/Redux/AppState';
import { EstimatesQueue, IEstimatesQueueForm } from '../../Models/IEstimatesQueue';
import { ILoggedInUserRoles, IUserRoles } from '../../Models/IUserRoles';
import { useServiceStore } from '../../RootStateContext';
import { EstimatesQueueColumns } from './EstimatesQueueColumns';
import { LoadingSpinner } from '../../Components/Shared/LoadingSpinner';
import { UnAuthorized } from '../../Components/QueueTable/Unauthorized';
import { DatePicker } from 'office-ui-fabric-react';
import { getStyles } from '../../Components/Shared/FilterPane.styles';
import * as moment from 'moment';
import { StackDisclaimer } from '../../Components/Shared/DisclaimerText';
import { BulkCommitWarningModal } from './BulkCommitWarningModal';
import { ActionType } from '../../Common/Redux/actionTypes';
import { DefaultButton } from '@fluentui/react/lib/Button';
import { Panel } from '@fluentui/react/lib/Panel';
import { useBoolean } from '@fluentui/react-hooks';
import { useHistory } from 'react-router-dom';

export function EstimatesQueue(): React.ReactElement {
    const history = useHistory();
    const {
        EstimatesQueueUnCommittedColumns,
        EstimatesQueueCommittedColumns,
        EstimatesQueueUnCommittedExcelColumns,
        EstimatesQueueCommittedExcelColumns,
    } = EstimatesQueueColumns();
    interface relotypeCheckBoxOptions {
        optionName: string;
        checked: boolean;
    }

    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [estimatesQueue, setestimatesqueue] = React.useState<IEstimatesQueueForm[]>([]);
    const [masterEstimatesQueue, setmasterEstimatesQueue] = React.useState<IEstimatesQueueForm[]>([]);
    const { useSelector, dispatch } = React.useContext(ReduxContext);
    const userdata: IUserRoles = useSelector((state: IAppState) => state.root.userReducer.userRoles);
    const userRole: ILoggedInUserRoles = useSelector((state: IAppState) => state.root.userReducer.loggedInUserRoles);
    const { estimatesQueueService } = useServiceStore();
    const getStoredFilters = useSelector((state: IAppState) => state.root.estimatesQueueFilterPanelReducer.filters);
    const getActiveTabs = useSelector(
        (state: IAppState) => state.root.estimatesQueueFilterPanelReducer.activeEstimatesTab
    );
    const [columns, setColumns] = React.useState<IColumn[]>(EstimatesQueueUnCommittedColumns);
    const [excelColumns, setExcelColumns] = React.useState<IColumn[]>(EstimatesQueueUnCommittedExcelColumns);
    const [currentPageData, setCurrentPageData] = React.useState<EstimatesQueue[]>([]);
    let d = new Date();
    d.setDate(d.getDate() - 6);
    d.setFullYear(d.getFullYear() - 1);
    const [isoFromDate, setisofromDate] = React.useState<string>(d.toISOString());
    const [fromDate, setfromDate] = React.useState<Date>(new Date(isoFromDate));
    const [toDate, settoDate] = React.useState<Date>(new Date());

    // const [isoFilterFromDate, setisoFIlterFromDate] = React.useState<string>(d.toISOString());
    const [filterFromDate, setFilterFromDate] = React.useState<Date>();
    const [filterToDate, setFilterToDate] = React.useState<Date>();
    // const [isoFilterToDate, setisoFilterToDate] = React.useState<string>(new Date().toISOString());

    const [isotoDate, setisotoDate] = React.useState<string>(new Date().toISOString());
    const [dropDownValue, setdropDownValue] = React.useState<string>('Select One');
    const [searchKeyword, setsearchKeyword] = React.useState<string>('');
    const [estimatesQueueStatus, setestimatesQueueStatus] = React.useState<string>('');

    const [relotypeCheckBoxOption, setrelotypeCheckBoxOption] = React.useState<relotypeCheckBoxOptions[]>([]);
    const [defaultrelotypeCheckBoxOption, setdefaultrelotypeCheckBoxOption] = React.useState<relotypeCheckBoxOptions[]>(
        []
    );

    const [selectedreloTypeFitlers, setselectedreloTypeFitlers] = React.useState<string[]>([]);
    const [selectedstatusFilters, setselectedstatusFilters] = React.useState<string[]>([]);
    const [visaPendingValue, setvisaPendingValue] = React.useState<string>('3');
    const [everSendBackValue, seteverSendBackValue] = React.useState<string>('');
    const [selectedByDefectFilters, setselectedByDefectFilters] = React.useState<string[]>([]);
    const [selectedAllDefectFilters, setselectedAllDefectFilters] = React.useState<string[]>([]);
    const [selectedBulkCommitId, setselectedBulkCommitId] = React.useState<string>('');
    const [selectedFileIds, setselectedFileIds] = React.useState<string>('');
    const [bulkCommitButton, setbulkCommitButton] = React.useState<boolean>(true);

    const [isEmpIDDefectChecked, setisEmpIDDefectChecked] = React.useState<boolean>(false);
    const [isCostCenterDefectChecked, setisCostCenterDefectChecked] = React.useState<boolean>(false);
    const [isAllDefectsChecked, setisAllDefectsChecked] = React.useState<boolean>(false);
    const [isNoDefectsChecked, setisNoDefectsChecked] = React.useState<boolean>(false);
    const [isNoActionChecked, setisNoActionChecked] = React.useState<boolean>(false);
    const [isPreviouslyCommittedChecked, setisPreviouslyCommittedChecked] = React.useState<boolean>(false);

    const [isBulkCommitModalOpen, setIsBulkCommitModalOpen] = React.useState<boolean>(false);
    const [searchFiltersApplied, setSearchFiltersApplied] = React.useState<boolean>(false);
    const [filtersQueueApplied, setFiltersQueueApplied] = React.useState<boolean>(false);
    const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);

    const styles = getStyles();
    const DEFAULT_PAGE_SIZE = 10;
    var totalRecords: number = 0;
    const selectedPage = React.useRef<number>(1);
    const pageCount = React.useRef<number>(0);
    const currentPageSize = React.useRef<number>(DEFAULT_PAGE_SIZE);
    let queueItems: EstimatesQueue[] = [];
    const [exportQueueItems, setExportQueueItems] = React.useState<EstimatesQueue[]>([]);

    const isNotNullandUndefined = (value: any) => {
        if (value !== null && value !== undefined) {
            if (typeof value === 'string' && value !== '') return true;
            else if (Array.isArray(value) && value.length > 0) return true;
            else if (typeof value === 'object' && value.constructor.toString().indexOf('Date') > -1) return true;
            else return false;
        } else {
            return false;
        }
    };
    React.useEffect(() => {
        if (isNotNullandUndefined(getActiveTabs) && getActiveTabs === 'UnCommitted') {
            setestimatesQueueStatus('UnCommitted');
            setColumns(EstimatesQueueUnCommittedColumns);
            setExcelColumns(EstimatesQueueUnCommittedExcelColumns);
        } else if (isNotNullandUndefined(getActiveTabs) && getActiveTabs === 'Committed') {
            setestimatesQueueStatus('Committed');
            setColumns(EstimatesQueueCommittedColumns);
            setExcelColumns(EstimatesQueueCommittedExcelColumns);
        }
        if (estimatesQueue.length > 0 && getStoredFilters) {
            Object.keys(getStoredFilters).map((item) => {
                if (isNotNullandUndefined(getStoredFilters[item])) {
                    if (item === 'selectedByDefectFilters') {
                        getStoredFilters[item].map((filter: string) => {
                            switch (filter) {
                                case 'EmpIDDefect':
                                    setisEmpIDDefectChecked(true);
                                    break;
                                case 'CostCenterDefect':
                                    setisCostCenterDefectChecked(true);
                                    break;
                            }
                        });
                        setselectedByDefectFilters(getStoredFilters[item]);
                    } else if (item === 'selectedAllDefectFilters') {
                        getStoredFilters[item].map((filter: string) => {
                            switch (filter) {
                                case 'AllDefects':
                                    setisAllDefectsChecked(true);
                                    break;
                                case 'NoDefects':
                                    setisNoDefectsChecked(true);
                                    break;
                            }
                        });
                        setselectedAllDefectFilters(getStoredFilters[item]);
                    } else if (item === 'selectedreloTypeFitlers') {
                        getStoredFilters[item].map((checkedReloType: string) => {
                            const objIndex = relotypeCheckBoxOption.findIndex(
                                (obj) => obj.optionName == checkedReloType
                            );

                            let reloTypeObj = relotypeCheckBoxOption.filter(
                                (item) => item.optionName !== checkedReloType
                            );

                            const obj = {
                                optionName: checkedReloType,
                                checked: true,
                            };

                            reloTypeObj.splice(objIndex, 0, obj);
                            setrelotypeCheckBoxOption(reloTypeObj);
                        });
                        setselectedreloTypeFitlers(getStoredFilters[item]);
                    } else if (item === 'selectedstatusFilters') {
                        getStoredFilters[item].map((filter: string) => {
                            switch (filter) {
                                case 'IsNoAction':
                                    setisNoActionChecked(true);
                                    break;
                                case 'PreviouslyCommitted':
                                    setisPreviouslyCommittedChecked(true);
                                    break;
                            }
                        });
                        setselectedstatusFilters(getStoredFilters[item]);
                    } else if (item === 'filterFromDate') {
                        setFilterFromDate(new Date(getStoredFilters[item]));
                    } else if (item === 'filterToDate') {
                        setFilterToDate(new Date(getStoredFilters[item]));
                    } else if (item === 'searchDropDown') {
                        setdropDownValue(getStoredFilters[item]);
                    } else if (item === 'searchKeyword') {
                        setsearchKeyword(getStoredFilters[item]);
                    }
                }
            });
            setSearchFiltersApplied(true);
        }
        if (estimatesQueue && estimatesQueue.length > 0) {
            let tempExportQueueItems = estimatesQueue.map((item) => {
                return {
                    ...item,
                    StartDate: item.StartDate ? new Date(item.StartDate) : '',
                    CommitStamp: item.CommitStamp ? new Date(item.CommitStamp) : '',
                };
            });
            setExportQueueItems(tempExportQueueItems);
        } else {
            setExportQueueItems([]);
        }
    }, [estimatesQueue]);

    React.useEffect(() => {
        if (searchFiltersApplied) {
            (async function () {
                await searchResults(true);
                setFiltersQueueApplied(true);
            })();
        }
    }, [searchFiltersApplied]);

    React.useEffect(() => {
        if (filtersQueueApplied) {
            (async function () {
                await filterQueue();
            })();
        }
    }, [filtersQueueApplied]);

    function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
        const key = columnKey as keyof T;
        return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    }

    const dropdownOptions = [
        { key: 'fileID', text: 'File ID' },
        { key: 'name', text: 'Full Name' },
    ];

    const formatDate = (date?: Date): string => {
        return !date ? '' : date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();
    };

    if (estimatesQueue && estimatesQueue.length > 0) {
        queueItems = estimatesQueue.map((row) => {
            return {
                FileId: row.FileId,
                EmployeeID: row.EmployeeID,
                CandidateID: row.CandidateID,
                CostCenterCode: row.CostCenterCode,
                HRRecruitingAssociate: row.HRRecruitingAssociate,
                BusinessGroup: row.BusinessGroup,
                CustomerName: row.CustomerName,
                TotalEstimate: row.TotalEstimate,
                StartDate: row.StartDate ? new Date(row.StartDate) : '',
                CostCenter: row.CostCenter,
                RelocationType: row.RelocationType,
                HireType: row.HireType,
                AssignedTo: row.AssignedTo,
                CommitStamp: moment(row.CommitStamp).format('L'),
                ReloID: row.ReloID,
                CommittedStatus: row.CommittedStatus,
                CostCenterDefect: row.CostCenterDefect,
                DateDefect: row.DateDefect,
                EmailDefect: row.EmailDefect,
                DateDiff: row.DateDiff,
                EverSendBack: row.EverSendBack,
                EmpIDDefect: row.EmpIDDefect,
                FirstNameDefect: row.FirstNameDefect,
                LastNameDefect: row.LastNameDefect,
                PolicyDefect: row.PolicyDefect,
            };
        });
    }

    const fetchData = (pageSize: number, newIndex: number = 0): void => {
        totalRecords = queueItems.length;
        let rowDatata: EstimatesQueue[] = [];
        for (let i = newIndex; i < Math.min(pageSize + newIndex, queueItems.length); ++i) {
            let row: EstimatesQueue = queueItems[i];
            rowDatata.push(row);
        }
        pageCount.current = Math.ceil(totalRecords / currentPageSize.current);

        setCurrentPageData(rowDatata);
    };

    const onPageChange = (newPageNumber: number): void => {
        if (newPageNumber !== selectedPage.current) {
            selectedPage.current = newPageNumber;
            fetchData(currentPageSize.current, (newPageNumber - 1) * currentPageSize.current);
        }
    };

    const onPageSizeChange = (newPageSize: string | number): void => {
        currentPageSize.current = newPageSize as number;
        selectedPage.current = 1;
        fetchData(currentPageSize.current);
    };
    const paginationProps: ICoherencePaginationProps = {
        pageCount: pageCount.current,
        selectedPage: selectedPage.current,
        previousPageAriaLabel: 'previous page',
        nextPageAriaLabel: 'next page',
        inputFieldAriaLabel: 'page number',
        onPageChange: onPageChange,
    };
    let pageSizeList: IComboBoxOption[] = [];
    for (let index = 10; index <= 50; index += 10) {
        if (queueItems.length < index) {
            pageSizeList.push({ key: queueItems.length, text: 'ALL' });
            break;
        }
        pageSizeList.push({ key: index, text: index.toString() });
    }

    const paginationPageSizeProps: ICoherencePageSizeProps = {
        pageSize: currentPageSize.current,
        pageSizeList: [
            { key: 10, text: '10' },
            { key: 20, text: '20' },
            { key: 50, text: '50' },
            { key: 100, text: '100' },
            { key: 200, text: '200' },
            { key: 300, text: '300' },
            { key: 400, text: '400' },
            { key: 500, text: '500' },
        ] as IComboBoxOption[],
        comboBoxAriaLabel: 'select page size',
        onPageSizeChange: onPageSizeChange,
    };

    function onDropdownChange(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void {
        setdropDownValue(option!.key as string);
    }

    React.useEffect(() => {
        getestimatesqueue();
    }, [estimatesQueueStatus]);

    React.useEffect(() => {
        fetchData(currentPageSize.current);
    }, [estimatesQueue]);
    React.useEffect(() => {
        let myGrid = document.querySelectorAll('.ms-DetailsList.is-horizontalConstrained div');
        myGrid.forEach((item) => {
            if (item.getAttribute('role') == 'grid') {
                item.setAttribute('title', 'table data');
            }
        });
    });
    const clearSelectedRecords = () => {
        const selectedRecords = selection.getSelection();
        selectedRecords.forEach((item) => selection.setKeySelected(item.FileId, false, false));
    };

    const selection = React.useMemo(
        () =>
            new Selection({
                onSelectionChanged: () => {
                    _getSelectionDetails();
                },
                canSelectItem: (item: any) => {
                    const { CostCenterDefect, DateDefect, EmpIDDefect, FirstNameDefect, LastNameDefect, PolicyDefect } =
                        item;

                    if (
                        CostCenterDefect ||
                        DateDefect ||
                        EmpIDDefect ||
                        FirstNameDefect ||
                        LastNameDefect ||
                        PolicyDefect
                    ) {
                        return false;
                    } else {
                        return true;
                    }
                },
                selectionMode: SelectionMode.multiple,
                getKey: _getKey,
            }),
        []
    );

    const getestimatesqueue = async () => {
        setLoaded(false);
        if (userdata.Alias !== '' && userdata.Roles.length > 0) {
            var obj1: relotypeCheckBoxOptions[] = [];
            estimatesQueueService
                .getQueueRecords(isotoDate, isoFromDate, estimatesQueueStatus.toUpperCase())
                .then((queuedata) => {
                    queuedata
                        .map((item) => item.RelocationType)
                        .filter((value, index, self) => self.indexOf(value) === index)
                        .forEach((item) => {
                            let abc = {
                                optionName: item,
                                checked: false,
                            };
                            obj1.push(abc);
                        });

                    setrelotypeCheckBoxOption(obj1);
                    setdefaultrelotypeCheckBoxOption(obj1);

                    setestimatesqueue(queuedata);
                    setmasterEstimatesQueue(queuedata);
                })
                .catch((err) => console.log(err))
                .finally(() => setLoaded(true));

            //MAP Relocation Type Filter
        }
    };

    const verticalGapStackTokens: IStackTokens = {
        childrenGap: 5,
        padding: 10,
    };

    const stackItemStyles: IStackItemStyles = {
        root: {
            padding: 5,
        },
    };

    const buttonStyle: IButtonStyles = {
        root: {
            marginRight: 10,
        },
    };

    const spacingStackTokens: IStackTokens = {
        childrenGap: 5,
        padding: 't 150px',
    };

    const pivotItemTokens: Partial<IStyleSet<IPivotStyles>> = {
        link: {
            selectors: {
                ':hover': {
                    backgroundColor: 'rgb(207, 207, 207)',
                },
            },
        },
    };

    usePageTitle(`Estimates Queue - ${__APP_NAME__}`);

    let reloTypeContainer = relotypeCheckBoxOption.map((reloType) => {
        return (
            <Checkbox
                className={styles.checkbox}
                label={reloType.optionName}
                inputProps={{ value: reloType.optionName }}
                onChange={handleReloTypeFilter}
                checked={reloType.checked}
            ></Checkbox>
        );
    });

    const theme = getTheme();
    const checkboxContainer = {
        paddingLeft: '15px',
    };
    const classNames = mergeStyleSets({
        wrapper: {
            height: '40vh',
            position: 'relative',
            maxHeight: 'inherit',
            marginLeft: '15px',
        },
        pane: {
            maxWidth: 'inherit',
            border: '1px solid ' + theme.palette.neutralLight,
        },
    });

    const scrollablePaneStyles: Partial<IScrollablePaneStyles> = { root: classNames.pane };
    React.useEffect(() => {
        let btn = document.querySelectorAll('button');
        let inp = document.querySelectorAll('input');
        let radioGroup = document.querySelectorAll('.ms-ChoiceFieldGroup > div');
        btn.forEach((item) => {
            item.setAttribute('role', 'button');
            item.setAttribute('tabindex', '0');
            if (item.hasAttribute('aria-hidden')) {
                item.setAttribute('disabled', 'true');
            }
            if (item.hasAttribute('aria-posinset')) {
                item.setAttribute('role', 'menuitem');
            }
        });
        inp.forEach((item) => {
            item.setAttribute('tabindex', '0');
            if (item.getAttribute('aria-label') === 'Enter Page Number') {
                item.setAttribute('role', 'textbox');
            }
            if (item.getAttribute('type') === 'checkbox') {
                item.setAttribute('role', 'checkbox');
            }
        });
        radioGroup.forEach((item) => {
            if (item.getAttribute('role') === 'radiogroup') {
                item.setAttribute('title', 'visa pending');
            }
        });
    });

    React.useEffect(() => {
        let btnCommitted = document.getElementsByName("Committed")[0];
        let btnUnCommitted = document.getElementsByName("UnCommitted")[0];
            if (btnCommitted) {
                btnCommitted.setAttribute('role','tab');
            }
            if (btnUnCommitted) {
                btnUnCommitted.setAttribute('role','tab');
            }
    });
    return (
        <>
            {!loaded ? (
                <LoadingSpinner />
            ) : (
                <>
                    {userRole.ReloAdmin || userRole.ReloAnalyst ? (
                        <Stack>
                            <Stack tokens={verticalGapStackTokens} horizontal>
                                <Stack.Item styles={{ root: { width: '100%' } }}>
                                    <Stack.Item align="auto" styles={stackItemStyles}>
                                        <h1>Estimates Queue</h1>
                                    </Stack.Item>
                                    <Stack horizontal horizontalAlign="space-between" styles={stackItemStyles}>
                                        <Stack.Item>
                                            <Pivot
                                                styles={pivotItemTokens}
                                                aria-label="estimate queue status tabs"
                                                linkFormat="tabs"
                                                selectedKey={estimatesQueueStatus}
                                                onLinkClick={() => {
                                                    if (estimatesQueueStatus != 'UnCommitted') {
                                                        setestimatesQueueStatus('UnCommitted');
                                                        setColumns(EstimatesQueueUnCommittedColumns);
                                                        setExcelColumns(EstimatesQueueUnCommittedExcelColumns);
                                                        dispatch({
                                                            type: ActionType.SET_ACTIVETAB,
                                                            data: 'UnCommitted',
                                                        });
                                                        resetFilter();
                                                        resetSearch();
                                                    }

                                                    if (estimatesQueueStatus != 'Committed') {
                                                        setestimatesQueueStatus('Committed');
                                                        setColumns(EstimatesQueueCommittedColumns);
                                                        setExcelColumns(EstimatesQueueCommittedExcelColumns);
                                                        dispatch({
                                                            type: ActionType.SET_ACTIVETAB,
                                                            data: 'Committed',
                                                        });
                                                        resetFilter();
                                                        resetSearch();
                                                    }
                                                }}
                                            >
                                                <PivotItem headerText="UnCommitted" itemKey="UnCommitted" />
                                                <PivotItem
                                                    className="pivot-item"
                                                    headerText="Committed"
                                                    itemKey="Committed"
                                                />
                                            </Pivot>
                                        </Stack.Item>
                                        <Stack horizontal>
                                            <Stack.Item align="auto" styles={stackItemStyles}>
                                                <ExportToExcel
                                                    rowsData={exportQueueItems}
                                                    colsData={excelColumns}
                                                    fileName="EstimatesQueue.xlsx"
                                                />
                                            </Stack.Item>
                                            <Stack.Item align="auto" styles={stackItemStyles}>
                                                <PrimaryButton
                                                    styles={buttonStyle}
                                                    text="Export to Finance"
                                                    href="/ExportToWebJEReport"
                                                />
                                                <DefaultButton text="Filter Panel" onClick={openPanel} />
                                            </Stack.Item>
                                        </Stack>
                                    </Stack>
                                    <Stack.Item align="auto" styles={stackItemStyles}></Stack.Item>
                                    <>
                                        <Stack.Item align="auto" styles={stackItemStyles}>
                                            <MarqueeSelection selection={selection}>
                                                <ShimmeredDetailsList
                                                    selectionMode={selectionMode()}
                                                    items={currentPageData || []}
                                                    columns={columns}
                                                    onRenderItemColumn={_renderItemColumn}
                                                    onColumnHeaderClick={_onColumnClick}
                                                    onRenderDetailsHeader={_onRenderDetailsHeader}
                                                    onRenderRow={_onRenderRow}
                                                    setKey="multiple"
                                                    layoutMode={DetailsListLayoutMode.justified}
                                                    enterModalSelectionOnTouch={true}
                                                    selectionPreservedOnEmptyClick={true}
                                                    isHeaderVisible={true}
                                                    ariaLabelForSelectionColumn="Toggle selection"
                                                    ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                                                    checkButtonAriaLabel="select row"
                                                    selection={selection}
                                                    ariaLabelForGrid="Estimates Queue"
                                                />
                                            </MarqueeSelection>
                                        </Stack.Item>
                                        {currentPageData.length === 0 && (
                                            <Stack horizontalAlign="center">
                                                <Text>Sorry no files found </Text>
                                            </Stack>
                                        )}
                                        <Stack
                                            className="pagination-controls"
                                            horizontal
                                            horizontalAlign="end"
                                            styles={{ root: { marginTop: '10px' } }}
                                        >
                                            <Stack
                                                horizontal
                                                horizontalAlign="space-between"
                                                styles={{ root: { width: '50%' } }}
                                            >
                                                <div style={{ marginLeft: -132 }}>
                                                    <CoherencePagination
                                                        {...paginationProps}
                                                        inputFieldAriaLabel="Enter Page Number"
                                                    />
                                                </div>
                                                <CoherencePageSize {...paginationPageSizeProps} />
                                            </Stack>
                                        </Stack>
                                        {estimatesQueueStatus !== 'Committed' && (
                                            <Stack horizontalAlign="end" horizontal tokens={{ childrenGap: 10 }}>
                                                <Stack.Item>
                                                    <PrimaryButton
                                                        styles={{ root: { marginTop: 10, marginRight: 10 } }}
                                                        text={'Bulk Commit'}
                                                        onClick={() => toggleBulkCommitModal(true)}
                                                        disabled={bulkCommitButton}
                                                    ></PrimaryButton>
                                                    <PrimaryButton
                                                        styles={{ root: { marginTop: 10 } }}
                                                        text="Clear"
                                                        onClick={clearSelectedRecords}
                                                    />
                                                </Stack.Item>
                                            </Stack>
                                        )}
                                        {isBulkCommitModalOpen && (
                                            <BulkCommitWarningModal
                                                isModalOpen={isBulkCommitModalOpen}
                                                selectedBulkCommitId={selectedBulkCommitId}
                                                selectedFileIds={selectedFileIds}
                                                toggleBulkCommitModal={closeBulkCommitModalAndReload}
                                            />
                                        )}
                                    </>
                                </Stack.Item>
                                <Panel
                                    headerText="Filter panel"
                                    // this prop makes the panel non-modal
                                    isBlocking={false}
                                    isOpen={isOpen}
                                    onDismiss={dismissPanel}
                                    closeButtonAriaLabel="Close"
                                    id="estimatesqueue"
                                >
                                    <Stack.Item tokens={spacingStackTokens} styles={stackItemStyles}>
                                        <Stack tokens={{ childrenGap: 5 }}>
                                            <Stack.Item>
                                                <Text variant="xLarge" role="heading" aria-level={2}>
                                                    Fetch Data
                                                </Text>
                                            </Stack.Item>
                                            <Stack.Item>
                                                <Text variant="medium">Start Date Range</Text>
                                            </Stack.Item>
                                            <Stack.Item>
                                                <DatePicker
                                                    value={fromDate}
                                                    ariaLabel="From Date"
                                                    formatDate={formatDate}
                                                    onSelectDate={(newDate) => {
                                                        if (newDate && newDate > new Date()) {
                                                            setfromDate(new Date());
                                                        } else {
                                                            setfromDate(newDate || new Date());
                                                            setisofromDate(
                                                                newDate?.toISOString() || new Date().toISOString()
                                                            );
                                                        }
                                                    }}
                                                ></DatePicker>
                                            </Stack.Item>
                                            <Stack.Item>
                                                <DatePicker
                                                    value={toDate}
                                                    ariaLabel="To Date"
                                                    formatDate={formatDate}
                                                    onSelectDate={(newDate) => {
                                                        settoDate(newDate || new Date());
                                                        setisotoDate(
                                                            newDate?.toISOString() || new Date().toISOString()
                                                        );
                                                    }}
                                                ></DatePicker>
                                            </Stack.Item>
                                            <Stack.Item>
                                                <PrimaryButton
                                                    styles={{ root: { marginTop: 10 } }}
                                                    text={'FETCH DATA'}
                                                    onClick={getestimatesqueue}
                                                ></PrimaryButton>
                                            </Stack.Item>
                                            <Stack tokens={{ childrenGap: 5 }}>
                                                <Stack.Item>
                                                    <Text variant="xLarge">Search this Queue</Text>
                                                    <Dropdown
                                                        onChange={onDropdownChange}
                                                        placeholder="Select One"
                                                        selectedKey={dropDownValue}
                                                        options={dropdownOptions}
                                                        ariaLabel="DropDown Options"
                                                    ></Dropdown>
                                                </Stack.Item>
                                                <Stack.Item>
                                                    <SearchBox
                                                        // styles={searchBoxStyles}
                                                        placeholder="Search"
                                                        onEscape={(ev) => {}}
                                                        onClear={(ev) => {}}
                                                        onChange={(_, newValue) => setsearchKeyword(newValue || '')}
                                                        onSearch={searchResultsBridge}
                                                        value={searchKeyword}
                                                    />
                                                </Stack.Item>
                                                <Stack horizontal tokens={{ childrenGap: 5 }}>
                                                    <Stack.Item>
                                                        <PrimaryButton
                                                            styles={{ root: { marginTop: 10 } }}
                                                            text={'Search'}
                                                            onClick={searchResultsBridge}
                                                        ></PrimaryButton>
                                                    </Stack.Item>
                                                    <Stack.Item>
                                                        <PrimaryButton
                                                            styles={{ root: { marginTop: 10 } }}
                                                            text={'Reset Search'}
                                                            onClick={resetSearch}
                                                        />
                                                    </Stack.Item>
                                                </Stack>
                                            </Stack>
                                            <Stack>
                                                <Text variant="xLarge" role="heading" aria-level={2}>
                                                    Filter This Queue
                                                </Text>
                                            </Stack>
                                            {estimatesQueueStatus === 'UnCommitted' ? (
                                                <Stack tokens={{ childrenGap: 5 }}>
                                                    <Stack.Item>
                                                        <Text variant="mediumPlus">
                                                            <strong>By Defects</strong>
                                                        </Text>
                                                    </Stack.Item>
                                                    <Stack.Item style={checkboxContainer}>
                                                        <Checkbox
                                                            className={styles.checkbox}
                                                            label="Employee Number"
                                                            inputProps={{ value: 'EmpIDDefect' }}
                                                            onChange={handleDefectsFilter}
                                                            checked={isEmpIDDefectChecked}
                                                        />
                                                        <Checkbox
                                                            className={styles.checkbox}
                                                            label="Cost Center"
                                                            inputProps={{ value: 'CostCenterDefect' }}
                                                            onChange={handleDefectsFilter}
                                                            checked={isCostCenterDefectChecked}
                                                        />
                                                    </Stack.Item>

                                                    <Stack.Item>
                                                        <Text variant="mediumPlus">
                                                            <strong>Filter By All Defects</strong>
                                                        </Text>
                                                        <Stack.Item style={checkboxContainer}>
                                                            <Checkbox
                                                                className={styles.checkbox}
                                                                label="All Defects"
                                                                inputProps={{ value: 'AllDefects' }}
                                                                onChange={handleAllDefectsFilter}
                                                                checked={isAllDefectsChecked}
                                                            />
                                                            <Checkbox
                                                                className={styles.checkbox}
                                                                label="No Defects"
                                                                inputProps={{ value: 'NoDefects' }}
                                                                onChange={handleAllDefectsFilter}
                                                                checked={isNoDefectsChecked}
                                                            />
                                                        </Stack.Item>
                                                        <Stack.Item>
                                                            <Text
                                                                variant="mediumPlus"
                                                                styles={{ root: { marginTop: 5, marginBottom: 5 } }}
                                                            >
                                                                <strong> Filter By Relocation Type</strong>
                                                            </Text>
                                                            <Stack.Item className={classNames.wrapper}>
                                                                <ScrollablePane
                                                                    scrollContainerFocus={true}
                                                                    scrollContainerAriaLabel="Relocation Type Filter Pane"
                                                                    styles={scrollablePaneStyles}
                                                                >
                                                                    {reloTypeContainer}
                                                                </ScrollablePane>
                                                            </Stack.Item>
                                                        </Stack.Item>
                                                    </Stack.Item>
                                                    <Text variant="mediumPlus">
                                                        {' '}
                                                        <strong>Current Status? </strong>
                                                    </Text>
                                                    <Stack.Item style={checkboxContainer}>
                                                        <Checkbox
                                                            className={styles.checkbox}
                                                            label="UnCommitted"
                                                            inputProps={{ value: 'IsNoAction' }}
                                                            onChange={handleCurrentStatusFilter}
                                                            checked={isNoActionChecked}
                                                        />
                                                        <Checkbox
                                                            className={styles.checkbox}
                                                            label="Previously Committed"
                                                            inputProps={{ value: 'PreviouslyCommitted' }}
                                                            onChange={handleCurrentStatusFilter}
                                                            checked={isPreviouslyCommittedChecked}
                                                        />
                                                    </Stack.Item>

                                                    <Stack.Item>
                                                        <Text variant="mediumPlus">
                                                            {' '}
                                                            <strong>Start Date Range </strong>
                                                        </Text>
                                                    </Stack.Item>
                                                </Stack>
                                            ) : (
                                                <Stack>
                                                    <Text variant="mediumPlus">
                                                        {' '}
                                                        <strong>Date Committed Range </strong>
                                                    </Text>
                                                </Stack>
                                            )}

                                            <Stack.Item>
                                                <DatePicker
                                                    value={filterFromDate}
                                                    ariaLabel="From Date"
                                                    formatDate={formatDate}
                                                    placeholder="From Date(mm/dd/yyyy)"
                                                    onSelectDate={(newDate) => {
                                                        if (newDate && newDate > new Date()) {
                                                            setFilterFromDate(new Date());
                                                        } else {
                                                            setFilterFromDate(newDate || new Date());
                                                            setisofromDate(
                                                                newDate?.toISOString() || new Date().toISOString()
                                                            );
                                                        }
                                                    }}
                                                ></DatePicker>
                                            </Stack.Item>
                                            <Stack.Item>
                                                <DatePicker
                                                    value={filterToDate}
                                                    ariaLabel="To Date"
                                                    formatDate={formatDate}
                                                    placeholder="To Date(mm/dd/yyyy)"
                                                    onSelectDate={(newDate) => {
                                                        setFilterToDate(newDate || new Date());
                                                        setisotoDate(
                                                            newDate?.toISOString() || new Date().toISOString()
                                                        );
                                                    }}
                                                ></DatePicker>
                                            </Stack.Item>
                                            <Stack.Item>
                                                <PrimaryButton
                                                    styles={{ root: { marginRight: 10 } }}
                                                    text="Filter"
                                                    onClick={filterQueueBrifdge}
                                                />
                                                <PrimaryButton text="Clear Filters" onClick={resetFilter} />
                                            </Stack.Item>
                                        </Stack>
                                    </Stack.Item>
                                </Panel>
                            </Stack>
                            {StackDisclaimer()}
                        </Stack>
                    ) : (
                        <UnAuthorized />
                    )}
                </>
            )}
        </>
    );

    function _getKey(item: any, index?: number): string {
        return item.FileId;
    }

    function _getSelectionDetails(): void {
        const _selectedReloIds = selection.getSelection();
        let selectedIds = [''];
        let selectedFileIds = [''];
        _selectedReloIds.forEach((item) => {
            if (!selectedIds.includes(item.ReloID.toString())) {
                selectedIds.push(item.ReloID.toString());
                selectedFileIds.push(item.FileId.toString());
            }
        });
        if (selectedIds.length > 1) {
            selectedIds.splice(0, 1);
            selectedFileIds.splice(0, 1);
        }
        selectedIds.join(',');
        selectedFileIds.join(',');
        setselectedBulkCommitId(selectedIds.toString());
        setselectedFileIds(selectedFileIds.toString());
        if (selectedIds.toString().length > 1) {
            setbulkCommitButton(false);
        } else setbulkCommitButton(true);
    }

    function _onRenderDetailsHeader(headerProps: any, defaultRender: any) {
        return defaultRender({
            ...headerProps,
            styles: {
                root: {
                    selectors: {
                        '.ms-DetailsHeader-cell': {
                            whiteSpace: 'normal',
                            lineHeight: 'normal',
                            wordWrap: 'break-word',
                            height: '100%',
                        },
                        '.ms-DetailsHeader-cellTitle': {
                            height: '100%',
                            alignItems: 'center',
                            wordWrap: 'break-word',
                        },
                    },
                    height: '60px',
                },
            },
        });
    }

    function _onRenderRow(props: IDetailsRowBaseProps | undefined, defaultRender: any | undefined) {
        const customStyles: Partial<IDetailsRowStyles> = {};
        let divProps;
        if (props) {
            if (
                props.item?.CommittedStatus.toUpperCase() == 'NO ACTION' ||
                props.item?.CommittedStatus.toUpperCase() == 'UNCOMMITTED' ||
                props.item?.CommittedStatus.toUpperCase() == 'COMMITTED'
            ) {
                customStyles.root = {
                    color: 'blue',
                    fontFamily: 'Segoe UI',
                    fontSize: '12px',
                    selectors: {
                        '.ms-DetailsRow-cell': {
                            whiteSpace: 'normal',
                            lineHeight: 'normal',
                            alignItems: 'center',
                        },
                        '.ms-DetailsRow-cellTitle': {
                            height: '100%',
                            alignItems: 'center',
                            wordWrap: 'break-word',
                        },
                    },
                };
                divProps = {
                    onClick: () => {},
                    onKeyDown: (e: any) => {
                        if (e.key === 'Enter') {
                            let myPattern = new RegExp('(\\w*' + 'Lump Sum' + '\\w*)', 'gi');
                            let matches = props.item?.RelocationType.match(myPattern);
                            let obj = JSON.stringify({
                                fileId: props.item.FileId,
                                reloId: props.item.ReloID,
                                totalEstimate: props.item.TotalEstimate,
                                isLumpSum: matches ? true : false,
                                committedStatus: props.item.CommittedStatus,
                                redirectedFrom: 'estimatesQueue',
                            });
                            history.push('/EstimatesForm', obj);
                        }
                    },
                };
            } else {
                customStyles.root = {
                    fontFamily: 'Segoe UI',
                    fontSize: '12px',
                    selectors: {
                        '.ms-DetailsRow-cell': {
                            whiteSpace: 'normal',
                            lineHeight: 'normal',
                            alignItems: 'center',
                        },
                        '.ms-DetailsRow-cellTitle': {
                            height: '100%',
                            alignItems: 'center',
                            wordWrap: 'break-word',
                        },
                    },
                };
            }
            divProps = {
                onClick: () => {},
                onKeyDown: (e: any) => {
                    if (e.key === 'Enter') {
                        let myPattern = new RegExp('(\\w*' + 'Lump Sum' + '\\w*)', 'gi');
                        let matches = props.item?.RelocationType.match(myPattern);
                        let obj = JSON.stringify({
                            fileId: props.item.FileId,
                            reloId: props.item.ReloID,
                            totalEstimate: props.item.TotalEstimate,
                            isLumpSum: matches ? true : false,
                            committedStatus: props.item.CommittedStatus,
                            redirectedFrom: 'estimatesQueue',
                        });
                        history.push('/EstimatesForm', obj);
                    }
                },
            };

            if (estimatesQueueStatus === 'Committed') {
                return (
                    <DetailsRow
                        {...divProps}
                        {...props}
                        styles={customStyles}
                        selectionMode={SelectionMode.none}
                        rowFieldsAs={renderRowFields}
                    >
                        {defaultRender && defaultRender(props)}{' '}
                    </DetailsRow>
                );
            }

            //Need to update the if condition for Defects in the record
            else if (props.item?.FileId == 2478) {
                return (
                    <DetailsRow
                        {...divProps}
                        {...props}
                        styles={customStyles}
                        selectionMode={SelectionMode.none}
                        rowFieldsAs={renderRowFields}
                    >
                        {defaultRender && defaultRender(props)}{' '}
                    </DetailsRow>
                );
            } else {
                return (
                    <DetailsRow {...divProps} {...props} styles={customStyles} selectionMode={SelectionMode.multiple}>
                        {defaultRender && defaultRender(props)}{' '}
                    </DetailsRow>
                );
            }
        }
        return null;
    }

    function renderRowFields(props: IDetailsRowFieldsProps) {
        return (
            <span data-selection-disabled={true}>
                <DetailsRowFields {...props} />
            </span>
        );
    }

    function _renderItemColumn(
        item: EstimatesQueue | undefined,
        index: number | undefined,
        column: IColumn | undefined
    ) {
        const fieldContent = item?.[column?.fieldName as keyof EstimatesQueue] as string;

        switch (column?.key) {
            case 'CommitStamp':
                return <span>{moment(item?.CommitStamp).format('MM/DD/YYYY')}</span>;

            case 'StartDate':
                return moment(item?.StartDate).format('MM/DD/YYYY');

            default:
                return <span>{fieldContent}</span>;
        }
    }

    function _onColumnClick(event: React.MouseEvent<HTMLElement> | undefined, column: IColumn | undefined): void {
        let isSortedDescending = column?.isSortedDescending;
        if (column?.isSorted) {
            isSortedDescending = !isSortedDescending;
        }
        let sortedItems: IEstimatesQueueForm[] = _copyAndSort(estimatesQueue, column?.fieldName!, isSortedDescending);

        let sortedCol = columns.map((col) => {
            col.isSorted = col.key === column?.key;

            if (col.isSorted) {
                col.isSortedDescending = isSortedDescending;
            }
            return col;
        });
        setestimatesqueue(sortedItems);
        setColumns(sortedCol);
    }

    function searchResultsBridge() {
        dispatch({
            type: ActionType.SET_FILTERS,
            data: { ...getStoredFilters, searchDropDown: dropDownValue, searchKeyword: searchKeyword },
        });
        searchResults(false);
    }

    async function searchResults(flag: boolean) {
        let filteredData = masterEstimatesQueue;

        switch (dropDownValue) {
            case 'fileID':
                filteredData = masterEstimatesQueue.filter((row) => row.FileId.toString() === searchKeyword?.trim());
                break;

            case 'name':
                filteredData = masterEstimatesQueue.filter((row) => {
                    return (
                        row.CustomerName.toLowerCase()
                            .replace(/ /g, '')
                            .indexOf(searchKeyword.toLowerCase().replace(/ /g, '')) > -1
                    );
                });
                break;

            default:
                filteredData = masterEstimatesQueue;
                break;
        }
        setestimatesqueue(filteredData);
    }

    async function filterQueueBrifdge() {
        let filterArray = {};
        if (estimatesQueueStatus == 'UnCommitted') {
            filterArray = {
                selectedByDefectFilters,
                selectedAllDefectFilters,
                selectedreloTypeFitlers,
                selectedstatusFilters,
                filterFromDate,
                filterToDate,
            };
            dispatch({
                type: ActionType.SET_FILTERS,
                data: { ...getStoredFilters, ...filterArray },
            });
        } else if (estimatesQueueStatus == 'Committed') {
            filterArray = {
                filterFromDate,
                filterToDate,
            };
            dispatch({
                type: ActionType.SET_FILTERS,
                data: {
                    filterFromDate: getStoredFilters?.filterFromDate,
                    filterToDate: getStoredFilters?.filterToDate,
                    ...filterArray,
                },
            });
        }

        await filterQueue();
    }

    async function filterQueue() {
        let filteredData = estimatesQueue;

        if (selectedByDefectFilters.length > 0) {
            selectedByDefectFilters.map((selValue, index) => {
                switch (selValue) {
                    case 'EmpIDDefect':
                        filteredData = estimatesQueue.filter((item) => item.EmpIDDefect === true);
                        break;
                    case 'CostCenterDefect':
                        filteredData = estimatesQueue.filter((item) => item.CostCenterDefect === true);
                        break;
                }
            });
        }

        if (selectedAllDefectFilters.length > 0) {
            selectedAllDefectFilters.map((selValue) => {
                if (selValue === 'AllDefects') {
                    filteredData = filteredData.filter((item) => {
                        if (
                            !item.DateDiff ||
                            item.EmpIDDefect == true ||
                            item.CostCenterDefect == true ||
                            item.FirstNameDefect == true ||
                            item.LastNameDefect == true ||
                            item.DateDefect == true ||
                            item.PolicyDefect == true
                        ) {
                            return item;
                        }
                    });
                } else {
                    filteredData = filteredData.filter((item) => {
                        if (
                            !item.DateDiff ||
                            (item.EmpIDDefect == false &&
                                item.CostCenterDefect == false &&
                                item.FirstNameDefect == false &&
                                item.LastNameDefect == false &&
                                item.DateDefect == false &&
                                item.PolicyDefect == false)
                        ) {
                            return item;
                        }
                    });
                }
            });
        }

        if (selectedreloTypeFitlers.length > 0) {
            selectedreloTypeFitlers.map((selValue, index) => {
                if (index == 0) {
                    filteredData = filteredData.filter((item) => item.RelocationType === selValue);
                } else {
                    filteredData = filteredData.concat(
                        estimatesQueue.filter((item) => item.RelocationType === selValue)
                    );
                }
            });
        }

        if (visaPendingValue != '3') {
            filteredData =
                visaPendingValue === '1'
                    ? filteredData.filter((row) => row.VisaPending.toUpperCase() === 'TRUE')
                    : filteredData.filter((row) => row.VisaPending.toUpperCase() == 'FALSE');
        }

        if (everSendBackValue.length > 0) {
            filteredData =
                everSendBackValue == 'SendBack'
                    ? filteredData.filter((row) => row.EverSendBack === true)
                    : filteredData.filter((row) => row.EverSendBack === true || row.EverSendBack === false);
        }

        if (selectedstatusFilters.length > 0) {
            selectedstatusFilters.map((selValue, index) => {
                switch (selValue) {
                    case 'PreviouslyCommitted':
                        filteredData = filteredData.filter(
                            (item) => item.CommittedStatus.toUpperCase() == 'UNCOMMITTED'
                        );
                        break;

                    case 'IsNoAction':
                        filteredData = filteredData.filter((item) => item.CommittedStatus.toUpperCase() == 'NO ACTION');

                    default:
                        break;
                }
            });
        }

        if (filterFromDate && filterToDate) {
            if (estimatesQueueStatus === 'Committed') {
                filteredData = filteredData.filter(
                    (item) =>
                        new Date(item?.CommitStamp).getTime() >= new Date(filterFromDate).getTime() &&
                        new Date(item?.CommitStamp).getTime() <= new Date(filterToDate).getTime()
                );
            } else {
                filteredData = filteredData.filter(
                    (item) =>
                        new Date(item?.StartDate).getTime() >= new Date(filterFromDate).getTime() &&
                        new Date(item?.StartDate).getTime() <= new Date(filterToDate).getTime()
                );
            }
        }

        setestimatesqueue(filteredData);
    }

    function handleReloTypeFilter(event?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void {
        const checkedReloType = (event!.currentTarget as HTMLInputElement).value;
        if (checked) {
            selectedreloTypeFitlers.push(checkedReloType);
            const objIndex = relotypeCheckBoxOption.findIndex((obj) => obj.optionName == checkedReloType);

            let reloTypeObj = relotypeCheckBoxOption.filter((item) => item.optionName !== checkedReloType);

            const obj = {
                optionName: checkedReloType,
                checked: true,
            };

            reloTypeObj.splice(objIndex, 0, obj);
            setrelotypeCheckBoxOption(reloTypeObj);
            setselectedreloTypeFitlers(selectedreloTypeFitlers);
        } else {
            let index = selectedreloTypeFitlers.indexOf(checkedReloType, 0);
            const objIndex = relotypeCheckBoxOption.findIndex((obj) => obj.optionName == checkedReloType);

            let reloTypeObj = relotypeCheckBoxOption.filter((item) => item.optionName !== checkedReloType);

            const obj = {
                optionName: checkedReloType,
                checked: false,
            };

            reloTypeObj.splice(objIndex, 0, obj);
            setrelotypeCheckBoxOption(reloTypeObj);
            if (index > -1) {
                selectedreloTypeFitlers.splice(index, 1);
            }
        }
    }

    function handleAllDefectsFilter(event?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void {
        let curStatusValues = selectedAllDefectFilters;
        const checkedAllDefect = (event!.currentTarget as HTMLInputElement).value;
        if (checked) {
            switch (checkedAllDefect) {
                case 'AllDefects':
                    setisAllDefectsChecked(true);
                    break;
                case 'NoDefects':
                    setisNoDefectsChecked(true);
                    break;
            }
            curStatusValues.push(checkedAllDefect);
        } else {
            const index = curStatusValues.indexOf(checkedAllDefect);
            curStatusValues.splice(index, 1);
            switch (checkedAllDefect) {
                case 'AllDefects':
                    setisAllDefectsChecked(false);
                    break;
                case 'NoDefects':
                    setisNoDefectsChecked(false);
                    break;
            }
        }
        setselectedAllDefectFilters(curStatusValues);
    }

    function handleDefectsFilter(event?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void {
        let curStatusValues = selectedByDefectFilters;
        const checkedAllDefect = (event!.currentTarget as HTMLInputElement).value;
        if (checked) {
            switch (checkedAllDefect) {
                case 'EmpIDDefect':
                    setisEmpIDDefectChecked(true);
                    break;
                case 'CostCenterDefect':
                    setisCostCenterDefectChecked(true);
                    break;
            }
            curStatusValues.push(checkedAllDefect);
        } else {
            const index = curStatusValues.indexOf(checkedAllDefect);
            curStatusValues.splice(index, 1);
            switch (checkedAllDefect) {
                case 'EmpIDDefect':
                    setisEmpIDDefectChecked(false);
                    break;
                case 'CostCenterDefect':
                    setisCostCenterDefectChecked(false);
                    break;
            }
        }
        setselectedByDefectFilters(curStatusValues);
    }

    function handleCurrentStatusFilter(
        event?: React.FormEvent<HTMLElement | HTMLInputElement>,
        checked?: boolean
    ): void {
        let curStatusValues = selectedstatusFilters;
        const checkedStatus = (event!.currentTarget as HTMLInputElement).value;
        if (checked) {
            switch (checkedStatus) {
                case 'IsNoAction':
                    setisNoActionChecked(true);
                    break;
                case 'PreviouslyCommitted':
                    setisPreviouslyCommittedChecked(true);
                    break;
            }
            curStatusValues.push(checkedStatus);
        } else {
            const index = curStatusValues.indexOf(checkedStatus);
            curStatusValues.splice(index, 1);
            switch (checkedStatus) {
                case 'IsNoAction':
                    setisNoActionChecked(false);
                    break;
                case 'PreviouslyCommitted':
                    setisPreviouslyCommittedChecked(false);
                    break;
            }
        }
        setselectedstatusFilters(curStatusValues);
    }

    function toggleBulkCommitModal(flag: boolean) {
        setIsBulkCommitModalOpen(flag);
    }

    function closeBulkCommitModalAndReload(action: string) {
        setIsBulkCommitModalOpen(false);
        if (action == 'YES') {
            getestimatesqueue();
        }
    }

    function selectionMode(): SelectionMode {
        if (estimatesQueueStatus == 'Committed') return SelectionMode.none;
        else return SelectionMode.multiple;
    }

    function resetSearch() {
        dispatch({
            type: ActionType.RESET_FILTERS_SEARCH,
        });
        setdropDownValue('Select One');
        setsearchKeyword('');
        setestimatesqueue(masterEstimatesQueue);
    }

    function resetFilter(): void {
        dispatch({
            type: ActionType.RESET_FILTERS_QUEUE,
        });
        setisofromDate(d.toISOString());
        setfromDate(new Date(isoFromDate));
        settoDate(new Date());
        setisotoDate(new Date().toISOString());
        setFilterFromDate(undefined);
        setFilterToDate(undefined);
        setselectedreloTypeFitlers([]);
        setselectedstatusFilters([]);
        setdropDownValue('Select One');
        setestimatesqueue(masterEstimatesQueue);
        setselectedByDefectFilters([]);
        setselectedAllDefectFilters([]);
        setrelotypeCheckBoxOption(defaultrelotypeCheckBoxOption);
        setvisaPendingValue('3');
        seteverSendBackValue('');
        setisEmpIDDefectChecked(false);
        setisCostCenterDefectChecked(false);
        setisAllDefectsChecked(false);
        setisNoDefectsChecked(false);
        setisNoActionChecked(false);
        setisPreviouslyCommittedChecked(false);
    }
}
