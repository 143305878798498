import * as React from 'react';
import { Redirect } from 'react-router';
import { ReduxContext } from '@employee-experience/common';
import { ILoggedInUserRoles } from '../../Models/IUserRoles';
import { IAppState } from '../../Common/Redux/AppState';

export const Home: React.FunctionComponent<{}> = () => {
    const { useSelector } = React.useContext(ReduxContext);
    const userRole: ILoggedInUserRoles = useSelector((state: IAppState) => state.root.userReducer.loggedInUserRoles);

    if (userRole.ReloAdmin) {
        return <Redirect to="/MyReloQueue" />;
    }
    if (userRole.Staffing) {
        return <Redirect to="/MyReloQueue" />;
    }
    if (userRole.GloMoCo) {
        return <Redirect to="/GlomocoeQueue" />;
    }
    if (userRole.ReloSpecialist) {
        return <Redirect to="/ReloSpecialistQueue" />;
    }
    if (userRole.ReloAnalyst) {
        return <Redirect to="/EstimatesQueue" />;
    }
    if (userRole.Guest) {
        return <Redirect to="/PendingExceptionsQueue" />;
    }
    if (userRole.NOROLE) {
        return <Redirect to="/NoRole" />;
    }
    if(userRole.GloMoCo && userRole.ReloSpecialist){
        return <Redirect to="/GlomocoeQueue" />;
    }
    return null;
};
