import { IHttpClient } from "@employee-experience/common";
import { IHttpClientResult } from "@employee-experience/common/lib/Models";

export class UploadActualsReportService {
    private httpClient: IHttpClient;
    private url: string;

    public constructor(httpClient: IHttpClient) {
        this.httpClient = httpClient;
        this.url = REACT_APP_RELO_API_URL;
    }


    public async UploadActualsReport(file: File, Alias: string): Promise<string> {

        const formData = new FormData();
        formData.append("file", file, file.name)

        const request: IHttpClientResult<string> = await this.httpClient.request({
            url: `${this.url}api/reloform/UploadActualsReport/${Alias}`,
            method:'POST',
            resource: __CLIENT_ID__,
            data:formData
        });
        return request?.data;



    }
}