import * as React from 'react';
import {
    Label,
    mergeStyleSets,
} from '@fluentui/react';
export const  RushCommentsRequirementText=()=>
{
    return (        
            <Label className={`${classNamesDisc.lblRushComments} disclaimerStyle`}>Since, the Estimated Start Date is less than the minimum, this is a RUSH relocation and you are required to provide a business justification in the comment section below.</Label>    
    );    
}

const classNamesDisc = mergeStyleSets({
    lblRushComments: { 
        'font-size': '15px'
    },
});