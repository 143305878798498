
import { IColumn } from '@fluentui/react';

export const ReloSpecialistColumns: IColumn[] = [
    {
        key: 'fileId',
        name: 'File ID',
        fieldName: 'fileId',
        minWidth: 0,
        maxWidth:30,
    },
    {
        key: 'CustomerName',
        name: 'Employee Name',
        fieldName: 'CustomerName',
        minWidth: 100,
        maxWidth:150,
    },
    {
        key: 'Approver',
        name: 'Approver',
        fieldName: 'Approver',
        minWidth: 0,
        maxWidth:30,
    },
    {
        key: 'RecruitingAssociateAlias',
        name: 'Recruiting Associate Alias',
        fieldName: 'RecruitingAssociateAlias',
        minWidth: 0,
        maxWidth:30,
    },
    {
        key: 'ReloSpecialistAlias',
        name: 'ReloSpecialist Alias',
        fieldName: 'ReloSpecialistAlias',
        minWidth: 0,
        maxWidth:30,
    },
    {
        key: 'StartDate',
        name: 'Request Creation Date',
        fieldName: 'StartDate',
        minWidth: 0,
        maxWidth:30,
    },
    {
        key: 'RelocationType',
        name: 'Relocation Type',
        fieldName: 'RelocationType',
        minWidth: 200,
        maxWidth:240,
    },
    {
        key: 'Exceptions',
        name: 'Category',
        fieldName: 'Exceptions',
        minWidth: 200,
        maxWidth:240,
    },
    {
        key: 'Status',
        name: 'Status',
        fieldName: 'Status',
        minWidth: 0,
        maxWidth:30,
    },
    {
        key: 'Reminders',
        name: 'Reminders',
        fieldName: 'Reminders',
        minWidth: 200,
        maxWidth:240,
    },
];
