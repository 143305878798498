import { IHttpClient, IHttpClientResult } from "@employee-experience/common";
import { IReassignValues } from "../../Models/IReAssignRecords";



export class ReassignRecordsService {

    private httpClient: IHttpClient;
    private url: string;

    public constructor(httpClient: IHttpClient) {
        this.httpClient = httpClient;
        this.url = REACT_APP_RELO_API_URL;
    }


    public async ReAssignBulkRecordToAnotherUser(ReloId: string, AliasReassigning: string): Promise<any> {

        var ReassignValues: IReassignValues = {} as IReassignValues
        ReassignValues.ReloIds = ReloId;
        ReassignValues.AliasReassigning = AliasReassigning;
        
        const request: IHttpClientResult<any> = await this.httpClient.request({
            url: `${this.url}api/reloform/ReAssignBulkRecordToAnotherUser`,
            method: 'POST',
            resource: __CLIENT_ID__,
            data: ReassignValues
        });
        return request?.data;

    }
}