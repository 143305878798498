import * as React from 'react';
import {
    PrimaryButton,
    Modal,
    mergeStyleSets,
    getTheme,
    FontWeights,
} from '@fluentui/react';
import { DeleteStatusPopUpProps } from '../../../Models/IEstimatesForm';

// --------- Styles ----------
const theme = getTheme();
const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
    },
    header: [
        // eslint-disable-next-line deprecation/deprecation
        theme.fonts.xLargePlus,
        {
            flex: '1 1 auto',
            borderTop: `4px solid orange`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 0 24px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});
const buttonStyles = mergeStyleSets({
    container: {
        marginTop: 20,
        display: 'flex',
        justifyContent: 'center',
    },
    button: {
        backgroundColor: 'rgb(0, 120, 212)',
        color: '#FFFFFF',
        marginRight: '10px'
    }
});

// --------- Component ----------
export const DeleteStatusPopUp: React.FC<DeleteStatusPopUpProps> = (props) => {
    const dismissPopUp = () => {
        props.toggleDeleteStatusPopUp();
    }

    return (
        <>
            <Modal
                isOpen={props.isModalOpen}
                containerClassName={contentStyles.container}
            >
                <>
                    <div className={contentStyles.header} />
                    <div className={contentStyles.body}>
                        <p>{props.message}</p>
                        <div className={buttonStyles.container}>
                            <PrimaryButton
                                text="Ok"
                                onClick={dismissPopUp}
                                allowDisabledFocus
                                disabled={false}
                                className={buttonStyles.button}
                            />
                        </div>
                    </div>
                </>
            </Modal>
        </>
    )
}