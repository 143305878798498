export interface IUserRoles {
    Alias: string;
    Name: string;
    FirstName: string;
    LastName: string;
    Roles: string[];
}

export interface ILoggedInUserRoles {
    GloMoCo: boolean;
    ReloAdmin: boolean;
    ReloAnalyst: boolean;
    ReloSpecialist: boolean;
    Staffing: boolean;
    Guest: boolean;
    NOROLE: boolean;
}

export enum UserRoles {
    GloMoCo = 'GloMoCo',
    ReloAdmin = 'ReloAdmin',
    ReloAnalyst = 'ReloAnalyst',
    ReloSpecialist = 'ReloSpecialist',
    Staffing = 'Staffing',
    Guest = 'Guest',
    NOROLE = 'NOROLE',
}
