import { IHttpClient, IHttpClientResult } from '@employee-experience/common';
import {
    IE2ALiabilityTrend
} from '../../Models/IE2ALiabilityTrend';

export class E2ALiabilityTrendService {
    private httpClient: IHttpClient;
    private url: string;

    public constructor(httpClient: IHttpClient) {
        this.httpClient = httpClient;
        this.url = REACT_APP_RELO_API_URL;
    }

    public async getE2ALiabilityTrendReport(StartDate: Date,EndDate: Date): Promise<IE2ALiabilityTrend[]> {
        const response: IHttpClientResult<IE2ALiabilityTrend[]> = await this.httpClient.request({
            url: `${this.url}api/reloform/GetE2ALiabilityTrendReport`,
            resource: __CLIENT_ID__,
            params:{
                StartDate: StartDate,
                EndDate: EndDate
            }
        });
        return response?.data;
    }

    
    
}
