import { IHttpClient, IHttpClientResult } from '@employee-experience/common';
import {
    ISentToFileSetup
} from '../../Models/ISentToFileSetupQueue';

export class SentToFileSetupQueueService {
    private httpClient: IHttpClient;
    private url: string;

    public constructor(httpClient: IHttpClient) {
        this.httpClient = httpClient;
        this.url = REACT_APP_RELO_API_URL;
    }

    public async getSentToFileSetup(): Promise<ISentToFileSetup[]> {
        const response: IHttpClientResult<ISentToFileSetup[]> = await this.httpClient.request({
            url: `${this.url}api/reloform/GetSentToFileSetup`,
            resource: __CLIENT_ID__
        });
        return response?.data;
    }

    public async getSentToFileSetupSearch(Flag: number, searchKeyword: string): Promise<ISentToFileSetup[]> {
        const response: IHttpClientResult<ISentToFileSetup[]> = await this.httpClient.request({
            url: `${this.url}api/reloform/GetSentToFileSetupSearch/${Flag}/${searchKeyword}?flag=${Flag}&searchKeyword=${searchKeyword}`,
            resource: __CLIENT_ID__
        });
        return response?.data;
    }

    

    
    
}
