import * as React from 'react';
import styled from 'styled-components';

export const StyledHelpPopupHeader = styled.div`
  margin-left: 32px;
  margin-top: 24px;
  font-weight: 700;
  font-size: 26px;
  display: flex;
  align-items: center;
`;
export function HelpPopupHeader(props: { title: string }): React.ReactElement {
  return <StyledHelpPopupHeader>{props.title}</StyledHelpPopupHeader>;
}