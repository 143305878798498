import * as React from 'react';
import {
    Stack,
    IconButton,
    IStackItemStyles,
    IStackProps,
    IStackStyles,
    TextField,
    ITextFieldStyles,
    Toggle,
    IDropdownOption,
    Dropdown,
} from '@fluentui/react';
import { useServiceStore } from '../../RootStateContext';
import { HireInfo } from '../../Models/INewRelocationRequest';

interface HireInformationProps {
    hireInfo: HireInfo;
    isFormEditable: boolean;
    internshipTypeID: number;
    updateHireInfo: Function;
    onInternshipTypeChange: Function;
    status:boolean;
}

// --------- Styles ----------

const stackStyles: Partial<IStackStyles> = { root: { width: '100%', marginTop: '7px', marginBottom: '0px' , padding:'14px'} };
const accordionHeader: IStackItemStyles = {
    root: {
        padding: 5,
        cursor: 'pointer',
        fontSize: '20px',
    },
};
const accordionContent: IStackItemStyles = {
    root: {
        margin: '0px 0px 7px 11px',
        width:'99%',
    boxShadow:'rgb(0 0 0 / 16%) 0px 1px 4px' 
    },
};
// --------- Constants ----------
const hireInfoColumnProps: Partial<IStackProps> = {
    tokens: { childrenGap: 15 },
    styles: { root: { width: '20%' } },
};
export const HireInformation: React.FC<HireInformationProps> = (props) => {
    const { reviewFormService } = useServiceStore();
    const [isOpen, setIsOpen] = React.useState<boolean>(true);
    const [recordIDTextFieldValue, setRecordIDTextFieldValue] = React.useState(props.hireInfo.reloID.toString() || '');
    const [candidateIDTextFieldValue, setCandidateIDTextFieldValue] = React.useState(
        props.hireInfo.candidateID.toString() || ''
    );
    const [employeeIDTextFieldValue, setEmployeeIDTextFieldValue] = React.useState(
        props.hireInfo.employeeID.toString() || ''
    );
    const [hireTypeTextFieldValue, setHireTypeTextFieldValue] = React.useState(setHireTypename);
    const [intershipTypeOptions, setinternshipTypeOptions] = React.useState<IDropdownOption[]>([]);
    const [selectedInternshipTypeID, setselectedInternshipTypeID] = React.useState<number | null>(
        props?.internshipTypeID || null
    );    

    React.useEffect(() => {
        if (props.hireInfo.reloID.toString() !== recordIDTextFieldValue) {
            setRecordIDTextFieldValue(props.hireInfo.reloID.toString());
        }
    }, [props.hireInfo]);

    function setHireTypename(): string {
        let hireTypeName = '';
        props.hireInfo.hireTypesOptions.forEach((item) => {
            if (item.key == props.hireInfo.hireTypeID) {
                hireTypeName = item.text.toString() || '';
                return hireTypeName;
            }
        });
        if (hireTypeName == '') {
            return '';
        } else return hireTypeName;
    }
    const toggleHireInformation = () => {
        setIsOpen(!isOpen);
    };
    const textFieldStyles: Partial<ITextFieldStyles> = { fieldGroup: { width: 200,color: 'rgb(50, 49, 48) !important' } };
    const onChangeRecordIDFieldValue = React.useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            setRecordIDTextFieldValue(newValue || '');
        },
        []
    );
    const onChangeCandidateIDFieldValue = React.useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            setCandidateIDTextFieldValue(newValue || '');
            props.updateHireInfo('candidateID', newValue);
        },
        [props]
    );
    const onChangeEmployeeIDFieldValue = React.useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            setEmployeeIDTextFieldValue(newValue || '');
            props.updateHireInfo('employeeID', newValue);
        },
        [props]
    );
    const onChangeHireTypeFieldValue = React.useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            setHireTypeTextFieldValue(newValue || '');
        },
        []
    );

    React.useEffect(() => {
        (async function getInternshipTypes() {
            let internOptions: IDropdownOption[] = [];
            if (props.hireInfo.hireTypeID == 4) {
                props.onInternshipTypeChange(props.internshipTypeID);
            }
            let result = await reviewFormService.GetInternshipTypes();
            result = result.filter((item) => {
                return item.InternshipTypeID != 9 && item.InternshipTypeID != 10;
            });
            result.forEach((item) => {
                internOptions.push({ key: item.InternshipTypeID, text: item.InternshipTypeName });
            });
            setinternshipTypeOptions(internOptions);
        })();
    }, []);

    return (
        <Stack className="HireInformation" style={{boxShadow:'rgb(0 0 0 / 16%) 0px 1px 4px',padding:'0px 2px 0px 2px'}}>
            <Stack horizontal={true} style={{backgroundColor:'rgb(235, 235, 235)'}}>
                <Stack.Item style={{ marginTop: '3px' }}>
                    <IconButton
                        aria-labelledby="Hire Information"
                        title="Hire Information"
                        role='button'
                        iconProps={{
                            iconName: isOpen ? 'ChevronUp' : 'ChevronDown',
                        }}
                        onClick={toggleHireInformation}
                    />
                </Stack.Item>
                <Stack.Item align="center" styles={accordionHeader}>
                <h2 style={{fontSize:'20px'}}> Hire Information </h2>
                </Stack.Item>
            </Stack>

            {isOpen && (
                <Stack styles={accordionContent}>
                    <Stack horizontal tokens={{ childrenGap: 20 }} styles={stackStyles}>
                        {((props.hireInfo.hireTypeID !== 5 &&
                            props.hireInfo.hireTypeID !== 6 &&
                            props.hireInfo.hireTypeID !== 7 &&
                            props.hireInfo.reloID != 0) || props?.status )&& (
                                <Stack {...hireInfoColumnProps}>
                                    <TextField
                                        label="Record ID"
                                        value={recordIDTextFieldValue}
                                        onChange={onChangeRecordIDFieldValue}
                                        readOnly={props.isFormEditable}
                                        disabled={props.isFormEditable}
                                        styles={textFieldStyles}
                                        maxLength={25}
                                    />
                                </Stack>
                            )}
                        <Stack {...hireInfoColumnProps}>
                            <TextField
                                label="Candidate ID"
                                role='textbox'
                                value={candidateIDTextFieldValue}
                                onChange={onChangeCandidateIDFieldValue}
                                styles={textFieldStyles}
                                readOnly={props.isFormEditable}
                                disabled={props.isFormEditable}
                                maxLength={25}
                            />
                        </Stack>
                        <Stack {...hireInfoColumnProps}>
                            <TextField
                                label="Employee ID"
                                role='textbox'
                                value={employeeIDTextFieldValue}
                                onChange={onChangeEmployeeIDFieldValue}
                                styles={textFieldStyles}
                                readOnly={props.isFormEditable}
                                disabled={props.isFormEditable}
                                maxLength={25}
                            />
                        </Stack>
                        <Stack {...hireInfoColumnProps}>
                            <TextField
                                label="Hire Type"
                                role='textbox'
                                value={hireTypeTextFieldValue}
                                onChange={onChangeHireTypeFieldValue}
                                styles={textFieldStyles}
                                readOnly={props.isFormEditable}
                                disabled={props.isFormEditable}
                            />
                        </Stack>
                        {props.hireInfo.showInternship && (
                            <Stack {...hireInfoColumnProps}>
                                <Dropdown
                                    options={intershipTypeOptions}
                                    label=" Internship Type"
                                    selectedKey={selectedInternshipTypeID}
                                    onChange={onIntershipChange}
                                    required
                                ></Dropdown>
                            </Stack>
                        )}
                    </Stack>

                    {props.hireInfo.hireTypeID == 3 && (
                        <Stack horizontal tokens={{ childrenGap: 20 }} styles={stackStyles}>
                            <Stack horizontal {...hireInfoColumnProps}>
                                <Toggle
                                    label="MACH"
                                    inlineLabel={true}
                                    defaultChecked={props.hireInfo.isMACH}
                                    disabled={false}
                                    onText="Yes"
                                    offText="No"
                                    onChange={_onMACHChange}
                                />
                            </Stack>
                            <Stack horizontal {...hireInfoColumnProps}>
                                <Toggle
                                    label="MBA"
                                    inlineLabel={true}
                                    disabled={false}
                                    defaultChecked={props.hireInfo.isMBA}
                                    onText="Yes"
                                    offText="No"
                                    onChange={_onMBAChange}
                                />
                            </Stack>
                        </Stack>
                    )}
                </Stack>
            )}
        </Stack>
    );

    function onIntershipChange(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void {
        setselectedInternshipTypeID((option!.key as number) || null);
        props.onInternshipTypeChange(option!.key as number);
    }

    function _onMBAChange(ev: React.MouseEvent<HTMLElement>, checked?: boolean) {
        if (checked) {
            props.updateHireInfo('isMBA', true);
        } else {
            props.updateHireInfo('isMBA', false);
        }
    }

    function _onMACHChange(ev: React.MouseEvent<HTMLElement>, checked?: boolean) {
        if (checked) {
            props.updateHireInfo('isMACH', true);
        } else {
            props.updateHireInfo('isMACH', false);
        }
    }
};
