import * as React from 'react';
import {
    Stack,
    IconButton,
    IStackItemStyles,
    IStackProps,
    IStackStyles,
    TextField,
    ITextFieldStyles,
    Toggle,
} from '@fluentui/react';
import { HireInfo } from '../../Models/INewRelocationRequest';

interface HireInformationProps {
    hireInfo: HireInfo;
    internshipTypeID: number;
    intersnhipTypeName: string | null;
}

// --------- Styles ----------


const stackStyles: Partial<IStackStyles> = { root: { width: '100%', marginTop: '7px', marginBottom: '0px', padding: '14px' } };
const accordionHeader: IStackItemStyles = {
    root: {
        padding: 5,
        cursor: 'pointer',
        fontSize: '20px',
    },
};
const accordionContent: IStackItemStyles = {
    root: {
        margin: '0px 0px 7px 11px',
        width:'99%',
    boxShadow:'rgb(0 0 0 / 16%) 0px 1px 4px' 
    },
};
// --------- Constants ----------
const hireInfoColumnProps: Partial<IStackProps> = {
    tokens: { childrenGap: 15 },
    styles: { root: { width: '20%' } },
};
// --------------------------------------------------
export const HireInformation: React.FC<HireInformationProps> = (props) => {
    const [isOpen, setIsOpen] = React.useState<boolean>(true);
    const [recordIDTextFieldValue, setRecordIDTextFieldValue] = React.useState(props.hireInfo.reloID.toString() || '');
    const [candidateIDTextFieldValue] = React.useState(props.hireInfo.candidateID.toString() || '');
    const [employeeIDTextFieldValue] = React.useState(props.hireInfo.employeeID.toString() || '');
    const [hireTypeTextFieldValue] = React.useState(setHireTypename);
    const [selectedInternshipTypeID] = React.useState<number | null>(props?.internshipTypeID || null);
    const [internshipType] = React.useState<string | null>(props.intersnhipTypeName);
    

    React.useEffect(() => {
        if (props.hireInfo.reloID.toString() !== recordIDTextFieldValue) {
            setRecordIDTextFieldValue(props.hireInfo.reloID.toString());
        }
    }, [props.hireInfo]);

    function setHireTypename(): string {
        let hireTypeName = '';
        props.hireInfo.hireTypesOptions.forEach((item) => {
            if (item.key == props.hireInfo.hireTypeID) {
                hireTypeName = item.text.toString() || '';
                return hireTypeName;
            }
        });
        if (hireTypeName == '') {
            return '';
        } else return hireTypeName;
    }

    const toggleHireInformation = () => {
        setIsOpen(!isOpen);
    };
    const textFieldStyles: Partial<ITextFieldStyles> = { fieldGroup: { width: 200 } };

    return (
        <Stack className="HireInformation ml-sm-0" style={{padding:'0px 2px 0px 2px'}}>
            <Stack horizontal={true} style={{backgroundColor:'rgb(235, 235, 235)',maxWidth: '100%',margin: '5px 0px 0px 5px'}}>
                <Stack.Item style={{ marginTop: '3px' }} className="ac-icon">
                    <IconButton
                        aria-labelledby="Hire Information"
                        title="Hire Information"
                        iconProps={{
                            iconName: isOpen ? 'ChevronUp' : 'ChevronDown',
                        }}
                        onClick={toggleHireInformation}
                    />
                </Stack.Item>
                <Stack.Item align="center" styles={accordionHeader} className="ac-title">
                <h2 style={{fontSize:'20px'}}> Hire Information </h2>
                </Stack.Item>
            </Stack>

            {isOpen && (
                <Stack styles={accordionContent}>
                    <Stack horizontal tokens={{ childrenGap: 20 }} styles={stackStyles}>
                        {
                                <Stack {...hireInfoColumnProps}>
                                    <TextField
                                        label="Record ID"
                                        value={recordIDTextFieldValue}
                                        readOnly={true}
                                        disabled={true}
                                        styles={textFieldStyles}
                                        maxLength={25}
                                    />
                                </Stack>
                            }
                        <Stack {...hireInfoColumnProps}>
                            <TextField
                                label="Candidate ID"
                                value={candidateIDTextFieldValue}
                                styles={textFieldStyles}
                                readOnly={true}
                                disabled={true}
                                maxLength={25}
                            />
                        </Stack>
                        <Stack {...hireInfoColumnProps}>
                            <TextField
                                label="Employee ID"
                                value={employeeIDTextFieldValue}
                                styles={textFieldStyles}
                                readOnly={true}
                                disabled={true}
                                maxLength={25}
                            />
                        </Stack>
                        <Stack {...hireInfoColumnProps}>
                            <TextField
                                label="Hire Type"
                                value={hireTypeTextFieldValue}
                                styles={textFieldStyles}
                                readOnly={true}
                                disabled={true}
                            />
                        </Stack>
                        {props.hireInfo.showInternship && (
                            <Stack {...hireInfoColumnProps}>
                                <TextField
                                    label="Internship Type"
                                    value={internshipType!}
                                    styles={textFieldStyles}
                                    readOnly={true}
                                    disabled={true}
                                />
                            </Stack>
                        )}
                    </Stack>

                    {props.hireInfo.hireTypeID == 3 && (
                        <Stack horizontal tokens={{ childrenGap: 20 }} styles={stackStyles}>
                            <Stack horizontal {...hireInfoColumnProps}>
                                <Toggle
                                    label="MACH"
                                    inlineLabel={true}
                                    defaultChecked={props.hireInfo.isMACH}
                                    readOnly={true}
                                    disabled={true}
                                    onText="Yes"
                                    offText="No"
                                />
                            </Stack>
                            <Stack horizontal {...hireInfoColumnProps}>
                                <Toggle
                                    label="MBA"
                                    inlineLabel={true}
                                    readOnly={true}
                                    disabled={true}
                                    defaultChecked={props.hireInfo.isMBA}
                                    onText="Yes"
                                    offText="No"
                                />
                            </Stack>
                        </Stack>
                    )}
                </Stack>
            )}
        </Stack>
    );
};
