export const fieldHeaderDesign = {
    backgroundColor: 'rgb(243, 242, 241)',
    fontWeight: 'normal' as 'normal',
    height: 25,
    textAlign: 'center' as 'center',
    cursor: 'pointer',
    fontSize: 16,
    padding: 3,
    whiteSpace: 'nowrap' as 'nowrap',
};

export const queueTile = {
    font: 'Segoe UI SemiLight',
    backgroundColor: '##F2F2F2',
    boxShadow:'rgb(0 0 0 / 16%) 0px 1px 4px'  ,
    minWidth: 120,
    maxWidth: 180,
    height: 140,
    color: '#000000',
};

export const tilesCount = {
    font: 'Segoe UI Light',
    color: 'rgb(0, 120, 212)',
    fontSize: 35,
    cursor: 'pointer',
};

export const fieldCellDesign = {
    font: 'Segoe UI',
    height: 22,
    fontSize: 12,
    color: 'rgb(0, 120, 212)' ,
    fontWeight: 'bold' as 'bold',
};

export const relTypeDataStyle = {
    marginLeft: 10,
    cursor: 'pointer',
};

export const tiles = {
    left: 10,
    top: 10,
    width: 165,
    wordWrap: 'break-word' as 'break-word',
    border: 'thick',
    textAlign: 'center' as 'center',
};
