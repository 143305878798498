import * as React from 'react';
import { TileComponentProps } from './RelocampQueueTable.types';
import * as RelocampQueueStyles from './RelocampQueueTable.styles';
import { RelocampTile } from './RelocampTile';

export const TilesComponent: React.FC<TileComponentProps> = ({ tileData, apiData, handleTileFilterSubmit }) => {
    const filterByTiles = (id: number) => {
        let tempData = apiData;
        switch (id) {
            //us domestic
            case 2:
                tempData = apiData.filter((row) => {
                    return row.RelocationTypeId === 24 || row.RelocationTypeId === 12;
                });
                break;
            //non us domestic
            case 1:
                tempData = apiData.filter((row) => {
                    return row.RelocationTypeId === 31;
                });
                break;
            //us inbound
            case 4:
                tempData = apiData.filter((row) => {
                    return row.RelocationTypeId === 28 || row.RelocationTypeId === 18;
                });
                break;
            //non us global
            case 3:
                tempData = apiData.filter((row) => {
                    return row.RelocationTypeId === 34;
                });
                break;
            //us domestic mba
            case 5:
                tempData = apiData.filter((row) => {
                    return row.RelocationTypeId === 25 || row.RelocationTypeId === 13;
                });
                break;
            //US Internship
            case 6:
                tempData = apiData.filter((row) => {
                    return row.RelocationTypeId === 19 || row.RelocationTypeId === 37 || row.RelocationTypeId === 52;
                });
                break;
            //Non US Internship
            case 7:
                tempData = apiData.filter((row) => {
                    return row.RelocationTypeId === 35 || row.RelocationTypeId === 53;
                });
                break;
            //university
            case 8:
                tempData = apiData.filter((row) => {
                    return (
                        row.RelocationTypeId === 24 ||
                        row.RelocationTypeId === 12 ||
                        row.RelocationTypeId === 31 ||
                        row.RelocationTypeId === 25 ||
                        row.RelocationTypeId === 13 ||
                        row.RelocationTypeId === 28 ||
                        row.RelocationTypeId === 18 ||
                        row.RelocationTypeId === 34
                    );
                });
                break;
            case 9:
                tempData = apiData.filter((row) => {
                    return row.RelocationTypeId === 19 || row.RelocationTypeId === 35;
                });
                break;
            //US Domestic or MBA
            case 10:
                tempData = apiData.filter((row) => {
                    return row.RelocationTypeId === 39;
                });
                break;            
        }
        handleTileFilterSubmit(tempData);
    };

    return (
        <div className="contentWrap" style={{ overflow: 'auto' as 'auto', marginBottom: '48px' }}>
            {tileData.map((tile, index) => {
                return (
                    <div style={RelocampQueueStyles.singleTileContainer}>
                        <RelocampTile instance={index} objParent={tile} filterByTiles={filterByTiles} />
                    </div>
                );
            })}
        </div>
    );
};
