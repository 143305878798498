import * as React from 'react';
import {
    Stack,
    IconButton,
    IStackItemStyles,
    CheckboxVisibility,
    SelectionMode,
    PrimaryButton,
    TextField,
    Selection,
    DetailsListLayoutMode,
} from '@fluentui/react';
import {
    CoreBenefitsEntity,
    allOptionalBenefitsListItemProps,
} from '../../Models/IEstimatesForm';
import {
    LumpSumRelocation,
    OptionalBenefitMapping,
} from '../../Models/IReviewForm';
import {
    DetailsList,
    IColumn,
    DetailsRow,
    IDetailsListProps,
} from '../../../node_modules/office-ui-fabric-react/lib/components/DetailsList/';

const formSectionStyle = {
    paddingTop: 15,
    paddingBottom: 15,
    borderTop: '3px solid #dcdcdc',
};
const accordionHeader: IStackItemStyles = {
    root: {
        padding: 5,
        cursor: 'pointer',
        fontSize: '20px',
    },
};
const accordionContent: IStackItemStyles = {
    root: {
        marginLeft: '50px',
        marginTop: 15,
    },
};
const buttonStyles = {
    backgroundColor: 'rgb(0, 120, 212)',
    color: '#FFFFFF',
    marginBottom: '10px',
};

export const RelocationPackage: React.FC<any> = React.forwardRef((props, ref) => {
    const [isOpen, setIsOpen] = React.useState<boolean>(true);

    const [coreBenefitsColumns, setCoreBenefitsColumns] = React.useState<IColumn[]>([]);
    const [coreBenefitsList, setCoreBenefitsList] = React.useState<CoreBenefitsEntity[]>([]);

    const [relocationCashColumns, setRelocationCashColumns] = React.useState<IColumn[]>([]);
    const [relocationCashList, setRelocationCashList] = React.useState<CoreBenefitsEntity[]>([]);

    const [relocationPackageColumns, setRelocationPackageColumns] = React.useState<IColumn[]>([]);
    const [relocationPackageList, setRelocationPackageList] = React.useState<LumpSumRelocation[]>([]);

    const [optionalBenefitsColumns, setOptionalBenefitsColumns] = React.useState<IColumn[]>([]);
    const [optionalBenefitsList, setOptionalBenefitsList] = React.useState<OptionalBenefitMapping[]>([]);
    const [selectedOptionalBenefitsIDList, setSelectedOptionalBenefitsIDList] = React.useState<number[]>([]);
    const [allOptionalBenefitsList, setAllOptionalBenefitsList] = React.useState<allOptionalBenefitsListItemProps[]>([]);
    const [optionalBenefitsListSelection] = React.useState<any>(new Selection({
        selectionMode: SelectionMode.multiple,
        getKey: _getKey,
        onSelectionChanged: _getSelectionDetails,
    }));

    React.useImperativeHandle(
        ref,
        () => ({
            validateForm() {
                let values: any = {};
                values['RelocationCashAmount'] = relocationCashList[0] ? relocationCashList[0].Amount : 0;
                values['LumpSumRelocation'] = relocationPackageList[0] ? relocationPackageList[0] : [];
                values['OptionalBenefitMapping'] = allOptionalBenefitsList[0] ? allOptionalBenefitsList.filter(item => selectedOptionalBenefitsIDList.includes(item.OptionalBenifitID, 0)) : [];

                return { values }
            }
        }),
    )

    const toggleRelocationPackage = () => {
        setIsOpen(!isOpen);
    };

    const initializeRelocationPackage = () => {
        let newRelocationPackageList = {
            ...props.relocationFormResponse.LumpSumRelocation,
            taxGrossup: props.getTaxGrossUp(props.relocationFormResponse.LumpSumRelocation?.NetCash, props.relocationFormResponse.LumpSumRelocation?.TaxRate)
        };;
        if (props.relocationFormResponse.LumpSumRelocation?.TaxRate == 0 || props.relocationFormResponse.RelocationpolicyTypeID == "52") {
            newRelocationPackageList = {
                ...props.relocationFormResponse.LumpSumRelocation,
                taxGrossup: props.getTaxGrossUp(props.relocationFormResponse.LumpSumRelocation?.NetCash)
            };
        }
        setRelocationPackageList([newRelocationPackageList]);
    }

    const initializeCoreBenefits = () => {
        let relocationCashList: CoreBenefitsEntity[] = [];
        const coreBenefitsList = props.coreBenefitsResponse.filter((item: any) => {
            if (item.CoreBeniftName.trim() === 'Relocation Cash Allowance') {
                if (!(item.CoreBenifitID === 3 && (
                    item.RelocationPolicyTypeID === 19 ||
                    item.RelocationPolicyTypeID === 20 ||
                    item.RelocationPolicyTypeID === 21 ||
                    item.RelocationPolicyTypeID === 37))) {
                    relocationCashList.push(item);
                    return;
                }
            }
            return item;
        });

        setCoreBenefitsList(coreBenefitsList);
        if (relocationCashList[0]) {
            let cashAmount = relocationCashList[0]?.Amount.toString();
            if (props.relocationCash != null) {
                cashAmount = props.relocationCash.toString();
            }
            setRelocationCashList([{ ...relocationCashList[0], Amount: cashAmount }]);
        }
    }

    const isValidPositiveNumberOrText = (value: string | undefined | null): boolean => {
        return (value !== '' && (isNaN(Number(value)) || 0 < Number(value))) ? true : false;
    }

    const isValidNumber = (value: string | undefined | null): boolean => {
        return (value !== '' && !isNaN(Number(value)) && 0 <= Number(value)) ? true : false;
    }

    const isValidNumberOrText = (value: string | undefined | null): boolean => {
        return (value !== '' && (isNaN(Number(value)) || 0 <= Number(value))) ? true : false;
    }

    const initializeOptionalBenefits = () => {
        setOptionalBenefitsList(props.relocationFormResponse?.OptionalBenefitMapping);
        let allOptionalBenefitsList = props.allOptionalBenefitsResponse.map((allOptionalBenefitsListItem: allOptionalBenefitsListItemProps) => {
            allOptionalBenefitsListItem.defaultAmount = allOptionalBenefitsListItem.Amount;
            allOptionalBenefitsListItem.isSelected = false;
            const findRecord = props.relocationFormResponse?.OptionalBenefitMapping
                .map((item: any) => ({ OptionalBenifitID: item.OptionalBenifitID, Amount: item.Amount, Unit: item.Unit }))
                .find((optionalBenefitsItem: any) => optionalBenefitsItem.OptionalBenifitID === allOptionalBenefitsListItem.OptionalBenifitID);
            if (findRecord !== undefined) {
                allOptionalBenefitsListItem.Amount = findRecord.Amount;
                allOptionalBenefitsListItem.Unit = findRecord.Unit;
                allOptionalBenefitsListItem.isSelected = true;
                optionalBenefitsListSelection.setKeySelected(findRecord.OptionalBenifitID.toString(), true, true);
            }

            allOptionalBenefitsListItem.isValid = isValidPositiveNumberOrText(allOptionalBenefitsListItem.Unit) && isValidNumberOrText(allOptionalBenefitsListItem.Amount);
            allOptionalBenefitsListItem.taxGrossup = props.getTaxGrossUp(allOptionalBenefitsListItem.Amount, allOptionalBenefitsListItem.TaxPct);
            return allOptionalBenefitsListItem;
        })
        const selectedOptionalBenefitsList = allOptionalBenefitsList.filter((item: allOptionalBenefitsListItemProps) => item.isSelected === true);
        setOptionalBenefitsList(selectedOptionalBenefitsList);
        setAllOptionalBenefitsList(allOptionalBenefitsList);
    }

    const initializeLists = () => {
        if (props.isLumpSum) {
            initializeRelocationPackage();
        } else {
            initializeCoreBenefits();
            initializeOptionalBenefits();
        }
    }

    React.useEffect(() => {
        initializeRelocationPackage();
    }, [props.relocationFormResponse?.LumpSumRelocation])


    React.useEffect(() => {
        initializeCoreBenefits();
    }, [props.coreBenefitsResponse, props.relocationFormResponse?.RelocationCashAmount])

    React.useEffect(() => {
        initializeOptionalBenefits();
    }, [props.allOptionalBenefitsResponse, props.relocationFormResponse?.OptionalBenefitMapping])

    const getTotalAmount = (item: CoreBenefitsEntity | OptionalBenefitMapping | LumpSumRelocation) => {
        if ("NetCash" in item) {
            return <span>{props.getTaxGrossUpFormatted(Number(item.NetCash))}</span>
        } else {
            return <span>{props.getTaxGrossUpFormatted(Number(item.Amount))}</span>
        }
    }

    React.useEffect(() => {
        const coreBenefitsColumns = [
            { key: 'column1', name: 'Benefit Name', fieldName: 'CoreBeniftName', minWidth: 300, maxWidth: 600 },
            { key: 'column2', name: 'Amount', fieldName: 'Amount', minWidth: 300, maxWidth: 600 },
        ];
        setCoreBenefitsColumns(coreBenefitsColumns);
    }, []);

    React.useEffect(() => {
        const relocationCashColumns: IColumn[] = [
            {
                key: 'column1',
                name: 'Relocation Cash',
                fieldName: 'CoreBeniftName',
                minWidth: 300,
                maxWidth: 600
            },
            {
                key: 'column2',
                name: 'Amount',
                fieldName: 'Amount',
                minWidth: 300,
                maxWidth: 600,
                onRender: (relocationCashItem, index) => {
                    if (props.isEditMode) {
                        return <TextField
                            aria-label={"Amount"}
                            value={relocationCashItem.Amount}
                            onChange={(event, newValue?: string) => {
                                if (isValidNumber(newValue)) {
                                    let newAmount = Number(newValue);
                                    if (10000 < newAmount) {
                                        newAmount = 10000;
                                    }
                                    relocationCashItem.Amount = newAmount.toString();
                                } else {
                                    relocationCashItem.Amount = '';
                                }
                                setRelocationCashList((prevState: any) =>
                                    prevState.map((listItem: any, listItemIndex: any) => {
                                        if (listItemIndex === index) {
                                            return relocationCashItem;
                                        } else {
                                            return listItem;
                                        }
                                    }
                                    ));
                            }}
                        />;
                    } else {
                        return <span>{props.currencyFormatter(relocationCashItem.Amount)}</span>;
                    }
                }
            },
            {
                key: 'column3',
                name: 'Total with Tax Gross-up',
                fieldName: 'Total',
                minWidth: 300,
                maxWidth: 600,
                onRender: (item: CoreBenefitsEntity) => getTotalAmount(item)
            }
        ];
        setRelocationCashColumns(relocationCashColumns);
    }, [props.isEditMode, relocationCashList]);

    React.useEffect(() => {
        const optionalBenefitsColumns: IColumn[] = [
            {
                key: 'column1',
                name: 'Benefits',
                fieldName: 'OptionalBeniftName',
                minWidth: 300,
                maxWidth: 600
            },
            {
                key: 'column2',
                name: 'Unit',
                fieldName: 'Unit',
                minWidth: 300,
                maxWidth: 600,
                onRender: (currentBenefit, currentBenefitIndex) => {
                    if (props.isEditMode && (isValidNumber(currentBenefit.Unit) || currentBenefit.Unit === '') && selectedOptionalBenefitsIDList.includes(currentBenefit.OptionalBenifitID, 0)) {
                        return <TextField
                            aria-label={"Unit"}
                            value={currentBenefit?.Unit}
                            onGetErrorMessage={(value: string) => { return props.isValidPositiveNumber(value) ? '' : 'Unit is required.' }}
                            onChange={(e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                if (props.isValidPositiveNumber(newValue)) {
                                    let newUnit = Number(newValue);
                                    if (props.isValidPositiveNumber(currentBenefit.UnitMaxValue) && Number(currentBenefit.UnitMaxValue) < newUnit) {
                                        newUnit = Number(currentBenefit.UnitMaxValue);
                                    }

                                    let previousUnit = 1;
                                    if (props.isValidPositiveNumber(currentBenefit.Unit)) {
                                        previousUnit = Number(currentBenefit.Unit);
                                    }

                                    let updatedAmount = Number(currentBenefit.defaultAmount);
                                    if (isValidNumber(currentBenefit.Amount)) {
                                        updatedAmount = Number(currentBenefit.Amount);
                                    }

                                    let costPerUnit = updatedAmount;
                                    if (0 < previousUnit) {
                                        costPerUnit = updatedAmount / previousUnit;
                                    }

                                    let unitDifference = newUnit - previousUnit;
                                    updatedAmount += costPerUnit * unitDifference;

                                    if (currentBenefit.MaxAmount < updatedAmount) {
                                        updatedAmount = currentBenefit.MaxAmount;
                                    }

                                    currentBenefit.Amount = updatedAmount.toString();
                                    currentBenefit.Unit = newUnit.toString();
                                } else {
                                    currentBenefit.Unit = '';
                                }
                                currentBenefit.isValid = props.isValidPositiveNumber(currentBenefit.Unit) && isValidNumber(currentBenefit.Amount);
                                setAllOptionalBenefitsList((prevState: any) =>
                                    prevState.map((item: any, itemIndex: any) => {
                                        if (itemIndex === currentBenefitIndex) {
                                            return currentBenefit;
                                        } else {
                                            return item;
                                        }
                                    }
                                    ));
                            }
                            }
                        />;
                    } else {
                        return <span>{currentBenefit.Unit}</span>;
                    }
                }
            },
            {
                key: 'column3',
                name: 'Amount',
                fieldName: 'Amount',
                minWidth: 300,
                maxWidth: 600,
                onRender: (currentBenefit, currentBenefitIndex) => {
                    if (props.isEditMode && currentBenefit.OptionalBenifitID !== 39 && selectedOptionalBenefitsIDList.includes(currentBenefit.OptionalBenifitID, 0)) {
                        return <TextField
                            aria-label={"Amount"}
                            value={currentBenefit.Amount}
                            onGetErrorMessage={(value: string) => { return isValidNumber(value) ? '' : 'Amount is required.' }}
                            onChange={(e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                if (isValidNumber(newValue)) {
                                    let newAmount = Number(newValue);
                                    if (currentBenefit.MaxAmount < newAmount) {
                                        newAmount = currentBenefit.MaxAmount;
                                    }
                                    currentBenefit.Amount = newAmount.toString();
                                } else {
                                    currentBenefit.Amount = '';
                                }
                                currentBenefit.isValid = isValidPositiveNumberOrText(currentBenefit.Unit) && isValidNumber(currentBenefit.Amount);
                                setAllOptionalBenefitsList((prevState: any) =>
                                    prevState.map((item: any, itemIndex: any) => {
                                        if (itemIndex === currentBenefitIndex) {
                                            currentBenefit.taxGrossup = props.getTaxGrossUp(currentBenefit.Amount, currentBenefit.TaxPct);
                                            return currentBenefit;
                                        } else {
                                            return item;
                                        }
                                    }
                                    ));
                            }
                            }
                        />;
                    } else {
                        return <span>{props.currencyFormatter(currentBenefit.Amount)}</span>;
                    }
                }
            },
            {
                key: 'column4',
                name: 'Total with Tax Gross-up',
                fieldName: 'Amount',
                minWidth: 300,
                maxWidth: 600,
                onRender: (item: OptionalBenefitMapping) => {
                    return <span>{props.currencyFormatter(item.taxGrossup)}</span>;
                }
            },
        ];
        setOptionalBenefitsColumns(optionalBenefitsColumns);
    }, [props.isEditMode, optionalBenefitsList, allOptionalBenefitsList, selectedOptionalBenefitsIDList]);

    React.useEffect(() => {
        const relocationPackageColumns: IColumn[] = [
            {
                key: 'column1',
                name: 'Benefits',
                fieldName: 'PackageName',
                minWidth: 300,
                maxWidth: 600,
                onRender: () => <span>{props.relocationType}</span>
            },
            {
                key: 'column2',
                name: 'Net Cash to Employee',
                fieldName: 'NetCash',
                minWidth: 300,
                maxWidth: 600,
                onRender: (relocationPackageItem, index) => {
                    if (props.isEditMode) {
                        return <TextField
                            aria-label={"NetCash"}
                            value={relocationPackageItem.NetCash?.toString()}
                            onChange={(event, newValue?: string) => {
                                if (isValidNumber(newValue)) {
                                    relocationPackageItem.NetCash = Number(newValue);
                                } else {
                                    relocationPackageItem.NetCash = undefined;
                                }
                                setRelocationPackageList((prevState: any) =>
                                    prevState.map((listItem: any, listItemIndex: any) => {
                                        if (listItemIndex === index) {
                                            relocationPackageItem.taxGrossup = props.getTaxGrossUp(relocationPackageItem.NetCash, relocationPackageItem.TaxRate);
                                            return relocationPackageItem;
                                        } else {
                                            return listItem;
                                        }
                                    }));
                            }}
                        />;
                    } else {
                        return <span>{props.currencyFormatter(relocationPackageItem.NetCash)}</span>;
                    }
                }
            },
            {
                key: 'column3',
                name: 'Total with Tax Gross-up',
                fieldName: 'totalGross',
                minWidth: 300,
                maxWidth: 600,
                onRender: (item) => {
                    return <span>{props.currencyFormatter(item.taxGrossup)}</span>
                }
            },
        ];
        setRelocationPackageColumns(relocationPackageColumns);
    }, [props.isEditMode, relocationPackageList]);

    React.useEffect(() => {
        let allInputs = document.querySelectorAll('input');
        allInputs.forEach((input) => {
            if (input.getAttribute('type') === 'number') {
                //input.setAttribute('aria-label','textbox');
                input.setAttribute('role', 'textbox');
                input.setAttribute('tabindex', '0');
            }
            if (input.getAttribute('type') === 'text') {
                //input.setAttribute('aria-label','textbox');
                input.setAttribute('role', 'textbox');
                input.setAttribute('tabindex', '0');
            }
        })
        let allDetailsRows = document.querySelectorAll('.ms-DetailsRow-check');
        allDetailsRows.forEach((detailsRow) => {
            detailsRow.setAttribute('aria-label', 'checkbox');
            if (detailsRow.getAttribute('role') === null) {
                detailsRow.setAttribute('role', 'checkbox');
            }
        })
    });

    function _getSelectionDetails(): void {
        let selectedOptionalBenefitsIDList: number[] = optionalBenefitsListSelection.getSelection().map((item: any) => item.OptionalBenifitID);
        setSelectedOptionalBenefitsIDList(selectedOptionalBenefitsIDList);
    }

    function _getKey(item: OptionalBenefitMapping, index?: number): string {
        return item.OptionalBenifitID.toString();
    }

    const updateEditForm = async () => {
        const invalidRecord = allOptionalBenefitsList
            .map((item) => ({ isValid: item.isValid, OptionalBenifitID: item.OptionalBenifitID }))
            .find((item) => item.isValid === false && selectedOptionalBenefitsIDList.includes(item.OptionalBenifitID, 0));
        if (invalidRecord === undefined) {
            await props.updateEstimateDetails();
        }
    };

    const cancelEditMode = () => {
        initializeLists();
        props.cancelEditMode();
    }

    React.useEffect(() => {
        initializeLists();
    }, [props.isLumpSum])

    const _onRenderRow: IDetailsListProps['onRenderRow'] = props => {
        if (props) {
            if (props.item.OptionalBenifitID !== 39) {
                return <DetailsRow {...props} />;
            }
            return <DetailsRow {...props} disabled />;
        }
        return null;
    };

    return (
        <Stack className="RelocationPackage" style={formSectionStyle}>
            <Stack horizontal horizontalAlign="space-between">
                <Stack horizontal>
                    <Stack.Item style={{ marginTop: '3px' }}>
                        <IconButton
                            aria-labelledby="RelocationPackage"
                            title="RelocationPackage"
                            iconProps={{
                                iconName: isOpen ? 'ChevronUp' : 'ChevronDown',
                            }}
                            onClick={toggleRelocationPackage}
                        />
                    </Stack.Item>

                    <Stack.Item align="start" styles={accordionHeader}>
                        <h2 style={{ fontSize: '20px' }}>Relocation Package</h2>
                    </Stack.Item>
                </Stack>
                <Stack>
                    <Stack.Item styles={accordionHeader} align="end">
                        <span>{props.relocationType}</span> <strong>{props.relocationType !== "Limited Benefits" ? props.totalEstimate : ""}</strong>
                    </Stack.Item>
                </Stack>
            </Stack>
            <Stack>
                {isOpen && (props.isLumpSum || props.selectedRelocationType === 'US Remote Internship') && (
                    <Stack styles={accordionContent}>
                        <Stack.Item>
                            <h3>Package</h3>
                        </Stack.Item>

                        <Stack.Item>
                            <DetailsList
                                items={relocationPackageList}
                                columns={relocationPackageColumns}
                                selectionMode={SelectionMode.none}
                            />
                        </Stack.Item>

                        <Stack.Item align="end" styles={accordionHeader}>
                            File Fee <strong>{props.currencyFormatter(props.relocationFormResponse.LumpSumRelocation?.FileFee)}</strong>
                        </Stack.Item>

                        <Stack.Item align="end" styles={accordionHeader}>
                            Total Package Value <strong>{props.totalEstimate}</strong>
                        </Stack.Item>
                    </Stack>
                )}
            </Stack>
            <Stack>
                {isOpen && !props.isLumpSum && props.isLimitedBenefits && props.selectedRelocationType !== 'US Virtual Internship' && (
                    <Stack styles={accordionContent}>
                        <Stack.Item>
                            <h3>Package</h3>
                            <p>This a Limited Benefit Package, refer to the Exceptions section for details.</p>
                        </Stack.Item>

                        <Stack.Item align="end" styles={accordionHeader}>
                            File Fee <strong>{props.currencyFormatter(props.LimitedBenifitsTotalPackageValue)}</strong>
                        </Stack.Item>

                        <Stack.Item align="end" styles={accordionHeader}>
                            Total Package Value <strong>{props.currencyFormatter(props.LimitedBenifitsTotalPackageValue)}</strong>
                        </Stack.Item>
                    </Stack>
                )}
                {isOpen && !props.isLumpSum && !props.isLimitedBenefits && props.selectedRelocationType !== 'US Remote Internship' && (
                    <Stack styles={accordionContent} tokens={{ childrenGap: 20 }}>
                        <Stack>
                            <Stack.Item>
                                {props.isEditMode && (
                                    <>
                                        <p>Core Package</p>
                                        <p>
                                            If you wish to modify (increase) the core benefits, please add the additional
                                            benefits in the Exceptions below
                                        </p>
                                    </>
                                )}
                            </Stack.Item>

                            <Stack>
                                <DetailsList
                                    items={coreBenefitsList}
                                    columns={coreBenefitsColumns}
                                    selectionMode={SelectionMode.none}
                                />
                            </Stack>

                            <Stack.Item align="end" styles={accordionHeader}>
                                {(
                                    <>
                                        <p>Core Package Value{' '}
                                            <strong>{props.currencyFormatter(props.corePackageValue)}</strong></p>
                                    </>
                                )}
                            </Stack.Item>

                            {relocationCashList[0] && (
                                <Stack.Item>
                                    <DetailsList
                                        items={relocationCashList}
                                        columns={relocationCashColumns}
                                        selectionMode={SelectionMode.none}
                                    />
                                </Stack.Item>)
                            }
                        </Stack>

                        <Stack>
                            <Stack.Item>
                                <h3>Optional Benefits</h3>
                            </Stack.Item>

                            <Stack.Item>
                                {props.isEditMode && (
                                    <p>
                                        If you wish to modify (increase) the optional benefits, please add the additional
                                        benefits in the Exceptions below. The grey sections in the below table are Not included
                                        in the policy chosen. Please email GloMoCoE for further assistance.
                                    </p>
                                )}
                            </Stack.Item>

                            <Stack.Item>
                                <DetailsList
                                    key={props.isEditMode ? allOptionalBenefitsList.length : optionalBenefitsList.length}
                                    items={props.isEditMode ? allOptionalBenefitsList : optionalBenefitsList}
                                    columns={optionalBenefitsColumns}
                                    selectionMode={props.isEditMode ? SelectionMode.multiple : SelectionMode.none}
                                    setKey="multiple"
                                    getKey={_getKey}
                                    selection={optionalBenefitsListSelection}
                                    layoutMode={DetailsListLayoutMode.justified}
                                    checkboxVisibility={CheckboxVisibility.always}
                                    selectionPreservedOnEmptyClick={true}
                                    onRenderRow={_onRenderRow}
                                />
                            </Stack.Item>
                            <Stack.Item align="end" styles={accordionHeader}>
                                Total Package Value <strong>{props.totalEstimate}</strong>
                            </Stack.Item>
                        </Stack>

                    </Stack>
                )}
                {isOpen && (props.commitStatus !== "COMMITTED") && (
                    <Stack horizontal horizontalAlign="end">
                        {!props.isEditMode && (
                            <PrimaryButton
                                text="Edit Profiles/Package"
                                onClick={() => props.setIsEditMode(true)}
                                allowDisabledFocus
                                disabled={false}
                                style={buttonStyles}
                            />
                        )}
                        {props.isEditMode && (
                            <>
                                <PrimaryButton
                                    text="Update"
                                    onClick={updateEditForm}
                                    allowDisabledFocus
                                    disabled={false}
                                    style={{ ...buttonStyles, marginRight: 10 }}
                                />
                                <PrimaryButton
                                    text="Cancel"
                                    onClick={cancelEditMode}
                                    allowDisabledFocus
                                    disabled={false}
                                />
                            </>
                        )}
                    </Stack>
                )}
            </Stack>
        </Stack>
    );
});