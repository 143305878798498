import * as React from 'react';
import {
    Stack,
    IconButton,
    IStackItemStyles,
    ShimmeredDetailsList,
    IColumn,
    SelectionMode,
    PrimaryButton,
    mergeStyles,
    FontIcon,
    DetailsListLayoutMode,
    IDetailsRowBaseProps,
    Dropdown,
    Text,
    IDropdownStyles,
    IDropdownOption,
} from '@fluentui/react';
import {
    OfferExceptionsProps,
    FileExceptionProps,
    DeleteRowProps,
    OfferFileExceptionProps,
} from '../../Models/IEstimatesForm';
import { ExceptionModal } from './ExceptionModal';
import * as moment from 'moment';
import { useServiceStore } from '../../RootStateContext';
import { WarningModal } from './WarningModal';
import { DeleteStatusPopUp } from '../ReloForm/PopUps/DeleteStatusPopUp';
import { DeleteWarningPopUp } from '../ReloForm/PopUps/DeleteWarningPopUp';
import { ILoggedInUserRoles } from '../../Models/IUserRoles';
import { ReduxContext } from '@employee-experience/common';
import { IAppState } from '../../Common/Redux/AppState';

const formSectionStyle = {
    paddingTop: 15,
    paddingBottom: 15,
    borderTop: '3px solid #dcdcdc',
};
const accordionHeader: IStackItemStyles = {
    root: {
        padding: 5,
        cursor: 'pointer',
        fontSize: '20px',
    },
};
const accordionContent: IStackItemStyles = {
    root: {
        marginLeft: '50px',
        marginTop: 15,
    },
};
const buttonStyles = {
    backgroundColor: 'rgb(0, 120, 212)',
    color: '#FFFFFF',
    marginBottom: '10px',
};
const iconClass = mergeStyles({
    fontSize: 20,
    height: 20,
    width: 20,
    margin: '0 10px',
    cursor: 'pointer',
});
const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: {
        width: 300
    },
    root: {
        paddingRight: 20
    }
};

export type ListItem = {
    id: string;
    ExceptionId: number;
    FileExceptionCreatedOn: string;
    ExceptionName: string;
    ExceptionJustification: string;
    ExceptionAmount: number;
    ExceptionQuantity: number;
    TaxPct: number;
    TotalAmount: number;
    ExceptionAmountManager: number;
    ExceptionBalanceAmount: number;
    ExceptionAction: string;
    remove: string;
    Reminders: string;
    FERecordId: number;
    redirectedFrom: string;
};

export const OfferExceptions: React.FC<OfferExceptionsProps> = React.forwardRef((props, ref) => {
    const [isOpen, setIsOpen] = React.useState<boolean>(true);
    const [isExceptionModalOpen, setIsExceptionModalOpen] = React.useState<boolean>(false);
    const [isEditExceptionModal, setIsEditExceptionModal] = React.useState<boolean>(false);

    const { estimatesFormService } = useServiceStore();

    const { useSelector } = React.useContext(ReduxContext);
    const userRole: ILoggedInUserRoles = useSelector((state: IAppState) => state.root.userReducer.loggedInUserRoles);

    const [isWarningPopUpOpen, setIsWarningPopUpOpen] = React.useState<boolean>(false);
    const [isDeleteWarningPopUpOpen, setIsDeleteWarningPopUpOpen] = React.useState<boolean>(false);
    const [isDeleteConfirmationPopUpOpen, setIsDeleteConfirmationPopUpOpen] = React.useState<boolean>(false);
    const [isDeleteErrorPopUpOpen, setIsDeleteErrorPopUpOpen] = React.useState<boolean>(false);

    const [offerExceptionsList, setOfferExceptionsList] = React.useState<FileExceptionProps[]>([]);
    const [offerExceptionColumns, setOfferExceptionColumns] = React.useState<IColumn[]>([]);

    const [deletedOfferException, setDeletedOfferException] = React.useState<DeleteRowProps>();
    const [offerExceptionAction, setOfferExceptionAction] = React.useState<string>('');

    const [exceptionsDropdown, setExceptionsDropdown] = React.useState<IDropdownOption[]>([]);
    const [selectedException, setSelectedException] = React.useState<IDropdownOption>({} as IDropdownOption);

    const [selectedRow, setSelectedRow] = React.useState<FileExceptionProps>({} as FileExceptionProps);

    const [isAlreadyPendingException, setIsAlreadyPendingException] = React.useState<boolean>(false);

    const toggleOfferExceptions = () => {
        setIsOpen(!isOpen);
    };

    React.useEffect(() => {
        let offerExceptionColumns = [
            {
                key: 'column1', name: 'EXCEPTION CREATED ON', fieldName: 'FileExceptionCreatedOn', minWidth: 100, maxWidth: 300,
                onRender: (item: ListItem) => <span>{moment(item.FileExceptionCreatedOn).format('MM/DD/YYYY')}</span>,
            },
            {
                key: 'column2', name: 'CATEGORY', fieldName: 'ExceptionName', minWidth: 100, maxWidth: 300,
                onRender: (item: ListItem) => <a role="link" onKeyPress={()=>editException(item)} onClick={() => editException(item)} > {item.ExceptionName} </a>,
            },
            {
                key: 'column3', name: 'JUSTIFICATION FOR EXCEPTION', fieldName: 'ExceptionJustification', minWidth: 100, maxWidth: 300
            },
            {
                key: 'column4', name: 'AMOUNT', fieldName: 'ExceptionAmount', minWidth: 100, maxWidth: 300,
                onRender: (item: ListItem) => <span>{props.currencyFormatter(item.ExceptionAmount)}</span>,
            },
            {
                key: 'column5', name: 'QUANTITY', fieldName: 'ExceptionQuantity', minWidth: 100, maxWidth: 300
            },
            {
                key: 'column6', name: 'TAXRATE', fieldName: 'TaxPct', minWidth: 100, maxWidth: 300
            },
            {
                key: 'column7', name: 'TOTAL', fieldName: 'TotalAmount', minWidth: 100, maxWidth: 300,
                onRender: (item: ListItem) => <span>{props.currencyFormatter(item.TotalAmount)}</span>,
            },
            {
                key: 'column8', name: 'ACTION/ STATUS', fieldName: 'ExceptionAction', minWidth: 100, maxWidth: 300,
                onRender: (item: ListItem) => {
                    if (item.ExceptionAction === 'PS') {
                        return (
                            <PrimaryButton
                                text="Send For Approval"
                                onClick={() => sendForApproval(item.FERecordId)}
                                allowDisabledFocus
                                disabled={false}
                                style={buttonStyles}
                            />
                        );
                    } else if (item.ExceptionAction === 'AU') {
                        return 'Authorized';
                    } else {
                        return 'Unknown';
                    }
                }
            },
            {
                key: 'column9', name: 'REMOVE', fieldName: 'remove', minWidth: 100, maxWidth: 300,
                onRender: (item: any) => {
                    return (
                        <FontIcon role="button"
                            tabIndex={0}
                            id={item.ExceptionId}
                            aria-label="Delete"
                            iconName="Package"
                            className={iconClass}
                            onClick={() => removeRow(item)}
                        />
                    );
                }
            },
            {
                key: 'column10', name: 'REMINDERS', fieldName: 'Reminders', minWidth: 100, maxWidth: 300,
            },
        ]

        setOfferExceptionColumns(offerExceptionColumns);
    }, []);

    React.useEffect(() => {
        setOfferExceptionsList([
            ...props.activeOfferExceptionsResponse,
            ...props.pendingAddOfferExceptions
        ]);
    }, [props.activeOfferExceptionsResponse,props.pendingAddOfferExceptions]);

    React.useEffect(() => {
        setOfferExceptionsList([
            ...props.pendingAddOfferExceptions
        ]);
    }, [props.offerExceptionsTotalEstimate]);


    const updateListData = (newException?: OfferFileExceptionProps) => {
        props.getAllFileExceptions();
    };

    const removeRow = async (item: ListItem) => {
        const response = await estimatesFormService.getIndvFileExcRecord(item.FERecordId);
        setDeletedOfferException({ FERecordId: item.FERecordId, ReloID: props.reloId, ExceptionId: item.ExceptionId });
        setOfferExceptionAction(item.ExceptionAction);
        setIsWarningPopUpOpen(response ? true : false);
    };

    function _onRenderRow(props: IDetailsRowBaseProps | undefined, defaultRender: any | undefined) {
        if (!props || !defaultRender) {
            return null;
        }
        return defaultRender({
            ...props,
            styles: {
                root: {
                    selectors: {
                        '.ms-DetailsRow-cell': {
                            whiteSpace: 'normal',
                            lineHeight: 'normal',
                            alignItems: 'center',
                        },
                        '.ms-DetailsRow-cellTitle': {
                            height: '100%',
                            alignItems: 'center',
                            wordWrap: 'break-word',
                        },
                    },
                },
            },
        })
    }

    const _onRenderDetailsHeader = (headerProps: any, defaultRender: any) => {
        if (!headerProps || !defaultRender) {
            return null;
        }
        return defaultRender({
            ...headerProps,
            styles: {
                root: {
                    selectors: {
                        '.ms-DetailsHeader-cell': {
                            whiteSpace: 'normal',
                            textOverflow: 'clip',
                            lineHeight: 'normal',
                            height: "100%",
                        },
                        '.ms-DetailsHeader-cellTitle': {
                            height: '100%',
                            alignItems: 'center',
                        },
                    },
                    height: "60px",
                },
            },
        })
    };

    React.useEffect(() => {
        const options: IDropdownOption[] = props.allActiveExceptionsResponse.map((item) => ({ key: item.ExceptionID, text: item.ExceptionName }))
        setExceptionsDropdown(options);
    }, []);

    const toggleWarningModal = (flag: boolean) => {
        setIsWarningPopUpOpen(flag)
    };

    const toggleDeleteWarningPopUp = (flag: boolean) => {
        setIsDeleteWarningPopUpOpen(flag);
    }
    const toggleDeleteStatusPopUp = (status?: string) => {
        if (status === "DeleteSuccess") {
            setIsDeleteWarningPopUpOpen(false);
            setIsDeleteConfirmationPopUpOpen(true);
        }
        else if (status === "DeleteError") {
            setIsDeleteWarningPopUpOpen(false);
            setIsDeleteErrorPopUpOpen(true);
        }
        else if (isDeleteConfirmationPopUpOpen === true) {
            setIsDeleteConfirmationPopUpOpen(false);
            props.dismissOnDeleteSuccess();
        }
        else if (isDeleteErrorPopUpOpen === true) {
            setIsDeleteErrorPopUpOpen(false);
        }
    }

    const sendForApproval = async (FERecordId: number) => {
        const approvalResponse = await estimatesFormService.sendExceptionApproval(FERecordId, props.reloId);
        if (approvalResponse) {
            await props.getAllFileExceptions();
        }
        return approvalResponse;
    };

    const triggerModal = (addExceptionFlag: boolean) => {
        if (selectedException.key !== undefined) {

            // If the exception modal is supposed to open, check if there are any existing offer exceptions
            // If there are, alert the user and don't let the user add a new one (there can only be one offer exception per exception ID - user can update offer exception instead)
            // Otherwise, check if there are pending offer exceptions so we can leave a note to the user if there is
            if (addExceptionFlag === true) {
                const existingExceptionIds=offerExceptionsList.map(item => item.ExceptionId.toString());
                if (existingExceptionIds.includes(selectedException.key.toString())) {
                    alert('This benefit has already been added below.');
                    addExceptionFlag = false;
                } else {
                    const existingPendingExceptionIds = props.pendingAddOfferExceptions.map(item => item.ExceptionId.toString());
                    if (existingPendingExceptionIds.includes(selectedException.key.toString())) {
                        setIsAlreadyPendingException(true);
                    } else {
                        setIsAlreadyPendingException(false);
                    }
                }
            }
            
            setIsExceptionModalOpen(addExceptionFlag);
            setIsEditExceptionModal(!addExceptionFlag);
        } else {
            alert('Please select any exception');
        }
    };

    const onDropdownChange = (event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
        setSelectedException(item || {} as IDropdownOption);
    };

    const editException = (item: ListItem) => {
        let newException = {
            key: item.ExceptionId,
            text: item.ExceptionName
        }
        setSelectedException(newException);
        setSelectedRow((item as unknown) as FileExceptionProps);
        setIsAlreadyPendingException(false);
        setIsExceptionModalOpen(true);
        setIsEditExceptionModal(true);
    };

    const handleForceCommit = async (flag: string) => {
        await props.handleForceCommit(flag);
    }

    const handleUnCommit = async () => {
        await props.handleUnCommit();
    }

    return (
        <Stack>
            {!props.isLumpSum && (<Stack className="OfferExceptions" style={formSectionStyle}>
                <Stack horizontal>
                    <Stack.Item style={{ marginTop: '3px' }}>
                        <IconButton
                            aria-labelledby="OfferExceptions"
                            title="OfferExceptions"
                            iconProps={{
                                iconName: isOpen ? 'ChevronUp' : 'ChevronDown',
                            }}
                            onClick={toggleOfferExceptions}
                        />
                    </Stack.Item>
                    <Stack.Item align="center" styles={accordionHeader}>
                        <h2 style={{ fontSize: '20px' }}>Offer Exceptions</h2>
                    </Stack.Item>
                </Stack>


                {isOpen && (
                    <Stack styles={accordionContent}>
                        <Stack.Item>
                            Please select the benefits you wish to provide in addition to those already listed in core and optional benefits.
                        </Stack.Item>

                        <Stack horizontal horizontalAlign='start' verticalAlign="end">
                            {props.isEditMode &&
                                <>
                                    <Dropdown
                                        placeholder="Select an Exception"
                                        label="Exceptions (Optional)"
                                        options={exceptionsDropdown}
                                        styles={dropdownStyles}
                                        selectedKey={selectedException ? selectedException.key : null}
                                        onChange={onDropdownChange}
                                        notifyOnReselect={true}
                                    />
                                    <PrimaryButton
                                        text="Add Exception"
                                        onClick={() => triggerModal(true)}
                                        allowDisabledFocus
                                        disabled={false}
                                    />
                                </>
                            }
                            {isExceptionModalOpen && selectedException && (
                                <ExceptionModal
                                    isModalOpen={isExceptionModalOpen}
                                    triggerModal={triggerModal}
                                    exceptionsDropdown={exceptionsDropdown}
                                    hiringManagerAlias={props.hiringManagerAlias}
                                    allExceptionsResponse={props.allExceptionsResponse}
                                    selectedException={selectedException}
                                    totalEstimate={props.offerExceptionsTotalEstimate}
                                    taxAmountsResponse={props.taxAmountsResponse}
                                    type="offerException"
                                    firstAndLastName={props.firstAndLastName}
                                    updateListData={updateListData}
                                    custFileID={props.custFileID}
                                    reloId={props.reloId}
                                    isEditException={isEditExceptionModal}
                                    selectedRow={selectedRow}
                                    currencyFormatter={props.currencyFormatter}
                                    getTaxGrossUp={props.getTaxGrossUp}
                                    getTaxGrossUpFormatted={props.getTaxGrossUpFormatted}
                                    storeNewOfferException={props.storeNewOfferException}
                                    commitStatus={props.commitStatus}
                                    isValidPositiveNumber={props.isValidPositiveNumber}
                                    isNotNullOrUndefined={props.isNotNullOrUndefined}
                                    isAlreadyPendingException={isAlreadyPendingException} isOptionalException={false}                                />)
                            }
                        </Stack>
                        <ShimmeredDetailsList
                            items={offerExceptionsList}
                            columns={offerExceptionColumns}
                            selectionMode={SelectionMode.none}
                            layoutMode={DetailsListLayoutMode.justified}
                            onRenderRow={_onRenderRow}
                            onRenderDetailsHeader={_onRenderDetailsHeader}
                        />
                        {offerExceptionsList.length === 0 &&
                            <Stack horizontalAlign='center'>
                                <Text>No Records to Display</Text>
                            </Stack>
                        }
                    </Stack>
                )}

            </Stack>
            )}
            <Stack>
               {props.relocationType === "Limited Benefits" && props.commitStatus ==="UNCOMMITTED"? 
               <Stack.Item align="end" styles={accordionHeader}>
                   {((props.commitStatus === "UNCOMMITTED") ? "Total Estimate" : (props.isLumpSum ? "Total Estimate" : "Accrual Total"))} 
                    <strong>{props.currencyFormatter(props.LimitedBenifitsTotalPackageValue)}</strong>
                </Stack.Item>
                :
                <>{<Stack.Item align="end" styles={accordionHeader}>
                   {((props.commitStatus === "UNCOMMITTED") ? "Total Estimate" : (props.isLumpSum ? "Total Estimate" : "Accrual Total"))} 
                    <strong>{props.currencyFormatter(props.offerExceptionsTotalEstimate)}</strong>
                </Stack.Item>}</>}


                {props.commitStatus !== 'COMMITTED'
                    ? <Stack horizontal horizontalAlign='end'>
                        {isWarningPopUpOpen && (
                            <WarningModal
                                isModalOpen={isWarningPopUpOpen}
                                toggleWarningModal={toggleWarningModal}
                                rowDetails={deletedOfferException}
                                getAllFileExceptions={props.getAllFileExceptions}
                                exceptionActionForRow={offerExceptionAction}
                            />
                        )}
                        {isDeleteWarningPopUpOpen && (
                            <DeleteWarningPopUp
                                isModalOpen={isDeleteWarningPopUpOpen}
                                toggleDeleteWarningPopUp={toggleDeleteWarningPopUp}
                                toggleDeleteStatusPopUp={toggleDeleteStatusPopUp}
                                reloId={props.reloId}
                            />
                        )}
                        {isDeleteConfirmationPopUpOpen && (
                            <DeleteStatusPopUp
                                isModalOpen={isDeleteConfirmationPopUpOpen}
                                toggleDeleteStatusPopUp={toggleDeleteStatusPopUp}
                                message={"This record was successfully deleted."}
                            />
                        )}
                        {isDeleteErrorPopUpOpen && (
                            <DeleteStatusPopUp
                                isModalOpen={isDeleteErrorPopUpOpen}
                                toggleDeleteStatusPopUp={toggleDeleteStatusPopUp}
                                message={"The record wasn't successfully deleted."}
                            />
                        )}
                        {(props.redirectedFrom === 'estimatesQueue' ||
                            (props.redirectedFrom === 'reloSpecialistQueue' &&
                                (userRole.ReloAdmin || userRole.ReloAnalyst))) &&
                            <Stack horizontal>
                                <Stack.Item>
                                    <PrimaryButton
                                        text={userRole.ReloAdmin ? "FORCE COMMIT" : "COMMIT"}
                                        onClick={() => handleForceCommit(userRole.ReloAdmin ? "FORCE COMMIT" : "COMMIT")}
                                        allowDisabledFocus={props.isEditMode ? false : true}
                                        disabled={(props.isEditMode ? true : false)}
                                        style={{ ...buttonStyles, marginRight: 10, opacity: (props.isEditMode ? .65 : 1.0) }}
                                    />
                                </Stack.Item>
                                <Stack.Item>
                                    {!props.isEditMode &&
                                        <PrimaryButton
                                            text="Delete e-form"
                                            onClick={() => toggleDeleteWarningPopUp(true)}
                                            allowDisabledFocus
                                            disabled={false}
                                            style={buttonStyles}
                                        />
                                    }
                                </Stack.Item>
                            </Stack>}
                    </Stack>
                    : <Stack horizontal horizontalAlign='end'>
                        <PrimaryButton
                            text={"UNCOMMIT (REVERSAL)"}
                            onClick={handleUnCommit}
                            allowDisabledFocus
                            disabled={false}
                            style={{ ...buttonStyles, marginRight: 10 }}
                        />
                    </Stack>
                }
            </Stack>
        </Stack>
    );
})
