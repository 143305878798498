import { IReloCampQueueForm } from '../../Models/IReloCampQueue';
import { TileData } from './RelocampQueueTable.types';

// Creates tile data object
export const getTileData = (apiData: IReloCampQueueForm[]): TileData[] => {
    //university  us domestic
    var lumpDomestic = apiData.filter((obj) => obj.RelocationTypeId === 24);
    var lumpDomesticcount = lumpDomestic != null ? lumpDomestic.length : 0;

    var usDomestic = apiData.filter((obj) => obj.RelocationTypeId === 12);
    var usDomesticcount = usDomestic != null ? usDomestic.length : 0;

    var usDomesticCount = usDomesticcount + lumpDomesticcount;

    //university non us domestic

    var nonusDomestic = apiData.filter((obj) => obj.RelocationTypeId === 31);
    var nonusDomesticCount = nonusDomestic != null ? nonusDomestic.length : 0;

    //University	US Domestic MBA
    var lumpMBA = apiData.filter((obj) => obj.RelocationTypeId === 25);
    var lumpMBACount = lumpMBA != null ? lumpMBA.length : 0;

    var usDomesticMBA = apiData.filter((obj) => obj.RelocationTypeId === 13);
    var usDomesticMBACount = usDomesticMBA != null ? usDomesticMBA.length : 0;

    var unversityMBACount = lumpMBACount + usDomesticMBACount;

    //University	US Domestic or MBA

    var usDomesticMBANew = apiData.filter((obj) => obj.RelocationTypeId === 39);
    var usDomesticMBANewCount = usDomesticMBANew != null ? usDomesticMBANew.length : 0;

    var unversityMBANewCount = usDomesticMBANewCount;

    //US Inbound
    var inboundLump = apiData.filter((obj) => obj.RelocationTypeId === 28);
    var inboundLumpCount = inboundLump != null ? inboundLump.length : 0;

    var inboundGlobal = apiData.filter((obj) => obj.RelocationTypeId === 18);
    var inboundGlobalCount = inboundGlobal != null ? inboundGlobal.length : 0;

    var inboundCount = inboundLumpCount + inboundGlobalCount;

    //Non US Global
    var nonUsGlobal = apiData.filter((obj) => obj.RelocationTypeId === 34);
    var nonUsGlobalCount = nonUsGlobal != null ? nonUsGlobal.length : 0;

    //university total count
    var universityTotal =
        usDomesticCount +
        nonusDomesticCount +
        unversityMBACount +
        unversityMBANewCount +
        inboundCount +
        nonUsGlobalCount;

    //Intern
    var usIntern = apiData.filter((obj) => obj.RelocationTypeId === 19);
    var usInternCount = usIntern != null ? usIntern.length : 0;

    var usGarage = apiData.filter((obj) => obj.RelocationTypeId === 37);
    var usGarageCount = usGarage != null ? usGarage.length : 0;
    usInternCount += usGarageCount;

    var usInternVirtual = apiData.filter((obj) => obj.RelocationTypeId === 52);
    var usInternVirtualCount = usInternVirtual != null ? usInternVirtual.length : 0;
    usInternCount += usInternVirtualCount;

    var nonUsIntern = apiData.filter((obj) => obj.RelocationTypeId === 35);
    var nonUsInternCount = nonUsIntern != null ? nonUsIntern.length : 0;

    var nonUsInternVirtual = apiData.filter((obj) => obj.RelocationTypeId === 53);
    nonUsInternCount += nonUsInternVirtual != null ? nonUsInternVirtual.length : 0;

    var internCount = usInternCount + nonUsInternCount;


    // ----- create tile data object -----
    const objItems: TileData[] = [];

    // Industry
    let industryObj = {
        header: 'University FTE',
        totalCount: universityTotal,
        child1: {
            totalCountData: 8,
            relTypeDataLabelId: 2,
            relTypeData1: usDomesticCount + '  US Domestic',
        },
        child: [
            {
                relTypeDataLabelId: 5,
                relTypeData: unversityMBACount + '  US Domestic MBA',
            },
            {
                relTypeDataLabelId: 10,
                relTypeData: unversityMBANewCount + '  US Domestic or MBA',
            },
            {
                relTypeDataLabelId: 4,
                relTypeData: inboundCount + '  US Inbound',
            },
            {
                relTypeDataLabelId: 1,
                relTypeData: nonusDomesticCount + '  Non US Domestic',
            },
            {
                relTypeDataLabelId: 3,
                relTypeData: nonUsGlobalCount + '  Non US Global',
            },
        ],
    };
    objItems.push(industryObj);

    // Global
    let internObj = {
        header: 'Intern',
        totalCount: internCount,
        child1: {
            totalCountData: 9,
            relTypeDataLabelId: 6,
            relTypeData1: usInternCount + '  US Internship',
        },
        child: [
            {
                relTypeDataLabelId: 7,
                relTypeData: nonUsInternCount + '  Non US Internship',
            },
        ],
    };
    objItems.push(internObj);

    return objItems;
};
