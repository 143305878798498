import { IColumn } from '@fluentui/react';

export const PendingExceptionColumns: IColumn[] = [
    {
        key: 'ActionDueDate',
        name: 'ACTION DUE DATE',
        fieldName: 'ActionDueDate',
        minWidth: 150,
        maxWidth: 150,
    },
    {
        key: 'EmployeeName',
        name: 'EMPLOYEE NAME',
        fieldName: 'EmployeeName',
        minWidth: 150,
        maxWidth: 150,
    },
    {
        key: 'JustificationForException',
        name: 'JUSTIFICATION FOR EXCEPTION',
        fieldName: 'JustificationForException',
        minWidth: 150,
        maxWidth: 150,
    },
    {
        key: 'ExceptionCategory',
        name: 'EXCEPTION CATEGORY',
        fieldName: 'ExceptionCategory',
        minWidth: 150,
        maxWidth: 150,
    },
    {
        key: 'CostOfExceptionBeyondPolicy',
        name: 'EXCEPTION AMOUNT',
        fieldName: 'CostOfExceptionBeyondPolicy',
        minWidth: 150,
        maxWidth: 150,
    },
    {
        key: 'ExceptionTotal',
        name: 'TOTAL EXCEPTION AMOUNT',
        fieldName: 'ExceptionTotal',
        minWidth: 150,
        maxWidth: 150,
    },
    {
        key: 'FileID',
        name: 'FILEID',
        fieldName: 'FileID',
        minWidth: 150,
        maxWidth: 150,
    },
];
