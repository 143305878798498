import { ComponentContext } from '@employee-experience/common';
import * as React from 'react';
import { ExceptionQueueService } from './Components/Services/ExceptionQueueService';
import { LoginService } from './Components/Services/LoginService';
import { QueueService } from './Components/Services/QueueSerivce';
import { ReassignRecordsService } from './Components/Services/ReassignRecordsService';
import { ReviewFormService } from './Components/Services/ReviewFormService';
import { UploadActualsReportService } from './Components/Services/UploadActualsReportService';
import { UploadReassignReportService } from './Components/Services/UploadReassignReportService';
import { EstimatesQueueService } from './Components/Services/EstimatesQueueService';
import { EstimatesFormService } from './Components/Services/EstimatesFormService';
import { E2ALiabilityTrendService } from './Components/Services/E2ALiabilityTrendService';
import { PendingExceptionQueueQueueService } from './Components/Services/PendingExceptionQueueService';
import { PendingExceptionQueueApprovalQueueService } from './Components/Services/PendingExceptionApprovalService';
import { StartNewReloService } from './Components/Services/StartNewReloService';
import { ExportToWebJEService } from './Components/Services/ExportToWebJEService';
import { SentToFileSetupQueueService } from './Components/Services/SentToFileSetupQueueService';

type RootServiceContextValue = {
    loginService: LoginService;
    reviewFormService: ReviewFormService;
    estimatesFormService: EstimatesFormService;
    relocationQueueService: QueueService;
    exceptionQueueService: ExceptionQueueService;
    uploadActualsReport: UploadActualsReportService;
    uploadReassignReportService: UploadReassignReportService;
    reassignRecordsService: ReassignRecordsService;
    estimatesQueueService: EstimatesQueueService;
    e2ALiabilityTrendService: E2ALiabilityTrendService;
    pendingExceptionQueueService: PendingExceptionQueueQueueService;
    pendingExceptionQueueApprovalQueueService: PendingExceptionQueueApprovalQueueService;
    startNewReloRequestService: StartNewReloService;
    exportToWebJEService: ExportToWebJEService;
    sentToFileSetupQueueService: SentToFileSetupQueueService;
};
export const RootStateContext = React.createContext<RootServiceContextValue>({} as RootServiceContextValue);

export const ServiceProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    //register the httpClient here so that it is available across your api calls
    const { httpClient } = React.useContext(ComponentContext);
    //create objects for your service
    const loginService = new LoginService(httpClient);
    const reviewFormService = new ReviewFormService(httpClient);
    const estimatesFormService = new EstimatesFormService(httpClient);
    const relocationQueueService = new QueueService(httpClient);
    const uploadActualsReport = new UploadActualsReportService(httpClient);
    const uploadReassignReportService = new UploadReassignReportService(httpClient);
    const exceptionQueueService = new ExceptionQueueService(httpClient);
    const reassignRecordsService = new ReassignRecordsService(httpClient);
    const estimatesQueueService = new EstimatesQueueService(httpClient);
    const e2ALiabilityTrendService = new E2ALiabilityTrendService(httpClient);
    const pendingExceptionQueueService = new PendingExceptionQueueQueueService(httpClient);
    const pendingExceptionQueueApprovalQueueService = new PendingExceptionQueueApprovalQueueService(httpClient);
    const startNewReloRequestService = new StartNewReloService(httpClient);
    const exportToWebJEService = new ExportToWebJEService(httpClient);
    const sentToFileSetupQueueService = new SentToFileSetupQueueService(httpClient);
    return (
        <RootStateContext.Provider
            value={{
                loginService,
                reviewFormService,
                estimatesFormService,
                relocationQueueService,
                exceptionQueueService,
                uploadActualsReport,
                uploadReassignReportService,
                reassignRecordsService,
                estimatesQueueService,
                e2ALiabilityTrendService,
                pendingExceptionQueueService,
                pendingExceptionQueueApprovalQueueService,
                startNewReloRequestService,
                exportToWebJEService,
                sentToFileSetupQueueService,
            }}
        >
            {children}
        </RootStateContext.Provider>
    );
};

export const useServiceStore = () => React.useContext(RootStateContext);
