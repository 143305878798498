import { IHttpClient } from "@employee-experience/common";
import { IHttpClientResult } from "@employee-experience/common/lib/Models";
import { IReassignValues } from '../../Models/IReAssignRecords';

export class UploadReassignReportService {
    private httpClient: IHttpClient;
    private url: string;

    public constructor(httpClient: IHttpClient) {
        this.httpClient = httpClient;
        this.url = REACT_APP_RELO_API_URL;
    }


    public async UploadReassignReport(data: IReassignValues[]): Promise<string> {


        const request: IHttpClientResult<string> = await this.httpClient.request({
            url: `${this.url}api/reloform/ReAssignBulkRecords`,
            method:'POST',
            resource: __CLIENT_ID__,
            data:data
        });
        return request?.data;
    }
}