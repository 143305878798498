import {
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    Dropdown,
    IDropdownOption,
    IStackItemStyles,
    IStackTokens,
    Panel,
    PanelType,
    PrimaryButton,
    Spinner,
    SpinnerSize,
    Stack,
    StackItem,
    Text,
    TextField,
} from '@fluentui/react';
import * as React from 'react';
import { useServiceStore } from '../../RootStateContext';
import { NewAuthForm } from '../NewAuthForm/NewAuthForm';
import { Redirect } from 'react-router-dom';
import { IUserRoles } from '../../Models/IUserRoles';
import { ReduxContext } from '@employee-experience/common';
import { IAppState } from '../../Common/Redux/AppState';
import { INewAuthForm } from '../../Models/INewRelocationRequest';

export function StartNewRelocation(): React.ReactElement {
    const { useSelector } = React.useContext(ReduxContext);
    const [isPanelOpen, setisPanelOpen] = React.useState<boolean>(true);
    const [showIntershipType, setshowIntershipType] = React.useState<boolean>(false);
    const [showIDDropDown, setshowIDDropDown] = React.useState<boolean>(false);
    const [showIDTextField, setshowIDTextField] = React.useState<boolean>(false);
    const [disableIDTextField, setdisableIDTextField] = React.useState<boolean>(true);
    const [showReviewFormModal, setShowReviewFormModal] = React.useState<boolean>(false);
    const [IDTypeLength, setIDTypeLength] = React.useState<number>(10);
    const [IDNumber, setIDNumber] = React.useState<string>('');
    const [selectedIDType, setselectedIDType] = React.useState<string>('');
    const [hireTypeOptions, sethireTypeOptions] = React.useState<IDropdownOption[]>([]);
    const [selectedHireType, setselectedHireType] = React.useState<string>('');
    const [dropDownValue, setdropDownValue] = React.useState<string>('Select One');
    const [selectedInternshipType, setselectedInternshipType] = React.useState<string>('');
    const [errorMessage] = React.useState<string>('Please enter the correct ID details to proceed further');
    const [showErrMsg, setshowErrMsg] = React.useState<boolean>(false);
    const [internshipOptions, setinternshipOptions] = React.useState<IDropdownOption[]>([]);
    const [hideDialog, setHideDialog] = React.useState<boolean>(true);
    const [existingReloMsg, setExistingReloMsg] = React.useState<string>('');
    const userdata: IUserRoles = useSelector((state: IAppState) => state.root.userReducer.userRoles);

    const [showhireTypeValidationMsg, setshowhireTypeValidationMsg] = React.useState<boolean>(false);
    const [showIntershipTypevalidationMsg, setshowIntershipTypevalidationMsg] = React.useState<boolean>(false);
    const [showIDTypevalidationMsg, setshowIDTypevalidationMsg] = React.useState<boolean>(false);
    const [showIDvalidationMsg, setshowIDvalidationMsg] = React.useState<boolean>(false);
    const [showRequiredField, setshowRequiredField] = React.useState<boolean>(true);
    const [loading, setLoading] = React.useState<boolean>(true);

    const modalPropsStyles = { main: { maxWidth: 450 } };
    const dialogContentProps = {
        type: DialogType.normal,
        title: 'Information',
        subText: existingReloMsg,
        Icon: 'information',
    };
    const modalProps = React.useMemo(
        () => ({
            isBlocking: true,
            styles: modalPropsStyles,
        }),
        []
    );

    const [formData, setFormData] = React.useState<INewAuthForm[]>([]);

    const { reviewFormService, startNewReloRequestService } = useServiceStore();
    let result: INewAuthForm[] = [];

    const actionButtonStyles: IStackItemStyles = {
        root: {
            marginTop: 28,
        },
    };

    const verticalGapStackTokens: IStackTokens = {
        childrenGap: 10,
        padding: 10,
    };

    const _onIDNumberChange = React.useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            let numberPattern = new RegExp(/^[0-9]+$/);
            if (newValue !== '' || newValue !== undefined || newValue !== null) {
                if (newValue?.match(numberPattern)) {
                    setIDNumber(newValue || '');
                    setshowIDvalidationMsg(false);
                } else {
                    setIDNumber('');
                }
            }
        },
        []
    );

    const dropdownOptions: IDropdownOption[] = [
        { key: 'SelectOne', text: 'Select One' },
        { key: 'candidate', text: 'Candidate ID' },
        { key: 'employee', text: 'Employee ID' },
    ];

    React.useEffect(() => {
        (async function getReloformData() {
            await getHireInfo();
            setLoading(false);
        })();
    }, []);

    async function getHireInfo() {
        let hiretypeoptions: IDropdownOption[] = [];
        let internshipoptions: IDropdownOption[] = [];
        const hireTypeResponse = await reviewFormService.getHireTypeService();
        hireTypeResponse.forEach((item) => {
            if (item.HireTypeID != 5 && item.HireTypeID != 6)
                hiretypeoptions.push({
                    key: item.HireTypeID,
                    text: item.HireTypeName,
                });
            if (item.HireTypeID == 6)
                hiretypeoptions.push({
                    key: item.HireTypeID,
                    text: 'Executive/ M&A',
                });
        });
        sethireTypeOptions(hiretypeoptions);

        const internshipTypeResponse = await reviewFormService.GetInternshipTypes();
        internshipTypeResponse.forEach((item) => {
            if (item.InternshipTypeID != 9 && item.InternshipTypeID != 10)
                internshipoptions.push({ key: item.InternshipTypeID, text: item.InternshipTypeName });
        });
        setinternshipOptions(internshipoptions);
    }

    React.useEffect(() => {
        let btn = document.querySelectorAll('button');
        btn.forEach((item)=>{
            if(item.getAttribute('type')=='button')
            {
                if(item.getAttribute('aria-label')=='Close New Relo Panel')
                {                    
                    item.setAttribute('role','button');
                }
            }
            if(item.getAttribute('aria-hidden')=='true')
                {
                    item.setAttribute('disabled','true');
                }
        })      
    });

    return (
        <>
            {loading && (
                <Stack>
                    <div>
                        <Spinner
                            label="Loading, please wait..."
                            size={SpinnerSize.large}
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                height: '100%',
                                width: '100%',
                                zIndex: 9999999,
                                backgroundColor: 'rgb(255, 255, 255, 255)',
                            }}
                        />
                    </div>
                </Stack>
            )}
            {showReviewFormModal && (
                <NewAuthForm
                    isModalOpen={showReviewFormModal}
                    setShowReviewFormModal={setReviewModalParam}
                    newAuthFormData={formData}
                    hireTypeOption={selectedHireType}
                />
            )}

            <Dialog
                hidden={hideDialog}
                onDismiss={() => {
                    setHideDialog(true);
                }}
                dialogContentProps={dialogContentProps}
                modalProps={modalProps}
            >
                <DialogFooter>
                    <PrimaryButton onClick={setNewAuthValues} text="Confirm" />
                    <DefaultButton
                        onClick={() => {
                            setHideDialog(true);
                        }}
                        text="Cancel"
                    />
                </DialogFooter>
            </Dialog>
            <Panel
                type={PanelType.smallFixedFar}
                headerText={'New Relocation Request'}
                isOpen={isPanelOpen}
                onDismiss={_closePanel}
                isBlocking={false}
                hasCloseButton={true}
                isLightDismiss={true}
                closeButtonAriaLabel="Close New Relo Panel"
            >
                <Stack>
                    <Stack.Item>
                        <Dropdown
                            label="Hire Type"
                            options={hireTypeOptions}
                            selectedKey={selectedHireType}
                            onChange={onHireTypeChange}
                            role="combobox"
                            required
                        ></Dropdown>
                        {showhireTypeValidationMsg && (
                            <Text variant="smallPlus" style={{ color: 'red' }}>
                                Please select the hire type
                            </Text>
                        )}
                    </Stack.Item>
                    {showIntershipType && (
                        <>
                            <Stack.Item>
                                <Dropdown
                                    label="Internship Type"
                                    options={internshipOptions}
                                    selectedKey={selectedInternshipType}
                                    onChange={onIntershipChange}
                                    role="combobox"
                                    required
                                ></Dropdown>
                                {showIntershipTypevalidationMsg && (
                                    <Text variant="smallPlus" style={{ color: 'red' }}>
                                        Please select the internship type
                                    </Text>
                                )}
                            </Stack.Item>
                        </>
                    )}
                    {showIDDropDown && (
                        <Stack.Item>
                            <Dropdown
                                label="Please select ID (Employee ID is preferred if available)"
                                options={dropdownOptions}
                                selectedKey={dropDownValue}
                                onChange={onDropdownChange}
                                role="combobox"
                                required={showRequiredField}
                            ></Dropdown>
                            {showIDTypevalidationMsg && (
                                <Text variant="smallPlus" style={{ color: 'red' }}>
                                    Please select the id type
                                </Text>
                            )}
                        </Stack.Item>
                    )}
                    {showIDTextField && (
                        <Stack.Item>
                            <TextField
                                label="Please enter ID below"
                                defaultValue={''}
                                value={IDNumber}
                                onChange={_onIDNumberChange}
                                disabled={disableIDTextField}
                                placeholder={'Please provide ID Number'}
                                maxLength={IDTypeLength}
                                role="textbox"
                                required={showRequiredField}
                            />
                        </Stack.Item>
                    )}
                    {showIDvalidationMsg && (
                        <Text variant="smallPlus" style={{ color: 'red' }}>
                            Please enter the id
                        </Text>
                    )}
                    {showErrMsg && (
                        <Text variant="medium" style={{ color: 'red' }}>
                            {errorMessage}
                        </Text>
                    )}
                </Stack>
                <Stack horizontal={true} tokens={verticalGapStackTokens}>
                    <StackItem>
                        <DefaultButton styles={actionButtonStyles} text="OK" onClick={_onOkClick} />
                    </StackItem>
                    <StackItem>
                        <DefaultButton styles={actionButtonStyles} text="Cancel" onClick={_closePanel} />
                    </StackItem>
                </Stack>
                {!isPanelOpen && <Redirect to={'/MyReloQueue'}></Redirect>}
            </Panel>
        </>
    );
    function _closePanel() {
        setisPanelOpen(false);
    }

    async function _onOkClick() {
        let isValid = await validateNewAuthorization();
        if (isValid) {
            setLoading(true);
            if (selectedIDType.toLowerCase() === 'candidate') {
                let existingReloIDResult = await startNewReloRequestService.GetReloForms(parseInt(IDNumber));
                if (existingReloIDResult.iReloids !== null && existingReloIDResult.iReloids !== 0) {
                    setExistingReloMsg(
                        `A relocation form already exist with the same candidate id: ${IDNumber} created by: ${existingReloIDResult.CreatedBy}. Do you want to create a new form?`
                    );
                    setLoading(false);
                    setHideDialog(false);
                } else {
                    setNewAuthValues();
                    setHideDialog(true);
                }
            } else {
                await setNewAuthValues();
            }
        }
    }

    async function setNewAuthValues() {
        setLoading(true);
        if (selectedHireType != null && selectedHireType != '' && selectedHireType !== undefined &&
        IDNumber != '' && !isNaN(Number(IDNumber)) && selectedIDType != '') {
            result = await startNewReloRequestService.GetEmployeeDetails(selectedIDType, IDNumber);
            if (result.length == 0) {
                setshowErrMsg(true);
                setHideDialog(true);
                setLoading(false);
            }

            if (result != null) {
                result[0].EstimatedStartDate = new Date().toString();
            }
            if (parseInt(selectedHireType) == 3) result[0].HiringManagerAlias = '';

            loadFormData(selectedHireType, selectedInternshipType);

            if (userdata.Alias != null) {
                result[0].HRRecruitingAssociate = userdata.Alias;
            }
        } else {
            let result1 = await startNewReloRequestService.GetEmployeeDetailsForMergerNAcquisition();
            result = Array(result1);
            result[0].HireTypeID = parseInt(selectedHireType);

            loadFormData(selectedHireType, selectedInternshipType);
        }
        setFormData(result);
        setHideDialog(true);
        setLoading(false);
        setShowReviewFormModal(true);
        setshowErrMsg(false);
    }

    function loadFormData(SelectedHireType: string, InternshipTypeID: string) {
        if (SelectedHireType != null && typeof SelectedHireType != 'undefined') {
            result[0].HireTypeID = parseInt(selectedHireType);
        }

        if (InternshipTypeID != null && typeof InternshipTypeID != 'undefined') {
            result[0].InternshipTypeID = parseInt(InternshipTypeID);
        }

        changeHireType();
    }

    function changeHireType() {
        if (isNotNullOrUndefined(result[0].HireTypeID))
            if (result[0].ReloID == 0 && result[0].HireTypeID == 4) {
                result[0].DepartureStatus = 'Renter';
            } else if (result[0].ReloID == 0 && result[0].HireTypeID != 4) result[0].DepartureStatus = '';
        if (result[0].HireTypeID == 6 || result[0].HireTypeID == 7) {
        }
    }

    function onHireTypeChange(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void {
        setselectedIDType('');
        setselectedInternshipType('');
        setIDNumber('');
        setIDTypeLength(0);
        setdropDownValue('Select One')
        if (option!.key === 1 || option!.key === 2 || option!.key === 3 || option!.key === 8) {
            setshowIDDropDown(true);
            setshowIDTextField(true);
            setshowIntershipType(false);
        }
        if (option!.key === 6) {
            setshowIDDropDown(true);
            setshowIDTextField(true);
            setshowIntershipType(false);
            setshowRequiredField(false);
        }
        else{
            setshowRequiredField(true);
        }

        if (option!.key === 4) {
            setshowIntershipType(true);
            setshowIDDropDown(true);
            setshowIDTextField(true);
        }
        if (option!.key === 5 || option!.key === 7) {
            setshowIntershipType(false);
            setshowIDDropDown(false);
            setshowIDTextField(false);
        }

        setselectedHireType(option!.key as string);
        setshowhireTypeValidationMsg(false);
    }

    function onDropdownChange(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void {
        if (option!.text === 'Select One') {
            setdisableIDTextField(true);
            setIDTypeLength(10);
        } else {
            if (option!.key === 'candidate') {
                setselectedIDType(option?.key || 'Select One');
                setIDTypeLength(12);
            }
            if (option!.key === 'employee') {
                setselectedIDType(option?.key || 'Select One');
                setIDTypeLength(8);
            }
            setdisableIDTextField(false);
            setshowIDTypevalidationMsg(false);
        }
        setdropDownValue(option!.key as string);
    }

    async function validateNewAuthorization(): Promise<boolean> {
        let isValid: boolean = true;
        if (selectedHireType === '') {
            setshowhireTypeValidationMsg(true);
            isValid = false;
        } else setshowhireTypeValidationMsg(false);

        if (selectedInternshipType === '' && selectedHireType !== '') {
            if (parseInt(selectedHireType) === 4) {
                setshowIntershipTypevalidationMsg(true);
                isValid = false;
            }
        } else setshowIntershipTypevalidationMsg(false);

        if (selectedHireType !== '' && selectedInternshipType !== '' && selectedIDType === '') {
            if (parseInt(selectedHireType) != 6 && parseInt(selectedHireType) != 7) {
                setshowIDTypevalidationMsg(true);
                isValid = false;
            }
        } else if (selectedHireType !== '') {
            if (selectedIDType === 'Select One' && parseInt(selectedHireType) != 6 && parseInt(selectedHireType) != 7) {
                setshowIDTypevalidationMsg(true);
                isValid = false;
            } else setshowIDTypevalidationMsg(false);
        } else setshowIDTypevalidationMsg(false);

        if (IDNumber === '' && selectedHireType !== '') {
            if (parseInt(selectedHireType) != 6 && parseInt(selectedHireType) != 7) {
                setshowIDvalidationMsg(true);
                isValid = false;
            }
        } else setshowIDvalidationMsg(false);

        return isValid;
    }

    function onIntershipChange(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void {
        setselectedInternshipType(option!.key as string);
        setshowIntershipTypevalidationMsg(false);
    }

    function setReviewModalParam() {
        setShowReviewFormModal(false);
    }

    function isNotNullOrUndefined(obj: any) {
        if (obj !== undefined && obj !== null) return true;
        else return false;
    }
}
