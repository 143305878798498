import * as React from 'react';
import { DetailsList, IColumn, SelectionMode } from '@fluentui/react';
import { ExceptionMapping, ExceptionsEntity } from '../../Models/IReviewForm';

type ExceptionType = {
    exceptionName: string;
    precedingString: string;
    unitOrAmount: string;
};

type ExceptionProps = {
    exceptionMapping: ExceptionMapping[];
    reloTypeId: number;
    exceptionResponse: ExceptionsEntity[];
};

const column: IColumn[] = [
    {
        key: 'exceptionName',
        name: 'Exceptions',
        fieldName: 'exceptionName',
        maxWidth: 400,
        minWidth: 250,
        ariaLabel: 'Column for name of exception',
        columnActionsMode: 1,
        isCollapsable: false,
        isCollapsible: false,
        isGrouped: false,
        isMultiline: false,
        isResizable: undefined,
        isRowHeader: false,
        isSorted: false,
        isSortedDescending: false,
        onColumnClick: undefined,
    },
    {
        key: 'precedingString',
        name: '',
        fieldName: 'precedingString',
        maxWidth: 180,
        minWidth: 50,
        ariaLabel: 'Column for unit preceding string',
        columnActionsMode: 1,
        isCollapsable: false,
        isCollapsible: false,
        isGrouped: false,
        isMultiline: false,
        isResizable: undefined,
        isRowHeader: false,
        isSorted: false,
        isSortedDescending: false,
        onColumnClick: undefined,
    },
    {
        key: 'unitOrAmount',
        name: 'Units/Amount',
        fieldName: 'unitOrAmount',
        maxWidth: 200,
        minWidth: 50,
        ariaLabel: 'Column for unit of exception',
        columnActionsMode: 1,
        isCollapsable: false,
        isCollapsible: false,
        isGrouped: false,
        isMultiline: false,
        isResizable: undefined,
        isRowHeader: false,
        isSorted: false,
        isSortedDescending: false,
        onColumnClick: undefined,
    },
];

export const ExceptionsList: React.FC<ExceptionProps> = (props) => {
    const [gridExceptions, setGridExcepions] = React.useState<any[]>([]);
    const [items, setItems] = React.useState<ExceptionType[]>([]);
    const [columns, setColumns] = React.useState<IColumn[]>(column);

    React.useEffect(() => {
        if (props.exceptionMapping.length > 0 && props.exceptionResponse.length > 0) {
            getExceptions();
        }
    }, [props.exceptionMapping]);

    const isNotNullOrUndefined = (obj: any): boolean => {
        if (obj !== undefined && obj !== null) return true;
        else return false;
    };

    const getExceptions = async (): Promise<void> => {
        let allExceptions = props.exceptionResponse;
        if (props.exceptionMapping != null && typeof props.exceptionMapping != 'undefined') {
            let gridExceptions = [];
            for (let exc of props.exceptionMapping as any) {
                let selectedExceptionID = exc.ExceptionID;
                let selectedException = allExceptions.filter((obj: any) => obj.ExceptionID == selectedExceptionID);
                if (isNotNullOrUndefined(selectedException[0])) {
                    selectedException[0].Amount = exc.Amount;
                    selectedException[0].Unit = exc.Unit;
                    gridExceptions.push(selectedException[0]);
                }
            }
            setGridExcepions(gridExceptions);
        }
    };

    React.useEffect(() => {
        if (gridExceptions.length > 0) mapExceptions();
    }, [gridExceptions]);

    React.useEffect(() => {
        let btn = document.querySelectorAll('button');
        let inp = document.querySelectorAll('input');
        inp.forEach((item)=>{
            if(item.getAttribute('type')=='text')
            {
                if(!(item.hasAttribute('aria-expanded')||item.hasAttribute('aria-checked')))
                {                    
                  item.setAttribute('role','textbox');
                }
            }
        })
        btn.forEach((item)=>{
            if(item.getAttribute('type')=='button')
            {
                if(!(item.hasAttribute('aria-expanded')||item.hasAttribute('aria-checked')))
                {                    
                    item.setAttribute('role','button');
                }
            }
            if(item.getAttribute('aria-hidden')=='true')
                {
                    item.setAttribute('disabled','true');
                }
        })      
    });

    const mapExceptions = () => {
        let exceptions: ExceptionType[] = [];
        for (let exception of gridExceptions as any) {
            let midColumnContent = '';
            let lastColumnContent = '';

            if (exception.UnitType != null && exception.UnitType != undefined) {
                if (exception.UnitType == 'Free Field' || exception.UnitType == 'Unit/per hour') {
                    if (
                        props.reloTypeId == 2 ||
                        props.reloTypeId == 4 ||
                        props.reloTypeId == 5 ||
                        props.reloTypeId == 7
                    ) {
                        midColumnContent = '$';
                    } else if (
                        props.reloTypeId == 1 ||
                        props.reloTypeId == 3 ||
                        props.reloTypeId == 6 ||
                        props.reloTypeId == 8
                    ) {
                        midColumnContent = '(In local currency)';
                    }
                }

                if (exception.UnitType == 'Unit') {
                    lastColumnContent = exception.Unit;
                } else if (exception.UnitType == 'Free Field' || exception.UnitType == 'Unit/per hour') {
                    lastColumnContent = exception.Amount;
                } else if (exception.UnitType == 'N/A') {
                    lastColumnContent = 'Per values provided in Cost Estimate';
                }

                let obj = {
                    exceptionName: exception.ExceptionName,
                    precedingString: midColumnContent,
                    unitOrAmount: lastColumnContent,
                };
                exceptions.push(obj);
            }
        }
        setItems(exceptions);
        setColumns(columns);
    };

    return <DetailsList items={items} columns={columns} selectionMode={SelectionMode.none}  ariaLabelForGrid="Exceptions List" />;
};
