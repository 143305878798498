import {
    CoherencePageSize,
    CoherencePagination,
    ICoherencePageSizeProps,
    ICoherencePaginationProps,
} from '@cseo/controls/lib/pagination';
import { ReduxContext } from '@employee-experience/common';
import { usePageTitle } from '@employee-experience/common/lib/usePageTitle';
import {
    Checkbox,
    PrimaryButton,
    DetailsListLayoutMode,
    DetailsRow,
    Dropdown,
    getTheme,
    IColumn,
    IComboBoxOption,
    IDetailsRowBaseProps,
    IDetailsRowStyles,
    IDropdownOption,
    IScrollablePaneStyles,
    IStackItemStyles,
    IStackTokens,
    mergeStyleSets,
    ScrollablePane,
    SearchBox,
    SelectionMode,
    ShimmeredDetailsList,
    Stack,
    Text,
} from '@fluentui/react';
import * as React from 'react';
import { IAppState } from '../../Common/Redux/AppState';
import { IExceptionQueueForm } from '../../Models/IExceptionQueue';
import { ILoggedInUserRoles, IUserRoles } from '../../Models/IUserRoles';
import { useServiceStore } from '../../RootStateContext';
import { ExceptionQueueColumns, ExportExceptionQueueColumns } from './ExceptionQueueColumns';
import { ExportToExcel } from '../../Components/Shared/ExportToExcel';
import { LoadingSpinner } from '../../Components/Shared/LoadingSpinner';
import { ReloFormStatusTypes } from '../../types/ReloFormTypes';
import { UnAuthorized } from '../../Components/QueueTable/Unauthorized';
import { DatePicker } from 'office-ui-fabric-react';
import { getStyles } from '../../Components/Shared/FilterPane.styles';
import { StackDisclaimer } from '../../Components/Shared/DisclaimerText';
import * as moment from 'moment';

export interface checkBoxOptions {
    optionName: string;
    checked: boolean | undefined;
}

export function ExceptionQueue(): React.ReactElement {
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const { exceptionQueueService } = useServiceStore();
    const [exceptionQueueColumns, setExceptionQueueColumns] = React.useState<IColumn[]>([]);
    const [exceptionQueuePage, setExceptionQueuePage] = React.useState<IExceptionQueueForm[]>([]);
    const [exceptionQueue, setExceptionQueue] = React.useState<IExceptionQueueForm[]>([]);
    const [masterExceptionQueue, setMasterExceptionQueue] = React.useState<IExceptionQueueForm[]>([]);

    // user data needed to get and view exception queue
    const { useSelector } = React.useContext(ReduxContext);
    const userData: IUserRoles = useSelector((state: IAppState) => state.root.userReducer.userRoles);
    const userRole: ILoggedInUserRoles = useSelector((state: IAppState) => state.root.userReducer.loggedInUserRoles);

    // dates for fetching master exception queue
    let date = new Date();
    const [fromDate, setFromDate] = React.useState<Date | null | undefined>(new Date(date.getFullYear() - 1, date.getMonth(), date.getDate()));
    const [toDate, setToDate] = React.useState<Date | null | undefined>(date);

    // Variables for searching
    const [selectedDropdownOption, setSelectedDropdownOption] = React.useState<string>('Select One');
    const [searchKeyword, setSearchKeyword] = React.useState<string>('');

    // Filters for exception queue
    const [selectedReloTypeFilters, setSelectedReloTypeFilters] = React.useState<string[]>([]);
    const [selectedBusinessGroupFilters, setSelectedBusinessGroupFilters] = React.useState<string[]>([]);
    const [selectedStatusFilters, setSelectedStatusFilters] = React.useState<string[]>([]);
    const [selectedFromDateFilter, setSelectedFromDateFilter] = React.useState<Date | null | undefined>(undefined);
    const [selectedToDateFilter, setSelectedToDateFilter] = React.useState<Date | null | undefined>(undefined);

    // Check box selections pulled from selected check boxes
    const [reloTypeCheckBoxOptions, setReloTypeCheckBoxOptions] = React.useState<checkBoxOptions[]>([]);
    const [businessGroupCheckBoxOptions, setBusinessGroupCheckBoxOptions] = React.useState<checkBoxOptions[]>([]);
    const [statusCheckBoxOptions, setStatusCheckBoxOptions] = React.useState<checkBoxOptions[]>([]);

    // Check box selections pulled from master queue
    const [defaultReloTypeOptions, setDefaultReloTypeOptions] = React.useState<string[]>([]);
    const [defaultBusinessGroupOptions, setDefaultBusinessGroupOptions] = React.useState<string[]>([]);
    const [defaultStatusOptions, setDefaultStatusOptions] = React.useState<string[]>([]);

    // Variables for pagination
    const DEFAULT_PAGE_SIZE = 10;
    const [selectedPage, setSelectedPage] = React.useState<number>(1);
    const [pageCount, setPageCount] = React.useState<number>(0);
    const [pageDisplayCount, setPageDisplayCount] = React.useState(DEFAULT_PAGE_SIZE);

    // Variables for styling
    const styles = getStyles();
    const theme = getTheme();

    const [exportQueueItems, setExportQueueItems] = React.useState<IExceptionQueueForm[]>([]);

    const stackItemStyles: IStackItemStyles = {
        root: {
            padding: 5,
        },
    };
    const childrenGapTokens: IStackTokens = {
        childrenGap: 5,
    };
    const spacingStackTokens: IStackTokens = {
        ...childrenGapTokens,
        padding: 't 150px',
    }
    const verticalGapStackTokens: IStackTokens = {
        ...childrenGapTokens,
        padding: 10,
    };
    const classNames = mergeStyleSets({
        wrapper: {
            height: '40vh',
            position: 'relative',
            maxHeight: 'inherit',
        },
        pane: {
            maxWidth: 'inherit',
            border: '1px solid ' + theme.palette.neutralLight,
        },
    });
    const filterHeaderStyles = {
        root: {
            marginTop: 5,
            marginBottom: 5
        }
    };
    const buttonStyles = {
        root: {
            marginTop: 10
        }
    };
    const notAuthorizedStyles = {
        fontFamily: 'Segoe UI',
        fontSize: '12px',
        selectors: {
            '.ms-DetailsRow-cell': {
                whiteSpace: 'normal',
                lineHeight: 'normal',
                alignItems: 'center',
            },
            '.ms-DetailsRow-cellTitle': {
                height: '100%',
                alignItems: 'center',
                wordWrap: 'break-word',
            },
        },
    };
    const authorizedStyles = {
        ...notAuthorizedStyles,
        color: 'blue',
    };
    const detailHeaderStyles = {
        root: {
            selectors: {
                '.ms-DetailsHeader-cell': {
                    whiteSpace: 'normal',
                    lineHeight: 'normal',
                    wordWrap: 'break-word',
                    height: '100%',
                },
                '.ms-DetailsHeader-cellTitle': {
                    height: '100%',
                    alignItems: 'center',
                    wordWrap: 'break-word',
                },
            },
            height: '80px',
        },
    };
    const scrollablePaneStyles: Partial<IScrollablePaneStyles> = { root: classNames.pane };

    const paginationProps: ICoherencePaginationProps = {
        pageCount: pageCount,
        selectedPage: selectedPage,
        previousPageAriaLabel: 'previous page',
        nextPageAriaLabel: 'next page',
        inputFieldAriaLabel: 'page number',
        onPageChange: (newPageNumber: number): void => {
            if (newPageNumber !== selectedPage) {
                setSelectedPage(newPageNumber);
                fetchData(pageDisplayCount, (newPageNumber - 1) * pageDisplayCount);
            }
        }
    };

    const paginationPageSizeProps: ICoherencePageSizeProps = {
        pageSize: pageDisplayCount,
        pageSizeList: [
            { key: 10, text: '10' },
            { key: 20, text: '20' },
            { key: 50, text: '50' },
            { key: 100, text: '100' },
            { key: 200, text: '200' },
            { key: 300, text: '300' },
            { key: 400, text: '400' },
            { key: 500, text: '500' },
        ] as IComboBoxOption[],
        comboBoxAriaLabel: 'select page size',
        onPageSizeChange: (newPageSize: string | number): void => {
            setPageDisplayCount(Number(newPageSize));
            setSelectedPage(1);
            fetchData(Number(newPageSize), 1);
        }
    };
    let pageSizeList: IComboBoxOption[] = [];
    for (let index = 10; index <= 50; index += 10) {
        if (exceptionQueue.length < index) {
            pageSizeList.push({ key: exceptionQueue.length, text: 'ALL' });
            break;
        }
        pageSizeList.push({ key: index, text: index.toString() });
    }

    const dropdownOptions = [
        { key: 'all', text: 'Select One' },
        { key: 'FileId', text: 'File ID' },
        { key: 'ReloId', text: 'Record ID' },
        { key: 'FullName', text: 'Full Name' },
        { key: 'Approver', text: 'Alias' },
    ];

    function onDropdownChange(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void {
        setSelectedDropdownOption(option!.key as string);
    }

    const currencyFormatter = (number: number | string | undefined) => {
        if (typeof number === "string") {
            number = Number(number);
        }
        if (number === undefined || number === null || isNaN(number)) {
            number = 0;
        }
        let amount = number.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
        });
        return amount;
    };

    function _copyAndSort<IExceptionQueueForm>(items: IExceptionQueueForm[], columnKey: string, isSortedDescending?: boolean): IExceptionQueueForm[] {
        const key = columnKey as keyof IExceptionQueueForm;
        return items.slice(0).sort((a: IExceptionQueueForm, b: IExceptionQueueForm) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    }

    const formatDate = (date?: Date | string) => {
        return moment(date).format('MM/DD/YYYY');
    };

    const fetchData = async (pageSize: number, newIndex: number = 0) => {
        const numRecords = exceptionQueue.length;
        const newExceptionQueuePage: IExceptionQueueForm[] = [];
        for (let exceptionQueueIndex = newIndex; exceptionQueueIndex < Math.min(pageSize + newIndex, numRecords); ++exceptionQueueIndex) {
            newExceptionQueuePage.push(exceptionQueue[exceptionQueueIndex]);
        }
        setPageCount(Math.ceil(numRecords / pageSize));
        setExceptionQueuePage(newExceptionQueuePage);
    };

    React.useEffect(() => {
        getExceptionQueue();
    }, []);

    React.useEffect(() => {
        fetchData(pageDisplayCount);
        if (exceptionQueue && exceptionQueue.length > 0) {
            let tempExportQueueItems = exceptionQueue.map((item) => {
                return {
                    ...item,
                    DateRequest: item.DateRequest ? new Date(item.DateRequest) : "",
                    DateApproved: item.DateApproved ? new Date(item.DateApproved) : "",
                    StartDate: item.StartDate ? new Date(item.StartDate) : "",
                    CommitStamp: item.CommitStamp? new Date(item.CommitStamp) :"",
                };

            })

            setExportQueueItems(tempExportQueueItems);
        } else {
            setExportQueueItems([]);
        }
    }, [exceptionQueue]);

    function setCheckBoxOptions(newDefaultOptions: string[], setOptions: Function, setDefaultOptions: Function): void {
        const newOptions: checkBoxOptions[] = [];
        if (newDefaultOptions.length > 0) {
            newDefaultOptions.sort();
            newDefaultOptions.forEach(item => newOptions.push({ optionName: item, checked: false } as checkBoxOptions));
            setOptions(newOptions);
            setDefaultOptions(newDefaultOptions);
        }
    }

    function setAllCheckBoxOptions(relocationTypes: string[], businessGroups: string[], statuses: string[]): void {
        setCheckBoxOptions(relocationTypes, setReloTypeCheckBoxOptions, setDefaultReloTypeOptions)
        setCheckBoxOptions(businessGroups, setBusinessGroupCheckBoxOptions, setDefaultBusinessGroupOptions);
        setCheckBoxOptions(statuses, setStatusCheckBoxOptions, setDefaultStatusOptions);
    }

    async function getExceptionQueue(): Promise<void> {
        if (userData.Alias !== '' && userData.Roles.length > 0) {
            var newExceptionQueue = await exceptionQueueService.getQueueRecords(toDate?.toISOString(), fromDate?.toISOString());

            const relocationTypes = newExceptionQueue
                .map((item) => item.RelocationType)
                .filter((value, index, self) => self.indexOf(value) === index);
            const businessGroups = newExceptionQueue
                .map((item) => item.BusinessGroup)
                .filter((value, index, self) => self.indexOf(value) === index)
            const statuses = newExceptionQueue
                .map((item) => item.Status)
                .filter((value, index, self) => value && self.indexOf(value) === index);
            setAllCheckBoxOptions(relocationTypes, businessGroups, statuses);

            newExceptionQueue.forEach(item => {
                if (item.CandidateID === 0) {
                    item.CandidateID = null;
                }
                item.CommitStamp = formatDate(item.CommitStamp);
                item.DateApproved = formatDate(item.DateApproved);
                item.DateRequest = formatDate(item.DateRequest)
                item.StartDate = formatDate(item.StartDate);
            })
            setExceptionQueue(newExceptionQueue);
            setMasterExceptionQueue(newExceptionQueue);
            setLoaded(true);
        }
    }

    let reloTypeContainer = reloTypeCheckBoxOptions.map((reloType) => {
        return (
            <Checkbox
                className={styles.checkbox}
                label={reloType.optionName}
                inputProps={{ value: reloType.optionName }}
                onChange={onReloTypeFilterChange}
                checked={reloType.checked}
            ></Checkbox>
        );
    });

    let businessGroupContainer = businessGroupCheckBoxOptions.map((busniessGroupType) => {
        return (
            <Checkbox
                className={styles.checkbox}
                label={busniessGroupType.optionName}
                inputProps={{ value: busniessGroupType.optionName }}
                onChange={onBusinessGroupFilterChange}
                checked={busniessGroupType.checked}
            ></Checkbox>
        );
    });

    let statusContainer = statusCheckBoxOptions.map((statusType) => {
        return (
            <Checkbox
                className={styles.checkbox}
                label={statusType.optionName}
                inputProps={{ value: statusType.optionName }}
                onChange={onStatusFilterChange}
                checked={statusType.checked}
            ></Checkbox>
        );
    });

    usePageTitle(`Exception Queue - ${__APP_NAME__}`);

    React.useEffect(() => {
        let inp = document.querySelectorAll('input');
        let btn = document.querySelectorAll('button');
        inp.forEach((item) => {
            item.setAttribute('tabindex', '0');
            if (item.getAttribute('aria-label') === "Enter Page Number") {
                item.setAttribute('role', 'textbox');
            }
            if (item.getAttribute('type') === "checkbox") {
                item.setAttribute('role', 'checkbox');
            }
        })
        btn.forEach((item) => {
            if (item.getAttribute('aria-label') === 'previous page') {
                item.setAttribute('role', 'button');
                item?.parentElement?.parentElement?.classList.add("wrap-btn");
                item?.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.classList.add("wrap-btn");
            }
            if (item.getAttribute('aria-label') === 'next page') {
                item.setAttribute('role', 'button');
            }
            if (item.getAttribute('aria-hidden') == 'true') {
                item.setAttribute('disabled', 'true');
            }
        })
    })

    return (
        <>
            {!loaded ? (
                <LoadingSpinner />
            ) : (
                <>
                    {(userRole.ReloAdmin || userRole.ReloAnalyst || userRole.ReloSpecialist) ? (
                        <Stack>
                            <Stack tokens={verticalGapStackTokens} horizontal>
                                <Stack.Item styles={{ root: { width: '75%' } }}>
                                    <Stack.Item align="auto" styles={stackItemStyles}>
                                        <h1>Exception Queue</h1>
                                    </Stack.Item>
                                    <Stack horizontal styles={stackItemStyles}>
                                        <Stack.Item align="auto" styles={stackItemStyles}>
                                            <ExportToExcel
                                                rowsData={exportQueueItems}
                                                colsData={ExportExceptionQueueColumns}
                                                fileName="ExceptionQueue.xlsx"
                                            />
                                        </Stack.Item>
                                    </Stack>
                                    <Stack.Item align="auto" styles={stackItemStyles}></Stack.Item>
                                    <>
                                        <Stack.Item align="auto" styles={stackItemStyles}>
                                            <ShimmeredDetailsList
                                                selectionMode={SelectionMode.none}
                                                items={exceptionQueuePage || []}
                                                columns={ExceptionQueueColumns}
                                                onRenderItemColumn={_renderItemColumn}
                                                onColumnHeaderClick={_onColumnClick}
                                                onRenderRow={_onRenderRow}
                                                onRenderDetailsHeader={_onRenderDetailsHeader}
                                                setKey="set"
                                                layoutMode={DetailsListLayoutMode.justified}
                                                selectionPreservedOnEmptyClick={true}
                                                ariaLabelForSelectionColumn="Toggle selection"
                                                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                                                checkButtonAriaLabel="select row"
                                                ariaLabelForGrid="Exception Queue"
                                            />
                                        </Stack.Item>
                                        {exceptionQueuePage.length === 0 &&
                                            <Stack horizontalAlign='center'>
                                                <Text>Sorry! No files found </Text>
                                            </Stack>
                                        }
                                        <Stack
                                            className="pagination-controls"
                                            horizontal
                                            horizontalAlign="end"
                                            styles={{ root: { marginTop: '10px' } }}
                                        >
                                            <Stack
                                                horizontal
                                                horizontalAlign="space-between"
                                                styles={{ root: { width: '50%' } }}
                                            >
                                                <div style={{ marginLeft: -132 }}>
                                                    <CoherencePagination
                                                        {...paginationProps}
                                                        inputFieldAriaLabel="Enter Page Number"
                                                    />
                                                </div>
                                                <CoherencePageSize
                                                    {...paginationPageSizeProps}
                                                />
                                            </Stack>
                                        </Stack>
                                    </>
                                </Stack.Item>
                                <Stack.Item tokens={spacingStackTokens} styles={stackItemStyles}>
                                    <Stack tokens={childrenGapTokens}>
                                        <Stack.Item>
                                            <Text variant="xLarge">Fetch Data</Text>
                                        </Stack.Item>
                                        <Stack.Item>
                                            <Text variant="medium"><b>Start Date Range</b></Text>
                                        </Stack.Item>
                                        <Stack.Item>
                                            <Text variant="small"><b>From Date</b></Text>
                                        </Stack.Item>
                                        <Stack.Item>
                                            <DatePicker
                                                value={fromDate ? fromDate : undefined}
                                                ariaLabel="From Date required"
                                                isRequired
                                                formatDate={formatDate}
                                                onSelectDate={(newDate) => setFromDate(newDate)}
                                            />
                                        </Stack.Item>
                                        <Stack.Item>
                                            <Text variant="small"><b>To Date</b></Text>
                                        </Stack.Item>
                                        <Stack.Item>
                                            <DatePicker
                                                value={toDate ? toDate : undefined}
                                                ariaLabel="To Date required"
                                                isRequired
                                                formatDate={formatDate}
                                                onSelectDate={(newDate) => setToDate(newDate)}
                                            />
                                        </Stack.Item>
                                        <Stack.Item>
                                            <PrimaryButton
                                                styles={buttonStyles}
                                                text={'Fetch Data'}
                                                onClick={getExceptionQueue}
                                            ></PrimaryButton>
                                        </Stack.Item>
                                        <Stack tokens={childrenGapTokens}>
                                            <Stack.Item>
                                                <Text variant="xLarge">Search this Queue</Text>
                                            </Stack.Item>
                                            <Stack.Item>
                                                <Dropdown
                                                    onChange={onDropdownChange}
                                                    selectedKey={selectedDropdownOption}
                                                    options={dropdownOptions}
                                                    ariaLabel="Search this Queue DropDown Options"
                                                    required
                                                ></Dropdown>
                                            </Stack.Item>
                                            <Stack.Item>
                                                <SearchBox
                                                    placeholder="Search"
                                                    onEscape={(ev) => { }}
                                                    onClear={(ev) => { }}
                                                    onChange={(ev, newValue) => setSearchKeyword(newValue || '')}
                                                    onSearch={searchQueue}
                                                    value={searchKeyword}
                                                />
                                            </Stack.Item>
                                            <Stack horizontal tokens={childrenGapTokens}>
                                                <Stack.Item>
                                                    <PrimaryButton
                                                        styles={buttonStyles}
                                                        text={'Search'}
                                                        onClick={searchQueue}
                                                    ></PrimaryButton>
                                                </Stack.Item>
                                                <Stack.Item>
                                                    <PrimaryButton
                                                        styles={buttonStyles}
                                                        text={'Reset Search'}
                                                        onClick={resetSearch}
                                                    />
                                                </Stack.Item>
                                            </Stack>
                                        </Stack>
                                        <Stack tokens={childrenGapTokens}>
                                            <Stack.Item>
                                                <Stack.Item>
                                                    <Text variant="xLarge">Filter This Queue</Text>
                                                </Stack.Item>
                                                <Stack.Item>
                                                    <Text
                                                        variant="medium"
                                                        styles={filterHeaderStyles}
                                                    >
                                                        <b>Filter By Relocation Type</b>
                                                    </Text>
                                                </Stack.Item>
                                                <Stack.Item className={classNames.wrapper}>
                                                    <ScrollablePane
                                                        scrollContainerFocus={true}
                                                        scrollContainerAriaLabel="Relocation Type Filter Pane"
                                                        styles={scrollablePaneStyles}
                                                    >
                                                        {reloTypeContainer}
                                                    </ScrollablePane>
                                                </Stack.Item>
                                            </Stack.Item>
                                            <Stack.Item>
                                                <Stack.Item>
                                                    <Text variant="medium" styles={filterHeaderStyles}>
                                                        <b>Filter By Business Group</b>
                                                    </Text>
                                                </Stack.Item>
                                                <Stack.Item className={classNames.wrapper}>
                                                    <ScrollablePane
                                                        scrollContainerFocus={true}
                                                        scrollContainerAriaLabel="Business Group Filter Pane"
                                                        styles={scrollablePaneStyles}
                                                    >
                                                        {businessGroupContainer}
                                                    </ScrollablePane>
                                                </Stack.Item>
                                            </Stack.Item>
                                            <Stack.Item>
                                                <Text variant="medium" styles={filterHeaderStyles}>
                                                    <b>Filter By Status</b>
                                                </Text>
                                                {statusContainer}
                                            </Stack.Item>
                                            <Stack.Item>
                                                <Text variant="medium"><b>Start Date Range</b></Text>
                                            </Stack.Item>
                                            <Stack.Item>
                                                <Text variant="small"><b>From Date</b></Text>
                                            </Stack.Item>
                                            <Stack.Item>
                                                <DatePicker
                                                    value={selectedFromDateFilter || undefined}
                                                    ariaLabel="From Date"
                                                    formatDate={formatDate}
                                                    onSelectDate={(newDate) => { setSelectedFromDateFilter(newDate); }}
                                                />
                                            </Stack.Item>
                                            <Stack.Item>
                                                <Text variant="small"><b>To Date</b></Text>
                                            </Stack.Item>
                                            <Stack.Item>
                                                <DatePicker
                                                    value={selectedToDateFilter || undefined}
                                                    ariaLabel="To Date"
                                                    formatDate={formatDate}
                                                    onSelectDate={(newDate) => { setSelectedToDateFilter(newDate); }}
                                                />
                                            </Stack.Item>
                                            <Stack.Item>
                                                <Stack horizontal tokens={childrenGapTokens}>
                                                    <Stack.Item>
                                                        <PrimaryButton
                                                            styles={buttonStyles}
                                                            text="Filter"
                                                            onClick={filterQueue}
                                                        />
                                                    </Stack.Item>
                                                    <Stack.Item>
                                                        <PrimaryButton
                                                            styles={buttonStyles}
                                                            text="Clear Filters"
                                                            onClick={resetFilter}
                                                        />
                                                    </Stack.Item>
                                                </Stack>
                                            </Stack.Item>
                                        </Stack>
                                    </Stack>
                                </Stack.Item>
                            </Stack>
                            {StackDisclaimer()}
                        </Stack>
                    ) : (
                        <UnAuthorized />
                    )}
                </>
            )}
        </>
    );

    function _onRenderDetailsHeader(headerProps: any, defaultRender: any) {
        return defaultRender({ ...headerProps, styles: detailHeaderStyles });
    }

    function _onRenderRow(props: IDetailsRowBaseProps | undefined, defaultRender: any | undefined) {
        const customStyles: Partial<IDetailsRowStyles> = {};
        if (props) {
            customStyles.root = (props.item?.Status !== ReloFormStatusTypes.AUTHORIZED) ? authorizedStyles : notAuthorizedStyles;
            return (
                <DetailsRow {...props} styles={customStyles}>
                    {defaultRender && defaultRender(props)}{' '}
                </DetailsRow>
            );
        }
        return null;
    }



    function _renderItemColumn(item: IExceptionQueueForm | undefined, index: number | undefined, column: IColumn | undefined) {
        const fieldContent = item?.[column?.fieldName as keyof IExceptionQueueForm] as string;
        switch (column?.key) {
            case 'WentInWebJE':
                if (item?.WentInWebJE) {
                    return <span>TRUE</span>;
                } else {
                    return <span>FALSE</span>;
                }
            case 'GrossUp': case 'TotalAmount': case 'ExceptionAmount':
                return <span>{currencyFormatter(fieldContent)}</span>
            default:
                return <span>{fieldContent}</span>;
        }
    }

    function _onColumnClick(event: React.MouseEvent<HTMLElement> | undefined, column: IColumn | undefined): void {
        let isSortedDescending = column?.isSortedDescending;
        if (column?.isSorted) {
            isSortedDescending = !isSortedDescending;
        }
        let sortedRecords: IExceptionQueueForm[] = _copyAndSort(exceptionQueue, column?.fieldName!, isSortedDescending);
        let sortedColumns = exceptionQueueColumns.map((column) => {
            column.isSorted = column.key === column?.key;
            if (column.isSorted) {
                column.isSortedDescending = isSortedDescending;
            }
            return column;
        });
        setExceptionQueue(sortedRecords);
        setExceptionQueueColumns(sortedColumns);
    }

    function searchQueue(): void {
        let searchedExceptionQueue: IExceptionQueueForm[] = [];

        switch (selectedDropdownOption) {
            case 'FileId': case 'ReloId':
                searchedExceptionQueue = exceptionQueue.filter((row) => row[selectedDropdownOption].toString() === searchKeyword.replace(/[^\d]/g, ''));
                break;
            case 'FullName':
                searchedExceptionQueue = exceptionQueue.filter((row) => row[selectedDropdownOption].toString().replace(/[^\w]/g, '') === searchKeyword.replace(/[^\w]/g, ''));
                break;
            case 'Approver':
                searchedExceptionQueue = exceptionQueue.filter((row) => row[selectedDropdownOption].toString() === searchKeyword.replace(/[^\w-]/g, ''));
                break;
            default:
                searchedExceptionQueue = exceptionQueue;
                break;
        }

        setExceptionQueue(searchedExceptionQueue);
    }

    function filterMeetsRequirements(filter: string[], optionName: any): boolean {
        if (filter.length > 0 && !filter.includes(optionName)) {
            return false;
        }
        return true;
    }

    function startDateWithinFilterRange(fromDate: Date | null | undefined, toDate: Date | null | undefined, startDate: Date): boolean {
        if (fromDate !== undefined && fromDate !== null && fromDate > (startDate)) {
            return false;
        }
        if (toDate !== undefined && toDate !== null && toDate < (startDate)) {
            return false;
        }
        return true;
    }

    function filterQueue(): void {
        let filteredExceptionQueue: IExceptionQueueForm[] = [];

        exceptionQueue.forEach((item) => {
            let recordMeetsRequirements = true;
            while (recordMeetsRequirements) {
                if (!filterMeetsRequirements(selectedReloTypeFilters, item.RelocationType)) {
                    recordMeetsRequirements = false;
                    break;
                }
                if (!filterMeetsRequirements(selectedBusinessGroupFilters, item.BusinessGroup)) {
                    recordMeetsRequirements = false;
                    break;
                }
                if (!filterMeetsRequirements(selectedStatusFilters, item.Status)) {
                    recordMeetsRequirements = false;
                    break;
                }
                if (!startDateWithinFilterRange(selectedFromDateFilter, selectedToDateFilter, new Date(item.StartDate))) {
                    recordMeetsRequirements = false;
                    break;
                }
                break;
            }
            if (recordMeetsRequirements === true) {
                filteredExceptionQueue.push(item);
            }
        })

        setExceptionQueue(filteredExceptionQueue);
    }

    function onFilterChange(checked: boolean | undefined, optionName: string, checkBoxOptions: checkBoxOptions[], setSelectedCheckBoxOptions: Function, setSelectedFilters: Function): void {
        const newSelectedCheckBoxOptions = checkBoxOptions.map(item => {
            if (item.optionName === optionName) {
                return { ...item, checked: checked }
            } else {
                return item;
            }
        })
        const newSelectedFilters = newSelectedCheckBoxOptions.filter((item) => (item.checked === true)).map((item) => item.optionName)
        setSelectedCheckBoxOptions(newSelectedCheckBoxOptions);
        setSelectedFilters(newSelectedFilters);
    }

    function onReloTypeFilterChange(event?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void {
        const checkedReloType = (event!.currentTarget as HTMLInputElement).value;
        onFilterChange(checked, checkedReloType, reloTypeCheckBoxOptions, setReloTypeCheckBoxOptions, setSelectedReloTypeFilters);
    }

    function onBusinessGroupFilterChange(event?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void {
        const checkedBusinessGroup = (event!.currentTarget as HTMLInputElement).value;
        onFilterChange(checked, checkedBusinessGroup, businessGroupCheckBoxOptions, setBusinessGroupCheckBoxOptions, setSelectedBusinessGroupFilters);
    }

    function onStatusFilterChange(event?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void {
        const checkedStatus = (event!.currentTarget as HTMLInputElement).value;
        onFilterChange(checked, checkedStatus, statusCheckBoxOptions, setStatusCheckBoxOptions, setSelectedStatusFilters);
    }

    function resetSearch(): void {
        setExceptionQueue(masterExceptionQueue);
        setSelectedDropdownOption('Select One');
        setSearchKeyword('');
    }

    function resetFilter(): void {
        setExceptionQueue(masterExceptionQueue);
        setSelectedFromDateFilter(undefined);
        setSelectedToDateFilter(undefined);
        setSelectedReloTypeFilters([]);
        setSelectedBusinessGroupFilters([]);
        setSelectedStatusFilters([]);
        setAllCheckBoxOptions(defaultReloTypeOptions, defaultBusinessGroupOptions, defaultStatusOptions);
    }
}
