import * as React from 'react';
import {
    Stack,
    IconButton,
    IStackItemStyles,
    IStackStyles,
    Dropdown,
    IDropdownOption,
    IDropdownStyles,
    Text,
    TextField,
    ITextFieldStyles,
    FontIcon,
    mergeStyleSets,
    mergeStyles,
} from '@fluentui/react';
import { DatePicker, IStackProps } from 'office-ui-fabric-react';
import { useServiceStore } from '../../RootStateContext';
import { jobLevelOptions } from '../../Models/IReviewForm';
import { EmploymentInfo } from '../../Models/INewRelocationRequest';
import * as moment from 'moment';
import {RushCommentsRequirementText} from '../../Components/Shared/ReusedLabels';

interface EmploymentInformationProps {
    employmentInfo: EmploymentInfo;
    jobLevelData: jobLevelOptions[];
    isFormEditable?: boolean;
    isFormReadOnly?: boolean;
    updateEmploymentInfo: Function;
    updateJobLevelInfo: Function;
    RushChange: Function;
    onJobLevelChange: Function;
    showHrStaffingManagerAliastxtBox: boolean;
    HrStaffingNameLabel: string;
    HrHiringManagerLabel: string;
    InternshipID: number;
    HireTypeID: number;
    validateOnSubmit: boolean;
    validateOnReSubmit: boolean;
    erroredFields: Function;
    clearedFields: Function;
    EstimatedStartDateLabelText: string;
    RushComments: string;
    relocationPolicyID: number | undefined;
    relocationpolicyTypeID:  number | undefined;
    relocationType: string;
}

// --------- Styles ----------
const stackStyles: Partial<IStackStyles> = { root: { width: '100%', marginTop: '7px', marginBottom: '0px' ,padding:'14px'} };
const accordionHeader: IStackItemStyles = {
    root: {
        padding: 5,
        cursor: 'pointer',
        fontSize: '20px',
    },
};
const accordionContent: IStackItemStyles = {
    root: {
        margin: '0px 0px 7px 11px',
        width:'99%',
    boxShadow:'rgb(0 0 0 / 16%) 0px 1px 4px'    },
};
// --------- Constants ----------
const stackTokens = { childrenGap: 20 };
const oneColumnProps: Partial<IStackProps> = {
    tokens: { childrenGap: 15 },
    styles: { root: { width: '100%' } },
};
const threeColumnProps: Partial<IStackProps> = {
    tokens: { childrenGap: 15 },
    styles: { root: { width: '23%' } },
};
// --------------------------------------------------
export const EmploymentInformation: React.FC<EmploymentInformationProps> = React.forwardRef((props, ref) => {
    const [isOpen, setIsOpen] = React.useState<boolean>(true);
    const [businesGroup, setBusinessGroup] = React.useState<IDropdownOption[]>([]);
    const [selectedBusinessGroup, setSelectedBusinessGroup] = React.useState(props.employmentInfo.businessGroup || '');
    const [selectedBusinessGroupError, setSelectedBusinessGroupError] = React.useState<string | undefined>(undefined);
    const [jobLevel, setJobLevel] = React.useState<IDropdownOption[]>([]);
    const [selectedJobLevel, setSelectedJobLevel] = React.useState('0');
    const [selectedJobLevelError, setSelectedJobLevelError] = React.useState<string | undefined>(undefined);
    const { reviewFormService } = useServiceStore();
    const [costCenterTextFieldValue, setCostCenterTextFieldValue] = React.useState(
        props.employmentInfo.costCenter || ''
    );

    const [_rushComments] = React.useState<string>(props.employmentInfo.RushComments|| '');
    const [DepartureCompanyCode,setDepartureCompanyCode] = React.useState<string | undefined>(props.employmentInfo.DepartureCompanyCode|| '');
    const [DestinationCompanyCode,setDestinationCompanyCode] = React.useState<string | undefined>(props.employmentInfo.DestinationCompanyCode|| '');
    const [RequisitionID,setRequisitionID] = React.useState<string | undefined>(props.employmentInfo.RequisitionID|| '');

    const[showRushCommentsBox, set_showRushCommentsBox]=React.useState<boolean>(false);

    const [costCenterTextFieldValueError, setCostCenterTextFieldValueError] = React.useState<string | undefined>(
        undefined
    );
    const [DepartureCompanyCodeTextFieldValueError, setDepartureCompanyCodeTextFieldValueError] = React.useState<string | undefined>(
        undefined
    );
    const [DestinationCompanyCodeTextFieldValueError, setDestinationCompanyCodeTextFieldValueError] = React.useState<string | undefined>(
        undefined
    );
    const [RequisitionIDTextFieldValueError, setRequisitionIDTextFieldValueError] = React.useState<string | undefined>(
        undefined
    );
    const [HRGroupListSelectedKey, setHRGroupListSelectedKey] = React.useState<string>(
        props.employmentInfo.hrStaffingGroup || ''
    );
    const [submitterManagerAlias, setSubmitterManagerAlias] = React.useState(
        props.employmentInfo.hRStaffingManagerAlias || ''
    );
    const [SubmittersManagerAliasErrorMessage, setSubmittersManagerAliasErrorMessage] = React.useState<
        string | undefined
    >(undefined);
    const [submitterAlias, setSubmitterAlias] = React.useState(props.employmentInfo.hRRecruitingAssociate || '');
    const [SubmittersAliasErrorMessage, setSubmittersAliasErrorMessage] = React.useState<string | undefined>(undefined);
    const [RushCommentsErrorMessage, setRushCommentsErrorMessage]= React.useState<string | undefined>(undefined);
    const [hiringManagerAlias, setHiringManagerAlias] = React.useState(props.employmentInfo.hiringManagerAlias || '');
    const [hiringManagerAliasErrorMessage, setHiringManagerAliasErrorMessage] = React.useState<string | undefined>(
        undefined
    );
    const [recruiterAlias, setRecruiterAlias] = React.useState(props.employmentInfo.hRRecruiterAlias || '');
    const [recruiterAliasErrorMessage, setRecruiterAliasErrorMessage] = React.useState<string | undefined>(undefined);
    const [estimatedStartDate, setEstimatedStartDate] = React.useState<string | null>(
        props.employmentInfo.estimatedStartDate || null
    );
    const [estimatedStartDateError, setEstimatedStartDateError] = React.useState<string | undefined>(undefined);
    const [estimatedEndDate, setEstimatedEndDate] = React.useState<string | null>(
        props.employmentInfo.estimatedEndDate || null
    );
    const [estimatedEndDateError, setEstimatedEndDateError] = React.useState<string | undefined>(undefined);

    React.useImperativeHandle(
        ref,
        () => ({
            validateForm() {
                let invalidFields: string[] = [];
                if (isNotNullOrUndefined(selectedBusinessGroup)) {
                    invalidFields.push('businessGroup');
                }
                if (isNotNullOrUndefined(costCenterTextFieldValue)) {
                    invalidFields.push('costCenter');
                }
                if (isNotNullOrUndefined(selectedJobLevel) || selectedJobLevel == '0') {
                    invalidFields.push('jobLevel');
                }
                if (isNotNullOrUndefined(hiringManagerAlias)) {
                    invalidFields.push('hiringManagerAlias');
                }
                if (isNotNullOrUndefined(estimatedStartDate)) {
                    invalidFields.push('startDate');
                }
                if (isNotNullOrUndefined(recruiterAlias)) {
                    invalidFields.push('recruiterAlias');
                }
                if (props.showHrStaffingManagerAliastxtBox) {
                    if (isNotNullOrUndefined(submitterManagerAlias)) {
                        invalidFields.push('hRStaffingManagerAlias');
                    }
                }
                if (isNotNullOrUndefined(submitterAlias)) {
                    invalidFields.push('submitterAlias');
                }

                if (props.HireTypeID == 4) {
                    if (isNotNullOrUndefined(estimatedEndDate)) {
                        invalidFields.push('EndDate');
                    }
                }
                return { errors: Array.from(new Set(invalidFields)) }
            }
        }),
    );

    const HRGroupListOptions: IDropdownOption[] = [
        { key: 'Select One', text: 'Select One' },
        { key: 'MSHires1', text: 'MSHires1' },
    ];

    // Opens and closes hire information component
    const toggleEmploymentInformation = () => {
        setIsOpen(!isOpen);
    };

    // To format Display date
    const formatDate = (date?: Date): string => {
        return !date ? '' : date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();
    };
    const onBusinessGroupChange = (
        event: React.FormEvent<HTMLDivElement> | undefined,
        item: IDropdownOption | undefined
    ): void => {
        if (item) {
            // setSelectedBusinessGroup(item.key.toString());
            props.updateEmploymentInfo('businessGroup', item.text);
            if (isNotNullOrUndefined(item.text)) {
                setSelectedBusinessGroupError('Please select Business Group.');
                props.erroredFields(['businessGroup']);
            } else {
                setSelectedBusinessGroupError(undefined);
                props.clearedFields('businessGroup');
            }
        }
    };
    const onJobLevelChange = (
        event: React.FormEvent<HTMLDivElement> | undefined,
        item: IDropdownOption | undefined
    ): void => {
        if (item) {
            let jobLevelObj = {
                jobLevel: item.text,
                jobLevelID: item.key.toString(),
            };
            if (isNotNullOrUndefined(item.key.toString())) {
                setSelectedJobLevelError('Please select Job Level.');
                props.erroredFields(['jobLevel']);
            } else {
                props.updateJobLevelInfo(jobLevelObj);
                setSelectedJobLevelError(undefined);
                props.clearedFields('jobLevel');
                set_showRushCommentsBox(false);
            }
        }
    };

    const onRushCommentsChange = React.useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            let newValue = event.currentTarget.value;
            props.updateEmploymentInfo('RushComments', newValue);
            if (isNotNullOrUndefined(newValue)) {
                setRushCommentsErrorMessage('Rush Comments are is required.');
                props.erroredFields(['RushComments']);
            }
            else
            {
                setRushCommentsErrorMessage(undefined);
                props.clearedFields('RushComments');
            }
    },[props]);

    const RushChange=() =>{
        var currentDate = new Date().getTime();            
            var estStartDate = moment(props.employmentInfo.estimatedStartDate).toDate().getTime(); 
            var startDateDelay = estStartDate - currentDate;
            var delayinDays = Math.floor(startDateDelay / (1000 * 60 * 60 * 24));           
            if (props.HireTypeID != 7 && props.HireTypeID != 4) 
            {
                //Domestic
                if ((props.relocationPolicyID == 1 || props.relocationPolicyID == 2) && delayinDays <= 30) {
                    set_showRushCommentsBox(true);
                }
                //International
                else if ((props.relocationPolicyID == 3 || props.relocationPolicyID == 4 || props.relocationPolicyID == 5 || props.relocationPolicyID == 6 ||props.relocationPolicyID == 10 || props.relocationPolicyID == 11 || props.relocationPolicyID == 12 || props.relocationPolicyID == 13) && delayinDays <= 60) {
                    set_showRushCommentsBox(true);
                }
                else {
                    set_showRushCommentsBox(false);
                    setRushCommentsErrorMessage(undefined);
                    props.clearedFields('RushComments');
                }
            }
            else 
            {
                set_showRushCommentsBox(false);
                setRushCommentsErrorMessage(undefined);
                props.clearedFields('RushComments');
            }

    };

    const dropdownStyles: Partial<IDropdownStyles> = {
        dropdown: { width: '100%' },
    };

    const iconClass = mergeStyles({
        fontSize: 22,
        height: 22,
        width: 22,
        paddingRight: 20,
    });

    const classNames = mergeStyleSets({
        submitted: [{ color: '#7BA811' }, iconClass],
        error: [{ color: '#DF0008' }, iconClass],
        warning: [{ color: '#EC9F1D' }, iconClass],
        info: [{ color: '#0078D7' }, iconClass],
    });

    React.useEffect(() => {
        //BusinessGroups
        var businessDropdown: IDropdownOption[] = [];
        reviewFormService.getBusinessGroups().then((groups) => {
            groups.forEach((group) => {
                businessDropdown.push({ key: group, text: group });
            });
            setBusinessGroup(businessDropdown);
        });
        //Jobvelevel
        var JobLevels: IDropdownOption[] = [];
        props.jobLevelData.forEach((data) => {
            if (data.ID == props.employmentInfo.jobLevelID) {
                setSelectedJobLevel(data.ID);
            }
            JobLevels.push({ key: data.ID, text: data.JobLevelName });
        });
        if (props.HireTypeID === 4 && props.employmentInfo.jobLevelID !== '0') {
            setSelectedJobLevel('1');
        }
        setJobLevel(JobLevels);
    }, []);

    React.useEffect(()=>
    {
        RushChange();
        
    },[props.employmentInfo.estimatedStartDate]);

    React.useEffect(() => {
        RushChange();
    },[props.relocationPolicyID]);

    React.useEffect(() => {
        let errorFields = [];
        if (props.validateOnSubmit || props.validateOnReSubmit) {
            if (isNotNullOrUndefined(selectedBusinessGroup)) {
                setSelectedBusinessGroupError('Please select Business Group.');
                errorFields.push('businessGroup');
            }
            if (isNotNullOrUndefined(costCenterTextFieldValue)) {
                setCostCenterTextFieldValueError('Cost Center is required.');
                errorFields.push('costCenter');
            }
            if (isNotNullOrUndefined(selectedJobLevel) || selectedJobLevel == '0') {
                setSelectedJobLevelError('Please select Job Level.');
                errorFields.push('jobLevel');
            }
            if (isNotNullOrUndefined(hiringManagerAlias)) {
                setHiringManagerAliasErrorMessage('Hiring Manager Alias is required.');
                errorFields.push('hiringManagerAlias');
            }
            if (isNotNullOrUndefined(estimatedStartDate)) {
                setEstimatedStartDateError('Estimated Start Date is required.');
                errorFields.push('startDate');
            }
            if (isNotNullOrUndefined(recruiterAlias)) {
                setRecruiterAliasErrorMessage('Recruiter Alias is required.');
                errorFields.push('recruiterAlias');
            }
            if (props.showHrStaffingManagerAliastxtBox) {
                if (isNotNullOrUndefined(submitterManagerAlias)) {
                    setSubmittersManagerAliasErrorMessage('Manager Alias is required');
                    errorFields.push('hRStaffingManagerAlias');
                }
            }
            if (isNotNullOrUndefined(submitterAlias)) {
                setSubmittersAliasErrorMessage('Recruiting Associate Alias is required');
                errorFields.push('submitterAlias');
            }
            if (props.HireTypeID == 4) {
                if (isNotNullOrUndefined(estimatedEndDate)) {
                    setEstimatedEndDateError('Estimated End Date is required.');
                    errorFields.push('EndDate');
                }
            }

            if (props.HireTypeID == 2) {
                if (isNotNullOrUndefined(DepartureCompanyCode)) {
                    setDepartureCompanyCodeTextFieldValueError('Departure Company Code is required.');
                    errorFields.push('DepartureCompanyCode');
                }
            }
            if (isNotNullOrUndefined(DestinationCompanyCode)) {
                setDestinationCompanyCodeTextFieldValueError('Destination Company Code is required.');
                errorFields.push('DestinationCompanyCode');
            }
            if (isNotNullOrUndefined(RequisitionID) && props.HireTypeID !=8) {
                setRequisitionIDTextFieldValueError('Requisition ID is required.');
                errorFields.push('RequisitionID');
            }

            if (showRushCommentsBox && isNotNullOrUndefined(props.employmentInfo.RushComments))
            {
                setRushCommentsErrorMessage('Rush Comments are required');
                errorFields.push('RushComments');
            }
            props.erroredFields(errorFields);
        }
    }, [props.validateOnSubmit, props.validateOnReSubmit]);

    const isNotNullOrUndefined = (value: any) => {
        if (value === null || value === undefined) return true;
        if (typeof value === 'string' && value === '') return true;
        return false;
    };

    const textFieldStyles: Partial<ITextFieldStyles> = { fieldGroup: { width: 200 } };

    React.useEffect(() => {
        //Compare Employement Details
        if (props.employmentInfo.businessGroup !== selectedBusinessGroup) {
            setSelectedBusinessGroup(props.employmentInfo.businessGroup);
        }
        if (props.employmentInfo.costCenter !== costCenterTextFieldValue) {
            setCostCenterTextFieldValue(props.employmentInfo.costCenter);
        }
        if (props.employmentInfo.jobLevelID !== selectedJobLevel) {
            setSelectedJobLevel(props.employmentInfo.jobLevelID);
        }
        if (props.employmentInfo.hRStaffingManagerAlias != submitterManagerAlias) {
            setSubmitterManagerAlias(props.employmentInfo.hRStaffingManagerAlias);
        }
        if (props.employmentInfo.hRRecruitingAssociate != submitterAlias) {
            setSubmitterAlias(props.employmentInfo.hRRecruitingAssociate);
        }
        if (props.employmentInfo.hiringManagerAlias != hiringManagerAlias) {
            setHiringManagerAlias(props.employmentInfo.hiringManagerAlias);
        }
        if (props.employmentInfo.estimatedStartDate != estimatedStartDate) {
            setEstimatedStartDate(props.employmentInfo.estimatedStartDate);
        }
        if (props.employmentInfo.hRRecruiterAlias != recruiterAlias) {
            setRecruiterAlias(props.employmentInfo.hRRecruiterAlias);
        }
        if (props.employmentInfo.estimatedEndDate != estimatedEndDate) {
            setEstimatedEndDate(props.employmentInfo.estimatedEndDate);
        }
        if (props.employmentInfo.hrStaffingGroup != HRGroupListSelectedKey) {
            if (isNotNullOrUndefined(props.employmentInfo.hrStaffingGroup)) {
                setHRGroupListSelectedKey(props.employmentInfo.hrStaffingGroup);
            }
        }
        if (props.employmentInfo.DepartureCompanyCode != DepartureCompanyCode) {
            setDepartureCompanyCode(props.employmentInfo.DepartureCompanyCode);
        }
        if (props.employmentInfo.DestinationCompanyCode != DestinationCompanyCode) {
            setDestinationCompanyCode(props.employmentInfo.DestinationCompanyCode);
        }
        if (props.employmentInfo.RequisitionID != RequisitionID) {
            setRequisitionID(props.employmentInfo.RequisitionID);
        }
    }, [props.employmentInfo]);

    async function onGetAliasDetails(v: string): Promise<string> {
        const info = await reviewFormService.GetEmployeeBasicDetails(v);
        if (info === null) {
            return Promise.resolve("invalid");
        }
        return Promise.resolve('');
    }

    async function _onNotifySubmitterManagerAliasValidationResult(errorMessage: string | JSX.Element, value: string | undefined) {
        if (value === null || value === undefined || value.replace(/\s/g, '') === '') {
            props.updateEmploymentInfo('hRStaffingManagerAlias', value);
            setSubmittersManagerAliasErrorMessage("Submitter's Manager Alias is required.");
            props.erroredFields(['hRStaffingManagerAlias']);
        } else if (value) {
            if ((await onGetAliasDetails(value)) === "invalid") {
                setSubmittersManagerAliasErrorMessage('Enter Valid Alias');
            } else {
                props.updateEmploymentInfo('hRStaffingManagerAlias', value);
                setSubmittersManagerAliasErrorMessage(undefined);
                props.clearedFields('hRStaffingManagerAlias');
            }
        }
    }

    async function _onNotifySubmitterAliasValidationResult(errorMessage: string | JSX.Element, value: string | undefined) {
        if (value === null || value === undefined || value.replace(/\s/g, '') === '') {
            props.updateEmploymentInfo('hRRecruitingAssociate', value);
            setSubmittersAliasErrorMessage("Submitter's Alias is required.");
            props.erroredFields(['submitterAlias']);
        } else if (value) {
            if ((await onGetAliasDetails(value)) === "invalid") {
                setSubmittersAliasErrorMessage('Enter Valid Alias');
            } else {
                props.updateEmploymentInfo('hRRecruitingAssociate', value);
                setSubmittersAliasErrorMessage(undefined);
                props.clearedFields('submitterAlias');
            }
        }
    }

    async function _onNotifyHiringManagerAliasValidationResult(e: string | JSX.Element, value: string | undefined) {
        if (value === null || value === undefined || value.replace(/\s/g, '') === '') {
            props.updateEmploymentInfo('hiringManagerAlias', value);
            setHiringManagerAliasErrorMessage("Hiring Manager's Alias is required.");
            props.erroredFields(['hiringManagerAlias']);
        } else if (value) {
            if ((await onGetAliasDetails(value)) === "invalid") {
                setHiringManagerAliasErrorMessage('Enter Valid Alias');
            } else {
                props.updateEmploymentInfo('hiringManagerAlias', value);
                setHiringManagerAliasErrorMessage(undefined);
                props.clearedFields('hiringManagerAlias');
            }
        }
    }

    async function _onNotifyRecruiterAliasValidationResult(e: string | JSX.Element, value: string | undefined) {
        if (value === null || value === undefined || value.replace(/\s/g, '') === '') {
            props.updateEmploymentInfo('hRRecruiterAlias', value);
            setRecruiterAliasErrorMessage("Recruiter's Alias is required.");
            props.erroredFields(['recruiterAlias']);
        } else if (value) {
            if ((await onGetAliasDetails(value)) === "invalid") {
                setRecruiterAliasErrorMessage('Enter Valid Alias');
            } else {
                props.updateEmploymentInfo('hRRecruiterAlias', value);
                setRecruiterAliasErrorMessage(undefined);
                props.clearedFields('recruiterAlias');
            }
        }
    }

    const onChangeCostCenterFieldValue = React.useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            let newValue = event.currentTarget.value
            let costcenterpattern = /^[0-9-?_=.*!@#$%^&*]+$/;
            props.updateEmploymentInfo('costCenter', newValue);
            if (isNotNullOrUndefined(newValue)) {
                setCostCenterTextFieldValueError('Cost Center is required.');
                props.erroredFields(['costCenter']);
            } else {
                if (newValue!.length < 2) {
                    setCostCenterTextFieldValueError('Cost center should be minimum 2 character.');
                    props.erroredFields(['costCenter']);
                } else if (!costcenterpattern.test(newValue!)) {
                    setCostCenterTextFieldValueError('Cost center accepts only numbers & special characters.');
                    props.erroredFields(['costCenter']);
                } else if (newValue!.length > 1 && [5, 7, 8].find((item) => item === newValue!.length) === undefined) {
                    setCostCenterTextFieldValueError('Cost center should be 5,7 or 8 digits.');
                    props.erroredFields(['costCenter']);
                } else {
                    setCostCenterTextFieldValueError(undefined);
                    props.clearedFields('costCenter');
                }
            }
        },
        [props]
    );

    const onChangeDepartureCompanyCodeFieldValue = React.useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            let newValue = event.currentTarget.value;
            let pattern = /^[0-9-?_=.*!@#$%^&*]+$/;
            props.updateEmploymentInfo('DepartureCompanyCode', newValue);
            if (isNotNullOrUndefined(newValue)) {
                setDepartureCompanyCodeTextFieldValueError('Departure Company Code is required.');
                props.erroredFields(['DepartureCompanyCode']);
            }
            else{
                if (newValue!.length < 2) {
                    setDepartureCompanyCodeTextFieldValueError('Departure Company Code should be minimum 2 character.');
                    props.erroredFields(['DepartureCompanyCode']);
                } else if (!pattern.test(newValue!)) {
                    setDepartureCompanyCodeTextFieldValueError('Departure Company Code accepts only numbers');
                    props.erroredFields(['DepartureCompanyCode']);
                } else {
                    setDepartureCompanyCodeTextFieldValueError(undefined);
                    props.clearedFields('DepartureCompanyCode');
                }
            }
        },
        [props]
    );   

    const onChangeDestinationCompanyCodeFieldValue = React.useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            let newValue = event.currentTarget.value;
            let pattern = /^[0-9-?_=.*!@#$%^&*]+$/;
            props.updateEmploymentInfo('DestinationCompanyCode', newValue);
            if (isNotNullOrUndefined(newValue)) {
                setDestinationCompanyCodeTextFieldValueError('Destination Company Code is required.');
                props.erroredFields(['DestinationCompanyCode']);
            }
            else{
                if (newValue!.length < 2) {
                    setDestinationCompanyCodeTextFieldValueError('Destination Company Code should be minimum 2 character.');
                    props.erroredFields(['DestinationCompanyCode']);
                } else if (!pattern.test(newValue!)) {
                    setDestinationCompanyCodeTextFieldValueError('Destination Company Code accepts only numbers.');
                    props.erroredFields(['DestinationCompanyCode']);
                } else {
                    setDestinationCompanyCodeTextFieldValueError(undefined);
                    props.clearedFields('DestinationCompanyCode');
                }
            }
        },
        [props]
    ); 

    const onChangeRequisitionIDFieldValue = React.useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            let newValue = event.currentTarget.value;
            let pattern = /^[0-9-?_=.*!@#$%^&*]+$/;
            props.updateEmploymentInfo('RequisitionID', newValue);
            if (isNotNullOrUndefined(newValue)) {
                setRequisitionIDTextFieldValueError('Requisition ID is required.');
                props.erroredFields(['RequisitionID']);
            }
            else{
                if (newValue!.length < 2) {
                    setRequisitionIDTextFieldValueError('Requisition ID should be minimum 2 character.');
                    props.erroredFields(['RequisitionID']);
                } else if (!pattern.test(newValue!)) {
                    setRequisitionIDTextFieldValueError('Requisition ID accepts only numbers.');
                    props.erroredFields(['RequisitionID']);
                } else {
                    setRequisitionIDTextFieldValueError(undefined);
                    props.clearedFields('RequisitionID');
                }
            }
        },
        [props]
    ); 
        

    return (
        <Stack className="EmploymentInformation" style={{padding:'0px 2px 0px 2px'}}>
            <Stack horizontal={true} style={{backgroundColor:'rgb(235, 235, 235)',maxWidth: '100%',margin: '5px 0px 0px 5px'}}>
            <Stack.Item style={{ marginTop: '3px' }} className="ac-icon">
                    <IconButton
                        aria-labelledby="Employment Information"
                        title="Employment Information"
                        iconProps={{
                            iconName: isOpen ? 'ChevronUp' : 'ChevronDown',
                        }}
                        onClick={toggleEmploymentInformation}
                    />
                </Stack.Item>
                <Stack.Item align="center" styles={accordionHeader} className="ac-title">
                    <h2 style={{ fontSize: '20px' }}> Employment Information </h2>
                </Stack.Item>
            </Stack>

            {isOpen && (
            <Stack styles={accordionContent} className="set-width">
                <Stack horizontal tokens={stackTokens} styles={stackStyles} className="setinner-width">
                    <Stack {...threeColumnProps} >
                            <Dropdown
                                label="Business Group"
                                onChange={onBusinessGroupChange}
                                selectedKey={selectedBusinessGroup}
                                options={businesGroup}
                                styles={dropdownStyles}
                                errorMessage={selectedBusinessGroupError}
                            />
                        </Stack>
                        <Stack {...threeColumnProps}>
                            <TextField
                                label="Cost Center"
                                defaultValue={costCenterTextFieldValue}
                                onBlur={onChangeCostCenterFieldValue}
                                readOnly={props.isFormReadOnly}
                                disabled={props.isFormEditable}
                                styles={textFieldStyles}
                                errorMessage={costCenterTextFieldValueError}
                                maxLength={8}
                                minLength={2}
                            />
                        </Stack>
                        <Stack {...threeColumnProps}>
                            <Dropdown
                                label="Job Level"
                                onChange={onJobLevelChange}
                                selectedKey={selectedJobLevel}
                                options={jobLevel}
                                styles={dropdownStyles}
                                errorMessage={selectedJobLevelError}
                                required
                            />
                        </Stack>

                        {props.showHrStaffingManagerAliastxtBox && (
                            <Stack {...threeColumnProps}>
                                <TextField
                                    label={props.HrStaffingNameLabel}
                                    errorMessage={SubmittersManagerAliasErrorMessage}
                                    defaultValue={submitterManagerAlias}
                                    data-value="1"
                                    maxLength={30}
                                    onNotifyValidationResult={_onNotifySubmitterManagerAliasValidationResult}
                                    validateOnLoad={false}
                                    required
                                    readOnly={props.HireTypeID == 4 && props.InternshipID != 5}
                                />
                            </Stack>
                        )}

                    </Stack>

                    <Stack horizontal tokens={stackTokens} styles={stackStyles}>
                        <Stack {...threeColumnProps}>
                            <TextField
                                label="E-Form Submitter Alias"
                                errorMessage={SubmittersAliasErrorMessage}
                                defaultValue={submitterAlias}
                                data-value="2"
                                onNotifyValidationResult={_onNotifySubmitterAliasValidationResult}
                                validateOnLoad={false}
                                maxLength={30}
                            />
                        </Stack>
                        <Stack {...threeColumnProps}>
                            <TextField
                                label={props.HrHiringManagerLabel}
                                errorMessage={hiringManagerAliasErrorMessage}
                                defaultValue={hiringManagerAlias}
                                data-value="3"
                                onNotifyValidationResult={_onNotifyHiringManagerAliasValidationResult}
                                validateOnLoad={false}
                                maxLength={30}
                            />
                        </Stack>

                        <Stack {...threeColumnProps}>
                            <TextField
                                label="Recruiter Alias"
                                errorMessage={recruiterAliasErrorMessage}
                                defaultValue={recruiterAlias}
                                data-value="4"
                                onNotifyValidationResult={_onNotifyRecruiterAliasValidationResult}
                                validateOnLoad={false}
                                maxLength={30}
                            />
                        </Stack>
                    </Stack>

                    <Stack horizontal tokens={stackTokens} styles={stackStyles}>
                    {(props.HireTypeID == 2) && (
                        <Stack {...threeColumnProps}>
                            <TextField
                                label="Departure Company Code"
                                role='textbox'
                                errorMessage={DepartureCompanyCodeTextFieldValueError}
                                defaultValue={DepartureCompanyCode}
                                onBlur={onChangeDepartureCompanyCodeFieldValue}
                                maxLength={30}
                                required
                            />
                        </Stack>)}
                        <Stack {...threeColumnProps}>
                            <TextField
                                label="Destination Company Code"
                                role='textbox'
                                errorMessage={DestinationCompanyCodeTextFieldValueError}
                                defaultValue={DestinationCompanyCode}
                                onBlur={onChangeDestinationCompanyCodeFieldValue}
                                maxLength={30}
                                required
                            />
                        </Stack>                       

                        <Stack {...threeColumnProps}>
                            <TextField
                                label="Requisition ID"
                                role='textbox'
                                errorMessage={RequisitionIDTextFieldValueError}
                                defaultValue={RequisitionID}
                                onBlur={onChangeRequisitionIDFieldValue}
                                maxLength={30}
                                required={props.HireTypeID != 8}
                            />
                        </Stack>

                    </Stack>


                    <Stack horizontal tokens={stackTokens} styles={stackStyles}>
                        <Stack {...threeColumnProps}>
                            <DatePicker
                                label={props.EstimatedStartDateLabelText}
                                value={estimatedStartDate ? new Date(estimatedStartDate) : undefined}
                                formatDate={formatDate}
                                onSelectDate={(newDate) => {
                                    onStartDateChange(newDate!);
                                }}
                                textField={{ errorMessage: estimatedStartDateError }}
                                minDate={new Date(Date.now())}
                            />
                        </Stack>
                    
                    
                        {props.HireTypeID == 4 && (
                            <Stack {...threeColumnProps}>
                                <DatePicker
                                    label="Estimated End Date"
                                    value={estimatedEndDate ? new Date(estimatedEndDate) : undefined}
                                    formatDate={formatDate}
                                    onSelectDate={(newDate) => {
                                        onEndDateChange(newDate!);
                                    }}
                                    disabled={props.HireTypeID != 4}
                                    minDate={new Date(Date.now())}
                                    textField={{ errorMessage: estimatedEndDateError }}
                                />
                            </Stack>
                        )}
                    </Stack>

                    <Stack horizontal tokens={stackTokens} styles={stackStyles}>
                            <Stack horizontal={true} {...oneColumnProps}> 
                                <FontIcon
                                    iconName="Info"
                                    aria-label="Estimated Start Date Info"
                                    className={classNames.info}
                                />
                                <Text variant="small">
                                    First select Job Level, Relocation Policy and Relocation Type.
                                    If estimated start date is unknown, select a date 30 days from today. 
                                    If Domestic (intra country) relocation, select a minimum of 30 calendar days from today's date.
                                    If Global (cross border) relocation, select a minimum of 60 calendar days from today's date
                                </Text>
                            </Stack>
                        </Stack>                        
                       
                       { showRushCommentsBox &&
                       
                       <Stack horizontal tokens={stackTokens} styles={stackStyles}>
                            <Stack {...oneColumnProps}>
                            <RushCommentsRequirementText />
                                <TextField
                                    label="Rush Comments"
                                    role='textbox'
                                    multiline
                                    rows={3}
                                    maxLength={400}
                                    defaultValue={''}
                                    errorMessage={RushCommentsErrorMessage}
                                    value={props.employmentInfo.RushComments}
                                    onChange={onRushCommentsChange}
                                    onBlur={onRushCommentsChange}
                                    description={`${400 - (props.RushComments != null ? props.RushComments.length : 0)
                                        } Characters Left`}
                                    required
                                ></TextField>
                            </Stack>
                        </Stack>}
                    
                </Stack>
            )}
        </Stack>
    );

    function onStartDateChange(date: Date | undefined) {
        let newDate = formatDate(date);
        props.updateEmploymentInfo('estimatedStartDate', newDate);
        if (isNotNullOrUndefined(newDate)) {
            setEstimatedStartDateError('Estimated Start Date is required.');
            props.erroredFields(['startDate']);
        } else {
            setEstimatedStartDateError(undefined);
            props.clearedFields('startDate');
        }
    }

    function onEndDateChange(date: Date | undefined) {
        let newDate = formatDate(date);
        props.updateEmploymentInfo('estimatedEndDate', newDate);
        if (isNotNullOrUndefined(newDate)) {
            setEstimatedEndDateError('Estimated End Date is required.');
            props.erroredFields(['EndDate']);
        } else {
            setEstimatedEndDateError(undefined);
            props.clearedFields('EndDate');
        }
    }
});
