import * as React from 'react';
import {
    Checkbox,
    ChoiceGroup,
    PrimaryButton,
    Dropdown,
    getTheme,
    IChoiceGroupOption,
    IDropdownOption,
    IScrollablePaneStyles,
    mergeStyleSets,
    Panel,
    PanelType,
    ScrollablePane,
    SearchBox,
    Stack,
    Text,
} from '@fluentui/react';
import { DatePicker } from 'office-ui-fabric-react';
import { getStyles } from '../Shared/FilterPane.styles';
import { MyReloQueueFilterComponentProps, relotypeCheckBoxOptions } from './QueueTable.types';
import * as moment from 'moment';
import { IRelocationFormQueues } from '../../Models/IRelocationFormQueues';

export const FilterPane: React.FC<MyReloQueueFilterComponentProps> = React.forwardRef((props, ref) => {
    const visaPendingOptions: IChoiceGroupOption[] = [
        { key: '1', text: 'Yes' },
        { key: '2', text: 'No' },
        { key: '3', text: 'All' },
    ];

    const dropdownOptions = [
        { key: '0', text: 'Record ID' },
        { key: '1', text: 'Name' },
        { key: '2', text: 'Submitter/Authorizer' },
    ];

    const theme = getTheme();
    const classNames = mergeStyleSets({
        wrapper: {
            height: '40vh',
            position: 'relative',
            maxHeight: 'inherit',
        },
        pane: {
            maxWidth: 'inherit',
            border: '1px solid ' + theme.palette.neutralLight,
        },
    });

    const styles = getStyles();
    const [dropDownValue, setdropDownValue] = React.useState<string>('Select One');
    const [searchKeyword, setsearchKeyword] = React.useState<string>('');
    const [statusValues, setstatusValues] = React.useState<string[]>([]);
    const [isCheckedAuthorized, setisCheckedAuthorized] = React.useState<boolean>(false);
    const [isCheckedSaved, setisCheckedSaved] = React.useState<boolean>(false);
    const [isCheckedSubmitted, setisCheckedSubmitted] = React.useState<boolean>(false);
    const [isCheckedResubmitted, setisCheckedResubmitted] = React.useState<boolean>(false);
    const [isCheckedReturnForEdits, setisCheckedReturnForEdits] = React.useState<boolean>(false);
    const [isCheckedEverSendBack, setisCheckedEverSendBack] = React.useState<boolean>(false);
    const [visaPendingValue, setvisaPendingValue] = React.useState<string>('3');
    const [everSendBackValue, seteverSendBackValue] = React.useState<string>('');
    const [estimatedFromDate, setestimatedFromDate] = React.useState<Date>();
    const [estimatedToDate, setestimatedToDate] = React.useState<Date>();
    const [selectedRelocationPolicyFilters, setselectedreloTypeFitlers] = React.useState<string[]>([]);
    const [relotypeCheckBoxOption, setrelotypeCheckBoxOption] = React.useState<relotypeCheckBoxOptions[]>(
        props.relotypeOptions
    );
    const scrollablePaneStyles: Partial<IScrollablePaneStyles> = { root: classNames.pane };

    const [filterData, setFilterData] = React.useState<any[]>([]);
    const [filterType, setFilterType] = React.useState('admin');
    React.useImperativeHandle(
        ref,
        () => ({
            ResetFilterCallFromMyRelo() {
                resetFilter();
            }
        }))
        
    React.useEffect(() => {
        if (props.filterType === 'staffing') {
            setdropDownValue('2');
            setsearchKeyword(props.loggerdInUser);
            setFilterType(props.filterType);
        }
    }, [props.filterType]);

    React.useEffect(() => {
        if (filterType === 'staffing' && searchKeyword !== '') {
            searchResults();
        }
    }, [filterType, searchKeyword])

    let relocationPolicyContainer = relotypeCheckBoxOption.map((reloType) => {
        return (
            <Checkbox
                className={styles.checkbox}
                label={reloType.optionName}
                inputProps={{ value: reloType.optionName }}
                onChange={handleReloTypeFilter}
                checked={reloType.checked}
            ></Checkbox>
        );
    });
  
    return (
        <Panel
            type={PanelType.smallFixedFar}
            headerText={'Search this Queue'}
            isOpen={props.isOpen}
            onDismiss={closePanel}
            hasCloseButton={true}
            isLightDismiss={true}
            closeButtonAriaLabel="Close Filter Panel"
        >
            <Stack className={styles.filterContainer}>
                <Stack tokens={{ childrenGap: 5 }}>
                    <Dropdown
                        label="Select an Option"
                        onChange={onDropdownChange}
                        placeholder="Select One"
                        selectedKey={dropDownValue}
                        options={dropdownOptions}
                    // styles={dropdownStyles}
                    />
                    <SearchBox
                        // styles={searchBoxStyles}
                        placeholder="Search"
                        onEscape={(ev) => { }}
                        onClear={(ev) => { }}
                        onChange={(_, newValue) => setsearchKeyword(newValue || '')}
                        onSearch={searchResults}
                        value={searchKeyword}
                    />
                    <Stack.Item>
                        <PrimaryButton
                            styles={{ root: { marginTop: 20, marginRight: 10 } }}
                            text={'Search'}
                            onClick={searchResults}
                        />
                        <PrimaryButton styles={{ root: { marginTop: 20 } }} text={'Reset'} onClick={resetFilter} />
                    </Stack.Item>
                </Stack>
                <Stack tokens={{ childrenGap: 10 }}>
                    <h2 className={styles.filterTitle}>Filters</h2>
                    <Stack.Item>
                        <Text variant="medium" styles={{ root: { marginTop: 5, marginBottom: 5 } }}>
                            Filter By Relocation Type
                        </Text>
                        <Stack.Item className={classNames.wrapper}>
                            <ScrollablePane
                                scrollContainerFocus={true}
                                scrollContainerAriaLabel="Relocation Type Filter Pane"
                                styles={scrollablePaneStyles}
                            >
                                {relocationPolicyContainer}
                            </ScrollablePane>
                        </Stack.Item>
                    </Stack.Item>
                    <Stack.Item>
                        <h3 className={styles.sectionTitles}>Status</h3>
                        <Checkbox
                            className={styles.checkbox}
                            label="Authorized"
                            inputProps={{ value: 'Authorized' }}
                            checked={isCheckedAuthorized}
                            onChange={handleStatusFilter}
                        />
                        <Checkbox
                            className={styles.checkbox}
                            label="Saved"
                            inputProps={{ value: 'Saved' }}
                            checked={isCheckedSaved}
                            onChange={handleStatusFilter}
                        />
                        <Checkbox
                            className={styles.checkbox}
                            label="Submitted"
                            inputProps={{ value: 'Submitted' }}
                            checked={isCheckedSubmitted}
                            onChange={handleStatusFilter}
                        />
                        <Checkbox
                            className={styles.checkbox}
                            label="Resubmitted"
                            inputProps={{ value: 'Resubmitted' }}
                            checked={isCheckedResubmitted}
                            onChange={handleStatusFilter}
                        />
                        <Checkbox
                            className={styles.checkbox}
                            label="Returned for edits"
                            inputProps={{ value: 'Return For Edits' }}
                            checked={isCheckedReturnForEdits}
                            onChange={handleStatusFilter}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <h3 className={styles.sectionTitles}>Visa pending</h3>
                        <ChoiceGroup
                            className={styles.checkbox}
                            options={visaPendingOptions}
                            defaultSelectedKey={'3'}
                            selectedKey={visaPendingValue}
                            onChange={handleVisaPendingValue}
                        />
                        {/* <Checkbox className={styles.checkbox} label="No" /> */}
                    </Stack.Item>
                    <h3 className={styles.sectionTitles}>Ever send back?</h3>
                    <Checkbox
                        className={styles.checkbox}
                        label="Ever send back"
                        inputProps={{ value: 'SendBack' }}
                        checked={isCheckedEverSendBack}
                        onChange={handleEverSendBackValue}
                    />
                    <Stack.Item>
                        <h3 className={styles.sectionTitles}>Estimated start date</h3>
                        <DatePicker
                            label="From"
                            ariaLabel="Select a date"
                            value={estimatedFromDate}
                            onSelectDate={(newDate) => setestimatedFromDate(newDate || undefined)}
                        />
                        <DatePicker
                            label="To"
                            ariaLabel="Select a date"
                            value={estimatedToDate}
                            onSelectDate={(newDate) => setestimatedToDate(newDate || undefined)}
                        />
                    </Stack.Item>
                </Stack>
                <Stack>
                    <Stack.Item>
                        <PrimaryButton
                            styles={{ root: { marginTop: 20, marginRight: 10 } }}
                            text="Filter"
                            onClick={filterByStatus}
                        />
                        <PrimaryButton styles={{ root: { marginTop: 20 } }} text="Reset" onClick={resetFilter} />
                    </Stack.Item>
                </Stack>
            </Stack>
        </Panel>
    );

    function filterByStatus() {
        let filteredData = props.apiData;
        const innerArray = props.apiData[0];
        let IsResetButtonToBeShown:boolean =false; 
        if (Object.keys(filterData).length > 0) {
            filteredData = filterData;
        }

        if (selectedRelocationPolicyFilters.length > 0) {
            IsResetButtonToBeShown =true;
            selectedRelocationPolicyFilters.map((selValue, index) => {
                if (index == 0) {
                    if (Array.isArray(innerArray)) {
                        filteredData = innerArray.filter((item) => item.RelocationPolicyName === selValue);
                    }
                    else{
                        filteredData = filteredData.filter((item) => item.RelocationPolicyName === selValue);
                    }
                } else {
                    if (Array.isArray(innerArray)) {
                        filteredData = filteredData.concat(
                            innerArray.filter((item) => item.RelocationPolicyName === selValue)
                        );
                    }
                    else{
                        filteredData = filteredData.concat(
                            filteredData.filter((item) => item.RelocationPolicyName === selValue)
                        );
                    }
                }
            });
        }

        if (statusValues.length > 0) {
            IsResetButtonToBeShown =true;
            let statusFilteredData: IRelocationFormQueues[] = [];
            if (Object.keys(filteredData).length > 0) {
                statusValues.forEach((item, index) => {
                    if ((index = 0)) {
                        if (Array.isArray(innerArray)) {
                            statusFilteredData = innerArray.filter((row) => row.Status.toString() === item);
                        }
                        else{
                            statusFilteredData = filteredData.filter((row) => row.Status.toString() === item);
                        }
                    } else {
                        if (Array.isArray(innerArray)) {
                            statusFilteredData = statusFilteredData.concat(
                                innerArray.filter((row) => row.Status.toString() === item)
                            );
                        }
                        else{
                            statusFilteredData = statusFilteredData.concat(
                                filteredData.filter((row) => row.Status.toString() === item)
                            );
                        }
                        
                    }
                });
            } else {
                statusValues.forEach((item, index) => {
                    if ((index = 0)) {
                        statusFilteredData = props.apiData.filter((row) => row.Status.toString() === item);
                    } else {
                        statusFilteredData = statusFilteredData.concat(
                            props.apiData.filter((row) => row.Status.toString() === item)
                        );
                    }
                });
            }
            filteredData = statusFilteredData;
        }

        if (visaPendingValue != '3') {
            IsResetButtonToBeShown =true;
            if (Array.isArray(innerArray)) {
                filteredData =
                visaPendingValue === '1'
                    ? innerArray.filter((row) => row.VisaStatus === true)
                    : innerArray.filter((row) => row.VisaStatus == false);          
            }
            else{
                filteredData =
                visaPendingValue === '1'
                    ? filteredData.filter((row) => row.VisaStatus === true)
                    : filteredData.filter((row) => row.VisaStatus == false);
            }
            
        }

        if (everSendBackValue.length > 0) {
            IsResetButtonToBeShown =true;
            if (Array.isArray(innerArray)) {
                filteredData =
                everSendBackValue == 'SendBack'
                    ? innerArray.filter((row) => row.EverSendBack === true)
                    : innerArray.filter((row) => row.EverSendBack === true || row.EverSendBack === false);         
            }
            else{
                filteredData =
                everSendBackValue == 'SendBack'
                    ? filteredData.filter((row) => row.EverSendBack === true)
                    : filteredData.filter((row) => row.EverSendBack === true || row.EverSendBack === false);
            }
            
        }

        if (estimatedFromDate != undefined) {
            IsResetButtonToBeShown =true;
            if (Array.isArray(innerArray)) {
                filteredData = innerArray.filter(
                    (row) => moment(row.EstimatedStartDate).toDate() >= moment(estimatedFromDate).toDate()
                );            
            }
            else{
                filteredData = filteredData.filter(
                    (row) => moment(row.EstimatedStartDate).toDate() >= moment(estimatedFromDate).toDate()
                );
            }
        }

        if (estimatedToDate != undefined) {
            IsResetButtonToBeShown =true;
            if (Array.isArray(innerArray)) {
                filteredData = innerArray.filter(
                    (row) => moment(row.EstimatedStartDate).toDate() <= moment(estimatedToDate).toDate()
                );         
            }
            else{
                filteredData = filteredData.filter(
                    (row) => moment(row.EstimatedStartDate).toDate() <= moment(estimatedToDate).toDate()
                );
            }
        }
            
        if(IsResetButtonToBeShown)
        {
            props.ShowResetButton();
        }
        if (Array.isArray(innerArray)) {
            props.handleFilterSubmit(filteredData.flat(1));      
        }
        else{
            props.handleFilterSubmit(filteredData);
        }
    }

    function handleStatusFilter(event?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void {
        let curStatusValues = statusValues;
        const status = (event!.currentTarget as HTMLInputElement).value;

        if (checked) {
            switch (status) {
                case 'Authorized':
                    setisCheckedAuthorized(true);
                    break;
                case 'Saved':
                    setisCheckedSaved(true);
                    break;
                case 'Submitted':
                    setisCheckedSubmitted(true);
                    break;
                case 'Resubmitted':
                    setisCheckedResubmitted(true);
                    break;
                case 'Return For Edits':
                    setisCheckedReturnForEdits(true);
                    break;
            }
            curStatusValues.push(status);
        } else {
            const index = curStatusValues.indexOf(status);
            curStatusValues.splice(index, 1);
            switch (status) {
                case 'Authorized':
                    setisCheckedAuthorized(false);
                    break;
                case 'Saved':
                    setisCheckedSaved(false);
                    break;
                case 'Submitted':
                    setisCheckedSubmitted(false);
                    break;
                case 'Resubmitted':
                    setisCheckedResubmitted(false);
                    break;
                case 'Return For Edits':
                    setisCheckedReturnForEdits(false);
                    break;
            }
        }
        setstatusValues(curStatusValues);
    }

    function handleVisaPendingValue(
        event?: React.FormEvent<HTMLElement | HTMLInputElement>,
        option?: IChoiceGroupOption
    ): void {
        setvisaPendingValue(option!.key);
    }

    function handleEverSendBackValue(event?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void {
        if (checked) {
            setisCheckedEverSendBack(true);
            seteverSendBackValue((event?.currentTarget as HTMLInputElement).value);
        } else {
            seteverSendBackValue('');
            setisCheckedEverSendBack(false);
        }
    }

    function onDropdownChange(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void {
        setdropDownValue(option!.key as string);
        if ((option!.key as string) === '2') {
            setsearchKeyword(props.loggerdInUser);
        }
    }

    function searchResults() {
        let filteredData = props.apiData;
        const innerArray = props.apiData[0];
        let noSpaceSearchKeyword = searchKeyword?.split(" ").join("")
        switch (dropDownValue) {
            case '0':
                if (Array.isArray(innerArray)) {
                    filteredData = innerArray.filter((row) => row.RecordID.toString() === noSpaceSearchKeyword);     
                }
                else{
                    filteredData = props.apiData.filter((row) => row.RecordID.toString() === noSpaceSearchKeyword);
                }
                break;
            case '1':
                if (Array.isArray(innerArray)) {
                    filteredData = innerArray.filter((row) => {
                        return (
                            row.FullName.toLowerCase()
                                .replaceAll(' ', '')
                                .indexOf(noSpaceSearchKeyword.toLowerCase().replaceAll(' ', '')) > -1
                        );
                    });      
                }
                else{
                    filteredData = props.apiData.filter((row) => {
                        return (
                            row.FullName.toLowerCase()
                                .replaceAll(' ', '')
                                .indexOf(noSpaceSearchKeyword.toLowerCase().replaceAll(' ', '')) > -1
                        );
                    });
                }
                break;
            case '2':
                if (Array.isArray(innerArray)) {
                    filteredData = innerArray.filter(
                        (row) => row.SubmittedBy === noSpaceSearchKeyword || row.CreatedBy === noSpaceSearchKeyword
                    );   
                }
                else{
                    filteredData = props.apiData.filter(
                        (row) => row.SubmittedBy === noSpaceSearchKeyword || row.CreatedBy === noSpaceSearchKeyword
                    );
                }
                break;
            case 'Select One': // not sure if this is necessary anymore since setting initially
                if (Array.isArray(innerArray)) {
                    filteredData = innerArray;       
                }
                else{
                    filteredData = props.apiData;
                }
                break;
        }
        if((noSpaceSearchKeyword.length>0) && (dropDownValue != "Select One") ) 
        {
            props.ShowResetButton();
        }
        setFilterData(filteredData);
        props.handleFilterSubmit(filteredData);
    }

    function handleReloTypeFilter(event?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void {
        const checkedReloType = (event!.currentTarget as HTMLInputElement).value;
        if (checked) {
            selectedRelocationPolicyFilters.push(checkedReloType);
            const objIndex = relotypeCheckBoxOption.findIndex((obj) => obj.optionName === checkedReloType);

            let reloTypeObj = relotypeCheckBoxOption.filter((item) => item.optionName !== checkedReloType);

            const obj = {
                optionName: checkedReloType,
                checked: true,
            };

            reloTypeObj.splice(objIndex, 0, obj);
            setrelotypeCheckBoxOption(reloTypeObj);
            setselectedreloTypeFitlers(selectedRelocationPolicyFilters);
        } else {
            let index = selectedRelocationPolicyFilters.indexOf(checkedReloType, 0);
            const objIndex = relotypeCheckBoxOption.findIndex((obj) => obj.optionName === checkedReloType);

            let reloTypeObj = relotypeCheckBoxOption.filter((item) => item.optionName !== checkedReloType);

            const obj = {
                optionName: checkedReloType,
                checked: false,
            };

            reloTypeObj.splice(objIndex, 0, obj);
            setrelotypeCheckBoxOption(reloTypeObj);
            if (index > -1) {
                selectedRelocationPolicyFilters.splice(index, 1);
            }
        }
    }

    function closePanel(): void {
        props._closePanel();
    }

    function resetFilter(): void {
        const innerArray = props.apiData[0];
        setstatusValues([]);
        setisCheckedAuthorized(false);
        setisCheckedSaved(false);
        setisCheckedSubmitted(false);
        setisCheckedResubmitted(false);
        setisCheckedReturnForEdits(false);
        setisCheckedEverSendBack(false);
        setvisaPendingValue('3');
        seteverSendBackValue('');
        setestimatedFromDate(undefined);
        setestimatedToDate(undefined);
        setdropDownValue('Select One');
        setsearchKeyword('');
        setrelotypeCheckBoxOption(props.relotypeOptions);
        if (Array.isArray(innerArray)) {
            props.handleFilterSubmit(innerArray);           
        }
        else{
            props.handleFilterSubmit(props.apiData);
        }
        props.HideResetButton();
    }
});
