import { IColumn } from '@fluentui/react';

export const SendBackColumns: IColumn[] = [
    {
        key: 'FullName',
        name: 'Employee Name',
        fieldName: 'FullName',
        minWidth: 100,
        maxWidth: 100,
    },

    {
        key: 'SubmittedBy',
        name: 'Submitted By',
        fieldName: 'SubmittedBy',
        minWidth: 80,
        maxWidth: 80,
    },
    {
        key: 'SendBackComments',
        name: 'SendBack Reason',
        fieldName: 'SendBackComments',
        minWidth: 80,
        maxWidth: 80,
    },
    {
        key: 'DateSubmitted',
        name: 'Date Submitted',
        fieldName: 'DateSubmitted',
        minWidth: 120,
        maxWidth: 120,
    },
    {
        key: 'Destination',
        name: 'Destination',
        fieldName: 'Destination',
        minWidth: 120,
        maxWidth: 120,
    },

    {
        key: 'Departure',
        name: 'Departure',
        fieldName: 'Departure',
        minWidth: 120,
        maxWidth: 120,
    },

    {
        key: 'EstimatedStartDate',
        name: 'Estimated Start Date',
        fieldName: 'EstimatedStartDate',
        minWidth: 120,
        maxWidth: 120,
    },
    {
        key: 'RelocationType',
        name: 'Relocation Type',
        fieldName: 'RelocationType',
        minWidth: 120,
        maxWidth: 120,
    },
    {
        key: 'RecordID',
        name: 'Record ID',
        fieldName: 'RecordID',
        minWidth: 60,
        maxWidth: 60,
    },
    {
        key: 'Status',
        name: 'Status',
        fieldName: 'Status',
        minWidth: 100,
        maxWidth: 100,
    },
    {
        key: 'CandidateID',
        name: 'Candidate ID',
        fieldName: 'CandidateID',
        minWidth: 60,
        maxWidth: 60,
    },
    {
        key: 'StaffingManagerAlias',
        name: 'Staffing Manager Alias',
        fieldName: 'StaffingManagerAlias',
        minWidth: 100,
        maxWidth: 100,
    },
    {
        key: 'CurentEmail',
        name: 'Employee Email Address',
        fieldName: 'CurentEmail',
        minWidth: 100,
        maxWidth: 100,
    },
];
