import { PrimaryButton } from '@fluentui/react';
import * as React from 'react';
const { Workbook } = require('react-excel-workbook-emptystring');

export const ExportToExcel: React.FC<any> = (props) => {
    return (
        <div>
            <Workbook
                filename={props.fileName}
                element={
                    <PrimaryButton text=" Export To Excel" aria-label="Export to Excel" role="button"></PrimaryButton>
                }
            >
                <Workbook.Sheet data={props.rowsData} name={(props.fileName.slice(0,31))}>
                    {props.colsData.map((item: { name: any; key: any }) => (
                        <Workbook.Column key={item.key} label={item.name} value={item.key} />
                    ))}
                </Workbook.Sheet>
            </Workbook>
        </div>
    );
};
