import { IHttpClient, IHttpClientResult } from '@employee-experience/common';
import { IInternshipTypeEntity } from '../../Models/INewRelocationRequest';
import {
    CoreBenefitsEntity,
    EmployeeDetails,
    ExceptionsEntity,
    HireType,
    IReviewForm,
    ISendBack,
    jobLevelOptions,
    PersonalDetails,
    RelocationPolicyEntity,
    RelocationType,
} from '../../Models/IReviewForm';

export class ReviewFormService {
    private httpClient: IHttpClient;
    private url: string;
    private apiURL: string;

    public constructor(httpClient: IHttpClient) {
        this.httpClient = httpClient;
        this.url = REACT_APP_RELO_API_URL;
        this.apiURL = '';
    }

    public async getRelocationFormData(RecordID: number): Promise<IReviewForm[]> {
        const response: IHttpClientResult<IReviewForm[]> = await this.httpClient.request({
            url: `${this.url}api/reloform/RelocationForm/${RecordID}`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    }

    public async getHireTypeService(): Promise<HireType[]> {
        const response: IHttpClientResult<HireType[]> = await this.httpClient.request({
            url: `${this.url}api/policy/HireTypes`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    }

    public async getJobLevelInformation(): Promise<jobLevelOptions[]> {
        const response: IHttpClientResult<jobLevelOptions[]> = await this.httpClient.request({
            url: `${this.url}api/common/JobLevels`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    }

    public async getrelocationPolicy(HireTypeID: number): Promise<RelocationPolicyEntity[]> {
        const response: IHttpClientResult<RelocationPolicyEntity[]> = await this.httpClient.request({
            url: `${this.url}api/policy/RelocationPolicies/${HireTypeID}`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    } //relocationTypeRequest

    public async getrelocationType(): Promise<RelocationType[]> {
        const response: IHttpClientResult<RelocationType[]> = await this.httpClient.request({
            url: `${this.url}api/policy/RelocationTypes`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    } //coreBenefits

    public async getCoreBenefits(ReloTypeID: number, FiscalYear: number): Promise<CoreBenefitsEntity[]> {
        if (__IS_ACCESSIBILITY_TESTING__ == 'true') {
            this.apiURL = `${this.url}api/policy/RelocationTypes/?${ReloTypeID}/FiscalYear/CoreBenefits?FiscalYear=${FiscalYear}&reloTypeId=${ReloTypeID}`;
        } else {
            this.apiURL = `${this.url}api/policy/RelocationTypes/${ReloTypeID}/FiscalYear/CoreBenefits?FiscalYear=${FiscalYear}&reloTypeId=${ReloTypeID}`;
        }
        const response: IHttpClientResult<CoreBenefitsEntity[]> = await this.httpClient.request({
            url: `${this.apiURL}`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    }

    public async getExceptions(ReloTypeID: number): Promise<ExceptionsEntity[]> {
        const response: IHttpClientResult<ExceptionsEntity[]> = await this.httpClient.request({
            url: `${this.url}api/policy/Exceptions/${ReloTypeID}`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    }

    public async getAllCountryNames(): Promise<PersonalDetails> {
        const response: IHttpClientResult<PersonalDetails> = await this.httpClient.request({
            method: 'POST',
            url: `${this.url}api/reloform/GetAllCountryNames`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    }

    public async getAllStateNames(StateName: string): Promise<PersonalDetails> {
        const response: IHttpClientResult<PersonalDetails> = await this.httpClient.request({
            method: 'POST',
            url: `${this.url}api/reloform/GetAllStateNames/${StateName}`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    }
    public async getBusinessGroups(): Promise<string[]> {
        const response: IHttpClientResult<string[]> = await this.httpClient.request({
            url: `${this.url}api/policy/BusinessGroups`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    }

    public async GetEmployeeBasicDetails(Alias: string): Promise<EmployeeDetails> {
        const response: IHttpClientResult<EmployeeDetails> = await this.httpClient.request({
            url: `${this.url}api/Employees/${Alias}`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    }

    public async GetInternshipTypes(): Promise<IInternshipTypeEntity[]> {
        const response: IHttpClientResult<IInternshipTypeEntity[]> = await this.httpClient.request({
            url: `${this.url}api/policy/InternshipTypes`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    }

    public async saveReadyToInitiate(RecordID: number): Promise<any> {
        const response: IHttpClientResult<any> = await this.httpClient.request({
            method: 'POST',
            url: `${this.url}api/reloform/SaveReadyToInitiate/${RecordID}`,
            resource: __CLIENT_ID__,
        });
        return response;
    }

    public async SendToFileSetUp(RecordID: number, SendToFileSetUpComments: string): Promise<any> {
        const response: IHttpClientResult<any> = await this.httpClient.request({
            url: `${this.url}api/reloform/SendToFileSetUp/reloID/SendToFileSetupComments/?SendToFileSetupComments=${SendToFileSetUpComments}&reloID=${RecordID}`,
            resource: __CLIENT_ID__,
        });
        return response;
    }

    public async SaveSendBackQueue(sendBackData: ISendBack): Promise<any> {
        const response: IHttpClientResult<any> = await this.httpClient.request({
            method: 'POST',
            url: `${this.url}api/reloform/SaveSendBackQueue`,
            resource: __CLIENT_ID__,
            data: sendBackData,
        });
        return response;
    }

    public async GetSendMailNotification(RecordID: number): Promise<void> {
        await this.httpClient.request({
            method: 'POST',
            url: `${this.url}api/reloform/GetSendMailNotification/reloID/?reloID=${RecordID}`,
            resource: __CLIENT_ID__,
        });
    }
}
