import * as React from 'react';
import * as moment from 'moment';
import { Stack, PrimaryButton, ShimmeredDetailsList, IStackTokens, StackItem, IStackItemStyles, CheckboxVisibility, Text } from '@fluentui/react'
import { DatePicker } from 'office-ui-fabric-react';
import { E2AReportColumns } from './E2AReportColumns';
import { IE2ALiabilityTrend } from '../../Models/IE2ALiabilityTrend';
import { useServiceStore } from '../../RootStateContext';
import { Pagination } from '../../Components/Shared/Pagination';
import { ExportToExcel } from '../../Components/Shared/ExportToExcel';
import { StackDisclaimer } from '../../Components/Shared/DisclaimerText';

export const E2ALiabilityTrend: React.FC<{}> = () => {
    const [E2AReportFromDate, setE2AReportFromDate] = React.useState<Date>();
    const [E2AReportToDate, setE2AReportToDate] = React.useState<Date>();
    const [E2AReport, setE2AReport] = React.useState<IE2ALiabilityTrend[]>();
    const [E2AReportShow, setE2AReportShow] = React.useState<IE2ALiabilityTrend[]>();
    const { e2ALiabilityTrendService } = useServiceStore();
    const [exportQueueItems, setExportQueueItems] = React.useState<IE2ALiabilityTrend[]>([]);
    const verticalGapStackTokens: IStackTokens = {
        childrenGap: 10,
        padding: 10,
    };

    const actionButtonStyles: IStackItemStyles = {
        root: {
            marginTop: 28
        },
    };

    const fetchReportData = async (): Promise<void> => {
        let fromData = E2AReportFromDate as Date;
        let toData = E2AReportToDate as Date;
        let e2areport = await e2ALiabilityTrendService.getE2ALiabilityTrendReport(fromData, toData)
        let tempE2aReport = e2areport.map((item) => {
            let dateStr = item.CommitDate ? moment(item.CommitDate).format('MM-YYYY').split("-") : null;
            return {
                ...item,
                CommitDate: dateStr ? `${dateStr[0]}-01-${dateStr[1]}` : null
            }
        });
        setE2AReport(tempE2aReport);
    }

    React.useEffect(() => {
        if (E2AReport && E2AReport.length > 0) {
            let tempExportQueueItems = E2AReport.map((item) => {
                return {
                    ...item,
                    EmployeeStartDate: item.EmployeeStartDate ? new Date(item.EmployeeStartDate) : "",
                    ActualMonth: item.ActualMonth ? new Date(item.ActualMonth) : "",
                    CommitDate: item.CommitDate? new Date(item.CommitDate):"",
                }
            });
            setExportQueueItems(tempExportQueueItems)
        } else {
            setExportQueueItems([])
        }

    }, [E2AReport])

    return (
        <div>
            <Stack tokens={verticalGapStackTokens}>
                <Stack.Item align="auto" styles={{ root: { padding: 5 } }}>
                    <h1>E2A Liability Trend</h1>

                </Stack.Item>
                <Stack horizontal tokens={{ childrenGap: 20 }} verticalAlign="end">
                    <StackItem>
                        <DatePicker
                            label="Start Date"
                            value={E2AReportFromDate}
                            className="datePick"
                            onSelectDate={(newDate) => setE2AReportFromDate(newDate || undefined)}
                            placeholder="Start Date (mm-dd-yyyy)"
                            ariaLabel="Start Date (mm-dd-yyyy)"
                        />
                    </StackItem>
                    <StackItem>
                        <DatePicker
                            label="End Date"
                            className="datePick"
                            value={E2AReportToDate}
                            onSelectDate={(newDate) => setE2AReportToDate(newDate || undefined)}
                            placeholder="End Date (mm-dd-yyyy)"
                            ariaLabel="End Date (mm-dd-yyyy)"
                        />
                    </StackItem>
                    <StackItem>
                        <PrimaryButton
                            styles={actionButtonStyles}
                            text="Run Report"
                            onClick={fetchReportData}
                        />
                    </StackItem>
                    <StackItem>
                        {E2AReportShow
                            ? <ExportToExcel
                                rowsData={exportQueueItems}
                                colsData={E2AReportColumns}
                                fileName={"E2ALiabilityTrendReport_" + (moment(new Date()).format('MMM_DD_YYYY_hhmm A')) + ".xlsx"}

                            />
                            : <PrimaryButton
                                styles={actionButtonStyles}
                                text="Export To Excel"
                            />}
                    </StackItem>
                </Stack>
                <Stack>
                    <StackItem>
                        <ShimmeredDetailsList
                            items={E2AReportShow || []}
                            columns={E2AReportColumns}
                            checkboxVisibility={CheckboxVisibility.hidden}
                            ariaLabelForGrid="E2A Liability Report"
                        />
                        {E2AReportShow?.length === 0 &&
                            <Stack horizontalAlign='center'>
                                <Text>No Records to Display </Text>
                            </Stack>
                        }
                    </StackItem>
                    <StackItem>
                        {E2AReport && <Pagination
                            data={E2AReport}
                            columns={E2AReportColumns}
                            setData={setE2AReportShow}
                        />}
                    </StackItem>
                </Stack>
                {StackDisclaimer()}
            </Stack>
        </div>
    );
}