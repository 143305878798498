import {
    ChoiceGroup,
    IChoiceGroupOption,
    IStackStyles,
    Link,
    Spinner,
    SpinnerSize,
    Stack,
    Text,
} from '@fluentui/react';
import * as React from 'react';
import { AttachReloSummary } from '../../Models/IReviewForm';
import { useServiceStore } from '../../RootStateContext';

interface RelocationSummaryAttachmentProps {
    reloFile: string;
    reloID: number;
    addtionalReloFile0: AttachReloSummary;
    addtionalReloFile1: AttachReloSummary;
    addtionalReloFile2: AttachReloSummary;
    addtionalReloFile3: AttachReloSummary;
    addtionalReloFile4: AttachReloSummary;
    GUID0: string;
    GUID1: string;
    GUID2: string;
    GUID3: string;
    GUID4: string;
}

// --------- Constants ----------
const stackTokens = { childrenGap: 20 };

const stackStyles: Partial<IStackStyles> = { root: { width: '100%', marginBottom: '5px', paddingLeft: 10 } };

export const RelocationSummaryAttachment: React.FC<RelocationSummaryAttachmentProps> = (props) => {
    const { startNewReloRequestService } = useServiceStore();
    const [selectedAttachmentType, setSelectedAttachmentType] = React.useState('ReloSummary');

    const [showfileUpload, setshowfileUpload] = React.useState<boolean>(false);

    const [showfileUpload0, setshowfileUpload0] = React.useState<boolean>(false);
    const [showfileUpload1, setshowFileUpload1] = React.useState<boolean>(false);
    const [showfileUpload2, setshowFileUpload2] = React.useState<boolean>(false);
    const [showfileUpload3, setshowFileUpload3] = React.useState<boolean>(false);
    const [showfileUpload4, setshowFileUpload4] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const options: IChoiceGroupOption[] = [
        { key: 'ReloSummary', text: 'Relo Summary', iconProps: { iconName: 'CalendarDay' } },
        { key: 'AdditionalDocument', text: 'Additional Document', iconProps: { iconName: 'CalendarWeek' } },
    ];
    const header = {
        fontWeight: 600,
    };

    const onAttachmentTypeChange = (
        _ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
        option?: IChoiceGroupOption | undefined
    ): void => {
        setSelectedAttachmentType(option!.key);
    };

    React.useEffect(() => {
        if (props.reloFile.length > 0) setshowfileUpload(true);
        if (Object.keys(props.addtionalReloFile0).length > 0) setshowfileUpload0(true);
        if (Object.keys(props.addtionalReloFile1).length > 0) setshowFileUpload1(true);
        if (Object.keys(props.addtionalReloFile2).length > 0) setshowFileUpload2(true);
        if (Object.keys(props.addtionalReloFile3).length > 0) setshowFileUpload3(true);
        if (Object.keys(props.addtionalReloFile1).length > 4) setshowFileUpload4(true);
    }, [props]);
    return (
        <>
            {loading && (
                <Stack>
                    <div>
                        <Spinner
                            label="Loading, please wait..."
                            size={SpinnerSize.small}
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                height: '100%',
                                width: '100%',
                                zIndex: 9999999,
                                backgroundColor: 'rgb(255, 255, 255, 255)',
                            }}
                        />
                    </div>
                </Stack>
            )}
            <Stack horizontal tokens={stackTokens} styles={stackStyles}>
                <Stack className="ml-sm-0">
                    <ChoiceGroup
                        label="Attach Relocation Summary"
                        aria-disabled="true"
                        defaultSelectedKey={selectedAttachmentType}
                        options={options}
                        onChange={onAttachmentTypeChange}
                        selectedKey={selectedAttachmentType}
                        disabled={true}
                    />
                </Stack>
            </Stack>
            <Stack horizontal tokens={stackTokens} styles={stackStyles}>
                <Stack>
                    {showfileUpload && (
                        <Link
                            aria-label="Download Relo File Link"
                            onClick={ async () => await DownloadRelocationSummary()
                            }
                        >
                            {props.reloFile}
                        </Link>
                    )}
                </Stack>
                <Stack horizontal={false} tokens={{ childrenGap: 10 }}>
                    {showfileUpload0 && (
                        <>
                            <Stack horizontal={true} tokens={{ childrenGap: 5 }}>
                                <Link
                                    aria-label="Download Additional Relo File Link"
                                    onClick={async () => await DownloadAdditionalRelocationSummary(0)}
                                >
                                    {props.addtionalReloFile0?.Filename}
                                </Link>
                                <Text variant="medium" style={header}>
                                    {props.addtionalReloFile0?.AddtionalDescription}
                                </Text>
                            </Stack>
                        </>
                    )}
                    {showfileUpload1 && (
                        <>
                            <Stack horizontal={true} tokens={{ childrenGap: 5 }}>
                                <Link aria-label="Download Additional Relo File Link"
                                 onClick={async () => await DownloadAdditionalRelocationSummary(1)}
                                 >
                                    {props.addtionalReloFile1?.Filename}
                                </Link>

                                <Text variant="medium" style={header}>
                                    {props.addtionalReloFile1?.AddtionalDescription}
                                </Text>
                            </Stack>
                        </>
                    )}
                    {showfileUpload2 && (
                        <>
                            <Stack horizontal={true} tokens={{ childrenGap: 5 }}>
                                <Link aria-label="Download Additional Relo File Link"
                                 onClick={async () =>await DownloadAdditionalRelocationSummary(2)}
                                 >
                                    {props.addtionalReloFile2?.Filename}
                                </Link>

                                <Text variant="medium" style={header}>
                                    {props.addtionalReloFile2?.AddtionalDescription}
                                </Text>
                            </Stack>
                        </>
                    )}
                    {showfileUpload3 && (
                        <>
                            <Stack horizontal={true} tokens={{ childrenGap: 5 }}>
                                <Link aria-label="Download Additional Relo File Link"
                                 onClick={async () =>await DownloadAdditionalRelocationSummary(3)}
                                 >
                                    {props.addtionalReloFile3?.Filename}
                                </Link>

                                <Text variant="medium" style={header}>
                                    {props.addtionalReloFile3?.AddtionalDescription}
                                </Text>
                            </Stack>
                        </>
                    )}
                    {showfileUpload4 && (
                        <>
                            <Stack horizontal={true} tokens={{ childrenGap: 5 }}>
                                <Link aria-label="Download Additional Relo File Link"
                                 onClick={async () =>await DownloadAdditionalRelocationSummary(4)}
                                 >
                                    {props.addtionalReloFile4?.Filename}
                                </Link>

                                <Text variant="medium" style={header}>
                                    {props.addtionalReloFile4?.AddtionalDescription}
                                </Text>
                            </Stack>
                        </>
                    )}
                </Stack>
            </Stack>
        </>
    );

    async function DownloadRelocationSummary() {
        if (props.reloID != null) {
            setLoading(true);
            let result = await startNewReloRequestService.GetReloAttachment(props.reloID);

            if (result.status == 200) {
                if (result.data.length > 0) {
                    var byteCharacters = atob(result.data);
                    var byteNumbers = new Array(byteCharacters.length);
                    for (var i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    var byteArray = new Uint8Array(byteNumbers);
                    var blob = new Blob([byteArray], {
                        type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                    });
                    setLoading(false);
                    saveAs(blob, props.reloFile);
                }
                if(result == null)
                {alert("download failed");}
            }
        }
    }

    async function DownloadAdditionalRelocationSummary(index: number) {
        let fileName = '';
        let fileGUID = '';

        if (props.reloID != null) {
            if (index == 0) {
                fileName = props.addtionalReloFile0.Filename;
                fileGUID = props.GUID0;
            }
            if (index == 1) {
                fileName = props.addtionalReloFile1.Filename;
                fileGUID = props.GUID1;
            }
            if (index == 2) {
                fileName = props.addtionalReloFile2.Filename;
                fileGUID = props.GUID2;
            }
            if (index == 3) {
                fileName = props.addtionalReloFile3.Filename;
                fileGUID = props.GUID3;
            }
            if (index == 4) {
                fileName = props.addtionalReloFile4.Filename;
                fileGUID = props.GUID4;
            }

            if (fileName != '' && fileGUID != '') {
                setLoading(true);
                let result = await startNewReloRequestService.GetAdditionalReloAttachment(fileGUID);
                if (result.status == 200) {
                    if (result.data.length > 0) {
                        var byteCharacters = atob(result.data);
                        var byteNumbers = new Array(byteCharacters.length);
                        for (var i = 0; i < byteCharacters.length; i++) {
                            byteNumbers[i] = byteCharacters.charCodeAt(i);
                        }
                        var byteArray = new Uint8Array(byteNumbers);
                        var blob = new Blob([byteArray], {
                            type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                        });
                        setLoading(false);
                        saveAs(blob, fileName);
                    }
                }
            }
        }
    }
};
