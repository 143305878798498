import * as React from 'react';
import {
    Stack,
    IconButton,
    IStackItemStyles,
    IStackProps,
    IStackStyles,
    TextField,
    ITextFieldStyles,
    Dropdown,
    IDropdownOption,
    IDropdownStyles,
    ComboBox,
    IComboBoxOption,
    MessageBar,
    MessageBarType,
    Toggle,
} from '@fluentui/react';
import { PersonalDetails } from '../../Models/IReviewForm';
import { useServiceStore } from '../../RootStateContext';
import { PersonalInfo } from '../../Models/INewRelocationRequest';

//Props

export type PersonalInformationProps = {
    personalInfo: PersonalInfo;
    updatePersonalDetails: Function;
    updateDepartureAddress: Function;
    updateDestinationAddress: Function;
    homeDestinationAddressDetails: Function;
    clearedFields: Function;
    erroredFields: Function;
    bulkHomeDestinationAddressUpdate: Function;
    validateOnSubmit: boolean;
    validateOnReSubmit: boolean;
    errorFieldsState: any;
};

// --------- Styles ----------
const stackStyles: Partial<IStackStyles> = { root: { width: '100%', marginTop: '7px', padding: '14px' } };
const personalInfoColumnProps: Partial<IStackProps> = {
    tokens: { childrenGap: 15 },
    styles: { root: { width: '20%' } },
};
const personalInfoDepAddColumnProps: Partial<IStackProps> = {
    tokens: { childrenGap: 15 },
    styles: { root: { width: '90%', padding: '5px' } },
};
const threeColumnProps: Partial<IStackProps> = {
    tokens: { childrenGap: 15 },
    styles: { root: { width: '32.33%' } },
};
const formSectionStyle = {
    padding: '0px 2px 0px 2px',
};
const accordionHeader: IStackItemStyles = {
    root: {
        padding: 5,
        cursor: 'pointer',
        fontSize: '20px',
    },
};
const accordionContent: IStackItemStyles = {
    root: {
        margin: '0px 0px 7px 11px',
        width: '99%',
        boxShadow: 'rgb(0 0 0 / 16%) 0px 1px 4px',
    },
};
const stackTokens = { childrenGap: 20 };
export const PersonalInformation: React.FC<PersonalInformationProps> = React.forwardRef((props, ref) => {
    const [isOpen, setIsOpen] = React.useState<boolean>(true);
    const [firstNameTextFieldValue, setFirstNameTextFieldValue] = React.useState(
        props.personalInfo.personalDetails.FirstName || ''
    );
    const [firstNameTextFieldValueError, setFirstNameTextFieldValueError] = React.useState<string | undefined>(
        undefined
    );
    const [middleNameTextFieldValue, setMiddleNameTextFieldValue] = React.useState(
        props.personalInfo.personalDetails.MiddleName || ''
    );
    const [lastNameTextFieldValue, setLastNameTextFieldValue] = React.useState(
        props.personalInfo.personalDetails.LastName || ''
    );
    const [lastNameTextFieldValueError, setLastNameTextFieldValueError] = React.useState<string | undefined>(undefined);
    const [personalPhoneTextFieldValue, setPersonalPhoneTextFieldValue] = React.useState(
        props.personalInfo.personalDetails.PersonalPhone || ''
    );
    const [personalPhoneTextFieldValueError, setPersonalPhoneTextFieldValueError] = React.useState<string | undefined>(
        undefined
    );
    const [workPhoneTextFieldValue, setWorkPhoneTextFieldValue] = React.useState(
        props.personalInfo.personalDetails.WorkPhone || ''
    );
    const [currentEmailTextFieldValue, setCurrentEmailTextFieldValue] = React.useState(
        props.personalInfo.personalDetails.CurrentEmail || ''
    );
    const [currentEmailTextFieldValueError, setCurrentEmailTextFieldValueError] = React.useState<string | undefined>(
        undefined
    );
    const [departureAddressTextFieldValue, setDepartureAddressTextFieldValue] = React.useState(
        props.personalInfo.departureAddress.Address || ''
    );
    const [departureAddressTextFieldValueError, setDepartureAddressTextFieldValueError] = React.useState<
        string | undefined
    >(undefined);
    const [departureAddressApartmentTextFieldValue, setDepartureApartmentTextFieldValue] = React.useState(
        props.personalInfo.departureAddress.Apartment || ''
    );
    const [departureCityTextFieldValue, setDepartureCityTextFieldValue] = React.useState(
        props.personalInfo.departureAddress.City || ''
    );
    const [departureCityTextFieldValueError, setDepartureCityTextFieldValueError] = React.useState<string | undefined>(
        undefined
    );
    const [departurePostalTextFieldValue, setDeparturePostalTextFieldValue] = React.useState(
        props.personalInfo.departureAddress.PostalCode || ''
    );

    const [workDestinationCityTextFieldValue, setWorkDestinationCityTextFieldValue] = React.useState(
        props.personalInfo.workDestinationAddress.City || ''
    );
    const [workDestinationCityTextFieldValueError, setWorkDestinationCityTextFieldValueError] = React.useState<
        string | undefined
    >(undefined);
    const [homeDestinationCityTextFieldValue, setHomeDestinationCityTextFieldValue] = React.useState(
        props.personalInfo.homeDestinationAddress.City || ''
    );
    const [homeDestinationCityTextFieldValueError, setHomeDestinationCityTextFieldValueError] = React.useState<
        string | undefined
    >(undefined);

    const [selectedDepartureCountry, setSelectedDepartureCountry] = React.useState(
        props.personalInfo.departureAddress.Country || ''
    );
    const [selectedDepartureCountryError, setSelectedDepartureCountryError] = React.useState<string | undefined>(
        undefined
    );
    const [selectedDepartureState, setSelectedDepartureState] = React.useState(
        props.personalInfo.departureAddress.StateProvince || ''
    );
    const [selectedDepartureStateError, setSelectedDepartureStateError] = React.useState<string | undefined>(undefined);
    const [selectedWorkDestinationCountry, setSelectedWorkDestinationCountry] = React.useState(
        props.personalInfo.workDestinationAddress.Country || ''
    );
    const [selectedWorkDestinationCountryError, setSelectedWorkDestinationCountryError] = React.useState<
        string | undefined
    >(undefined);
    const [selectedWorkDestinationState, setSelectedWorkDestinationState] = React.useState(
        props.personalInfo.workDestinationAddress.StateProvince || ''
    );
    const [selectedWorkDestinationStateError, setSelectedWorkDestinationStateError] = React.useState<
        string | undefined
    >(undefined);
    const [selectedHomeDestinationCountry, setSelectedHomeDestinationCountry] = React.useState(
        props.personalInfo.homeDestinationAddress.Country || ''
    );
    const [selectedHomeDestinationCountryError, setSelectedHomeDestinationCountryError] = React.useState<
        string | undefined
    >(undefined);
    const [selectedHomeDestinationState, setSelectedHomeDestinationState] = React.useState(
        props.personalInfo.homeDestinationAddress.StateProvince || ''
    );
    const [selectedHomeDestinationStateError, setSelectedHomeDestinationStateError] = React.useState<
        string | undefined
    >(undefined);

    const [countries, setCountries] = React.useState<IDropdownOption[]>([]);
    const [departureStates, setDepartureStates] = React.useState<IDropdownOption[]>([]);
    const [workDestinationState, setWorkDestinationState] = React.useState<IDropdownOption[]>([]);
    const [homeDestinationState, sethomeDestinationState] = React.useState<IDropdownOption[]>([]);
    const [toggleChecked, setChecked] = React.useState(false);

    const [selectedVisaPendingOption, setSelectedVisaPendingOption] = React.useState(
        props.personalInfo.personalDetails.VisaPending === 'Yes' ? '1' : '2'
    );

    const visaPendingOptions: IDropdownOption[] = [
        { key: '1', text: 'Yes' },
        { key: '2', text: 'No' },
    ];

    const textFieldStyles: Partial<ITextFieldStyles> = { fieldGroup: { width: 200 } };
    const { reviewFormService } = useServiceStore();
    React.useImperativeHandle(
        ref,
        () => ({
            validateForm() {
                let invalidFields: string[] = [];
                if (isNotNullOrUndefined(firstNameTextFieldValue)) {
                    invalidFields.push('firstName');
                }
                if (isNotNullOrUndefined(lastNameTextFieldValue)) {
                    invalidFields.push('lastName');
                }
                if (isNotNullOrUndefined(personalPhoneTextFieldValue)) {
                    invalidFields.push('personalPhone');
                }
                if (isNotNullOrUndefined(currentEmailTextFieldValue)) {
                    invalidFields.push('currentEmail');
                }
                if (isNotNullOrUndefined(departureAddressTextFieldValue)) {
                    invalidFields.push('depatureAddress');
                }
                if (isNotNullOrUndefined(departureCityTextFieldValue)) {
                    invalidFields.push('depatureCity');
                }
                if (isNotNullOrUndefined(selectedDepartureCountry)) {
                    invalidFields.push('depatureCountry');
                }
                if (isNotNullOrUndefined(selectedDepartureState)) {
                    invalidFields.push('depatureState');
                }
                if (isNotNullOrUndefined(workDestinationCityTextFieldValue)) {
                    invalidFields.push('workDestinationCity');
                }
                if (isNotNullOrUndefined(selectedWorkDestinationCountry)) {
                    invalidFields.push('workDestinationCountry');
                }
                if (isNotNullOrUndefined(selectedWorkDestinationState)) {
                    invalidFields.push('workDestinationState');
                }
                if (isNotNullOrUndefined(homeDestinationCityTextFieldValue)) {
                    invalidFields.push('homeDestinationCity');
                }
                if (isNotNullOrUndefined(selectedHomeDestinationCountry)) {
                    invalidFields.push('homeDestinationCountry');
                }
                if (isNotNullOrUndefined(selectedHomeDestinationState)) {
                    invalidFields.push('homeDestinationState');
                }
           
                return { errors: Array.from(new Set(invalidFields)) }
            }
        }),
    );
    const onChangeFirstNameFieldValue = React.useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            props.updatePersonalDetails('FirstName', newValue);
            if (isNotNullOrUndefined(newValue)) {
                setFirstNameTextFieldValueError('First Name is required.');
                props.erroredFields(['firstName']);
            } else {
                setFirstNameTextFieldValueError(undefined);
                props.clearedFields('firstName');
            }
        },
        [props]
    );
    const onChangeMiddleNameFieldValue = React.useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            props.updatePersonalDetails('MiddleName', newValue);
        },
        [props]
    );
    const onChangeLastNameFieldValue = React.useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            props.updatePersonalDetails('LastName', newValue);
            if (isNotNullOrUndefined(newValue)) {
                setLastNameTextFieldValueError('Last Name is required.');
                props.erroredFields(['lastName']);
            } else {
                setLastNameTextFieldValueError(undefined);
                props.clearedFields('lastName');
            }
        },
        [props]
    );
    const onChangePhoneTextFieldValue = React.useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            let numberpattern = /^[0-9=+]*$/;
            if (numberpattern.test(newValue!)) {
                props.updatePersonalDetails('PersonalPhone', newValue);
                if (isNotNullOrUndefined(newValue)) {
                    setPersonalPhoneTextFieldValueError('Personal Phone is required.');
                    props.erroredFields(['personalPhone']);
                } else {
                    setPersonalPhoneTextFieldValueError(undefined);
                    props.clearedFields('personalPhone');
                }
            }
        },
        [props]
    );
    const onChangeWorkPhoneFieldValue = React.useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            props.updatePersonalDetails('WorkPhone', newValue);
        },
        [props]
    );
    const onChangeAddressTextFieldValue = React.useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            props.updateDepartureAddress('Address', newValue);
            if (isNotNullOrUndefined(newValue)) {
                setDepartureAddressTextFieldValueError('Address is required.');
                props.erroredFields(['depatureAddress']);
            } else {
                setDepartureAddressTextFieldValueError(undefined);
                props.clearedFields('depatureAddress');
            }
        },
        [props]
    );
    const onChangeApartmentTextFieldValue = React.useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            props.updateDepartureAddress('Apartment', newValue);
        },
        [props]
    );
    const onChangeDeparturePostalTextFieldValue = React.useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            props.updateDepartureAddress('PostalCode', newValue);
        },
        [props]
    );
    const onChangeDepartureCityTextFieldValue = React.useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            props.updateDepartureAddress('City', newValue);
            if (isNotNullOrUndefined(newValue)) {
                setDepartureCityTextFieldValueError('City is required.');
                props.erroredFields(['depatureCity']);
            } else {
                setDepartureCityTextFieldValueError(undefined);
                props.clearedFields('depatureCity');
            }
        },
        [props]
    );
    const onChangeworkDestinationCityTextFieldValue = React.useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            props.updateDestinationAddress('City', newValue);
            if (isNotNullOrUndefined(newValue)) {
                setWorkDestinationCityTextFieldValueError('City is required.');
                props.erroredFields(['workDestinationCity']);
            } else {
                setWorkDestinationCityTextFieldValueError(undefined);
                props.clearedFields('workDestinationCity');
            }
        },
        [props]
    );
    const onChangehomeDestinationCityTextFieldValue = React.useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            if (isNotNullOrUndefined(newValue)) {
                setHomeDestinationCityTextFieldValueError('City is required.');
                props.erroredFields(['homeDestinationCity']);
            } else {
                props.homeDestinationAddressDetails('City', newValue);
                setHomeDestinationCityTextFieldValueError(undefined);
                props.clearedFields('homeDestinationCity');
            }
        },
        [props]
    );
    const onChangeCurrentEmailFieldValue = React.useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            props.updatePersonalDetails('CurrentEmail', newValue);
            let emailpattern = /^(?:\w+[\-!\.])*\w+@(?:\w+[\-!\.])*\w+\.\w+$/;
            if (isNotNullOrUndefined(newValue)) {
                setCurrentEmailTextFieldValueError('Current Email is required.');
                props.erroredFields(['currentEmail']);
            } else if (!isNotNullOrUndefined(newValue) && !emailpattern.test(newValue!)) {
                setCurrentEmailTextFieldValueError('Current Email is not valid.');
            } else {
                setCurrentEmailTextFieldValueError(undefined);
                props.clearedFields('currentEmail');
            }
        },
        [props]
    );
    var departureStateOptions: IDropdownOption[] = [];
    var destinationWorkStateOptions: IDropdownOption[] = [];
    var destinationHomeStateOptions: IDropdownOption[] = [];

    const dropdownStyles: Partial<IDropdownStyles> = {
        dropdown: { width: '100%' },
    };
    const togglePersonalInformation = () => {
        setIsOpen(!isOpen);
    };

    React.useEffect(() => {
        //Compare Personal Details
        if (props.personalInfo.personalDetails.FirstName !== firstNameTextFieldValue) {
            setFirstNameTextFieldValue(props.personalInfo.personalDetails.FirstName);
        }
        if (props.personalInfo.personalDetails.MiddleName !== middleNameTextFieldValue) {
            setMiddleNameTextFieldValue(props.personalInfo.personalDetails.MiddleName);
        }
        if (props.personalInfo.personalDetails.LastName !== lastNameTextFieldValue) {
            setLastNameTextFieldValue(props.personalInfo.personalDetails.LastName);
        }
        if (props.personalInfo.personalDetails.PersonalPhone !== personalPhoneTextFieldValue) {
            setPersonalPhoneTextFieldValue(props.personalInfo.personalDetails.PersonalPhone);
        }
        if (props.personalInfo.personalDetails.WorkPhone !== workPhoneTextFieldValue) {
            setWorkPhoneTextFieldValue(props.personalInfo.personalDetails.WorkPhone);
        }
        if (props.personalInfo.personalDetails.CurrentEmail !== currentEmailTextFieldValue) {
            setCurrentEmailTextFieldValue(props.personalInfo.personalDetails.CurrentEmail);
        }
        if (props.personalInfo.homeDestinationAddress.City !== homeDestinationCityTextFieldValue) {
            setHomeDestinationCityTextFieldValue(props.personalInfo.homeDestinationAddress.City);
        }
        if (props.personalInfo.workDestinationAddress.City !== workDestinationCityTextFieldValue) {
            setWorkDestinationCityTextFieldValue(props.personalInfo.workDestinationAddress.City);
        }

        if (props.personalInfo.departureAddress.Address !== departureAddressTextFieldValue) {
            setDepartureAddressTextFieldValue(props.personalInfo.departureAddress.Address);
        }

        if (props.personalInfo.departureAddress.Apartment !== departureAddressApartmentTextFieldValue) {
            setDepartureApartmentTextFieldValue(props.personalInfo.departureAddress.Apartment);
        }

        if (props.personalInfo.departureAddress.City !== departureCityTextFieldValue) {
            setDepartureCityTextFieldValue(props.personalInfo.departureAddress.City);
        }
        if (props.personalInfo.departureAddress.PostalCode !== departurePostalTextFieldValue) {
            setDeparturePostalTextFieldValue(props.personalInfo.departureAddress.PostalCode);
        }
    }, [props.personalInfo]);

    React.useEffect(() => {
        var countriesDropdown: IDropdownOption[] = [];
        let departCountry = props.personalInfo.departureAddress.Country;
        let destinationCountry = props.personalInfo.workDestinationAddress.Country;
        let homeCountry = props.personalInfo.homeDestinationAddress.Country;
        reviewFormService.getAllCountryNames().then((countries) => {
            countries.CountryNames.sort().forEach((country) => {
                countriesDropdown.push({ key: country, text: country });
                if (country === props.personalInfo.departureAddress.Country) {
                    setSelectedDepartureCountry(country);
                }
                if (country === props.personalInfo.workDestinationAddress.Country) {
                    setSelectedWorkDestinationCountry(country);
                }
                if (country === props.personalInfo.homeDestinationAddress.Country) {
                    setSelectedHomeDestinationCountry(country);
                }
            });
            setCountries(countriesDropdown);

            if(isNotNullOrUndefined(departCountry)){
                departureStateOptions.push({ key: 'NA', text: 'NA' });
                setDepartureStates(departureStateOptions);
            }else{
                reviewFormService.getAllStateNames(departCountry).then((departurestatedata) => {
                    if (departurestatedata.stateNames.length === 0) {
                        departureStateOptions.push({ key: 'NA', text: 'NA' });
                        setDepartureStates(departureStateOptions);
                    } else {
                        departurestatedata.stateNames.forEach((state) => {
                            if (state == props.personalInfo.departureAddress.StateProvince) {
                                setSelectedDepartureState(state);
                            }
                            departureStateOptions.push({ key: state, text: state });
                        });
                        setDepartureStates(departureStateOptions);
                    }
    
                });
            }

            if(isNotNullOrUndefined(destinationCountry)){
                destinationWorkStateOptions.push({ key: 'NA', text: 'NA', data: 'Departure' });
                setWorkDestinationState(destinationWorkStateOptions);
            }else{
                reviewFormService.getAllStateNames(destinationCountry).then((destinationworkstatedata) => {
                    if (destinationworkstatedata.stateNames.length === 0) {
                        destinationWorkStateOptions.push({ key: 'NA', text: 'NA', data: 'Departure' });
                        setWorkDestinationState(destinationWorkStateOptions);
                    } else {
                        destinationworkstatedata.stateNames.forEach((state) => {
                            if (state == props.personalInfo.workDestinationAddress.StateProvince) {
                                setSelectedWorkDestinationState(state);
                            }
                            destinationWorkStateOptions.push({ key: state, text: state, data: 'Departure' });
                        });
                        setWorkDestinationState(destinationWorkStateOptions);
                    }
    
                });
            }

            if(isNotNullOrUndefined(homeCountry)){
                destinationHomeStateOptions.push({ key: 'NA', text: 'NA' });
                    sethomeDestinationState(destinationHomeStateOptions);
            }else{
                reviewFormService.getAllStateNames(homeCountry).then((destinationhomestatedata) => {
                    if (destinationhomestatedata.stateNames.length === 0) {
                        destinationHomeStateOptions.push({ key: 'NA', text: 'NA' });
                        sethomeDestinationState(destinationHomeStateOptions);
                    } else {
                        destinationhomestatedata.stateNames.forEach((state) => {
                            if (state == props.personalInfo.homeDestinationAddress.StateProvince) {
                                setSelectedHomeDestinationState(state);
                            }
                            destinationHomeStateOptions.push({ key: state, text: state });
                        });
                        sethomeDestinationState(destinationHomeStateOptions);
                    }
                });
            }
        });
    }, []);

    React.useEffect(() => {
        let inp = document.querySelectorAll('input.ms-TextField-field');
        let btn = document.querySelectorAll('button.ms-Button.ms-Button--icon');
        let txt = document.querySelectorAll('textarea');
        let divs = document.querySelectorAll('div');

        btn.forEach((item) => {
            item.setAttribute('tabindex', '0');
            item.setAttribute('role', 'button');
            if (!item.hasAttribute('disabled')) {
                if (item.getAttribute('aria-hidden') == 'true') {
                    item.setAttribute('disabled', 'true');
                } else {
                    item.removeAttribute('disabled');
                }
            }
        });
        inp.forEach((item) => {
            item.setAttribute('role', 'textbox');
            item.setAttribute('tabindex', '0');
        });
        txt.forEach((item) => {
            item.setAttribute('role', 'textbox');
            item.setAttribute('tabindex', '0');
        });
        divs.forEach((item) => {
            if (item.getAttribute('role') == 'alertdialog') {
                item.setAttribute('title', 'alert-dialog');
            }
        })
    });

    enum StateType {
        DEPARTURE = 'Departure',
        WORKDESTINATION = 'WorkDestination',
        HOMEDESTINATION = 'HomeDestination',
    }
    const onCountryChangeDeparture = (
        event: React.FormEvent<HTMLDivElement> | undefined,
        item: IComboBoxOption | undefined
    ): void => {
        onCountryChange(event, item, StateType.DEPARTURE);
    };

    const onCountryChangeWorkDestination = (
        event: React.FormEvent<HTMLDivElement> | undefined,
        item: IComboBoxOption | undefined
    ): void => {
        onCountryChange(event, item, StateType.WORKDESTINATION);
    };
    const onCountryChangeHomeDestination = (
        event: React.FormEvent<HTMLDivElement> | undefined,
        item: IComboBoxOption | undefined
    ): void => {
        onCountryChange(event, item, StateType.HOMEDESTINATION);
    };

    const onCountryChange = (
        event: React.FormEvent<HTMLDivElement> | undefined,
        item: IComboBoxOption | undefined,
        country_Type: any
    ): void => {
        var countryType = country_Type;
        if (item) {
            if (countryType == StateType.DEPARTURE) {
                setSelectedDepartureCountry(item.key.toString());
                setAllStateNames(item.key.toString(), countryType);
                props.updateDepartureAddress('Country', item.key.toString());
                if (isNotNullOrUndefined(item.key.toString())) {
                    setSelectedDepartureCountryError('Country is required.');
                    props.erroredFields(['depatureCountry']);
                } else {
                    setSelectedDepartureCountryError(undefined);
                    props.clearedFields('depatureCountry');
                }
            }
            if (countryType == StateType.WORKDESTINATION) {
                setSelectedWorkDestinationCountry(item.key.toString());
                setAllStateNames(item.key.toString(), countryType);
                props.updateDestinationAddress('Country', item.key.toString());
                if (isNotNullOrUndefined(item.key.toString())) {
                    setSelectedWorkDestinationCountryError('Country is required.');
                    props.erroredFields(['workDestinationCountry']);
                } else {
                    setSelectedWorkDestinationCountryError(undefined);
                    props.clearedFields('workDestinationCountry');
                }
            }
            if (countryType == StateType.HOMEDESTINATION) {
                setSelectedHomeDestinationCountry(item.key.toString());
                setAllStateNames(item.key.toString(), countryType);
                props.homeDestinationAddressDetails('Country', item.key.toString());
                if (isNotNullOrUndefined(item.key.toString())) {
                    setSelectedHomeDestinationCountryError('Country is required.');
                    props.erroredFields(['homeDestinationCountry']);
                } else {
                    setSelectedHomeDestinationCountryError(undefined);
                    props.clearedFields('homeDestinationCountry');
                }
            }
        }
    };
    const onDepartureStateChange = (
        event: React.FormEvent<HTMLDivElement> | undefined,
        item: IDropdownOption | undefined
    ): void => {
        if (item) {
            setSelectedDepartureState(item.key.toString());
            props.updateDepartureAddress('StateProvince', item.key.toString());
            if (isNotNullOrUndefined(item.key.toString())) {
                setSelectedDepartureStateError('State/Province is required.');
                props.erroredFields(['depatureState']);
            } else {
                setSelectedDepartureStateError(undefined);
                props.clearedFields('depatureState');
            }
        }
    };
    const onHomeStateChange = (
        event: React.FormEvent<HTMLDivElement> | undefined,
        item: IDropdownOption | undefined
    ): void => {
        if (item) {
            setSelectedHomeDestinationState(item.key.toString());
            props.homeDestinationAddressDetails('StateProvince', item.key.toString());
            if (isNotNullOrUndefined(item.key.toString())) {
                setSelectedHomeDestinationStateError('State/Province is required.');
                props.erroredFields(['homeDestinationState']);
            } else {
                setSelectedHomeDestinationStateError(undefined);
                props.clearedFields('homeDestinationState');
            }
        }
    };
    const onWorkStateChange = (
        event: React.FormEvent<HTMLDivElement> | undefined,
        item: IDropdownOption | undefined
    ): void => {
        if (item) {
            setSelectedWorkDestinationState(item.key.toString());
            props.updateDestinationAddress('StateProvince', item.key.toString());
            if (isNotNullOrUndefined(item.key.toString())) {
                setSelectedWorkDestinationStateError('State/Province is required.');
                props.erroredFields(['workDestinationState']);
            } else {
                setSelectedWorkDestinationStateError(undefined);
                props.clearedFields('workDestinationState');
            }
        }
    };

    const setAllStateNames = async (countryname: string, stateType: string): Promise<void> => {
        if(isNotNullOrUndefined(countryname)){
            return;
        }
        let statesResponse = await getAllStateNames(countryname)
        if (stateType == StateType.DEPARTURE) {
            if (statesResponse.stateNames.length === 0) {
                departureStateOptions.push({ key: 'NA', text: 'NA' });
                setDepartureStates(departureStateOptions);
            } else {
                statesResponse.stateNames.sort().forEach((state) => {
                    departureStateOptions.push({ key: state, text: state });
                });
                setDepartureStates(departureStateOptions);
            }

        }
        if (stateType == StateType.WORKDESTINATION) {
            if (statesResponse.stateNames.length === 0) {
                destinationWorkStateOptions.push({ key: 'NA', text: 'NA' });
                setWorkDestinationState(destinationWorkStateOptions);
            } else {
                statesResponse.stateNames.sort().forEach((state) => {
                    destinationWorkStateOptions.push({ key: state, text: state });
                });
                setWorkDestinationState(destinationWorkStateOptions);
            }
        }
        if (stateType == StateType.HOMEDESTINATION) {
            if (statesResponse.stateNames.length === 0) {
                destinationHomeStateOptions.push({ key: 'NA', text: 'NA' });
                sethomeDestinationState(destinationHomeStateOptions);
            } else {
                statesResponse.stateNames.sort().forEach((state) => {
                    destinationHomeStateOptions.push({ key: state, text: state });
                });
                sethomeDestinationState(destinationHomeStateOptions);
            }
        }
    };

    const getAllStateNames = async (countryname: string): Promise<PersonalDetails> => {
        var states = {} as PersonalDetails;
        await reviewFormService.getAllStateNames(countryname).then((details) => {
            states = details;
        });
        return states;
    };
    const _setHomeDestinationAddress = () => {
        sethomeDestinationState(workDestinationState);
        setHomeDestinationCityTextFieldValue(workDestinationCityTextFieldValue);
        setSelectedHomeDestinationCountry(selectedWorkDestinationCountry);
        setSelectedHomeDestinationState(selectedWorkDestinationState);

        let tempHomeAddressObj = {
            Country: selectedWorkDestinationCountry,
            StateProvince: selectedWorkDestinationState,
            City: workDestinationCityTextFieldValue,
        };

        props.bulkHomeDestinationAddressUpdate(tempHomeAddressObj);

        setHomeDestinationCityTextFieldValueError(undefined);
        setSelectedHomeDestinationCountryError(undefined);
        setSelectedHomeDestinationStateError(undefined);
        props.clearedFields(['homeDestinationCity', 'homeDestinationCountry', 'homeDestinationState']);
    }

    function _onToggleChange(ev: React.MouseEvent<HTMLElement>, checked?: boolean) {
        setChecked(!checked);
        if (checked) {
            sethomeDestinationState(workDestinationState);

            setHomeDestinationCityTextFieldValue(workDestinationCityTextFieldValue);
            setSelectedHomeDestinationCountry(selectedWorkDestinationCountry);
            setSelectedHomeDestinationState(selectedWorkDestinationState);

            let tempHomeAddressObj = {
                Country: selectedWorkDestinationCountry,
                StateProvince: selectedWorkDestinationState,
                City: workDestinationCityTextFieldValue,
            };

            props.bulkHomeDestinationAddressUpdate(tempHomeAddressObj);

            setHomeDestinationCityTextFieldValueError(undefined);
            setSelectedHomeDestinationCountryError(undefined);
            setSelectedHomeDestinationStateError(undefined);
            props.clearedFields(['homeDestinationCity', 'homeDestinationCountry', 'homeDestinationState']);
        } else {
            setHomeDestinationCityTextFieldValue('');
            setSelectedHomeDestinationCountry('');
            setSelectedHomeDestinationState('');

            let tempHomeAddressObj = {
                Country: '',
                StateProvince: '',
                City: '',
            };

            props.bulkHomeDestinationAddressUpdate(tempHomeAddressObj);
        }
    }
    function _onVisaPendingToggleChange(
        event: React.FormEvent<HTMLDivElement> | undefined,
        item: IDropdownOption | undefined
    ) {
        setSelectedVisaPendingOption(item?.key as string);
        if (item!.key == '1') {
            props.updatePersonalDetails('VisaPending', 'Yes');
        } else {
            props.updatePersonalDetails('VisaPending', 'No');
        }
    }

    React.useEffect(() => {
        let errorFields = [];
        if (props.validateOnSubmit || props.validateOnReSubmit) {
            if (isNotNullOrUndefined(firstNameTextFieldValue)) {
                setFirstNameTextFieldValueError('First Name is required.');
                errorFields.push('firstName');
            }
            if (isNotNullOrUndefined(lastNameTextFieldValue)) {
                setLastNameTextFieldValueError('Last Name is required.');
                errorFields.push('lastName');
            }
            if (isNotNullOrUndefined(personalPhoneTextFieldValue)) {
                setPersonalPhoneTextFieldValueError('Personal Phone is required.');
                errorFields.push('personalPhone');
            }
            if (isNotNullOrUndefined(currentEmailTextFieldValue)) {
                setCurrentEmailTextFieldValueError('Current Email is required.');
                errorFields.push('currentEmail');
            }
            if (isNotNullOrUndefined(departureAddressTextFieldValue)) {
                setDepartureAddressTextFieldValueError('Address is required.');
                errorFields.push('depatureAddress');
            }
            if (isNotNullOrUndefined(departureCityTextFieldValue)) {
                setDepartureCityTextFieldValueError('City is required.');
                errorFields.push('depatureCity');
            }
            if (isNotNullOrUndefined(selectedDepartureCountry)) {
                setSelectedDepartureCountryError('Country is required.');
                errorFields.push('depatureCountry');
            }
            if (isNotNullOrUndefined(selectedDepartureState)) {
                setSelectedDepartureStateError('State/Province is required.');
                errorFields.push('depatureState');
            }
            if (isNotNullOrUndefined(workDestinationCityTextFieldValue)) {
                setWorkDestinationCityTextFieldValueError('City is required.');
                errorFields.push('workDestinationCity');
            }
            if (isNotNullOrUndefined(selectedWorkDestinationCountry)) {
                setSelectedWorkDestinationCountryError('Country is required.');
                errorFields.push('workDestinationCountry');
            }
            if (isNotNullOrUndefined(selectedWorkDestinationState)) {
                setSelectedWorkDestinationStateError('State/Province is required.');
                errorFields.push('workDestinationState');
            }
            if (isNotNullOrUndefined(homeDestinationCityTextFieldValue)) {
                setHomeDestinationCityTextFieldValueError('City is required.');
                errorFields.push('homeDestinationCity');
            }
            if (isNotNullOrUndefined(selectedHomeDestinationCountry)) {
                setSelectedHomeDestinationCountryError('Country is required.');
                errorFields.push('homeDestinationCountry');
            }
            if (isNotNullOrUndefined(selectedHomeDestinationState)) {
                setSelectedHomeDestinationStateError('State/Province is required.');
                errorFields.push('homeDestinationState');
            }
            props.updatePersonalDetails('VisaPending', selectedVisaPendingOption == '1' ? 'Yes' : 'No');
            props.erroredFields(errorFields);
        }
    }, [props.validateOnSubmit, props.validateOnReSubmit]);

    const isNotNullOrUndefined = (value: any) => {
        if (value === null || value === undefined) return true;
        if (typeof value === 'string' && value === '') return true;
        return false;
    };
    return (
        <Stack className="PersonalInformation" style={formSectionStyle}>
            <Stack horizontal={true}
                style={{ backgroundColor: 'rgb(235, 235, 235)', maxWidth: '100%', margin: '5px 0px 0px 5px' }}>
                <Stack.Item style={{ marginTop: '3px' }} className="ac-icon">
                    <IconButton
                        aria-labelledby="Personal Information"
                        title="Personal Information"
                        iconProps={{
                            iconName: isOpen ? 'ChevronUp' : 'ChevronDown',
                        }}
                        onClick={togglePersonalInformation}
                    />
                </Stack.Item>
                <Stack.Item align="center" styles={accordionHeader} className="ac-title">
                    <h2 style={{ fontSize: '20px' }}> Personal Information </h2>
                </Stack.Item>
            </Stack>
            {isOpen && (
                <Stack styles={accordionContent}>
                    {props.errorFieldsState.length > 0 && (
                        <MessageBar delayedRender={false} messageBarType={MessageBarType.error} role="alert">
                            We need all required information filled in before we can submit the form.Please fill in the
                            fields highlighted below and submit again.
                        </MessageBar>
                    )}
                    <Stack horizontal tokens={stackTokens} styles={stackStyles}>
                        <Stack.Item {...personalInfoColumnProps}>
                            <TextField
                                label="First Name"
                                value={firstNameTextFieldValue}
                                onChange={onChangeFirstNameFieldValue}
                                styles={textFieldStyles}
                                errorMessage={firstNameTextFieldValueError}
                                required
                            />
                        </Stack.Item>
                        <Stack.Item {...personalInfoColumnProps}>
                            <TextField
                                label="Middle Name (optional)"
                                value={middleNameTextFieldValue}
                                onChange={onChangeMiddleNameFieldValue}
                                styles={textFieldStyles}
                            />
                        </Stack.Item>
                        <Stack.Item {...personalInfoColumnProps}>
                            <TextField
                                label="Last Name"
                                value={lastNameTextFieldValue}
                                onChange={onChangeLastNameFieldValue}
                                styles={textFieldStyles}
                                errorMessage={lastNameTextFieldValueError}
                                required
                            />
                        </Stack.Item>
                        <Stack.Item {...personalInfoColumnProps}>
                            <TextField
                                label="Personal Phone"
                                value={personalPhoneTextFieldValue}
                                onChange={onChangePhoneTextFieldValue}
                                styles={textFieldStyles}
                                errorMessage={personalPhoneTextFieldValueError}
                                required
                            />
                        </Stack.Item>
                    </Stack>
                    <Stack horizontal tokens={stackTokens} styles={stackStyles}>
                        <Stack.Item {...personalInfoColumnProps}>
                            <TextField
                                label="Work Phone (optional)"
                                value={workPhoneTextFieldValue}
                                onChange={onChangeWorkPhoneFieldValue}
                                styles={textFieldStyles}
                            />
                        </Stack.Item>
                        <Stack.Item {...personalInfoColumnProps}>
                            <TextField
                                label="Current Email"
                                value={currentEmailTextFieldValue}
                                onChange={onChangeCurrentEmailFieldValue}
                                styles={textFieldStyles}
                                errorMessage={currentEmailTextFieldValueError}
                                required
                            />
                        </Stack.Item>
                        <Stack.Item {...personalInfoColumnProps}>
                            <Dropdown
                                label="Visa Pending?"
                                aria-required="true"
                                options={visaPendingOptions}
                                onChange={_onVisaPendingToggleChange}
                                selectedKey={selectedVisaPendingOption}
                                required
                            ></Dropdown>
                        </Stack.Item>
                    </Stack>

                    <Stack horizontal tokens={stackTokens} styles={stackStyles} style={{ padding: "14px 2px" }}>
                        <Stack {...personalInfoDepAddColumnProps}>
                            <h3 style={{ backgroundColor: 'rgb(235, 235, 235)', padding: '4px' }}>
                                Departure Address
                            </h3>
                            <Stack style={{ backgroundColor: 'white', boxShadow: 'rgb(0 0 0 / 16%) 0px 1px 4px', marginTop: '0px' }}>
                                <Stack horizontal tokens={stackTokens} styles={stackStyles}>
                                    <Stack {...personalInfoColumnProps}>
                                        <ComboBox
                                            label="Country"
                                            onChange={onCountryChangeDeparture}
                                            allowFreeform={true}
                                            autoComplete="on"
                                            data-attr={StateType.DEPARTURE}
                                            selectedKey={selectedDepartureCountry}
                                            options={countries}
                                            styles={dropdownStyles}
                                            errorMessage={selectedDepartureCountryError}
                                            required
                                        />
                                    </Stack>
                                    <Stack {...personalInfoColumnProps}>
                                        <TextField
                                            label="Address"
                                            value={departureAddressTextFieldValue}
                                            onChange={onChangeAddressTextFieldValue}
                                            styles={textFieldStyles}
                                            errorMessage={departureAddressTextFieldValueError}
                                            required
                                        />
                                    </Stack>
                                    <Stack {...personalInfoColumnProps}>
                                        <TextField
                                            label="Apartment/Suite (optional)"
                                            value={departureAddressApartmentTextFieldValue}
                                            onChange={onChangeApartmentTextFieldValue}
                                            styles={textFieldStyles}
                                            maxLength={80}
                                        />
                                    </Stack>
                                    <Stack {...threeColumnProps}>
                                        <TextField
                                            label="City"
                                            value={departureCityTextFieldValue}
                                            onChange={onChangeDepartureCityTextFieldValue}
                                            styles={textFieldStyles}
                                            errorMessage={departureCityTextFieldValueError}
                                            required
                                        />
                                    </Stack>
                                </Stack>
                                <Stack horizontal tokens={stackTokens} styles={stackStyles}>
                                    <Stack {...personalInfoColumnProps}>
                                        <ComboBox
                                            label="State/Province"
                                            selectedKey={selectedDepartureState}
                                            data-attr={StateType.DEPARTURE}
                                            onChange={onDepartureStateChange}
                                            allowFreeform={true}
                                            autoComplete="on"
                                            options={departureStates}
                                            styles={dropdownStyles}
                                            errorMessage={selectedDepartureStateError}
                                            required
                                        />
                                    </Stack>
                                    <Stack {...personalInfoColumnProps}>
                                        <TextField
                                            label="Postal Code (optional)"
                                            value={departurePostalTextFieldValue}
                                            onChange={onChangeDeparturePostalTextFieldValue}
                                            styles={textFieldStyles}
                                            maxLength={10}
                                        />
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>
                    <Stack horizontal tokens={stackTokens} styles={stackStyles}>
                        <Stack {...personalInfoDepAddColumnProps}>
                            <h3 style={{ backgroundColor: 'rgb(235, 235, 235)', padding: '4px' }}>
                                Destination Address
                            </h3>
                            <Stack style={{ backgroundColor: 'white', boxShadow: 'rgb(0 0 0 / 16%) 0px 1px 4px', marginTop: '0px' }}>
                                <h4 style={{ paddingLeft: '10px' }}>Work Destination Address</h4>
                                <Stack horizontal tokens={stackTokens} styles={stackStyles}>
                                    <Stack {...personalInfoColumnProps}>
                                        <ComboBox
                                            label="Country"
                                            selectedKey={selectedWorkDestinationCountry}
                                            data-attr={StateType.WORKDESTINATION}
                                            onChange={onCountryChangeWorkDestination}
                                            allowFreeform={true}
                                            autoComplete="on"
                                            options={countries}
                                            styles={dropdownStyles}
                                            errorMessage={selectedWorkDestinationCountryError}
                                            onBlur={_setHomeDestinationAddress}
                                            required
                                        />
                                    </Stack>
                                    <Stack {...personalInfoColumnProps}>
                                        <TextField
                                            label="City"
                                            value={workDestinationCityTextFieldValue}
                                            onChange={onChangeworkDestinationCityTextFieldValue}
                                            styles={textFieldStyles}
                                            errorMessage={workDestinationCityTextFieldValueError}
                                            onBlur={_setHomeDestinationAddress}
                                            required
                                        />
                                    </Stack>
                                    <Stack {...personalInfoColumnProps}>
                                        <ComboBox
                                            label="State/Province"
                                            selectedKey={selectedWorkDestinationState}
                                            data-attr={StateType.WORKDESTINATION}
                                            options={workDestinationState}
                                            allowFreeform={true}
                                            autoComplete="on"
                                            onChange={onWorkStateChange}
                                            styles={dropdownStyles}
                                            errorMessage={selectedWorkDestinationStateError}
                                            onBlur={_setHomeDestinationAddress}
                                            required
                                        />
                                    </Stack>
                                </Stack>
                                <hr />
                                <div style={{display:'none'}}>
                                <Stack>
                                    <div>
                                        <h4 style={{ paddingLeft: '10px', width: '43%', float: 'left' }}>
                                            Home Destination Address
                                        </h4>
                                        <div style={{ float: 'right' }}>
                                            <Toggle
                                                label="Same as Work Destination Address"
                                                defaultChecked={true}
                                                inlineLabel
                                                onText="Yes"
                                                offText="No"
                                                onChange={_onToggleChange}
                                            />
                                        </div>
                                    </div>
                                    <Stack horizontal tokens={stackTokens} styles={stackStyles}>
                                        <Stack {...personalInfoColumnProps}>
                                            <ComboBox
                                                label="Country"
                                                selectedKey={selectedHomeDestinationCountry}
                                                data-attr={StateType.HOMEDESTINATION}
                                                onChange={onCountryChangeHomeDestination}
                                                options={countries}
                                                styles={dropdownStyles}
                                                errorMessage={selectedHomeDestinationCountryError}
                                            />
                                        </Stack>
                                        <Stack {...personalInfoColumnProps}>
                                            <TextField
                                                label="City"
                                                value={homeDestinationCityTextFieldValue}
                                                onChange={onChangehomeDestinationCityTextFieldValue}
                                                styles={textFieldStyles}
                                                errorMessage={homeDestinationCityTextFieldValueError}
                                            />
                                        </Stack>
                                        <Stack {...personalInfoColumnProps}>
                                            <ComboBox
                                                label="State/Province"
                                                selectedKey={selectedHomeDestinationState}
                                                data-attr={StateType.HOMEDESTINATION}
                                                options={homeDestinationState}
                                                onChange={onHomeStateChange}
                                                styles={dropdownStyles}
                                                errorMessage={selectedHomeDestinationStateError}
                                            />
                                        </Stack>
                                    </Stack>
                                </Stack>
                                </div>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            )}
        </Stack>
    );
});
