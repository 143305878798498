import { IColumn } from '@fluentui/react';

export const ExportToFinanceColumns: IColumn[] = [
    {
        key: 'Account',
        name: 'ACCOUNT',
        fieldName: 'Account',
        minWidth: 90,
        maxWidth: 90,
    },
    {
        key: 'CostCenter',
        name: 'COST CENTER',
        fieldName: 'CostCenter',
        minWidth: 90,
        maxWidth: 90,
    },
    {
        key: 'Amount',
        name: 'AMOUNT',
        fieldName: 'Amount',
        minWidth: 90,
        maxWidth: 90,
    },
    {
        key: 'LineDescription',
        name: 'LINE DESCRIPTION',
        fieldName: 'LineDescription',
        minWidth: 140,
        maxWidth: 140,
    },
];


export const ExportToFinanceColumnsExcel: IColumn[] = [
    {
        key: 'Account',
        name: 'ACCOUNT',
        fieldName: 'Account',
        minWidth: 90,
        maxWidth: 90,
    },
    {
        key: 'CostCenter',
        name: 'COST CENTER',
        fieldName: 'CostCenter',
        minWidth: 90,
        maxWidth: 90,
    },
    {
        key: 'DestinationCompanyCode',
        name: 'DESTINATION COMPANY CODE',
        fieldName: 'DestinationCompanyCode',
        minWidth: 140,
        maxWidth: 140,
    },
    {
        key: 'Amount',
        name: 'AMOUNT',
        fieldName: 'Amount',
        minWidth: 90,
        maxWidth: 90,
    },
    {
        key: 'LineDescription',
        name: 'LINE DESCRIPTION',
        fieldName: 'LineDescription',
        minWidth: 140,
        maxWidth: 140,
    },
];