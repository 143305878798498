import styled from 'styled-components'; 

export const Main = styled.main`
  // Flex for sticky footer.
  flex: 1 0 auto;
  // Adding space for header.
  height: auto;
  width: auto;

  @media (max-width: 1025px) {
    &.navCollapsed {
      margin-left: 0px;
      width: 100%;
    }

    &.navExpanded {
      margin-left: 228px;
      width: calc(100% - 228px);
    }
  }
  @media (min-width: 1026px) {
    &.navCollapsed {
      margin-left: 48px;
      width: calc(100% - 48px);
    }

    &.navExpanded {
      margin-left: 228px;
      width: calc(100% - 228px);
    }
  }
`;