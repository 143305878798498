import * as React from 'react';
import {
    Checkbox,
    ChoiceGroup,
    PrimaryButton,
    Dropdown,
    IChoiceGroupOption,
    IDropdownOption,
    Panel,
    PanelType,
    SearchBox,
    Stack,
} from '@fluentui/react';
import { DatePicker } from 'office-ui-fabric-react';
import { getStyles } from '../Shared/FilterPane.styles';
import * as moment from 'moment';
import { MyGlomocoeQueueFilterComponentProps } from './GlomocoeQueueTable.types';

export const FilterPane: React.FC<MyGlomocoeQueueFilterComponentProps> = React.forwardRef((props, ref) => {
    const visaPendingOptions: IChoiceGroupOption[] = [
        { key: '1', text: 'Yes' },
        { key: '2', text: 'No' },
        { key: '3', text: 'All' },
    ];

    const dropdownOptions = [
        { key: 'recordId', text: 'Record ID' },
        { key: 'name', text: 'Name' },
    ];

    const styles = getStyles();
    const [dropDownValue, setdropDownValue] = React.useState<string>('Select One');
    const [searchKeyword, setsearchKeyword] = React.useState<string>('');
    const [statusValues, setstatusValues] = React.useState<string[]>([]);
    const [isCheckedReady, setisCheckedReady] = React.useState<boolean>(false);
    const [isCheckedResubmitted, setisCheckedResubmitted] = React.useState<boolean>(false);
    const [departureStatusValues, setdepartureStatusValues] = React.useState<string[]>([]);
    const [isCheckedHomeowner, setisCheckedHomeowner] = React.useState<boolean>(false);
    const [isCheckedRenter, setisCheckedRenter] = React.useState<boolean>(false);
    const [isCheckedEverSendBack, setisCheckedEverSendBack] = React.useState<boolean>(false);
    const [visaPendingValue, setvisaPendingValue] = React.useState<string>('3');
    const [everSendBackValue, seteverSendBackValue] = React.useState<string>('');
    const [estimatedFromDate, setestimatedFromDate] = React.useState<Date>();
    const [estimatedToDate, setestimatedToDate] = React.useState<Date>();
    React.useImperativeHandle(
        ref,
        () => ({
            ResetFilterCallFromMyRelo() {
                resetFilter();
            }
        }))

    return (
        <Panel
            type={PanelType.smallFixedFar}
            headerText={'Search this Queue'}
            isOpen={props.isOpen}
            onDismiss={props._closePanel}
            hasCloseButton={true}
            isLightDismiss={true}
            closeButtonAriaLabel="Close Filter Panel"
        >
            <div className={styles.filterContainer}>
                <Stack tokens={{ childrenGap: 10 }}>
                    <Dropdown
                        label="Select an Option"
                        onChange={onDropdownChange}
                        placeholder="Select One"
                        selectedKey={dropDownValue}
                        options={dropdownOptions}
                        // styles={dropdownStyles}
                    />
                    <SearchBox
                        // styles={searchBoxStyles}
                        placeholder="Search"
                        onEscape={(ev) => {}}
                        onClear={(ev) => {}}
                        onChange={(_, newValue) => setsearchKeyword(newValue || '')}
                        onSearch={searchResults}
                        value={searchKeyword}
                    />

                    <Stack.Item>
                        <PrimaryButton
                            styles={{ root: { marginTop: 20, marginRight: 10 } }}
                            text={'Search'}
                            onClick={searchResults}
                        />
                        <PrimaryButton styles={{ root: { marginTop: 20 } }} text={'Reset'} onClick={resetResults} />
                    </Stack.Item>
                </Stack>

                <Stack tokens={{ childrenGap: 10 }}>
                    <h2 className={styles.filterTitle}>Filters</h2>
                    <Stack.Item>
                        <section>
                            <h3 className={styles.sectionTitles}>Status</h3>
                            <Checkbox
                                label="Ready to Initiate"
                                name="Ready"
                                className={styles.checkbox}
                                inputProps={{ value: 'Submitted' }}
                                checked={isCheckedReady}
                                onChange={handleStatusFilter}
                            />
                            <Checkbox
                                label="Resubmitted"
                                name="Resubmitted"
                                className={styles.checkbox}
                                inputProps={{ value: 'Resubmitted' }}
                                checked={isCheckedResubmitted}
                                onChange={handleStatusFilter}
                            />
                        </section>
                    </Stack.Item>
                    <Stack.Item>
                        <h3 className={styles.sectionTitles}>Visa Status</h3>
                        <ChoiceGroup
                            options={visaPendingOptions}
                            selectedKey={visaPendingValue}
                            className={styles.checkbox}
                            onChange={handleVisaPendingValue}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <h3 className={styles.sectionTitles}>Ever Sendback</h3>
                        <Checkbox
                            label="Ever SendBack"
                            name="SendBack"
                            inputProps={{ value: 'SendBack' }}
                            checked={isCheckedEverSendBack}
                            onChange={handleEverSendBackValue}
                            className={styles.checkbox}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <h3 className={styles.sectionTitles}>Departure Status</h3>
                        <Checkbox
                            label="Homeowner"
                            name="Homeowner"
                            className={styles.checkbox}
                            inputProps={{ value: 'Homeowner' }}
                            checked={isCheckedHomeowner}
                            onChange={handleDepartureStatusFilter}
                        />
                        <Checkbox
                            label="Renter"
                            name="Renter"
                            className={styles.checkbox}
                            inputProps={{ value: 'Renter' }}
                            checked={isCheckedRenter}
                            onChange={handleDepartureStatusFilter}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <h3 className={styles.sectionTitles}>ESTIMATED START DATE</h3>
                        <DatePicker
                            // styles={datePickerStyles}
                            label="From"
                            placeholder="Select a date..."
                            ariaLabel="Select a date"
                            value={estimatedFromDate}
                            onSelectDate={(newDate) => setestimatedFromDate(newDate || undefined)}
                        />
                        <DatePicker
                            // styles={datePickerStyles}
                            label="To"
                            placeholder="Select a date..."
                            ariaLabel="Select a date"
                            value={estimatedToDate}
                            onSelectDate={(newDate) => setestimatedToDate(newDate || undefined)}
                        />
                    </Stack.Item>
                </Stack>
                <Stack>
                    <Stack.Item>
                        <PrimaryButton
                            styles={{ root: { marginTop: 20, marginRight: 10 } }}
                            text="Filter"
                            onClick={filterByStatus}
                        />
                        <PrimaryButton styles={{ root: { marginTop: 20 } }} text="Reset" onClick={resetFilter} />
                    </Stack.Item>
                </Stack>
            </div>
        </Panel>
    );

    function filterByStatus() {
        let filteredData = props.apiData;
        const innerArray = props.apiData[0];
        let IsResetButtonToBeShown:boolean =false;
        if (statusValues.length > 0) {
            IsResetButtonToBeShown =true;
            statusValues.map((selStatus, index) => {
                if (index === 0) {
                    if (Array.isArray(innerArray)) {
                        filteredData = innerArray.filter((row) => row.Status.toString() === selStatus);
                    }
                    else{
                        filteredData = props.apiData.filter((row) => row.Status.toString() === selStatus);
                    }
                    
                } else {
                    if(Array.isArray(innerArray)){
                        filteredData = filteredData.concat(
                            innerArray.filter((row) => row.Status.toString() === selStatus)
                        );
                    }
                    else{
                        filteredData = filteredData.concat(
                            props.apiData.filter((row) => row.Status.toString() === selStatus)
                        );
                    }
                }
            });
        }
        if (visaPendingValue != '3') {
            IsResetButtonToBeShown =true;
            if(Array.isArray(innerArray)){
                filteredData =
                visaPendingValue === '1'
                    ? innerArray.filter((row) => row.VisaStatus === true)
                    : innerArray.filter((row) => row.VisaStatus === false);
            }
            else{
                filteredData =
                visaPendingValue === '1'
                    ? filteredData.filter((row) => row.VisaStatus === true)
                    : filteredData.filter((row) => row.VisaStatus === false);
            }
            
        }
        if (everSendBackValue.length > 0) {
            IsResetButtonToBeShown =true;
            if(Array.isArray(innerArray)){
                filteredData =
                everSendBackValue === 'SendBack'
                    ? innerArray.filter((row) => row.EverSendBack === true)
                    : innerArray.filter((row) => row.EverSendBack === true || row.EverSendBack === false);
            }
            else{
                filteredData =
                everSendBackValue === 'SendBack'
                    ? filteredData.filter((row) => row.EverSendBack === true)
                    : filteredData.filter((row) => row.EverSendBack === true || row.EverSendBack === false);
            }
            
        }
        if (departureStatusValues.length > 0) {
            IsResetButtonToBeShown =true;
            departureStatusValues.map((Status, index) => {
                if (index === 0) {
                    if (Array.isArray(innerArray)) {
                        filteredData = innerArray.filter((row) => row.DepartureStatus?.toString() === Status);
                    }
                    else{
                        filteredData = props.apiData.filter((row) => row.DepartureStatus?.toString() === Status);
                    }
                } else {
                    if (Array.isArray(innerArray)) {
                        filteredData = filteredData.concat(
                            innerArray.filter((row) => row.DepartureStatus?.toString() === Status)
                        );
                    }
                    else{
                        filteredData = filteredData.concat(
                            props.apiData.filter((row) => row.DepartureStatus?.toString() === Status)
                        );
                    }
                }
            });
        }
        if (estimatedFromDate != undefined) {
            IsResetButtonToBeShown =true;
            if (Array.isArray(innerArray)){
                filteredData = innerArray.filter(
                    (row) => moment(row.EstimatedStartDate).toDate() >= moment(estimatedFromDate).toDate()
                );
            }
            else{
                filteredData = filteredData.filter(
                    (row) => moment(row.EstimatedStartDate).toDate() >= moment(estimatedFromDate).toDate()
                );
            }
        }
        if (estimatedToDate != undefined) {
            IsResetButtonToBeShown =true;
            if (Array.isArray(innerArray)){
                filteredData = innerArray.filter(
                    (row) => moment(row.EstimatedStartDate).toDate() <= moment(estimatedToDate).toDate()
                );
            }
            else{
                filteredData = filteredData.filter(
                    (row) => moment(row.EstimatedStartDate).toDate() <= moment(estimatedToDate).toDate()
                );
            }
           
        }
        if(IsResetButtonToBeShown)
        {
            props.ShowResetButton();
        }
        if (Array.isArray(innerArray)) {
            props.handleFilterSubmit(filteredData.flat(1));      
        }
        else{
            props.handleFilterSubmit(filteredData);
        }
        
    }

    function handleStatusFilter(event?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void {
        let curStatusValues = statusValues;
        const status = (event!.currentTarget as HTMLInputElement).value;

        if (checked) {
            switch (status) {
                case 'Resubmitted':
                    setisCheckedResubmitted(true);
                    break;
                case 'Submitted':
                    setisCheckedReady(true);
                    break;
            }
            curStatusValues.push(status);
        } else {
            const index = curStatusValues.indexOf(status);
            curStatusValues.splice(index, 1);
            switch (status) {
                case 'Resubmitted':
                    setisCheckedResubmitted(false);
                    break;
                case 'Submitted':
                    setisCheckedReady(false);
                    break;
            }
        }
        setstatusValues(curStatusValues);
    }

    function handleVisaPendingValue(
        event?: React.FormEvent<HTMLElement | HTMLInputElement>,
        option?: IChoiceGroupOption
    ): void {
        setvisaPendingValue(option!.key);
    }

    function handleEverSendBackValue(event?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void {
        if (checked) {
            setisCheckedEverSendBack(true);
            seteverSendBackValue((event?.currentTarget as HTMLInputElement).value);
        } else {
            seteverSendBackValue('');
            setisCheckedEverSendBack(false);
        }
    }

    function onDropdownChange(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void {
        setdropDownValue(option!.key as string);
    }

    function searchResults() {
        let filteredData = props.apiData;
        const innerArray = props.apiData[0];
        switch (dropDownValue) {
            case 'recordId':
                if (Array.isArray(innerArray)) {
                    filteredData = innerArray.filter((row) => row.RecordID.toString() === searchKeyword);
                }
                else{
                    filteredData = props.apiData.filter((row) => row.RecordID.toString() === searchKeyword);
                }
                break;
            case 'name':
                if (Array.isArray(innerArray)) {
                    filteredData = innerArray.filter((row) => {
                        return (
                            row.FullName.toLowerCase()
                                .replaceAll(' ', '')
                                .indexOf(searchKeyword.toLowerCase().replaceAll(' ', '')) > -1
                        );
                    });
                }
                else{
                    filteredData = props.apiData.filter((row) => {
                        return (
                            row.FullName.toLowerCase()
                                .replaceAll(' ', '')
                                .indexOf(searchKeyword.toLowerCase().replaceAll(' ', '')) > -1
                        );
                    });
                }
                break;
            case 'Select One': // not sure if this is necessary anymore since setting initially
                if (Array.isArray(innerArray)) {
                    filteredData = innerArray;       
                }
                else{
                    filteredData = props.apiData;
                }
                break;
        }
        if((searchKeyword.length>0) && (dropDownValue != "Select One") ) 
        {
            props.ShowResetButton();
        }
        props.handleFilterSubmit(filteredData);
    }

    function handleDepartureStatusFilter(
        event?: React.FormEvent<HTMLElement | HTMLInputElement>,
        checked?: boolean
    ): void {
        let curStatusValues = departureStatusValues;
        let status = (event!.currentTarget as HTMLInputElement).value;

        if (checked) {
            switch (status) {
                case 'Homeowner':
                    setisCheckedHomeowner(true);
                    break;
                case 'Renter':
                    setisCheckedRenter(true);
                    break;
            }
            curStatusValues.push(status);
        } else {
            let index = curStatusValues.indexOf(status);
            curStatusValues.splice(index, 1);
            switch (status) {
                case 'Homeowner':
                    setisCheckedHomeowner(false);
                    break;
                case 'Renter':
                    setisCheckedRenter(false);
                    break;
            }
        }
        setdepartureStatusValues(curStatusValues);
    }

    function resetFilter(): void {
        const innerArray = props.apiData[0];
        setstatusValues([]);
        setisCheckedReady(false);
        setisCheckedResubmitted(false);
        setisCheckedEverSendBack(false);
        setdepartureStatusValues([]);
        setisCheckedRenter(false);
        setisCheckedHomeowner(false);
        setvisaPendingValue('3');
        seteverSendBackValue('');
        setestimatedFromDate(undefined);
        setestimatedToDate(undefined);
        setdropDownValue('Select One');
        setsearchKeyword('');
        if (Array.isArray(innerArray)) {
            props.handleFilterSubmit(innerArray);           
        }
        else{
            props.handleFilterSubmit(props.apiData);
        }
        props.HideResetButton();
    }

    function resetResults() {
        const innerArray = props.apiData[0];
        setstatusValues([]);
        setisCheckedReady(false);
        setisCheckedResubmitted(false);
        setdepartureStatusValues([]);
        setisCheckedEverSendBack(false);
        setisCheckedRenter(false);
        setisCheckedHomeowner(false);
        setvisaPendingValue('3');
        seteverSendBackValue('');
        setestimatedFromDate(undefined);
        setestimatedToDate(undefined);
        setdropDownValue('Select One');
        setsearchKeyword('');
        if (Array.isArray(innerArray)) {
            props.handleFilterSubmit(innerArray);         
        }
        else{
            props.handleFilterSubmit(props.apiData);
        }
        props.HideResetButton();    
    }
});
