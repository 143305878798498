import { IHttpClient, IHttpClientResult } from '@employee-experience/common';
import { IEstimatesQueueForm, IUpdateCommitStatus } from '../../Models/IEstimatesQueue';

export class EstimatesQueueService {
    private httpClient: IHttpClient;
    private url: string;
    private apiURL: string;

    public constructor(httpClient: IHttpClient) {
        this.httpClient = httpClient;
        this.url = REACT_APP_RELO_API_URL;
        this.apiURL = '';
    }

    public async getQueueRecords(EndDate: string, StartDate: string, Status: string): Promise<IEstimatesQueueForm[]> {
        if (__IS_ACCESSIBILITY_TESTING__ == 'true') {
            this.apiURL = `${this.url}/api/reloform/GetEstimatedQueueRecords`;
        } else {
            this.apiURL = `${this.url}/api/reloform/GetEstimatedQueueRecords?StartDate=${StartDate}&EndDate=${EndDate}&Status=${Status}`;
        }
        const response: IHttpClientResult<IEstimatesQueueForm[]> = await this.httpClient.request({
            url: `${this.apiURL}`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    }

    public async UpdateBulkCommitStatus(ReloId: string, CommitStatus: string): Promise<string> {
        var UpdateCommitStatus: IUpdateCommitStatus = {} as IUpdateCommitStatus;
        UpdateCommitStatus.ReloIds = ReloId;
        UpdateCommitStatus.CommitStatus = CommitStatus;
        const request: IHttpClientResult<string> = await this.httpClient.request({
            url: `${this.url}api/reloform/UpdateBulkCommitStatus`,
            method: 'POST',
            resource: __CLIENT_ID__,
            data: UpdateCommitStatus,
        });
        return request?.data.toString();
    }
}
