import * as React from 'react';
import { TileData } from './RelocampQueueTable.types';
import * as TileStyle from './RelocampTile.styles';

export const RelocampTile: React.FC<{ instance: number; objParent: TileData; filterByTiles: any }> = ({
    instance,
    objParent,
    filterByTiles,
}) => {
    const onItemActivate = (event: any) => {
        let allSelectedAttr = document.querySelectorAll('[aria-selected="true"]');
        if (allSelectedAttr && allSelectedAttr.length > 0) {
            allSelectedAttr.forEach((item) => item.removeAttribute('aria-selected'));
        }
        let targetId = event.currentTarget.id;
        let targetEle = document.getElementById(targetId);
        if (targetEle) {
            targetEle.setAttribute('aria-selected', 'true');
        }
    };
    return (
        <table role="presentation" cellPadding="0" className="queueTile" cellSpacing="0" style={TileStyle.queueTile}>
            <tbody>
                <tr>
                    <th className="FieldHeaderDesign" style={TileStyle.fieldHeaderDesign}>
                        <span>{objParent.header}</span>
                    </th>
                </tr>
            </tbody>
            <tbody>
                <tr>
                    <td style={TileStyle.tiles} className="text">
                        <div
                            role="tablist"
                            aria-busy="true"
                            id={`${TileStyle.tilesCount}-${instance}`}
                            className="tilesCount"
                            style={TileStyle.tilesCount}
                            onClick={(e) => {
                                filterByTiles(objParent.child1.totalCountData);
                                onItemActivate(e);
                            }}
                            tabIndex={0}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    filterByTiles(objParent.child1.totalCountData);
                                    onItemActivate(e);
                                }
                            }}
                        >
                            {objParent.totalCount}
                        </div>
                    </td>
                </tr>
            </tbody>
            <tbody>
                <tr className="FieldCellDesign" style={TileStyle.fieldCellDesign}>
                    <td style={{ border: 'thick' }}>
                        {objParent.child1 && objParent.child1.relTypeDataLabelId && (
                            <div
                                id={`${objParent.child1.relTypeDataLabelId}`}
                                className="FieldCellDesign"
                                style={TileStyle.relTypeDataStyle}
                                onClick={(e) => {
                                    filterByTiles(objParent.child1.relTypeDataLabelId);
                                    onItemActivate(e);
                                }}
                                tabIndex={0}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        filterByTiles(objParent.child1.relTypeDataLabelId);
                                        onItemActivate(e);
                                    }
                                }}
                            >
                                {objParent.child1.relTypeData1}
                            </div>
                        )}
                    </td>
                </tr>
            </tbody>
            {objParent.child?.map((childObj) => {
                return (
                    <tbody>
                        <tr className="FieldCellDesign" style={TileStyle.fieldCellDesign}>
                            <td style={{ border: 'thick' }}>
                                {childObj && (
                                    <div
                                        id={`${childObj!.relTypeDataLabelId}`}
                                        className="FieldCellDesign"
                                        style={TileStyle.relTypeDataStyle}
                                        onClick={(e) => {
                                            filterByTiles(childObj!.relTypeDataLabelId);
                                            onItemActivate(e);
                                        }}
                                        tabIndex={0}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                filterByTiles(childObj!.relTypeDataLabelId);
                                                onItemActivate(e);
                                            }
                                        }}
                                    >
                                        {childObj.relTypeData}
                                    </div>
                                )}
                            </td>
                        </tr>
                    </tbody>
                );
            })}
        </table>
    );
};
