import * as React from 'react';
import { usePageTitle } from '@employee-experience/common/lib/usePageTitle';
import { ReduxContext } from '@employee-experience/common';
import { useServiceStore } from '../../RootStateContext';
import {
    PrimaryButton,
    DetailsListLayoutMode,
    DetailsRow,
    IColumn,
    IComboBoxOption,
    IDetailsRowBaseProps,
    IDetailsRowStyles,
    IStackItemStyles,
    IStackTokens,
    SelectionMode,
    ShimmeredDetailsList,
    Stack,
    FontIcon,
    mergeStyleSets,
    mergeStyles,
} from '@fluentui/react';
import { LoadingSpinner } from '../../Components/Shared/LoadingSpinner';
import { ActionType } from '../../Common/Redux/actionTypes';
import { GlomocoeColumns } from './GlomocoeColumns';
import { GlomocoeColumnsPage } from './GlomocoeColumnsPage';
import { ILoggedInUserRoles, IUserRoles } from '../../Models/IUserRoles';
import { IAppState } from '../../Common/Redux/AppState';
import { IGlomocoeQueue, IGlomocoeQueueForm } from '../../Models/IGlomocoeQueue';
import {
    CoherencePageSize,
    CoherencePagination,
    ICoherencePageSizeProps,
    ICoherencePaginationProps,
} from '@cseo/controls/lib/pagination';
import { TileData } from '../../Components/Glomocoe//GlomocoeQueueTable.types';
import { getTileData } from '../../Components/Glomocoe/TileData';
import { FilterPane } from '../../Components/Glomocoe/GlomocoeQueueFilterPane';
import { TilesComponent } from '../../Components/Glomocoe/TilesComponent';
import * as moment from 'moment';
import { UnAuthorized } from '../../Components/QueueTable/Unauthorized';
import { ReloFormStatusTypes } from '../../types/ReloFormTypes';
import { RelocationStatus } from '../../Components/Shared/Relocation.types';
import { ExportToExcel } from '../../Components/Shared/ExportToExcel';
import { useHistory } from 'react-router-dom';
import { StackDisclaimer } from '../../Components/Shared/DisclaimerText';

export const GlomocoeQueue: React.FC<{}> = () => {
    const history = useHistory();
    const { useSelector, dispatch } = React.useContext(ReduxContext);
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [isPanelOpen, setIsPanelOpen] = React.useState<boolean>(false);
    const [glomocoeQueue, setGlomocoeQueue] = React.useState<IGlomocoeQueueForm[]>([]);
    const [columns, setColumns] = React.useState<IColumn[]>(GlomocoeColumns);
    const [columnsPage] = React.useState<IColumn[]>(GlomocoeColumnsPage);
    const [currentPageData, setCurrentPageData] = React.useState<IGlomocoeQueue[]>([]);
    const [tileData, setTileData] = React.useState<TileData[]>([]);
    const [IsResetHidded, setIsResetHidded] = React.useState<boolean>(true);
    const userdata: IUserRoles = useSelector((state: IAppState) => state.root.userReducer.userRoles);
    const userRole: ILoggedInUserRoles = useSelector((state: IAppState) => state.root.userReducer.loggedInUserRoles);
    const data = useSelector((state: IAppState) => state.root.glomocoeReducer.glomocoeQueues);
    const { relocationQueueService } = useServiceStore();

    var alias = '';
    var role = '';
    const DEFAULT_PAGE_SIZE = 10;
    var totalRecords: number = 0;
    const selectedPage = React.useRef<number>(1);
    const pageCount = React.useRef<number>(0);
    const currentPageSize = React.useRef<number>(DEFAULT_PAGE_SIZE);


    const reloFilterRef = React.useRef<any>();
    const [queueItems, setQueueItems] = React.useState<IGlomocoeQueueForm[]>([]);
    const [exportQueueItems, setExportQueueItems] = React.useState<IGlomocoeQueue[]>([]);
    function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
        const key = columnKey as keyof T;
        return items.slice(0).sort((a: T, b: T) => {
            if (isSortedDescending) {
                if (a[key] === null) {
                    return 1
                }
                if (b[key] === null) {
                    return -1
                }
                if (a[key] < b[key]) {
                    return 1
                } else if (a[key] > b[key]) {
                    return -1
                }
            } else {
                if (a[key] === null) {
                    return -1
                }
                if (b[key] === null) {
                    return 1
                }
                if (a[key] < b[key]) {
                    return -1
                } else if (a[key] > b[key]) {
                    return 1
                }
            }
            return 0;
        })
    }

    const fetchData = (pageSize: number, newIndex: number = 0): void => {
        totalRecords = queueItems.length;
        let rowData: IGlomocoeQueue[] = [];
        for (let i = newIndex; i < Math.min(pageSize + newIndex, queueItems.length); ++i) {
            let row: IGlomocoeQueueForm = queueItems[i];
            rowData.push(row);
        }
        pageCount.current = Math.ceil(totalRecords / currentPageSize.current);

        setCurrentPageData(rowData);
    };

    const onPageChange = (newPageNumber: number): void => {
        if (newPageNumber !== selectedPage.current) {
            selectedPage.current = newPageNumber;
            fetchData(currentPageSize.current, (newPageNumber - 1) * currentPageSize.current);
        }
    };

    const onPageSizeChange = (newPageSize: string | number): void => {
        currentPageSize.current = newPageSize as number;
        selectedPage.current = 1;
        fetchData(currentPageSize.current);
    };
    React.useEffect(() => {

    }, [])
    const paginationProps: ICoherencePaginationProps = {
        pageCount: pageCount.current,
        selectedPage: selectedPage.current,
        previousPageAriaLabel: 'previous page',
        nextPageAriaLabel: 'next page',
        inputFieldAriaLabel: 'page number',
        onPageChange: onPageChange
    };

    let pageSizeList: IComboBoxOption[] = [];
    for (let index = 10; index <= 50; index += 10) {
        if (queueItems.length < index) {
            pageSizeList.push({ key: queueItems.length, text: 'ALL' });
            break;
        }
        pageSizeList.push({ key: index, text: index.toString() });
    }

    const paginationPageSizeProps: ICoherencePageSizeProps = {
        pageSize: currentPageSize.current,
        pageSizeList: [
            { key: 10, text: '10' },
            { key: 20, text: '20' },
            { key: 50, text: '50' },
            { key: 100, text: '100' },
            { key: 200, text: '200' },
            { key: 300, text: '300' },
            { key: 400, text: '400' },
            { key: 500, text: '500' },
        ] as IComboBoxOption[],
        comboBoxAriaLabel: 'select page size',
        onPageSizeChange: onPageSizeChange,
    };

    React.useEffect(() => {
        (async function getGlomocoeQueue() {
            if (userdata.Alias !== '' && userdata.Roles.length > 0) {
                alias = userdata.Alias;
                role = userdata?.Roles[0];
                if (userRole.GloMoCo && userRole.ReloSpecialist) {
                    role = 'glomoco';
                }
                switch (role) {
                    case 'ReloAdmin':
                        role = 'reloadmin';
                        break;
                    default:
                        role = role;
                }
                const reportType = 'glomoco';
                var queuedata: any[] = await relocationQueueService.getQueueRecords(role, reportType, alias);
                //todo: we should not add these many reord to store as it is exceedeing the setLimit whihc has console issue
                //(rror storing data DOMException: Failed to execute 'setItem' on 'Storage': Setting the value of 'persist:root' exceeded the quota.)
                dispatch({
                    type: ActionType.SET_GLOMOCOEQUEUE,
                    data: queuedata,
                });
                setGlomocoeQueue(queuedata);
                setTileData(getTileData(queuedata));
                setLoaded(true);
            }
        })();
    }, []);

    React.useEffect(() => {
        if (glomocoeQueue && glomocoeQueue.length > 0) {
            let tempQueue: IGlomocoeQueueForm[] = glomocoeQueue.map((row: any) => {
                return {
                    ...row,
                    FullName: row.FullName,
                    LatestSubmissionDate: row.SubmittedDate.toString() === '0001-01-01T00:00:00'
                        ? null
                        : moment(row.SubmittedDate).format('l'),
                    LatestSubmissionDateValue:
                        row.SubmittedDate.toString() === '0001-01-01T00:00:00'
                            ? 0
                            : new Date(row.SubmittedDate).getTime(),
                    LatestResubmissionDate:
                        row.SubmittedDate.toString() === '0001-01-01T00:00:00'
                            ? null
                            : moment(row.SubmittedDate).format('l'),
                    SubmittedBy: row.SubmittedBy,
                    Destination: row.Destination,
                    Departure: row.Departure,
                    EstimatedStartDate:
                        row.EstimatedStartDate === null
                            ? null
                            : row.EstimatedStartDate.toString() === '0001-01-01T00:00:00'
                                ? null
                                : moment(row.EstimatedStartDate).format('l'),
                    EstimatedStartDateValue:
                        row.EstimatedStartDate === null
                            ? 0
                            : row.EstimatedStartDate.toString() === '0001-01-01T00:00:00'
                                ? 0
                                : new Date(row.EstimatedStartDate).getTime(),
                    RelocationType: row.RelocationType,
                    RecordID: row.RecordID,
                    VisaStatus: row.VisaStatus ? row.VisaStatus : false,
                    Status: row.Status,
                    CandidateId: row.CandidateID,
                    OriginalSubmissionDate: moment(row.OriginalSubmissionDate.split('.')[0]).format('M/D/YYYY HH:mm'),
                    CandidateID: row.CandidateID,
                    EmployeeId: row.EmployeeId,
                    FirstName: row.FirstName,
                    LastName: row.LastName,
                    EverSendBack: row.EverSendBack,
                    CurentEmail: row.CurentEmail,
                    DepartureCountry: row.DepartureCountry,
                    DepartureCity: row.DepartureCity,
                    DepartureStateProvince: row.DepartureStateProvince,
                    WorkDestinationCountry: row.WorkDestinationCountry,
                    WorkDestinationCity: row.WorkDestinationCity,
                    WorkDestinationStateProvince: row.WorkDestinationStateProvince,
                    BusinessGroup: row.BusinessGroup,
                    CostCenter: row.CostCenter,
                    JobLevel: row.JobLevel,
                    StaffingManagerAlias: row.StaffingManagerAlias,
                    HiringManagerAlias: row.HiringManagerAlias,
                    RelocationSpecialist: row.RelocationSpecialist,
                    HireTypeName: row.HireTypeName,
                    InternshipTypeName: row.InternshipTypeName,
                    Mach: row.Mach,
                    Mba: row.Mba,
                    RelocationPolicyName: row.RelocationPolicyName,
                    Comments: row.SendBackComments,
                    RecruiterAlias: row.RecruiterAlias,
                    SubmittedDate: row.SubmittedDate,
                };
            });

            setQueueItems(tempQueue);
        }
    }, [glomocoeQueue])

    const normalizeDate = (inputDate: Date) => {
        if (inputDate) {
            return new Date(inputDate.toJSON().split('T')[0]);
        }
        else {
        return "";
        }
    }

    React.useEffect(() => {
        if (queueItems) {
            let tempExportQueueItems = queueItems.map((item) => {
                return {
                    ...item,
                    EstimatedStartDate: item.EstimatedStartDate ? normalizeDate(new Date(item.EstimatedStartDate)) : "",
                    OriginalSubmissionDate: item.OriginalSubmissionDate ? new Date(item.OriginalSubmissionDate) : "",
                    Status: item.Status.toUpperCase() === 'SUBMITTED' ? 'Ready To Initiate' : item.Status,
                };
            });
            setExportQueueItems(tempExportQueueItems)
        }
    }, [queueItems])

    const verticalGapStackTokens: IStackTokens = {
        childrenGap: 10,
        padding: 10,
    };

    const _closePanel = () => {
        setIsPanelOpen(false);
    };

    React.useEffect(() => {
        fetchData(currentPageSize.current);
    }, [queueItems]);

    const stackItemStyles: IStackItemStyles = {
        root: {
            padding: 5,
        },
    };

    usePageTitle(`Glomocoe Queue - ${__APP_NAME__}`);

    React.useEffect(() => {
        let inp = document.querySelectorAll('input');
        let btn = document.querySelectorAll('button');
        inp.forEach((item) => {
            item.setAttribute('tabindex', '0');
            if (item.getAttribute('type') === 'text') {
                item.setAttribute('role', 'textbox');
            }
            if (item.getAttribute('type') === 'checkbox') {
                item.setAttribute('role', 'checkbox');
            }
            if (item.getAttribute('aria-label') === 'select page size') {
                item.setAttribute('role', 'combobox');
            }
        });
        btn.forEach((item) => {
            if (item.getAttribute('aria-label') === 'previous page') {
                item.setAttribute('role', 'button');
                item?.parentElement?.parentElement?.classList.add('wrap-btn');
                item?.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.classList.add('wrap-btn');
            }
            if (item.getAttribute('aria-label') === 'next page') {
                item.setAttribute('role', 'button');
            }
            if (item.getAttribute('aria-hidden') == 'true') {
                item.setAttribute('disabled', 'true');
            }
        });
    });

    return (
        <Stack>
            {!loaded ? (
                <LoadingSpinner />
            ) : (
                <>
                    {(userRole.GloMoCo || userRole.ReloAdmin) ? (
                        <Stack tokens={verticalGapStackTokens}>
                            <h1 style={{ marginBottom: '5px' }}>Glomocoe Queue</h1>
                            <Stack horizontal tokens={verticalGapStackTokens} verticalAlign="end" style={{ marginTop: '0px', padding: '0px 10px 0px 10px' }}>
                                {(
                                    <Stack.Item align="auto" styles={stackItemStyles}>
                                        <PrimaryButton
                                            label="Open Filter"
                                            text="Open Filter"
                                            onClick={() => {
                                                setIsPanelOpen(true);
                                            }}
                                            ariaLabel="Filter Glomocoe Queue"
                                            role="button"
                                            tabIndex={0}
                                        ></PrimaryButton>
                                        <FilterPane
                                            isOpen={isPanelOpen}
                                            _closePanel={_closePanel}
                                            ref={reloFilterRef}
                                            apiData={data}
                                            handleFilterSubmit={handleFilterSubmit}
                                            ShowResetButton={ShowResetButton}
                                            HideResetButton={HideResetButton}
                                        />
                                    </Stack.Item>
                                )}
                                <Stack.Item align="auto" styles={stackItemStyles} hidden={IsResetHidded}>
                                    <PrimaryButton
                                        text="Reset Filter"
                                        onClick={() => {
                                            reloFilterRef.current.ResetFilterCallFromMyRelo();
                                            setIsResetHidded(true);
                                        }}
                                        ariaLabel="Filter Relo Queue"
                                        role="button"
                                        tabIndex={0}
                                    ></PrimaryButton>
                                </Stack.Item>
                                <Stack.Item align="auto" styles={stackItemStyles}>
                                    <ExportToExcel
                                        rowsData={exportQueueItems}
                                        colsData={columns}
                                        fileName="GlomocoeQueue.xlsx"
                                    />
                                </Stack.Item>
                            </Stack>
                            <Stack style={{}}>
                                {currentPageData !== null && currentPageData !== undefined && (
                                    <Stack>
                                        <Stack>
                                            <Stack.Item>
                                                <TilesComponent
                                                    tileData={tileData}
                                                    apiData={data}
                                                    handleTileFilterSubmit={handleFilterSubmit}
                                                />
                                            </Stack.Item>
                                            <Stack.Item>
                                                <ShimmeredDetailsList
                                                    items={currentPageData || []}
                                                    columns={columnsPage}
                                                    onColumnHeaderClick={_onColumnClick}
                                                    onRenderItemColumn={_renderItemColumn}
                                                    onRenderRow={_onRenderRow}
                                                    onItemInvoked={_onItemInvoked}
                                                    onRenderDetailsHeader={_onRenderDetailsHeader}
                                                    setKey="set"
                                                    layoutMode={DetailsListLayoutMode.justified}
                                                    selectionPreservedOnEmptyClick={true}
                                                    selectionMode={SelectionMode.none}
                                                    ariaLabelForSelectionColumn="Toggle selection"
                                                    ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                                                    checkButtonAriaLabel="select row"
                                                    ariaLabelForGrid="Glomocoe Queue"
                                                />
                                            </Stack.Item>
                                        </Stack>
                                        <Stack className="pagination-controls ml-sm-0"
                                            horizontal
                                            horizontalAlign="end"
                                            styles={{ root: { marginTop: '10px' } }}>
                                            <Stack horizontal horizontalAlign="space-between" className="ml-sm-0 pg-c-sm">
                                                <div className="innr">
                                                    <CoherencePagination {...paginationProps} />
                                                </div>
                                                <CoherencePageSize {...paginationPageSizeProps} />
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                )}
                            </Stack>
                            {StackDisclaimer()}
                        </Stack>
                    ) : (
                        <UnAuthorized />
                    )}
                </>
            )}
        </Stack>
    );

    function handleFilterSubmit(filteredData: any[]) {
        setGlomocoeQueue(filteredData);
    }
    function ShowResetButton() {
        setIsResetHidded(false);
    }
    function HideResetButton() {
        setIsResetHidded(true);
    }
    function _renderItemColumn(
        item: IGlomocoeQueue | undefined,
        index: number | undefined,
        column: IColumn | undefined
    ) {
        const fieldContent = item?.[column?.fieldName as keyof IGlomocoeQueue] as string;
        const statusClass = mergeStyles({ fontSize: '12px', fontFamily: 'Segoe UI', paddingLeft: '10px' });

        const iconClass = mergeStyles({
            fontSize: 16,
            height: 16,
            width: 16,
            paddingLeft: 5
        });
        const classNames = mergeStyleSets({
            submitted: [{ color: '#7BA811' }, iconClass],
            error: [{ color: '#DF0008' }, iconClass],
            warning: [{ color: '#EC9F1D' }, iconClass],
        });

        switch (column?.key) {
            case 'Status':
                if (item?.Status === ReloFormStatusTypes.RESUBMITTED) {
                    return (
                        <Stack horizontal horizontalAlign="start">
                            <span className={statusClass}>{item?.Status}</span>
                            <FontIcon
                                aria-label={item?.Status}
                                iconName="WarningSolid"
                                className={classNames.warning}
                            />

                        </Stack>
                    );
                }
                if (item?.Status === ReloFormStatusTypes.SUBMITTED) {
                    return (
                        <Stack horizontal horizontalAlign="start">
                            <span className={statusClass}>{RelocationStatus.READYTOINITIATE}</span>
                            <FontIcon
                                aria-label={RelocationStatus.READYTOINITIATE}
                                iconName="SkypeCircleCheck"
                                className={classNames.submitted}
                            />

                        </Stack>
                    );
                }

            case 'EstimatedStartDate':
                if (item?.EstimatedStartDate === null) {
                    return <span>{fieldContent}</span>;
                } else {
                    return moment(item?.EstimatedStartDate).format('MM/DD/YYYY');
                }

            case 'LatestSubmissionDate':
                if (item?.LatestSubmissionDate === null) {
                    return <span>{fieldContent}</span>;
                } else {
                    return moment(item?.LatestSubmissionDate).format('MM/DD/YYYY');
                }

            default:
                return <span>{fieldContent}</span>;
        }
    }

    function _onRenderDetailsHeader(headerProps: any, defaultRender: any) {
        return defaultRender({
            ...headerProps,
            styles: {
                root: {
                    selectors: {
                        '.ms-DetailsHeader-cell': {
                            whiteSpace: 'normal',
                            lineHeight: 'normal',
                            wordWrap: 'break-word',
                            height: '100%',
                        },
                        '.ms-DetailsHeader-cellTitle': {
                            height: '100%',
                            alignItems: 'center',
                            wordWrap: 'break-word',
                        },
                    },
                    height: '60px',
                },
            },
        });
    }

    function _onRenderRow(props: IDetailsRowBaseProps | undefined, defaultRender: any | undefined) {
        const customStyles: Partial<IDetailsRowStyles> = {};
        let divProps;
        if (props) {
            if (
                props.item?.Status == ReloFormStatusTypes.RESUBMITTED ||
                props.item?.Status == ReloFormStatusTypes.SUBMITTED
            ) {
                customStyles.root = {
                    color: 'blue',
                    selectors: {
                        '.ms-DetailsRow-cell': {
                            whiteSpace: 'normal',
                            lineHeight: 'normal',
                        },
                        '.ms-DetailsRow-cellTitle': {
                            height: '100%',
                            alignItems: 'center',
                            wordWrap: 'break-word',
                        },
                    },
                };
                divProps = {
                    onClick: () => {
                        let obj = JSON.stringify({
                            recordID: props.item?.RecordID,
                            recordStatus: props.item?.Status,
                            ActionType: 'GLOMOCO',
                            redirectedFrom: 'glomocoequeue'
                        });
                        history.push('/ReviewForm', obj);
                    },
                };
            }

            return (
                <DetailsRow {...divProps} {...props} styles={customStyles}>
                    {defaultRender && defaultRender(props)}{' '}
                </DetailsRow>
            );
        }
        return null;
    }

    function _onItemInvoked(item: IGlomocoeQueue | undefined, index: number | undefined) {
        //adding cases
        if (item?.Status === ReloFormStatusTypes.RESUBMITTED || item?.Status === ReloFormStatusTypes.SUBMITTED) {
            let obj = JSON.stringify({
                recordID: item?.RecordID,
                recordStatus: item?.Status,
                ActionType: 'GLOMOCO',
                redirectedFrom: 'glomocoequeue'
            });
            history.push('/ReviewForm', obj);
        }
    }

    function _onColumnClick(event: React.MouseEvent<HTMLElement> | undefined, column: IColumn | undefined): void {
        let isSortedDescending = column?.isSortedDescending;
        if (column?.isSorted) {
            isSortedDescending = !isSortedDescending;
        }
        let sortedItems: any[] = _copyAndSort(queueItems, column?.fieldName!, isSortedDescending);

        let sortedCol = columnsPage.map((col) => {
            col.isSorted = col.key === column?.key;

            if (col.isSorted) {
                col.isSortedDescending = isSortedDescending;
            }
            return col;
        });
        setGlomocoeQueue(sortedItems);
        setColumns(sortedCol);
        selectedPage.current = 1;
    }
};
