import * as React from 'react';
import {
    Dialog,
    DialogType,
    DialogFooter,
    PrimaryButton,
    hiddenContentStyle,
    mergeStyles,
    Spinner,
    SpinnerSize,
    Stack,
} from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import { ILineItemExcel } from '../../../Models/IExportToFinance';
import { useServiceStore } from '../../../RootStateContext';
import { GenericNotification } from '../../../Components/Shared/GenericNotification';
import { exportToCSV } from '../../../Components/Shared/exportToCSV';
import { ExportToFinanceColumnsExcel } from '../ExportToFinanceColumns';

const screenReaderOnly = mergeStyles(hiddenContentStyle);

// ---------------------------------------------------------------------------
export const DownLoadConfirmationPopUp: React.FC<{ dismiss: any; items: ILineItemExcel[]; LoggedInAlias: string }> = ({
    dismiss,
    items,
    LoggedInAlias,
}) => {
    const subTextId: string = useId('ConfirmTextLabel');
    const { exportToWebJEService } = useServiceStore();
    const [loading, setLoading] = React.useState<boolean>(false);

    
    const WebJEFileName = () => {
        const TodayD = new Date();
        
        const sDay = TodayD.getDate().toString().length == 2 ? TodayD.getDate().toString() : '0' + TodayD.getDate().toString() ;
        const sMonth = TodayD.toDateString().substring(4,7);
        const sYear = TodayD.getFullYear();
        const sTimearray = TodayD.toLocaleTimeString().split(':');
        const sTimestring = (sTimearray[0].length == 2 ? sTimearray[0] : '0' + sTimearray[0]) + sTimearray[1] + sTimearray[2].split(' ')[1];

        return "WebJEReport_" + sMonth + "_" + sDay + "_" + sYear + "_" + sTimestring;
    }

    const dialogContentProps = {
        type: DialogType.normal,
        // title: 'Warning! Deleting this e-form cannot be undone.',
        closeButtonAriaLabel: 'Close',
        subText: 'Are you sure you wish to download this data?',
    };

    return (
        <>
            <label id={subTextId} className={screenReaderOnly}>
                Are you sure you wish to download this data?
            </label>

            {loading && (
                <Stack>
                    <Spinner
                        label="Loading, please wait..."
                        size={SpinnerSize.large}
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            height: '100%',
                            width: '100%',
                            zIndex: 9999999,
                            backgroundColor: 'rgb(255, 255, 255, 255)',
                        }}
                    />
                </Stack>
            )}

            <Dialog hidden={false} onDismiss={dismiss} dialogContentProps={dialogContentProps}>
                <DialogFooter>
                    <PrimaryButton onClick={dismiss} text="Cancel" />
                    <PrimaryButton
                        onClick={() => {
                            ExportToWebJEReport(items, LoggedInAlias);
                        }}
                        text="OK"
                    />
                </DialogFooter>
            </Dialog>
        </>
    );

    async function ExportToWebJEReport(items: ILineItemExcel[], LoggedInAlias: string) {
        setLoading(true);
        await exportToWebJEService
            .UpdateOnWebJEExport()
            .then((result) => {
                if (result) {
                    //Need to add export to csv / excel functionality here
                    console.log(`The Exported records are ${items}`);
                    exportToCSV(items, WebJEFileName(), ExportToFinanceColumnsExcel);
                }
            })
            .catch((error) => {
                <GenericNotification title="Error" msg="Unable to export." />;
                console.log(error);
            })
            .finally(() => {
                setLoading(false);
                dismiss();
            });
    }
};
