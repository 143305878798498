import * as React from 'react';
import {
    Stack,
    IconButton,
    Label,
    IStackStyles,
    IStackProps,
    IStackItemStyles,
    TextField,
    IDropdownOption,
    Text,
} from '@fluentui/react';
import { CoreBenefitsList } from './CoreBenefitsList';
import { OptionalBenefitsList } from './OptionalBenefitsList';
import { ExceptionsList } from './ExceptionsList';
import { EmploymentInfo, IReviewForm } from '../../Models/IReviewForm';
import { RelocationInfo } from '../../Models/INewRelocationRequest';
import { LumpSumPanel } from './LumpSumPanel';
import { PreHireTypePanel } from './PreHireTypePanel';

export interface RelocationInfoProps {
    reloInfo: RelocationInfo;
    relocationTypeOptions: IDropdownOption[];
    hireTypeOption: number;
    employmentInfo: EmploymentInfo;
    newAuthFormData: IReviewForm[];
    changeReloType: Function;
    handleExceptionUnitValueUpdate: Function;
    handleRemoveException: Function;
    handleAddException: Function;
    updateRelocationInfo: Function;
    onRelocationPolicyChange: Function;
    showBenefitsPanel: boolean;
    showOptionalException: boolean;
    validateOnSubmit: boolean;
    validateOnReSubmit: boolean;
    erroredFields: Function;
    clearedFields: Function;
    RelocationCashAmount: number;
    taxpactforlumpsum: number;
}

// --------- Styles ----------
const oneColumnProps: Partial<IStackProps> = {
    tokens: { childrenGap: 15 },
    styles: { root: { width: '100%' } },
};

const stackStyles: Partial<IStackStyles> = { root: { width: '100%', marginTop: '7px', marginBottom: '0px', padding: '14px' } };
const accordionHeader: IStackItemStyles = {
    root: {
        padding: 5,
        cursor: 'pointer',
        fontSize: '20px',
    },
};

const threeColumnProps: Partial<IStackProps> = {
    tokens: { childrenGap: 15 },
    styles: { root: { width: '32.33%' } },
};

const header = {
    fontWeight: 600,
};

const accordionContent: IStackItemStyles = {
    root: {
        margin: '0px 0px 7px 11px',
        width: '99%',
        boxShadow: 'rgb(0 0 0 / 16%) 0px 1px 4px'
    },
};

// --------- Constants ----------
const stackTokens = { childrenGap: 20 };

export enum IAttachmentType {
    ReloSummary = 1,
    AdditionalDocument = 2,
}
// --------------------------------------------------
export const RelocationInformation: React.FC<any> = (props) => {
    const [isOpen, setIsOpen] = React.useState<boolean>(true);

    React.useEffect(() => {
        console.log('The number of re renders');
        console.log('Show Benefits Panel' + props.showBenefitsPanel);
    }, [props]);

    const toggleRelocationInformation = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="RelocationInformation" style={{ padding: '0px 2px 0px 2px' }}>
            <Stack horizontal={true} style={{ backgroundColor: 'rgb(235, 235, 235)', maxWidth: '100%', margin: '5px 0px 0px 5px' }}>
                <Stack.Item style={{ marginTop: '3px' }} className="ac-icon">
                    <IconButton
                        title="Relocation Information"
                        iconProps={{
                            iconName: isOpen ? 'ChevronUp' : 'ChevronDown',
                        }}
                        onClick={toggleRelocationInformation}
                    />
                </Stack.Item>
                <Stack.Item align="center" styles={accordionHeader} className="ac-title">
                    <h2 style={{ fontSize: '20px' }}> Relocation Information </h2>
                </Stack.Item>
            </Stack>

            {isOpen && (
                <Stack styles={accordionContent}>
                    <Stack horizontal tokens={stackTokens} styles={stackStyles}>
                        <Stack {...threeColumnProps}>
                            <Text variant="medium" style={header}>
                                Relocation Policy
                            </Text>
                            <Text block>{props.reloInfo.relocationPolicy}</Text>
                        </Stack>
                        <Stack {...threeColumnProps}>
                            <Text variant="medium" style={header}>
                                Relocation Type
                            </Text>
                            <Text block>{props.reloInfo.relocationType}</Text>
                        </Stack>
                    </Stack>
                    <>
                        {/* {showBenefitsPanel &&  */}
                        {props.showBenefitsPanel && (
                            <>
                                <Stack style={{ paddingLeft: '10px' }}>
                                    <h3> <Label>Core Benefits</Label> </h3>
                                    <CoreBenefitsList
                                        reloTypeId={parseInt(props.reloInfo.relocationPolicyID)}
                                        reloTypePolicyId={parseInt(props.reloInfo.relocationpolicyTypeID)}
                                        fiscalYear={props.reloInfo.fiscalYear}
                                        relocationCashAmount={props.RelocationCashAmount}
                                        taxpactforlumpsum={props.taxpactforlumpsum}
                                    />
                                </Stack>
                                {props.showOptionalException && 
                                (<Stack>
                                    <Stack styles={stackStyles}>
                                        <h3><Label>Optional Benefits</Label></h3>
                                        {props.reloInfo.relocationpolicyTypeID != 51
                                            && props.reloInfo.relocationpolicyTypeID != 50
                                            && (
                                                <Text>Select all Optional Benefits that apply. Use the Exceptions drop down below to provide Optional Benefits beyond policy caps.</Text>)
                                        }
                                        {props.reloInfo.relocationpolicyTypeID == 50
                                            && (
                                                <Text style={{ color: 'red' }}>Optional Benefits are limited to one only. Household Goods and Storage equals one benefit.</Text>)
                                        }
                                        {props.reloInfo.relocationpolicyTypeID == 51
                                            && (
                                                <Text style={{ color: 'red' }}>Optional Benefits are limited to two only. Household Goods and Storage equals one benefit.</Text>)
                                        }
                                        <OptionalBenefitsList
                                            reloPolicyId={parseInt(props.reloInfo.relocationPolicyID)}
                                            optionalBenefitMapping={props.reloInfo.optionalBenefitMapping}
                                        />
                                    </Stack>
                                    <h3><Label>Exceptions</Label></h3>
                                    <ExceptionsList
                                        reloTypeId={parseInt(props.reloInfo.relocationpolicyTypeID)}
                                        exceptionMapping={props.reloInfo.exceptionMapping}
                                        exceptionResponse={props.exceptionsResponse}
                                    />
                                </Stack>)}
                            </>
                        )}

                        {props.showLumpSumPanel && (
                            <>
                                {' '}
                                <Stack>
                                    <h3> <Label>Core Benefits</Label> </h3>
                                    <LumpSumPanel
                                        relocationPolicyID={Number(props.reloInfo.relocationPolicyID)}
                                        relocationType={props.reloInfo.relocationType}
                                        LumpSumRelocation={props.lumpsumRelocaiton}
                                    />
                                </Stack>
                            </>
                        )}

                        {props.showPreHireTypePanel && (
                            <>
                                <PreHireTypePanel
                                    preHireTypeBenefits={props.preHireTypeBenefits}
                                    preHireTypeBenefitsMapping={props.preHireTypeBenefitsMapping}
                                />
                            </>
                        )}

                        <Stack horizontal tokens={stackTokens} styles={stackStyles}>
                            <Stack {...oneColumnProps}>
                                <TextField
                                    label="Comments"
                                    role='textbox'
                                    multiline
                                    rows={3}
                                    readOnly={true}
                                    disabled={true}
                                    value={props.reloInfo.comments}
                                    description={`${400 - (props.reloInfo.comments != null ? props.reloInfo.comments.length : 0)
                                        } Characters Left`}
                                ></TextField>
                            </Stack>
                        </Stack>
                    </>
                </Stack>
            )}
        </div>
    );
};
