import * as React from 'react';
import {
    getTheme,
    mergeStyleSets,
    FontWeights,
    Modal,
    IIconProps,
    Stack,
    IStackProps,
    TextField,
    Dropdown,
    IDropdownOption,
    IDropdownStyles,
    IStackTokens,
} from '@fluentui/react';
import { PrimaryButton, IconButton, IButtonStyles, PrimaryButton } from '@fluentui/react/lib/Button';
import { useId, useBoolean } from '@fluentui/react-hooks';
import { IReviewForm, ISendBack } from '../../../Models/IReviewForm';
import { useServiceStore } from '../../../RootStateContext';
import { LoadingSpinner } from '../../../Components/Shared/LoadingSpinner';

const cancelIcon: IIconProps = { iconName: 'Cancel' };
const theme = getTheme();
const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
    },
    header: [
        {
            flex: '1 1 auto',
            borderTop: `5px solid orange`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});
const iconButtonStyles: Partial<IButtonStyles> = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};

const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: 300 },
};

const oneColumnProps: Partial<IStackProps> = {
    tokens: { childrenGap: 15 },
    styles: { root: { width: '100%', marginBottom: '20px', padding: 10 } },
};

const horizontalGapStackTokens: IStackTokens = {
    childrenGap: 10,
    padding: 10,
};

// ---------------------------------------------------------------------------

const options: IDropdownOption[] = [
    { key: 'Data', text: 'Data' },
    { key: 'Duplicate', text: 'Duplicate' },
    { key: 'Miscellaneous', text: 'Miscellaneous' },
    { key: 'Policy', text: 'Policy' },
    { key: 'Relo Summary Error', text: 'Relo Summary Error' },
];

// -------------------------------------------------------------------------
export const SendBackPopUp: React.FC<{
    dismiss: any;
    formData: IReviewForm;
    LoggedInAlias: string;
    updateisSendBack: Function;
}> = ({ dismiss, formData, LoggedInAlias, updateisSendBack }) => {
    const { reviewFormService } = useServiceStore();
    const [isModalOpen, { setFalse: hideModal }] = useBoolean(true);
    const [commentsForSendBack, setCommentsForSendBack] = React.useState('');
    const [selectedSendBackCategory, setSelectedSendBackCategory] = React.useState<string>('');
    const [sendBackErrorMsg, setSendBackErrorMsg] = React.useState<string>('');
    const [isLoading, set_IsLoading] = React.useState<boolean>(false);

    const titleId = useId('title');

    async function SendBackComment() {
        if (selectedSendBackCategory !== '') {
            let sendBackObj: ISendBack = {} as ISendBack;
            sendBackObj = {
                Sendbackcategory: selectedSendBackCategory,
                alias: LoggedInAlias,
                comments: commentsForSendBack,
                estimatedStartDate: new Date(formData.EstimatedStartDate),
                personalDetails: formData.PersonalDetails,
                reloID: formData.ReloID,
                status: 'SendBack',
            };
            set_IsLoading(true);
            await reviewFormService
                .SaveSendBackQueue(sendBackObj)
                .then(async (result) => {
                    if (result.status == 201) {
                        await updateisSendBack();
                        await reviewFormService
                            .GetSendMailNotification(formData.ReloID)
                            .then((result) => { })
                            .catch((error) => {
                                console.log(`Failed Error Message ${error}`);
                                alert('Failed to send email notification');
                            });
                    }
                })
                .catch((error) => {
                    console.log(`Failed to send back record , error message is ${error}`);
                })
                .finally(() => {
                    set_IsLoading(true);
                    dismiss();
                });
        } else {
            setSendBackErrorMsg('Please Select Send Back');
        }
    }

    const _onSendBackCategoryChange = (
        event: React.FormEvent<HTMLDivElement> | undefined,
        item: IDropdownOption | undefined
    ): void => {
        if (item?.key !== 0) {
            setSelectedSendBackCategory(item?.key as string);
            setSendBackErrorMsg('');
        }
    };

    return (
        <div>
            {isLoading && <LoadingSpinner />}

            <Modal
                titleAriaId={titleId}
                isOpen={isModalOpen}
                onDismiss={dismiss}
                isBlocking={false}
                containerClassName={contentStyles.container}
            >
                <div className={contentStyles.header}>
                    <span id={titleId} style={{ padding: '10px' }}>
                        Please provide comments, stating the reason for Send Back
                    </span>
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={cancelIcon}
                        ariaLabel="Close popup modal"
                        onClick={hideModal}
                    />
                </div>
                <div className={contentStyles.body}>
                    {/* Dropdown */}
                    <Stack horizontal disableShrink tokens={horizontalGapStackTokens} className="ml-sm-0">
                        <span>
                            <Dropdown
                                placeholder="Select an option"
                                label="Send Back Category"
                                options={options}
                                styles={dropdownStyles}
                                defaultSelectedKey={selectedSendBackCategory}
                                onChange={_onSendBackCategoryChange}
                                required
                                errorMessage={sendBackErrorMsg}
                            />
                        </span>
                    </Stack>

                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                        <Stack {...oneColumnProps} className="ml-sm-0">
                            <TextField
                                label="Comments"
                                multiline
                                rows={3}
                                maxLength={400}
                                defaultValue={commentsForSendBack}
                                description={`${400 - (commentsForSendBack != null ? commentsForSendBack.length : 0)
                                    } Characters Left`}
                                onChange={(_, newValue?: string) => {
                                    if (newValue?.trim() !== '' && newValue !== undefined)
                                        setCommentsForSendBack(newValue || '');
                                    else setCommentsForSendBack(newValue || '');
                                }}
                            ></TextField>
                        </Stack>
                    </Stack>

                    <Stack horizontal horizontalAlign="center" disableShrink tokens={horizontalGapStackTokens}>
                        <PrimaryButton
                            onClick={async () => {
                                await SendBackComment();
                            }}
                            disabled={!commentsForSendBack.length > 0}
                        >
                            OK
                        </PrimaryButton>
                        <PrimaryButton onClick={dismiss} className="mt-sm-5 ml-sm-0"> Cancel </PrimaryButton>
                    </Stack>
                </div>
            </Modal>
        </div>
    );
};
