import {
    CoherencePageSize,
    CoherencePagination,
    ICoherencePageSizeProps,
    ICoherencePaginationProps,
} from '@cseo/controls/lib/pagination';
import { ReduxContext } from '@employee-experience/common';
import { usePageTitle } from '@employee-experience/common/lib/usePageTitle';
import {
    DetailsListLayoutMode,
    DetailsRow,
    IColumn,
    IComboBoxOption,
    IDetailsRowBaseProps,
    IDetailsRowStyles,
    IStackItemStyles,
    IStackTokens,
    Selection,
    MarqueeSelection,
    SelectionMode,
    ShimmeredDetailsList,
    Stack,
    Text,
    TextField,
    ITextFieldStyles,
    StackItem,
    Modal,
    ResponsiveMode,
    mergeStyleSets,
    MessageBar,
    MessageBarType,
    PrimaryButton,
    mergeStyles,
    FontIcon,
} from '@fluentui/react';
import * as React from 'react';
import { IAppState } from '../../Common/Redux/AppState';
import {
    IPendingExceptionsApprovalQueue,
    IPendingExceptionsApprovalQueueForm,
} from '../../Models/IPendingExceptionApproalQueue';
import { ILoggedInUserRoles, IUserRoles } from '../../Models/IUserRoles';
import { useServiceStore } from '../../RootStateContext';
import { PendingExceptionColumns } from './PendingExceptionColumns';
import { LoadingSpinner } from '../../Components/Shared/LoadingSpinner';
import { ReloFormStatusTypes } from '../../types/ReloFormTypes';
import { UnAuthorized } from '../../Components/QueueTable/Unauthorized';
import * as moment from 'moment';
import { StackDisclaimer } from '../../Components/Shared/DisclaimerText';

export function PendingExceptionApprovalQueue(): React.ReactElement {
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [pendingExceptionApprovalQueue, setpendingExceptionApprovalQueue] = React.useState<
        IPendingExceptionsApprovalQueueForm[]
    >([]);
    const { useSelector } = React.useContext(ReduxContext);
    const userdata: IUserRoles = useSelector((state: IAppState) => state.root.userReducer.userRoles);
    const userRole: ILoggedInUserRoles = useSelector((state: IAppState) => state.root.userReducer.loggedInUserRoles);
    const { pendingExceptionQueueApprovalQueueService } = useServiceStore();
    const [columns, setColumns] = React.useState<IColumn[]>(PendingExceptionColumns);
    const [currentPageData, setCurrentPageData] = React.useState<IPendingExceptionsApprovalQueue[]>([]);
    const [selectedExceptionId, setselectedExceptionId] = React.useState<number[]>([]);
    const [isModalOpen, setisModalOpen] = React.useState<boolean>(false);
    const [approverComments, setapproverComments] = React.useState<string>('');
    const [exceptionStatus, setexceptionStatus] = React.useState<string>('');
    const [approverCommentLength, setapproverCommentLength] = React.useState<number>(400);
    const [isMessageBarVisible, setIsMessageBarVisible] = React.useState<boolean>(false);
    const [messageBarText, setMessageBarText] = React.useState<string>('');
    const [messageBarType, setMessageBarType] = React.useState<number>(MessageBarType.success);
    const [reloadData, setReloadData] = React.useState<boolean>(false);
    const [showCriticalMessage, setShowCriticalMessage] = React.useState(false);

    var alias = '';
    const DEFAULT_PAGE_SIZE = 10;
    var totalRecords: number = 0;
    const selectedPage = React.useRef<number>(1);
    const pageCount = React.useRef<number>(0);
    const currentPageSize = React.useRef<number>(DEFAULT_PAGE_SIZE);
    let queueItems: IPendingExceptionsApprovalQueue[] = [];

    function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
        const key = columnKey as keyof T;
        return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    }

    if (pendingExceptionApprovalQueue && pendingExceptionApprovalQueue.length > 0) {
        queueItems = pendingExceptionApprovalQueue.map((row) => {
            return {
                FileExceptionID: row.FileExceptionID,
                ActionDueDate: row.ActionDueDate,
                CostOfExceptionBeyondPolicy: row.CostOfExceptionBeyondPolicy,
                EmployeeName: row.EmployeeName,
                ExceptionCategory: row.ExceptionCategory,
                ExceptionTotal: row.ExceptionTotal,
                FileID: row.FileID,
                JustificationForException: row.JustificationForException,
                ApproverArguency: row.ApproverArguency,
            };
        });
    }

    const fetchData = (pageSize: number, newIndex: number = 0): void => {
        totalRecords = queueItems.length;
        let rowDatata: IPendingExceptionsApprovalQueue[] = [];
        let criticalCounter = 0;
        for (let i = newIndex; i < Math.min(pageSize + newIndex, queueItems.length); ++i) {
            let row: IPendingExceptionsApprovalQueue = queueItems[i];
            rowDatata.push(row);
            if(row.ApproverArguency === 'C') {
                criticalCounter++
            }
        }
        pageCount.current = Math.ceil(totalRecords / currentPageSize.current);
        setCurrentPageData(rowDatata);
        if(criticalCounter > 0) {
            setShowCriticalMessage(true)
        } else {
            setShowCriticalMessage(false)
        }
    };

    const onPageChange = (newPageNumber: number): void => {
        if (newPageNumber !== selectedPage.current) {
            selectedPage.current = newPageNumber;
            fetchData(currentPageSize.current, (newPageNumber - 1) * currentPageSize.current);
        }
    };

    const onPageSizeChange = (newPageSize: string | number): void => {
        currentPageSize.current = newPageSize as number;
        selectedPage.current = 1;
        fetchData(currentPageSize.current);
    };
    const paginationProps: ICoherencePaginationProps = {
        pageCount: pageCount.current,
        selectedPage: selectedPage.current,
        previousPageAriaLabel: 'previous page',
        nextPageAriaLabel: 'next page',
        inputFieldAriaLabel: 'page number',
        onPageChange: onPageChange,
    };
    let pageSizeList: IComboBoxOption[] = [];
    for (let index = 10; index <= 50; index += 10) {
        if (queueItems.length < index) {
            pageSizeList.push({ key: queueItems.length, text: 'ALL' });
            break;
        }
        pageSizeList.push({ key: index, text: index.toString() });
    }

    const paginationPageSizeProps: ICoherencePageSizeProps = {
        pageSize: currentPageSize.current,
        pageSizeList: [
            { key: 10, text: '10' },
            { key: 20, text: '20' },
            { key: 50, text: '50' },
            { key: 100, text: '100' },
            { key: 200, text: '200' },
            { key: 300, text: '300' },
            { key: 400, text: '400' },
            { key: 500, text: '500' },
        ] as IComboBoxOption[],
        comboBoxAriaLabel: 'select page size',
        onPageSizeChange: onPageSizeChange,
    };

    const contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
            width: '40em',
        },
    });
    const iconClass = mergeStyles({
        fontSize: 16,
        height: 16,
        width: 16,
        paddingLeft: 10,
    });

    const classNames = mergeStyleSets({
        submitted: [{ color: '#7BA811' }, iconClass],
        error: [{ color: '#DF0008' }, iconClass],
        warning: [{ color: '#EC9F1D' }, iconClass],
    });

    //todo: there is an issue herer where there are multiple calls going on to apoi hae to fix state ~sadatla
    React.useEffect(() => {
        (async function getPendingExceptionApprovalQueue() {
            if (userdata.Alias !== '' && userdata.Roles.length > 0) {
                alias = userdata.Alias;
                setLoaded(false);
                var pendingApprovalQueue = await pendingExceptionQueueApprovalQueueService.GetPendingExceptionApprovalDetails(
                    alias
                );
                setpendingExceptionApprovalQueue(pendingApprovalQueue);
                setLoaded(true);
            }
        })();
    }, [reloadData]);

    const selection = React.useMemo(
        () =>
            new Selection({
                onSelectionChanged: () => {
                    _getSelectionDetails();
                },
                selectionMode: SelectionMode.multiple,
                getKey: (item: any) => item.FileExceptionID,
            }),
        []
    );

    const clearSelectedRecords = () => {
        const selectedRecords = selection.getSelection();
        selectedRecords.forEach((index) => selection.setIndexSelected(index, false, false));
    }

    const verticalGapStackTokens: IStackTokens = {
        childrenGap: 10,
        padding: 10,
    };

    const actionButtonStyles: IStackItemStyles = {
        root: {
            marginTop: 28,
        },
    };

    React.useEffect(() => {
        fetchData(currentPageSize.current);
    }, [pendingExceptionApprovalQueue]);

    const stackItemStyles: IStackItemStyles = {
        root: {
            padding: 5,
        },
    };

    const onApproverCommentsValueChange = React.useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            if (newValue!== null && newValue !== undefined && newValue.length <= 400) {
                setapproverComments(newValue || '');
                setapproverCommentLength(400 - newValue?.length);
            }
        },
        []
    );

    usePageTitle(`Relocation Queue - ${__APP_NAME__}`);

    const handleMessageBar = () => {
        setIsMessageBarVisible(false)
    }

    const handleCriticalMessageBar = () => {
        setShowCriticalMessage(false);
    }

    React.useEffect(()=>{
        let inp = document.querySelectorAll('input');
        let btn = document.querySelectorAll('button');
        inp.forEach((item)=>{
            item.setAttribute('tabindex','0');
            if(item.getAttribute('aria-label')==="page number")
            {
                item.setAttribute('role','textbox');
            }
            if(item.getAttribute('type')==="checkbox")
            {
                item.setAttribute('role','checkbox');
            }
        })
        btn.forEach((item)=>{
        if(item.getAttribute('aria-label')==='previous page')
            {
                item.setAttribute('role','button');
                item?.parentElement?.parentElement?.classList.add("wrap-btn");
                item?.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.classList.add("wrap-btn");
            }
            if(item.getAttribute('aria-label')==='next page')
            {
                item.setAttribute('role','button');
            }
            if(item.getAttribute('aria-hidden')=='true')
            {
                item.setAttribute('disabled','true');
            }
        })
    })

    return (
        <>
            {!loaded ? (
                <LoadingSpinner />
            ) : (
                <>
                    {userRole.ReloAdmin || userRole.Guest || userRole.Staffing ? (
                        <Stack>
                            <Stack tokens={verticalGapStackTokens}>
                                <Stack.Item align="auto" styles={stackItemStyles}>
                                    <h1>Pending Approvals Queue</h1>
                                    {/* <img src={photo} /> */}
                                </Stack.Item>
                                <Stack.Item align="auto" styles={stackItemStyles}></Stack.Item>
                                <>
                                    {isMessageBarVisible && <MessageBar
                                        messageBarType={messageBarType}
                                        isMultiline={false}
                                        onDismiss={handleMessageBar}
                                        dismissButtonAriaLabel="Close"
                                    >
                                        {messageBarText}
                                    </MessageBar>}
                                    {showCriticalMessage && <MessageBar
                                        messageBarType={3}
                                        messageBarIconProps={undefined}
                                        isMultiline={false}
                                        onDismiss={handleCriticalMessageBar}
                                        dismissButtonAriaLabel="Close"
                                    >
                                        Please review the critical exception requests below and provide your approval or rejection today.
                                    </MessageBar>}
                                    <Stack.Item align="auto" styles={stackItemStyles}>
                                        <MarqueeSelection selection={selection}>
                                            <ShimmeredDetailsList
                                                selectionMode={SelectionMode.multiple}
                                                items={currentPageData || []}
                                                columns={PendingExceptionColumns}
                                                onRenderItemColumn={_renderItemColumn}
                                                onColumnHeaderClick={_onColumnClick}
                                                onRenderRow={_onRenderRow}
                                                onRenderDetailsHeader={_onRenderDetailsHeader}
                                                setKey="multiple"
                                                getKey={_getKey}
                                                selection={selection}
                                                layoutMode={DetailsListLayoutMode.justified}
                                                selectionPreservedOnEmptyClick={true}
                                                ariaLabelForSelectionColumn="Toggle selection"
                                                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                                                checkButtonAriaLabel="select row"
                                                ariaLabelForGrid="Pending Exception Approval Queue"
                                            />
                                        </MarqueeSelection>
                                    </Stack.Item>
                                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                                        <StackItem>
                                            <PrimaryButton
                                                styles={actionButtonStyles}
                                                text="Approve"
                                                onClick={() => {
                                                    OnApproveOrRejectClick('Approved');
                                                }}
                                                ariaLabel="Approve selected exceptions"
                                                role="button"
                                            />
                                        </StackItem>
                                        <StackItem>
                                            <PrimaryButton
                                                styles={actionButtonStyles}
                                                text="Reject"
                                                onClick={() => {
                                                    OnApproveOrRejectClick('Rejected');
                                                }}
                                                ariaLabel="Reject selected exceptions"
                                                role="button"
                                            />
                                        </StackItem>
                                    </Stack>
                                    <Stack
                                        className="pagination-controls"
                                        horizontal
                                        horizontalAlign="end"
                                        styles={{ root: { marginTop: '10px' } }}
                                    >
                                        <Stack
                                            horizontal
                                            horizontalAlign="space-between"
                                            styles={{ root: { width: '50%' } }}
                                        >
                                            <div style={{ marginLeft: -132 }}>
                                                <CoherencePagination {...paginationProps} />
                                            </div>
                                            <CoherencePageSize {...paginationPageSizeProps} />
                                        </Stack>
                                    </Stack>
                                    {isModalOpen && (
                                        <Modal
                                            titleAriaId={'Approver Comments'}
                                            isOpen={true}
                                            onDismiss={() => {
                                                setisModalOpen(false);
                                            }}
                                            containerClassName={contentStyles.container}
                                            responsiveMode={ResponsiveMode.medium}
                                        // isBlocking={false}
                                        >
                                            <Stack style={{ padding: '20px' }}>
                                                <Text variant={'xLarge'} nowrap block>
                                                    Reject Exception Justification
                                                </Text>
                                                <TextField
                                                    label="Please provide the Justification that we can share with the employee."
                                                    onChange={onApproverCommentsValueChange}
                                                    multiline
                                                    autoAdjustHeight
                                                    required
                                                    validateOnLoad={false}
                                                    description={`${approverCommentLength} Characters Left`}
                                                />
                                                <Stack horizontal tokens={{ childrenGap: 20 }} verticalAlign={'center'}>
                                                    <StackItem>
                                                        <PrimaryButton
                                                            styles={actionButtonStyles}
                                                            text="OK"
                                                            onClick={() => {
                                                                onConfirm(exceptionStatus);
                                                            }}
                                                            ariaLabel="Confirm Action"
                                                        />
                                                    </StackItem>
                                                    <StackItem>
                                                        <PrimaryButton
                                                            styles={actionButtonStyles}
                                                            text="Cancel"
                                                            onClick={() => {
                                                                setisModalOpen(false);
                                                            }}
                                                            ariaLabel="Cancel"
                                                        />
                                                    </StackItem>
                                                </Stack>
                                            </Stack>
                                        </Modal>
                                    )}
                                </>
                            </Stack>
                            {StackDisclaimer()}
                        </Stack>
                    ) : (
                        <UnAuthorized />
                    )}
                </>
            )}
        </>
    );

    function _onRenderDetailsHeader(headerProps: any, defaultRender: any) {
        return defaultRender({
            ...headerProps,
            styles: {
                root: {
                    selectors: {
                        '.ms-DetailsHeader-cell': {
                            whiteSpace: 'normal',
                            lineHeight: 'normal',
                            wordWrap: 'break-word',
                            height: "100%",
                        },
                        '.ms-DetailsHeader-cellTitle': {
                            height: '100%',
                            alignItems: 'center',
                            wordWrap: 'break-word',
                        },
                    },
                    height: "60px"
                },
            },
        });
    }

    function _onRenderRow(props: IDetailsRowBaseProps | undefined, defaultRender: any | undefined) {
        const customStyles: Partial<IDetailsRowStyles> = {};
        let divProps;
        if (props) {
            if (props.item?.Status !== ReloFormStatusTypes.AUTHORIZED) {
                customStyles.root = {
                    color: 'blue',
                    fontFamily: 'Segoe UI',
                    fontSize: '12px',
                    selectors: {
                        '.ms-DetailsRow-cell': {
                            whiteSpace: 'normal',
                            lineHeight: 'normal',
                            alignItems: 'center',
                        },
                        '.ms-DetailsRow-cellTitle': {
                            height: '100%',
                            alignItems: 'center',
                            wordWrap: 'break-word',
                        },
                    },
                };
                divProps = {
                    onClick: () => { },
                };
            } else {
                customStyles.root = {
                    fontFamily: 'Segoe UI',
                    fontSize: '12px',
                    selectors: {
                        '.ms-DetailsRow-cell': {
                            whiteSpace: 'normal',
                            lineHeight: 'normal',
                            alignItems: 'center',
                        },
                        '.ms-DetailsRow-cellTitle': {
                            height: '100%',
                            alignItems: 'center',
                            wordWrap: 'break-word',
                        },
                    },
                };
            }
            divProps = {};

            return (
                <DetailsRow {...divProps} {...props} styles={customStyles}>
                    {defaultRender && defaultRender(props)}{' '}
                </DetailsRow>
            );
        }
        return null;
    }

    function _getKey(item: any, index?: number): string {
        return item.FileId;
    }

    function _getSelectionDetails(): void {
        const _selectedFileIds = selection.getSelection();
        let selectedIds: number[] = [];
        _selectedFileIds.forEach((item) => {
            if (!selectedIds.includes(item.FileExceptionID)) selectedIds.push(item.FileExceptionID);
        });
        if (selectedIds.length > 1) {
            selectedIds.splice(0, 1);
        }
        // selectedIds.join(',');
        setselectedExceptionId(selectedIds);
    }

    function _renderItemColumn(
        item: IPendingExceptionsApprovalQueue | undefined,
        index: number | undefined,
        column: IColumn | undefined
    ) {
        const fieldContent = item?.[column?.fieldName as keyof IPendingExceptionsApprovalQueue] as string;

        // const iconPropsWarnings: IIconProps = {
        //     iconName: 'WarningSolid',
        //     styles: {
        //         root: { color: '#EC9F1D', fontSize: '22px' },
        //     },
        // };

        const textFieldStyles: Partial<ITextFieldStyles> = {
            field: { color: 'Blue', fontSize: '12px', fontFamily: 'Segoe UI' },
        };

        const statusClass = mergeStyles({ fontSize: '12px', fontFamily: 'Segoe UI' });
        switch (column?.key) {
            case 'ActionDueDate':
                if (item?.ApproverArguency === 'C') {
                    return (
                        <Stack horizontal horizontalAlign="start">
                        <span className={statusClass}>Today</span>
                        <FontIcon
                            iconName="WarningSolid"
                            className={classNames.warning}
                        />
                    </Stack>
                    );
                }
                if (item?.ApproverArguency !== 'C') {
                    return (
                        <TextField
                            ariaLabel="ApproverArguency"
                            value={moment(item?.ActionDueDate).format('MM/DD/YYYY')}
                            styles={textFieldStyles}
                            borderless
                            role="textbox"
                        ></TextField>
                    );
                }

            default:
                return <span>{fieldContent}</span>;
        }
    }

    function _onColumnClick(event: React.MouseEvent<HTMLElement> | undefined, column: IColumn | undefined): void {
        let isSortedDescending = column?.isSortedDescending;
        if (column?.isSorted) {
            isSortedDescending = !isSortedDescending;
        }
        let sortedItems: IPendingExceptionsApprovalQueueForm[] = _copyAndSort(
            pendingExceptionApprovalQueue,
            column?.fieldName!,
            isSortedDescending
        );

        let sortedCol = columns.map((col) => {
            col.isSorted = col.key === column?.key;

            if (col.isSorted) {
                col.isSortedDescending = isSortedDescending;
            }
            return col;
        });
        setpendingExceptionApprovalQueue(sortedItems);
        setColumns(sortedCol);
    }

    function OnApproveOrRejectClick(Status: string) {
        if (selectedExceptionId.length < 1) {
            alert('Please select atleast one exception');
        } else {
            setexceptionStatus(Status);
            if (Status === 'Rejected') {
                setisModalOpen(true);
            } else {
                setLoaded(false);
                pendingExceptionQueueApprovalQueueService.UpdateSubmissionStatus(
                    selectedExceptionId,
                    Status,
                    "Approved"
                ).then((response) => {
                    if (response === true) {
                        setIsMessageBarVisible(true);
                        setMessageBarText('The exception request has been approved.');
                        setMessageBarType(MessageBarType.success);
                        setReloadData((preState) => !preState);
                        clearSelectedRecords();
                    }
                }).finally(() => setLoaded(true));
            }

        }
    }

    function onConfirm(Status: string) {
        if (approverComments.length < 1) {
            alert('Justification Required');
        } else {
            setisModalOpen(false);
            setLoaded(false)
            pendingExceptionQueueApprovalQueueService.UpdateSubmissionStatus(
                selectedExceptionId,
                Status,
                approverComments
            ).then((response) => {
                if (response == true) {
                    setIsMessageBarVisible(true);
                    setMessageBarText('The exception request has been rejected.');
                    setMessageBarType(MessageBarType.error);
                    setReloadData((preState) => !preState);
                    clearSelectedRecords()
                }
            }).finally(() => setLoaded(true));
        }
    }
}
