import { IHttpClient, IHttpClientResult } from '@employee-experience/common';
import { ILineItem } from '../../Models/IExportToFinance';

export class ExportToWebJEService {
    private httpClient: IHttpClient;
    private url: string;

    public constructor(httpClient: IHttpClient) {
        this.httpClient = httpClient;
        this.url = REACT_APP_RELO_API_URL;
    }

    public async GetLineItems(): Promise<ILineItem[]> {
        const response: IHttpClientResult<ILineItem[]> = await this.httpClient.request({
            url: `${this.url}api/reloform/GetLineItems`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    }

  
    public async UpdateOnWebJEExport(): Promise<boolean> {
        const response: IHttpClientResult<boolean> = await this.httpClient.request({
            url: `${this.url}api/PendingExceptions/UpdateOnWebJEExport`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    }
}
