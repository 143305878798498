import * as React from 'react';
import {
    Stack,
    IconButton,
    IStackItemStyles,
    IStackProps,
    IStackStyles,
    TextField,
    ITextFieldStyles,
    IDropdownOption,
} from '@fluentui/react';
import { useServiceStore } from '../../RootStateContext';
import { PersonalInfo } from '../../Models/INewRelocationRequest';

export type PersonalInformationProps = {
    personalInfo: PersonalInfo;
};

// --------- Styles ----------
const stackStyles: Partial<IStackStyles> = { root: { width: '100%', marginTop: '7px', padding:'14px' } };
const personalInfoColumnProps: Partial<IStackProps> = {
    tokens: { childrenGap: 15 },
    styles: { root: { width: '20%' } },
};
const personalInfoDepAddColumnProps: Partial<IStackProps> = {
    tokens: { childrenGap: 15 },
    styles: { root: { width: '90%',  padding: '5px' } },
};
const threeColumnProps: Partial<IStackProps> = {
    tokens: { childrenGap: 15 },
    styles: { root: { width: '32.33%' } },
};
const formSectionStyle = {
    padding :'0px 2px 0px 2px'
};
const accordionHeader: IStackItemStyles = {
    root: {
        padding: 5,
        cursor: 'pointer',
        fontSize: '20px',
    },
};
const accordionContent: IStackItemStyles = {
    root: {
        margin: '0px 0px 7px 11px',
        width: '99%',
        boxShadow: 'rgb(0 0 0 / 16%) 0px 1px 4px'
    },
};
// --------- Constants ---------
const stackTokens = { childrenGap: 20 };
// --------------------------------------------------
export const PersonalInformation: React.FC<PersonalInformationProps> = (props) => {
    const [isOpen, setIsOpen] = React.useState<boolean>(true);
    const [firstNameTextFieldValue, setFirstNameTextFieldValue] = React.useState(
        props.personalInfo.personalDetails.firstName || ''
    );
    const [middleNameTextFieldValue, setMiddleNameTextFieldValue] = React.useState(
        props.personalInfo.personalDetails.middleName || ''
    );
    const [lastNameTextFieldValue, setLastNameTextFieldValue] = React.useState(
        props.personalInfo.personalDetails.lastName || ''
    );
    const [personalPhoneTextFieldValue, setPersonalPhoneTextFieldValue] = React.useState(
        props.personalInfo.personalDetails.personalPhone || ''
    );
    const [workPhoneTextFieldValue, setWorkPhoneTextFieldValue] = React.useState(
        props.personalInfo.personalDetails.workPhone || ''
    );
    const [currentEmailTextFieldValue, setCurrentEmailTextFieldValue] = React.useState(
        props.personalInfo.personalDetails.currentEmail || ''
    );
    const [departureAddressTextFieldValue, setDepartureAddressTextFieldValue] = React.useState(
        props.personalInfo.departureAddress.address || ''
    );
    const [departureAddressApartmentTextFieldValue, setDepartureApartmentTextFieldValue] = React.useState(
        props.personalInfo.departureAddress.apartment || ''
    );
    const [departureCityTextFieldValue, setDepartureCityTextFieldValue] = React.useState(
        props.personalInfo.departureAddress.city || ''
    );

    const [departurePostalTextFieldValue, setDeparturePostalTextFieldValue] = React.useState(
        props.personalInfo.departureAddress.postalCode || ''
    );

    const [workDestinationCityTextFieldValue, setWorkDestinationCityTextFieldValue] = React.useState(
        props.personalInfo.workDestinationAddress.City || ''
    );
    const [homeDestinationCityTextFieldValue, setHomeDestinationCityTextFieldValue] = React.useState(
        props.personalInfo.homeDestinationAddress.City || ''
    );

    const [selectedDepartureCountry, setSelectedDepartureCountry] = React.useState(
        props.personalInfo.departureAddress.country || ''
    );

    const [selectedDepartureState, setSelectedDepartureState] = React.useState(
        props.personalInfo.departureAddress.stateProvince || ''
    );
    const [selectedWorkDestinationCountry, setSelectedWorkDestinationCountry] = React.useState(
        props.personalInfo.workDestinationAddress.country || ''
    );
    const [selectedWorkDestinationState, setSelectedWorkDestinationState] = React.useState(
        props.personalInfo.workDestinationAddress.stateProvince || ''
    );
    const [selectedHomeDestinationCountry, setSelectedHomeDestinationCountry] = React.useState(
        props.personalInfo.homeDestinationAddress.country || ''
    );
    const [selectedHomeDestinationState, setSelectedHomeDestinationState] = React.useState(
        props.personalInfo.homeDestinationAddress.stateProvince || ''
    );
    const [visaPendingValue] = React.useState<string>(props.personalInfo.personalDetails.visaPending);

    const textFieldStyles: Partial<ITextFieldStyles> = { fieldGroup: { width: 200 } };
    const { reviewFormService } = useServiceStore();

    var departureStateOptions: IDropdownOption[] = [];
    var destinationWorkStateOptions: IDropdownOption[] = [];
    var destinationHomeStateOptions: IDropdownOption[] = [];

    // Opens and closes hire information component
    const togglePersonalInformation = () => {
        setIsOpen(!isOpen);
    };

    React.useEffect(() => {
        if (props.personalInfo.personalDetails.firstName !== firstNameTextFieldValue) {
            setFirstNameTextFieldValue(props.personalInfo.personalDetails.firstName);
        }
        if (props.personalInfo.personalDetails.middleName !== middleNameTextFieldValue) {
            setMiddleNameTextFieldValue(props.personalInfo.personalDetails.middleName);
        }
        if (props.personalInfo.personalDetails.lastName !== lastNameTextFieldValue) {
            setLastNameTextFieldValue(props.personalInfo.personalDetails.lastName);
        }
        if (props.personalInfo.personalDetails.personalPhone !== personalPhoneTextFieldValue) {
            setPersonalPhoneTextFieldValue(props.personalInfo.personalDetails.personalPhone);
        }
        if (props.personalInfo.personalDetails.workPhone !== workPhoneTextFieldValue) {
            setWorkPhoneTextFieldValue(props.personalInfo.personalDetails.workPhone);
        }
        if (props.personalInfo.personalDetails.currentEmail !== currentEmailTextFieldValue) {
            setCurrentEmailTextFieldValue(props.personalInfo.personalDetails.currentEmail);
        }
        if (props.personalInfo.homeDestinationAddress.city !== homeDestinationCityTextFieldValue) {
            setHomeDestinationCityTextFieldValue(props.personalInfo.homeDestinationAddress.city);
        }
        if (props.personalInfo.workDestinationAddress.city !== workDestinationCityTextFieldValue) {
            setWorkDestinationCityTextFieldValue(props.personalInfo.workDestinationAddress.city);
        }

        if (props.personalInfo.departureAddress.address !== departureAddressTextFieldValue) {
            setDepartureAddressTextFieldValue(props.personalInfo.departureAddress.address);
        }

        if (props.personalInfo.departureAddress.apartment !== departureAddressApartmentTextFieldValue) {
            setDepartureApartmentTextFieldValue(props.personalInfo.departureAddress.apartment);
        }

        if (props.personalInfo.departureAddress.city !== departureCityTextFieldValue) {
            setDepartureCityTextFieldValue(props.personalInfo.departureAddress.city);
        }
        if (props.personalInfo.departureAddress.postalCode !== departurePostalTextFieldValue) {
            setDeparturePostalTextFieldValue(props.personalInfo.departureAddress.postalCode);
        }
    }, [props.personalInfo]);

    React.useEffect(() => {
        let txt = document.querySelectorAll('.ms-TextField-field');
        txt.forEach((item)=>{
            item.setAttribute('role','textbox');
            item.setAttribute('tabindex','0');
        })
        let inp = document.querySelectorAll('input.ms-TextField-field');
        let btn = document.querySelectorAll('button.ms-Button.ms-Button--icon');
                
        btn.forEach((item)=>{
                    item.setAttribute('tabindex','0');
            item.setAttribute('role', 'button');
            if (item.getAttribute('aria-hidden') == 'true') {
                item.setAttribute('disabled', 'true');
            }
                    
        })
        inp.forEach((item)=>{
                    if(item.hasAttribute('disabled'))
                    {
                        item.removeAttribute('aria-expanded');
                    }
                    item.setAttribute('role','textbox');
                    item.setAttribute('tabindex','0');
        })
        var countriesDropdown: IDropdownOption[] = [];
        let departCountry = props.personalInfo.departureAddress.Country;
        let destinationCountry = props.personalInfo.workDestinationAddress.Country;
        let homeCountry = props.personalInfo.homeDestinationAddress.Country;
        reviewFormService.getAllCountryNames().then((countries) => {
            countries.CountryNames.forEach((country) => {
                countriesDropdown.push({ key: country, text: country });

                if (country === props.personalInfo.departureAddress.country) {
                    setSelectedDepartureCountry(country);
                }
                if (country === props.personalInfo.workDestinationAddress.country) {
                    setSelectedWorkDestinationCountry(country);
                }
                if (country === props.personalInfo.homeDestinationAddress.country) {
                    setSelectedHomeDestinationCountry(country);
                }
            });
            if(departCountry){
                reviewFormService.getAllStateNames(selectedDepartureCountry).then((departurestatedata) => {
                    departurestatedata.stateNames.forEach((state) => {
                        if (state == props.personalInfo.departureAddress.stateProvince) {
                            setSelectedDepartureState(state);
                        }
                        departureStateOptions.push({ key: state, text: state });
                    });
                });
            }
            if(destinationCountry){
                reviewFormService.getAllStateNames(selectedWorkDestinationCountry).then((destinationworkstatedata) => {
                    destinationworkstatedata.stateNames.forEach((state) => {
                        if (state == props.personalInfo.workDestinationAddress.stateProvince) {
                            setSelectedWorkDestinationState(state);
                        }
                        destinationWorkStateOptions.push({ key: state, text: state, data: 'Departure' });
                    });
                });
            }
            if(homeCountry){
                reviewFormService.getAllStateNames(selectedHomeDestinationCountry).then((destinationhomestatedata) => {
                    destinationhomestatedata.stateNames.forEach((state) => {
                        if (state == props.personalInfo.homeDestinationAddress.stateProvince) {
                            setSelectedHomeDestinationState(state);
                        }
                        destinationHomeStateOptions.push({ key: state, text: state });
                    });
                });
            }
        });
    }, []);

    const _setHomeDestinationAddress = () => {
        setHomeDestinationCityTextFieldValue(workDestinationCityTextFieldValue);
        setSelectedHomeDestinationCountry(selectedWorkDestinationCountry);
        setSelectedHomeDestinationState(selectedWorkDestinationState);
    }

    return (
        <Stack className="PersonalInformation" style={formSectionStyle}>
            <Stack horizontal={true} style={{ backgroundColor: 'rgb(235, 235, 235)', maxWidth: '100%', margin: '5px 0px 0px 5px' }}>
                <Stack.Item style={{ marginTop: '3px' }} className="ac-icon">
                    <IconButton
                        aria-labelledby="Personal Information"
                        title="Personal Information"
                        iconProps={{
                            iconName: isOpen ? 'ChevronUp' : 'ChevronDown',
                        }}
                        onClick={togglePersonalInformation}
                    />
                </Stack.Item>
               <Stack.Item align="center" styles={accordionHeader} className="ac-title">
               <h2 style={{fontSize:'20px'}}> Personal Information </h2>
                </Stack.Item>
            </Stack>
            {isOpen && (
                <Stack styles={accordionContent}>
                    <Stack horizontal tokens={stackTokens} styles={stackStyles}>
                        <Stack.Item {...personalInfoColumnProps}>
                            <TextField
                                label="First Name"
                                value={firstNameTextFieldValue}
                                styles={textFieldStyles}
                                readOnly={true}
                                disabled={true}
                            />
                        </Stack.Item>
                        <Stack.Item {...personalInfoColumnProps}>
                            <TextField
                                label="Middle Name (optional)"
                                value={middleNameTextFieldValue}
                                styles={textFieldStyles}
                                readOnly={true}
                                disabled={true}
                            />
                        </Stack.Item>
                        <Stack.Item {...personalInfoColumnProps}>
                            <TextField
                                label="Last Name"
                                value={lastNameTextFieldValue}
                                styles={textFieldStyles}
                                readOnly={true}
                                disabled={true}
                            />
                        </Stack.Item>
                        <Stack.Item {...personalInfoColumnProps}>
                            <TextField
                                label="Personal Phone"
                                value={personalPhoneTextFieldValue}
                                styles={textFieldStyles}
                                readOnly={true}
                                disabled={true}
                            />
                        </Stack.Item>
                    </Stack>
                    <Stack horizontal tokens={stackTokens} styles={stackStyles}>
                        <Stack.Item {...personalInfoColumnProps}>
                            <TextField
                                label="Work Phone (optional)"
                                value={workPhoneTextFieldValue}
                                styles={textFieldStyles}
                                readOnly={true}
                                disabled={true}
                            />
                        </Stack.Item>
                        <Stack.Item {...personalInfoColumnProps}>
                            <TextField
                                label="Current Email"
                                value={currentEmailTextFieldValue}
                                styles={textFieldStyles}
                                readOnly={true}
                                disabled={true}
                            />
                        </Stack.Item>
                        <Stack.Item {...personalInfoColumnProps}>
                            <TextField
                                label="Visa Pending?"
                                value={visaPendingValue}
                                styles={textFieldStyles}
                                readOnly={true}
                                disabled={true}
                            />
                        </Stack.Item>
                    </Stack>

                    <Stack horizontal tokens={stackTokens} styles={stackStyles}>
                        <Stack {...personalInfoDepAddColumnProps}>
                            <h3 style={{ backgroundColor: 'rgb(235, 235, 235)', padding: '4px' }}>
                                Departure Address
                            </h3>
                            <Stack style={{ backgroundColor:'white',boxShadow: 'rgb(0 0 0 / 16%) 0px 1px 4px', marginTop:'0px' }}>
                                <Stack horizontal tokens={stackTokens} styles={stackStyles}>
                                    <Stack {...personalInfoColumnProps}>
                                        <TextField
                                            label="Country"
                                            value={selectedDepartureCountry}
                                            readOnly={true}
                                            disabled={true}
                                            styles={textFieldStyles}
                                        />
                                    </Stack>
                                    <Stack {...personalInfoColumnProps}>
                                        <TextField
                                            label="Address"
                                            value={departureAddressTextFieldValue}
                                            styles={textFieldStyles}
                                            readOnly={true}
                                            disabled={true}
                                        />
                                    </Stack>
                                    <Stack {...personalInfoColumnProps}>
                                        <TextField
                                            label="Apartment/Suite (optional)"
                                            value={departureAddressApartmentTextFieldValue}
                                            styles={textFieldStyles}
                                            maxLength={80}
                                            readOnly={true}
                                            disabled={true}
                                        />
                                    </Stack>
                                    <Stack {...threeColumnProps}>
                                        <TextField
                                            label="City"
                                            value={departureCityTextFieldValue}
                                            readOnly={true}
                                            disabled={true}
                                            styles={textFieldStyles}
                                        />
                                    </Stack>                                    
                                </Stack>
                                <Stack horizontal tokens={stackTokens} styles={stackStyles}>
                                    <Stack {...personalInfoColumnProps}>
                                        <TextField
                                            label="State/Province"
                                            value={selectedDepartureState}
                                            readOnly={true}
                                            disabled={true}
                                            styles={textFieldStyles}
                                        />
                                    </Stack>
                                    <Stack {...personalInfoColumnProps}>
                                        <TextField
                                            label="Postal Code (optional)"
                                            value={departurePostalTextFieldValue}
                                            styles={textFieldStyles}
                                            maxLength={10}
                                            readOnly={true}
                                            disabled={true}
                                        />
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>
                    <Stack horizontal tokens={stackTokens} styles={stackStyles}>
                        <Stack {...personalInfoDepAddColumnProps}>
                            <h3 style={{ backgroundColor:  'rgb(235, 235, 235)', padding: '4px' }}>
                                Destination Address
                            </h3>
                            <Stack style={{ backgroundColor:'white',boxShadow: 'rgb(0 0 0 / 16%) 0px 1px 4px', marginTop:'0px'}}>
                                <h4 style={{ paddingLeft: '10px' }}>Work Destination Address</h4>
                                <Stack horizontal tokens={stackTokens} styles={stackStyles}>
                                    <Stack {...personalInfoColumnProps}>
                                        <TextField
                                            label="Country"
                                            value={selectedWorkDestinationCountry}
                                            styles={textFieldStyles}
                                            readOnly={true}
                                            disabled={true}
                                            onBlur={_setHomeDestinationAddress}
                                        />
                                    </Stack>
                                    <Stack {...personalInfoColumnProps}>
                                        <TextField
                                            label="City"
                                            value={workDestinationCityTextFieldValue}
                                            onBlur={_setHomeDestinationAddress}
                                            styles={textFieldStyles}
                                            readOnly={true}
                                            disabled={true}
                                        />
                                    </Stack>                                   

                                    <Stack {...personalInfoColumnProps}>
                                        <TextField
                                            label="State/Province"
                                            value={selectedWorkDestinationState}
                                            onBlur={_setHomeDestinationAddress}
                                            styles={textFieldStyles}
                                            readOnly={true}
                                            disabled={true}
                                        />
                                    </Stack>
                                </Stack>
                                <hr />
                                <div style={{display:'none'}}>
                                <Stack>    
                                    <div>
                                        <h4 style={{ paddingLeft: '10px', width: '43%', float: 'left' }}>
                                            Home Destination Address
                                        </h4>
                                    </div>
                                    <Stack horizontal tokens={stackTokens} styles={stackStyles}>
                                        <Stack {...personalInfoColumnProps}>
                                            <TextField
                                                label="Country"
                                                value={selectedHomeDestinationCountry}
                                                styles={textFieldStyles}
                                                readOnly={true}
                                                disabled={true}
                                            />
                                        </Stack>
                                        <Stack {...personalInfoColumnProps}>
                                            <TextField
                                                label="City"
                                                value={homeDestinationCityTextFieldValue}
                                                readOnly={true}
                                                disabled={true}
                                                styles={textFieldStyles}
                                            />
                                        </Stack>                                    
                                        <Stack {...personalInfoColumnProps}>
                                            <TextField
                                                label="State/Province"
                                                value={selectedHomeDestinationState}
                                                styles={textFieldStyles}
                                                readOnly={true}
                                                disabled={true}
                                            />
                                        </Stack>
                                    </Stack>
                                </Stack>
                                </div>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            )}
        </Stack>
    );
};
