import {
    ChoiceGroup,
    IChoiceGroupOption,
    IconButton,
    IStackProps,
    IStackStyles,
    Link,
    Spinner,
    SpinnerSize,
    Stack,
    TextField,
    Label,
    mergeStyleSets
} from '@fluentui/react';
import * as React from 'react';
import Dropzone from 'react-dropzone';
import { AttachReloSummary } from '../../Models/IReviewForm';
import { useServiceStore } from '../../RootStateContext';

interface RelocationSummaryAttachmentProps {
    uploadaddfile: Function;
    uploadfile: Function;
    RemoveSelectedReloFile: Function;
    RemoveAddSelectedReloFile: Function;
    validateOnSubmit: boolean;
    validateOnReSubmit: boolean;
    erroredFields: Function;
    clearedFields: Function;
    updateAdditionalDescriptionValue: Function;
    reloFile: string;
    reloID: number;
    hireTypeID : number;
    addtionalReloFile0: AttachReloSummary;
    addtionalReloFile1: AttachReloSummary;
    addtionalReloFile2: AttachReloSummary;
    addtionalReloFile3: AttachReloSummary;
    addtionalReloFile4: AttachReloSummary;
    GUID0: string;
    GUID1: string;
    GUID2: string;
    GUID3: string;
    GUID4: string;
    AttachRelocationSummaryText: string;
}

// --------- Constants ----------
const stackTokens = { childrenGap: 20 };

const classNames = mergeStyleSets({
    stackImportant: {
        'flex-flow': 'initial',
    },
    LblImportant: {
        'color': '#C11616',
        'padding-left': '20px',
    },
});

const stackStyles: Partial<IStackStyles> = { root: { width: '100%', marginBottom: '5px' ,paddingLeft:10 } };

const oneColumnProps: Partial<IStackProps> = {
    tokens: { childrenGap: 15 },
    styles: { root: { width: '100%' } },
};

export const RelocationSummaryAttachment: React.FC<RelocationSummaryAttachmentProps> = React.forwardRef((props, ref) => {
    const { startNewReloRequestService } = useServiceStore();

    const [loading, setLoading] = React.useState<boolean>(false);

    const [selectedAttachmentType, setSelectedAttachmentType] = React.useState('ReloSummary');

    // Variables for required relo summary
    const [fileUpload, setFileUpload] = React.useState<AttachReloSummary>({} as AttachReloSummary);
    const [showFileUpload, setShowFileUpload] = React.useState<boolean>(false);
    const [fileRequiredError, setFileRequiredError] = React.useState<string | undefined>(undefined);

    // Variables for additional relo summaries
    const [fileUpload0, setFileUpload0] = React.useState<AttachReloSummary>({} as AttachReloSummary);
    const [fileUpload1, setFileUpload1] = React.useState<AttachReloSummary>({} as AttachReloSummary);
    const [fileUpload2, setFileUpload2] = React.useState<AttachReloSummary>({} as AttachReloSummary);
    const [fileUpload3, setFileUpload3] = React.useState<AttachReloSummary>({} as AttachReloSummary);
    const [fileUpload4, setFileUpload4] = React.useState<AttachReloSummary>({} as AttachReloSummary);

    const [showFileUpload0, setShowFileUpload0] = React.useState<boolean>(false);
    const [showFileUpload1, setShowFileUpload1] = React.useState<boolean>(false);
    const [showFileUpload2, setShowFileUpload2] = React.useState<boolean>(false);
    const [showFileUpload3, setShowFileUpload3] = React.useState<boolean>(false);
    const [showFileUpload4, setShowFileUpload4] = React.useState<boolean>(false);

    const [addDescriptionError0, setAddDescriptionError0] = React.useState<string | undefined>(undefined);
    const [addDescriptionError1, setAddDescriptionError1] = React.useState<string | undefined>(undefined);
    const [addDescriptionError2, setAddDescriptionError2] = React.useState<string | undefined>(undefined);
    const [addDescriptionError3, setAddDescriptionError3] = React.useState<string | undefined>(undefined);
    const [addDescriptionError4, setAddDescriptionError4] = React.useState<string | undefined>(undefined);

    const _validFileExtensions = ['.doc', '.docx', '.msg', '.pdf'];
    const invalidExtensionError = ' is invalid, allowed extensions are: ' + _validFileExtensions.join(', ');
    const invalidFilenameError = ' is too long, file name should be less than 100 characters.';
    const invalidSizeError = ' is too large, allowed size less than 1 MB.';

    const options: IChoiceGroupOption[] = [
        { key: 'ReloSummary', text: props.AttachRelocationSummaryText, iconProps: { iconName: 'CalendarDay' } },
        { key: 'AdditionalDocument', text: 'Additional Document', iconProps: { iconName: 'CalendarWeek' } },
    ];
    React.useImperativeHandle(
        ref,
        () => ({
            validateForm() {
                let invalidFields: string[] = [];
                if (props.hireTypeID !=8 && !showFileUpload && !fileUploadIsValid(fileUpload)) {
                    invalidFields.push('uploadFile');
                }
                if (showFileUpload0 && !isValidString(fileUpload0.AddtionalDescription)) {
                    invalidFields.push('addtionalDescription0');
                }
                if (showFileUpload1 && !isValidString(fileUpload1.AddtionalDescription)) {
                    invalidFields.push('addtionalDescription1');
                }
                if (showFileUpload2 && !isValidString(fileUpload2.AddtionalDescription)) {
                    invalidFields.push('addtionalDescription2');
                }
                if (showFileUpload3 && !isValidString(fileUpload3.AddtionalDescription)) {
                    invalidFields.push('addtionalDescription3');
                }
                if (showFileUpload4 && !isValidString(fileUpload4.AddtionalDescription)) {
                    invalidFields.push('addtionalDescription4');
                }
                return { errors: Array.from(new Set(invalidFields)) }
            }
        }),
    );
    function ValidateFileUploadX(setFileUploadX: Function, setShowFileUploadX: Function, fileUploadX: AttachReloSummary) {
        if (fileUploadIsValid(fileUploadX)) {
            setFileUploadX(fileUploadX);
            setShowFileUploadX(true);
        } else {
            setFileUploadX({} as AttachReloSummary);
            setShowFileUploadX(false);
        }
    }

    React.useEffect(() => {
        ValidateFileUploadX(setFileUpload, setShowFileUpload, props.reloFile);
    }, [props.reloFile])

    React.useEffect(() => {
        ValidateFileUploadX(setFileUpload0, setShowFileUpload0, props.addtionalReloFile0);
    }, [props.addtionalReloFile0])

    React.useEffect(() => {
        ValidateFileUploadX(setFileUpload1, setShowFileUpload1, props.addtionalReloFile1);
    }, [props.addtionalReloFile1])

    React.useEffect(() => {
        ValidateFileUploadX(setFileUpload2, setShowFileUpload2, props.addtionalReloFile2);
    }, [props.addtionalReloFile2])

    React.useEffect(() => {
        ValidateFileUploadX(setFileUpload3, setShowFileUpload3, props.addtionalReloFile3);
    }, [props.addtionalReloFile3])

    React.useEffect(() => {
        ValidateFileUploadX(setFileUpload4, setShowFileUpload4, props.addtionalReloFile4);
    }, [props.addtionalReloFile4])

    const onDropFile = (file: File[]) => {
        props.hidemessageBox();
    
        if (file) {
            if (selectedAttachmentType === 'ReloSummary') {
                ValidateSingleInput(file[0]);
            }
            if (selectedAttachmentType === 'AdditionalDocument') {
                if (!isValidString(fileUpload0.Filename)) {
                    ValidateAdditionalSingleInput(file[0], 0);
                } else if (!isValidString(fileUpload1.Filename)) {
                    ValidateAdditionalSingleInput(file[0], 1);
                } else if (!isValidString(fileUpload2.Filename)) {
                    ValidateAdditionalSingleInput(file[0], 2);
                } else if (!isValidString(fileUpload3.Filename)) {
                    ValidateAdditionalSingleInput(file[0], 3);
                } else if (!isValidString(fileUpload4.Filename)) {
                    ValidateAdditionalSingleInput(file[0], 4);
                }
            }
        }
    };

    const onAttachmentTypeChange = (
        _ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
        option?: IChoiceGroupOption | undefined
    ): void => {
        setSelectedAttachmentType(option!.key);
    };

    function handleInvalidDescriptionX(setAddDescriptionErrorX: Function, fieldName: string) {
        setAddDescriptionErrorX('Required');
        props.erroredFields([fieldName]);
    }

    React.useEffect(() => {
        if (props.validateOnSubmit || props.validateOnReSubmit) {
            if (props.hireTypeID !=8 && !showFileUpload && !fileUploadIsValid(fileUpload)) {
                setFileRequiredError('Please upload the file.');
                props.erroredFields(['uploadFile']);
            }
            if (showFileUpload0 && !isValidString(fileUpload0.AddtionalDescription)) {
                handleInvalidDescriptionX(setAddDescriptionError0, 'addtionalDescription0');
            }
            if (showFileUpload1 && !isValidString(fileUpload1.AddtionalDescription)) {
                handleInvalidDescriptionX(setAddDescriptionError1, 'addtionalDescription1');
            }
            if (showFileUpload2 && !isValidString(fileUpload2.AddtionalDescription)) {
                handleInvalidDescriptionX(setAddDescriptionError2, 'addtionalDescription2');
            }
            if (showFileUpload3 && !isValidString(fileUpload3.AddtionalDescription)) {
                handleInvalidDescriptionX(setAddDescriptionError3, 'addtionalDescription3');
            }
            if (showFileUpload4 && !isValidString(fileUpload4.AddtionalDescription)) {
                handleInvalidDescriptionX(setAddDescriptionError4, 'addtionalDescription4');
            }
        }
    }, [props.validateOnSubmit, props.validateOnReSubmit]);
    
    React.useEffect(()=>{
        let txt = document.querySelectorAll('textarea');
        txt.forEach((item)=>{
            item.setAttribute('tabindex','0');
            item.setAttribute('role','textbox');
        })

    })

    return (
        <>
            {loading && (
                <Stack grow>
                    <div>
                        <Spinner
                            label="Loading, please wait..."
                            size={SpinnerSize.small}
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                height: '100%',
                                width: '100%',
                                zIndex: 9999999,
                                backgroundColor: 'rgb(255, 255, 255, 255)',
                            }}
                        />
                    </div>
                </Stack>
            )}

            <Stack styles={stackStyles}>
                <ChoiceGroup
                    aria-label="Attach Relocation Summary"
                    label="Attach Relocation Summary"
                    defaultSelectedKey={selectedAttachmentType}
                    options={[options[0]]}
                    onChange={onAttachmentTypeChange}
                    selectedKey={selectedAttachmentType}
                />
                {showFileUpload && (
                    <>
                        <Stack horizontal tokens={stackTokens} styles={{ root: { width: '30%', marginBottom: '5px' } }}>
                            <Link
                                aria-label={`Download ${fileUpload.Filename} File Link`}File Link
                                onClick={async () => {
                                    await DownloadRelocationSummary()
                                }}
                            >
                                {fileUpload.Filename}
                            </Link>
                            <IconButton
                                iconProps={{ iconName: 'Delete' }}
                                className="BulkUploadDeleteIcon"
                                onClick={RemoveAttachment}
                                title="Clear"
                                aria-label={`${fileUpload.Filename} File Clear`}
                            />
                        </Stack>
                    </>
                )}
                <p style={{
                        color: 'rgb(164, 38, 44)',
                        fontSize: '12px',
                        fontWeight: 400,
                        paddingTop: '5px',
                    }}
                >
                    {fileRequiredError}
                </p>
            </Stack>
            <Stack styles={stackStyles}>
                <ChoiceGroup
                    aria-label="Attach Additional Summary"
                    defaultSelectedKey={selectedAttachmentType}
                    options={[options[1]]}
                    onChange={onAttachmentTypeChange}
                    selectedKey={selectedAttachmentType}
                />
                {showFileUpload0 && (
                    <>
                        <Stack horizontal>
                            <Stack horizontal tokens={stackTokens} styles={{ root: { width: '30%', marginBottom: '5px' } }}>
                                <Link
                                    aria-label={`Download ${fileUpload0.Filename} File Link`}File Link
                                    onClick={async () => {
                                        await DownloadAddRelocationSummary(props.GUID0, fileUpload0.Filename)
                                    }}
                                >
                                    {fileUpload0.Filename}
                                </Link>
                                <IconButton
                                    iconProps={{ iconName: 'Delete' }}
                                    className="BulkUploadDeleteIcon"
                                    onClick={() => {
                                        RemoveAddSelectedAddFile(0);
                                    }}
                                    title="Clear"
                                    aria-label={`${fileUpload0.Filename} File Clear`}
                                />
                            </Stack>
                            <Stack>
                                <TextField
                                    ariaLabel="Additional Description"
                                    required
                                    placeholder="Enter additional description"
                                    value={fileUpload0.AddtionalDescription}
                                    data-attr={0}
                                    onChange={onAdditionalTextChange}
                                    errorMessage={addDescriptionError0}
                                ></TextField>
                            </Stack>
                        </Stack>
                    </>
                )}
                {showFileUpload1 && (
                    <>
                        <Stack horizontal>
                            <Stack horizontal tokens={stackTokens} styles={{ root: { width: '30%', marginBottom: '5px' } }}>
                                <Link
                                    aria-label={`Download ${fileUpload1.Filename} File Link`}File Link
                                    onClick={async () => {
                                        await DownloadAddRelocationSummary(props.GUID1, fileUpload1.Filename)
                                    }}
                                >
                                    {fileUpload1.Filename}
                                </Link>
                                <IconButton
                                    iconProps={{ iconName: 'Delete' }}
                                    className="BulkUploadDeleteIcon"
                                    onClick={() => {
                                        RemoveAddSelectedAddFile(1);
                                    }}
                                    title="Clear"
                                    aria-label={`${fileUpload1.Filename} File Clear`}
                                />
                            </Stack>
                            <Stack>
                                <TextField
                                    ariaLabel="Additional Description"
                                    required
                                    placeholder="Enter additional description"
                                    value={fileUpload1.AddtionalDescription}
                                    data-attr={1}
                                    onChange={onAdditionalTextChange}
                                    errorMessage={addDescriptionError1}
                                ></TextField>
                            </Stack>
                        </Stack>
                    </>
                )}
                {showFileUpload2 && (
                    <>
                        <Stack horizontal>
                            <Stack horizontal tokens={stackTokens} styles={{ root: { width: '30%', marginBottom: '5px' } }}>
                                <Link
                                    aria-label={`Download ${fileUpload2.Filename} File Link`}File Link
                                    onClick={async () => {
                                        await DownloadAddRelocationSummary(props.GUID2, fileUpload2.Filename)
                                    }}
                                >
                                    {fileUpload2.Filename}
                                </Link>
                                <IconButton
                                    iconProps={{ iconName: 'Delete' }}
                                    className="BulkUploadDeleteIcon"
                                    onClick={() => {
                                        RemoveAddSelectedAddFile(2);
                                    }}
                                    title="Clear"
                                    aria-label={`${fileUpload2.Filename} File Clear`}
                                />
                            </Stack>
                            <Stack>
                                <TextField
                                    ariaLabel="Additional Description"
                                    required
                                    placeholder="Enter additional description"
                                    value={fileUpload2.AddtionalDescription}
                                    data-attr={2}
                                    onChange={onAdditionalTextChange}
                                    errorMessage={addDescriptionError2}
                                ></TextField>
                            </Stack>
                        </Stack>
                    </>
                )}
                {showFileUpload3 && (
                    <>
                        <Stack horizontal>
                            <Stack horizontal tokens={stackTokens} styles={{ root: { width: '30%', marginBottom: '5px' } }}>
                                <Link
                                    aria-label={`Download ${fileUpload3.Filename} File Link`}File Link
                                    onClick={async () => {
                                        await DownloadAddRelocationSummary(props.GUID3, fileUpload3.Filename)
                                    }}
                                >
                                    {fileUpload3.Filename}
                                </Link>
                                <IconButton
                                    iconProps={{ iconName: 'Delete' }}
                                    className="BulkUploadDeleteIcon"
                                    onClick={() => {
                                        RemoveAddSelectedAddFile(3);
                                    }}
                                    title="Clear"
                                    aria-label={`${fileUpload3.Filename} File Clear`}
                                />
                            </Stack>
                            <Stack>
                                <TextField
                                    ariaLabel="Additional Description"
                                    required
                                    placeholder="Enter additional description"
                                    value={fileUpload3.AddtionalDescription}
                                    data-attr={3}
                                    onChange={onAdditionalTextChange}
                                    errorMessage={addDescriptionError3}
                                ></TextField>
                            </Stack>
                        </Stack>
                    </>
                )}
                {showFileUpload4 && (
                    <>
                        <Stack horizontal>
                            <Stack horizontal tokens={stackTokens} styles={{ root: { width: '30%', marginBottom: '5px' } }}>
                                <Link
                                    aria-label={`Download ${fileUpload4.Filename} File Link`}File Link
                                    onClick={async () => {
                                        await DownloadAddRelocationSummary(props.GUID4, fileUpload4.Filename)
                                    }}
                                >
                                    {fileUpload4.Filename}
                                </Link>
                                <IconButton
                                    iconProps={{ iconName: 'Delete' }}
                                    className="BulkUploadDeleteIcon"
                                    onClick={() => {
                                        RemoveAddSelectedAddFile(4);
                                    }}
                                    title="Clear"
                                    aria-label={`${fileUpload4.Filename} File Clear`}
                                />
                            </Stack>
                            <Stack>
                                <TextField
                                    ariaLabel="Additional Description"
                                    required
                                    placeholder="Enter additional description"
                                    value={fileUpload4.AddtionalDescription}
                                    data-attr={4}
                                    onChange={onAdditionalTextChange}
                                    errorMessage={addDescriptionError4}
                                ></TextField>
                            </Stack>
                        </Stack>
                    </>
                )}
            </Stack>
            <Stack horizontal tokens={stackTokens} style={{margin:'10px'}}>
                <Stack {...oneColumnProps}>
                    <Dropzone onDrop={onDropFile} multiple={false}>
                        {({ getRootProps, getInputProps }) => (
                            <section className="container dragContainer">
                                <div {...getRootProps({ className: 'dropzone' })} style={{border:"2px solid black"}}>
                                    <input {...getInputProps()} />
                                    <p style={{ color: "black" }}>Drag n Drop or Click to select file</p>
                                </div>
                            </section>
                        )}
                    </Dropzone>
                </Stack>
            </Stack>
            <Stack className={classNames.stackImportant} className="imp-label-wrap">
                <Label className={classNames.LblImportant} className="imp-label"> IMPORTANT: </Label>
                <Label required>  To complete  the transaction you MUST click on the "Save/Submit" button to the right before leaving this page or your update will not be saved.</Label>
            </Stack>
        </>
    );

    function ValidateDescription(setAddDescriptionErrorX: Function, fieldName: string, description?: string) {
        if (!isValidString(description)) {
            handleInvalidDescriptionX(setAddDescriptionErrorX, fieldName);
        } else {
            setAddDescriptionErrorX(undefined);
            props.clearedFields(fieldName);
        }
    }

    function onAdditionalTextChange(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) {
        let txtBoxNum = Number(event!.target!.dataset.attr);
        props.updateAdditionalDescriptionValue(newValue!, txtBoxNum);
        switch (txtBoxNum) {
            case 0:
                ValidateDescription(setAddDescriptionError0, 'addtionalDescription0', newValue);
                break;
            case 1:
                ValidateDescription(setAddDescriptionError1, 'addtionalDescription1', newValue);
                break;
            case 2:
                ValidateDescription(setAddDescriptionError2, 'addtionalDescription2', newValue);
                break;
            case 3:
                ValidateDescription(setAddDescriptionError3, 'addtionalDescription3', newValue);
                break;
            case 4:
                ValidateDescription(setAddDescriptionError4, 'addtionalDescription4', newValue);
                break;
        }
    }

    function alertUserOfError(errorMessage: string) {
        alert('Sorry, ' + errorMessage);
    }

    function extensionIsValid(fileName: string, extension: string) {
        if (fileName.substr(fileName.length - extension.length, extension.length).toLowerCase() === extension) {
            return true;
        }
        return false;
    }

    function fileExtensionIsValid(fileName: string) {
        for (let j = 0; j < _validFileExtensions.length; j++) {
            if (extensionIsValid(fileName, _validFileExtensions[j])) {
                return true;
            }
        }
        alertUserOfError(fileName + invalidExtensionError);
        return false;
    }

    function fileNameIsValid(fileName: string) {
        if (fileName.length > 0 && fileName.length <= 100) {
            return true;
        }
        alertUserOfError(fileName + invalidFilenameError);
        return false;
    }

    function fileSizeIsValid(fileName: string, size: number) {
        if (size <= 1000000) {
            return true;
        }
        alertUserOfError(fileName + invalidSizeError);
        return false;
    }

    async function ValidateSingleInput(newFile: File) {
        const fileName = newFile.name;
        if (fileSizeIsValid(fileName, newFile.size) && fileNameIsValid(fileName) && fileExtensionIsValid(fileName)) {
            await props.uploadfile(newFile);
            setFileRequiredError(undefined);
            props.clearedFields('uploadFile');
        }
    }

    async function ValidateAdditionalSingleInput(newFile: File, i: number) {
        const fileName = newFile.name;
        if (fileSizeIsValid(fileName, newFile.size) && fileNameIsValid(fileName) && fileExtensionIsValid(fileName)) {
            await props.uploadaddfile(newFile, i);
        }
    }

    function RemoveAddSelectedAddFile(fileNum: number) {
        props.RemoveAddSelectedReloFile(fileNum);
    }

    function RemoveAttachment() {
        props.RemoveSelectedReloFile();
    }

    function downloadBlob(attachmentResponse: any, fileName: string) {
        if (attachmentResponse.status === 200 && attachmentResponse.data.length > 0) {
            const byteCharacters = atob(attachmentResponse.data);
            let byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], {
                type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            });
            saveAs(blob, fileName);
        }
    }

    async function DownloadRelocationSummary() {
        if (!isNullOrUndefined(props.reloID)) {
            setLoading(true);
            const attachmentResponse = await startNewReloRequestService.GetReloAttachment(props.reloID);
            downloadBlob(attachmentResponse, fileUpload.Filename);
            setLoading(false);
        }
    }

    async function DownloadAddRelocationSummary(guid: string, fileName: string) {
        if (!isNullOrUndefined(guid)) {
            setLoading(true);
            const attachmentResponse = await startNewReloRequestService.GetAdditionalReloAttachment(guid);
            downloadBlob(attachmentResponse, fileName);
            setLoading(false);
        }
    }

    function fileUploadIsValid(reloSummary: AttachReloSummary) {
        if (Object.keys(reloSummary).length > 0) {
            return true;
        } else {
            return false;
        }
    }

    function isNullOrUndefined(value: any) {
        if (value === null || value === undefined) {
            return true;
        }
        return false;
    }

    function isValidString(value: any) {
        if (isNullOrUndefined(value) || value === '') {
            return false;
        }
        return true;
    }
});
