import { IHttpClient, IHttpClientResult } from '@employee-experience/common';
import { IExceptionQueueForm, EmployeeExceptionQueueDetails } from '../../Models/IExceptionQueue';
import { RelocationType } from '../../Models/IEstimatesForm';

export class ExceptionQueueService {
    private httpClient: IHttpClient;
    private url: string;
    private apiURL: string;

    public constructor(httpClient: IHttpClient) {
        this.httpClient = httpClient;
        this.url = REACT_APP_RELO_API_URL;
        this.apiURL = '';
    }

    public async getQueueRecords(
        EndDate: string | undefined,
        StartDate: string | undefined
    ): Promise<IExceptionQueueForm[]> {
        if (__IS_ACCESSIBILITY_TESTING__ == 'true') {
            this.apiURL = `${this.url}api/reloform/GetExceptionQueue`;
        } else {
            this.apiURL = `${this.url}/api/reloform/GetExceptionQueue?EndDate=${EndDate}&StartDate=${StartDate}`;
        }
        const response: IHttpClientResult<IExceptionQueueForm[]> = await this.httpClient.request({
            url: `${this.apiURL}`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    }

    public async getEmployeeExceptionQueueDetails(ReloID: string): Promise<EmployeeExceptionQueueDetails> {
        const response: IHttpClientResult<EmployeeExceptionQueueDetails> = await this.httpClient.request({
            url: `${this.url}/api/GetEmployeeDetailsByReloID/${ReloID}`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    }

    public async getRelocationTypes(): Promise<RelocationType[]> {
        const response: IHttpClientResult<RelocationType[]> = await this.httpClient.request({
            url: `${this.url}api/policy/RelocationTypes`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    }

    public async getBusinessGroups(): Promise<string[]> {
        const response: IHttpClientResult<string[]> = await this.httpClient.request({
            url: `${this.url}api/policy/BusinessGroups`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    }
}
