export enum ActionType {
    SET_USERS = 'SET_USERS',
    SET_MYRELOQUEUE = 'SET_MYRELOQUEUE',
    SET_GLOMOCOEQUEUE = 'SET_GLOMOCOEQUEUE',
    SET_RELOCAMPQUEUE = 'SET_RELOCAMPQUEUE',
    SET_SENDBACKQUEUE = 'SET_SENDBACKQUEUE',
    SET_STARTNEWRELOCATION = 'SET_STARTNEWRELOCATION',
    SET_TAXRATESBYJOBLEVEL = 'SET_TAXRATESBYJOBLEVEL',
    SET_FILTERS = 'SET_FILTERS',
    SET_ACTIVETAB = 'SET_ACTIVETAB',
    RESET_FILTERS_QUEUE = 'RESET_FILTERS_QUEUE',
    RESET_FILTERS_SEARCH = 'RESET_FILTERS_SEARCH',
}
