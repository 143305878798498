import * as React from 'react';
import { TileComponentProps } from './GlomocoeQueueTable.types';
import * as GloQueueStyles from './GlomocoeQueueTable.styles';
import { GlomocoeTile } from './GlomocoeTile';

export const TilesComponent: React.FC<TileComponentProps> = ({ tileData, apiData, handleTileFilterSubmit }) => {
    const filterByTiles = (id: number) => {
        let tempData = apiData;
        switch (id) {
            // Industry
            case 9:
                tempData = apiData?.filter((row) => {
                    return (
                        row.RelocationTypeId === 8 ||
                        row.RelocationTypeId === 9 ||
                        row.RelocationTypeId === 10 ||
                        row.RelocationTypeId === 14 ||
                        row.RelocationTypeId === 15 ||
                        row.RelocationTypeId === 16 ||
                        row.RelocationTypeId === 18 ||
                        row.RelocationTypeId === 17
                    );
                });
                break;
            // Industry - US Domestic
            case 2:
                tempData = apiData?.filter((row) => {
                    return row.RelocationTypeId === 8 || row.RelocationTypeId === 9 || row.RelocationTypeId === 10;
                });
                break;
            // Industry - US Inbound
            case 4:
                tempData = apiData?.filter((row) => {
                    return (
                        row.RelocationTypeId === 14 ||
                        row.RelocationTypeId === 15 ||
                        row.RelocationTypeId === 16 ||
                        row.RelocationTypeId === 18 ||
                        row.RelocationTypeId === 17
                    );
                });
                break;
            // Global
            case 10:
                tempData = apiData?.filter((row) => {
                    return (
                        row.RelocationTypeId === 29 ||
                        row.RelocationTypeId === 30 ||
                        row.RelocationTypeId === 32 ||
                        row.RelocationTypeId === 41 ||
                        row.RelocationTypeId === 50 ||
                        row.RelocationTypeId === 51 ||
                        row.RelocationTypeId === 33
                    );
                });
                break;
            // Global - Non US Domestic
            case 1:
                tempData = apiData?.filter((row) => {
                    return row.RelocationTypeId === 29 || row.RelocationTypeId === 30 || row.RelocationTypeId === 41;
                });
                break;
            // Global - Non US Global
            case 3:
                tempData = apiData?.filter((row) => {
                    return row.RelocationTypeId === 32 || row.RelocationTypeId === 33 || row.RelocationTypeId === 50 || row.RelocationTypeId === 51;
                });
                break;
            // Global Plus Count
            case 6:
                tempData = apiData?.filter((row) => {
                    return (
                        row.RelocationTypeId === 1 ||
                        row.RelocationTypeId === 2 ||
                        row.RelocationTypeId === 3 ||
                        row.RelocationTypeId === 4
                    );
                });
                break;
            // Executive
            case 13:
                tempData = apiData?.filter((row) => {
                    return row.RelocationTypeId === 11 || row.RelocationTypeId === 7 || row.RelocationTypeId === 5 || row.RelocationTypeId === 42;
                });
                break;
            // Executive - Silver
            case 11:
                tempData = apiData?.filter((row) => {
                    return row.RelocationTypeId === 11;
                });
                break;
            // Executive - Platinum
            case 7:
                tempData = apiData?.filter((row) => {
                    return row.RelocationTypeId === 7;
                });
                break;
            // Executive - Silver International
            case 5:
                tempData = apiData?.filter((row) => {
                    return row.RelocationTypeId === 5;
                });
                break;
            // Visiting
            case 12:
                tempData = apiData?.filter((row) => {
                    return row.RelocationTypeId === 20 || row.RelocationTypeId === 21;
                });
                break;
            // Visiting - Artist
            case 20:
                tempData = apiData?.filter((row) => {
                    return row.RelocationTypeId === 20;
                });
                break;
            // Visiting - Research
            case 21:
                tempData = apiData?.filter((row) => {
                    return row.RelocationTypeId === 21;
                });
                break;
            // Pre Hire
            case 200:
                tempData = apiData?.filter((row) => {
                    return row.RelocationTypeId === 38;
                });
                break;
            // Lump Sum
            case 8:
                tempData = apiData?.filter((row) => {
                    return (
                        row.RelocationTypeId === 22 ||
                        row.RelocationTypeId === 24 ||
                        row.RelocationTypeId === 25 ||
                        row.RelocationTypeId === 28 ||
                        row.RelocationTypeId === 44 ||
                        row.RelocationTypeId === 45 ||
                        row.RelocationTypeId === 47 ||
                        row.RelocationTypeId === 48 ||
                        row.RelocationTypeId === 49 
                    );
                });
                break;
            //US Lump Sum
            case 14:
                tempData = apiData?.filter((row) => {
                    return (
                        row.RelocationTypeId === 22 ||
                        row.RelocationTypeId === 24 ||
                        row.RelocationTypeId === 25 ||
                        row.RelocationTypeId === 28                       
                    );
                });
                break;            
            //Non US Lump Sum
            case 15:
                tempData = apiData?.filter((row) => {
                    return (                        
                        row.RelocationTypeId === 44 ||
                        row.RelocationTypeId === 45 ||
                        row.RelocationTypeId === 47 ||
                        row.RelocationTypeId === 48 ||
                        row.RelocationTypeId === 49 
                    );
                });
                break;
            // Payroll Compliance
            case 16:
                tempData = apiData?.filter((row) => {
                    return (                        
                        row.RelocationTypeId === 54 ||
                        row.RelocationTypeId === 55
                    );
                }); 
                break;               
            // US Payroll Compliance
            case 17:
                tempData = apiData?.filter((row) => {
                    return (                        
                        row.RelocationTypeId === 54 
                    );
                });
                break;
            // NON US Payroll Compliance
            case 18:
                tempData = apiData?.filter((row) => {
                    return (                        
                        row.RelocationTypeId === 55
                    );
                });     
                break;
        
                // Default            
            default:
                tempData = apiData;
                break;
        }
        handleTileFilterSubmit(tempData);
    };

    return (
        <div className="contentWrap" style={{ overflow: 'auto' as 'auto', paddingBottom: '10px' }}>
            {tileData.map((tile, index) => {
                return (
                    <div style={GloQueueStyles.singleTileContainer}>
                        <GlomocoeTile instance={index} objParent={tile} filterByTiles={filterByTiles} />
                    </div>
                );
            })}
        </div>
    );
};
