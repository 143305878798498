import { useEffect, useState } from 'react';
import { IUser, useLoginOnStartup } from '@employee-experience/common';

export function useMockLoginOnStartup(): [IUser | null] {
    const [user, setUser] = useState<IUser | null>(null);

    useEffect(() => {
        if (__IS_ACCESSIBILITY_TESTING__ === 'true') {
            setUser({
                id: 'accessibility',
                email: 'mockemail@microsoft.com',
                name: 'accessibility',
                oid: '12345',
            });
        }
        return;
    }, []);

    return [user];
}

export function useLoginOnStartupBase() {
    return useLoginOnStartup();
}
