import * as React from 'react';
import {
    DetailsList,
    IColumn,
    SelectionMode,
    DetailsListLayoutMode,
    IDetailsRowBaseProps,
    Selection,
} from '@fluentui/react';

interface PreHireTypeBenefitsType {
    ID: string;
    BenefitName: string;
    BenefitDescptions: string;
    isSelected: boolean;
}

export interface PreHireTypeBenefitsProps {
    preHireTypeBenefits: PreHireTypeBenefitsType[];
}

export const PreHireTypePanel: React.FC<PreHireTypeBenefitsProps> = (props) => {
    const [items, setItems] = React.useState<PreHireTypeBenefitsType[]>([]);

    React.useEffect(() => {
        if (props.preHireTypeBenefits.length > 0 && props.preHireTypeBenefits !== undefined) {
            console.log('preHireTypeBenefits', props.preHireTypeBenefits);
            let tempBenefitsMapping = [];

            if (props.preHireTypeBenefitsMapping !== undefined && props.preHireTypeBenefitsMapping.length > 0) {

                props.preHireTypeBenefits.forEach((item) => {
                    props.preHireTypeBenefitsMapping.forEach((selected: any) => {
                        if (item.ID === selected.SelectedPreHireTypeBenefitID) {
                            tempBenefitsMapping.push(item)
                        }
                    })

                })

            }
            setItems(tempBenefitsMapping);
        }
    }, [props]);

    const columns: IColumn[] = [
        {
            key: 'column1',
            name: 'Benefits',
            ariaLabel: 'Column for BenefitName',
            fieldName: 'BenefitName',
            maxWidth: 180,
            minWidth: 50,
            isRowHeader: true,
            isResizable: true,
            isPadded: true,
        },
        {
            key: 'column2',
            name: 'Description of service options available',
            ariaLabel: 'Column  for Description of service options available',
            fieldName: 'BenefitDescptions',
            maxWidth: 100,
            minWidth: 50,
            isRowHeader: true,
            isResizable: true,
            isPadded: true,
        },
    ];

    function _onRenderRow(props: IDetailsRowBaseProps | undefined, defaultRender: any | undefined) {
        if (!props || !defaultRender) {
            return null;
        }
        return defaultRender({
            ...props,
            styles: {
                root: {
                    selectors: {
                        '.ms-DetailsRow-cell': {
                            whiteSpace: 'normal',
                            lineHeight: 'normal',
                            alignItems: 'center',
                        },
                        '.ms-DetailsRow-cellTitle': {
                            height: '100%',
                            alignItems: 'center',
                            wordWrap: 'break-word',
                        },
                    },
                },
            },
        });
    }

    function _getSelectionDetails(): string {
        const selectionCount = selection.getSelectedCount();
        switch (selectionCount) {
            case 0:
                return 'No items selected';
            case 1:
                return '1 item selected: ';
            default:
                return `${selectionCount} items selected`;
        }
    }

    const selection = React.useMemo(
        () =>
            new Selection({
                onSelectionChanged: () => {
                    _getSelectionDetails();
                },
                selectionMode: SelectionMode.multiple,
            }),
        []
    );

    return (
        <>
            <h3>Pre Hire Type</h3>
            <p>Please select from the available benefits for Pre Hire Services</p>

            <DetailsList
                items={items}
                columns={columns}
                selectionMode={SelectionMode.none}
                setKey="multiple"
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                onRenderRow={_onRenderRow}
                selection={selection}
                selectionPreservedOnEmptyClick={true}
                ariaLabelForGrid="Pre Hire Type Panel"
            />
        </>
    );
};
