import { IHttpClient, IHttpClientResult } from '@employee-experience/common';
import {
    IExistingCandidateID,
    IInternshipTypeEntity,
    ILumpSumpPolicyData,
    INewAuthForm,
    IOptionalBenefitsEntity,
    ITaxPerJobLevel,
} from '../../Models/INewRelocationRequest';
import {
    CoreBenefitsEntity,
    EmployeeDetails,
    ExceptionsEntity,
    HireType,
    ISendBack,
    jobLevelOptions,
    PersonalDetails,
    PreHireTypeBenefits,
    RelocationPolicyEntity,
    RelocationType,
} from '../../Models/IReviewForm';

export class StartNewReloService {
    private httpClient: IHttpClient;
    private url: string;
    private apiURL: string;

    public constructor(httpClient: IHttpClient) {
        this.httpClient = httpClient;
        this.url = REACT_APP_RELO_API_URL;
        this.apiURL = '';
    }

    public async GetEmployeeDetails(numType: string, IDnumber: string): Promise<INewAuthForm[]> {
        const response: IHttpClientResult<INewAuthForm[]> = await this.httpClient.request({
            url: `${this.url}api/Employees/${numType}/${IDnumber}?numType=${numType}&number=${IDnumber}`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    }

    public async getHireTypeService(): Promise<HireType[]> {
        const response: IHttpClientResult<HireType[]> = await this.httpClient.request({
            url: `${this.url}api/policy/HireTypes`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    }

    public async getJobLevelInformation(): Promise<jobLevelOptions[]> {
        const response: IHttpClientResult<jobLevelOptions[]> = await this.httpClient.request({
            url: `${this.url}api/common/JobLevels`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    }

    public async getrelocationPolicy(HireTypeID: number): Promise<RelocationPolicyEntity[]> {
        const response: IHttpClientResult<RelocationPolicyEntity[]> = await this.httpClient.request({
            url: `${this.url}api/policy/RelocationPolicies/${HireTypeID}`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    } //relocationTypeRequest

    public async getrelocationType(): Promise<RelocationType[]> {
        const response: IHttpClientResult<RelocationType[]> = await this.httpClient.request({
            url: `${this.url}api/policy/RelocationTypes`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    } //coreBenefits

    public async getCoreBenefits(ReloTypeID: number, FiscalYear: number): Promise<CoreBenefitsEntity[]> {
        if (__IS_ACCESSIBILITY_TESTING__ == 'true') {
            this.apiURL = `${this.url}api/policy/RelocationTypes/?${ReloTypeID}/${FiscalYear}/CoreBenefits?FiscalYear=${FiscalYear}&reloTypeId=${ReloTypeID}`;
        } else {
            this.apiURL = `${this.url}api/policy/RelocationTypes/${ReloTypeID}/${FiscalYear}/CoreBenefits?FiscalYear=${FiscalYear}&reloTypeId=${ReloTypeID}`;
        }
        const response: IHttpClientResult<CoreBenefitsEntity[]> = await this.httpClient.request({
            url: `${this.apiURL}`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    }

    public async getExceptions(ReloTypeID: number): Promise<ExceptionsEntity[]> {
        const response: IHttpClientResult<ExceptionsEntity[]> = await this.httpClient.request({
            url: `${this.url}api/policy/Exceptions/${ReloTypeID}`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    }

    public async getAllCountryNames(): Promise<PersonalDetails> {
        const response: IHttpClientResult<PersonalDetails> = await this.httpClient.request({
            method: 'POST',
            url: `${this.url}api/reloform/GetAllCountryNames`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    }

    public async getAllStateNames(StateName: string): Promise<PersonalDetails> {
        const response: IHttpClientResult<PersonalDetails> = await this.httpClient.request({
            method: 'POST',
            url: `${this.url}api/reloform/GetAllStateNames/${StateName}`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    }
    public async getBusinessGroups(): Promise<string[]> {
        const response: IHttpClientResult<string[]> = await this.httpClient.request({
            url: `${this.url}api/policy/BusinessGroups`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    }

    public async GetEmployeeBasicDetails(Alias: string): Promise<EmployeeDetails> {
        const response: IHttpClientResult<EmployeeDetails> = await this.httpClient.request({
            url: `${this.url}api/Employees/${Alias}`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    }

    public async GetInternshipTypes(): Promise<IInternshipTypeEntity[]> {
        const response: IHttpClientResult<IInternshipTypeEntity[]> = await this.httpClient.request({
            url: `${this.url}api/policy/InternshipTypes`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    }

    public async GetLumpSumRelocationTypes(FiscalYear: number): Promise<ILumpSumpPolicyData[]> {
        var datenow = new Date();
        if (FiscalYear === null && FiscalYear === undefined) {
            FiscalYear = datenow.getMonth() > 5 ? datenow.getFullYear() + 1 : datenow.getFullYear();
        }
        const response: IHttpClientResult<ILumpSumpPolicyData[]> = await this.httpClient.request({
            url: `${this.url}api/policy/LumpSumRelocationTypes/${FiscalYear}`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    }

    public async GetTaxRatesByJobLevel(): Promise<ITaxPerJobLevel[]> {
        const response: IHttpClientResult<ITaxPerJobLevel[]> = await this.httpClient.request({
            url: `${this.url}api/policy/JobLevelTaxRates`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    }

    public async GetPreHireTypeBenefits(): Promise<PreHireTypeBenefits[]> {
        const response: IHttpClientResult<PreHireTypeBenefits[]> = await this.httpClient.request({
            url: `${this.url}api/common/getPreHireTypeBenefits`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    }

    public async GetAllBenefits(reloType: string): Promise<IOptionalBenefitsEntity[]> {
        const response: IHttpClientResult<IOptionalBenefitsEntity[]> = await this.httpClient.request({
            url: `${this.url}api/policy/OptionalBenefits/${reloType}`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    }

    public async GetRelocationOptionalBenefits(relocationpolicyTypeID: number): Promise<IOptionalBenefitsEntity[]> {
        const response: IHttpClientResult<IOptionalBenefitsEntity[]> = await this.httpClient.request({
            url: `${this.url}api/policy/RelocationTypes/${relocationpolicyTypeID}/OptionalBenefits`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    }

    public async GetEmployeeDetailsForMergerNAcquisition(): Promise<INewAuthForm> {
        const response: IHttpClientResult<INewAuthForm> = await this.httpClient.request({
            url: `${this.url}api/EmployeesForMergerNAcquisition`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    }

    public async SaveRelocationForm(formData: INewAuthForm): Promise<any> {
        const request: IHttpClientResult<any> = await this.httpClient.request({
            url: `${this.url}api/reloform/RelocationForm`,
            method: 'POST',
            resource: __CLIENT_ID__,
            data: formData,
        });
        return request;
    }

    public async UploadRelocationSummary(file: File): Promise<string> {
        const formData = new FormData();
        formData.append('file', file);

        const request: IHttpClientResult<string> = await this.httpClient.request({
            url: `${this.url}api/reloform/UploadRelocationSummary/`,
            method: 'POST',
            resource: __CLIENT_ID__,
            data: formData,
        });
        return request?.data;
    }

    public async SubmitRelocationForm(formData: INewAuthForm): Promise<any> {
        const request: IHttpClientResult<any> = await this.httpClient.request({
            url: `${this.url}api/reloform/SaveSubmittedEForm`,
            method: 'POST',
            resource: __CLIENT_ID__,
            data: formData,
        });
        return request;
    }

    public async Submit(formData: ISendBack): Promise<any> {
        const request: IHttpClientResult<any> = await this.httpClient.request({
            url: `${this.url}api/reloform/SaveSubmittedEForm`,
            method: 'POST',
            resource: __CLIENT_ID__,
            data: formData,
        });
        return request;
    }

    public async getRelocationFormData(RecordID: number): Promise<INewAuthForm[]> {
        const response: IHttpClientResult<INewAuthForm[]> = await this.httpClient.request({
            url: `${this.url}api/reloform/RelocationForm/${RecordID}`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    }

    public async DeleteReloID(RecordID: number): Promise<any> {
        const response: IHttpClientResult<any> = await this.httpClient.request({
            url: `${this.url}api/reloform/DeleteReloID/${RecordID}`,
            resource: __CLIENT_ID__,
        });
        return response;
    }

    public async GetReloForms(CandidateID: number): Promise<IExistingCandidateID> {
        const response: IHttpClientResult<IExistingCandidateID> = await this.httpClient.request({
            url: `${this.url}api/reloform/GetReloForms/${CandidateID}`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    }

    public async GetReloAttachment(ReloID: number): Promise<any> {
        const response: IHttpClientResult<BigInt64Array[]> = await this.httpClient.request({
            url: `${this.url}api/reloform/DownloadRelocationSummary/${ReloID}`,
            resource: __CLIENT_ID__,
        });
        return response;
    }

    public async GetAdditionalReloAttachment(GUID: string): Promise<any> {
        const response: IHttpClientResult<BigInt64Array[]> = await this.httpClient.request({
            url: `${this.url}api/reloform/DownloadRelocationSummaryNew/${GUID}`,
            resource: __CLIENT_ID__,
        });
        return response;
    }
}
