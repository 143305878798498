import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import { MyReloQueue } from './Pages/MyReloQueue/MyReloQueue';
import { EstimatesQueue } from './Pages/EstimatesQueue/EstimatesQueue';
import { EstimatesForm } from './Pages/EstimatesForm/EstimatesForm';
import { GlomocoeQueue } from './Pages/GlomocoeQueue/GlomocoeQueue';
import ReloCampQueue from './Pages/ReloCampQueue/ReloCampQueue';
import { ReloSpecialistQueue } from './Pages/ReloSpecialistQueue/ReloSpecialistQueue';
import { SentBackQueue } from './Pages/SentBackQueue/SentBackQueue';
import { ExportToWebJEReport } from './Pages/ExportToFinance/ExportToWebJEReport';
import { E2ALiabilityTrend } from './Pages/E2ALiabilityTrend/E2ALiabilityTrend';
import { ReduxContext } from '@employee-experience/common';
import { ILoggedInUserRoles } from './Models/IUserRoles';
import { IAppState } from './Common/Redux/AppState';
import { UnAuthorized } from './Components/QueueTable/Unauthorized';
import { Home } from './Pages/Home/Home';
import { ExceptionQueue } from './Pages/ExceptionQueue/ExceptionQueue';
import { UploadActualsReport } from './Pages/UploadActualsReport/UploadActualsReport';
import { UploadReassignReport } from './Pages/UploadReassignReport/UploadReassignReport';
import { ReassignRecords } from './Pages/ReassignRecords/ReassignRecords';
import { PendingExceptionApprovalQueue } from './Pages/PendingExceptionApprovalQueue/PendingExceptionApprovalQueue';
import { StartNewRelocation } from './Pages/NewRelocation/StartNewRelocation';
import { ReviewForm } from './Pages/NewReviewForm/ReviewForm';
import { ReloForm } from './Pages/ReloForm/ReloForm';
import { SentBackReport } from './Pages/SendBackQueueReport/SentBackReport';
import { SentToFileSetup } from './Pages/SentToFileSetup/SentToFileSetup';

export function Routes(): React.ReactElement {
    const { useSelector } = React.useContext(ReduxContext);
    const userRole: ILoggedInUserRoles = useSelector((state: IAppState) => state.root.userReducer.loggedInUserRoles);
    
    return (
        <Switch>
            <Route path="/" component={Home} exact />
            {(userRole.ReloAdmin || userRole.Staffing || userRole.GloMoCo) && <Route path="/MyReloQueue" component={MyReloQueue} exact />}
            {(userRole.ReloAdmin || userRole.ReloAnalyst) && <Route path="/EstimatesQueue" component={EstimatesQueue} exact />}
            {(userRole.ReloAdmin || userRole.ReloAnalyst || userRole.ReloSpecialist) && <Route path="/EstimatesForm" component={EstimatesForm} exact />}
            {(userRole.ReloAdmin || userRole.GloMoCo) && (
                <Route path="/ReloCampQueue" component={ReloCampQueue} exact />
            )}
            {(userRole.ReloAdmin || userRole.GloMoCo) && (
                <Route path="/SentBackQueue" component={SentBackQueue} exact />
            )}
            {(userRole.ReloAdmin || userRole.GloMoCo) && (
                <Route path="/GlomocoeQueue" component={GlomocoeQueue} exact />
            )}
            {(userRole.ReloAdmin || userRole.Guest || userRole.Staffing) && (
                <Route path="/PendingExceptionsQueue" component={PendingExceptionApprovalQueue} exact />
            )}
            {(userRole.ReloAdmin || userRole.ReloAnalyst || userRole.ReloSpecialist) && <Route path="/ReloSpecialistQueue" component={ReloSpecialistQueue} exact />}
            {(userRole.ReloAdmin || userRole.GloMoCo || userRole.Staffing) && <Route path="/ReassignRecords" component={ReassignRecords} exact />}
            {(userRole.ReloAdmin|| userRole.ReloAnalyst) && <Route path="/UploadActualsReport" component={UploadActualsReport} exact />}
            {(userRole.ReloAdmin|| userRole.GloMoCo) && <Route path="/UploadReassignReport" component={UploadReassignReport} exact />}
            {(userRole.ReloAdmin || userRole.ReloAnalyst) && <Route path="/ExportToWebJEReport" component={ExportToWebJEReport} exact />}
            {(userRole.ReloAdmin|| userRole.GloMoCo || userRole.ReloSpecialist) && <Route path="/SentToFileSetup" component={SentToFileSetup} exact />}
            {(userRole.ReloAdmin || userRole.ReloAnalyst || userRole.ReloSpecialist)&& <Route path="/ExceptionQueue" component={ExceptionQueue} exact />}
            {userRole.ReloAdmin && <Route path="/E2ALiabilityTrend" component={E2ALiabilityTrend} exact />}
            {(userRole.ReloAdmin || userRole.GloMoCo) && 
            <Route path="/SendBackReport" component={SentBackReport} exact />
            }
            {(userRole.ReloAdmin || userRole.Staffing) && (
                <Route path="/StartNewRelocation" component={StartNewRelocation} exact />
            )}
            {(userRole.ReloAdmin || userRole.GloMoCo || userRole.Staffing) && (
                <Route path="/ReviewForm" component={ReviewForm} exact />
            )}
            {(userRole.ReloAdmin || userRole.Staffing || userRole.GloMoCo) && (
                <Route path="/ReloForm" component={ReloForm} exact />
            )}
            <Route path="/NoRole" component={UnAuthorized} exact />
            <Route component={UnAuthorized}></Route>
        </Switch>
    );
}
