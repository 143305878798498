import * as React from 'react';
import {
    Stack,
    PrimaryButton,
    Dropdown,
    IDropdownStyles,
    IDropdownOption,
    Modal,
    mergeStyleSets,
    getTheme,
    FontWeights,
    TextField,
    ChoiceGroup,
    IChoiceGroupOption,
    Checkbox,
} from '@fluentui/react';
import {
    ExceptionModalProps,
    SaveFileExceptionProps,
    UpdateAuthFEProps,
} from '../../Models/IEstimatesForm';
import { LoadingSpinner } from '../../Components/Shared/LoadingSpinner';
import { useServiceStore } from '../../RootStateContext';
import * as moment from 'moment';



const iconProps = { iconName: 'Contact' };
const policyEligibleExceptionBalance = ["8", "9", "10", "11", "7", "14", "15", "16", "17", "5"];
const theme = getTheme();
const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
    },
    header: [
        theme.fonts.xLargePlus,
        {
            flex: '1 1 auto',
            borderTop: `4px solid orange`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: {
                margin: '14px 0'
            },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});
const buttonStyles = {
    backgroundColor: 'rgb(0, 120, 212)',
    color: '#FFFFFF',
    marginBottom: '10px',
    marginRight: '10px'
};
const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: {
        width: '100%'
    }
};
const fieldTitle = {
    fontSize: 14,
    fontWeight: 600,
}

const approvalUrgencyOptions: IChoiceGroupOption[] = [
    { key: 'S', text: 'Standard (48 hours)' },
    { key: 'U', text: 'Urgent (24 hours)' },
    { key: 'C', text: 'Critical (Same day)', }
];
const taxGrossUpOptions: IDropdownOption[] = [
    { key: 'T', text: 'Taxable' },
    { key: 'NT', text: 'Non Taxable' },
];

export const ExceptionModal: React.FC<ExceptionModalProps> = (props) => {
    const [loaded, setLoaded] = React.useState<boolean>(false);

    const { estimatesFormService } = useServiceStore();

    const [approverAlias, setApproverAlias] = React.useState<string>(props.hiringManagerAlias);
    const [approverMPMAlias,setApproverMPMAlias] = React.useState<string>(__MPM_APPROVER_ALIAS__);
    
    const exceptionGeneratedDate = new Date();
    const [isQuantityDisabled, setIsQuantityDisabled] = React.useState<boolean>(false);
    const [selectedUrgency, setSelectedUrgency] = React.useState<string>(approvalUrgencyOptions[0].key);
    const [selectedException, setSelectedException] = React.useState<IDropdownOption>({} as IDropdownOption);
    const [approverAliasErrorMessage,setApproverAliasErrorMessage]=React.useState<string | undefined>(undefined);
    const [approverMPMAliasErrorMessage,setMPMApproverAliasErrorMessage]=React.useState<string | undefined>(undefined);


    // Tax rate info
    const [taxRate, setTaxRate] = React.useState<number | undefined>(undefined);
    const [taxGrossUpKey, setTaxGrossUpKey] = React.useState<string>('U');
    const [taxGrossUpChoice, setTaxGrossUpChoice] = React.useState<IDropdownOption>(taxGrossUpOptions[0]);

    // Various amounts shown
    const [defaultAmount, setDefaultAmount] = React.useState<number>(0);
    const [totalEstimate, setTotalEstimate] = React.useState<number>(0);
    const [maximumAmount, setMaximumAmount] = React.useState<number>(0);
    const [totalAmount, setTotalAmount] = React.useState<number>(0);
    const [totalEstimateWithAmount, setTotalEstimateWithAmount] = React.useState<number>(0);

    // MPM Exception information
    const [showMPMException, setshowMPMException] = React.useState<boolean>(false);
    const [isNPMException, setIsNPMException] = React.useState<boolean>(false);
    const [MPMNotificationException, setMPMNotificationException] = React.useState<boolean>(false);
    const [exceptionBalance, setExceptionBalance] = React.useState<number>(0);
    const [allocatedBalance, setAllocatedBalance] = React.useState<number>(0);
    const [costCenterCharge, setCostCenterCharge] = React.useState<number>(0);

    // User inputs
    const [justification, setJustification] = React.useState<string | undefined>(undefined);
    const [quantity, setQuantity] = React.useState<number | string | undefined>(undefined);
    const [amount, setAmount] = React.useState<number | string | undefined>(undefined);

    // User input errors
    const [justificationError, setJustificationError] = React.useState<string | undefined>(undefined);
    const [quantityError, setQuantityError] = React.useState<string | undefined>(undefined);
    const [amountError, setAmountError] = React.useState<string | undefined>(undefined);

    const [titleTextException,setTitleTextException] = React.useState<string | undefined>("Exception");
    const [categoryTextException,setCategoryTextException] = React.useState<string | undefined>("Exception");
    const [descrptionTextException,setdescrptionTextException] = React.useState<string | undefined>("Exception");

    React.useEffect(() => {
        setTotalEstimateWithAmount(totalEstimate + totalAmount);
    }, [totalEstimate, totalAmount])

    React.useEffect(() => {
        let exceptionTotal = 0;
        if (amount !== undefined && amount !== '') {
            exceptionTotal = Number(amount);
        }
        if (taxGrossUpKey === 'B' && taxGrossUpChoice.key === 'NT') {
            setTotalAmount(props.getTaxGrossUp(exceptionTotal, 0));
        } else {
            setTotalAmount(props.getTaxGrossUp(exceptionTotal, taxRate));
        }
    }, [amount, quantity, taxRate, taxGrossUpKey, taxGrossUpChoice, selectedException])

    React.useEffect(() => {
        setTotalEstimate(props.totalEstimate);
    }, [props.totalEstimate]);

    React.useEffect(() => {
        if(props.isOptionalException){
            setTitleTextException("Optional Benefit");
            setCategoryTextException("");
            setdescrptionTextException("Benefit");
        }else{
            setTitleTextException("Exception");
            setCategoryTextException("Exception");
            setdescrptionTextException("Exception");
        }
    }, [props.isOptionalException]);

    React.useEffect(() => {
        setApproverAlias(props.hiringManagerAlias)
        setExceptionBalance(props.remainingExceptionBalance || 0);
        setApproverMPMAlias(props.mpmAlias);
    }, [props.remainingExceptionBalance, props.hiringManagerAlias,props.mpmAlias]);

    React.useEffect(() => {
        setIsNPMException(showMPMException && exceptionBalance >= 0);
        setMPMNotificationException(showMPMException && exceptionBalance<=0);
    }, [exceptionBalance, showMPMException]);

    React.useEffect(() => {
        if (props.relocationPolictTypeID && !props.isOptionalException) {
            let inList = policyEligibleExceptionBalance.includes(props.relocationPolictTypeID);
            setshowMPMException(inList);
        }

    }, [props.relocationPolictTypeID]);

    React.useEffect(() => {
        setSelectedException(props.selectedException);
    }, [props.selectedException]);

    React.useEffect(() => {
        if (isNPMException) {
            if (amount !== undefined && amount !== '') {
                const exceptionAllocatedAmount = (exceptionBalance > Number(amount)) ? (amount) : exceptionBalance;
                setAllocatedBalance(Number(exceptionAllocatedAmount));
                if (Number(amount) > exceptionBalance) {
                    let costCenterCharge = Number(amount) - Number(exceptionAllocatedAmount);
                    setCostCenterCharge(costCenterCharge);
                } else {
                    setCostCenterCharge(0);
                }
            } else {
                setAllocatedBalance(0);
                setCostCenterCharge(0);
            }
        }
    }, [amount, quantity, isNPMException])

    React.useEffect(() => {
        if (selectedException) {
            const exceptionDetails = props.allExceptionsResponse.filter((item) => item.ExceptionID === selectedException.key);

            let exceptionQuantity: number | string = '';
            let exceptionQuantityDisabled = false;
            let exceptionTaxGrossUpKey = 'U';
            if (exceptionDetails[0]) {
                if (exceptionDetails[0].UnitType.trim() === "N/A" || exceptionDetails[0].UnitType.trim() === "Free Field") {
                    exceptionQuantity = 1;
                    exceptionQuantityDisabled = true;
                }
                if (exceptionDetails[0].TAX !== null && exceptionDetails[0].TAX !== undefined) {
                    exceptionTaxGrossUpKey = exceptionDetails[0].TAX.trim();
                }
            }
            setMaximumAmount(exceptionDetails[0]?.MaximumAmount);
            setIsQuantityDisabled(exceptionQuantityDisabled);
            setTaxGrossUpKey(exceptionTaxGrossUpKey);

            const taxDetails = props.taxAmountsResponse.filter((item) => item.ExceptionId === selectedException.key);
            let exceptionTaxRate: number | undefined = 0;
            let exceptionAmount: number | undefined = 0;
            if (taxDetails[0] !== undefined) {
                if (exceptionTaxGrossUpKey !== "NT") {
                    exceptionTaxRate = taxDetails[0].Tax_Pct;
                }
                if (exceptionDetails[0]?.MaximumAmount < taxDetails[0].Actual_Amount) {
                    exceptionAmount = exceptionDetails[0]?.MaximumAmount;
                } else if (taxDetails[0].Actual_Amount !== 0) {
                    exceptionAmount = taxDetails[0].Actual_Amount;
                }
            } else {
                if (exceptionTaxGrossUpKey !== "NT") {
                    exceptionTaxRate = undefined;
                }
            }

            if (props.isEditException) {
                exceptionQuantity = props.selectedRow?.ExceptionQuantity;
                exceptionAmount = Number(props.selectedRow?.ExceptionAmount);
                if (props.type === "postOfferException") {
                    setJustification(props.selectedRow?.ExceptionJustification);
                    setSelectedUrgency(props.selectedRow?.ApprovalUrgency.charAt(0).toUpperCase());
                }
            }

            setTaxRate(exceptionTaxRate);
            setAmount(exceptionAmount);
            setDefaultAmount(exceptionAmount);
            setQuantity(exceptionQuantity);
            setAmountError(undefined);
            setQuantityError(undefined);
            setJustificationError(undefined);
        }
        setCorrectHiringManager().then(() => {setLoaded(true);});
    }, [selectedException, props.allExceptionsResponse, props.taxAmountsResponse, props.selectedRow, props.isEditException, props.isModalOpen]);

    const setCorrectHiringManager = async () => {
        await estimatesFormService.isCManager(props.hiringManagerAlias).then((IsCManager) => {
            if(IsCManager){
                setApproverAlias(__MPM_APPROVER_ALIAS__);
            }
            else{
                setApproverAlias(props.hiringManagerAlias)
            }
        });
    }
    const validateQuantity = (value?: number | string) => {
        const newQuantity = (props.isValidPositiveNumber(value)) ? Number(value) : 1;
        const previousQuantity = props.isValidPositiveNumber(quantity) ? Number(quantity) : 1;

        let updatedAmount = (maximumAmount < defaultAmount) ? maximumAmount : defaultAmount;
        if (props.isValidPositiveNumber(amount)) {
            updatedAmount = Number(amount);
        }

        let costPerUnit = updatedAmount;
        if (0 < previousQuantity) {
            costPerUnit = updatedAmount / previousQuantity;
        }

        if (0 < newQuantity) {
            let quantityDifference: number = newQuantity - previousQuantity;
            updatedAmount += costPerUnit * quantityDifference;
        }

        if (props.isValidPositiveNumber(value)) {
            setQuantity(newQuantity);
            setQuantityError(undefined);
        } else {
            setQuantity('');
            setQuantityError('Quantity is required.');
        }

        validateAmount(updatedAmount);
    }

    const validateAmount = (value?: number | string) => {
        let newAmount: number | string = '';
        if (props.isValidPositiveNumber(value)) {
            newAmount = Number(value);
        }

        if (props.isValidPositiveNumber(value)) {
            setAmount(newAmount);
            if (maximumAmount < Number(newAmount)) {
                setAmountError('Exception amount cannot be greater than the maximum amount.');
            } else {
                setAmountError(undefined);
            }

        } else {
            setAmount(newAmount);
            setAmountError('Exception amount is required.')
        }
    }

    const validateJustification = (value?: string) => {
        if (props.isNotNullOrUndefined(value)) {
            setJustificationError(undefined);
        } else {
            setJustificationError('Justification is required.')
        }
        setJustification(value);
    }

    const onChangeException = (event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
        setSelectedException(item || {} as IDropdownOption);
    };

    const onChangeTaxGrossUp = (event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
        setTaxGrossUpChoice(item || taxGrossUpOptions[0]);
    };

    const onChangeApprovalUrgency = (ev?: React.SyntheticEvent<HTMLElement>, option?: IChoiceGroupOption) => {
        setSelectedUrgency(option?.key || 'S');
    }

    const onChangeNPMException = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
        setIsNPMException(!!checked);
    }

    const onQuantityChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        validateQuantity(newValue);
    }

    const onJustificationChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        validateJustification(newValue);
    }

    const onAmountChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        validateAmount(newValue);
    }

    //We used on change as on blur can cause issue with the user when submitting a exceptions without out focusing text fields
    const onApproverAliasChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string)=> {
        let promiseValidation = validateEmailAlias(newValue);
        promiseValidation.then((validatedEmail) =>{
            if(validatedEmail == newValue || validatedEmail == ''){
                setApproverAliasErrorMessage(undefined);
                setApproverAlias(validatedEmail);
            }else{
                setApproverAliasErrorMessage(validatedEmail);
            }
        })
    }

    const onMPMApproverAliasChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string)=> {
        let promiseValidation = validateEmailAlias(newValue);
        promiseValidation.then((validatedEmail) =>{
            if(validatedEmail == newValue || validatedEmail == ''){
                setMPMApproverAliasErrorMessage(undefined);
                setApproverMPMAlias(validatedEmail);
            }else{
                setMPMApproverAliasErrorMessage(validatedEmail);
            }
        })
    }

    async function validateEmailAlias(newValue? :string) {
        if (!newValue || newValue.indexOf(' ') >= 0 || newValue.indexOf('@') >= 0) {
            return 'Enter a valid Alias without empty spaces or @ symbol';
        }
        if ((await onGetAliasDetails(newValue)) === "invalid") {
            return 'Enter Valid Alias';
        } else {
            return newValue || '';
        }
    }

   async function onGetAliasDetails(alias: string): Promise<string> {
        const info = await estimatesFormService.getEmployeeBasicDetails(alias);
        if (info === null) {
            return Promise.resolve("invalid");
        }
        return Promise.resolve('');
    }

    const validateAllDetails = () => {
        validateAmount(amount || 0);
        validateQuantity(quantity || 0);
        if (props.type === "postOfferException") {
            validateJustification(justification);
        }
    }

    const addOffer = () => {
        validateAllDetails();
        if (props.isValidPositiveNumber(quantity) && quantityError === undefined && props.isValidPositiveNumber(amount) && amountError === undefined && Number(amount) <= maximumAmount) {
            if (props.type === "offerException") {
                addOfferException();
                props.triggerModal(false);
            } else if (props.type === "postOfferException") {
                if (props.isNotNullOrUndefined(justification) && justificationError === undefined) {
                    addPostOfferException();
                    props.triggerModal(false);
                }
            }
        }
    }

    const updateOffer = async () => {
        validateAllDetails();
        if (props.isValidPositiveNumber(quantity) && quantityError === undefined && props.isValidPositiveNumber(amount) && amountError === undefined && Number(amount) <= maximumAmount) {
            if (props.type === "offerException") {
                updateOfferException();
                props.triggerModal(false);
            } else if (props.type === "postOfferException") {
                if (justification !== undefined && justification !== '' && justificationError === undefined) {
                    updatePostOfferException();
                    props.triggerModal(false);
                }
            }
        }
    }

    const addPostOfferException = async () => {
        let isPostAccrual = (props.commitStatus === "COMMITTED") ? true : false;
        let newOffer: SaveFileExceptionProps = {
            ApprovalUrgency: selectedUrgency.charAt(0).toUpperCase(),
            Approver: approverAlias,
            MpmApprover: approverMPMAlias,
            CostOfBenefitPerPolciy: '',
            CustFileID: props.custFileID.toString(),
            ExceptionAmount: amount === undefined ? 0 : amount,
            ExceptionId: selectedException.key,
            ExceptionJustification: justification === undefined ? '' : justification,
            ExceptionQuantity: quantity === undefined ? 0 : quantity,
            FileExceptionCreatedOn: new Date().toISOString(),
            IsPostAccrual: isPostAccrual,
            IsOptionalException: props.isOptionalException,
            IsNPMException: isNPMException,
            Status: "PS",
            TaxGrossUp: taxGrossUpKey === 'B' ? taxGrossUpChoice.key : taxGrossUpKey,
            TotalAmount: totalAmount,
        };

        await estimatesFormService.saveFileException(newOffer);
        props.updateListData();
    }

    const updatePostOfferException = async () => {
        let updatedOffer: SaveFileExceptionProps = {
            ApprovalUrgency: selectedUrgency.charAt(0).toUpperCase(),
            Approver: approverAlias,
            MpmApprover: approverMPMAlias,
            CustFileID: props.custFileID.toString(),
            CostOfBenefitPerPolciy: '',
            ExceptionAmount: amount === undefined ? 0 : amount,
            ExceptionId: selectedException.key,
            ExceptionJustification: justification === undefined ? '' : justification,
            ExceptionQuantity: quantity === undefined ? 0 : quantity,
            FERecordId: props.selectedRow?.FERecordId,
            FileExceptionCreatedOn: props.selectedRow?.FileExceptionCreatedOn,
            IsPostAccrual: false,
            IsOptionalException: props.isOptionalException,
            IsNPMException: isNPMException,
            Status: "PS",
            TaxGrossUp: taxGrossUpKey === 'B' ? taxGrossUpChoice.key : taxGrossUpKey,
            TotalAmount: totalAmount,
        };
        await estimatesFormService.saveFileException(updatedOffer);
        props.updateListData();
    }

    const addOfferException = () => {
        let newOffer = {
            CustFileID: props.reloId,
            ExceptionAmount: amount,
            ExceptionId: selectedException.key,
            ExceptionQuantity: quantity,
            FileExceptionCreatedOn: new Date().toISOString(),
            IsPostAccrual: false,
            TaxGrossUp: taxGrossUpKey === 'B' ? taxGrossUpChoice.key : taxGrossUpKey,
            TotalAmount: totalAmount,
            isQuantityDisabled: isQuantityDisabled,
            TaxRate: taxRate,
            MaximumAmount: maximumAmount
        };

        if (props.storeNewOfferException !== undefined) {
            props.storeNewOfferException(newOffer);
        }
    }

    const updateOfferException = async () => {
        let updatedOffer: UpdateAuthFEProps = {
            CustFileID: props.custFileID.toString(),
            ExceptionAmount: amount === undefined ? 0 : amount,
            ExceptionID: selectedException.key,
            IsPostAccrual: false,
            Quantity: quantity === undefined ? 0 : quantity,
            ReloId: props.reloId,
            TaxGrossUp: taxGrossUpKey === 'B' ? taxGrossUpChoice.key : taxGrossUpKey,
            TotalAmount: totalAmount
        };
        await estimatesFormService.updateAuthFE(updatedOffer)
        props.updateListData();
    }

    return (
        <>
            <Modal
                titleAriaId='title'
                isOpen={props.isModalOpen}
                containerClassName={contentStyles.container}
            >
                {!loaded
                    ? <LoadingSpinner />
                    : (<>
                        <div className={contentStyles.header}>
                            <span id='title'>{props.isEditException ? 'Edit' : 'Add'} an {titleTextException} for {props.firstAndLastName}</span>
                        </div>

                        <div className={contentStyles.body}>
                            {(props?.isAlreadyPendingException === true) &&
                                <p style={{ ...fieldTitle, color: 'red' }}>
                                    NOTE: This exception has already been added
                                </p>
                            }
                            {props.type === 'postOfferException' &&
                                <>
                                    <Dropdown
                                        label={categoryTextException + " Category"}
                                        selectedKey={selectedException ? selectedException.key : null}
                                        onChange={onChangeException}
                                        placeholder="Select an option"
                                        options={props.exceptionsDropdown}
                                        styles={dropdownStyles}
                                        isDisabled={props.selectedRow?.Status === 'PA' ? true : false} // disable dropdown if pending approval
                                    />

                                    <TextField
                                        label="MPM Approver Alias"
                                        iconProps={iconProps}
                                        defaultValue={approverMPMAlias}
                                        onChange={onMPMApproverAliasChange}
                                        validateOnFocusOut={true}
                                        errorMessage={approverMPMAliasErrorMessage}
                                    />
                                    <TextField
                                        label="Hiring Manager Alias"
                                        iconProps={iconProps}
                                        defaultValue={approverAlias}
                                        onChange={onApproverAliasChange}
                                        validateOnFocusOut={true}
                                        errorMessage={approverAliasErrorMessage}
                                    />
                                </>}

                            <Stack style={{ paddingTop: 10 }}>
                                <p style={fieldTitle}>{categoryTextException} Created On</p>
                                <p>{moment(exceptionGeneratedDate).format('MM/DD/YYYY')}</p>
                            </Stack>

                            {props.type === 'postOfferException' &&
                                <>
                                    <ChoiceGroup
                                        selectedKey={selectedUrgency}
                                        options={approvalUrgencyOptions}
                                        onChange={onChangeApprovalUrgency}
                                        label="Approval Urgency"
                                    />

                                    {(showMPMException && !MPMNotificationException) && (
                                        <Stack style={{ paddingTop: 10 }}>
                                            <p style={fieldTitle}>MPM Exception</p>
                                            <Checkbox
                                                label={`Current Exception Balance: ${props.currencyFormatter(exceptionBalance)}`}
                                                checked={isNPMException}
                                                disabled={exceptionBalance <= 0}
                                                onChange={onChangeNPMException}
                                            />
                                        </Stack>
                                    )}
                                    {MPMNotificationException && (
                                        <Stack style={{ paddingTop: 10 }}>
                                            <p style={fieldTitle}>MPM Notification</p>
                                            <Checkbox
                                                label="MPM Notification"
                                                checked={isNPMException}
                                                onChange={onChangeNPMException}
                                            />
                                        </Stack>
                                    )}

                                    <TextField
                                        label={"Justification for " + descrptionTextException}
                                        multiline
                                        rows={3}
                                        required
                                        value={justification}
                                        maxLength={400}
                                        onChange={onJustificationChange}
                                        errorMessage={justificationError}
                                        description={`${400 - (justification != null ? justification.length : 0)
                                        } Characters Left`}
                                    />

                                    <Stack style={{ paddingTop: 10 }}>
                                        <p style={fieldTitle}>Total Estimate</p>
                                        <p>{props.currencyFormatter(totalEstimate)}</p>
                                    </Stack>
                                </>
                            }

                            {taxGrossUpKey === 'B' &&
                                <Dropdown
                                    label="Tax Gross Up"
                                    selectedKey={taxGrossUpChoice ? taxGrossUpChoice.key : null}
                                    onChange={onChangeTaxGrossUp}
                                    options={taxGrossUpOptions}
                                    styles={dropdownStyles}
                                    isDisabled={props.selectedRow?.Status === 'PA' ? true : false}
                                />
                            }

                            <TextField
                                label="Quantity"
                                required
                                value={quantity?.toString() || ''}
                                onChange={onQuantityChange}
                                errorMessage={quantityError}
                                disabled={isQuantityDisabled}
                            />

                            <TextField
                                label={descrptionTextException + " Amount"}
                                value={amount?.toString() || ''}
                                onChange={onAmountChange}
                                errorMessage={amountError}
                            />

                            <Stack style={{ paddingTop: 10 }}>
                                <p style={fieldTitle}>Maximum {props.currencyFormatter(maximumAmount)}</p>
                            </Stack>

                            <Stack style={{ paddingTop: 10 }}>
                                <p style={fieldTitle}>Total {descrptionTextException} Amount (including TAX gross-up if applicable )</p>
                                <p>{props.currencyFormatter(totalAmount)}</p>
                            </Stack>

                            {props.type === "postOfferException" && showMPMException && !MPMNotificationException &&
                                <>
                                    <Stack style={{ paddingTop: 10 }}>
                                        <p style={fieldTitle}>Amount Allocated to Exception Balance</p>
                                        <p>{props.currencyFormatter(allocatedBalance)}</p>
                                    </Stack>
                                    <Stack style={{ paddingTop: 10 }}>
                                        <p style={fieldTitle}>Charge to Cost Center (without gross up)</p>
                                        <p>{props.currencyFormatter(costCenterCharge)}</p>
                                    </Stack>
                                </>
                            }

                            <Stack style={{ paddingTop: 10 }}>
                                <p style={fieldTitle}>Total Estimate With {descrptionTextException} Amount</p>
                                <p>{props.currencyFormatter(totalEstimateWithAmount)}</p>
                            </Stack>

                            <div style={{ marginTop: 20 }}>
                                {!props.isEditException
                                    ? <PrimaryButton
                                        text="Ok"
                                        onClick={addOffer}
                                        allowDisabledFocus
                                        disabled={false}
                                        style={buttonStyles}
                                    />
                                    : <PrimaryButton
                                        text="Update"
                                        onClick={updateOffer}
                                        allowDisabledFocus
                                        disabled={false}
                                        style={buttonStyles}
                                    />
                                }
                                <PrimaryButton
                                    text="Cancel"
                                    onClick={() => props.triggerModal(false)}
                                    allowDisabledFocus
                                    disabled={false}
                                />
                            </div>
                        </div>
                    </>)}
            </Modal>
        </>)
}