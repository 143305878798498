import {
    CoherencePageSize,
    CoherencePagination,
    ICoherencePageSizeProps,
    ICoherencePaginationProps,
} from '@cseo/controls/lib/pagination';
import { ReduxContext } from '@employee-experience/common';
import { usePageTitle } from '@employee-experience/common/lib/usePageTitle';
import {
    PrimaryButton,
    DetailsListLayoutMode,
    DetailsRow,
    IColumn,
    IComboBoxOption,
    IDetailsRowBaseProps,
    IDetailsRowStyles,
    IStackItemStyles,
    IStackTokens,
    SelectionMode,
    ShimmeredDetailsList,
    Stack,
    Text,
} from '@fluentui/react';
import { DatePicker } from 'office-ui-fabric-react';
import * as React from 'react';
import { IAppState } from '../../Common/Redux/AppState';
import { ISendBackReport } from '../../Models/ISendBackReport';
import { ILoggedInUserRoles, IUserRoles } from '../../Models/IUserRoles';
import { useServiceStore } from '../../RootStateContext';
import { SendBackColumns } from './SendBackReportColumns';
import * as moment from 'moment';
import { LoadingSpinner } from '../../Components/Shared/LoadingSpinner';
import { ReloFormStatusTypes } from '../../types/ReloFormTypes';
import { UnAuthorized } from '../../Components/QueueTable/Unauthorized';
import { ExportToExcel } from '../../Components/Shared/ExportToExcel';
import { SendBackColumnsPage } from './SentBackReportColumnsPage';
import { useHistory } from 'react-router-dom';
import { StackDisclaimer } from '../../Components/Shared/DisclaimerText';

export function SentBackReport(): React.ReactElement {
    const history = useHistory();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [sendBackQueue, setsendBackQueue] = React.useState<ISendBackReport[]>([]);
    const { useSelector } = React.useContext(ReduxContext);
    const userdata: IUserRoles = useSelector((state: IAppState) => state.root.userReducer.userRoles);
    const userRole: ILoggedInUserRoles = useSelector((state: IAppState) => state.root.userReducer.loggedInUserRoles);
    const { relocationQueueService } = useServiceStore();
    const [columns] = React.useState<IColumn[]>(SendBackColumns);
    const [columnsPage] = React.useState<IColumn[]>(SendBackColumnsPage);
    const [currentPageData, setCurrentPageData] = React.useState<ISendBackReport[]>([]);
    const [datesLoaded, setdatesLoaded] = React.useState<Boolean>(false);
    const DEFAULT_PAGE_SIZE = 10;
    var totalRecords: number = 0;
    const selectedPage = React.useRef<number>(1);
    const pageCount = React.useRef<number>(0);
    const currentPageSize = React.useRef<number>(DEFAULT_PAGE_SIZE);
    const [estimatedFromDate, setestimatedFromDate] = React.useState<Date>();
    const [estimatedToDate, setestimatedToDate] = React.useState<Date>();
    let queueItems: ISendBackReport[] = [];
    const [exportQueueItems, setExportQueueItems] = React.useState<any[]>([]);

    if (sendBackQueue && sendBackQueue.length > 0) {
        queueItems = sendBackQueue.map((row) => {
            return {
                ReloID: row.ReloID,
                FileID: row.FileID,
                SendBackDate: moment(row.SendBackDate.split('.')[0]).format('M/D/YYYY'),
                SubmittedDate: moment(row.SubmittedDate.split('.')[0]).format('M/D/YYYY'),
                HireTypeName: row.HireTypeName,
                RelocationPolicyTypeName: row.RelocationPolicyTypeName,
                Comments: row.Comments,
                FullName: row.FullName,
                SubmittedBy: row.SubmittedBy,
                EmployeeID: row.EmployeeID,
            };
        });
    }

    async function getSendBackReport() {
        setLoaded(false);
        if (!estimatedFromDate || !estimatedToDate) {
            return;
        }
        if (userdata.Alias !== '' && userdata.Roles.length > 0) {
            let queuedata = await relocationQueueService
                .getSendBackReport(estimatedFromDate, estimatedToDate)
                .finally(() => setLoaded(true));
            setsendBackQueue(queuedata);
        }
    }

    const fetchData = (pageSize: number, newIndex: number = 0): void => {
        totalRecords = queueItems.length;
        let data: ISendBackReport[] = [];
        for (let i = newIndex; i < Math.min(pageSize + newIndex, queueItems.length); ++i) {
            let row: ISendBackReport = queueItems[i];
            data.push(row);
        }
        pageCount.current = Math.ceil(totalRecords / currentPageSize.current);

        setCurrentPageData(data);
    };

    const onPageChange = (newPageNumber: number): void => {
        if (newPageNumber !== selectedPage.current) {
            selectedPage.current = newPageNumber;
            fetchData(currentPageSize.current, (newPageNumber - 1) * currentPageSize.current);
        }
    };

    const onPageSizeChange = (newPageSize: string | number): void => {
        currentPageSize.current = newPageSize as number;
        selectedPage.current = 1;
        fetchData(currentPageSize.current);
    };
    const paginationProps: ICoherencePaginationProps = {
        pageCount: pageCount.current,
        selectedPage: selectedPage.current,
        previousPageAriaLabel: 'previous page',
        nextPageAriaLabel: 'next page',
        inputFieldAriaLabel: 'page number',
        onPageChange: onPageChange,
    };
    let pageSizeList: IComboBoxOption[] = [];
    for (let index = 10; index <= 50; index += 10) {
        if (queueItems.length < index) {
            pageSizeList.push({ key: queueItems.length, text: 'ALL' });
            break;
        }
        pageSizeList.push({ key: index, text: index.toString() });
    }

    const paginationPageSizeProps: ICoherencePageSizeProps = {
        pageSize: currentPageSize.current,
        pageSizeList: [
            { key: 10, text: '10' },
            { key: 20, text: '20' },
            { key: 50, text: '50' },
            { key: 100, text: '100' },
            { key: 200, text: '200' },
            { key: 300, text: '300' },
            { key: 400, text: '400' },
            { key: 500, text: '500' },
        ] as IComboBoxOption[],
        comboBoxAriaLabel: 'select page size',
        onPageSizeChange: onPageSizeChange,
    };

    React.useEffect(() => {
        let previousYearDate = new Date();
        previousYearDate.setFullYear(previousYearDate.getFullYear() - 1);
        setestimatedFromDate(previousYearDate);
        setestimatedToDate(new Date());
        setdatesLoaded(true);
    }, []);

    React.useEffect(() => {
        getSendBackReport();
    }, [datesLoaded]);

    const verticalGapStackTokens: IStackTokens = {
        childrenGap: 10,
        padding: 10,
    };

    React.useEffect(() => {
        fetchData(currentPageSize.current);
        if (sendBackQueue && sendBackQueue.length > 0) {
             let tempExportQueueItems = sendBackQueue.map((item) => {
                return {
                    ...item,
                    SendBackDate: item.SendBackDate ? new Date(item.SendBackDate) : "",
                    SubmittedDate: item.SubmittedDate ? new Date(item.SubmittedDate) : "",
                }
            })
            setExportQueueItems(tempExportQueueItems);
        } else {
            setExportQueueItems([])
        }

    }, [sendBackQueue]);

    const stackItemStyles: IStackItemStyles = {
        root: {
            padding: 5,
        },
    };
    usePageTitle(`Send Back Report - ${__APP_NAME__}`);

    return (
        <>
            {(userRole.GloMoCo || userRole.ReloAdmin) ? (
                <Stack tokens={verticalGapStackTokens}>
                    <Stack.Item align="auto" styles={stackItemStyles}>
                        <h1>Send Back Report</h1>
                    </Stack.Item>
                    <Stack.Item>
                        <Stack horizontal horizontalAlign="space-between">
                            <Stack.Item align="auto" styles={{ root: { marginTop: '20px' } }}>
                                <ExportToExcel
                                    rowsData={exportQueueItems}
                                    colsData={columns}
                                    fileName="SentBackQueueReport.xlsx"
                                />
                            </Stack.Item>
                            <Stack
                                tokens={verticalGapStackTokens}
                                styles={{ root: { marginRight: '25px', width: '200px' } }}
                            >
                                <Stack.Item align="auto">
                                    <Text variant="medium">FETCH DATES</Text>
                                </Stack.Item>
                                <Stack.Item>
                                    <Text variant="small"><b>From Date</b></Text>
                                </Stack.Item>
                                <Stack.Item align="auto">
                                    <DatePicker
                                        placeholder="Select a from date..."
                                        ariaLabel="Select a from date"
                                        value={estimatedFromDate}
                                        onSelectDate={(newDate) => setestimatedFromDate(newDate || undefined)}
                                    />
                                </Stack.Item>
                                <Stack.Item>
                                    <Text variant="small"><b>To Date</b></Text>
                                </Stack.Item>
                                <Stack.Item align="auto">
                                    <DatePicker
                                        placeholder="Select a to date..."
                                        ariaLabel="Select a to date"
                                        value={estimatedToDate}
                                        onSelectDate={(newDate) => setestimatedToDate(newDate || undefined)}
                                    />
                                </Stack.Item>
                                <Stack.Item align="auto">
                                    <PrimaryButton text="Fetch Data" onClick={getSendBackReport}></PrimaryButton>
                                </Stack.Item>
                            </Stack>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item styles={stackItemStyles}></Stack.Item>
                    {!loaded ? (
                        <LoadingSpinner />
                    ) : (
                        <>
                            <Stack.Item align="auto" styles={stackItemStyles}>
                                <ShimmeredDetailsList
                                    selectionMode={SelectionMode.none}
                                    items={currentPageData || []}
                                    columns={columnsPage}
                                    onRenderItemColumn={_renderItemColumn}
                                    onRenderRow={_onRenderRow}
                                    onRenderDetailsHeader={_onRenderDetailsHeader}
                                    setKey="set"
                                    layoutMode={DetailsListLayoutMode.justified}
                                    selectionPreservedOnEmptyClick={true}
                                    ariaLabelForSelectionColumn="Toggle selection"
                                    ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                                    checkButtonAriaLabel="select row"
                                />
                            </Stack.Item>
                            <Stack
                                className="pagination-controls"
                                horizontal
                                horizontalAlign="end"
                                styles={{ root: { marginTop: '10px' } }}
                            >
                                <Stack horizontal horizontalAlign="space-between" className="ml-sm-0 pg-c-sm">
                                    <div>
                                        <CoherencePagination {...paginationProps} />
                                    </div>
                                    <CoherencePageSize {...paginationPageSizeProps} />
                                </Stack>
                            </Stack>
                        </>
                    )}
                    {StackDisclaimer()}
                </Stack>
            ) : (
                <UnAuthorized />
            )}
        </>
    );

    function _onRenderDetailsHeader(headerProps: any, defaultRender: any) {
        return defaultRender({
            ...headerProps,
            styles: {
                root: {
                    selectors: {
                        '.ms-DetailsHeader-cell': {
                            whiteSpace: 'normal',
                            lineHeight: 'normal',
                            wordWrap: 'break-word',
                            height: '100%',
                        },
                        '.ms-DetailsHeader-cellTitle': {
                            height: '100%',
                            alignItems: 'center',
                            wordWrap: 'break-word',
                        },
                    },
                    height: '60px',
                },
            },
        });
    }

    function _onRenderRow(props: IDetailsRowBaseProps | undefined, defaultRender: any | undefined) {
        const customStyles: Partial<IDetailsRowStyles> = {};
        let divProps;
        if (props) {
            if (props.item?.Status !== ReloFormStatusTypes.SUBMITTED) {
                customStyles.root = {
                    color: 'blue',
                    selectors: {
                        '.ms-DetailsRow-cell': {
                            whiteSpace: 'normal',
                            lineHeight: 'normal',
                        },
                        '.ms-DetailsRow-cellTitle': {
                            height: '100%',
                            alignItems: 'center',
                            wordWrap: 'break-word',
                        },
                    },
                };
                divProps = {
                    onClick: () => {
                        let parentStatus = props.item?.Status;
                        if (props.item?.Status.toUpperCase() == 'RETURN FOR EDITS') {
                            parentStatus = 'SENDBACK';
                        }
                        let obj = JSON.stringify({
                            recordID: props.item?.RecordID,
                            recordStatus: parentStatus,
                            ActionType: 'SENDBACK',
                        });
                        history.push('/ReviewForm', obj);
                    },
                };
            }

            return (
                <DetailsRow {...divProps} {...props} styles={customStyles}>
                    {defaultRender && defaultRender(props)}{' '}
                </DetailsRow>
            );
        }
        return null;
    }

    function _renderItemColumn(
        item: ISendBackReport | undefined,
        index: number | undefined,
        column: IColumn | undefined
    ) {
        const fieldContent = item?.[column?.fieldName as keyof ISendBackReport] as string;
        switch (column?.key) {
            default:
                return <span>{fieldContent}</span>;
        }
    }
}
