import {
    CoherencePageSize,
    CoherencePagination,
    ICoherencePageSizeProps,
    ICoherencePaginationProps,
} from '@cseo/controls/lib/pagination';
import { ReduxContext } from '@employee-experience/common';
import { usePageTitle } from '@employee-experience/common/lib/usePageTitle';
import {
    PrimaryButton,
    DetailsListLayoutMode,
    DetailsRow,
    IColumn,
    IComboBoxOption,
    IDetailsRowBaseProps,
    IDetailsRowStyles,
    IStackItemStyles,
    IStackTokens,
    SelectionMode,
    ShimmeredDetailsList,
    Stack,
    FontIcon,
    mergeStyles,
    mergeStyleSets,
    Text,
} from '@fluentui/react';
import * as React from 'react';
import * as moment from 'moment';
import { ActionType } from '../../Common/Redux/actionTypes';
import { IAppState } from '../../Common/Redux/AppState';
import { FilterPane } from '../../Components/QueueTable/ReloQueueFilterPane';
import { IAdminExcel, IMyReloQueue, IRelocationFormQueues } from '../../Models/IRelocationFormQueues';
import { ILoggedInUserRoles, IUserRoles } from '../../Models/IUserRoles';
import { useServiceStore } from '../../RootStateContext';
import { ReloColumns } from './ReloColumns';
import { LoadingSpinner } from '../../Components/Shared/LoadingSpinner';
import { ReloFormStatusTypes } from '../../types/ReloFormTypes';
import { UnAuthorized } from '../../Components/QueueTable/Unauthorized';
import { ExportToExcel } from '../../Components/Shared/ExportToExcel';
import { ReloColumnsPage } from './ReloColumnsPage';
import { relotypeCheckBoxOptions } from '../../Components/QueueTable/QueueTable.types';
import { useHistory } from 'react-router-dom';
import { StackDisclaimer } from '../../Components/Shared/DisclaimerText';

interface IMyReloQueueProps { }

export const MyReloQueue: React.FC<IMyReloQueueProps> = (props) => {
    const history = useHistory();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [reloqueue, setreloqueue] = React.useState<IRelocationFormQueues[]>([]);
    const { useSelector, dispatch } = React.useContext(ReduxContext);
    const [isPanelOpen, setIsPanelOpen] = React.useState<boolean>(false);
    const userdata: IUserRoles = useSelector((state: IAppState) => state.root.userReducer.userRoles);
    const userRole: ILoggedInUserRoles = useSelector((state: IAppState) => state.root.userReducer.loggedInUserRoles);
    const data = useSelector((state: IAppState) => state.root.myReloReducer.reloQueues);
    const { relocationQueueService } = useServiceStore();
    const [IsResetHidded, setIsResetHidded] = React.useState<boolean>(true);
    const [columnsPage] = React.useState<IColumn[]>(ReloColumnsPage);
    const [currentPageData, setCurrentPageData] = React.useState<IMyReloQueue[]>([]);
    const [adminExcelData, setAdminExcelData] = React.useState<IAdminExcel[]>([] as IAdminExcel[]);

    const [relocationPolicyCheckBoxOption, setRelocationPolicyCheckBoxOption] = React.useState<
        relotypeCheckBoxOptions[]
    >([]);

    var alias = '';
    var role = '';
    const DEFAULT_PAGE_SIZE = 10;
    var totalRecords: number = 0;
    const selectedPage = React.useRef<number>(1);
    const pageCount = React.useRef<number>(0);
    const currentPageSize = React.useRef<number>(DEFAULT_PAGE_SIZE);
    const reloFilterRef = React.useRef<any>();
    let queueItems: IMyReloQueue[] = [];
    const [exportQueueItems, setExportQueueItems] = React.useState<IMyReloQueue[]>([]);

    let sendBackCount = 0;
    //let sendBackPercentage = 0;
    const [currentMonthList, setCurrentMonthList] = React.useState<string[]>([]);

    function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
        const key = columnKey as keyof T;
        return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    }

    const calculateSendBackData = async () => {
        let currentMonthListResponse = await relocationQueueService.GetCurrentMonthSubmittedFormsList();
        setCurrentMonthList(currentMonthListResponse);
        return currentMonthListResponse;
    };

    if (reloqueue && reloqueue.length > 0) {

        queueItems = reloqueue.map((row) => {
            currentMonthList.map((value) => {
                if (parseInt(value) == row.RecordID) {
                    if (row.EverSendBack === true) {
                        sendBackCount++;
                    }
                }
            });

            return {
                RecordID: row.RecordID,
                FullName: row.FullName,
                RelocationSpecialist: row.RelocationSpecialist,
                LatestSubmissionDate: row.LastUpdated,
                Destination: row.Destination,
                Departure: row.Departure,
                EstimatedStartDate: row.EstimatedStartDate,
                RelocationType: row.RelocationType,
                CandidateID: row.CandidateID,
                Status: row.Status,
            };
        });
    }

    const fetchData = (pageSize: number, newIndex: number = 0): void => {
        totalRecords = queueItems.length;
        let rowDatata: IMyReloQueue[] = [];
        for (let i = newIndex; i < Math.min(pageSize + newIndex, queueItems.length); ++i) {
            let row: IMyReloQueue = queueItems[i];
            rowDatata.push(row);
        }
        pageCount.current = Math.ceil(totalRecords / currentPageSize.current);

        setCurrentPageData(rowDatata);
    };

    const onPageChange = (newPageNumber: number): void => {
        if (newPageNumber !== selectedPage.current) {
            selectedPage.current = newPageNumber;
            fetchData(currentPageSize.current, (newPageNumber - 1) * currentPageSize.current);
        }
    };

    const onPageSizeChange = (newPageSize: string | number): void => {
        currentPageSize.current = newPageSize as number;
        selectedPage.current = 1;
        fetchData(currentPageSize.current);
    };
    const paginationProps: ICoherencePaginationProps = {
        pageCount: pageCount.current,
        selectedPage: selectedPage.current,
        previousPageAriaLabel: 'previous page',
        nextPageAriaLabel: 'next page',
        inputFieldAriaLabel: 'page number',
        onPageChange: onPageChange,
    };
    let pageSizeList: IComboBoxOption[] = [];
    for (let index = 10; index <= 50; index += 10) {
        if (queueItems.length < index) {
            pageSizeList.push({ key: queueItems.length, text: 'ALL' });
            break;
        }
        pageSizeList.push({ key: index, text: index.toString() });
    }

    const paginationPageSizeProps: ICoherencePageSizeProps = {
        pageSize: currentPageSize.current,
        pageSizeList: [
            { key: 10, text: '10' },
            { key: 20, text: '20' },
            { key: 50, text: '50' },
            { key: 100, text: '100' },
            { key: 200, text: '200' },
            { key: 300, text: '300' },
            { key: 400, text: '400' },
            { key: 500, text: '500' },
        ] as IComboBoxOption[],
        comboBoxAriaLabel: 'select page size',
        onPageSizeChange: onPageSizeChange,
    };

    const isNotNullOrUndefined = (value: any) => {
        if (value === null || value === undefined) return true;
        if (typeof value === 'string' && value === '') return true;
        return false;
    };

    React.useEffect(() => {
        if (reloqueue.length > 0 && adminExcelData.length > 0) {
            let reloIDs = reloqueue.map((item) => item.RecordID);
            let currentAdminExcelData = adminExcelData.filter((item) => reloIDs.includes(item.RecordID, 0));
            let tempExportQueueItems = currentAdminExcelData.map((item) => {
                return {
                    ...item,
                    OriginalSubmissionDate: item.OriginalSubmissionDate ? new Date(item.OriginalSubmissionDate.split(".")[0]) : "",
                    SubmittedDate: item.SubmittedDate ? new Date(item.SubmittedDate) : null,
                    EstimatedStartDate: item.EstimatedStartDate ? new Date(item.EstimatedStartDate) : "",
                };
            });
            setExportQueueItems(tempExportQueueItems);
        } else if (reloqueue.length > 0 && adminExcelData.length === 0) {
            let tempExportQueueItems = reloqueue.map((item) => {
                return {
                    ...item,
                    OriginalSubmissionDate: item.OriginalSubmissionDate ? new Date(item.OriginalSubmissionDate.split(".")[0]) : "",
                    SubmittedDate: item.SubmittedDate ? new Date(item.SubmittedDate) : null,
                    EstimatedStartDate: item.EstimatedStartDate ? new Date(item.EstimatedStartDate) : "",
                };
            });
            setExportQueueItems(tempExportQueueItems);
        } else {
            setExportQueueItems([]);
        }
    }, [reloqueue, adminExcelData]);

    React.useEffect(() => {
        (async function getreloqueue() {
            if (userdata.Alias !== '' && userdata.Roles.length > 0) {
                alias = userdata.Alias;
                role = userdata?.Roles[0];
                switch (role) {
                    case 'ReloAdmin':
                        role = 'reloadmin';
                        break;
                    default:
                        role = role;
                }
                if(userdata.Roles.length>0 && (userRole.GloMoCo && userRole.ReloSpecialist)){
                    role='glomoco';
                }
                const reportType = 'staffing';
                relocationQueueService.GetReloAdminQueueForExportToExcel(role, userdata.Alias).then((result) => {
                    if (result.status == 200) {
                        result.data = result.data.map((item: IAdminExcel) => {
                            return {
                                ...item,
                                OriginalSubmissionDate: item.OriginalSubmissionDate === "Not Submitted yet"
                                    ? null
                                    : item.OriginalSubmissionDate,                                
                            };
                        });
                        setAdminExcelData(result.data)
                    }
                });
                var relocationTypes: relotypeCheckBoxOptions[] = [];
                var queuedata: any[] = await relocationQueueService.getQueueRecords(role, reportType, alias);
                queuedata.sort((a, b) => (a.LastUpdated > b.LastUpdated ? 1 : -1));
                queuedata.sort((a, b) => (a.Status !== 'Saved' && b.Status === 'Saved' ? 1 : -1));
                queuedata = queuedata.map((item: any) => {
                    return {
                        ...item,
                        OriginalSubmissionDate: item.OriginalSubmissionDate === "Not Submitted yet"
                            ? null
                            : item.OriginalSubmissionDate,                       
                    };
                });
                queuedata
                    .map((item: any) => item.RelocationPolicyName)
                    .filter((value: number, index: number, self: any) => self.indexOf(value) === index)
                    .forEach((item: any) => {
                        if (!isNotNullOrUndefined(item)) {
                            let relocationType = {
                                optionName: item,
                                checked: false,
                            };
                            relocationTypes.push(relocationType);
                        }
                    });
                setRelocationPolicyCheckBoxOption(relocationTypes);                
                dispatch({
                    type: ActionType.SET_MYRELOQUEUE,
                    data: queuedata,
                });
                await calculateSendBackData();
                setreloqueue(queuedata);
                setLoaded(true);

            }
        })();
    }, []);
    const verticalGapStackTokens: IStackTokens = {
        childrenGap: 10,
        padding: 10,
    };

    const _closePanel = () => {
        setIsPanelOpen(false);
    };

    React.useEffect(() => {
        fetchData(currentPageSize.current);
    }, [reloqueue]);

    const stackItemStyles: IStackItemStyles = {
        root: {
            padding: 5,
        },
    };

    usePageTitle(`Relocation Queue - ${__APP_NAME__}`);

    React.useEffect(() => {
        let inp = document.querySelectorAll('input');
        let btn = document.querySelectorAll('button');
        inp.forEach((item) => {
            item.setAttribute('tabindex', '0');
            if (item.getAttribute('aria-label') === 'page number') {
                item.setAttribute('role', 'textbox');
            }
            if (item.getAttribute('type') === 'checkbox') {
                item.setAttribute('role', 'checkbox');
            }
        });
        btn.forEach((item) => {
            if (item.getAttribute('aria-label') === 'previous page') {
                item.setAttribute('role', 'button');
                item?.parentElement?.parentElement?.classList.add('wrap-btn');
                item?.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.classList.add('wrap-btn');
            }
            if (item.getAttribute('aria-label') === 'next page') {
                item.setAttribute('role', 'button');
            }
            if (item.getAttribute('aria-hidden') == 'true') {
                item.setAttribute('disabled', 'true');
            }
        })
        document.querySelectorAll("div[role='alert']").forEach(item => {
            item.setAttribute('title', 'error');
        })
    })

    return (
        <>
            {!loaded ? (
                <LoadingSpinner />
            ) : (
                <>
                    {(userRole.Staffing || userRole.ReloAdmin || userRole.GloMoCo) ? (
                        <Stack tokens={verticalGapStackTokens}>
                            <Stack.Item align="auto" styles={stackItemStyles}>
                                <h1>
                                    Relocation Queue for {userdata.FirstName} {userdata.LastName}
                                </h1>
                            </Stack.Item>
                            <Stack horizontal styles={stackItemStyles}>
                                <Stack.Item align="auto" styles={stackItemStyles}>
                                    <PrimaryButton
                                        text="Open Filter"
                                        onClick={() => {
                                            setIsPanelOpen(true);
                                        }}
                                        ariaLabel="Filter Relo Queue"
                                        role="button"
                                        tabIndex={0}
                                    ></PrimaryButton>
                                    <FilterPane
                                        isOpen={isPanelOpen}
                                        _closePanel={_closePanel}
                                        ref={reloFilterRef}
                                        apiData={[data]}
                                        handleFilterSubmit={handleFilterSubmit}
                                        ShowResetButton={ShowResetButton}
                                        HideResetButton={HideResetButton}
                                        loggerdInUser={userdata.Alias}
                                        relotypeOptions={relocationPolicyCheckBoxOption}
                                        filterType={userRole.Staffing ? 'staffing' : 'admin'}
                                    />
                                </Stack.Item>
                                <Stack.Item align="auto" styles={stackItemStyles} hidden={IsResetHidded}>
                                    <PrimaryButton
                                        text="Reset Filter"
                                        onClick={() => {
                                            reloFilterRef.current.ResetFilterCallFromMyRelo();
                                            setIsResetHidded(true);
                                        }}
                                        ariaLabel="Filter Relo Queue"
                                        role="button"
                                        tabIndex={0}
                                    ></PrimaryButton>
                                </Stack.Item>
                                <Stack.Item align="auto" styles={stackItemStyles}>
                                    <ExportToExcel
                                        rowsData={exportQueueItems}
                                        colsData={ReloColumns}
                                        fileName="MyReloQueue.xlsx"
                                    />
                                </Stack.Item>
                            </Stack>

                            {userRole.ReloAdmin && (
                                <ul style={{ textAlign: 'right', marginRight: 15, verticalAlign: 'middle' }}>
                                    <li style={{ display: 'inline', alignContent: 'center', verticalAlign: 'middle' }}>
                                        <FontIcon iconName="MailForward" />
                                    </li>
                                    <li
                                        style={{
                                            display: 'inline',
                                            alignContent: 'center',
                                            marginInline: 5,
                                            fontSize: 14,
                                        }}
                                    >
                                        {sendBackCount}
                                    </li>
                                </ul>
                            )}

                            <Stack.Item align="auto" styles={stackItemStyles}></Stack.Item>
                            <>
                                <Stack.Item align="auto" styles={stackItemStyles}>
                                    <ShimmeredDetailsList
                                        selectionMode={SelectionMode.none}
                                        items={currentPageData || []}
                                        columns={columnsPage}
                                        onRenderItemColumn={_renderItemColumn}
                                        onColumnHeaderClick={_onColumnClick}
                                        onItemInvoked={_onItemInvoked}
                                        onRenderRow={_onRenderRow}
                                        onRenderDetailsHeader={_onRenderDetailsHeader}
                                        setKey="set"
                                        layoutMode={DetailsListLayoutMode.justified}
                                        selectionPreservedOnEmptyClick={true}
                                        ariaLabelForSelectionColumn="Toggle selection"
                                        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                                        checkButtonAriaLabel="select row"
                                        ariaLabelForGrid="My Relo Queue"
                                    />
                                    {currentPageData.length === 0 && (
                                        <Stack horizontalAlign="center">
                                            <Text>No Records to Display </Text>
                                        </Stack>
                                    )}
                                </Stack.Item>
                                <Stack
                                    className="pagination-controls"
                                    horizontal
                                    horizontalAlign="end"
                                    styles={{ root: { marginTop: '10px' } }}
                                >
                                    <Stack
                                        horizontal
                                        horizontalAlign="space-between"
                                        styles={{ root: { width: '50%' } }}
                                    >
                                        <div style={{ marginLeft: -132 }}>
                                            <CoherencePagination {...paginationProps} />
                                        </div>
                                        <CoherencePageSize {...paginationPageSizeProps} />
                                    </Stack>
                                </Stack>
                            </>
                            {StackDisclaimer()}
                        </Stack>
                    ) : (
                        <UnAuthorized />
                    )}
                </>
            )}
        </>
    );

    function _onItemInvoked(item: IMyReloQueue | undefined, index: number | undefined) {
        if (
            item?.Status === ReloFormStatusTypes.RESUBMITTED.toUpperCase() ||
            item?.Status === ReloFormStatusTypes.SUBMITTED.toUpperCase()||
            item?.Status === ReloFormStatusTypes.BENIVOSUBMITTED.toUpperCase()||
            item?.Status === ReloFormStatusTypes.BENIVOSUBMISSION.toUpperCase()
        ) {
        }
        if (item?.Status == ReloFormStatusTypes.SENDBACK) {
        }
    }

    function _onRenderDetailsHeader(headerProps: any, defaultRender: any) {
        return defaultRender({
            ...headerProps,
            styles: {
                root: {
                    selectors: {
                        '.ms-DetailsHeader-cell': {
                            whiteSpace: 'normal',
                            lineHeight: 'normal',
                            wordWrap: 'break-word',
                            height: '100%',
                        },
                        '.ms-DetailsHeader-cellTitle': {
                            height: '100%',
                            alignItems: 'center',
                            wordWrap: 'break-word',
                        },
                    },
                    height: '60px',
                },
            },
        });
    }

    function _onRenderRow(props: IDetailsRowBaseProps | undefined, defaultRender: any | undefined) {
        const customStyles: Partial<IDetailsRowStyles> = {};
        let divProps;
        if (props) {
            if (
                props.item?.Status.toUpperCase() == ReloFormStatusTypes.SAVED.toUpperCase() ||
                props.item?.Status.toUpperCase() == ReloFormStatusTypes.SENDBACK.toUpperCase()
            ) {
                customStyles.root = {
                    color: 'blue',
                    fontFamily: 'Segoe UI',
                    fontSize: '12px',
                    selectors: {
                        '.ms-DetailsRow-cell': {
                            whiteSpace: 'normal',
                            lineHeight: 'normal',
                            alignItems: 'center',
                        },
                        '.ms-DetailsRow-cellTitle': {
                            height: '100%',
                            alignItems: 'center',
                            wordWrap: 'break-word',
                        },
                    },
                };
                divProps = {
                    onClick: () => {
                        let parentStatus = props.item?.Status;
                        let obj = JSON.stringify({
                            recordID: props.item?.RecordID,
                            recordStatus: parentStatus,
                            ActionType: 'STAFFING',
                            redirectedFrom: 'myreloqueue',
                        });
                        if (userRole.GloMoCo) {
                            history.push('/ReviewForm', obj);
                        }
                        else {
                            history.push('/ReloForm', obj)
                        };
                    },
                    onKeyDown: (e: any) => {
                        if (e.key === 'Enter') {
                            let parentStatus = props.item?.Status;
                            let obj = JSON.stringify({
                                recordID: props.item?.RecordID,
                                recordStatus: parentStatus,
                                ActionType: 'STAFFING',
                            });
                            history.push('/ReloForm', obj);
                        }
                    }
                };
            } else if (
                props.item?.Status.toUpperCase() == ReloFormStatusTypes.SUBMITTED.toUpperCase() ||
                props.item?.Status.toUpperCase() == ReloFormStatusTypes.RESUBMITTED.toUpperCase()||
                props.item?.Status.toUpperCase() == ReloFormStatusTypes.BENIVOSUBMITTED.toUpperCase()||
                props.item?.Status.toUpperCase() == ReloFormStatusTypes.BENIVOSUBMISSION.toUpperCase()
            ) {
                customStyles.root = {
                    color: 'blue',
                    fontFamily: 'Segoe UI',
                    fontSize: '12px',
                    selectors: {
                        '.ms-DetailsRow-cell': {
                            whiteSpace: 'normal',
                            lineHeight: 'normal',
                            alignItems: 'center',
                        },
                        '.ms-DetailsRow-cellTitle': {
                            height: '100%',
                            alignItems: 'center',
                            wordWrap: 'break-word',
                        },
                    },
                };
                divProps = {
                    onClick: () => {
                        let parentStatus = props.item?.Status;
                        if (props.item?.Status.toUpperCase() == 'RETURN FOR EDITS') {
                            parentStatus = 'SENDBACK';
                        }
                        let obj = JSON.stringify({
                            recordID: props.item?.RecordID,
                            recordStatus: parentStatus,
                            ActionType: 'STAFFING',
                            redirectedFrom: 'myreloqueue'
                        });
                        history.push('/ReviewForm', obj);
                    },
                    onKeyDown: (e: any) => {
                        if (e.key === 'Enter') {
                            let parentStatus = props.item?.Status;
                            let obj = JSON.stringify({
                                recordID: props.item?.RecordID,
                                recordStatus: parentStatus,
                                ActionType: 'STAFFING',
                            });
                            history.push('/ReloForm', obj);
                        }
                    }
                };
            } else {
                customStyles.root = {
                    fontFamily: 'Segoe UI',
                    fontSize: '12px',
                    selectors: {
                        '.ms-DetailsRow-cell': {
                            whiteSpace: 'normal',
                            lineHeight: 'normal',
                            alignItems: 'center',
                        },
                        '.ms-DetailsRow-cellTitle': {
                            height: '100%',
                            alignItems: 'center',
                            wordWrap: 'break-word',
                        },
                    },
                };
            }
            return (
                <DetailsRow {...divProps} {...props} styles={customStyles}>
                    {defaultRender && defaultRender(props)}{' '}
                </DetailsRow>
            );
        }
        return null;
    }

    function _renderItemColumn(item: IMyReloQueue | undefined, index: number | undefined, column: IColumn | undefined) {
        const fieldContent = item?.[column?.fieldName as keyof IMyReloQueue] as string;

        const statusClass = mergeStyles({ fontSize: '12px', fontFamily: 'Segoe UI' });

        const iconClass = mergeStyles({
            fontSize: 16,
            height: 16,
            width: 16,
            paddingLeft: 10,
        });

        const classNames = mergeStyleSets({
            submitted: [{ color: '#7BA811' }, iconClass],
            error: [{ color: '#DF0008' }, iconClass],
            warning: [{ color: '#EC9F1D' }, iconClass],
        });

        switch (column?.key) {
            case 'Status':
                if (
                    item?.Status === ReloFormStatusTypes.SUBMITTED ||
                    item?.Status === ReloFormStatusTypes.RESUBMITTED||
                    item?.Status === ReloFormStatusTypes.BENIVOSUBMITTED||
                    item?.Status === ReloFormStatusTypes.BENIVOSUBMISSION
                ) {
                    return (
                        <Stack horizontal horizontalAlign="start">
                            <span className={statusClass}>{item?.Status}</span>
                            <FontIcon
                                aria-label={item?.Status}
                                iconName="SkypeCircleCheck"
                                className={classNames.submitted}
                            />
                        </Stack>
                    );
                }
                if (item?.Status === ReloFormStatusTypes.SENDBACK) {
                    return (
                        <Stack horizontal horizontalAlign="start">
                            <span className={statusClass}>{item?.Status}</span>
                            <FontIcon
                                aria-label={item?.Status}
                                iconName="StatusErrorFull"
                                className={classNames.error}
                            />
                        </Stack>
                    );
                }
                if (item?.Status === ReloFormStatusTypes.AUTHORIZED || item?.Status === ReloFormStatusTypes.SAVED) {
                    return (
                        <Stack horizontal horizontalAlign="start">
                            <span className={statusClass}>{item?.Status}</span>
                            <FontIcon
                                aria-label={item?.Status}
                                iconName="WarningSolid"
                                className={classNames.warning}
                            />
                        </Stack>
                    );
                }

            case 'EstimatedStartDate':
                if (item?.EstimatedStartDate === null) {
                    return <span>{fieldContent}</span>;
                } else {
                    return moment(item?.EstimatedStartDate).format('MM/DD/YYYY');
                }

            case 'LatestSubmissionDate':
                if (item?.LatestSubmissionDate === null) {
                    return <span>{fieldContent}</span>;
                } else {
                    return moment(item?.LatestSubmissionDate).format('MM/DD/YYYY');
                }
            case 'RecordID':
                const RfEClass = mergeStyles({
                    fontSize: 23,
                    paddingRight: 20,
                    marginLeft: 7,
                    color: '#323130',
                });

                if (item?.Status === ReloFormStatusTypes.SENDBACK) {
                    return (
                        <div style={{ display: 'grid' }}>
                            <span>{fieldContent}</span>
                            <FontIcon iconName="MailForward" className={RfEClass} />
                        </div>
                    );
                } else return <span>{fieldContent}</span>;

            default:
                return <span>{fieldContent}</span>;
        }
    }

    function handleFilterSubmit(filteredData: any[]) {
        setreloqueue(filteredData);
    }
    function ShowResetButton() {
        setIsResetHidded(false);
    }
    function HideResetButton() {
        setIsResetHidded(true);
    }

    function _onColumnClick(event: React.MouseEvent<HTMLElement> | undefined, column: IColumn | undefined): void {
        let isSortedDescending = column?.isSortedDescending;
        if (column?.isSorted) {
            isSortedDescending = !isSortedDescending;
        }
        let sortedItems: IRelocationFormQueues[] = _copyAndSort(reloqueue, column?.fieldName!, isSortedDescending);

        setreloqueue(sortedItems);
    }
};
