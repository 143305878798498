import * as React from 'react';
import {
    Stack,
    IconButton,
    IStackItemStyles,
    IStackProps,
    IStackStyles,
    IStackTokens,
    Label,
} from '@fluentui/react';
import {
    Dropdown,
    IDropdownStyles,
    IDropdownOption
} from '@fluentui/react/lib/Dropdown';
import * as moment from 'moment';
import { ReduxContext } from '@employee-experience/common';
import { ILoggedInUserRoles } from '../../Models/IUserRoles';
import { IAppState } from '../../Common/Redux/AppState';

const wrapStackTokens: IStackTokens = { childrenGap: 60 };
const estimateProfileDivStyle = {
    paddingBottom: 15,
};
const stackStyles: Partial<IStackStyles> = {
    root: {
        width: '100%',
        marginTop: '7px',
        marginBottom: '50px'
    }
};
const accordionHeader: IStackItemStyles = {
    root: {
        padding: 5,
        cursor: 'pointer',
        fontSize: '20px',
    },
};
const accordionContent: IStackItemStyles = {
    root: {
        marginLeft: '50px',
        marginTop: 15,
    },
};
const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: {
        width: 250
    },
    dropdownOptionText: {
        overflow: 'visible',
        whiteSpace: 'normal'
    },
    dropdownItem: {
        height: 'auto'
    },
};
const estimateProfileColumnProps: Partial<IStackProps> = {
    tokens: {
        childrenGap: 10
    },
    styles: {
        root: {
            width: '15%'
        }
    },
};

export const EstimateProfile: React.FC<any> = React.forwardRef((props, ref) => {
    const [isOpen, setIsOpen] = React.useState<boolean>(true);

    const { useSelector } = React.useContext(ReduxContext);
    const userRole: ILoggedInUserRoles = useSelector((state: IAppState) => state.root.userReducer.loggedInUserRoles);

    const [relocationType, setRelocationType] = React.useState<string>('');
    const [relocationTypeID, setRelocationTypeID] = React.useState<string>('');
    const [relocationPolicy, setRelocationPolicy] = React.useState<string>('');
    const [relocationPolicyID, setRelocationPolicyID] = React.useState<string>('');

    const [relocationTypeOptions, setRelocationTypeOptions] = React.useState<IDropdownOption[]>([]);
    const [relocationPolicyOptions, setRelocationPolicyOptions] = React.useState<IDropdownOption[]>([]);

    React.useImperativeHandle(
        ref,
        () => ({
            validateForm() {
                let invalidFields = [];
                let values: any = {};

                if (props.isNotNullOrUndefined(relocationTypeID)) {
                    values['RelocationpolicyTypeID'] = relocationTypeID;
                } else {
                    values['RelocationpolicyTypeID'] = null;
                    invalidFields.push('RelocationpolicyTypeID');
                }

                if (props.isNotNullOrUndefined(relocationPolicyID)) {
                    values['RelocationPolicyID'] = relocationPolicyID
                } else {
                    values['RelocationPolicyID'] = null;
                    invalidFields.push('RelocationPolicyID');
                }

                if (props.isNotNullOrUndefined(props.estimateProfileResponse?.DestinationCountry)) {
                    values['Country'] = props.estimateProfileResponse?.DestinationCountry;
                } else {
                    values['Country'] = null;
                    invalidFields.push('Country');
                }

                return { errors: Array.from(new Set(invalidFields)), values }
            }
        }),
    );

    React.useEffect(() => {
        updateRelocationTypeDropdownOptions();
        updateRelocationPolilcyDropdownOptions();
    }, []);

    React.useEffect(() => {
        if (props.isChangesCancelled) {
            updateRelocationTypeDropdownOptions();
            updateRelocationPolilcyDropdownOptions();
        }
    }, [props.isChangesCancelled]);

    React.useEffect(() => {
        updateRelocationTypeDropdownOptions();
    }, [props.relocationTypeOptions]);

    React.useEffect(() => {
        let detailsRows = document.querySelectorAll('.ms-DetailsRow-check');
        detailsRows.forEach((detailsRow) => {
            detailsRow.setAttribute('aria-label', 'checkbox');
        })
    })

    const toggleEstimateProfile = () => {
        setIsOpen(!isOpen);
    };

    const updateRelocationTypeDropdownOptions = () => {
        if (props.relocationTypeOptions) {
            let relocationTypeOptionsFormatting: IDropdownOption[] = [];
            props.relocationTypeOptions.map((item: any) => {
                relocationTypeOptionsFormatting.push({ key: item.RelocationTypeID, text: item.RelocationTypeName });
            });
            setRelocationTypeOptions(relocationTypeOptionsFormatting);
            setRelocationTypeID(props?.RelocationPolicyTypeID);
            setRelocationType(props.estimateProfileResponse?.RelocationType)
        }
    }

    const updateRelocationPolilcyDropdownOptions = () => {
        if (props.relocationPoliciesResponse) {
            let relocationPolicyOptions: IDropdownOption[] = [];
            props.relocationPoliciesResponse?.map((item: any) => {
                relocationPolicyOptions.push({ key: item.RelocationPolicyID, text: item.RelocationPolicyName });
                if (item.RelocationPolicyName === props.estimateProfileResponse?.RelocationPolicy) {
                    setRelocationPolicyID(item.RelocationPolicyID);
                }
            });
            setRelocationPolicyOptions(relocationPolicyOptions);
            setRelocationPolicy(props.estimateProfileResponse?.RelocationPolicy);
        }
    }

    const onRelocationTypeChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {

        setRelocationType(item.text);
        setRelocationTypeID(`${item.key}`);
        let myPattern = new RegExp('(\\w*' + 'Lump Sum' + '\\w*)', 'gi');

        let changedDetails = {
            HireTypeID: props.HireTypeID,
            RelocationPolicyID: relocationPolicyID,
            RelocationpolicyTypeID: item.key,
            IsLumpSum: item.text.match(myPattern) ? true : false,
            RelocationTypeID: item.key,
            ReloPolicyType: item.text.match(myPattern) ? 'LumSum' : 'Full'
        }
        props.onRelocationTypeChange(item, changedDetails)
        props.setRelocationTypeResponse(item.text)
    };

    React.useEffect(() => {
        if (props.selectedRelocationType && props.selectedRelocationType !== props.estimateProfileResponse?.RelocationType) {
        } else {
            setRelocationTypeID(props?.RelocationPolicyTypeID);
            setRelocationType(props.estimateProfileResponse?.RelocationType)
        }

    }, [props.selectedRelocationType])

    const onRelocationPolicyChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
        setRelocationPolicy(item.text);
        setRelocationPolicyID(`${item.key}`);
        setRelocationTypeID('');
        props.getAllRelocationTypesResponse(props.HireTypeID,`${item.key}`);
    };

    return (
        <Stack className="EstimateProfile" style={estimateProfileDivStyle}>
            <Stack horizontal={true}>
                <Stack.Item style={{ marginTop: '3px' }}>
                    <IconButton
                        aria-labelledby="Estimate Profile"
                        title="Estimate Profile"
                        iconProps={{
                            iconName: isOpen ? 'ChevronUp' : 'ChevronDown',
                        }}
                        onClick={toggleEstimateProfile}
                        role="button"
                    />
                </Stack.Item>
                <Stack.Item align="center" styles={accordionHeader}>
                    <h2 style={{ fontSize: '20px' }}>Estimate Profile</h2>
                </Stack.Item>
            </Stack>

            {isOpen && (
                <Stack styles={accordionContent}>
                    <Stack horizontal wrap tokens={wrapStackTokens} styles={stackStyles}>
                        <Stack.Item {...estimateProfileColumnProps}>
                            <Label>Record Id</Label>
                            <p>{props.estimateProfileResponse?.RecordID}</p>
                        </Stack.Item>

                        <Stack.Item {...estimateProfileColumnProps}>
                            <Label>File Id</Label>
                            <p>{props.estimateProfileResponse?.FileID}</p>
                        </Stack.Item>

                        <Stack.Item {...estimateProfileColumnProps}>
                            <Label>Updated By</Label>
                            <p>{props.estimateProfileResponse?.Updatedby}</p>
                        </Stack.Item>

                        <Stack.Item {...estimateProfileColumnProps}>
                            <Label>Updated On</Label>
                            <p>{moment(props.estimateProfileResponse?.Updatedon).format('MM/DD/YYYY')}</p>

                        </Stack.Item>

                        <Stack.Item {...estimateProfileColumnProps}>
                            <Label>Assigned To</Label>
                            <p>{props.estimateProfileResponse?.Assignedto}</p>
                        </Stack.Item>

                        <Stack.Item {...estimateProfileColumnProps}>
                            <Label>Relocation Policy</Label>
                            {!props.isEditMode
                                ? <p>{relocationPolicy}</p>
                                : (userRole.ReloAdmin || userRole.ReloAnalyst || userRole.ReloSpecialist)
                                    ? (
                                        <Dropdown
                                            ariaLabel="relocation policy dropdown"
                                            options={relocationPolicyOptions || []}
                                            styles={dropdownStyles}
                                            selectedKey={relocationPolicyID ? relocationPolicyID : null}
                                            onChange={(event, option) => {
                                                if (option) {
                                                    return onRelocationPolicyChange(event, option)
                                                }
                                            }}
                                            required
                                        />
                                    )
                                    : <p>{relocationPolicy}</p>

                            }
                        </Stack.Item>

                        <Stack.Item {...estimateProfileColumnProps}>
                            <Label>Relocation Type</Label>
                            {!props.isEditMode
                                ? <p>{relocationType}</p>
                                : (userRole.ReloAdmin || userRole.ReloAnalyst || userRole.ReloSpecialist)
                                    ? (
                                        <Dropdown
                                            ariaLabel="relocation types dropdown"
                                            options={relocationTypeOptions || []}
                                            styles={dropdownStyles}
                                            selectedKey={relocationTypeID ? relocationTypeID : null}
                                            onChange={(event, option) => {
                                                if (option) {
                                                    return onRelocationTypeChange(event, option)
                                                }
                                            }}
                                            required
                                        />
                                    )
                                    : <p>{relocationType}</p>
                            }
                        </Stack.Item>

                        <Stack.Item {...estimateProfileColumnProps}>
                            <Label>Hire Type</Label>
                            <p>{props.estimateProfileResponse?.HireType}</p>
                        </Stack.Item>

                        <Stack.Item {...estimateProfileColumnProps}>
                            <Label>Destination Country</Label>
                            <p>{props.estimateProfileResponse?.DestinationCountry}</p>
                        </Stack.Item>
                    </Stack>
                </Stack>
            )}
        </Stack>
    )
})