import { IColumn } from '@fluentui/react';

export const GlomocoeColumns: IColumn[] = [
  {
    key: 'FirstName',
    name: 'FIRST NAME',
    minWidth: 120,
  },
  {
    key: 'LastName',
    name: 'LAST NAME',
    minWidth: 120,
  },
  {
    key: 'RecordID',
    name: 'GMAT Record ID',
    minWidth: 125,
  },
  {
    key: 'SubmittedBy',
    name: 'E-FORM SUBMITTER',
    minWidth: 125,
  },
  {
    key: 'VisaStatus',
    name: 'VISA PENDING',
    minWidth: 125,
  },
  {
    key: 'EstimatedStartDate',
    name: 'ESTIMATED START DATE',
    minWidth: 125,
  },
  {
    key: 'OriginalSubmissionDate',
    name: 'ORIGINAL SUBMISSION DATE',
    minWidth: 125,
  },
  {
    key: 'RelocationType',
    name: 'RELOCATION TYPE',
    minWidth: 125,
  },
  {
    key: 'CurentEmail',
    name: 'CURRENT EMAIL',
    minWidth: 125,
  },
  {
    key: 'DepartureCountry',
    name: 'DEPARTURE COUNTRY',
    minWidth: 125,
  },
  {
    key: 'DepartureCity',
    name: 'DEPARTURE CITY',
    minWidth: 125,
  },
  {
    key: 'DepartureStateProvince',
    name: 'DEPARTURE STATE/PROVINCE',
    minWidth: 125,
  },
  {
    key: 'WorkDestinationCountry',
    name: 'DESTINATION COUNTRY',
    minWidth: 125,
  },
  {
    key: 'WorkDestinationCity',
    name: 'DESTINATION CITY',
    minWidth: 125,
  },
  {
    key: 'WorkDestinationStateProvince',
    name: 'DESTINATION STATE/PROVINCE',
    minWidth: 125,
  },
  {
    key: 'CandidateID',
    name: 'CANDIDATE ID',
    minWidth: 80,
  },
  {
    key: 'EmployeeId',
    name: 'EMPLOYEE ID',
    minWidth: 120,
  },
  {
    key: 'HireTypeName',
    name: 'HIRE TYPE',
    minWidth: 125,
  },
  {
    key: 'InternshipTypeName',
    name: 'INTERNSHIP TYPE',
    minWidth: 125,
  },
  {
    key: 'Status',
    name: 'Status',
    minWidth: 120,
  },
  {
    key: 'RelocationPolicyName',
    name: 'RELOCATION POLICY',
    minWidth: 125,
  },
  {
    key: 'SubmissionDate',
    name: 'LATEST RESUBMISSION DATE',
    minWidth: 125,
  },
  {
    key: 'BusinessGroup',
    name: 'BUSINESS GROUP',
    minWidth: 125,
  },
  {
    key: 'CostCenter',
    name: 'COST CENTER',
    minWidth: 125,
  },
  {
    key: 'JobLevel',
    name: 'JOB LEVEL',
    minWidth: 125,
  },
  {
    key: 'StaffingManagerAlias',
    name: 'STAFFING MANAGER ALIAS',
    minWidth: 125,
  },
  {
    key: 'RecruiterAlias',
    name: 'RECRUITER ALIAS',
    minWidth: 125,
  },
  {
    key: 'HiringManagerAlias',
    name: 'HIRING MANAGER ALIAS',
    minWidth: 125,
  },
  {
    key: 'RelocationSpecialist',
    name: 'RELOCATION SPECIALIST',
    minWidth: 125,
  },
  {
    key: 'Mach',
    name: 'MACH',
    minWidth: 125,
  },
  {
    key: 'Mba',
    name: 'MBA',
    minWidth: 125,
  },
  {
    key: 'EverSendBack',
    name: 'EVER SEND BACK',
    minWidth: 125,
  },
  {
    key: 'Comments',
    name: 'COMMENTS',
    minWidth: 125,
  },
];
