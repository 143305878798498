import * as React from 'react';
import { 
  Dialog, 
  DialogType, 
  DialogFooter,
  PrimaryButton,
  hiddenContentStyle,
  mergeStyles,
} from '@fluentui/react';
import { useId, useBoolean } from '@fluentui/react-hooks';

const dialogStyles = { main: { maxWidth: 450 } };
const screenReaderOnly = mergeStyles(hiddenContentStyle);

export const GenericNotification: React.FC<{ title: string, msg: string, fx?: any }> = ({ title, msg, fx }) => {
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(false);
  const labelId: string = useId('dialogLabel');
  const subTextId: string = useId('subTextLabel');

  const modalProps = React.useMemo(
    () => ({
      titleAriaId: labelId,
      subtitleAriaId: subTextId,
      isBlocking: false,
      styles: dialogStyles,
    }),
    [labelId, subTextId]
  );
  const dialogContentProps = {
    type: DialogType.normal,
    title: title,
    closeButtonAriaLabel: 'Close',
    subText: msg,
  };

  const executeAndClose = () => { 
    if (fx != undefined) { 
      fx(); // execute passed in function
    }
    toggleHideDialog();
  }

  return (
    <>
      <label id={labelId} className={screenReaderOnly}>
        {title} Message
      </label>
      <label id={subTextId} className={screenReaderOnly}>
        {msg}
      </label>

      <Dialog
        hidden={hideDialog}
        // onDismiss={fx === undefined ? toggleHideDialog : fx} // check this, simulate errors in ReviewForm
        dialogContentProps={dialogContentProps}
        modalProps={modalProps}
      >
        <DialogFooter>
          <PrimaryButton onClick={executeAndClose} text="Close" />
        </DialogFooter>
      </Dialog>
    </>
  );
};
