import { IHttpClient, IHttpClientResult } from '@employee-experience/common';
import { IPendingExceptionsApprovalQueueForm, IListOfSubmissions } from '../../Models/IPendingExceptionApproalQueue';

export class PendingExceptionQueueApprovalQueueService {
    private httpClient: IHttpClient;
    private url: string;

    public constructor(httpClient: IHttpClient) {
        this.httpClient = httpClient;
        this.url = REACT_APP_RELO_API_URL;
    }

    public async GetPendingExceptionApprovalDetails(
        LoggedinAlias: string
    ): Promise<IPendingExceptionsApprovalQueueForm[]> {
        const response: IHttpClientResult<IPendingExceptionsApprovalQueueForm[]> = await this.httpClient.request({
            url: `${this.url}/api/PendingExceptions/GetPendingExceptionApprovalDetails/${LoggedinAlias}?LoggedinAlias=${LoggedinAlias}`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    }

    public async UpdateSubmissionStatus(FileExceptionIDs: number[], Status: string, Comments: string): Promise<boolean> {
        var exceptionUpdateStatusValues: IListOfSubmissions = {} as IListOfSubmissions;
        exceptionUpdateStatusValues.listOfFileIds = FileExceptionIDs;
        exceptionUpdateStatusValues.Comments = Comments;
        const request: IHttpClientResult<boolean> = await this.httpClient.request({
            url: `${this.url}api/PendingExceptions/UpdateSubmissionStatus/${Status}?Status=${Status}`,
            method: 'POST',
            resource: __CLIENT_ID__,
            data: exceptionUpdateStatusValues,
        });
        return request?.data;
    }
}
