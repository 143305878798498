// Working on this
import * as React from 'react';
import {
    DetailsList,
    IColumn,
    SelectionMode,
    DetailsListLayoutMode,
    IDetailsRowBaseProps,
    Selection,
    MarqueeSelection,
} from '@fluentui/react';

interface PreHireTypeBenefitsType {
    ID: string;
    BenefitName: string;
    BenefitDescptions: string;
    isSelected: boolean;
}

export interface PreHireTypeBenefitsProps {
    preHireTypeBenefits: PreHireTypeBenefitsType[];
    updatePreHireSelectedBenefits: Function;
}

// type PreHireTypeBenefitsState = {
//     items: PreHireTypeBenefitsType[];
//     columns: IColumn[];
// };

export const PreHireTypePanel: React.FC<PreHireTypeBenefitsProps> = (props) => {
    const [items, setItems] = React.useState<PreHireTypeBenefitsType[]>([]);

    React.useEffect(() => {
        if (props.preHireTypeBenefits.length > 0 && props.preHireTypeBenefits !== undefined) {
            console.log('preHireTypeBenefits', props.preHireTypeBenefits);
            setItems(props.preHireTypeBenefits);
        }
    }, [props]);

    React.useEffect(() => {
        mapSelectedRows();
    }, [items]);

    let _selection = React.useMemo(
        () =>
            new Selection({
                onSelectionChanged: () => {
                    _getSelectionDetails();
                },
                selectionMode: SelectionMode.multiple,
            }),
        []
    );

    function mapSelectedRows(): any {
        const newSelection = _selection;
        let selItems: any[] = props.preHireTypeBenefits;
        let preSelectedItems = props.preHireTypeBenefits.filter((item, index) => {
            if (item.isSelected) {
                return item;
            }
        });
        //Need to add null check
        newSelection.setItems(selItems);
        preSelectedItems.map((selectedItem: any, s) => {
            let index = props.preHireTypeBenefits.indexOf(selectedItem);
            newSelection.setIndexSelected(index, true, false);
        });
    }

    const columns: IColumn[] = [
        {
            key: 'column1',
            name: 'Benefits',
            ariaLabel: 'Column for BenefitName',
            fieldName: 'BenefitName',
            maxWidth: 180,
            minWidth: 50,
            isRowHeader: true,
            isResizable: true,
            isPadded: true,
        },
        {
            key: 'column2',
            name: 'Description of service options available',
            ariaLabel: 'Column  for Description of service options available',
            fieldName: 'BenefitDescptions',
            maxWidth: 100,
            minWidth: 50,
            isRowHeader: true,
            isResizable: true,
            isPadded: true,
        },
    ];

    function _onRenderRow(props: IDetailsRowBaseProps | undefined, defaultRender: any | undefined) {
        if (!props || !defaultRender) {
            //technically these may be undefined...
            return null;
        }
        return defaultRender({
            ...props,
            styles: {
                root: {
                    selectors: {
                        '.ms-DetailsRow-cell': {
                            whiteSpace: 'normal',
                            lineHeight: 'normal',
                            alignItems: 'center',
                        },
                        '.ms-DetailsRow-cellTitle': {
                            height: '100%',
                            alignItems: 'center',
                            wordWrap: 'break-word',
                        },
                    },
                },
            },
        });
    }

    async function _getSelectionDetails(): Promise<void> {
        await props.updatePreHireSelectedBenefits(_selection.getSelection());
    }

    return (
        <>
            <h3>Pre Hire Type</h3>
            <p>Please select from the available benefits for Pre Hire Services</p>
            <MarqueeSelection selection={_selection}>
                <DetailsList
                    items={items}
                    columns={columns}
                    selectionMode={SelectionMode.multiple}
                    setKey="multiple"
                    layoutMode={DetailsListLayoutMode.justified}
                    isHeaderVisible={true}
                    onRenderRow={_onRenderRow}
                    selection={_selection}
                    selectionPreservedOnEmptyClick={true}
                    ariaLabelForGrid="Pre Hire Type Panel"
                />
            </MarqueeSelection>
        </>
    );
};
