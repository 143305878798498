/* eslint-disable prettier/prettier */
import * as React from 'react';
import {
    DetailsList,
    IStackTokens,
    PrimaryButton,
    SelectionMode,
    Stack,
    TextField,
    MessageBar,
    MessageBarType,
    IColumn,
    IIconProps,
    ITextFieldStyles,
} from '@fluentui/react';
import { IReassignResult } from '../../Models/IReAssignRecords';
import { useServiceStore } from '../../RootStateContext';
import { ILoggedInUserRoles } from '../../Models/IUserRoles';
import { IAppState } from '../../Common/Redux/AppState';
import { ReduxContext } from '@employee-experience/common';
import { UnAuthorized } from '../../Components/QueueTable/Unauthorized';
import { ReassignColumnsPage } from './ReassignColumnsPage';
import { StackDisclaimer } from '../../Components/Shared/DisclaimerText';
interface ReassignRecordsProps { }
export const ReassignRecords: React.FC<ReassignRecordsProps> = (props) => {
    const [reAssignedResult, setReAssignedResults] = React.useState<IReassignResult[]>([] as IReassignResult[]);
    const [recordIDs, setRecordIDs] = React.useState('');
    const [recruitigAlias, setRecruitingAlias] = React.useState('');
    const { reassignRecordsService } = useServiceStore();
    const outerStackTokens: IStackTokens = { childrenGap: 10, padding: 5 };
    const horizontalGapStackTokens: IStackTokens = { childrenGap: 50 };
    // const stackItemStyles: IStackItemStyles = {
    //     root: {
    //         alignItems: 'center',
    //         // background: DefaultPalette.themePrimary,
    //         // color: DefaultPalette.white,
    //         display: 'flex',
    //         height: 50,
    //         justifyContent: 'center',
    //     },
    // };
    // Tokens definition
    const stackTokens: IStackTokens = {
        childrenGap: 5,
        padding: 10,
    };
    const itemStyles: React.CSSProperties = {
        alignItems: 'center',
        // background: DefaultPalette.themePrimary,
        // color: DefaultPalette.white,
        display: 'flex',
        // height: 50,
        justifyContent: 'center',
        padding: '10px',

        // width: 50,
    };
    const [IsReassigned, setIsReassigned] = React.useState<boolean>(false);
    const [IsSomethingWentWrong, setIsSomethingWentWrong] = React.useState<boolean>(false);
    const [newAlias, setNewAlias] = React.useState('');
    const [IsInitialStateRecordIDs, setIsInitialStateRecordIDs] = React.useState<boolean>(true);
    const [IsInitialStateAlias, setIsInitialStateAlias] = React.useState<boolean>(true);
    const [input, setInput] = React.useState('');
    const { useSelector } = React.useContext(ReduxContext);
    const userRole: ILoggedInUserRoles = useSelector((state: IAppState) => state.root.userReducer.loggedInUserRoles);
    const [columnsPage] = React.useState<IColumn[]>(ReassignColumnsPage);
    const onChangeRecordIDs = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newText: string): void => {
        setInput(newText);
        newText = newText.replace(/(\r\n|\n|\r)/gm, '');
        newText = newText.replace(/[^0-9+,]/g, '');
        setRecordIDs(newText);
        setIsInitialStateRecordIDs(false);
    };
    const onChangeRecruitingAssociate = (
        ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newText: string
    ): void => {
        setRecruitingAlias(newText);
        setIsInitialStateAlias(false);
    };
    const showSomethingWentWrong = () => {
        return (
            <MessageBar
                messageBarType={MessageBarType.error}
                dismissButtonAriaLabel="Close"
                onDismiss={() => {
                    setIsSomethingWentWrong(false);
                }}
                role={'alert'}
            >
                Sorry, something went wrong. E-form couldn't be reassigned.
            </MessageBar>
        );
    };
    const showIsReassigned = () => {
        return (
            <MessageBar
                messageBarType={MessageBarType.success}
                dismissButtonAriaLabel="Close"
                onDismiss={() => {
                    setIsReassigned(false);
                }}
                role={'alert'}
            >
                <b>Success!</b> Records have been reassigned to {newAlias}
            </MessageBar>
        );
    };
    const getRecordIdErrorMessage = (input: string) => {
        if (!IsInitialStateRecordIDs && input == '') {
            return 'Please enter record ID';
        }
    };
    const getAliasErrorMessage = (input: string) => {
        if (!IsInitialStateAlias && input == '') {
            return "Please enter recruiting associate's alias";
        }
    };
    const onAssignClick = (): void => {
        setInput(recordIDs);
        if (recruitigAlias !== '' && recordIDs !== '') {
            setIsSomethingWentWrong(false);
            setIsReassigned(false);
            reassignRecordsService
                .ReAssignBulkRecordToAnotherUser(recordIDs, recruitigAlias)
                .then((result) => {
                    if (result.includes('Error')) {
                        setIsSomethingWentWrong(true);
                        setIsReassigned(false);
                    } else {
                        setReAssignedResults(result);
                        setNewAlias(recruitigAlias);
                        setIsSomethingWentWrong(false);
                        setIsReassigned(true);
                    }
                })
                .catch((error) => {
                    setIsSomethingWentWrong(true);
                    setIsReassigned(false);
                });
        } else {
            setIsSomethingWentWrong(true);
            setIsReassigned(false);
        }
    };
    function _renderItemColumn(
        item: IReassignResult | undefined,
        index: number | undefined,
        column: IColumn | undefined
    ) {
        const fieldContent = item?.[column?.fieldName as keyof IReassignResult] as string;
        const iconPropsWarnings: IIconProps = {
            iconName: 'WarningSolid',
            styles: {
                root: { color: '#EC9F1D', fill: 'Black', fontSize: '22px' },
            },
        };
        const iconPropsError: IIconProps = {
            iconName: 'StatusErrorFull',
            styles: {
                root: { color: '#DF0008', fill: 'Black', fontSize: '22px' },
            },
        };
        const iconPropsSuccessful: IIconProps = {
            iconName: 'SkypeCircleCheck',
            styles: {
                root: { color: '#7BA811', fill: 'Black', fontSize: '22px' },
            },
        };
        const textFieldStyles: Partial<ITextFieldStyles> = {
            field: { verticalAlign: 'center', fontSize: '12px', fontFamily: 'Segoe UI', fill: 'Black' },
        };
        switch (column?.key) {
            case 'ReloId':
                if (item?.ReloId === null) {
                    return <span>{fieldContent}</span>;
                } else {
                    return item?.ReloId;
                }
            case 'OldAlias':
                if (item?.OldAlias === null) {
                    return <span>{fieldContent}</span>;
                } else {
                    return item?.OldAlias;
                }
            case 'NewAlias':
                if (item?.NewAlias === null) {
                    return <span>{fieldContent}</span>;
                } else {
                    return item?.NewAlias;
                }
            case 'Result':
                if (item?.Result.toUpperCase() == 'SUCCESS') {
                    return (
                        <TextField
                            iconProps={iconPropsSuccessful}
                            value={item?.Result}
                            styles={textFieldStyles}
                            borderless
                            ariaLabel="Result"
                        ></TextField>
                    );
                } else if (item?.Result.toUpperCase() == 'ALREADY ASSIGNED') {
                    return (
                        <TextField
                            iconProps={iconPropsWarnings}
                            ariaLabel="Result"
                            value={item?.Result}
                            styles={textFieldStyles}
                            borderless
                            readOnly
                        ></TextField>
                    );
                } else {
                    return (
                        <TextField
                            iconProps={iconPropsError}
                            ariaLabel="Result"
                            value={item?.Result}
                            styles={textFieldStyles}
                            borderless
                        ></TextField>
                    );
                }
            default:
                return <span>{fieldContent}</span>;
        }
    }
    return (
        <>
            {(userRole.Staffing || userRole.ReloAdmin || userRole.GloMoCo) ? (
                <Stack tokens={outerStackTokens}>
                    <Stack horizontal disableShrink horizontalAlign="space-between" tokens={horizontalGapStackTokens}>
                        <span style={{ marginLeft: '10px' }}>
                            <h1>Reassign Records</h1>
                        </span>
                    </Stack>
                    {(IsReassigned || IsSomethingWentWrong) && (
                        <Stack
                            horizontal
                            disableShrink
                            horizontalAlign="space-between"
                            tokens={horizontalGapStackTokens}
                        >
                            <span style={{ marginLeft: '10px' }}>
                                {IsReassigned && showIsReassigned()}
                                {IsSomethingWentWrong && showSomethingWentWrong()}
                            </span>
                        </Stack>
                    )}
                    <Stack horizontal disableShrink tokens={stackTokens}>
                        <span style={{ marginLeft: '10px', width: '100%' }}>
                            <h4>
                                To assign a record to different recruiting associate, provide a record ID, key in the
                                recruiting associate alias you want the record to be resigned to, then click the
                                Reassign Record button.
                            </h4>
                        </span>
                    </Stack>
                    <Stack horizontal horizontalAlign="start">
                        <span style={itemStyles}>
                            <TextField
                                label="Record ID"
                                role="textbox"
                                required
                                // multiline
                                // rows={3}
                                value={input}
                                onChange={(event, newText) => {
                                    if (newText) {
                                        onChangeRecordIDs(event, newText)
                                    }
                                }}
                                onGetErrorMessage={getRecordIdErrorMessage}
                            />
                        </span>
                        <span style={itemStyles}>
                            <TextField
                                label="E-Form Submitter"
                                role="textbox"
                                onChange={(event, newText) => {
                                    if (newText) {
                                        onChangeRecruitingAssociate(event, newText)
                                    }
                                }}
                                onGetErrorMessage={getAliasErrorMessage}
                            />
                        </span>
                        <span style={itemStyles}>
                            <PrimaryButton
                                text="Reassign Record"
                                allowDisabledFocus
                                onClick={onAssignClick}
                                style={{ position: 'relative', top: '13px' }}
                            />
                        </span>
                    </Stack>

                    <DetailsList
                        selectionMode={SelectionMode.none}
                        items={reAssignedResult}
                        columns={columnsPage}
                        onRenderItemColumn={_renderItemColumn}
                        ariaLabelForGrid="Reassign Records"
                        styles={{
                            root: {
                                width: '50%',
                            },
                        }}
                    />


                    {StackDisclaimer()}
                </Stack>
            ) : (
                <UnAuthorized />
            )}
        </>
    );
};
