import * as React from 'react';
import { buildColumns, DetailsList, IColumn, SelectionMode, Stack, Text } from '@fluentui/react';
import { CoreAllowance } from '../../Models/IReviewForm';
import { useServiceStore } from '../../RootStateContext';

type CoreBenefitsProps = {
    reloTypeId: number;
    reloTypePolicyId: number;
    fiscalYear: number;
    relocationCashAmount: number;
    taxpactforlumpsum: number;

};

export type ListItem = {
    coreBenefitName: string;
    amount: string;
};

const allowanceStyle = {
    border: '1px solid rgb(237, 235, 233)',
    padding: 10,
    margin :5
};

export const CoreBenefitsList: React.FC<CoreBenefitsProps> = (props) => {
    const [items, setItems] = React.useState<ListItem[]>([]);
    const [columns, setColumns] = React.useState<IColumn[]>([]);
    const [coreAllowance, setCoreAllowance] = React.useState<CoreAllowance[]>([]);
    const { reviewFormService } = useServiceStore();
    const [relocationDisplayString, setRelocationDisplayString] = React.useState<string>('');

    React.useEffect(() => {
        getCoreBenefits();
    }, [props.taxpactforlumpsum]);

    const getCoreBenefits = async () => {
        let result = await reviewFormService.getCoreBenefits(props.reloTypePolicyId, props.fiscalYear);
        let coreBenefits: ListItem[] = [];
        let coreAllowance: CoreAllowance[] = [];
        let filteredResult = result.filter((benefit: any) => {
            if (benefit.CoreBeniftName.trim() === 'Relocation Cash Allowance') {
                if (
                    benefit.RelocationPolicyTypeID === 19 ||
                    benefit.RelocationPolicyTypeID === 20 ||
                    benefit.RelocationPolicyTypeID === 21 ||
                    benefit.RelocationPolicyTypeID === 37
                ) {
                    if (benefit.CoreBenifitID === 3) {
                        coreAllowance.push({
                            CoreBenifitID: 0,
                            CashAmount: benefit.Amount,
                            IsLessThan: parseFloat(benefit.Amount) <= 10000 ? true : false,
                            CoreBeniftName: 'Relocation Cash Allowance',
                            IsDisabled: true,
                            Amount: benefit.Amount,
                        });
                        benefit.Amount = '$' + benefit.Amount.toString();
                    }
                    return benefit;
                } else {
                    coreAllowance.push({
                        CoreBenifitID: 0,
                        CashAmount: benefit.Amount,
                        IsLessThan: parseFloat(benefit.Amount) <= 10000 ? true : false,
                        CoreBeniftName: 'Relocation Cash Allowance',
                        IsDisabled: false,
                        Amount: benefit.Amount,
                    });
                }
            }
            return benefit.CoreBeniftName.trim() != 'Relocation Cash Allowance';
        });
        for (let benefit of filteredResult) {
            let obj = {
                coreBenefitName: benefit.CoreBeniftName,
                amount: benefit.Amount,
            };
            if(obj.coreBenefitName === "Tax Gross-up on taxable benefits" ){
                obj.amount = "Included @" + props.taxpactforlumpsum + "%"
            }
            coreBenefits.push(obj);
        }

        setItems(coreBenefits);
        setColumns(_buildColumns(coreBenefits));
        setCoreAllowance(coreAllowance);
    };

    React.useEffect(() => {
        const reloTypeId = props.reloTypeId.toString();
        if (reloTypeId === '2' || reloTypeId === '4' || reloTypeId === '5' || reloTypeId === '12' || reloTypeId === '13') {
            setRelocationDisplayString(`$${props.relocationCashAmount}`);
        } else if (reloTypeId === '1' || reloTypeId === '3' || reloTypeId === '6' || reloTypeId === '8' || reloTypeId === '10' || reloTypeId === '11') {
            setRelocationDisplayString(`(In local currency) ${props.relocationCashAmount}`);
        } else {
            setRelocationDisplayString('0');
        }
    }, [props.reloTypeId])

    return (
        <>
            {props.reloTypePolicyId !=14 && (
                    <Text variant="small">
                        Use the Exceptions drop down below for any exceptions approved in addition to the Core Benefits.
                    </Text>
            )}

            <DetailsList items={items} columns={columns} selectionMode={SelectionMode.none} ariaLabelForGrid="Core Benefits List" />

            {props.relocationCashAmount > 0 &&
                props.reloTypeId != 19 &&
                props.reloTypeId != 20 &&
                props.reloTypeId != 21 &&
                props.reloTypeId != 37 && (
                    <Stack horizontal horizontalAlign="space-between" style={allowanceStyle}>
                        <Stack.Item>
                            <Text variant="large"> Relocation Cash Amount</Text>
                        </Stack.Item>
                        <Stack.Item>
                            <Text variant="large" block role='textbox' aria-label="Relocation Cash Amount">
                                {relocationDisplayString}
                            </Text>
                        </Stack.Item>
                    </Stack>
                )}
        </>
    );
};

function _buildColumns(items: ListItem[]): IColumn[] {
    const columns = buildColumns(items);

    const finalColumns = columns.map((col) => {
        switch (col.key) {
            case 'coreBenefitName':
                col.name = 'Benefits';
                col.minWidth = 300;
                col.maxWidth = 600;
                return col;
            case 'amount':
                col.name = 'Amount';
                return col;
            default:
                return col;
        }
    });
    return finalColumns;
}
