import * as React from 'react';
import { DetailsList, IColumn, SelectionMode, DetailsListLayoutMode } from '@fluentui/react/lib/DetailsList';
import { TextField } from '@fluentui/react/lib/TextField';
import { LumpSumRelocation } from '../../Models/IReviewForm';

export interface LumpSumPanelProps {
    relocationPolicyID: number;
    relocationType: string;
    updateLumSumNetCash: Function;
    updateLumSumAccuralCost: Function;
    LumpSumRelocation: LumpSumRelocation;
    taxPctForLumpSum: number;
}

export const LumpSumPanel: React.FC<LumpSumPanelProps> = (props) => {
    const [tableData] = React.useState([
        {
            relocationType: props.relocationType,
            RelocationPolicyID: props.relocationPolicyID,
            LumpSumRelocation: {
                NetCash: props.LumpSumRelocation.NetCash,
                AccrualCostCenter: props.LumpSumRelocation.AccrualCostCenter,
            },
        },
    ]);
    const [netCash, setNetCash] = React.useState(props.LumpSumRelocation.NetCash)
    const [accuralCost, setAccuralCost] = React.useState(props.LumpSumRelocation.AccrualCostCenter)
    const dollarSet = [2, 4, 5, 7];
    const localCurrencySet = [1, 3, 6, 8];

    const [dollarFlag] = React.useState(dollarSet.findIndex((item) => item === props.relocationPolicyID) != -1);

    const [localCurrencyFlag] = React.useState(
        localCurrencySet.findIndex((item) => item === props.relocationPolicyID) != -1
    );

    React.useEffect(() => {
        console.log('LumpSumPanelProps dollarFlag', dollarFlag);
        console.log('LumpSumPanelProps localCurrencyFlag', localCurrencyFlag);
    }, [dollarFlag, localCurrencyFlag]);

    const columns: IColumn[] = [
        {
            key: 'column1',
            name: 'Benefits',
            ariaLabel: 'Benefits column',
            fieldName: 'benefits',
            minWidth: 210,
            maxWidth: 350,
            isRowHeader: true,
            isResizable: true,
            isPadded: true,
            onRender: (item) => <span>{item.relocationType}</span>,
        },
        {
            key: 'column2',
            name: 'Net Cash to Employee',
            fieldName: 'netCash',
            minWidth: 210,
            maxWidth: 350,
            isRowHeader: true,
            isResizable: true,
            isPadded: true,
            onRender: (item) => (
                <>
                    {dollarFlag && <span>$</span>}
                    {localCurrencyFlag && <span>(In local currency)</span>}
                    <TextField
                        ariaLabel="Net Cash to Employe"
                        value={netCash}
                        onChange={(event, newValue) => {
                            setNetCash(newValue);
                            let taxNetCashVal = 100.0;
                            let taxNetCashVal = taxNetCashVal + props.taxPctForLumpSum;
                            let accrualCostCenter = (newValue * taxNetCashVal) / 100;
                            setAccuralCost(accrualCostCenter)
                        }}
                        onBlur={(value) => {
                            props.updateLumSumNetCash(value.currentTarget.value);
                        }}
                    />
                </>
            ),
        },
        {
            key: 'column3',
            name: 'Accrual to Cost Center(including gross-up)',
            fieldName: 'accuralCost',
            minWidth: 210,
            maxWidth: 350,
            isRowHeader: true,
            isResizable: true,
            isPadded: true,
            onRender: (item) => (
                <>
                    {dollarFlag && <span>$</span>}
                    {localCurrencyFlag && <span>(In local currency)</span>}
                    <TextField
                        ariaLabel="Accrual to Cost Center"
                        value={accuralCost}
                        onChange={(event, newValue) => setAccuralCost(newValue)}
                        onBlur={(value) => props.updateLumSumAccuralCost(value.currentTarget.value)}
                    />
                </>
            ),
        },
    ];

    return (
        <>
            <DetailsList
                items={tableData}
                columns={columns}
                selectionMode={SelectionMode.none}
                setKey="none"
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                ariaLabelForGrid="Lump Sum Panel"
            />
        </>
    );
};
