/* eslint-disable prettier/prettier */
import {
    CoherencePageSize,
    CoherencePagination,
    ICoherencePageSizeProps,
    ICoherencePaginationProps,
} from '@cseo/controls/lib/pagination';
import { ReduxContext } from '@employee-experience/common';
import { usePageTitle } from '@employee-experience/common/lib/usePageTitle';
import {
    DetailsListLayoutMode,
    DetailsRow,
    Dropdown,
    IColumn,
    IComboBoxOption,
    IDetailsRowBaseProps,
    IDetailsRowStyles,
    IDropdownOption,
    IStackItemStyles,
    IStackTokens,
    SelectionMode,
    ShimmeredDetailsList,
    Stack,
    FontIcon,
    mergeStyles,
    mergeStyleSets,
    SearchBox,
    PrimaryButton,
} from '@fluentui/react';
import * as React from 'react';
import { IAppState } from '../../Common/Redux/AppState';
import {
    PendingExceptionQueue,
    IPendingExceptionQueueForm,
} from '../../Models/IPendingExceptionQueue';
import * as moment from 'moment';
import { ILoggedInUserRoles, IUserRoles } from '../../Models/IUserRoles';
import { useServiceStore } from '../../RootStateContext';
import { ReloSpecialistColumns } from './ReloSpecialistColumns';
import { LoadingSpinner } from '../../Components/Shared/LoadingSpinner';
import { ReloFormStatusTypes } from '../../types/ReloFormTypes';
import { UnAuthorized } from '../../Components/QueueTable/Unauthorized';
import { useHistory } from 'react-router-dom';
import { StackDisclaimer } from '../../Components/Shared/DisclaimerText';
import { ExportToExcel } from '../../Components/Shared/ExportToExcel';

export function ReloSpecialistQueue(): React.ReactElement {
    const history = useHistory();

    const [loaded, setLoaded] = React.useState<boolean>(false);
    const { useSelector } = React.useContext(ReduxContext);
    const userdata: IUserRoles = useSelector((state: IAppState) => state.root.userReducer.userRoles);
    const userRole: ILoggedInUserRoles = useSelector((state: IAppState) => state.root.userReducer.loggedInUserRoles);
    const [columns, setColumns] = React.useState<IColumn[]>(ReloSpecialistColumns);
    const [currentPageData, setCurrentPageData] = React.useState<PendingExceptionQueue[]>([]);
    const DEFAULT_PAGE_SIZE = 10;
    var totalRecords: number = 0;
    const selectedPage = React.useRef<number>(1);
    const pageCount = React.useRef<number>(0);
    const currentPageSize = React.useRef<number>(DEFAULT_PAGE_SIZE);
    let queueItems: PendingExceptionQueue[] = [];
    const [exportQueueItems, setExportQueueItems] = React.useState<any[]>([]);
    const { reviewFormService, pendingExceptionQueueService } = useServiceStore();
    const [reloSpecialistDropdown, setReloSpecialistDropdown] = React.useState<IDropdownOption[]>([]);
    const [searchByValue, setsearchByValue] = React.useState<string>('Search by');
    const [searchByText, setsearchByText] = React.useState<string>('Search by');
    const [selectedReloSpecialist, setSelectedReloSpecialist] = React.useState<string>('');
    const [pendingExceptionQueue, setPendingExceptionQueue] = React.useState<IPendingExceptionQueueForm[]>([]);
    const [showEstimatesFormModal, setShowEstimatesFormModal] = React.useState<boolean>(true);
    const [searchKeyword, setSearchKeyword] = React.useState<string>('');
    const [searchError, setSearchError] = React.useState<boolean>(false);
    const [numReloSpecialists, setNumReloSpecialists] = React.useState<number>(0);

    const dropdownOptions = [
        { key: 'fileID', text: 'File ID' },
        { key: 'recordID', text: 'Record ID' },
        { key: 'employeeNumber', text: 'Employee Number' },
        { key: 'employeeName', text: 'Employee Name' },
    ];

    enum searchByOptions {
        fileID = 1,
        recordID = 2,
        employeeNumber = 3,
        employeeName = 4,
    }

    const custom_error_msg = mergeStyles({
        color: 'crimson',
        fontSize: '12.6px',
        marginTop: '5px',
        marginBottom: '0px',
        fontWeight: 'bold',
    });

    function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
        const key = columnKey as keyof T;
        return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    }

    if (pendingExceptionQueue && pendingExceptionQueue.length > 0) {
        queueItems = pendingExceptionQueue.map((row) => {
            return {
                fileId: row.fileId,
                CustomerName: row.CustomerName,
                RecruitingAssociateAlias: row.RecruitingAssociateAlias,
                ReloSpecialistAlias: row.ReloSpecialistAlias,
                Approver: row.Approver,
                StartDate: moment(row.StartDate.split('.')[0]).format('M/D/YYYY'),
                RelocationType: row.RelocationType,
                Exceptions: row.Exceptions,
                Status: row.Status,
                Reminders: row.Reminders,
                reloId: row.reloId,
                FEId: row.FEId,
            };
        });
    }

    const fetchData = (pageSize: number, newIndex: number = 0): void => {
        totalRecords = queueItems.length;
        let rowDatata: PendingExceptionQueue[] = [];
        for (let i = newIndex; i < Math.min(pageSize + newIndex, queueItems.length); ++i) {
            let row: PendingExceptionQueue = queueItems[i];
            rowDatata.push(row);
        }
        pageCount.current = Math.ceil(totalRecords / currentPageSize.current);

        setCurrentPageData(rowDatata);
    };

    const onPageChange = (newPageNumber: number): void => {
        if (newPageNumber !== selectedPage.current) {
            selectedPage.current = newPageNumber;
            fetchData(currentPageSize.current, (newPageNumber - 1) * currentPageSize.current);
        }
    };

    function onDropdownChange(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void {
        setsearchByValue(option!.key as string);
        setsearchByText(option!.text as string);
        setSearchError(false);
    }

    const onPageSizeChange = (newPageSize: string | number): void => {
        currentPageSize.current = newPageSize as number;
        selectedPage.current = 1;
        fetchData(currentPageSize.current);
    };
    const paginationProps: ICoherencePaginationProps = {
        pageCount: pageCount.current,
        selectedPage: selectedPage.current,
        previousPageAriaLabel: 'previous page',
        nextPageAriaLabel: 'next page',
        inputFieldAriaLabel: 'page number',
        onPageChange: onPageChange,
    };
    let pageSizeList: IComboBoxOption[] = [];
    for (let index = 10; index <= 50; index += 10) {
        if (queueItems.length < index) {
            pageSizeList.push({ key: queueItems.length, text: 'ALL' });
            break;
        }
        pageSizeList.push({ key: index, text: index.toString() });
    }

    const paginationPageSizeProps: ICoherencePageSizeProps = {
        pageSize: currentPageSize.current,
        pageSizeList: [
            { key: 10, text: '10' },
            { key: 20, text: '20' },
            { key: 50, text: '50' },
            { key: 100, text: '100' },
            { key: 200, text: '200' },
            { key: 300, text: '300' },
            { key: 400, text: '400' },
            { key: 500, text: '500' },
        ] as IComboBoxOption[],
        comboBoxAriaLabel: 'select page size',
        onPageSizeChange: onPageSizeChange,
    };
    const onReloSpecialistSelect = async (
        event: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption
    ): Promise<any> => {
        if (option?.text === 'Relo Specialist List') {
            getPendingExceptionQueue('dropdown');
        } else {
            getPendingExceptionQueue('dropdown', option?.key.toString());
        }
        setSearchKeyword('');
    };
    async function getReloSpecialists() {
        return await pendingExceptionQueueService.GetReloSpecialistList();
    }

    async function getReloSpecialistDropdown() {
        //Get reloSpecialist
        let reloSpecialists = await getReloSpecialists();
        //Map to generate array fo key and text (alias, "Alias: FirstName,LastName")
        reloSpecialists = reloSpecialists.map((row) => {
            if (!row.FullName) {
                row.FullName = '';
            }
            return row;
        });
        const reloSpecialistsOptions = reloSpecialists.map((row) => ({
            key: row.RelocationSpecialList,
            text:
                row.RelocationSpecialList === 'Relo Specialist List'
                    ? row.RelocationSpecialList
                    : `${row.RelocationSpecialList}: ${row.FullName}`,
        }));
        //Set relospecialist dropdown with array
        setNumReloSpecialists(reloSpecialistsOptions.length);
        setReloSpecialistDropdown(reloSpecialistsOptions);
    }

    const getPendingExceptionQueue = async (filterType?: string, alias?: string) => {
        let records: IPendingExceptionQueueForm[] = [];
        if (filterType === 'dropdown') {
            if (alias !== undefined) {
                records = await pendingExceptionQueueService.GetPendingExceptionsByRSAlias(alias);
                setSelectedReloSpecialist(alias);
            } else {
                records = await pendingExceptionQueueService.GetExceptionDetails(0, 0);
                setSelectedReloSpecialist('Relo Specialist List');
            }
        } else if (filterType === 'search') {
            //Get information from API, push into estiamtes form
            const searchOption: searchByOptions = searchByOptions[searchByValue as keyof typeof searchByOptions];
            const tempRecords = await pendingExceptionQueueService.GetExceptionDetailsSearch(
                searchOption,
                searchKeyword.trimEnd()
            );
            if (tempRecords.length === 0) {
                setSearchError(true);
                return;
            }
            const resultTemp = tempRecords[0];
            let obj = JSON.stringify({
                fileId: resultTemp.fileId,
                reloId: resultTemp.reloId,
                redirectedFrom: 'reloSpecialistQueue',
            });
            history.push('/EstimatesForm', obj);
        } else if (filterType === 'inital') {
            //Update depending if they are in the relo specialist
            if (userdata.Alias !== '' && userdata.Roles.length > 0) {
                let aliasInDropDown = false;
                for (let DropdownOption of reloSpecialistDropdown) {
                    if (userdata.Alias === DropdownOption.key) {
                        aliasInDropDown = true;
                        break;
                    }
                }
                if (aliasInDropDown) {
                    records = await pendingExceptionQueueService.GetPendingExceptionsByRSAlias(userdata.Alias)
                    setSelectedReloSpecialist(userdata.Alias);
                } else {
                    records = await pendingExceptionQueueService.GetExceptionDetails(0, 0);
                    setSelectedReloSpecialist('Relo Specialist List');
                }
            } else {
                setSelectedReloSpecialist('Relo Specialist List');
            }
        } else {
            records = pendingExceptionQueue;
        }
        const sortedRecords = records?.filter(
            (record) => record.RelocationType.includes('US Inbound') || record.RelocationType.includes('US Domestic')
        );
        setPendingExceptionQueue(_copyAndSort(sortedRecords, 'Status', true));
    };
    React.useEffect(() => {
        if (reloSpecialistDropdown.length == numReloSpecialists && numReloSpecialists !== 0) {
            setLoaded(true);
            getPendingExceptionQueue('inital');
        }
    }, [reloSpecialistDropdown]);
    React.useEffect(() => {
        (async function getDropdownAndQueue() {
            await getReloSpecialistDropdown();
        })();
    }, []);

    React.useEffect(() => {
        if (userRole.ReloAnalyst) {
            setLoaded(true);
        }
    }, [])


    function getSearchResults() {
        if (showEstimatesFormModal === true) {
            setShowEstimatesFormModal(false);
        }
        getPendingExceptionQueue('search', searchKeyword);
    }


    const verticalGapStackTokens: IStackTokens = {
        childrenGap: 10,
    };

    React.useEffect(() => {
        fetchData(currentPageSize.current);
        if (pendingExceptionQueue && pendingExceptionQueue.length > 0) {
             let tempExportQueueItems = pendingExceptionQueue.map((item) => {
                return {
                    ...item,
                    StartDate: item.StartDate ? new Date(item.StartDate) : "",
                }
            });
            setExportQueueItems(tempExportQueueItems);
        } else {
            setExportQueueItems([]);
        }
    }, [pendingExceptionQueue]);

    const stackItemStyles: IStackItemStyles = {
        root: {
            padding: 5,
        },
    };
    // const stackItemStyles1: IStackItemStyles = {
    //     root: {
    //         padding: 5,
    //         width: '35ex',
    //     },
    // };
    const horizontalGapStackTokens: IStackTokens = {
        childrenGap: 10,
        padding: 10,
    };
    usePageTitle(`Pending Exceptions Queue - ${__APP_NAME__}`);

    React.useEffect(() => {
        let inp = document.querySelectorAll('input');
        let btn = document.querySelectorAll('button');
        inp.forEach((item) => {
            item.setAttribute('tabindex', '0');
            if (item.getAttribute('aria-label') === 'page number') {
                item.setAttribute('role', 'textbox');
            }
            if (item.getAttribute('type') === 'checkbox') {
                item.setAttribute('role', 'checkbox');
            }
        });
        btn.forEach((item) => {
            if (item.getAttribute('aria-label') === 'previous page') {
                item.setAttribute('role', 'button');
                item?.parentElement?.parentElement?.classList.add('wrap-btn');
                item?.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.classList.add('wrap-btn');
            }
            if (item.getAttribute('aria-label') === 'next page') {
                item.setAttribute('role', 'button');
            }
            if (item.getAttribute('aria-hidden') == 'true') {
                item.setAttribute('disabled', 'true');
            }
        });
    });

    return (
        <>
            {!loaded ? (
                <LoadingSpinner />
            ) : (
                <>
                    {(userRole.ReloAdmin || userRole.ReloSpecialist || userRole.ReloAnalyst) ? (
                        <Stack>
                            <Stack tokens={verticalGapStackTokens}>
                                <Stack tokens={verticalGapStackTokens}>
                                    <Stack.Item align="auto" styles={stackItemStyles}>
                                        <h1>Pending Requests</h1>
                                        {/* <img src={photo} /> */}
                                    </Stack.Item>
                                    <Stack horizontal horizontalAlign="space-between" tokens={horizontalGapStackTokens}>
                                        <Stack.Item>
                                            <Stack.Item align="auto" style={{ display: 'inline-flex' }}>
                                                <Dropdown
                                                    label="ReloSpecialist"
                                                    options={reloSpecialistDropdown}
                                                    selectedKey={selectedReloSpecialist}
                                                    onChange={onReloSpecialistSelect}
                                                />
                                            </Stack.Item>
                                            <Stack.Item align="auto" style={{ display: 'inline-flex', paddingLeft: '10px', position: 'relative', top: '30px' }}>
                                                <ExportToExcel
                                                    rowsData={exportQueueItems}
                                                    colsData={ReloSpecialistColumns}
                                                    fileName="ReloSpecialistQueue.xlsx"
                                                />
                                            </Stack.Item>
                                        </Stack.Item>
                                        <Stack.Item>
                                            <Stack.Item style={{ display: 'inline-flex' }}>
                                                <Dropdown
                                                    label="Search by"
                                                    placeholder='Select One'
                                                    options={dropdownOptions}
                                                    selectedKey={searchByValue}
                                                    onChange={onDropdownChange}
                                                    ariaLabel="SearchBy Options"
                                                    style={{ width: '200px' }}
                                                />
                                            </Stack.Item>
                                            <Stack.Item align="end" style={{ display: 'inline-flex', paddingLeft: '10px', position: 'relative', top: '33px' }}>
                                                <SearchBox
                                                    placeholder="Search"
                                                    onEscape={(ev) => { }}
                                                    onClear={(ev) => { setSearchError(false); }}
                                                    onChange={(_, newValue) => setSearchKeyword(newValue || '')}
                                                    onSearch={getSearchResults}
                                                    value={searchKeyword}
                                                />
                                                {searchError && (
                                                    <p className={custom_error_msg}>
                                                        Please enter valid {searchByText}
                                                    </p>
                                                )}
                                            </Stack.Item>
                                            <Stack.Item style={{ display: 'inline-flex', paddingLeft: '10px', position: 'relative', top: '30px' }}>
                                                <PrimaryButton
                                                    text={'Search'}
                                                    onClick={() => getSearchResults()}
                                                ></PrimaryButton>
                                            </Stack.Item>
                                        </Stack.Item>
                                    </Stack>
                                    {/* <Stack horizontal horizontalAlign="space-between" styles={stackItemStyles}>
                                        <Stack tokens={verticalGapStackTokens} styles={stackItemStyles1}>
                                            <Stack.Item align="auto">
                                                <Dropdown
                                                    label="ReloSpecialist"
                                                    options={reloSpecialistDropdown}
                                                    selectedKey={selectedReloSpecialist}
                                                    onChange={onReloSpecialistSelect}
                                                />
                                            </Stack.Item>
                                            <Stack.Item align="auto">
                                                <ExportToExcel
                                                    rowsData={queueItems}
                                                    colsData={ReloSpecialistColumns}
                                                    fileName="ReloSpecialistQueue.xlsx"
                                                />
                                            </Stack.Item>
                                        </Stack>
                                        <Stack horizontalAlign="space-between" tokens={verticalGapStackTokens}>
                                            <Stack.Item>
                                                <Dropdown
                                                    label="Search by"
                                                    options={dropdownOptions}
                                                    selectedKey={searchByValue}
                                                    onChange={onDropdownChange}
                                                    ariaLabel="SearchBy Options"
                                                />
                                            </Stack.Item>
                                            <Stack.Item align="end">
                                                <SearchBox
                                                    placeholder="Search"
                                                    onEscape={(ev) => {}}
                                                    onClear={(ev) => {}}
                                                    onChange={(_, newValue) => setSearchKeyword(newValue || '')}
                                                    onSearch={getSearchResults}
                                                    value={searchKeyword}
                                                />
                                            </Stack.Item>
                                            {searchError && (
                                                <Stack.Item>
                                                    <p className={custom_error_msg}>
                                                        Please enter valid {searchByValue}
                                                    </p>
                                                </Stack.Item>
                                            )}
                                            <Stack.Item>
                                                <PrimaryButton
                                                    text={'Search'}
                                                    onClick={() => getSearchResults()}
                                                ></PrimaryButton>
                                            </Stack.Item>
                                        </Stack>
                                    </Stack> */}
                                    <Stack.Item align="auto" styles={stackItemStyles}></Stack.Item>
                                    <>
                                        <Stack.Item align="auto" styles={stackItemStyles}>
                                            <ShimmeredDetailsList
                                                selectionMode={SelectionMode.none}
                                                items={currentPageData || []}
                                                columns={ReloSpecialistColumns}
                                                onRenderItemColumn={_renderItemColumn}
                                                onColumnHeaderClick={_onColumnClick}
                                                onRenderRow={_onRenderRow}
                                                onRenderDetailsHeader={_onRenderDetailsHeader}
                                                setKey="set"
                                                layoutMode={DetailsListLayoutMode.justified}
                                                selectionPreservedOnEmptyClick={true}
                                                ariaLabelForSelectionColumn="Toggle selection"
                                                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                                                checkButtonAriaLabel="select row"
                                                ariaLabelForGrid="Relo Specialist Queue"
                                            />
                                        </Stack.Item>
                                        <Stack
                                            className="pagination-controls"
                                            horizontal
                                            horizontalAlign="end"
                                            styles={{ root: { marginTop: '10px' } }}
                                        >
                                            <Stack
                                                horizontal
                                                horizontalAlign="space-between"
                                                styles={{ root: { width: '50%' } }}
                                            >
                                                <div style={{ marginLeft: -132 }}>
                                                    <CoherencePagination {...paginationProps} />
                                                </div>
                                                <CoherencePageSize {...paginationPageSizeProps} />
                                            </Stack>
                                        </Stack>
                                    </>
                                </Stack>
                            </Stack>
                            {StackDisclaimer()}
                        </Stack>
                    ) : (
                        <UnAuthorized />
                    )}
                </>
            )}
        </>
    );

    function _onRenderDetailsHeader(headerProps: any, defaultRender: any) {
        return defaultRender({
            ...headerProps,
            styles: {
                root: {
                    selectors: {
                        '.ms-DetailsHeader-cell': {
                            whiteSpace: 'normal',
                            lineHeight: 'normal',
                            wordWrap: 'break-word',
                            height: '100%',
                        },
                        '.ms-DetailsHeader-cellTitle': {
                            height: '100%',
                            alignItems: 'center',
                            wordWrap: 'break-word',
                        },
                    },
                    height: '60px',
                },
            },
        });
    }
    function _onRenderRow(props: IDetailsRowBaseProps | undefined, defaultRender: any | undefined) {
        const customStyles: Partial<IDetailsRowStyles> = {};
        let divProps;
        if (props) {
            if (props.item?.Status !== ReloFormStatusTypes.AUTHORIZED) {
                customStyles.root = {
                    color: 'blue',
                    fontFamily: 'Segoe UI',
                    fontSize: '12px',
                    selectors: {
                        '.ms-DetailsRow-cell': {
                            whiteSpace: 'normal',
                            lineHeight: 'normal',
                            alignItems: 'center',
                        },
                        '.ms-DetailsRow-cellTitle': {
                            height: '100%',
                            alignItems: 'center',
                            wordWrap: 'break-word',
                        },
                    },
                };
            } else {
                customStyles.root = {
                    fontFamily: 'Segoe UI',
                    fontSize: '12px',
                    selectors: {
                        '.ms-DetailsRow-cell': {
                            whiteSpace: 'normal',
                            lineHeight: 'normal',
                            alignItems: 'center',
                        },
                        '.ms-DetailsRow-cellTitle': {
                            height: '100%',
                            alignItems: 'center',
                            wordWrap: 'break-word',
                        },
                    },
                };
            }
            divProps = {
                onClick: async () => {
                    let myPattern = new RegExp('(\\w*' + 'Lump Sum' + '\\w*)', 'gi');
                    let matches = props.item?.RelocationType.match(myPattern);

                    const reloFormData = await reviewFormService.getRelocationFormData(props.item?.reloId);

                    let obj = JSON.stringify({
                        fileId: props.item.fileId,
                        reloId: props.item.reloId,
                        totalEstimate: reloFormData[0].TotalEstimate ? reloFormData[0].TotalEstimate : 0,
                        isLumpSum: matches ? true : false,
                        committedStatus: reloFormData[0].CommitStatus ? reloFormData[0].CommitStatus : 'UNCOMMITTED',
                        keyword: searchKeyword,
                        redirectedFrom: 'reloSpecialistQueue',
                    });
                    history.push('/EstimatesForm', obj);
                },
                onKeyDown: (event: any) => {
                    if (event.key === 'Enter') {
                        let obj = JSON.stringify({
                            fileId: props.item.fileId,
                            reloId: props.item.reloId,
                            keyword: searchKeyword,
                            redirectedFrom: 'reloSpecialistQueue',
                        });
                        history.push('/EstimatesForm', obj);
                    }
                }
            };

            return (
                <DetailsRow {...divProps} {...props} styles={customStyles}>
                    {defaultRender && defaultRender(props)}{' '}
                </DetailsRow>
            );
        }
        return null;
    }

    function _renderItemColumn(
        item: PendingExceptionQueue | undefined,
        index: number | undefined,
        column: IColumn | undefined
    ) {
        const fieldContent = item?.[column?.fieldName as keyof PendingExceptionQueue] as string;
        const statusClass = mergeStyles({ fontSize: '12px', fontFamily: 'Segoe UI' });
        const iconClass = mergeStyles({
            fontSize: 16,
            height: 16,
            width: 16,
            paddingLeft: 10,
        });
        const classNames = mergeStyleSets({
            pendingApproval: [{ color: '#484644' }, iconClass],
            draft: [{ color: '#EC9F1D' }, iconClass],
        });
        switch (column?.key) {
            case 'Status':
                if (item?.Status === 'PA') {
                    return (
                        <Stack horizontal horizontalAlign="start">
                            <span className={statusClass}>Pending Approval</span>
                            <FontIcon
                                aria-label="PendingApproval"
                                iconName="Clock"
                                className={classNames.pendingApproval}
                            />
                        </Stack>
                    );
                }
                if (item?.Status === 'PS') {
                    return (
                        <Stack horizontal horizontalAlign="start">
                            <span className={statusClass}>Draft</span>
                            <FontIcon aria-label="Draft" iconName="WarningSolid" className={classNames.draft} />
                        </Stack>
                    );
                }

            default:
                return <span>{fieldContent}</span>;
        }
    }

    function _onColumnClick(event: React.MouseEvent<HTMLElement> | undefined, column: IColumn | undefined): void {
        let isSortedDescending = column?.isSortedDescending;
        if (column?.isSorted) {
            isSortedDescending = !isSortedDescending;
        }
        let sortedItems: IPendingExceptionQueueForm[] = _copyAndSort(
            pendingExceptionQueue,
            column?.fieldName!,
            isSortedDescending
        );

        let sortedCol = columns.map((col) => {
            col.isSorted = col.key === column?.key;

            if (col.isSorted) {
                col.isSortedDescending = isSortedDescending;
            }
            return col;
        });
        setPendingExceptionQueue(sortedItems);
        setColumns(sortedCol);
    }
}
