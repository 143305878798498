export const filterButton = {
    marginBottom: 10,
    marginRight: 10,
    backgroundColor: '#0078D7',
    font: 'Segoe UI',
    color: '#FFFFFF',
    border: '2px solid #0078D7',
};

export const searchButtons = {
    marginBottom: 10,
    backgroundColor: '#0078D7',
    font: 'Segoe UI',
    color: '#FFFFFF',
    border: '1px solid #0078D7',
};

export const singleTileContainer = {
    float: 'left' as 'left',
    marginTop: 15,
    marginLeft: 20,
};

export const FilterSection = {
    fontSize: 20,
    font: 'Segoe UI',
    color: '#000000',
    marginBottom: 15,
};

export const StatusTxt = {
    font: 'Segoe UI Bold',
    fontSize: 15,
    fontWeight: 700,
    color: '#000000',
    marginBottom: 10,
};

export const EstmtdTxt = {
    font: 'Segoe UI',
    fontSize: 20,
    color: '#000000',
    marginBottom: 5,
};

export const filterStyle = {
    marginBottom: 10,
    backgroundColor: '#0078D7',
    font: 'Segoe UI',
    color: '#FFFFFF',
    border: '2px solid #0078D7',
};
