import {
    CoherencePageSize,
    CoherencePagination,
    ICoherencePageSizeProps,
    ICoherencePaginationProps,
} from '@cseo/controls/lib/pagination';
import { ReduxContext } from '@employee-experience/common';
import { usePageTitle } from '@employee-experience/common/lib/usePageTitle';
import {
    PrimaryButton,
    DetailsListLayoutMode,
    DetailsRow,
    IColumn,
    IComboBoxOption,
    IDetailsRowBaseProps,
    IDetailsRowStyles,
    IStackItemStyles,
    IStackTokens,
    SelectionMode,
    ShimmeredDetailsList,
    Stack,
    FontIcon,
    mergeStyleSets,
    mergeStyles,
} from '@fluentui/react';
import * as React from 'react';
import * as moment from 'moment';
import { ActionType } from '../../Common/Redux/actionTypes';
import { IAppState } from '../../Common/Redux/AppState';
import { FilterPane } from '../../Components/SendBackQueue/SendBackQueueFilterPane';
import { ISendBackQueue, IRelocationFormQueues } from '../../Models/ISendBackQueue';
import { ILoggedInUserRoles, IUserRoles } from '../../Models/IUserRoles';
import { useServiceStore } from '../../RootStateContext';
import { SendBackColumns } from './SendBackColumns';
import { LoadingSpinner } from '../../Components/Shared/LoadingSpinner';
import { ReloFormStatusTypes } from '../../types/ReloFormTypes';
import { UnAuthorized } from '../../Components/QueueTable/Unauthorized';
import { RelocationStatus } from '../../Components/Shared/Relocation.types';
import { ExportToExcel } from '../../Components/Shared/ExportToExcel';
import { SendBackColumnsPage } from './sentBackColumnsPage';
import { useHistory } from 'react-router-dom';
import { StackDisclaimer } from '../../Components/Shared/DisclaimerText';

export function SentBackQueue(): React.ReactElement {
    const history = useHistory();
    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [sendBackQueue, setsendBackQueue] = React.useState<IRelocationFormQueues[]>([]);
    const { useSelector, dispatch } = React.useContext(ReduxContext);
    const [isPanelOpen, setIsPanelOpen] = React.useState<boolean>(false);
    const userdata: IUserRoles = useSelector((state: IAppState) => state.root.userReducer.userRoles);
    const userRole: ILoggedInUserRoles = useSelector((state: IAppState) => state.root.userReducer.loggedInUserRoles);
    const data = useSelector((state: IAppState) => state.root.sendBackReducer.sendBackQueues);
    const { relocationQueueService } = useServiceStore();
    const [columns, setColumns] = React.useState<IColumn[]>(SendBackColumns);
    const [IsResetHidded, setIsResetHidded] = React.useState<boolean>(true);
    const [columnsPage] = React.useState<IColumn[]>(SendBackColumnsPage);
    const [currentPageData, setCurrentPageData] = React.useState<ISendBackQueue[]>([]);

    var alias = '';
    var role = '';
    const DEFAULT_PAGE_SIZE = 10;
    var totalRecords: number = 0;
    const selectedPage = React.useRef<number>(1);
    const pageCount = React.useRef<number>(0);
    const currentPageSize = React.useRef<number>(DEFAULT_PAGE_SIZE);
    let queueItems: ISendBackQueue[] = [];
    const reloFilterRef = React.useRef<any>();
    const [exportQueueItems, setExportQueueItems] = React.useState<ISendBackQueue[]>([]);

    function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
        const key = columnKey as keyof T;
        return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    }

    if (sendBackQueue && sendBackQueue.length > 0) {
        queueItems = sendBackQueue.map((row) => {
            return {
                RecordID: row.RecordID,
                FullName: row.FullName,
                RelocationSpecialist: row.RelocationSpecialist,
                LatestSubmissionDate: row.LastUpdated ? new Date(row.LastUpdated) : "",
                Destination: row.Destination,
                Departure: row.Departure,
                EstimatedStartDate: row.EstimatedStartDate ? new Date(row.EstimatedStartDate) : "",
                RelocationType: row.RelocationType,
                CandidateID: row.CandidateID,
                Status: row.Status,
                DateSubmitted: row.SubmittedDate ? new Date(row.SubmittedDate) : "",
                SubmittedBy: row.SubmittedBy,
                SendBackComments: row.SendBackComments,
                CurrentEmail: row.CurentEmail,
                StaffingManagerAlias: row.StaffingManagerAlias,
            };
        });
    }

    const fetchData = (pageSize: number, newIndex: number = 0): void => {
        totalRecords = queueItems.length;
        let data: ISendBackQueue[] = [];
        for (let i = newIndex; i < Math.min(pageSize + newIndex, queueItems.length); ++i) {
            let row: ISendBackQueue = queueItems[i];
            data.push(row);
        }
        pageCount.current = Math.ceil(totalRecords / currentPageSize.current);

        setCurrentPageData(data);
    };

    const onPageChange = (newPageNumber: number): void => {
        if (newPageNumber !== selectedPage.current) {
            selectedPage.current = newPageNumber;
            fetchData(currentPageSize.current, (newPageNumber - 1) * currentPageSize.current);
        }
    };

    const onPageSizeChange = (newPageSize: string | number): void => {
        currentPageSize.current = newPageSize as number;
        selectedPage.current = 1;
        fetchData(currentPageSize.current);
    };
    const paginationProps: ICoherencePaginationProps = {
        pageCount: pageCount.current,
        selectedPage: selectedPage.current,
        previousPageAriaLabel: 'previous page',
        nextPageAriaLabel: 'next page',
        inputFieldAriaLabel: 'page number',
        onPageChange: onPageChange,
    };
    let pageSizeList: IComboBoxOption[] = [];
    for (let index = 10; index <= 50; index += 10) {
        if (queueItems.length < index) {
            pageSizeList.push({ key: queueItems.length, text: 'ALL' });
            break;
        }
        pageSizeList.push({ key: index, text: index.toString() });
    }

    const paginationPageSizeProps: ICoherencePageSizeProps = {
        pageSize: currentPageSize.current,
        pageSizeList: [
            { key: 10, text: '10' },
            { key: 20, text: '20' },
            { key: 50, text: '50' },
            { key: 100, text: '100' },
            { key: 200, text: '200' },
            { key: 300, text: '300' },
            { key: 400, text: '400' },
            { key: 500, text: '500' },
        ] as IComboBoxOption[],
        comboBoxAriaLabel: 'select page size',
        onPageSizeChange: onPageSizeChange,
    };

    //todo: there is an issue herer where there are multiple calls going on to apoi hae to fix state ~sadatla
    React.useEffect(() => {
        (async function getSendBackQueue() {
            if (userdata.Alias !== '' && userdata.Roles.length > 0) {
                alias = userdata.Alias;
                role = userdata?.Roles[0];
                if (userRole.GloMoCo && userRole.ReloSpecialist) {
                    role = 'glomoco';
                }
                const reportType = 'sendback';
                var queuedata: any = await relocationQueueService.getQueueRecords(role, reportType, alias);

                //todo: we should not add these many reord to store as it is exceedeing the setLimit whihc has console issue
                //(rror storing data DOMException: Failed to execute 'setItem' on 'Storage': Setting the value of 'persist:root' exceeded the quota.)
                dispatch({
                    type: ActionType.SET_SENDBACKQUEUE,
                    data: queuedata,
                });
                setsendBackQueue(queuedata);
                setLoaded(true);
            }
        })();
    }, []);
    const verticalGapStackTokens: IStackTokens = {
        childrenGap: 10,
        padding: 10,
    };

    const _closePanel = () => {
        setIsPanelOpen(false);
    };

    React.useEffect(() => {
        fetchData(currentPageSize.current);
        if (sendBackQueue && sendBackQueue.length > 0) {
            let tempExportQueueItems = sendBackQueue.map((item) => {
                return {
                    ...item,
                    DateSubmitted: item.SubmittedDate? new Date(item.SubmittedDate):"",
                    EstimatedStartDate: item.EstimatedStartDate ? new Date(item.EstimatedStartDate) : "",
                }
            })
            setExportQueueItems(tempExportQueueItems);
        } else {
            setExportQueueItems([]);
        }

    }, [sendBackQueue]);

    const stackItemStyles: IStackItemStyles = {
        root: {
            padding: 5,
        },
    };
    usePageTitle(`SendBack Queue - ${__APP_NAME__}`);

    React.useEffect(() => {
        let inp = document.querySelectorAll('input');
        let btn = document.querySelectorAll('button');
        inp.forEach((item) => {
            item.setAttribute('tabindex', '0');
            if (item.getAttribute('aria-label') === "page number") {
                item.setAttribute('role', 'textbox');
            }
            if (item.getAttribute('type') === "checkbox") {
                item.setAttribute('role', 'checkbox');
            }
        })
        btn.forEach((item) => {
            if (item.getAttribute('aria-label') === 'previous page') {
                item.setAttribute('role', 'button');
                item?.parentElement?.parentElement?.classList.add("wrap-btn");
                item?.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.classList.add("wrap-btn");
            }
            if (item.getAttribute('aria-label') === 'next page') {
                item.setAttribute('role', 'button');
            }
            if (item.getAttribute('aria-hidden') == 'true') {
                item.setAttribute('disabled', 'true');
            }
        })
    })

    return (
        <>
            {!loaded ? (
                <LoadingSpinner />
            ) : (
                <>
                    {userRole.GloMoCo || userRole.ReloAdmin ? (
                        <Stack tokens={verticalGapStackTokens}>
                            <Stack.Item align="auto" styles={stackItemStyles}>
                                <h1>SendBack Queue</h1>
                                {/* <img src={photo} /> */}
                            </Stack.Item>
                            <Stack horizontal styles={stackItemStyles}>
                                {(
                                    <Stack.Item align="center">
                                        <PrimaryButton
                                            text="Open Filter"
                                            onClick={() => {
                                                setIsPanelOpen(true);
                                            }}
                                            ariaLabel="Filter Sendback Queue"
                                            role="button"
                                            tabIndex={0}
                                        ></PrimaryButton>
                                        <FilterPane
                                            isOpen={isPanelOpen}
                                            _closePanel={_closePanel}
                                            ref={reloFilterRef}
                                            apiData={[data]}
                                            handleFilterSubmit={handleFilterSubmit}
                                            ShowResetButton={ShowResetButton}
                                            HideResetButton={HideResetButton}
                                        />
                                    </Stack.Item>
                                )}
                                <Stack.Item align="auto" styles={stackItemStyles} hidden={IsResetHidded}>
                                    <PrimaryButton
                                        text="Reset Filter"
                                        onClick={() => {
                                            reloFilterRef?.current?.ResetFilterCallFromMyRelo();
                                            setIsResetHidded(true);
                                        }}
                                        ariaLabel="Filter Relo Queue"
                                        role="button"
                                        tabIndex={0}
                                    ></PrimaryButton>
                                </Stack.Item>
                                <Stack.Item align="auto" styles={stackItemStyles}>
                                    <ExportToExcel
                                        rowsData={exportQueueItems}
                                        colsData={columns}
                                        fileName="SentBackQueue.xlsx"
                                    />
                                </Stack.Item>
                            </Stack>
                            <Stack.Item align="auto" styles={stackItemStyles}></Stack.Item>
                            <>
                                <Stack.Item align="auto" styles={stackItemStyles}>
                                    <ShimmeredDetailsList
                                        selectionMode={SelectionMode.none}
                                        items={currentPageData || []}
                                        columns={columnsPage}
                                        onRenderItemColumn={_renderItemColumn}
                                        onColumnHeaderClick={_onColumnClick}
                                        onItemInvoked={_onItemInvoked}
                                        onRenderRow={_onRenderRow}
                                        onRenderDetailsHeader={_onRenderDetailsHeader}
                                        setKey="set"
                                        layoutMode={DetailsListLayoutMode.justified}
                                        selectionPreservedOnEmptyClick={true}
                                        ariaLabelForSelectionColumn="Toggle selection"
                                        ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                                        checkButtonAriaLabel="select row"
                                        ariaLabelForGrid="Sent Back Queue"
                                    />
                                </Stack.Item>
                                <Stack
                                    className="pagination-controls"
                                    horizontal
                                    horizontalAlign="end"
                                    styles={{ root: { marginTop: '10px' } }}
                                >
                                    <Stack
                                        horizontal
                                        horizontalAlign="space-between"
                                        className="ml-sm-0 pg-c-sm"
                                    >
                                        <div>
                                            <CoherencePagination {...paginationProps} />
                                        </div>
                                        <CoherencePageSize {...paginationPageSizeProps} />
                                    </Stack>
                                </Stack>
                            </>
                            {StackDisclaimer()}
                        </Stack>
                    ) : (
                        <UnAuthorized />
                    )}
                </>
            )}
        </>
    );

    function _onItemInvoked(item: ISendBackQueue | undefined, index: number | undefined) {
        //adding cases
        if (item?.Status === ReloFormStatusTypes.RESUBMITTED || item?.Status === ReloFormStatusTypes.SUBMITTED) {
            //Need to Redirect to Form page
        }
        if (item?.Status == ReloFormStatusTypes.SENDBACK) {
            let parentStatus = item?.Status.toString().toUpperCase();
            if (item?.Status.toUpperCase() == 'RETURN FOR EDITS') {
                parentStatus = 'SENDBACK';
            }
            let obj = JSON.stringify({
                recordID: item?.RecordID,
                recordStatus: parentStatus,
                ActionType: 'SENDBACK',
            });
            history.push('/ReviewForm', obj);
        }
    }

    function _onRenderDetailsHeader(headerProps: any, defaultRender: any) {
        return defaultRender({
            ...headerProps,
            styles: {
                root: {
                    selectors: {
                        '.ms-DetailsHeader-cell': {
                            whiteSpace: 'normal',
                            lineHeight: 'normal',
                            wordWrap: 'break-word',
                            height: "100%",
                        },
                        '.ms-DetailsHeader-cellTitle': {
                            height: '100%',
                            alignItems: 'center',
                            wordWrap: 'break-word',
                        },
                    },
                    height: "60px"
                },
            },
        });
    }

    function _onRenderRow(props: IDetailsRowBaseProps | undefined, defaultRender: any | undefined) {
        const customStyles: Partial<IDetailsRowStyles> = {};
        let divProps;
        if (props) {
            if (props.item?.Status !== ReloFormStatusTypes.SUBMITTED) {
                customStyles.root = {
                    color: 'blue',
                    selectors: {
                        '.ms-DetailsRow-cell': {
                            whiteSpace: 'normal',
                            lineHeight: 'normal',
                        },
                        '.ms-DetailsRow-cellTitle': {
                            height: '100%',
                            alignItems: 'center',
                            wordWrap: 'break-word',
                        },
                    },
                };
                divProps = {
                    onClick: () => {
                        let parentStatus = props.item?.Status;
                        if (props.item?.Status.toUpperCase() == 'RETURN FOR EDITS') {
                            parentStatus = 'SENDBACK';
                        }
                        let obj = JSON.stringify({
                            recordID: props.item?.RecordID,
                            recordStatus: parentStatus,
                            ActionType: 'SENDBACK',
                        });
                        history.push('/ReviewForm', obj);
                    },
                };
            }

            return (
                <DetailsRow {...divProps} {...props} styles={customStyles}>
                    {defaultRender && defaultRender(props)}{' '}
                </DetailsRow>
            );
        }
        return null;
    }

    function _renderItemColumn(
        item: ISendBackQueue | undefined,
        index: number | undefined,
        column: IColumn | undefined
    ) {
        const fieldContent = item?.[column?.fieldName as keyof ISendBackQueue] as string;


        const statusClass = mergeStyles({ fontSize: '12px', fontFamily: 'Segoe UI' })

        const iconClass = mergeStyles({
            fontSize: 16,
            height: 16,
            width: 16,
            paddingLeft: 10,

        });

        const classNames = mergeStyleSets({
            submitted: [{ color: '#7BA811' }, iconClass],
            error: [{ color: '#DF0008' }, iconClass],
            warning: [{ color: '#EC9F1D' }, iconClass],
        });

        switch (column?.key) {
            case 'Status':
                if (item?.Status !== ReloFormStatusTypes.SUBMITTED) {
                    return (
                        <Stack horizontal horizontalAlign='start'>
                            <span className={statusClass}>{RelocationStatus.SENTBACK}</span>
                            <FontIcon aria-label={RelocationStatus.SENTBACK} iconName="WarningSolid" className={classNames.warning} />
                        </Stack>

                    );
                }
                if (item?.Status === ReloFormStatusTypes.SUBMITTED) {
                    return (
                        <Stack horizontal horizontalAlign='start'>
                            <span className={statusClass}>{RelocationStatus.READYTOINITIATE}</span>
                            <FontIcon aria-label={RelocationStatus.READYTOINITIATE} iconName="SkypeCircleCheck" className={classNames.submitted} />
                        </Stack>


                    );
                }

            case 'EstimatedStartDate':
                if (item?.EstimatedStartDate === null) {
                    return <span>{fieldContent}</span>;
                } else {
                    return moment(item?.EstimatedStartDate).format('MM/DD/YYYY');
                }

            case 'LatestSubmissionDate':
                if (item?.LatestSubmissionDate === null) {
                    return <span>{fieldContent}</span>;
                } else {
                    return moment(item?.LatestSubmissionDate).format('MM/DD/YYYY');
                }

            default:
                return <span>{fieldContent}</span>;
        }
    }

    function handleFilterSubmit(filteredData: any[]) {
        setsendBackQueue(filteredData);
    }
    function ShowResetButton() {
        setIsResetHidded(false);
    }
    function HideResetButton() {
        setIsResetHidded(true);
    }
    function _onColumnClick(event: React.MouseEvent<HTMLElement> | undefined, column: IColumn | undefined): void {
        let isSortedDescending = column?.isSortedDescending;
        if (column?.isSorted) {
            isSortedDescending = !isSortedDescending;
        }
        let sortedItems: IRelocationFormQueues[] = _copyAndSort(sendBackQueue, column?.fieldName!, isSortedDescending);

        let sortedCol = columnsPage.map((col) => {
            col.isSorted = col.key === column?.key;

            if (col.isSorted) {
                col.isSortedDescending = isSortedDescending;
            }
            return col;
        });
        setsendBackQueue(sortedItems);
        setColumns(sortedCol);
    }
}
