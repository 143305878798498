import { useHistory, useLocation } from 'react-router-dom';


export const NavConfig = () => {
    const history = useHistory();
    const location = useLocation();
    const reloAdminNavConfig = [
        {
            key: 'Main',
            links: [
                {
                    key: 1,
                    name: 'Home',
                    icon: 'Home',
                    onClick: () => {
                        history.push('/');
                    },
                    isSelected: location?.pathname === '/'
                },
                {
                    key: 20,
                    name: 'Queues',
                    icon: 'Emoji',
                    links: [
                        {
                            key: 29,
                            name: 'Start New Relocation',
                            onClick: () => {
                                history.push('/StartNewRelocation');
                            },
                            isSelected: location?.pathname === '/StartNewRelocation'
                        },
                        {
                            key: 24,
                            name: 'My Relo Queue',
                            onClick: () => {
                                history.push('/MyReloQueue');
                            },
                            isSelected: location?.pathname === '/MyReloQueue'
                        },
                        {
                            key: 25,
                            name: 'Glomocoe Queue',
                            onClick: () => {
                                history.push('/GlomocoeQueue');
                            },
                            isSelected: location?.pathname === '/GlomocoeQueue'
                        },
                        {
                            key: 22,
                            name: 'ReloCamp Queue',
                            onClick: () => {
                                history.push('/ReloCampQueue');
                            },
                            isSelected: location?.pathname === '/ReloCampQueue'
                        },
                        {
                            key: 23,
                            name: 'Sent Back Queue',
                            onClick: () => {
                                history.push('/SentBackQueue');
                            },
                            isSelected: location?.pathname === '/SentBackQueue'
                        },
                        {
                            key: 21,
                            name: 'Estimates Queue',
                            onClick: () => {
                                history.push('/EstimatesQueue');
                            },
                            isSelected: location?.pathname === '/EstimatesQueue'
                        },
                        {
                            key: 28,
                            name: 'ReloSpecialist Queue',
                            onClick: () => {
                                history.push('/ReloSpecialistQueue');
                            },
                            isSelected: location?.pathname === '/ReloSpecialistQueue'
                        },


                        {
                            key: 26,
                            name: 'Pending Approval Queue',
                            onClick: () => {
                                history.push('/PendingExceptionsQueue');
                            },
                            isSelected: location?.pathname === '/PendingExceptionsQueue'
                        },
                        {
                            key: 27,
                            name: 'Exception Queue',
                            onClick: () => {
                                history.push('/ExceptionQueue');
                            },
                            isSelected: location?.pathname === '/ExceptionQueue'
                        },

                    ],
                },
                {
                    key: 30,
                    name: 'Actions',
                    icon: 'MountainClimbing',
                    links: [
                        {
                            key: 31,
                            name: 'Reassign Records',
                            onClick: () => {
                                history.push('/ReassignRecords');
                            },
                            isSelected: location?.pathname === '/ReassignRecords'
                        },
                        {
                            key: 32,
                            name: 'Upload Actuals Report',
                            onClick: () => {
                                history.push('/UploadActualsReport');
                            },
                            isSelected: location?.pathname === '/UploadActualsReport'
                        },
                        {
                            key: 33,
                            name: 'Upload Reassign Report',
                            onClick: () => {
                                history.push('/UploadReassignReport');
                            },
                            isSelected: location?.pathname==='/UploadReassignReport'
                        },
                    ],
                },
                {
                    key: 40,
                    name: 'Reports',
                    icon: 'ExcelLogo',
                    links: [
                        {
                            key: 41,
                            name: 'Export to Finance',
                            onClick: () => {
                                history.push('/ExportToWebJEReport');
                            },
                            isSelected: location?.pathname === '/ExportToWebJEReport'
                        },
                        {
                            key: 42,
                            name: 'E2A Liability Trend',
                            onClick: () => {
                                history.push('/E2ALiabilityTrend');
                            },
                            isSelected: location?.pathname === '/E2ALiabilityTrend'
                        },
                        {
                            key: 43,
                            name: 'Send Back Report',
                            onClick: () => {
                                history.push('/SendBackReport');
                            },
                            isSelected: location?.pathname === '/SendBackReport'
                        },
                        {
                            key: 44,
                            name: 'Sent To File Setup Queue',
                            onClick: () => {
                                history.push('/SentToFileSetup');
                            },
                            isSelected: location?.pathname === '/SentToFileSetup'
                        },
                    ],
                },
            ],
        },
    ];

    const glomocoeNavConfig = [
        {
            key: 'Main',
            links: [
                {
                    key: 1,
                    name: 'Home',
                    icon: 'Home',
                    onClick: () => {
                        history.push('/');
                    },
                    isSelected: location?.pathname ==='/'
                },
                {
                    key: 20,
                    name: 'Queues',
                    icon: 'Emoji',
                    links: [
                        {
                            key: 24,
                            name: 'My Relo Queue',
                            onClick: () => {
                                history.push('/MyReloQueue');
                            },
                            isSelected: location?.pathname === '/MyReloQueue'
                        },
                        {
                            key: 25,
                            name: 'Glomocoe Queue',
                            onClick: () => {
                                history.push('/GlomocoeQueue');
                            },
                            isSelected: location?.pathname ==='/GlomocoeQueue'
                        },
                        {
                            key: 22,
                            name: 'ReloCamp Queue',
                            onClick: () => {
                                history.push('/ReloCampQueue');
                            },
                            isSelected: location?.pathname ==='/ReloCampQueue'
                        },
                        {
                            key: 23,
                            name: 'Sent Back Queue',
                            onClick: () => {
                                history.push('/SentBackQueue');
                            },
                            isSelected: location?.pathname ==='/SentBackQueue'
                        },

                    ],
                },
                {
                    key: 30,
                    name: 'Actions',
                    icon: 'MountainClimbing',
                    links: [
                        {
                            key: 31,
                            name: 'Reassign Records',
                            onClick: () => {
                                history.push('/ReassignRecords');
                            },
                            isSelected: location?.pathname ==='/ReassignRecords'
                        },
                        {
                            key: 33,
                            name: 'Reassign Report',
                            onClick: () => {
                                history.push('/UploadReassignReport');
                            },
                            isSelected: location?.pathname==='/UploadReassignReport'
                        },
                    ],
                },
                {
                    key: 40,
                    name: 'Reports',
                    icon: 'ExcelLogo',
                    links: [
                        {
                            key: 43,
                            name: 'Send Back Report',
                            onClick: () => {
                                history.push('/SendBackReport');
                            },
                            isSelected: location?.pathname ==='/SendBackReport'
                        },
                        {
                            key: 44,
                            name: 'Sent To File Setup Queue',
                            onClick: () => {
                                history.push('/SentToFileSetup');
                            },
                            isSelected: location?.pathname === '/SentToFileSetup'
                        },
                    ],
                },
            ],
        },
    ];

    const reloAnalystNavConfig = [
        {
            key: 'Main',
            links: [
                {
                    key: 1,
                    name: 'Home',
                    icon: 'Home',
                    onClick: () => {
                        history.push('/');
                    },
                    isSelected: location?.pathname ==='/'
                },
                {
                    key: 20,
                    name: 'Queues',
                    icon: 'Emoji',
                    links: [
                        {
                            key: 21,
                            name: 'Estimates Queue',
                            onClick: () => {
                                history.push('/EstimatesQueue');
                            },
                            isSelected: location?.pathname ==='/EstimatesQueue'
                        },
                        {
                            key: 28,
                            name: 'ReloSpecialist Queue',
                            onClick: () => {
                                history.push('/ReloSpecialistQueue');
                            },
                            isSelected: location?.pathname==='/ReloSpecialistQueue'
                        },
                        {
                            key: 27,
                            name: 'Exception Queue',
                            onClick: () => {
                                history.push('/ExceptionQueue');
                            },
                            isSelected: location?.pathname==='/ExceptionQueue'
                        },
                    ],
                },
                {
                    key: 30,
                    name: 'Actions',
                    icon: 'MountainClimbing',
                    links: [
                        {
                            key: 32,
                            name: 'Upload Actuals Report',
                            onClick: () => {
                                history.push('/UploadActualsReport');
                            },
                            isSelected: location?.pathname==='/UploadActualsReport'
                        },
                        {
                            key: 41,
                            name: 'Export to Finance',
                            onClick: () => {
                                history.push('/ExportToWebJEReport');
                            },
                            isSelected: location?.pathname==='/ExportToWebJEReport'
                        },
                    ],
                },
            ],
        },
    ];

    const reloSpecialistNavConfig = [
        {
            key: 'Main',
            links: [
                {
                    key: 1,
                    name: 'Home',
                    icon: 'Home',
                    onClick: () => {
                        history.push('/');
                    },
                    isSelected: location?.pathname==='/'
                },
                {
                    key: 20,
                    name: 'Queues',
                    icon: 'Emoji',
                    links: [
                        {
                            key: 28,
                            name: 'ReloSpecialist Queue',
                            onClick: () => {
                                history.push('/ReloSpecialistQueue');
                            },
                            isSelected: location?.pathname==='/ReloSpecialistQueue'
                        },
                        {
                            key: 27,
                            name: 'Exception Queue',
                            onClick: () => {
                                history.push('/ExceptionQueue');
                            },
                            isSelected: location?.pathname==='/ExceptionQueue'
                        },
                    ],
                },
            ],
        },
    ];

    const staffingNavConfig = [
        {
            key: 'Main',
            links: [
                {
                    key: 1,
                    name: 'Home',
                    icon: 'Home',
                    onClick: () => {
                        history.push('/');
                    },
                    isSelected: location?.pathname==='/'
                },
                {
                    key: 20,
                    name: 'Queues',
                    icon: 'Emoji',
                    links: [
                        {
                            key: 29,
                            name: 'Start New Relocation',
                            onClick: () => {
                                history.push('/StartNewRelocation');
                            },
                            isSelected: location?.pathname==='/StartNewRelocation'
                        },
                        {
                            key: 24,
                            name: 'My Relo Queue',
                            onClick: () => {
                                history.push('/MyReloQueue');
                            },
                            isSelected: location?.pathname==='/MyReloQueue'
                        },
                        {
                            key: 26,
                            name: 'Pending Approval Queue',
                            onClick: () => {
                                history.push('/PendingExceptionsQueue');
                            },
                            isSelected: location?.pathname==='/PendingExceptionsQueue'
                        },                      

                    ],
                },
                {
                    key: 30,
                    name: 'Actions',
                    icon: 'MountainClimbing',
                    links: [
                        {
                            key: 31,
                            name: 'Reassign Records',
                            onClick: () => {
                                history.push('/ReassignRecords');
                            },
                            isSelected: location?.pathname ==='/ReassignRecords'
                        },
                    ],
                },
            ],
        },
    ];

    const guestNavConfig = [
        {
            key: 'Main',
            links: [
                {
                    key: 1,
                    name: 'Home',
                    icon: 'Home',
                    onClick: () => {
                        history.push('/');
                    },
                    isSelected: location?.pathname==='/'
                },
                {
                    key: 20,
                    name: 'Queues',
                    icon: 'Emoji',
                    links: [                        
                        {
                            key: 26,
                            name: 'Pending Approval Queue',
                            onClick: () => {
                                history.push('/PendingExceptionsQueue');
                            },
                            isSelected: location?.pathname==='/PendingExceptionsQueue'
                        },

                    ],
                },
            ],
        },        
    ];
   
   
    const noRoleNavConfig = [
        {
            key: 'Main',
            links: [],
        },
        
    ];
    const glomocoSpecialisttNavConfig = [
        {
            key: 'Main',
            links: [
                {
                    key: 1,
                    name: 'Home',
                    icon: 'Home',
                    onClick: () => {
                        history.push('/');
                    },
                    isSelected: location?.pathname==='/'
                },
                {
                    key: 20,
                    name: 'Queues',
                    icon: 'Emoji',
                    links: [
                        {
                            key: 24,
                            name: 'My Relo Queue',
                            onClick: () => {
                                history.push('/MyReloQueue');
                            },
                            isSelected: location?.pathname==='/MyReloQueue'
                        },
                        {
                            key: 25,
                            name: 'Glomocoe Queue',
                            onClick: () => {
                                history.push('/GlomocoeQueue');
                            },
                            isSelected: location?.pathname==='/GlomocoeQueue'
                        },
                        {
                            key: 22,
                            name: 'ReloCamp Queue',
                            onClick: () => {
                                history.push('/ReloCampQueue');
                            },
                            isSelected: location?.pathname==='/ReloCampQueue'
                        },
                        {
                            key: 23,
                            name: 'Sent Back Queue',
                            onClick: () => {
                                history.push('/SentBackQueue');
                            },
                            isSelected: location?.pathname==='/SentBackQueue'
                        },
                        
                        {
                            key: 28,
                            name: 'ReloSpecialist Queue',
                            onClick: () => {
                                history.push('/ReloSpecialistQueue');
                            },
                            isSelected: location?.pathname==='/ReloSpecialistQueue'
                        },
                        {
                            key: 27,
                            name: 'Exception Queue',
                            onClick: () => {
                                history.push('/ExceptionQueue');
                            },
                            isSelected: location?.pathname==='/ExceptionQueue'
                        },
                    ],
                },
                {
                    key: 30,
                    name: 'Actions',
                    icon: 'MountainClimbing',
                    links: [     
                        {
                            key: 31,
                            name: 'Reassign Records',
                            onClick: () => {
                                history.push('/ReassignRecords');
                            },
                            isSelected: location?.pathname==='/ReassignRecords'
                        }, 
                        {
                            key: 33,
                            name: 'Bulk Reassign Records',
                            onClick: () => {
                                history.push('/UploadReassignReport');
                            },
                            isSelected: location?.pathname==='/UploadReassignReport'
                        },
                    ],
                },
                {
                    key: 40,
                    name: 'Reports',
                    icon: 'ExcelLogo',
                    links: [
                        {
                            key: 43,
                            name: 'Send Back Report',
                            onClick: () => {
                                history.push('/SendBackReport');
                            },
                            isSelected: location?.pathname==='/SendBackReport'
                        },
                        {
                            key: 44,
                            name: 'Sent To File Setup Queue',
                            onClick: () => {
                                history.push('/SentToFileSetup');
                            },
                            isSelected: location?.pathname === '/SentToFileSetup'
                        },
                    ],
                },
            ],
        },
    ];

    return {
        reloAdminNavConfig,
        glomocoeNavConfig,
        reloAnalystNavConfig,
        reloSpecialistNavConfig,
        staffingNavConfig,
        guestNavConfig,
        glomocoSpecialisttNavConfig,
        noRoleNavConfig
    }
}