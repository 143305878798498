import { Navigation } from './Components/Navigation/Navigation';
import * as Styled from './App.styled';
import { CoherenceCustomizations } from '@cseo/styles';
import { IUser, ReduxContext } from '@employee-experience/common';
import { Header } from '@employee-experience/shell';
import { Footer } from '@employee-experience/shell/lib/Footer';
import { ShellStyles } from '@employee-experience/shell/lib/Shell';
import { Link, Stack } from '@fluentui/react';
import { Customizer } from 'office-ui-fabric-react';
import * as React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import { ActionType } from './Common/Redux/actionTypes';
import { LoadingSpinner } from './Components/Shared/LoadingSpinner';
import * as navconfigs from './navConfig';
import { ServiceProvider, useServiceStore } from './RootStateContext';
import { Routes } from './Routes';
import { ShellWithStore } from './ShellWithStore';
import { useHeaderConfig } from './useHeaderConfig';
import { ILoggedInUserRoles } from './Models/IUserRoles';
import { initializeOBFeedback } from '@cseo/controls/lib/OfficeBrowerFeedback';
import { IAppState } from './Common/Redux/AppState';
import { useLoginOnStartupBase, useMockLoginOnStartup } from './Environment/useLoginOnStartup';

initializeOBFeedback(
    __OCV_CONFIG__.AppId,
    __OCV_CONFIG__.Env,
    __OCV_CONFIG__.ScriptUrl,
    __OCV_CONFIG__.StyleUrl,
    __OCV_CONFIG__.IntlUrl,
    __OCV_CONFIG__.UserVoiceUrl
);
function App(): React.ReactElement {
    //validating login using MSAL
    const [user] =
        __IS_ACCESSIBILITY_TESTING__ == 'true'
            ? (useMockLoginOnStartup() as [IUser | null])
            : (useLoginOnStartupBase() as [IUser | null]);
    const { dispatch, useSelector } = React.useContext(ReduxContext);
    const userRole: ILoggedInUserRoles = useSelector((state: IAppState) => state.root.userReducer.loggedInUserRoles);

    const headerConfig = useHeaderConfig();
    const NavConfig = navconfigs.NavConfig();

    const { loginService } = useServiceStore();
    const [navConfig, setNavConfig] = React.useState<any>(NavConfig.noRoleNavConfig);
    const [isNavCollapsed, setIsNavCollapsed] = React.useState<boolean>(false);

    React.useEffect(() => {
        (async function login() {
            const loggedInUser = await loginService.getLoginUser();
            //dispatching an action and updating the state to set the userdata
            dispatch({
                type: ActionType.SET_USERS,
                data: loggedInUser,
            });

            let menuBar = document.querySelectorAll('div');
            let navBar = document.querySelectorAll('.ms-FocusZone');

            menuBar.forEach((item) => {
                if (item.getAttribute('role') == 'menu') {
                    item.setAttribute('title', 'menubar');
                    item.querySelectorAll('.ms-OverflowSet-item').forEach((listItems) => {
                        listItems.setAttribute('role', 'menuitem');
                        listItems.getElementsByTagName('button')[0].setAttribute('role', 'button');
                    });
                }
            });
            navBar.forEach((item) => {
                item.setAttribute('title', 'navbar');
                item.querySelectorAll('li').forEach((listItems) => {
                    listItems.setAttribute('role', 'menuitem');
                    listItems.querySelectorAll('.ms-TooltipHost').forEach((x) => {
                        x.setAttribute('role', 'menu');
                        x.setAttribute('title', 'menubar');
                    });
                });
            });

            let header = document.querySelectorAll('a');
            header.forEach((item) => {
                if (item.getAttribute('aria-label') === 'GMAT') {
                    item.setAttribute('role', 'link');
                    item.setAttribute('id', 'gmat-head');
                    item.setAttribute('tabindex', '0');
                    item.setAttribute('aria-label', 'GMAT Home Link');
                }
                if (item.innerText === 'Skip to main content') {
                    item.setAttribute('id', 'skiplink');
                    item.setAttribute('href', '#main');
                }
            });

            if (navConfig !== undefined || navConfig !== null) {
                if (loggedInUser.Roles.length === 1) {
                    if (userRole.ReloAdmin) setNavConfig(NavConfig.reloAdminNavConfig);
                    if (userRole.ReloAnalyst) setNavConfig(NavConfig.reloAnalystNavConfig);
                    if (userRole.ReloSpecialist) setNavConfig(NavConfig.reloSpecialistNavConfig);
                    if (userRole.GloMoCo) setNavConfig(NavConfig.glomocoeNavConfig);
                    if (userRole.Staffing) setNavConfig(NavConfig.staffingNavConfig);
                    if (userRole.Guest) setNavConfig(NavConfig.guestNavConfig);
                    if (userRole.NOROLE) setNavConfig(NavConfig.noRoleNavConfig);
                } else {
                    if (userRole.GloMoCo && userRole.ReloSpecialist)
                        setNavConfig(NavConfig.glomocoSpecialisttNavConfig);
                }
            }
        })();
    }, [user]);

    React.useEffect(() => {
        if (location?.pathname && userRole) {
            if (userRole.ReloAdmin) setNavConfig(NavConfig.reloAdminNavConfig);
            if (userRole.ReloAnalyst) setNavConfig(NavConfig.reloAnalystNavConfig);
            if (userRole.ReloSpecialist) setNavConfig(NavConfig.reloSpecialistNavConfig);
            if (userRole.GloMoCo) setNavConfig(NavConfig.glomocoeNavConfig);
            if (userRole.Staffing) setNavConfig(NavConfig.staffingNavConfig);
            if (userRole.Guest) setNavConfig(NavConfig.guestNavConfig);
            if (userRole.NOROLE) setNavConfig(NavConfig.noRoleNavConfig);
            if (userRole.GloMoCo && userRole.ReloSpecialist) setNavConfig(NavConfig.glomocoSpecialisttNavConfig);
        }
    }, [location.pathname]);

    function _onNavCollapsed(isCollapsed?: boolean) {
        if (isCollapsed !== undefined && isCollapsed !== null) {
            setIsNavCollapsed(isCollapsed);
        }
    }

    if (!user) {
        return <LoadingSpinner />;
    }

    return (
        <>
            {navConfig !== null && navConfig !== undefined && (
                <Customizer {...CoherenceCustomizations}>
                    <ShellStyles />
                    {/* todo: have to update this header to our custom header */}
                    <Header {...headerConfig} />

                    <Navigation
                        groups={navConfig}
                        appName={'GMAT Quick Links'}
                        defaultIsNavCollapsed={true}
                        onNavCollapsed={_onNavCollapsed}
                    />

                    <Styled.Main className={isNavCollapsed ? 'navCollapsed' : 'navExpanded'} id="main" tabIndex={-1}>
                        <Routes />
                    </Styled.Main>

                    <Footer>
                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                            <Stack.Item>
                                <Link
                                    href="https://msdpn.azurewebsites.net"
                                    target="_blank"
                                    styles={{ root: { color: '0078d4', backgroundColor: 'ffffff' } }}
                                >
                                    Data Protection Notice
                                </Link>
                            </Stack.Item>
                            <Stack.Item>
                                <Link
                                    href="https://microsoft.sharepoint.com/sites/hrw"
                                    target="_blank"
                                    styles={{ root: { color: '0078d4', backgroundColor: 'ffffff' } }}
                                >
                                    Help
                                </Link>
                            </Stack.Item>
                        </Stack>
                    </Footer>
                </Customizer>
            )}
        </>
    );
}

render(
    <ShellWithStore>
        <ServiceProvider>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </ServiceProvider>
    </ShellWithStore>,
    document.getElementById('app')
);
