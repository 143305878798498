export enum RelocationType {
    PRE_HIRE_SERVICE = 'Pre Hire Service',
    US_DOMESTIC_HOMEOWNER = 'US Domestic Homeowner',
    US_DOMESTIC_RENTER = 'US Domestic Renter',
    US_INBOUND_GLOBAL_UNIVERSITY = 'US Inbound Global University',
}

export enum RelocationStatus {
    AUTHORIZED = 'Authorized',
    RETURNED = 'Returned For Edits',
    SUBMITTED = 'Submitted',
    READYTOINITIATE = 'Ready To Initiate',
    READYTOREVIEW = 'Ready To Review',
    SENTBACK = 'Sent Back',
}
