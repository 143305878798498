import * as React from 'react';
import { DetailsList, IColumn, SelectionMode, DetailsListLayoutMode } from '@fluentui/react/lib/DetailsList';
import { TextField } from '@fluentui/react/lib/TextField';
import { LumpSumRelocation } from '../../Models/IReviewForm';
export interface LumpSumPanelProps {
    relocationPolicyID: number;
    relocationType: string;
    updateLumSumNetCash: Function;
    updateLumSumAccuralCost: Function;
    lumpSumRelocation: LumpSumRelocation;
}

export const LumpSumPanel: React.FC<LumpSumPanelProps> = (props) => {
    const [tableData, setTableData] = React.useState<any>([
        {
            relocationType: props.relocationType,
            RelocationPolicyID: props.relocationPolicyID,
            LumpSumRelocation: {
                NetCash: '',
                AccrualCostCenter: '',
            },
        },
    ]);
    const dollarSet = [2, 4, 5, 7];
    const localCurrencySet = [1, 3, 6, 8];

    React.useEffect(() => {
        setTableData(() => {
            return ([
                {
                    relocationType: props.relocationType,
                    RelocationPolicyID: props.relocationPolicyID,
                    LumpSumRelocation: {
                        NetCash: Object.keys(props.lumpSumRelocation).length > 0
                            ? props.lumpSumRelocation.NetCash
                            : '',
                        AccrualCostCenter: Object.keys(props.lumpSumRelocation).length > 0
                            ? props.lumpSumRelocation.AccrualCostCenter
                            : '',
                    },
                },
            ])
        })
    }, [props])
    const [dollarFlag, setDollarFlag] = React.useState(
        dollarSet.findIndex((item) => item === props.relocationPolicyID) != -1
    );

    const [localCurrencyFlag, setLocalCurrencyFlag] = React.useState(
        localCurrencySet.findIndex((item) => item === props.relocationPolicyID) != -1
    );

    const columns: IColumn[] = [
        {
            key: 'column1',
            name: 'Benefits',
            ariaLabel: 'Benefits column',
            fieldName: 'benefits',
            minWidth: 210,
            maxWidth: 350,
            isRowHeader: true,
            isResizable: true,
            isPadded: true,
            onRender: (item) => <span>{item.relocationType}</span>,
        },
        {
            key: 'column2',
            name: 'Net Cash to Employee',
            fieldName: 'netCash',
            minWidth: 210,
            maxWidth: 350,
            isRowHeader: true,
            isResizable: true,
            isPadded: true,
            onRender: (item) => {
                return (
                    <>
                        {dollarFlag && <span>$</span>}
                        {localCurrencyFlag && <span>(In local currency)</span>}
                        <TextField
                            key={item.LumpSumRelocation.NetCash}
                            ariaLabel="Net Cash to Employe"
                            role='textbox'
                            defaultValue={item.LumpSumRelocation.NetCash}
                            onChange={(e, newValue) => props.updateLumSumNetCash(newValue)}
                        />
                    </>
                )
            },
        },
        {
            key: 'column3',
            name: 'Accrual to Cost Center(including gross-up)',
            fieldName: 'accuralCost',
            minWidth: 210,
            maxWidth: 350,
            isRowHeader: true,
            isResizable: true,
            isPadded: true,
            onRender: (item) => (
                <>
                    {dollarFlag && <span>$</span>}
                    {localCurrencyFlag && <span>(In local currency)</span>}
                    <TextField
                        key={item.LumpSumRelocation.AccrualCostCenter}
                        ariaLabel="Accrual to Cost Center"
                        role='textbox'
                        defaultValue={item.LumpSumRelocation.AccrualCostCenter}
                    />
                </>
            ),
        },
    ];

    return (
        <>
            <DetailsList
                items={tableData}
                columns={columns}
                selectionMode={SelectionMode.none}
                setKey="none"
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                ariaLabelForGrid="Lump Sum Panel"
            />
        </>
    );
};
