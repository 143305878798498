import { IColumn } from '@fluentui/react';

export const ReloColumnsPage: IColumn[] = [
    {
        key: 'RecordID',
        name: 'Record ID',
        fieldName: 'RecordID',
        minWidth: 0,
        maxWidth: 30,

    },
    {
        key: 'FullName',
        name: 'Full Name',
        fieldName: 'FullName',
        minWidth: 100,
        maxWidth: 150,    },
    {
        key: 'RelocationSpecialist',
        name: 'Relocation Specialist',
        fieldName: 'RelocationSpecialist',
        minWidth: 0,
        maxWidth: 30,    },
    {
        key: 'LatestSubmissionDate',
        name: 'Latest Submission Date',
        fieldName: 'LatestSubmissionDate',
        minWidth: 0,
        maxWidth: 30,    },
    {
        key: 'Destination',
        name: 'Destination',
        fieldName: 'Destination',
        minWidth: 100,
        maxWidth: 140,    },
    {
        key: 'Departure',
        name: 'Departure',
        fieldName: 'Departure',
        minWidth: 100,
        maxWidth: 140,    },
    {
        key: 'EstimatedStartDate',
        name: 'Estimated Start Date',
        fieldName: 'EstimatedStartDate',
        minWidth: 0,
        maxWidth: 30,    },
    {
        key: 'RelocationType',
        name: 'Relocation Type',
        fieldName: 'RelocationType',
        minWidth: 200,
        maxWidth: 240,   
     },
    {
        key: 'CandidateID',
        name: 'Candidate ID',
        fieldName: 'CandidateID',
        minWidth: 0,
        maxWidth: 30,    },
    {
        key: 'Status',
        name: 'Status',
        fieldName: 'Status',
        minWidth: 30,
        maxWidth: 30,    },
];
