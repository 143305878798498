import { IColumn } from '@fluentui/react';

export const E2AReportColumns: IColumn[] = [
    {
        key: 'FileID',
        name: 'FileID',
        fieldName: 'FileID',
        minWidth: 125,
        maxWidth: 125,
    },
    {
        key: 'EmployeeNumber',
        name: 'Employee Number',
        fieldName: 'EmployeeNumber',
        minWidth: 125,
        maxWidth: 125,
    },
    {
        key: 'CostCenter',
        name: 'Cost Center',
        fieldName: 'CostCenter',
        minWidth: 125,
        maxWidth: 125,
    },
    {
        key: 'EmployeeStartDate',
        name: 'Employee Start Date',
        fieldName: 'EmployeeStartDate',
        minWidth: 125,
        maxWidth: 125,
    },
    {
        key: 'RelocationPolicy',
        name: 'Relocation Policy',
        fieldName: 'RelocationPolicy',
        minWidth: 125,
        maxWidth: 125,
    },
    {
        key: 'PackageName',
        name: 'Package Name',
        fieldName: 'PackageName',
        minWidth: 125,
        maxWidth: 125,
    },
    {
        key: 'FiscalYear',
        name: 'Fiscal Year',
        fieldName: 'FiscalYear',
        minWidth: 125,
        maxWidth: 125,
    },
    {
        key: 'PackageAmount',
        name: 'Package Amount',
        fieldName: 'PackageAmount',
        minWidth: 125,
        maxWidth: 125,
    },
    {
        key: 'Type',
        name: 'Package/Exception',
        fieldName: 'Type',
        minWidth: 125,
        maxWidth: 125,
    },
    {
        key: 'Category',
        name: 'Category',
        fieldName: 'Category',
        minWidth: 125,
        maxWidth: 125,
    },
    {
        key: 'SubCategory',
        name: 'Sub Category',
        fieldName: 'SubCategory',
        minWidth: 125,
        maxWidth: 125,
    },
    {
        key: 'FinanceGroup',
        name: 'Finance Group',
        fieldName: 'FinanceGroup',
        minWidth: 125,
        maxWidth: 125,
    },
    {
        key: 'CommitDate',
        name: 'Estimate Month',
        fieldName: 'CommitDate',
        minWidth: 125,
        maxWidth: 125,
    },
    {
        key: 'ActualMonth',
        name: 'Actual Month',
        fieldName: 'ActualMonth',
        minWidth: 125,
        maxWidth: 125,
    },
    {
        key: 'MonthIndex',
        name: 'Month Index',
        fieldName: 'MonthIndex',
        minWidth: 125,
        maxWidth: 125,
    },
    {
        key: 'EstimatedAmount',
        name: 'Estimated Amount',
        fieldName: 'EstimatedAmount',
        minWidth: 125,
        maxWidth: 125,
    },
    {
        key: 'ActualAmount',
        name: 'Actual Amount',
        fieldName: 'ActualAmount',
        minWidth: 125,
        maxWidth: 125,
    },
];
