import { IColumn } from '@fluentui/react';
export const ReassignReportColumns: IColumn[] = [
        {
            key: 'ReloIds',
            name: 'Relocation IDs',
            fieldName: 'ReloIds',
            data: 'string',
            minWidth: 100,
            maxWidth: 200,
        },
        {
            key: 'AliasReassigning',
            name: 'Alias Reassigning',
            fieldName: 'AliasReassigning',
            data: 'string',
            minWidth: 100,
            maxWidth: 200,
        },
];