import * as React from 'react';
import {
    Checkbox,
    ChoiceGroup,
    PrimaryButton,
    Dropdown,
    IChoiceGroupOption,
    IDropdownOption,
    Panel,
    PanelType,
    SearchBox,
    Stack,
} from '@fluentui/react';
import { DatePicker } from 'office-ui-fabric-react';
import { getStyles } from '../Shared/FilterPane.styles';
import { SendBackQueueFilterComponentProps } from './QueueTable.types';
import * as moment from 'moment';

export const FilterPane: React.FC<SendBackQueueFilterComponentProps> = React.forwardRef((props, ref) => {
    const visaPendingOptions: IChoiceGroupOption[] = [
        { key: '1', text: 'Yes' },
        { key: '2', text: 'No' },
        { key: '3', text: 'All' },
    ];

    const dropdownOptions = [
        { key: 'recordId', text: 'Record ID' },
        { key: 'name', text: 'Name' },
    ];

    const styles = getStyles();
    const [dropDownValue, setdropDownValue] = React.useState<string>('Select One');
    const [searchKeyword, setsearchKeyword] = React.useState<string>('');

    const [statusValues, setstatusValues] = React.useState<string[]>([]);
    const [isCheckedNonUSGlobal, setisCheckedNonUSGlobal] = React.useState<boolean>(false);
    const [isCheckedNonUSDomestic, setisCheckedNonUSDomestic] = React.useState<boolean>(false);
    const [isCheckedUSInbound, setisCheckedUSInbound] = React.useState<boolean>(false);
    const [isCheckedUSDomestic, setisCheckedUSDomestic] = React.useState<boolean>(false);

    const [internshipValues, setinternshipValues] = React.useState<string[]>([]);

    const [isCheckedHomeowner, setisCheckedHomeowner] = React.useState<boolean>(false);
    const [isCheckedRenter, setisCheckedRenter] = React.useState<boolean>(false);
    const [departureStatusValues, setdepartureStatusValues] = React.useState<string[]>([]);

    const [isCelaIntern, setisCelaIntern] = React.useState<boolean>(false);
    const [isExploreIntern, setisExploreIntern] = React.useState<boolean>(false);
    const [isGarageIntern, setisGarageIntern] = React.useState<boolean>(false);
    const [isHRIntern, setisHRIntern] = React.useState<boolean>(false);
    const [isIntern, setisIntern] = React.useState<boolean>(false);
    const [isMBAIntern, setisMBAIntern] = React.useState<boolean>(false);
    const [isGlobalIntern, setisGlobalIntern] = React.useState<boolean>(false);
    const [isResearchIntern, setisResearchIntern] = React.useState<boolean>(false);
    const [isTauberIntern, setisTauberIntern] = React.useState<boolean>(false);

    const [visaPendingValue, setvisaPendingValue] = React.useState<string>('3');
    const [everSendBackValue, seteverSendBackValue] = React.useState<string>('');
    const [estimatedFromDate, setestimatedFromDate] = React.useState<Date>();
    const [estimatedToDate, setestimatedToDate] = React.useState<Date>();
    React.useImperativeHandle(
        ref,
        () => ({
            ResetFilterCallFromMyRelo() {
                resetFilter();
            }
        }))

    return (
        <Panel
            type={PanelType.smallFixedFar}
            headerText={'Search this Queue'}
            isOpen={props.isOpen}
            onDismiss={props._closePanel}
            hasCloseButton={true}
            isLightDismiss={true}
            closeButtonAriaLabel="Close Filter Panel"
        >
            <div className={styles.filterContainer}>
                <Stack tokens={{ childrenGap: 10 }}>
                    <Dropdown
                        label="Select an Option"
                        onChange={onDropdownChange}
                        placeholder="Select One"
                        selectedKey={dropDownValue}
                        options={dropdownOptions}
                        // styles={dropdownStyles}
                    />
                    <SearchBox
                        // styles={searchBoxStyles}
                        placeholder="Search"
                        onEscape={(ev) => {}}
                        onClear={(ev) => {}}
                        onChange={(_, newValue) => setsearchKeyword(newValue || '')}
                        onSearch={searchResults}
                        value={searchKeyword}
                    />

                    <Stack.Item>
                        <PrimaryButton
                            styles={{ root: { marginTop: 20, marginRight: 10 } }}
                            text={'Search'}
                            onClick={searchResults}
                        />
                        <PrimaryButton styles={{ root: { marginTop: 20 } }} text={'Reset'} onClick={resetFilter} />
                    </Stack.Item>
                </Stack>

                <Stack tokens={{ childrenGap: 10 }}>
                    <h2 className={styles.filterTitle}>Filters</h2>
                    <Stack.Item>
                        <section>
                            <h3 className={styles.sectionTitles}>Relo Type</h3>
                            <Checkbox
                                label="Non US Global"
                                name="Non US Global"
                                className={styles.checkbox}
                                inputProps={{ value: 'Non_US_Global' }}
                                checked={isCheckedNonUSGlobal}
                                onChange={handleReloTypeFilter}
                            />
                            <Checkbox
                                label="Non US Domestic"
                                name="Non US Domestic"
                                className={styles.checkbox}
                                inputProps={{ value: 'Non_US_Domestic' }}
                                checked={isCheckedNonUSDomestic}
                                onChange={handleReloTypeFilter}
                            />
                            <Checkbox
                                label="US Inbound"
                                name="US Inbound"
                                className={styles.checkbox}
                                inputProps={{ value: 'US_Inbound' }}
                                checked={isCheckedUSInbound}
                                onChange={handleReloTypeFilter}
                            />
                            <Checkbox
                                label="US Domestic"
                                name="US Domestic"
                                className={styles.checkbox}
                                inputProps={{ value: 'US_Domestic' }}
                                checked={isCheckedUSDomestic}
                                onChange={handleReloTypeFilter}
                            />
                        </section>
                    </Stack.Item>
                    <Stack.Item>
                        <h3 className={styles.sectionTitles}>Visa Status</h3>
                        <ChoiceGroup
                            options={visaPendingOptions}
                            selectedKey={visaPendingValue}
                            className={styles.checkbox}
                            onChange={handleVisaPendingValue}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <h3 className={styles.sectionTitles}>Departure Status</h3>
                        <Checkbox
                            label="Homeowner"
                            name="Homeowner"
                            className={styles.checkbox}
                            inputProps={{ value: 'Homeowner' }}
                            checked={isCheckedHomeowner}
                            onChange={handleDepartureStatusFilter}
                        />
                        <Checkbox
                            label="Renter"
                            name="Renter"
                            className={styles.checkbox}
                            inputProps={{ value: 'Renter' }}
                            checked={isCheckedRenter}
                            onChange={handleDepartureStatusFilter}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <h3 className={styles.sectionTitles}>INTERNSHIP TYPE</h3>
                        <Checkbox
                            label="CELA Intern"
                            name="CELA Intern"
                            className={styles.checkbox}
                            inputProps={{ value: 'CelaIntern' }}
                            checked={isCelaIntern}
                            onChange={handleInternshipTypeFilter}
                        />
                        <Checkbox
                            label="Explore Intern"
                            name="Explore Intern"
                            className={styles.checkbox}
                            inputProps={{ value: 'ExploreIntern' }}
                            checked={isExploreIntern}
                            onChange={handleInternshipTypeFilter}
                        />
                        <Checkbox
                            label="Garage Intern"
                            name="Garage Intern"
                            className={styles.checkbox}
                            inputProps={{ value: 'GarageIntern' }}
                            checked={isGarageIntern}
                            onChange={handleInternshipTypeFilter}
                        />
                        <Checkbox
                            label="HR Intern"
                            name="HR Intern"
                            className={styles.checkbox}
                            inputProps={{ value: 'HRIntern' }}
                            checked={isHRIntern}
                            onChange={handleInternshipTypeFilter}
                        />
                        <Checkbox
                            label="Intern"
                            name="Intern"
                            className={styles.checkbox}
                            inputProps={{ value: 'Intern' }}
                            checked={isIntern}
                            onChange={handleInternshipTypeFilter}
                        />
                        <Checkbox
                            label="MBA Intern"
                            name="MBA Intern"
                            className={styles.checkbox}
                            inputProps={{ value: 'MBAIntern' }}
                            checked={isMBAIntern}
                            onChange={handleInternshipTypeFilter}
                        />
                        <Checkbox
                            label="Non US Global Intern"
                            name="Non US Global Intern"
                            className={styles.checkbox}
                            inputProps={{ value: 'GlobalIntern' }}
                            checked={isGlobalIntern}
                            onChange={handleInternshipTypeFilter}
                        />
                        <Checkbox
                            label="Research Intern"
                            name="Research Intern"
                            className={styles.checkbox}
                            inputProps={{ value: 'ResearchIntern' }}
                            checked={isResearchIntern}
                            onChange={handleInternshipTypeFilter}
                        />
                        <Checkbox
                            label="Tauber Intern"
                            name="Tauber Intern"
                            className={styles.checkbox}
                            inputProps={{ value: 'TauberIntern' }}
                            checked={isTauberIntern}
                            onChange={handleInternshipTypeFilter}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <h3 className={styles.sectionTitles}>ESTIMATED START DATE</h3>
                        <DatePicker
                            // styles={datePickerStyles}
                            label="From"
                            placeholder="Select a date..."
                            ariaLabel="Select a date"
                            value={estimatedFromDate}
                            onSelectDate={(newDate) => setestimatedFromDate(newDate || undefined)}
                        />
                        <DatePicker
                            // styles={datePickerStyles}
                            label="To"
                            placeholder="Select a date..."
                            ariaLabel="Select a date"
                            value={estimatedToDate}
                            onSelectDate={(newDate) => setestimatedToDate(newDate || undefined)}
                        />
                    </Stack.Item>
                </Stack>
                <Stack>
                    <Stack.Item>
                        <PrimaryButton
                            styles={{ root: { marginTop: 20, marginRight: 10 } }}
                            text="Filter"
                            onClick={filterByStatus}
                        />
                        <PrimaryButton styles={{ root: { marginTop: 20 } }} text="Reset" onClick={resetFilter} />
                    </Stack.Item>
                </Stack>
            </div>
        </Panel>
    );

    function filterByStatus() {
        let filteredData = props.apiData;
        const innerArray = props.apiData[0];
        let IsResetButtonToBeShown:boolean =false;
        if (statusValues.length > 0) {
            IsResetButtonToBeShown =true;
            statusValues.map((selStatus, index) => {
                if (index == 0) {
                    switch (selStatus) {
                        case 'Non_US_Global':
                            if (Array.isArray(innerArray)) {
                                filteredData = innerArray.filter((row) => row.RelocationPolicyID == 3);
                            }
                            else{
                                filteredData = props.apiData.filter((row) => row.RelocationPolicyID == 3);
                            }
                            break;

                        case 'Non_US_Domestic':
                            if (Array.isArray(innerArray)) {
                                filteredData = innerArray.filter((row) => row.RelocationPolicyID == 1);
                            }
                            else{
                                filteredData = props.apiData.filter((row) => row.RelocationPolicyID == 1);
                            }
                            break;

                        case 'US_Inbound':
                            if (Array.isArray(innerArray)) {
                                filteredData = innerArray.filter((row) => row.RelocationPolicyID == 4);
                            }
                            else{
                                filteredData = props.apiData.filter((row) => row.RelocationPolicyID == 4);
                            }
                            
                            break;

                        case 'US_Domestic':
                            if (Array.isArray(innerArray)) {
                                filteredData = innerArray.filter((row) => row.RelocationPolicyID == 2);
                            }
                            else{
                                filteredData = props.apiData.filter((row) => row.RelocationPolicyID == 2);
                            }
                            break;
                    }
                } else {
                    switch (selStatus) {
                        case 'Non_US_Global':
                            if (Array.isArray(innerArray)) {
                                filteredData = filteredData.concat(
                                    innerArray.filter((row) => row.RelocationPolicyID == 3)
                                );
                            }
                            else{
                                filteredData = filteredData.concat(
                                    props.apiData.filter((row) => row.RelocationPolicyID == 3)
                                );
                            }
                            break;

                        case 'Non_US_Domestic':
                            if (Array.isArray(innerArray)) {
                                filteredData = filteredData.concat(
                                    innerArray.filter((row) => row.RelocationPolicyID == 1)
                                );
                            }
                            else{
                                filteredData = filteredData.concat(
                                    props.apiData.filter((row) => row.RelocationPolicyID == 1)
                                );
                            }
                            break;

                        case 'US_Inbound':
                            if (Array.isArray(innerArray)) {
                                filteredData = filteredData.concat(
                                    innerArray.filter((row) => row.RelocationPolicyID == 4)
                                );
                            }
                            else{
                                filteredData = filteredData.concat(
                                    props.apiData.filter((row) => row.RelocationPolicyID == 4)
                                );
                            }
                            break;

                        case 'US_Domestic':
                            if (Array.isArray(innerArray)) {
                                filteredData = filteredData.concat(
                                    innerArray.filter((row) => row.RelocationPolicyID == 2)
                                );
                            }
                            else{
                                filteredData = filteredData.concat(
                                    props.apiData.filter((row) => row.RelocationPolicyID == 2)
                                );
                            }
                            
                            break;
                    }
                }
            });
        }
        if (visaPendingValue != '3') {
            IsResetButtonToBeShown =true;
            if (Array.isArray(innerArray)) {
                filteredData =
                visaPendingValue === '1'
                    ? innerArray.filter((row) => row.VisaStatus === true)
                    : innerArray.filter((row) => row.VisaStatus === false);
            }
            else{
                filteredData =
                visaPendingValue === '1'
                    ? filteredData.filter((row) => row.VisaStatus === true)
                    : filteredData.filter((row) => row.VisaStatus === false);
            }
        }
        if (everSendBackValue.length > 0) {
            IsResetButtonToBeShown =true;
            if (Array.isArray(innerArray)) {
                filteredData =
                everSendBackValue === 'SendBack'
                    ? innerArray.filter((row) => row.EverSendBack === true)
                    : innerArray.filter((row) => row.EverSendBack === true || row.EverSendBack === false);
            }
            else{
                filteredData =
                everSendBackValue === 'SendBack'
                    ? filteredData.filter((row) => row.EverSendBack === true)
                    : filteredData.filter((row) => row.EverSendBack === true || row.EverSendBack === false);
            }
        }
        if (internshipValues.length > 0) {
            IsResetButtonToBeShown =true;
            internshipValues.map((internType, index) => {
                if (index == 0) {
                    switch (internType) {
                        case 'CelaIntern':
                            if (Array.isArray(innerArray)) {
                                filteredData = innerArray.filter((row) => row.InternshipTypeID === 8);
                            }
                            else{
                                filteredData = props.apiData.filter((row) => row.InternshipTypeID === 8);
                            }
                            break;
                        case 'ExploreIntern':
                            if (Array.isArray(innerArray)) {
                                filteredData = innerArray.filter((row) => row.InternshipTypeID === 1);
               
                            }
                            else{
                            filteredData = props.apiData.filter((row) => row.InternshipTypeID === 1);

                            }
                            break;
                        case 'GarageIntern':
                            if (Array.isArray(innerArray)) {
                                filteredData = innerArray.filter((row) => row.InternshipTypeID === 2);
               
                            }
                            else{
                                filteredData = props.apiData.filter((row) => row.InternshipTypeID === 2);

                            }
                            break;
                        case 'HRIntern':
                            if (Array.isArray(innerArray)) {
                                filteredData = innerArray.filter((row) => row.InternshipTypeID === 7);
                            }
                            else{
                                filteredData = props.apiData.filter((row) => row.InternshipTypeID === 7);
                            }
                            break;
                        case 'Intern':
                            if (Array.isArray(innerArray)) {
                                filteredData = innerArray.filter((row) => row.InternshipTypeID === 3);
                            }
                            else{
                                filteredData = props.apiData.filter((row) => row.InternshipTypeID === 3);
                            }
                            break;
                        case 'MBAIntern':
                            if (Array.isArray(innerArray)) {
                                filteredData = innerArray.filter((row) => row.InternshipTypeID === 4);
                            }
                            else{
                                filteredData = props.apiData.filter((row) => row.InternshipTypeID === 4);
                            }
                            break;
                        case 'GlobalIntern':
                            if (Array.isArray(innerArray)) {
                                filteredData = innerArray.filter((row) => row.InternshipTypeID === 6);
                            }
                            else{
                                filteredData = props.apiData.filter((row) => row.InternshipTypeID === 6);
                            }
                            break;
                        case 'ResearchIntern':
                            if (Array.isArray(innerArray)) {
                                filteredData = innerArray.filter((row) => row.InternshipTypeID === 5);
                            }
                            else{
                                filteredData = props.apiData.filter((row) => row.InternshipTypeID === 5);
                            }
                            break;
                        case 'TauberIntern':
                            if (Array.isArray(innerArray)) {
                                filteredData = innerArray.filter((row) => row.InternshipTypeID === 11);
                            }
                            else{
                                filteredData = props.apiData.filter((row) => row.InternshipTypeID === 11);
                            }
                            break;
                    }
                } else {
                    switch (internType) {
                        case 'CelaIntern':
                            if (Array.isArray(innerArray)) {
                                filteredData = innerArray.filter((row) => row.InternshipTypeID === 8);
                            }
                            else{
                                filteredData = props.apiData.filter((row) => row.InternshipTypeID === 8);
                            }
                            break;
                        case 'ExploreIntern':
                            if (Array.isArray(innerArray)) {
                                filteredData = filteredData.concat(
                                    innerArray.filter((row) => row.InternshipTypeID === 1)
                                );
                            }
                            else{
                                filteredData = filteredData.concat(
                                    props.apiData.filter((row) => row.InternshipTypeID === 1)
                                );
                            }
                            break;
                        case 'GarageIntern':
                            if (Array.isArray(innerArray)) {
                                filteredData = filteredData.concat(
                                    innerArray.filter((row) => row.InternshipTypeID === 2)
                                );
                            }
                            else{
                                filteredData = filteredData.concat(
                                    props.apiData.filter((row) => row.InternshipTypeID === 2)
                                );
                            }
                            break;
                        case 'HRIntern':
                            if (Array.isArray(innerArray)) {
                                filteredData = filteredData.concat(
                                    innerArray.filter((row) => row.InternshipTypeID === 7)
                                );
                            }
                            else{
                                filteredData = filteredData.concat(
                                    props.apiData.filter((row) => row.InternshipTypeID === 7)
                                );
                            }
                            break;
                        case 'Intern':
                            if (Array.isArray(innerArray)) {
                                filteredData = filteredData.concat(
                                    innerArray.filter((row) => row.InternshipTypeID === 3)
                                );
                            }
                            else{
                                filteredData = filteredData.concat(
                                    props.apiData.filter((row) => row.InternshipTypeID === 3)
                                );
                            }
                            break;
                        case 'MBAIntern':
                            if (Array.isArray(innerArray)) {
                                filteredData = filteredData.concat(
                                    innerArray.filter((row) => row.InternshipTypeID === 4)
                                );
                            }
                            else{
                                filteredData = filteredData.concat(
                                    props.apiData.filter((row) => row.InternshipTypeID === 4)
                                );
                            }
                            break;
                        case 'GlobalIntern':
                            if (Array.isArray(innerArray)) {
                                filteredData = filteredData.concat(
                                    innerArray.filter((row) => row.InternshipTypeID === 6)
                                );
                            }
                            else{
                                filteredData = filteredData.concat(
                                    props.apiData.filter((row) => row.InternshipTypeID === 6)
                                );
                            }
                            break;
                        case 'ResearchIntern':
                            if (Array.isArray(innerArray)) {
                                filteredData = filteredData.concat(
                                    innerArray.filter((row) => row.InternshipTypeID === 5)
                                );
                            }
                            else{
                                filteredData = filteredData.concat(
                                    props.apiData.filter((row) => row.InternshipTypeID === 5)
                                );
                            }
                            break;
                        case 'TauberIntern':
                            if (Array.isArray(innerArray)) {
                                filteredData = filteredData.concat(
                                    innerArray.filter((row) => row.InternshipTypeID === 11)
                                );
                            }
                            else{
                                filteredData = filteredData.concat(
                                    props.apiData.filter((row) => row.InternshipTypeID === 11)
                                );
                            }
                            break;
                    }
                }
            });
        }
        if (estimatedFromDate != undefined) {
            IsResetButtonToBeShown =true;
            if (Array.isArray(innerArray)) {
                filteredData = innerArray.filter(
                    (row) => moment(row.CreatedDate).toDate() >= moment(estimatedFromDate).toDate()
                );
            }
            else{
                filteredData = filteredData.filter(
                    (row) => moment(row.CreatedDate).toDate() >= moment(estimatedFromDate).toDate()
                );
            }
        }
        if (estimatedToDate != undefined) {
            IsResetButtonToBeShown =true;
            if (Array.isArray(innerArray)) {
                filteredData = innerArray.filter(
                    (row) => moment(row.EstimatedStartDate).toDate() <= moment(estimatedToDate).toDate()
                );
            }
            else{
                filteredData = filteredData.filter(
                    (row) => moment(row.EstimatedStartDate).toDate() <= moment(estimatedToDate).toDate()
                );
            }
            
        }
        if(IsResetButtonToBeShown)
        {
            props.ShowResetButton();
        }
        if (Array.isArray(innerArray)) {
            props.handleFilterSubmit(filteredData.flat(1));      
        }
        else{
            props.handleFilterSubmit(filteredData);
        }
    }

    function handleReloTypeFilter(event?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void {
        let curStatusValues = statusValues;
        const status = (event!.currentTarget as HTMLInputElement).value;

        if (checked) {
            switch (status) {
                case 'Non_US_Global':
                    setisCheckedNonUSGlobal(true);
                    break;
                case 'Non_US_Domestic':
                    setisCheckedNonUSDomestic(true);
                    break;
                case 'US_Inbound':
                    setisCheckedUSInbound(true);
                    break;
                case 'US_Domestic':
                    setisCheckedUSDomestic(true);
                    break;
            }
            curStatusValues.push(status);
        } else {
            const index = curStatusValues.indexOf(status);
            curStatusValues.splice(index, 1);
            switch (status) {
                case 'Non_US_Global':
                    setisCheckedNonUSGlobal(false);
                    break;
                case 'Non_US_Domestic':
                    setisCheckedNonUSDomestic(false);
                    break;
                case 'US_Inbound':
                    setisCheckedUSInbound(false);
                    break;
                case 'US_Domestic':
                    setisCheckedUSDomestic(false);
                    break;
            }
        }
        setstatusValues(curStatusValues);
    }

    function handleDepartureStatusFilter(
        event?: React.FormEvent<HTMLElement | HTMLInputElement>,
        checked?: boolean
    ): void {
        let curStatusValues = departureStatusValues;
        let status = (event!.currentTarget as HTMLInputElement).value;

        if (checked) {
            switch (status) {
                case 'Homeowner':
                    setisCheckedHomeowner(true);
                    break;
                case 'Renter':
                    setisCheckedRenter(true);
                    break;
            }
            curStatusValues.push(status);
        } else {
            let index = curStatusValues.indexOf(status);
            curStatusValues.splice(index, 1);
            switch (status) {
                case 'Homeowner':
                    setisCheckedHomeowner(false);
                    break;
                case 'Renter':
                    setisCheckedRenter(false);
                    break;
            }
        }
        setdepartureStatusValues(curStatusValues);
    }

    function handleVisaPendingValue(
        event?: React.FormEvent<HTMLElement | HTMLInputElement>,
        option?: IChoiceGroupOption
    ): void {
        setvisaPendingValue(option!.key);
    }

    function onDropdownChange(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void {
        setdropDownValue(option!.key as string);
    }

    function searchResults() {
        let filteredData = props.apiData;
        const innerArray = props.apiData[0];
        switch (dropDownValue) {
            case 'recordId':
                if (Array.isArray(innerArray)) {
                    filteredData = innerArray.filter((row) => row.RecordID.toString() === searchKeyword);
                }
                else{
                    filteredData = props.apiData.filter((row) => row.RecordID.toString() === searchKeyword);
                }
                break;
            case 'name':
                if (Array.isArray(innerArray)) {
                    filteredData = innerArray.filter((row) => {
                        return (
                            row.FullName.toLowerCase()
                                .replace(/ /g, "")
                                .indexOf(searchKeyword.toLowerCase().replace(/ /g, "")) > -1
                        );
                    });
                }
                else{
                    filteredData = props.apiData.filter((row) => {
                        return (
                            row.FullName.toLowerCase()
                                .replace(/ /g, "")
                                .indexOf(searchKeyword.toLowerCase().replace(/ /g, "")) > -1
                        );
                    });
                }
                break;
            case 'Select One': // not sure if this is necessary anymore since setting initially
                if (Array.isArray(innerArray)) {
                    filteredData = innerArray;
                }
                else{
                    filteredData = props.apiData;
                }
                break;
        }
        if((searchKeyword.length>0) && (dropDownValue != "Select One") ) 
        {
            props.ShowResetButton();
        }
        props.handleFilterSubmit(filteredData);
    }

    function handleInternshipTypeFilter(
        event?: React.FormEvent<HTMLElement | HTMLInputElement>,
        checked?: boolean
    ): void {
        let curStatusValues = internshipValues;
        let status = (event!.currentTarget as HTMLInputElement).value;

        if (checked) {
            switch (status) {
                case 'CelaIntern':
                    setisCelaIntern(true);
                    break;
                case 'ExploreIntern':
                    setisExploreIntern(true);
                    break;
                case 'GarageIntern':
                    setisGarageIntern(true);
                    break;
                case 'HRIntern':
                    setisHRIntern(true);
                    break;
                case 'Intern':
                    setisIntern(true);
                    break;
                case 'MBAIntern':
                    setisMBAIntern(true);
                    break;
                case 'GlobalIntern':
                    setisGlobalIntern(true);
                    break;
                case 'ResearchIntern':
                    setisResearchIntern(true);
                    break;
                case 'TauberIntern':
                    setisTauberIntern(true);
                    break;
            }
            curStatusValues.push(status);
        } else {
            const index = curStatusValues.indexOf(status);
            curStatusValues.splice(index, 1);
            switch (status) {
                case 'CelaIntern':
                    setisCelaIntern(false);
                    break;
                case 'ExploreIntern':
                    setisExploreIntern(false);
                    break;
                case 'GarageIntern':
                    setisGarageIntern(false);
                    break;
                case 'HRIntern':
                    setisHRIntern(false);
                    break;
                case 'Intern':
                    setisIntern(false);
                    break;
                case 'MBAIntern':
                    setisMBAIntern(false);
                    break;
                case 'GlobalIntern':
                    setisGlobalIntern(false);
                    break;
                case 'ResearchIntern':
                    setisResearchIntern(false);
                    break;
                case 'TauberIntern':
                    setisTauberIntern(false);
                    break;
            }
        }
        setinternshipValues(curStatusValues);
    }

    function resetFilter(): void {
        const innerArray = props.apiData[0];
        setstatusValues([]);
        setisCheckedNonUSGlobal(false);
        setisCheckedNonUSDomestic(false);
        setisCheckedUSInbound(false);
        setisCheckedUSDomestic(false);
        setinternshipValues([]);
        setisCelaIntern(false);
        setisExploreIntern(false);
        setisGarageIntern(false);
        setisHRIntern(false);
        setisIntern(false);
        setisMBAIntern(false);
        setisGlobalIntern(false);
        setisResearchIntern(false);
        setisTauberIntern(false);
        setvisaPendingValue('3');
        seteverSendBackValue('');
        setestimatedFromDate(undefined);
        setestimatedToDate(undefined);
        setdropDownValue('Select One');
        setsearchKeyword('');
        if (Array.isArray(innerArray)) {
            props.handleFilterSubmit(innerArray);         
        }
        else{
            props.handleFilterSubmit(props.apiData);
        }
        
        props.HideResetButton();
    }
});
