import { IHttpClient, IHttpClientResult } from '@employee-experience/common';
import { IUserRoles } from '../../Models/IUserRoles';

export class LoginService {
    private httpClient: IHttpClient;
    private url: string;

    public constructor(httpClient: IHttpClient) {
        this.httpClient = httpClient;
        this.url = REACT_APP_RELO_API_URL;
    }

    public async getLoginUser(): Promise<IUserRoles> {
        const response: IHttpClientResult<IUserRoles> = await this.httpClient.request({
            url: `${this.url}api/LoggedInUser`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    }
}
