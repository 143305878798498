import { IHttpClient, IHttpClientResult } from '@employee-experience/common';
import { IRelocationFormQueues } from '../../Models/IRelocationFormQueues';
import { IReloCampQueueForm } from '../../Models/IReloCampQueue';
import { ISendBackReport } from '../../Models/ISendBackReport';

export class QueueService {
    private httpClient: IHttpClient;
    private url: string;

    public constructor(httpClient: IHttpClient) {
        this.httpClient = httpClient;
        this.url = REACT_APP_RELO_API_URL;
    }

    public async getQueueRecords(Role: string, ReportType: string, Alias: string): Promise<IRelocationFormQueues[]> {
        if (__IS_ACCESSIBILITY_TESTING__ == 'true') {
            Role = '?' + Role;
        }
        const response: IHttpClientResult<IRelocationFormQueues[]> = await this.httpClient.request({
            url: `${this.url}api/reloform/RelocationFormQueue/${Role}/${ReportType}/${Alias}?alias=${Alias}&reportType=${ReportType}&role=${Role}`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    }

    public async getReloCampQueueRecords(Role: string, ReportType: string, Alias: string): Promise<IReloCampQueueForm[]> {
        const response: IHttpClientResult<IReloCampQueueForm[]> = await this.httpClient.request({
            url: `${this.url}api/reloform/RelocationFormQueue/${Role}/${ReportType}/${Alias}?alias=${Alias}&reportType=${ReportType}&role=${Role}`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    }

    public async getSendBackReport(StartDate: Date, EndDate: Date): Promise<ISendBackReport[]> {
        const response: IHttpClientResult<ISendBackReport[]> = await this.httpClient.request({
            url: `${this.url}api/reloform/GetSendBackReport`,
            resource: __CLIENT_ID__,
            params: {
                StartDate: StartDate,
                EndDate: EndDate
            }
        });
        return response?.data;
    }

    public async GetReloAdminQueueForExportToExcel(Role: string, Alias: string): Promise<any> {
        if (__IS_ACCESSIBILITY_TESTING__ == 'true') {
            Role = '?' + Role;
        }
        const response: IHttpClientResult<any> = await this.httpClient.request({
            url: `${this.url}api/reloform/GetReloAdminQueueForExportToExcel/${Role}/Staffing/${Alias}?alias=${Alias}&reportType=staffing&role=${Role}`,
            resource: __CLIENT_ID__,
        });
        return response;
    }

    public async GetCurrentMonthSubmittedFormsList(): Promise<string[]> {
        const response: IHttpClientResult<string[]> = await this.httpClient.request({
            method: 'GET',
            url: `${this.url}api/reloform/GetCurrentMonthSubmittedFormsList`,
            resource: __CLIENT_ID__,
        });
        return response?.data;
    }
}