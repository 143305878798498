import * as React from 'react';
import { IColumn } from '@fluentui/react';
import { useHistory } from 'react-router-dom';
import { FontIcon } from '@fluentui/react/lib/Icon';
import { mergeStyles, mergeStyleSets } from '@fluentui/react/lib/Styling';
import * as moment from 'moment';

const iconClass = mergeStyles({
    fontSize: 16,
    height: 16,
    width: 16,
    margin: '0 0 0 10px',
    display: 'inline-flex',
    position:'relative',
    top:'5px'
});

const iconColorNames = mergeStyleSets({
    goldenrod: [{ color: 'goldenrod' }, iconClass],
    RfEClass: [{
        fontSize: 23,
        paddingRight: 5,
        marginLeft: 2,
        color: '#323130',
        display: 'grid',
    }]

});

export const EstimatesQueueColumns = () => {
    const history = useHistory();
    const redirectToEstimateForm = (item: any) => {
        let myPattern = new RegExp('(\\w*' + 'Lump Sum' + '\\w*)', 'gi');
        let matches = item?.RelocationType.match(myPattern);
        let obj = JSON.stringify({
            fileId: item.FileId,
            reloId: item.ReloID,
            totalEstimate: item.TotalEstimate,
            isLumpSum: matches ? true : false,
            committedStatus: item.CommittedStatus,
            redirectedFrom: 'estimatesQueue',
        });
        history.push('/EstimatesForm', obj)
    }

    const EstimatesQueueUnCommittedColumns: IColumn[] = [
        {
            key: 'FileId',
            name: 'File ID',
            fieldName: 'FileId',
            minWidth: 60,
            maxWidth:140,
            onRender: (item) => {
                const { CostCenterDefect, DateDefect, EmpIDDefect, FirstNameDefect, LastNameDefect, PolicyDefect, DateDiff, EverSendBack } = item;
                return (<>
                    <span onClick={() => {
                        redirectToEstimateForm(item)
                    }}>{item.FileId}</span>
                    <div className={iconClass}>
                        {EverSendBack && <FontIcon
                            iconName="MailForward"
                            className={iconColorNames.RfEClass}
                        />}
                        {(DateDiff == true ? (CostCenterDefect || DateDefect || EmpIDDefect || FirstNameDefect || LastNameDefect || PolicyDefect) : false) && <FontIcon aria-label="Compass" iconName="IncidentTriangle" className={iconColorNames.goldenrod}
                        />}</div>
                </>)
            }
        },
        {
            key: 'BusinessGroup',
            name: 'Business Group',
            fieldName: 'BusinessGroup',
            minWidth: 60,
            maxWidth:140,
            onRender: (item) => <span onClick={() => {
                redirectToEstimateForm(item)
            }}>{item.BusinessGroup}</span>
        },
        {
            key: 'CustomerName',
            name: 'Employee Name',
            fieldName: 'CustomerName',
            minWidth: 60,
            maxWidth:140,
            onRender: (item) => <span onClick={() => {
                redirectToEstimateForm(item)
            }}>{item.CustomerName}</span>
        },
        {
            key: 'TotalEstimate',
            name: 'Total Estimate',
            fieldName: 'TotalEstimate',
            minWidth: 60,
            maxWidth:140,            
            onRender: (item) => <span onClick={() => {
                redirectToEstimateForm(item)
            }}>{item.TotalEstimate}</span>
        },
        {
            key: 'StartDate',
            name: 'Start Date',
            fieldName: 'StartDate',
            minWidth: 60,
            maxWidth:140,
            onRender: (item) => <span onClick={() => {
                redirectToEstimateForm(item)
            }}>{moment(item?.StartDate).format('MM/DD/YYYY')}</span>
        },
        {
            key: 'CostCenter',
            name: 'COST CENTER',
            fieldName: 'CostCenter',
            minWidth: 60,
            maxWidth:140,
            onRender: (item) => <span onClick={() => {
                redirectToEstimateForm(item)
            }}>{item.CostCenter}</span>
        },
        {
            key: 'RelocationType',
            name: 'Relocation Type',
            fieldName: 'RelocationType',
            minWidth: 60,
            maxWidth:140,
            onRender: (item) => <span onClick={() => {
                redirectToEstimateForm(item)
            }}>{item.RelocationType}</span>
        },
        {
            key: 'HireType',
            name: 'Hire Type',
            fieldName: 'HireType',
            minWidth: 60,
            maxWidth:140,
            onRender: (item) => <span onClick={() => {
                redirectToEstimateForm(item)
            }}>{item.HireType}</span>
        },
    ];

    const EstimatesQueueCommittedColumns: IColumn[] = [
        {
            key: 'FileId',
            name: 'File ID',
            fieldName: 'FileId',
            minWidth: 60,
            maxWidth:140,
            onRender: (item) => {
                const { EverSendBack } = item;
                return (<>
                    <span onClick={() => {
                        redirectToEstimateForm(item)
                    }}>{item.FileId}</span>
                    {EverSendBack && <FontIcon
                        iconName="MailForward"
                        className={iconColorNames.RfEClass}
                    />}
                </>)
            }
        }, {
            key: 'CustomerName',
            name: 'Employee Name',
            fieldName: 'CustomerName',
            minWidth: 60,
            maxWidth:140,
            onRender: (item) => <span onClick={() => {
                redirectToEstimateForm(item)
            }}>{item.CustomerName}</span>
        },
        {
            key: 'AssignedTo',
            name: 'Assigned To',
            fieldName: 'AssignedTo',
            minWidth: 60,
            maxWidth:140,
            onRender: (item) => <span onClick={() => {
                redirectToEstimateForm(item)
            }}>{item.AssignedTo}</span>
        },
        {
            key: 'StartDate',
            name: 'Start Date',
            fieldName: 'StartDate',
            minWidth: 60,
            maxWidth:140,
            onRender: (item) => <span onClick={() => {
                redirectToEstimateForm(item)
            }}>{moment(item?.StartDate).format('MM/DD/YYYY')}</span>
        },
        {
            key: 'CostCenter',
            name: 'COST CENTER',
            fieldName: 'CostCenter',
            minWidth: 60,
            maxWidth:140,
            onRender: (item) => <span onClick={() => {
                redirectToEstimateForm(item)
            }}>{item.CostCenter}</span>
        },
        {
            key: 'RelocationType',
            name: 'Relocation Type',
            fieldName: 'RelocationType',
            minWidth: 60,
            maxWidth:140,
            onRender: (item) => <span onClick={() => {
                redirectToEstimateForm(item)
            }}>{item.RelocationType}</span>
        },
        {
            key: 'HireType',
            name: 'Hire Type',
            fieldName: 'HireType',
            minWidth: 60,
            maxWidth:140,
            onRender: (item) => <span onClick={() => {
                redirectToEstimateForm(item)
            }}>{item.HireType}</span>
        },
        {
            key: 'CommitStamp',
            name: 'Date Committed',
            fieldName: 'CommitStamp',
            minWidth: 60,
            maxWidth:140,
            onRender: (item) => <span onClick={() => {
                redirectToEstimateForm(item)
            }}>{item.CommitStamp}</span>
        },
    ];

    const EstimatesQueueUnCommittedExcelColumns: IColumn[] = [
        {
            key: 'FileId',
            name: 'File ID',
            fieldName: 'FileId',
            minWidth: 60,
            maxWidth:140,
        },
        {
            key: 'CustomerName',
            name: 'Employee Name',
            fieldName: 'CustomerName',
            minWidth: 80,
        },
        {
            key: 'EmployeeID',
            name: 'EEID',
            fieldName: 'EmployeeID',
            minWidth: 60,
            maxWidth:140,
        },
        {
            key: 'CandidateID',
            name: 'CandidateID',
            fieldName: 'CandidateId',
            minWidth: 60,
            maxWidth:140,
        },
        {
            key: 'BusinessGroup',
            name: 'Business Group',
            fieldName: 'BusinessGroup',
            minWidth: 60,
            maxWidth:140,
        },
        {
            key: 'AssignedTo',
            name: 'Assigned To',
            fieldName: 'AssignedTo',
            minWidth: 60,
            maxWidth:140,
        },
        
        {
            key: 'StartDate',
            name: 'Start Date',
            fieldName: 'StartDate',
            minWidth: 60,
            maxWidth:140,
        },
        {
            key: 'CostCenter',
            name: 'COST CENTER',
            fieldName: 'CostCenter',
            minWidth: 60,
            maxWidth:140,
        },
        {
            key: 'RelocationType',
            name: 'Relocation Type',
            fieldName: 'RelocationType',
            minWidth: 60,
            maxWidth:140,
        },
        {
            key: 'HireType',
            name: 'Hire Type',
            fieldName: 'HireType',
            minWidth: 60,
            maxWidth:140,
        },
        {
            key: 'CostCenterCode',
            name: 'Head Trax Cost Center',
            fieldName: 'CostCenterCode',
            minWidth: 60,
            maxWidth:140,
        },
        {
            key: 'HRRecruitingAssociate',
            name: 'Recruiter ID',
            fieldName: 'HRRecruitingAssociate',
            minWidth: 60,
            maxWidth:140,
        },
        {
            key: 'TotalEstimate',
            name: 'Total Estimate',
            fieldName: 'TotalEstimate',
            minWidth: 60,
            maxWidth:140,
        },
    ];

    const EstimatesQueueCommittedExcelColumns: IColumn[] = [
        {
            key: 'FileId',
            name: 'File ID',
            fieldName: 'FileId',
            minWidth: 60,
            maxWidth:140,
        },
        {
            key: 'CustomerName',
            name: 'Employee Name',
            fieldName: 'CustomerName',
            minWidth: 60,
            maxWidth:140,
        },
        {
            key: 'EmployeeID',
            name: 'EEID',
            fieldName: 'EmployeeID',
            minWidth: 60,
            maxWidth:140,
        },
        {
            key: 'CandidateID',
            name: 'CandidateID',
            fieldName: 'CandidateId',
            minWidth: 60,
            maxWidth:140,
        },
        {
            key: 'BusinessGroup',
            name: 'Business Group',
            fieldName: 'BusinessGroup',
            minWidth: 60,
            maxWidth:140,
        },
        {
            key: 'AssignedTo',
            name: 'Assigned To',
            fieldName: 'AssignedTo',
            minWidth: 60,
            maxWidth:140,
        },
        {
            key: 'StartDate',
            name: 'Start Date',
            fieldName: 'StartDate',
            minWidth: 60,
            maxWidth:140,
        },
        {
            key: 'CostCenter',
            name: 'COST CENTER',
            fieldName: 'CostCenter',
            minWidth: 60,
            maxWidth:140,
        },
        {
            key: 'RelocationType',
            name: 'Relocation Type',
            fieldName: 'RelocationType',
            minWidth: 60,
            maxWidth:140,
        },
        {
            key: 'HireType',
            name: 'Hire Type',
            fieldName: 'HireType',
            minWidth: 60,
            maxWidth:140,
        },
        {
            key: 'CostCenterCode',
            name: 'Head Trax Cost Center',
            fieldName: 'CostCenterCode',
            minWidth: 60,
            maxWidth:140,
        },
        {
            key: 'HRRecruitingAssociate',
            name: 'Recruiter ID',
            fieldName: 'HRRecruitingAssociate',
            minWidth: 60,
            maxWidth:140,
        },
        {
            key: 'TotalEstimate',
            name: 'Total Estimate',
            fieldName: 'TotalEstimate',
            minWidth: 60,
            maxWidth:140,
        },
        {
            key: 'CommitStamp',
            name: 'Date Committed',
            fieldName: 'CommitStamp',
            minWidth: 60,
            maxWidth:140,
        },
    ];

    return {
        EstimatesQueueUnCommittedColumns,
        EstimatesQueueCommittedColumns,
        EstimatesQueueUnCommittedExcelColumns,
        EstimatesQueueCommittedExcelColumns
    }
};
